import React, {useEffect, useState} from "react";
import LayoutPanel from "../../../components/layoutPanel";

import './main.scss';
import useLearnerMain from "./hooks/courseMain.hook";
import Loader from "../../../components/loader";
import useVideoPlayers from "../../../components/videoPlayers/hooks/videoPlayers.hook";
import useGetAllVideosWatched from "../../../hooks/getallvideoswatched.hook";
import {useLocation, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { GURU } from "../../../constants/app.constants";
import LayoutGuru from "../../../components/layoutGuru";
import CourseMainView from "./view/homeCourseMainView";
import useCourseMain from "./hooks/courseMain.hook";
import HomeCourseMainView from "./view/homeCourseMainView";
import Layout from "../../../components/layout";

const HomeCoursePreview = props => {
    const {videoId} = useParams()
    const learnerMainHook = useCourseMain();
    const videoPlayersHook = useVideoPlayers()
    const getAllVideosHook = useGetAllVideosWatched()
    const {pathname} = useLocation();
    var isMyLearningPage = true;
    const userInfo = useSelector(state => state?.app?.userInfo);
   
     
    console.log("userInfo",userInfo);

    
    useEffect(() => {

        learnerMainHook.getVideoDetailsById(null, isMyLearningPage)
        
    }, []);

    if (learnerMainHook.loading || learnerMainHook?.loading ) {
        return (
            <>
               <Layout {...props}>
                    <Loader/>
               </Layout>
            </>
        )

    } else {
        return (
            <>
              

              <Layout {...props}>
                    <HomeCourseMainView {...props} {...learnerMainHook} {...videoPlayersHook} isMyLearningPage={isMyLearningPage} {...getAllVideosHook}/>
                  </Layout>
               
            </>
        )
    }


}

export default HomeCoursePreview;
