import React from "react";
import Text from "../text";
import './textReadonly.scss';

const TextReadonly = props => {
    return(
        <div className={`text-readonly-item ${props.className}`} title={props.text}>
            <label className="text-readonly-item-label">{props.label}</label>
          
            {props.type === "PARAGRAPH" && <p className={props.className} style={props.style}>{props.text} </p>}
            {props.type != "PARAGRAPH" && props.text && <Text type="H4" text={props.text} className="text-readonly-item-title" />}
            {props.children}
        </div>
    )
}

export default TextReadonly;
