import React from 'react';
import Helmet from 'react-helmet'
import Footer from '../footer';
import Header from '../header';
import './layout.scss'
const Layout = ({props, children, text}) => {
    return (
        <>
            <Helmet>
            <title>CourseTsunami</title>
            <meta name="description" content="COURSE_TSUNAMI" />
            </Helmet>
            <div className="layout-wrapper">
                <Header text={text}/>
                <div className="main_wrapper">
                    {children}
                </div>
                <Footer text={text}/>
            </div>
        </>
    );
};

export default Layout;
