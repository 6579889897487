import React from 'react';
import {useFormik} from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import useLogin from "../../../../authentication/login/hooks/login.hook";
import useAccount from '../hooks/account.hook';
import EyeIcon from '../../../../../components/svg/EyeIcon';
import AccountExistingPswd from '../../../../../components/input/accountExistingPswd';
import AccountNewPswd from '../../../../../components/input/accountNewPswd';
import AccountConfirmPswd from '../../../../../components/input/accountConfirmPswd';
import NavLearnerProfile from '../../../../../components/navLearnerProfile';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import AccountPassword from '../../../../../components/input/accountPassword';


const AccountView = (props) => {

    const navigate = useNavigate();
    const {
        lowercaseRegex,
        uppercaseRegex,
        numericRegex,
        nonalphanumeric,
        numbersOnly
    } = useLogin()
    const { showExistingPassword,
        ShowExistingPasswordOnClick,
        showNewPassword,
        ShowNewPasswordOnClick,
        showConfirmNewPassword,
        ShowConfirmNewPasswordOnClick } = useAccount();
    const {post} = useHttpHandler()
    const {text} =props
    const formik = useFormik({
        initialValues: {
            existingPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema: Yup.object({
            existingPassword: Yup.string().required('Please enter a valid existing password'),
            newPassword: Yup.string()
                .min(6, 'Your password must be atleast 6 digits')
                .matches(uppercaseRegex, 'Atleast one capital letter required')
                .matches(lowercaseRegex, 'Atleast one small letter required')
                .matches(numericRegex, 'Atleast one number required')
                .matches(nonalphanumeric, 'Atleast one Special Character required')
                .required('Please enter a password'),
            confirmNewPassword: Yup.string()
                .min(6, 'Your password must be atleast 6 digits')
                .required('Please re-enter password')
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        }),
        onSubmit: (values, actions) => {

            const data = {
                old_password: values.existingPassword,
                new_password: values.newPassword
            }

            post('setting_change_password', data).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    toast.success('Successfully changed Password.')
                } else {
                    toast.error(status.msg);
                }
            }).catch(err => {actions.setSubmitting(false);})
        },
    });
    return (
        <>
         
           <div className='change-password mt-lg-5 px-5 py-3 rounded-2'>
          
          <form onSubmit={formik.handleSubmit} >
              <div className='row'>
                  <div className='col-md-12'>
                  <div className="d-flex align-items-center mb-20">
              <Text type="H3" text="Change Password" className="font-weight-500 mr-24 mb-0" />
          </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12 col-md-4">
                      <div className="">
                          <AccountPassword
                              tertiary
                              label="Existing Password"
                              type={showExistingPassword ? 'text' : 'password'}
                              name="existingPassword"
                              placeholder=""
                              value={formik.values.existingPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              mandatory
                              errormessage={formik.touched.existingPassword && formik.errors.existingPassword ? (
                                  formik.errors.existingPassword
                              ) : null}
                              icon={
                                  <span onClick={ShowExistingPasswordOnClick}><EyeIcon visible={showExistingPassword} /></span>
                              }
                          />
                      </div>
                  </div>
              
                  <div className="col-12 col-md-4">
                      <div className="">
                          <AccountPassword
                              tertiary
                              label="New Password"
                              type={showNewPassword ? 'text' : 'password'}
                              name="newPassword"
                              placeholder=""
                              value={formik.values.newPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              mandatory
                              errormessage={formik.touched.newPassword && formik.errors.newPassword ? (
                                  formik.errors.newPassword
                              ) : null}
                              icon={
                                  <span onClick={ShowNewPasswordOnClick}><EyeIcon visible={showNewPassword} /></span>
                              }
                          />
                      </div>
                  </div>
                  
                  <div className="col-12 col-md-4">
                      <div className="">
                          <AccountPassword
                              tertiary
                              label="Confirm New Password"
                              type={showConfirmNewPassword ? 'text' : 'password'}
                              name="confirmNewPassword"
                              placeholder="password "
                              value={formik.values.confirmNewPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              mandatory
                              errormessage={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                                  formik.errors.confirmNewPassword
                              ) : null}
                              icon={
                                  <span onClick={ShowConfirmNewPasswordOnClick}><EyeIcon visible={showConfirmNewPassword} /></span>
                              }
                          />
                      </div>
                  </div>
              </div>
              <div className='row mt-4'>
                  <div className='col-md-12'>
                  <div className="action-buttons-end my-1">
                  <Button
                      light
                      type="button"
                      text="Cancel"
                  />
                  <Button
                      type="submit"
                      text="Save"
                      className="btn-save ml-3"
                  />
              </div>
                  </div>
              
              </div>
          </form>
        </div>
        </>
    );
};

export default AccountView;

