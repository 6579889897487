import React from 'react';
import Input from '../input';
import Select from '../input/select'
import Button from '../button'
import SearchIcon from '../svg/searchIcon';
import './search.scss'
import {GURU, TOPICS} from "../../constants/app.constants";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";

const Search = props => {
    const {text} = props
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const searchInputValue = searchParams.get('value')


    const formik = useFormik({
        initialValues: {
            searchId: id ? id : TOPICS,
            searchValue: searchInputValue ? searchInputValue : '',
        },
        validationSchema: Yup.object({
            searchId: Yup.string('Please enter a valid username'),
            searchValue: Yup.string('Please enter a valid email address'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            navigate('/trainee/search?id=' + values.searchId + '&value=' + values.searchValue)

        },
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <div
                className={`search-wrapper`}
            >
               
                <Input
                    type="text"
                    label="Topic Search"
                    placeholder="Search for any topics..."
                    name="searchValue"
                    value={formik.values.searchValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errormessage={formik.touched.searchValue && formik.errors.searchValue ? (
                        formik.errors.searchValue
                    ) : null}
                />
                <Button
                    link
                    text={<SearchIcon/>}
                    type="submit"
                />
            </div>
        </form>
    );
};

export default Search;

Search.defaultProps = {
    src: "https://picsum.photos/48",
    alt: "Avatar",
}
