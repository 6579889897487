import React from "react";
import Layout from "../../components/layout";
import Loader from "../../components/loader";
import useHome from "../Home/hooks/home.hook";
import PrcingPageView from "./views/pricingPageView";



const PricingPage = (props) => {
    const homeHook = useHome()
    if (homeHook?.loading) {
        return (
            <>
                <Layout {...props}>
                    <Loader/>
                </Layout>
            </>
        )
    } else {
        return (
            <>
                <Layout {...props}>
                    <PrcingPageView />
                </Layout>
            </>
        )
    }
}

export default PricingPage;
