import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import LayoutGuru from '../../../components/layoutGuru';
import Loader from '../../../components/loader';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import useHome from '../../Home/hooks/home.hook';
import TopicPageView from './views/topicPageView';


const TopicPage = (props) => {

  const homeHook = useHome()
  const {courseId,moduleId} = useParams();
    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'guru_course_topic_list';
        DEFAULT_FILTERS.moduleid = moduleId;
        commonHook.getList(DEFAULT_FILTERS)
    },[])

    if (commonHook?.loading) {
      return (
          <>
             <LayoutGuru {...props}>
              <Loader />
              </LayoutGuru>
          </>
      )
  } else {

  return (
    <div>
        <LayoutGuru {...props}>
      <TopicPageView {...props} {...commonHook} {...homeHook}/>
      </LayoutGuru>
    </div>
  )
}
}

export default TopicPage;
