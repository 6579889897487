import React,{useEffect, useState} from 'react'
import { DEFAULT_FILTERS, TEMP_DEFAULT_FILTERS } from '../../../../constants/app.constants';
import Loader from '../../../../components/loader';
import useGuruUploadVideo from '../../uploadVideo/hooks/guruUploadVideo.hook';
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import LayoutGuru from '../../../../components/layoutGuru';
import TotalMinutesPageView from './views/totalMinutesPageView';



const TotalMinutes =(props)=>{
  const guruUploadVideoHook = useGuruUploadVideo();
  const commonHook = useGetTableList();
  
  useEffect(()=>{
      commonHook.setKeys();
      TEMP_DEFAULT_FILTERS.uri = 'fetchInstructorTotalPaidMiuntes';
      TEMP_DEFAULT_FILTERS.type = 1;
      TEMP_DEFAULT_FILTERS.pageType = 1;

      commonHook.getList(TEMP_DEFAULT_FILTERS)
      
  },[])



  if (commonHook?.loading) {
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
} else {
  return (
    <>
    <LayoutGuru {...props}>
    <TotalMinutesPageView  {...props}  {...commonHook}  />
        
    </LayoutGuru>
    </>
)
}
  
}

export default TotalMinutes;
