import React from 'react';
import Flex from "../flex";
import Text from "../text";
import Button from "../button";
import Modal from "../modal";
import {useNavigate} from "react-router-dom";
import { BsCartPlusFill } from "react-icons/bs";


const GlobalInfoModal = props => {
    const navigate = useNavigate()
    const closeModal = () => {
        props.setIsModal(false)
        if (props.navigateBack) {
            navigate(-1)
        }
    }
    const onClickOuterClose = () => {
        if (props.outerClose) {
            props.setIsModal(false)
        }
    }
    const goback =()=>{
        navigate('/trainee/dashboard')
    }

    return (
        
        <Modal
        
            onClose={closeModal}
            onOuterClose={onClickOuterClose}
             light
            modalSmall={props?.smallModal}
            canClose={props.canClose}
            modalContent={
               
                <Flex
                    flexColumn
                    alignItemsCenter
                    justifyContent="center"
                >
                    <Text
                        type="H2"
                        className="text-center mb-10"
                        text="Subscribe Any One Categeory"
                    />
                    
                        <Text
                            type="PARAGRAPH"
                            className="text-center mb-10 "
                            text="Please Purchase any one Categeory to enjoy learning and earn Affiliate Income."
                        />
                    
                         <div className='row mt-3'>
                            <div className='col-md-4'>
                            <Button
                                type="button"
                                light large outline
                                text="Cancel"
                                isSubmitting={props.isSubmitting}
                                onClick={goback}
                            />
                            </div>
                            <div className='col-md-8'>
                            <Button
                                type="button"
                                gray  large
                                text="Purchase Now"
                                isSubmitting={props.isSubmitting}
                                onClick={props?.onClick}
                            />
                            </div>
                         </div> 
                       </Flex> 
                       
                       
                
            }
        />
        
    );
    
};

export default GlobalInfoModal;
GlobalInfoModal.defaultProps = {
    navigateBack: false,
    outerClose: false,
    title: '',
    subtitle: '',
    buttonText: '',
    buttonType: '',
    smallModal: false
}


