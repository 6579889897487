import React from 'react';

const IDCardIcon = props => {
    return (
        <>
            <svg width={props.width} height={props.height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2.77539V12.7754C1 13.88 1.89543 14.7754 3 14.7754H17C18.1046 14.7754 19 13.88 19 12.7754V2.77539C19 1.67082 18.1046 0.775391 17 0.775391H3C1.89543 0.775391 1 1.67082 1 2.77539Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13 5.77539H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M13 8.77539H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5.77539C9 6.87996 8.10457 7.77539 7 7.77539C5.89543 7.77539 5 6.87996 5 5.77539C5 4.67082 5.89543 3.77539 7 3.77539C7.53043 3.77539 8.03914 3.9861 8.41421 4.36118C8.78929 4.73625 9 5.24496 9 5.77539Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 11.7753C5.783 8.63834 9.052 9.62434 11 11.7753" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
        </>
    );
};

export default IDCardIcon;

IDCardIcon.defaultProps = {
    width: '20',
    height: '16'
}