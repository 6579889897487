import React from 'react';
import SubscriptionFreemium from '../../../../../components/trainee/subscriptionFreemium';
import SubscriptionPremium from '../../../../../components/trainee/subscriptionPremium';
import Text from '../../../../../components/text';

const SubscriptionView = props => {
    if (props?.isPremium) {
        return (
            <SubscriptionPremium {...props} />
        )
    } else {
        return ( 
            <SubscriptionFreemium {...props} />
        )
    }
}

const MySubscriptionView = (props) => {
    return (
        <>
            <Text
                type="H1"
                text="Member Subscription"
            />
            <SubscriptionView {...props} />
        </>
    );
};

export default MySubscriptionView;
