import React from 'react'
import PlayIcon from '../svg/playIcon'
import CourseImg from '../../assets/img/logo.svg';
import Icon from '../icon';
import Button from '../button';

import { useNavigate } from 'react-router-dom';
import Text from '../text';
import Flex from '../flex';
import { GURU } from '../../constants/app.constants';
import { useSelector } from 'react-redux';
import useHelper from '../../hooks/helpers.hook';
import Image from '../image';

const LineModule = (item) => {
  const navigate = useNavigate();
  const userInfo = useSelector(state => state?.app?.userInfo);
  const {secondsToMinutes} = useHelper()
  return (
    <div>
       <div className='row d-flex flex-row py-4 border-bottom'>
        <div className='col-md-3'>

        <Image src={item.thumbnail} alt="module-image" className='img-fluid module-img-1' />      

         
         <div className='play-icon-module'>
     
         {userInfo?.usertypeid != GURU &&(
         <Button
	        	link
            text={
              <Icon icon={<PlayIcon />}/>
            }
         
            onClick={() => {
              console.log("111")
              navigate('/trainee/my-learning/video-details/' + item.videoid);
      
            }}
            type="button"
	       />
         )}

        {userInfo?.usertypeid == GURU &&(
              <Button
                  link
                  text={
                    <Icon icon={<PlayIcon />}/>
                  }
              
                  onClick={() => {
                    console.log("item",item)
                    navigate('/instructor/course-video-details/' + item.videoid);
            
                  }}
                  type="button"
              />
         )}
       
         </div>  
         </div>
         <div className='col-md-9'>
          <h3 className='fs-5'>{item.title}</h3>
          <p> {item.des}</p>

          <Flex justifyContent="start">
                        <Text
                            type="SPAN"
                            text={secondsToMinutes(item.videoduration)}
                            className="watch-next-video-item-duration"
                        />
                    </Flex>
       
          </div>
         </div>
    </div>
  )
}

export default LineModule
