import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyReportView from "./view/WalletView";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import Loader from "../../../../components/loader";
import useGetTableList from "../../../../hooks/getTableListCommonHook";
import {DEFAULTFILTERS} from "../../../../constants/app.constants";
import WalletView from "./view/WalletView";
import useVideosCommonHooks from "../../../instructor/createCourse/videosCommonHook";
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js'
import CheckoutForm from '../../stripe/checkoutForm';


const AddToWallet = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const commonHook = useVideosCommonHooks();
    const [walletAmount,setWalletAmount] = useState(0);
    const [reloadPage,setReloadPage] = useState(0);
    const [getPriceData,setPriceData] = useState([]);

    const [priceId,setPriceId] =useState({});


    useEffect(()=>{
        commonHook.setkeys();
        DEFAULTFILTERS.uri = 'GetWalletDetails';
        DEFAULTFILTERS.type = 0;
        commonHook.getList(DEFAULTFILTERS)
    },[reloadPage]);

    useEffect(()=>{
        console.log("commonHook.tableData?.customdata?.length",commonHook.tableData?.customdata?.length,process.env.REACT_APP_PAYMENTGATEWAYKEY)
        if(commonHook.tableData?.customdata?.priceList.length >=1){

                setWalletAmount(commonHook.tableData?.customdata.balance[0].WalletAmount)
                setPriceData(commonHook.tableData?.customdata.priceList)
                setPriceId(commonHook.tableData?.customdata.priceList[0])
    
        }
    },[commonHook.loading]);

    


    if (commonHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel>
                <WalletView {...props} {...commonHook} walletAmount={walletAmount} setReloadPage={setReloadPage} getPriceData={getPriceData} priceId={priceId }setPriceId={setPriceId}/>
            </LayoutPanel>
        </>
    )
}

export default AddToWallet;
