import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate, useParams} from "react-router-dom";

import {useSelector} from "react-redux";
import ChatSwitchButton from '../../../../components/ChatswitchButton';
import DashboardBoxes from '../../../../components/dashboardBoxes';
import { FREEMIUM } from '../../../../constants/app.constants';
import useNewCommonHook from '../../../../hooks/newCommonHook';

const FavouriteDashboardView = (props) => {
    
    const [dashboardDetails,setDashboardDetails] = useState({});
    const {kFormatter, getCurrentMonthRange} = useHelper()
    const navigate = useNavigate()

    const commonHook = useNewCommonHook();


        
    useEffect(() => {

        commonHook._promiseService({"uri":"trainerFavouriteDashboard"}).then((data) => {
          //  console.log("data-->",data.data.data[0])
            if(data.data.status.result == 200){
                setDashboardDetails(data.data.data[0]);
            }
        
          });
      
    }, [])

    
 

  


    return (
        <>
            
            <div className="row course-main-box"  >

                  <div className="row all-main-box"  style={ {marginLeft: '0px',padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                       

                          <div className="col-12 col-md-3">
                          <DashboardBoxes
                                icon={0}
                                  title="Favourite Courses"
                                  number={kFormatter(dashboardDetails?.favouriteCourses)}
                                  onClick={() => {
                                    navigate('/trainee/favourite/favourite-course')
                                  }}
                                  {...props}
                              />
                          </div>



                          <div className="col-12 col-md-3">
                                            <DashboardBoxes
                                            icon={0}
                                                title="Liked Courses"
                                                number={kFormatter(dashboardDetails?.totalLikedCourse)}
                                                onClick={() => {
                                                    navigate('/trainee/favourite/liked-course')
                                                }}
                                                {...props}
                                            />
                                </div>

                                <div className="col-12 col-md-3">
                                            <DashboardBoxes
                                            icon={0}
                                                title="Liked Videos"
                                                number={kFormatter(dashboardDetails?.totalLikedVideos)}
                                                onClick={() => {
                                                    navigate('/trainee/favourite/liked-videos')
                                                }}
                                                {...props}
                                            />
                                </div>
                      


                          <div className="col-12 col-md-3">
                                            <DashboardBoxes
                                            icon={0}
                                                title="Favourite Instructors"
                                                number={kFormatter(dashboardDetails?.favouriteInstructors)}
                                                onClick={() => {
                                                    navigate('/trainee/favourite/favourite-instructor')
                                                }}
                                                {...props}
                                            />
                                </div>





                         
                                


                          <div className="col-12 col-md-3 mt-4">
                          <DashboardBoxes
                                icon={0}
                                  title="Complainted About Videos"
                                  number={dashboardDetails?.todayAddedCourseCount}
                                  onClick={() => {
                                      navigate('/trainee/courses/today-added-courses')
                                  }}
                                  {...props}
                              />
                          </div>
                        


                    {/*

                    <div className="col-12 col-md-3 mt-20">
                    <DashboardBoxes
                                icon={0}
                                        title="Trending Topic"
                                        buttonTitle = "View Details"
                                        number={0 }
                                        onClick={() => {
                                            navigate('/trainee/courses/trending-topics')
                                        }}
                                        {...props}
                                    />
                    </div>
                    */}

                 
                      </div>            
                      </div>    
                    

                      
   
         
  
  
             
            {/*props.titlesObj.showDashboard == 1 &&(
                  
            )

            */}
          


       
           

                                 


                    
       
        </>
    );
};


export default FavouriteDashboardView;
