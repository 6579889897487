import React from "react";
import Button from "../button";
import Image from "../image";
import Text from "../text";
import './magazineCard.scss';

const MagazineCard = props => {
    return(
        <div className="magazine-card">
            <Image
                src={props.src}
                alt={props.alt}
            />
            <Text
                type="PARAGRAPH"
                text={props.date}
                className="magazine-date"
            />
            <Text
                type="H3"
                text={props.title}
                className="magazine-title"
            />
            <a href={props.file} target="_blank" className="btn  btn-primary btn-outline btn-large">
                Download
            </a>
        </div>
    )
}

export default MagazineCard;
