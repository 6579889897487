import React from 'react';

const TwitterIcon = props => {
    return (
        <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33 3.31076C31.7874 3.87655 30.483 4.25407 29.1072 4.43269C30.506 3.55793 31.582 2.16276 32.0871 0.514621C30.7782 1.32276 29.3281 1.91655 27.7855 2.22938C26.5536 0.853517 24.7965 0 22.8497 0C19.11 0 16.0788 3.16497 16.0788 7.06759C16.0788 7.61986 16.1402 8.15765 16.2557 8.68C10.6297 8.38262 5.63978 5.56524 2.30156 1.28993C1.71494 2.33462 1.38497 3.55793 1.38497 4.84883C1.38497 7.30221 2.57745 9.46207 4.39597 10.7346C3.28598 10.6989 2.24106 10.3735 1.32631 9.85214C1.32631 9.87338 1.32631 9.90524 1.32631 9.93517C1.32631 13.3637 3.66086 16.2188 6.75527 16.8686C6.18973 17.0317 5.59212 17.1225 4.97617 17.1225C4.53895 17.1225 4.11274 17.0703 3.70028 16.9921C4.56187 19.7941 7.06141 21.8419 10.0229 21.9028C7.70577 23.7952 4.78735 24.9287 1.61412 24.9287C1.06599 24.9287 0.529789 24.8959 0 24.8283C2.99817 26.8279 6.5582 28 10.3795 28C22.8313 28 29.6434 17.2287 29.6434 7.88441C29.6434 7.57834 29.6343 7.27324 29.6205 6.972C30.9496 5.98331 32.0953 4.73586 33 3.31076Z" fill="#03A9F4"/>
        </svg>
    );
};

export default TwitterIcon;