import React,{useEffect} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import ContentUploadPageView from './views/moduleCardEditView';
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import Loader from "../../../components/loader";
import ModuleCardEditView from './views/moduleCardEditView';


const ModuleCardEdit = (props) => {
    const guruUploadVideoHook = useGuruUploadVideo()
  useEffect(()=>{
      guruUploadVideoHook.getVideoDetailsById().then(()=>{
          guruUploadVideoHook.getDropDowns()
      })
  },[])

  if(guruUploadVideoHook.loading) {
      return (
          <>
              <LayoutGuru {...props} noBgContent="true">
                  <Loader />
              </LayoutGuru>
          </>
      )
  }
  return (
    
        <LayoutGuru {...props}>
           <ModuleCardEditView {...props} guruUploadVideoHook={guruUploadVideoHook}/>
        </LayoutGuru>
  )
}

export default ModuleCardEdit;
