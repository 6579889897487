import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useState} from "react";
import useHttpHandler from "../../../../hooks/httphandler.hook";

const useAddressInformation = () => {
    const [loading, setLoading] = useState(true)
    const [dropdownsData, setDropdownsData] = useState(null)
    const [countries, setCountries] = useState(null)
    const [states, setStates] = useState(null)
    const [cities, setCities] = useState(null)
    const {post} = useHttpHandler()
    const navigate = useNavigate()

    const goBackToAddressInformation = () => {
        navigate('/trainee/personal-information')
    }
    const goToBankDetails = () => {
        navigate('/trainee/bank-details')
    }
    const goToWorkExperience = () => {
        navigate('/instructor/work-experience')

    }

    const getCountries = () => {
        post('getCountries').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                let countryOptions = []
                data?.map((item, index) => {
                    countryOptions.push({value: item.countryid, label: item.countrytitle})
                })
                setCountries(countryOptions)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {setLoading(false);})
    }

    const getCitiesByState = (stateId) => {
        post('cities_getbystateid', {state_id: stateId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                let cityOptions = []
                data?.map((item, index) => {
                    cityOptions.push({value: item.cityid, label: item.city})
                })
                setCities(cityOptions)
            } else {
                //toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {setLoading(false);})
    }

    const getStatesByCountry = (countryId) => {
        post('states_getbycountryid').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                let stateOptions = []
                data?.map((item, index) => {
                    stateOptions.push({value: item.stateid, label: item.statetitle})
                })
                setStates(stateOptions)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {setLoading(false);})
    }


    const updateAddressInfo = (data) => {}

    return {
        goBackToAddressInformation,
        goToBankDetails,
        dropdownsData,
        loading,
        updateAddressInfo,
        countries,
        states,
        cities,
        getCountries,
        getCitiesByState,
        getStatesByCountry,
        setLoading

}

}
export default useAddressInformation


