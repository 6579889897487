import React from "react";
import { ProgressBar } from "react-bootstrap";
import InstructorImg from '../../../../../assets/img/logo.svg';
import { BiUserCircle } from "react-icons/bi";
import { FaPlay } from "react-icons/fa";
import '../../instructor.css';


const InstructorDashboardpageView =()=>{
    const now = 0;
    return(
        <>
         <div className="page-bg">
            <div className="instructor-bg-details">
            <div className="container">
                <div className="row d-flex align-item-center p-3">
                   <div className="col-md-4">
                     <img src={InstructorImg} className="img-fluid instructor-img" alt="instructor-image" />
                   </div>
                   <div className="col-md-4 mt-5">
                    <h3 className="text-white font-weight-bold ">Sara Course #1 Template</h3>
                    <p className="text-white font-weight-medium">Here's is what you will learn</p>
                    <button className="btn btn-start">start Course</button>
                   </div>
                   <div className="col-md-4">
                    
                   </div>
                </div>
                </div>
                </div>
                <div className="container">
                <div className="row align-item-center justify-content-center p-5">
                    <div className="col-md-8">
                      <div className="card p-3 instructor-card">
                        <h2 className="font-weight-bold">Week 1</h2>
                        <p>Various educators teach rules governing the length of paragraphs. They may say that a paragraph should be 100 to 200 words long, or be no more than five or six sentences.</p>
                          <div className="card-body">
                            <div>
                            <h4 className="font-weight-bold">Module 1</h4>
                            <p>Various educators teach rules governing the length of paragraphs.</p>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                     <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 1</h5>
                                </div>
                                <div className="col-12 d-flex mb-4">
                                <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 2</h5>
                                </div>
                                <div className="col-12 d-flex mb-4">
                                <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 3</h5>
                                </div>
                            </div>
                            </div>
                            <div>
                            <h4 className="font-weight-bold">Module 2</h4>
                            <p>Various educators teach rules governing the length of paragraphs.</p>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 4</h5>
                                </div>
                                <div className="col-12 d-flex mb-4">
                                <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 5</h5>
                                </div>
                                <div className="col-12 d-flex mb-4">
                                <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 6</h5>
                                </div>
                            </div>
                            </div>
                            <div>
                            <h4 className="font-weight-bold">Module 3</h4>
                            <p>Various educators teach rules governing the length of paragraphs.</p>
                            <div className="row">
                                <div className="col-12 d-flex mb-4">
                                <img src={InstructorImg} className="img-fluid module-img" alt="course-details" />
                                     <FaPlay size={'25px'} className="module-playicon"/>
                                     <h5 className="module-title">Lesson 7</h5>
                                </div>
                                
                            </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                       <div className="card p-4 mb-2 instructor-card">
                        <h5 className="font-weight-bold">0 of 36 Lessons Completed</h5>
                       <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                       </div>
                       <div className="card p-3 instructor-card">
                        <h5>Instructor</h5>
                       <div className="card-body d-flex">
                           <BiUserCircle size={'45px'} className="user-icon"/>
                           <div className="user-details">
                            <h5>Instructor Name</h5>
                            <p className="instructor-title">Instructor</p>
                           </div>
                        </div>
                    </div>
                    </div>
                
                </div>
            </div>

         </div>
        
        </>
    )
}

export default InstructorDashboardpageView;