import React from "react";
import PageHeader from "../../../components/pageHeader";
import PricingTable from "../../../components/PricingTables";


const PrcingPageView =()=>{
    return(
        <>
        <PageHeader 
         title={"Pricing"}
         page={"Pricing"}
        />
        <PricingTable />
        
        </>
    )
}


export default PrcingPageView;