import React, {useEffect} from 'react';
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import EyeIcon from "../../../../components/svg/EyeIcon";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import FacebookIcon from "../../../../components/svg/facebookIcon";
import GoogleIcon from "../../../../components/svg/googleIcon";
import AppleIcon from "../../../../components/svg/appleIcon";
import {useFormik} from "formik";
import * as Yup from "yup";
import useLogin from "../hooks/login.hook";
import { useNavigate } from 'react-router-dom';
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import logoImg from '../../../../assets/userimg/login-bg2.png';
import PlayIcon from '../../../../components/svg/playIcon';
import Icon from '../../../../components/icon';
import instructorSignUp from '../../../../assets/img/instructorSignUp.png';
import learnerSignUp from '../../../../assets/img/learnerSignUp.png';

import { IoHome,IoShareSocial } from "react-icons/io5";



const RegisterView = (props) => {
    const {redirectTo, setUserSessionInfo, signInWithGoogle, signInWithFacebook} = props
    const {post} = useHttpHandler()
   
    const navigate = useNavigate();
    

    return (
        <>
<div className='login'>
        <main>
   <div class="home-login">

     <section class="section">
       <div class="container">
        <div className='row'>
            <div className='col-12'>
            <div class="feature-box-icon">
            

            <Button
                              link
                              text={
                                <IoHome className="text-color-light fs-2 " /> 
                              }
                              onClick={() => {
                                //navigate("/trainee/signup")
                                navigate("/");
                              }}
                              type="button"
                            />
        </div> 
                  </div>
            </div>
        

   
      
                            <div className='branding-logo-register-view text-center'>
                                <img src={logoImg} className='img-fluid' alt='logo' />
                            </div>
                    
   
            <div className='sign-form '>
            <div class="row justify-content-center"> 

        
                    
                    <div className="col-md-3 text-center "  >
                        <div className=' padding-10 ' >
                        <Button
                            link
                            text={
                                <img src={learnerSignUp}  alt="copy" className='icon-175' />
                            }
                            onClick={()=>{
                                //navigate("/trainee/signup")
                                navigate("/join-waiting-list");
                            }}
                            type="button"
                            
                        />
                        </div>  
                        <div className='justify-content-center'>
                        <Button
                        className="btn-primary-dashboard"
                            text="Register AS A LEARNER"
                            onClick={()=>{
                                //navigate("/trainee/signup")
                                navigate("/join-waiting-list");
                            }}
                            
                            type="button"
                        />
                        </div>                      
                    </div>
                    
    
                    <div className="col-md-3 text-center"  >
    
    
                    <div className='padding-10' >
                    <Button
                        link
                        text={
                            <img src={instructorSignUp}  alt="copy" className='icon-175' />
                        }
                        
                        onClick={()=>{
                            navigate("/instructor/signup")
                            
                        
                        }}
                        type="button"
                        />
                        </div>
                        <div className='justify-content-center'>
                    <Button
                        className="btn-primary-dashboard"
                        text="Register AS AN INSTRUCTOR"
                        onClick={()=>{
                            navigate("/instructor/signup")
                        }}
                        type="button"
                    />
                    </div>
                    </div>                                 
                        
             </div>
</div>
       </div>

     </section>

   </div>
 </main>
 </div>
        </>
    );
};

export default RegisterView;

