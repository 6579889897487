import React, {useState} from 'react';
import Flex from '../../../../components/flex';
import Text from '../../../../components/text';
import PaginationTotal from '../../../../components/pagination';
import Breadcrumbs from '../../../../components/breadcrumbs';
import Button from '../../../../components/button';
import TopicVideoCard from '../../../../components/topicVideoCard';
import topicVideoPoster from "../../../../assets/images/topic-video-poster.jpg";
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";
import {useNavigate} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Pagination} from "swiper";
import "swiper/css/pagination";
import Section from '../../../../components/section';
import CategoryButtons from "../../../../components/categoryButtons";
import {DEFAULT_FILTERS} from "../../../../constants/app.constants";
import FavGuruImage from "../../../../assets/img/logo.svg";
import useHelper from "../../../../hooks/helpers.hook";

const breadcrumbList = [
    {
        text: 'Main page',
        link: '/trainee/dashboard',
    },
    {
        text: 'Topics',
        link: '/trainee/search-topics',
    },
    {
        text: 'Budgeting',
        link: '/trainee/search-topics',
    },
]

const SearchTopicsView = (props) => {
    const {getShortDescription} = useHelper()
    const tableData = props?.tableData?.listData
    const navigate = useNavigate()
    const {text} =props
    const [allTopicsItem, setAllTopicsItem] = useState(true);

    if (tableData?.length > 0) {
        if (props?.selectedCategory?.catId == 0) {
            return (
                <>
                    {tableData?.map((catitem, index) => {
                            const topicImage = catitem?.pic ? process.env.REACT_APP_IMG_URL + catitem?.pic : FavGuruImage
                            if (catitem?.topic_data?.length > 0) {
                                return (
                                    <Section className="pt-0 overflow-outer rml-24">
                                        <div className="section-head">
                                            <Text
                                                type="H3"
                                                text={catitem?.category}
                                                className="section-title text-uppercase"
                                            />
                                            <Flex justifyContent="end">
                                                <Button
                                                    linkPrimary
                                                    text="View all"
                                                    onClick={()=>{navigate('/trainee/learn-by-topics?'+'catId='+catitem?.videocategoryid + '&catName='+catitem?.category)}}
                                                />
                                            </Flex>
                                        </div>
                                        <div className="slider-main max-slider">
                                            <Swiper
                                                spaceBetween={14}
                                                slidesPerView={1}
                                                modules={[Pagination]}
                                                pagination={true}
                                                breakpoints={{
                                                    640: {
                                                        slidesPerView: 1,
                                                        pagination: true,
                                                    },
                                                    768: {
                                                        slidesPerView: 3,
                                                        pagination: true,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3,
                                                        pagination: false,
                                                    },
                                                }}
                                            >

                                                {catitem?.topic_data?.map((item, index) => {
                                                    const topicImage = item?.thumbnail ? process.env.REACT_APP_IMG_URL + item?.thumbnail : FavGuruImage
                                                    const avatarImage = item?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + item?.uploaded_by_pic : AvatarPic
                                                    return (

                                                        <SwiperSlide>
                                                            <TopicVideoCard
                                                                secondary
                                                                videoPoster={topicImage}
                                                                duration={item.videoduration}
                                                                watchedupto={item?.watchedupto}
                                                                avatarSrc={avatarImage}
                                                                title={item.title}
                                                                avatarName={item.uploaded_by_name}
                                                                by={item.uploaded_by_name}
                                                                shortDescription={getShortDescription(item?.des)}
                                                                views={item?.ViewsCount}
                                                                imgWidth="320"
                                                                imgHeight="233"
                                                                onClick={() => {
                                                                    navigate('/trainee/video-details/' + item.videoid)
                                                                }}
                                                            />
                                                        </SwiperSlide>

                                                    )
                                                })}

                                            </Swiper>
                                        </div>
                                    </Section>

                                )
                            }

                        }
                    )}
                </>
            )

        } else {
            return (
                <>
                    <Text
                        type="H2"
                        className="mb-0"
                        text={text?.SEARCH_RESULT_FOR + ' ' + props.searchKeyword}
                    />
                    <Text
                        type="PARAGRAPH"
                        className="text-white-half mb-4 mb-md-5"
                        text={`${tableData?.length} Videos has Been selected based on the keyword used.`}
                    />
                    <Flex justifyContent="start" alignItemsEnd className="mb-5">
                        <Text
                            type="H3"
                            className="mb-0 mr-2"
                            text={props?.selectedCategory?.catName}
                        />
                        <Text
                            type="H4"
                            className="text-primary mb-0"
                            text={`${tableData?.length} Videos Found`}
                        />
                    </Flex>
                    <div className="row">
                        {tableData?.map((item, index) => {
                            const topicImage = item?.thumbnail ? process.env.REACT_APP_IMG_URL + item?.thumbnail : FavGuruImage
                            const avatarImage = item?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + item?.uploaded_by_pic : AvatarPic
                            return (
                                <div className="col-6 col-md-3">
                                    <TopicVideoCard
                                        secondary
                                        videoPoster={topicImage}
                                        duration={item.videoduration}
                                        watchedupto={item?.watchedupto}
                                        avatarSrc={avatarImage}
                                        title={item.uploaded_by_name}
                                        avatarName={item.uploaded_by_name}
                                        by={item.uploaded_by_name}
                                        shortDescription={getShortDescription(item?.des)}
                                        views={item?.ViewsCount}
                                        imgWidth="320"
                                        imgHeight="233"
                                        onClick={() => {
                                            navigate('/trainee/video-details/' + item.videoid)
                                        }}
                                    />
                                </div>
                            )
                        })}

                    </div>


                    {/*<Pagination
                    totalPages={props?.tableData?.totalPages}
                    currentPage={props?.tableData?.currentPage}
                    totalRecords={props?.tableData?.totalRecords}
                    nextPage={props?.nextPage}
                    previousPage={props?.previousPage}
                    perPage={props?.filterKeys?.perpage}
                />*/}
                </>
            );
        }
    } else {
        return (
            <>
                <div className="search-no-found">
                    <Text type="H2" className="title" text={text?.SEARCH_NOT_FOUND}/>
                    <Text type="H3" text={text?.YOUR_SEARCH_DID_NOT_MATCH_ANY_RESULTS}/>
                </div>

            </>
        )
    }
};

export default SearchTopicsView;

