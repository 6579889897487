import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import './navbar.scss';
import { BiSearchAlt2,BiChevronDown } from "react-icons/bi";

const Navbar = props => {
    const {text, userInfo} = props
    const onClickScroll = (navItem)=>{
        const element = document.getElementById(navItem);
        element.scrollIntoView({behavior: 'smooth'});
    }
    const navigate = useNavigate()


    return (
        <>
           <nav className="navbar">
            {/*
           <div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#"> <input type="text"
                name="query" placeholder="Search" title="Enter search keyword" /> <button type="submit"
                title="Search"><BiSearchAlt2 color="#8081ff" /></button></form>
            </div>
                */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="nav-link">
                          <NavLink to={'/'} activeclassName='is-active' className="nav-text">{text?.MENU_HOME} </NavLink>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link">
                        <NavLink to={'/about-us'} activeclassName='is-active' className="nav-text"> {text?.MENU_ABOUT}</NavLink>
                        </div>
                    </li>
                 
                    <li className="nav-item">
                        <div className="nav-link">
                        <NavLink to={'/pricing-page'} activeclassName="is-active" className="nav-text">{text?.MENU_PRICING}</NavLink>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link">
                        <NavLink to={'/contact-us'} activeclassName="is-active" className="nav-text">{text?.MENU_CONTACT}</NavLink>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link">
                        <NavLink to={'/register'} activeclassName="is-active" className="nav-text">Register Now</NavLink>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar;
