import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/button'
import CourseCard from '../../../../components/courseCard'
import InstructorImage from '../../../../assets/img/logo.svg';
import Table from '../../../../components/table';
import Pagination from '../../../../components/pagination';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import Image from '../../../../components/image';
import { useMediaQuery } from 'react-responsive';
import useHelper from "../../../../hooks/helpers.hook";
import Status from "../../../../components/status";
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import LearnerCard from '../../../../components/traineeCard';
import TopicVideoCard from '../../../../components/topicVideoCard';





const InstructorCoursesPageView = (props) => {
    const {formatDateDDMMYY, getShortDescription} = useHelper()
	const {text} = props
	const {formatDateMMMDDYYYY} = useHelper()
	const [yearSelected, setYearSelected] = useState('Year 2022');
	const [monthSelected, setMonthSelected] = useState('Select Month');
	const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const navigate = useNavigate();
    const tableData = props?.tableData?.listData

    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Favourite Instructor",
            link:"/trainee/favourite-instructor",
        },
        {
            text:"Instructor Courses",
            link:"/trainee/instructor-courses",
        },
    ]



  return (
    
    <>
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
   
      <Text
        type="H1"
        text="Instructor Courses"
        className="mt-0 mb-30"
    />

                <Table
                thead={
                    <tr>
                       
                    </tr>
                }
                tbody={
                    <>
                    <div className='row'>
                        {tableData?.map(function (item, index) {
                            return (
                                <div className='col-md-4'>
                                <tr>
                                  <TopicVideoCard
                            poster={item.thumbnail}
                            categeoryname="categeoryname"
                            courseId={item.courseid}
                            categeorytitle={item.catName}
                            subcategeory="subcategeory"
                    subcategeorytitle={item.subCatName}
                            title={item.title}
                            desc={item.des}
                            heart="25"
                            imgWidth="320"
                            imgHeight="233"
                            onClick={() => {
                                navigate('/trainee/course-details/' + item.courseid)
                            }}
                            duration={item.videoduration}
                            watchedupto={item?.watchedupto}
                        />
                                  <br />
                                </tr>
                                </div>
                            )
                        })}
                        </div>
                       
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
	
    </>
  )
}

export default InstructorCoursesPageView
    

