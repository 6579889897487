import React, {useRef} from 'react';
import Text from '../../../../components/text';
import Loader from "../../../../components/loader";
import useHelper from "../../../../hooks/helpers.hook";
import TopicVideoCard from "../../../../components/topicVideoCard";
import topicVideoPoster from "../../../../assets/images/topic-video-poster.jpg";
import {useNavigate} from "react-router-dom";
import Paginations from '../../../../components/pagination';
import NavLearnerFavouriteGuruDetails from '../../../../components/navLearnerFavouriteGuruDetails';
import "swiper/css/pagination";
import DP from '../../../../assets/images/dp-100.png';
import FavouriteGuruUserInfo from '../../../../components/favouriteGuruUserInfo';
import FavouriteGuruSwiper from "../../favouriteInstructor/view/FavouriteGuruSwiper";




const FavouriteGuruDetailList = (props) => {
    const navigate = useNavigate()
    const {getShortDescription} = useHelper()

    const tableData = props?.tableData?.listData

    if (props.loading) {
        return <Loader/>
    } else {
        if (tableData && tableData.length > 0) {
            return (tableData?.map((item, index) => {
                const posterImage = item?.thumb_nail ? process.env.REACT_APP_IMG_URL + item?.thumb_nail : topicVideoPoster
                const avatarImage = item?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + item?.uploaded_by_pic : DP
                return (
                    <div className="col-12 col-md-3">
                        <TopicVideoCard
                            secondary
                            videoPoster={posterImage}
                            duration={item.videoduration}
                            avatarSrc={avatarImage}
                            avatarName={item?.title}
                            title={item?.title}
                            by={item?.uploaded_by_name}
                            shortDescription={getShortDescription(item?.des, 10)}
                            views={item?.ViewsCount}
                            imgWidth="320"
                            imgHeight="233"
                            onClick={() => {
                                navigate('/trainee/video-details/' + item.videoid)
                            }}
                        />
                    </div>
                )
            }))
        }
        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No videos available"/></div>
    }
}

const FavouriteGuruDetailsView = (props) => {
    const {kFormatter} = useHelper()
    const guruInfo = props?.tableData?.customdata
    const profileImage = guruInfo?.pic ? process.env.REACT_APP_IMG_URL + guruInfo?.pic : DP

    return (
        <>
            {guruInfo && (
                <FavouriteGuruUserInfo
                    avatar={profileImage}
                    fullname={guruInfo?.preffered_name ? guruInfo?.preffered_name : guruInfo?.fullname}
                    category={guruInfo?.expertise}
                    totalFavourited={kFormatter(guruInfo?.total_favourited)}
                    totalVideos={kFormatter(guruInfo?.total_videos_uploaded)}
                />
            )}
            <NavLearnerFavouriteGuruDetails/>
            <Text type="H3" className="mb-4" text="All Videos"/>
            <div className="row">
                <FavouriteGuruDetailList {...props} />
            </div>
            <Paginations
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props?.nextPage}
                previousPage={props?.previousPage}
                perPage={props?.filterKeys?.perpage}
            />
            <FavouriteGuruSwiper/>

        </>
    );
};

export default FavouriteGuruDetailsView;

