import React, {useEffect, useState} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Input from "../input";
import Text from "../text";
import Flex from "../flex";
import Button from "../button";
import {useNavigate} from "react-router-dom";
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import Loader from "../loader";
import Select from "../input/select";

const CreditDebitCard = (props) => {
    const [paymentInProcess, setPaymentInProcess] = useState(false)
    const {numbersOnly} = useLogin()
    const {generateInvoice, packages, text} = props
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            /*cardNumber: '',
            expirationDate: '',
            cvv: '',*/
            fullname: '',
            billingAddress: '',
            id_type: '',
            ic_passportno: '',
        },
        validationSchema: Yup.object({
            /*cardNumber: Yup.number().required('Please enter a valid card number'),
            expirationDate: Yup.string().required('Please enter a valid card number'),
            cvv: Yup.string().required('Please enter a valid card number'),*/
            fullname: Yup.string().required('Please enter name'),
            billingAddress: Yup.string().required(text?.PLEASE_ENTER_A_ADDRESS),
            ic_passportno: Yup.string().required('Please enter your Id'),
            id_type: Yup.string().required('Please select ID type'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)
            setPaymentInProcess(true)
            //navigate('/trainee/payment-success');
            generateInvoice(actions, values, packages)
        },
    });


    /*useEffect(() => {
        const interval = setInterval(() => {
            window.close()
        }, 10000);

        return () => clearInterval(interval);
    }, [paymentInProcess]);*/

    if (paymentInProcess) {
        return (
            <>
                <Loader message='' style={{minHeight: 0}}/>
                <Text type="H3" className="mb-48 text-center"
                      text="You will be redirected to payment gateway in a new window and you can close/exit this page"/>
            </>
        )
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {/*<div className="formBox">
                    <Input
                        label="Card Number"
                        type="text"
                        name="cardNumber"
                        maxlength="16"
                        placeholder="1234 1234 1234 1234"
                        value={formik.values.cardNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.cardNumber && formik.errors.cardNumber ? (
                            formik.errors.cardNumber
                        ) : null}
                    />
                </div>
                <div className="formBox">
                    <Input
                        label="Expiration Date"
                        type="date"
                        name="expirationDate"
                        placeholder="MM/YY"
                        value={formik.values.expirationDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.expirationDate && formik.errors.expirationDate ? (
                            formik.errors.expirationDate
                        ) : null}
                    />
                </div>
                <div className="formBox">
                    <Input
                        label="CVV"
                        type="password"
                        name="cvv"
                        placeholder="***"
                        value={formik.values.cvv}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={3}
                        errormessage={formik.touched.cvv && formik.errors.cvv ? (
                            formik.errors.cvv
                        ) : null}
                    />
                </div>*/}


                <>
                    <div className="formBox">
                        <Input
                            label={text?.NAME}
                            type="text"
                            name="fullname"
                            placeholder="Please enter your name"
                            value={formik.values.fullname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errormessage={formik.touched.fullname && formik.errors.fullname ? (
                                formik.errors.fullname
                            ) : null}
                        />
                    </div>
                    <div className="formBox">
                        <Input
                            label={text?.BILLING_ADDRESS}
                            type="text"
                            name="billingAddress"
                            placeholder="Please enter your address"
                            value={formik.values.billingAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errormessage={formik.touched.billingAddress && formik.errors.billingAddress ? (
                                formik.errors.billingAddress
                            ) : null}
                        />
                    </div>

                    <div className="formBox">
                        <Select
                            label="ID Type"
                            name="id_type"
                            value={formik.values.id_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            options={[
                                {value: 'PASSPORT_NUMBER', label: 'Passport Number'},
                                {value: 'NRIC', label: 'New IC Number'}
                            ]}
                            errormessage={formik.touched.id_type && formik.errors.id_type ? (
                                formik.errors.id_type
                            ) : null}
                        />
                    </div>

                    {formik.values.id_type && (
                        <div className="formBox">
                            <Input
                                label={formik.values.id_type == 'PASSPORT_NUMBER' ? 'Passport Number' : 'New IC Number'}
                                type="text"
                                name="ic_passportno"
                                placeholder="Please enter your Id"
                                value={formik.values.ic_passportno}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errormessage={formik.touched.ic_passportno && formik.errors.ic_passportno ? (
                                    formik.errors.ic_passportno
                                ) : null}
                            />
                        </div>
                    )}


                    <Text
                        type="PARAGRAPH"
                        text={text?.DISCLAIMER_PAYMENT}
                        className="mb-48"
                    />
                    <Flex justifyContent="center" className="mb-48">
                        <Button
                            type="submit"
                            text={text?.PAY + ' RM 99.00'}
                            large
                            isSubmitting={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                        />
                    </Flex>
                </>
            </form>
        </>
    )
}

export default CreditDebitCard;
