import React from "react";
import Text from "../text";
import './listStep.scss';

const ListStep = props => {
    return(
        <>
            <div className="list-step-group">
                <div className="list-step">
                    <Text type="H4" text="1" />
                    <Text type="PARAGRAPH" text="Invite friends" />
                </div>
                <div className="list-step">
                    <Text type="H4" text="2" />
                    <Text type="PARAGRAPH" text="Friends Register" />
                </div>
                <div className="list-step">
                    <Text type="H4" text="3" />
                    <Text type="PARAGRAPH" text="Get Affiliate income" />
                </div>
            </div>
        </>
    )
}

export default ListStep;