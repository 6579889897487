import React, {useEffect, useRef} from "react";
import VideoJs from "./videoJs";
import videojs from 'video.js';
import Text from "../text";
import Icon from "../icon";
import RorateIcon from "../svg/rorateIcon";
import Flex from "../flex";
import Button from "../button";
import {useMediaQuery} from "react-responsive";


const StaticVideoPlayer = props => {
    const {videofilename, videofiletype} = props.videoDetails
    const playerRef = useRef(null);

    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const videoSrc = videofilename ? process.env.REACT_APP_IMG_URL + videofilename : ''

    const videoJsOptions = {
        liveui: true,
        autoplay: props.autoplay,
        controls: true,
        responsive: true,
        muted: props.muted,
        fluid: true,
        sources: [{
            src: videoSrc,
            type: videofiletype
        }],
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });

        player.on("loadedmetadata", function () {
        });

        player.on("play", function () {
        });

        player.on("playing", function () {
        });
        player.on("ended", function () {
            props.videoEnded(true)
            document.exitFullscreen()

        });
        player.on("timeupdate", function () {
        });
        if (props.autoplay) {
            player.autoplay();
        }
    };

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.pause()
        }
        console.log(playerRef.current)
    }, [playerRef])


    return (
        <>
            <VideoJs options={videoJsOptions} onReady={handlePlayerReady} {...props}/>
            {props?.showVideoEnded && (
                <div className="video-replay-details">
                    <div className="video-replay-details-main">
                        <div
                            className="pointer"
                            onClick={() => {
                                props.videoEnded(false)
                                playerRef.current.play()
                            }}
                        >
                            <Icon
                                gray
                                size32={isMobile ? true : false}
                                icon={<RorateIcon/>}

                            />
                        </div>

                        <Text
                            type="H2"
                            className="video-replay-details-text-title"
                            text="Are you Ready to learn and Earn with COURSETSUNAMI?"
                        />
                        <Text
                            type="PARAGRAPH"
                            className="video-replay-details-text-desc"
                            text="Publish the educational video and control your own video contents"
                        />
                        <Flex justifyContent="center">
                            <Button
                                type="button"
                                large={isMobile ? false : true}
                                small={isMobile ? true : false}
                                text="Join US in COURSETSUNAMI now!"
                                onClick={props?.goToLearnerSignup}
                            />
                        </Flex>
                    </div>
                </div>
            )}

        </>
    );
}
export default StaticVideoPlayer;
StaticVideoPlayer.defaultProps = {
    autoplay: false,
    muted: false
}
