import React, {useEffect, useState} from "react";
import Text from "../text";
import './learnByTopics.scss';
import Flex from "../flex";
import Button from "../button";
import useLearnByTopics from "./hooks/learnByTopics.hook";
import useHelper from "../../hooks/helpers.hook";
import Loader from "../loader";
import topicVideoPoster from "../../assets/images/topic-video-poster.jpg";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import TopicVideoCard from "../topicVideoCard";
import TopicFlowCard from "../topic_flow_Card";
import FlowCard from "../flowCard";



const   VideosList = (props) => {

    const navigate = useNavigate()
    const {getShortDescription} = useHelper()
    const {videosListLoading, videosList} = props
    const {videoId} =useParams();

    if (videosListLoading) {
        return <Loader/>
    } else {
        if (videosList && videosList.length > 0) {
            return (videosList?.map((item, index) => {
                
                return (

                
        


                    <div className="col-12 col-md-4">
                   {/* <Card
                     poster={item.thumbnail}
                     categeoryname="categeoryname"
                    courseId={item.courseid}
                    categeorytitle={item.catName}
                    subcategeory="subcategeory"
                    subcategeorytitle={item.subCatName}
                    course="Course Name"
                    coursetitle={item.title}
                    module="Added Modules"
                    value={item.modules}
                    availablevideos="Available Videos"
                    valuevideo={item.avaliable_videos}
                    viewcontent="View Module"
                    viewcontent1="View Videos"
                    link={"/trainee/course-details/"+item.videoid}
                    
                    desc={item.des}
                    />
                */} 




                            <TopicVideoCard
                            poster={item.thumbnail}
                            categeoryname="categeoryname"
                            courseId={item.courseid}
                            categeorytitle={item.catName}
                            subcategeory="subcategeory"
                    subcategeorytitle={item.subCatName}
                            title={item.title}
                            desc={item.des}
                            heart="25"
                            imgWidth="320"
                            imgHeight="233"
                            onClick={() => {
                                navigate('/trainee/course-details/' + item.videoid)
                            }}
                            duration={item.videoduration}
                            watchedupto={item?.watchedupto}
                            avatarSrc={topicVideoPoster}
                        />
                        
                    </div>
                )
            }))
        }
        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No videos available"/></div>
    }
}


const LearnByTopics = (props) => {
    console.log("test_value-->",props?.extraParams);
    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get('catId')
    const categoryName = searchParams.get('catName')
    
    
    const learnByTopicsHook = useLearnByTopics(categoryId, categoryName)
    if(props?.extraParams?.type == "get_today_added_course"){
     //console.log("date->",useHelper.formatDateYYYYMMDD(new Date()));
        learnByTopicsHook.filterKeys.fromdate = props?.extraParams?.from_date
    }else{

    }
    
    
        const [active, setActive] = useState({catId: 0, catName: 'All topics'});
        const showLoadMore = learnByTopicsHook?.videosList && Object.keys(learnByTopicsHook?.videosList).length >= 2 && learnByTopicsHook?.videosApiData?.totalRecords > Object.keys(learnByTopicsHook?.videosList).length
       
  


    return (
        <>
                    <div className="row">
                        <VideosList {...learnByTopicsHook}/>
                        
                    </div>
               

                {showLoadMore && (
                    <Flex justifyContent="center">
                        <Button type="button" outline large text="Load More" onClick={() => {
                            learnByTopicsHook.setFilters(active.catId, learnByTopicsHook.filterKeys.page + 1)
                        }}/>
                    </Flex>
                )}
            
        </>
    )
}

export default LearnByTopics;
