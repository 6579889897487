import React from "react";
import SignupLayout from "../../../components/signuplayout";
import PaymentFailedView from "../paymentStatus/view/PaymentFailedView";

const PaymentFailed = props => {

    return (
        <>
            <SignupLayout {...props}>
                <PaymentFailedView {...props} />
            </SignupLayout>
        </>
    )
}

export default PaymentFailed;
