import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import Button from '../button'
import { NavLink, useNavigate } from "react-router-dom";
import ViewContent from "../viewContent";
import ProgressBarCard from "../programBarCard";
import TextHtmlParser from "../htmlparser";
import Text from "../text";
import Image from "../image";
import Icon from "../icon";
import PlayIcon from "../svg/playIcon";
import useHelper from "../../hooks/helpers.hook";
import ModuleImage from '../../assets/img/logo.svg';
import { BsArrowRight } from "react-icons/bs";


const TotalTopicSearchCard =(props)=>{
	const [showDescription, setShowDescription] = useState(!props.hideDescription)
    const {secondsToMinutes, toSeconds} = useHelper()
	const navigate = useNavigate();
	const duration = secondsToMinutes(props.duration)
    const watchedSeconds = toSeconds(props?.watchedupto)
    const barPercentage = props.duration ? (watchedSeconds / props.duration) * 100 : 0
	const {homeVideos} = props
  
	const showActive = () => {
		console.log("Active")
	  }

	  const showInActive = () => {
		console.log("InActive")
	  }


    return(
        <>
	<div class="container mb-3">
	<div class="col-xs-12 col-md-12 col-12 d-flex flex-wrap bg-light total_topic_search_height">
		<div class="position-relative col-12 col-md-3 lazyloaded">
		<div className="video-card">
		<div className="video-poster mt-2">

		<img
	src={process.env.REACT_APP_IMG_URL + props.poster}
	alt={props.alt}
	className="total_topic_search_card"
/>
	
{!props.noPlay &&
<div className="video-play">
	<Button
		link
		text={
			<Icon icon={<PlayIcon />}/>
		}
		onClick={props.onPlay}
		type="button"
	/>
</div>
}


</div>
		</div>
		</div>
		<div class="col-md-9">
		
			<div className="col-md-11"></div>
									<div class="col-lg-1 col-md-2 col-sm-12">
										<div class="top_search_edit">
										<h4 class="text-2 mb-3 text-center link_color"><NavLink to={props?.link} className="link_color"> {props?.edit} </NavLink> </h4>	
				                        <h4 class="text-2 text-center link_color"><NavLink to={props?.link} className="link_color"> {props?.delete} </NavLink> </h4>	
										</div>
									</div>	
		
			<div className="mt-2">
				<div className="col-md-12">
				<div className="d-flex top4 bg-subcolor flex-row align-item-center">
				<p class="mt-1 text-1 mr-3  text-center  font-weight-bold text-dark text-uppercase">{props.categeoryname}</p>
				<h4 class="mt-1 text-2 text-center text-color-secondary">: {props.categeorytitle}</h4>
				</div>
				</div>
			
				<div className="col-12 mb-4">
					<div className="d-flex bg-subcolor flex-row align-item-center">
				<p class="mt-1 text-1 mr-4 text-center font-weight-bold text-dark text-uppercase">{props.subcategeory}</p>
				<h4 class="mt-1 text-2  text-color-secondary">: {props.subcategeorytitle}</h4>
				</div>
			</div>
			<div class="col-6 mb-4">
					<div class="d-flex bg-subcolor align-items-center">
							<p class="mt-3 text-1 text-center font-weight-bold text-dark text-uppercase">{props.course}</p>&nbsp;&nbsp;&nbsp;&nbsp;
							<h4 class="mt-2 text-2 text-color-secondary">: {props.coursetitle}</h4>
					</div>
				</div>
				<div class="col-6 mb-4" >
					<div class="d-flex bg-subcolor align-items-center">
							<p class="mt-3 text-1 mr-3  text-center font-weight-bold text-dark text-uppercase">{props.modulename}</p>
							<h4 class="mb-0 text-2 text-center text-color-secondary">: {props.moduletitle}</h4>
						
					</div>
				</div>
                 </div>
				 <div className="row">
				 <div class="col-6 mb-3" >
					<div class="d-flex bg-subcolor align-items-center">
							<p class="mt-3 text-1 mr-3  text-center font-weight-bold text-dark text-uppercase">{props.module}</p>
							<h4 class="mb-0 text-2 text-center text-color-secondary"><NavLink to={props?.link}> {props.value} <BsArrowRight /></NavLink></h4>
						
					</div>
				</div>
			
				<div class="col-6 mb-3">
					<div class="d-flex bg-subcolor align-items-center">
								<p class="mt-3 text-1 mr-3 text-center font-weight-bold text-uppercase text-dark">{props.availablevideos} </p>
								<h4 class="mb-0 text-2 text-center text-color-secondary"><NavLink to={props?.link}> {props.valuevideo} <BsArrowRight /></NavLink> </h4>
					</div>
				</div>
				<div class="col-2">
				<div className="d-flex bg-subcolor align-items-center">
					<p class="mt-4 text-1 mr-3 text-center font-weight-bold text-uppercase text-dark">{props.status} </p>
								<h4 class="mt-3 text-2 text-center text-color-secondary">{props.active}</h4>
					</div>	
					</div>		
								<div className="col-md-2">
								<div className="d-flex bg-subcolor links_active align-items-center">
								<ViewContent title={props.inactive} onClick={showActive} link=""/>
								<ViewContent title={props.statusactive} onClick={showInActive} link=""/>
								</div>
								
								</div>
								
								
			
								
					
				</div>
			</div>
			</div> 
	</div>
        </>
    )
}


export default TotalTopicSearchCard;



