import React from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import VideoCard from '../../../../components/videocard';
import GuidelinePosterImg from '../../../../assets/img/logo.svg'
import GuidelineRightImg from '../../../../assets/img/logo.svg'
import PDFIcon from '../../../../assets/images/pdf-icon.png'
import Image from '../../../../components/image';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";
import StaticVideoPlayer from "../../../../components/videoPlayers/staticVideoPlayer";
import TextHtmlParser from "../../../../components/htmlparser";

const GuruVideoGuidelineView = (props) => {
    const {getFileSize, getFileExtension} = useHelper()
    const {goToBack} = props
    const {
        createdon,
        description,
        doc_file_size,
        doc_format,
        document,
        picture,
        title,
        userid,
        video,
        video_thumbnail,
        duration
    } = props?.guruVideoGuidelineDetails ?? {}
    const navigate = useNavigate()
    const {formatDateMMDDYYHms} = useHelper()
    const videoThumb = video_thumbnail ? process.env.REACT_APP_IMG_URL + video_thumbnail : GuidelinePosterImg
    const thumbNailImage = video_thumbnail ? process.env.REACT_APP_IMG_URL + picture : GuidelineRightImg
    const fileSize = 'File size :' + getFileSize(doc_file_size)
    const videoType = getFileExtension(video)
    const videoDetails = {
        videofilename:video,
        videofiletype:'video/'+videoType
    }
    return (
        <>
            <Text
                type="H1"
                text="Guru Video Guideline"
                className="mb-40"
            />
            {/*<Text
                type="H2"
                text="Navigate TO COURSETSUNAMI"
            />
            <Text
                type="PARAGRAPH"
                text="To navigate is to plan your course or to steer, guide or move through something. When you draw out a route to take on a map, this is an example of a time when you navigate."
            />
            <Text
                type="PARAGRAPH"
                text="To navigate is to plan your course or to steer, guide or move through something."
            />
            <ul className="m-0 p-0 pl-3 mb-40">
                <li>When you draw out a route to take on a <Button linkSecondary text="map." style={{fontWeight: 'inherit', fontSize: 'inherit', color: 'inherit',}} /> </li>
                <li>This is an example of a time when you navigate. </li>
                <li>When you steer and guide a ship to its destination, this is an example of a time when you navigate.</li>
                <li>Checkout <Button linkSecondary text="www.Rezkilahnavigate.com.my." style={{fontWeight: 'inherit', fontSize: 'inherit', color: 'inherit',}} /></li>
            </ul>*/}

            <div className="row">
                <div className="col-12">
                    <TextHtmlParser text={description}/>
                </div>
            </div>

            <div className="row mb-48">
                <div className="col-12 col-md-6">
                    <StaticVideoPlayer videoDetails={videoDetails}/>
                </div>
                <div className="col-12 col-md-6">
                    <div className="guideline-img">

                        <Image
                            widthFull
                            src={thumbNailImage}
                            alt="Guideline"
                        />
                    </div>
                </div>
            </div>
            <div className="pdf-details">
                <div className="pdf-details-icon">
                    <Image
                        src={PDFIcon}
                        alt="PDF"
                    />
                </div>
                <div className="pdf-details-info">
                    <Text type="H4" text={document}/>
                    <Text type="PARAGRAPH" text={fileSize}/>
                    <Text type="PARAGRAPH" text={`File format : ${doc_format}`}/>
                </div>
            </div>
            <div className="action-buttons-end">
                <Button
                    type="button"
                    text="Back"
                    onClick={goToBack}
                />
            </div>

        </>
    );
};

export default GuruVideoGuidelineView;
