import React from "react"
import { Link, NavLink, useLocation } from "react-router-dom";
import './breadcrumbs.scss'
import Text from "../text";
import { CgFormatSlash } from "react-icons/cg";

const Breadcrumbs = props => {
    const { pathname } = useLocation();
    return (
        <>
            <div className="breadcrumb-wrapper">
                {props.pageTitle && <Text type="H1" text={props.pageTitle} />}
                <div className="breadcrumb-main">
                    {props?.breadcrumbList?.map(function(d, idx){
                        return (
                            <div key={idx} className="breadcrumb-row">
                                <Link
                                    to={d.link}
                                    className={pathname === d.link ? "active" : ""}
                                >
                                    {d.text}
                                </Link>
                                <span className="breadcrumb-arrow-icon">
                                   <CgFormatSlash />
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
export default Breadcrumbs

