import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive'
import Button from '../button';
import Logo from '../svg/Logo';
import './headerLearner.scss';
import MenuIcon from '../svg/menuIcon'
import CloseIcon from '../svg/closeIcon'
import { NavLink, useNavigate } from "react-router-dom";
import {ENGLISH, MALAY} from "../../languages/languages.constants";
import Search from '../search'
import DisplayProfile from '../displayProfile';
import NavbarLearner from '../navbarLearner'
import {useSelector} from "react-redux";
import useHttpHandler from "../../hooks/httphandler.hook";
import useTranslations from "../../hooks/translations.hook";
import {toast} from "react-toastify";
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import useHelper from "../../hooks/helpers.hook";
import {GURU} from "../../constants/app.constants";
import AdminLogo from '../svg/adminLogo';
const HeaderLearner = props => {
    const {isMember} = useHelper()
    const {convertUser, isSwitchingUser} = useLogin()

    const userInfo = useSelector(state => state?.app?.userInfo)
    const navigate = useNavigate();
    const {text} = props
    const {setDefaultLanguage,lang} = useTranslations()
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const [menuBar, setMenuBar] = useState(false);
    const MenuToggle = () => {
        setMenuBar(true);
    }
    const MenuCloseToggle = () => {
        setMenuBar(false);
    }
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }

    return (
        <>
            <header className="header-learner">
               
                    <div className="header-main">
                        <div className="header-left">
                            <div className="logo">
                                <NavLink to="/">
                                    <AdminLogo/>
                                </NavLink>
                            </div>
                            {isMobile &&
                            <Button
                                text={<MenuIcon/>}
                                link
                                onClick={MenuToggle}
                            />
                            }
                        </div>
                        <div className={isMobile ? `menu-collapse ${menuBar ? 'menu-open' : ''}` : 'header-right'}>
                            {isMobile &&
                            <div className="menu-close-button">
                                <Button
                                    text={<CloseIcon/>}
                                    link
                                    large
                                    onClick={MenuCloseToggle}
                                />
                            </div>
                            }
                           {/** <div className="h-search">
                                <Search />
                            </div> */}

                          {lang == "malay" &&
                                 <select onChange={changeLanguage} className="form-control">
                                        <option value={ENGLISH}>English</option>
                                        <option value={MALAY} selected >Malay</option>
                                </select>
                            }

                            {lang == "en" &&
                                 <select onChange={changeLanguage} className="form-control">
                                        <option value={ENGLISH} selected>English</option>
                                        <option value={MALAY}  >Malay</option>
                                </select>
                            }

                            <div className="h-dp">
                                {/*
                            <Button
                                    link={!isSwitchingUser}
                                    active={isSwitchingUser}
                                    type="button"
                                    text="Become a Coach"
                                    className="beaguru-link"
                                    isSubmitting={isSwitchingUser}
                                    onClick={()=>{convertUser('instructor')}}
                                />
                                */}
                                <DisplayProfile
                                    userInfo={userInfo}
                                    dpOption={[
                                        {
                                            text:text?.MY_PROFILE,
                                            link: "/trainee/settings/profile",
                                        },
                                        {
                                            text:text?.CHANGE_PASSWORD,
                                            link: "/trainee/settings/reset-password",
                                        },
                                        {
                                            text:text?.BANK_DETAILS,
                                            link: "/trainee/settings/bank-details",
                                        },
                                        {
                                            text:text?.LOGOUT,
                                            link: "/logout",
                                        },
                                    ]}
                                />
                            </div>
                            {isMobile &&
                                <div className="lp-sidebar-wrapper">
                                    <NavbarLearner />
                                </div>
                            }
                        </div>
                    </div>
                
            </header>
        </>
    );
};

export default HeaderLearner;
