import React from 'react';

const UploadIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.86328 14.2641C5.86328 13.8499 5.52749 13.5141 5.11328 13.5141C4.69907 13.5141 4.36328 13.8499 4.36328 14.2641H5.86328ZM5.11328 15.1698H4.36328H5.11328ZM18.5425 14.2641C18.5425 13.8499 18.2067 13.5141 17.7925 13.5141C17.3783 13.5141 17.0425 13.8499 17.0425 14.2641H18.5425ZM12.0386 5.67604C12.2973 5.35259 12.2449 4.88063 11.9214 4.62187C11.598 4.36311 11.126 4.41555 10.8673 4.739L12.0386 5.67604ZM7.24461 9.2673C6.98585 9.59075 7.03829 10.0627 7.36174 10.3215C7.68519 10.5802 8.15716 10.5278 8.41591 10.2043L7.24461 9.2673ZM12.0386 4.739C11.7798 4.41555 11.3078 4.36311 10.9844 4.62187C10.6609 4.88063 10.6085 5.35259 10.8673 5.67604L12.0386 4.739ZM14.4899 10.2043C14.7487 10.5278 15.2206 10.5802 15.5441 10.3215C15.8675 10.0627 15.92 9.59075 15.6612 9.2673L14.4899 10.2043ZM12.2029 5.20752C12.2029 4.79331 11.8671 4.45752 11.4529 4.45752C11.0387 4.45752 10.7029 4.79331 10.7029 5.20752H12.2029ZM10.7029 15.1698C10.7029 15.584 11.0387 15.9198 11.4529 15.9198C11.8671 15.9198 12.2029 15.584 12.2029 15.1698H10.7029ZM4.36328 14.2641V15.1698H5.86328V14.2641H4.36328ZM4.36328 15.1698C4.36328 17.0845 5.9155 18.6368 7.83026 18.6368V17.1368C6.74393 17.1368 5.86328 16.2561 5.86328 15.1698H4.36328ZM7.83026 18.6368H15.0755V17.1368H7.83026V18.6368ZM15.0755 18.6368C16.9903 18.6368 18.5425 17.0845 18.5425 15.1698H17.0425C17.0425 16.2561 16.1619 17.1368 15.0755 17.1368V18.6368ZM18.5425 15.1698V14.2641H17.0425V15.1698H18.5425ZM10.8673 4.739L7.24461 9.2673L8.41591 10.2043L12.0386 5.67604L10.8673 4.739ZM10.8673 5.67604L14.4899 10.2043L15.6612 9.2673L12.0386 4.739L10.8673 5.67604ZM10.7029 5.20752V15.1698H12.2029V5.20752H10.7029Z" fill="currentColor"/>
        </svg>
    );
};

export default UploadIcon;

UploadIcon.defaultProps = {
    width: '24',
    height: '24'
}