import React, {useEffect} from "react";
import LayoutGuru from '../../../../components/layoutGuru';
import EditBankDetailsView from "./view/EditBankDetailsView";
import useBankDetails from "./hooks/bankDetails.hook";
import Loader from "../../../../components/loader";

const EditBankDetails = props => {
    const {text} = props
    const bankDetailsHook = useBankDetails()
    useEffect(() => {
        if (!bankDetailsHook?.bankDetails)
            bankDetailsHook?.getBankDetails()
    }, [])
    if (bankDetailsHook.loading) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }
    return (
        <>
            <LayoutGuru {...props}>
                <EditBankDetailsView {...props} {...bankDetailsHook} />
            </LayoutGuru>
        </>
    )
}

export default EditBankDetails;
