import React from 'react';
import Text from '../text';


const TermsCondition = props => {
    return (
<div class="rounded-3" style={{padding:"10px",backgroundColor:"#fff"}}>
<Text className="p-2" type="H4" style={{color:"#8081ff"}} text="Course Tsunami Sign-up Terms and Conditions" />
<div className=' p-2 t-condition'>
<Text type="H5" text="Terms and Conditions" className="mt-20" />

<Text style={ {marginTop: '10px'} } className="small-sub-blacl-text" type="PARAGRAPH" text="Please read these Terms and Conditions carefully before using Course Tsunami ('the Platform'). By registering as a learner or instructor and using the Platform, you agree to be bound by these Terms and Conditions."  />

<Text type="H5" text="1. Definitions" className="term-condition-extra-bold-text" />

<Text className="term-condition-text" type="PARAGRAPH" text="'Course Tsunami' refers to the e-learning platform and its website, applications, and related services provided by Course Tsunami."  />
<Text className="term-condition-text" type="PARAGRAPH" text="'Learner' refers to an individual who registers on Course Tsunami to access and participate in courses offered by instructors."  />
<Text className="term-condition-text" type="PARAGRAPH" text="'Instructor' refers to an individual or organization that creates and offers courses on Course Tsunami."  />
<Text className="term-condition-text" type="PARAGRAPH" text="'Content' refers to any text, images, videos, quizzes, assignments, and other materials available on the Platform."  />


<Text type="H4" text="2. Registration and Account" className="term-condition-extra-bold-text" />

<Text type="H5" text="2.1 Registration and Account" className="term-condition-bold-text"  />
<Text className="term-condition-text" type="PARAGRAPH" text="To access and use Course Tsunami as a learner, you must create a user account. You agree to provide accurate and up-to-date information during the registration process and keep your account credentials secure. You are solely responsible for the activities that occur under your account."  />

<Text type="H5" text="2.2 Instructor Account" className="term-condition-bold-text"  />
<Text className="term-condition-text" type="PARAGRAPH" text="To become an instructor and offer courses on Course Tsunami, you must create an instructor account. You must ensure that the content you provide complies with our guidelines and does not infringe any third-party rights."  />

<Text type="H4" className="term-condition-extra-bold-text" text="3. Course Content and Intellectual Property"/>
<Text type="H5" className="term-condition-bold-text"  text="3.1 Learner's Rights" />
<Text className="term-condition-text" type="PARAGRAPH" text="As a learner, you have the right to access and view course content for personal, non-commercial use. You agree not to share your account credentials or distribute course materials to others without permission."  />

<Text type="H5" className="term-condition-bold-text"  text="3.2 Instructor's Rights" />
<Text className="term-condition-text" type="PARAGRAPH" text="As an instructor, you retain ownership of the content you create for courses on Course Tsunami. By uploading content, you grant Course Tsunami a limited license to use, reproduce, distribute, and display your content for the purpose of delivering the course to learners. Course Tsunami does not have the right to modify or alter your content without your explicit consent."  />

<Text type="H4" className="term-condition-extra-bold-text" text="4. Code of Conduct"/>
<Text type="H5" className="term-condition-bold-text"  text="4.1 Learner Conduct" />
<Text className="term-condition-text" type="PARAGRAPH" text="You agree not to engage in any activities that may interfere with the Platform's proper functioning or compromise the security of the Platform or other users' data. Harassment, inappropriate content, and any form of unethical behaviour are strictly prohibited."  />
<Text type="H5" className="term-condition-bold-text"  text="4.2 Instructor Conduct" />
<Text className="term-condition-text" type="PARAGRAPH" text="As an instructor, you agree to provide accurate and up-to-date information, deliver high-quality courses, and engage professionally with learners. You are responsible for ensuring that your content complies with applicable laws and does not violate any intellectual property rights."  />


<Text type="H4" className="term-condition-extra-bold-text" text="5. Payments and Refunds"/>
<Text type="H5" className="term-condition-bold-text"  text="5.1 Course Fees" />
<Text className="term-condition-text" type="PARAGRAPH" text="Course Tsunami may offer both free and paid courses. Learners agree to pay the specified fees for any paid courses they wish to access."  />
<Text type="H5" className="term-condition-bold-text"  text="5.2 Refund Policy" />
<Text className="term-condition-text" type="PARAGRAPH" text="Course Tsunami's refund policy varies depending on the type of course and payment method. Refunds, if applicable, will be subject to the terms specified at the time of purchase."  />


<Text type="H4" className="term-condition-extra-bold-text" text="6. Modifications and Termination"/>
<Text className="term-condition-text" type="PARAGRAPH" text="Course Tsunami reserves the right to modify, suspend, or terminate the Platform or any part of it at any time without notice. We may also terminate or suspend user accounts for violations of these Terms and Conditions or for any other reason at our sole discretion."  />

<Text type="H4" className="term-condition-extra-bold-text" text="7. Privacy"/>
<Text className="term-condition-text" type="PARAGRAPH" text="By using Course Tsunami, you acknowledge and agree to the collection, use, and disclosure of your personal information as described in our Privacy Policy."  />


<Text type="H4" className="term-condition-extra-bold-text" text="8. Indemnification"/>
<Text className="term-condition-text" type="PARAGRAPH" text="You agree to indemnify and hold Course Tsunami and its affiliates, partners, and employees harmless from any claims, losses, damages, liabilities, or expenses arising from your use of the Platform, your content, or any violation of these Terms and Conditions."  />


<Text type="H4" className="term-condition-extra-bold-text" text="9. Governing Law and Jurisdiction"/>
<Text className="term-condition-text" type="PARAGRAPH" text="These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction]."  />

<Text type="H4" className="term-condition-extra-bold-text" text="10. Contact Information"/>
<Text className="term-condition-text" type="PARAGRAPH" text="If you have any questions or concerns about these Terms and Conditions, please contact us at info@courstsunami.com By clicking 'Sign Up' or accessing the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with these Terms and Conditions, you must not use the Platform."  />
</div>
</div>

);
};

export default TermsCondition;
