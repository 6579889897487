import { useNavigate } from "react-router-dom";
import useTranslations from "../../../../hooks/translations.hook";

const useUploadAttachments = () => {
    const navigate = useNavigate()
    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    const goBackToBankDetails = () =>{
        navigate('/instructor/bank-details')
    }
    const goToThankyou = () => {
        navigate('/instructor/bank-details')
    }
    return {
        goBackToBankDetails,
        goToThankyou
    }

}
export default useUploadAttachments


