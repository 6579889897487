import React, {useState} from "react";
import './videocard.scss'
import Image from "../image";
import Icon from '../icon'
import PlayIcon from '../svg/playIcon'
import Button from "../button";
import Text from "../text";
import useHelper from "../../hooks/helpers.hook";
import ProgressBarCard from "../programBarCard";
import TextHtmlParser from "../htmlparser";


const VideoCard = props => {
    const [showDescription, setShowDescription] = useState(!props.hideDescription)
    const {secondsToMinutes, toSeconds} = useHelper()
    const duration = secondsToMinutes(props.duration)
    const watchedSeconds = toSeconds(props?.watchedupto)
    const barPercentage = props.duration ? (watchedSeconds / props.duration) * 100 : 0
    return (
        <>
            <div className="video-card">
                <div className="video-poster">
                    <Image
                        src={props.poster}
                        alt={props.alt}
                        className="course_poster"
                     
                    />
                    {!props.noPlay &&
                    <div className="video-play">
                        <Button
                            link
                            text={
                                <Icon icon={<PlayIcon/>}/>
                            }
                          

                            onClick={() => {

                                console.log(props);
                          
                                props.playVideo(props.videofilename)
                        
                            
                            
                        }}
                            type="button"
                        />
                    </div>
                    }
                   
                    {/* <ProgressBarCard hideText width={barPercentage}/> */}
                </div>
                {!props.noCaption &&
                <div className="video-caption">
                    <Text
                        type="H5"
                        text={props.title}
                        className="video-title"
                    />
                    {props.hideDescription && !showDescription && (
                        <Button
                            linkPrimary
                            text={props?.readMoreText}
                            onClick={() => {
                                setShowDescription(true)
                            }}
                        />
                    )}
                    {showDescription && (
                        <TextHtmlParser
                            text={props.description}
                            className="video-desc"
                        />
                    )}
                </div>
                }
            </div>
        </>
    )
}
export default VideoCard;

VideoCard.defaultProps = {
    poster: "",
    alt: "video poster",
    duration: 0,
    hideDescription: false,
    readMoreText: "read more"
}
