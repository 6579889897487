import React, {useEffect} from "react";
import LayoutPanel from "../../../../components/layoutPanel";
import EditFreemiumProfileView from "./view/EditFreemiumProfileView";
import './profile.scss';
import useProfile from "../../../trainee/settings/profile/hooks/profile.hook";
import useAddressInformation from "../../addressInformation/hooks/addressInformation.hook";
import Loader from "../../../../components/loader";
import {useSelector} from "react-redux";
import useHelper from "../../../../hooks/helpers.hook";
import EditPremiumProfileView from "./view/EditPremiumProfileView";

const EditProfile = props => {
    const userInfo = useSelector(state => state.app.userInfo)
    const {isFreemiumMember} = useHelper()
    const profileHook = useProfile()
    const addressInformationHook = useAddressInformation()
    const isLoaded = !profileHook.loading && !addressInformationHook.loading
    useEffect(() => {
        if (!profileHook.profileDetails) {
            profileHook.getProfileDetails()
        }
        profileHook.getDropDowns()
    }, [])
    useEffect(() => {
        addressInformationHook.getCountries()
        addressInformationHook?.getStatesByCountry()
        addressInformationHook?.getCitiesByState(profileHook?.profileDetails?.stateid)
    }, [profileHook.profileDetails])
    if (!isLoaded) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    } else {
        if (isFreemiumMember(userInfo)) {
            return (
                <>
                    <LayoutPanel {...props}>
                        <EditFreemiumProfileView {...props} {...profileHook} addressInformationHook={addressInformationHook}/>
                    </LayoutPanel>
                </>
            )
        } else {
            return (
                <>
                    <LayoutPanel {...props}>
                        <EditPremiumProfileView {...props} {...profileHook} addressInformationHook={addressInformationHook}/>
                    </LayoutPanel>
                </>
            )
        }
    }

}

export default EditProfile;
