
import React, { useEffect, useState } from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import Input from "../input";
import Search from "../search";
import Button from "../button";
import Text from "../text";
import Flex from "../flex";
import rightIcon from '../../assets/img/rightIcon.png'

const ChatConfirmTrainer =(props)=>{
   
    const navigate = useNavigate();

if(props.headingMessage.type == "joined"){
    props.setIsModal(false)
}

    return(
        <>


<div className="row popup-padding " style={{background:"#fff"}}>

{props.headingMessage.type == "joined" &&(
<>
    <Text
    type="H5"
    text={"Connected" } 
    className="mb-10 mt-10 text-center"
/>

</>
)}

{props.headingMessage.type == "connecting" &&(
<>
    <Text
    type="H5"
    text={"Connecting to " + props.getchatDashboardData.uname + " Please wait a moment" } 
    className="mb-10 mt-10 text-center"
/>

</>
)}


{props.headingMessage.type == "cancelled" &&(
<>
    <Text
    type="H5"
    text={props.getMessage}
    className="mb-10 mt-10 text-center"
/>

<Flex justifyContent="center" className="mb-48 mt-40">
<Button type="primary" key="console" text="Ok"  onClick={()=>{
 navigate("/trainee/chat-with-instructor")
}}/>

</Flex>

</>
)}

  

</div>

        </>
    )
}


export default ChatConfirmTrainer;


