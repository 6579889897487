import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import Input from "../input";
import Search from "../search";
import Button from "../button";


const TotalTopicSearch =(props)=>{
    console.log("props------>",props);
    const navigate = useNavigate();
    const {post} = useHttpHandler();
    const formik = useFormik({
        initialValues: {
            search:'',
            category: props.getCategoryId,
            subcategory: props.getSubCategoryId,
        },
        validationSchema: Yup.object({
            search: Yup.string().required('Please enter a valid Categeory'),
            category: Yup.string().required('Please enter a valid Categeory'),
            subcategory: Yup.string().required('Please enter a valid Sub Categeory'),
        }),
        onSubmit: (values, actions) => {

            actions.setSubmitting(true)
            let postData = {
            
                category: values.category,
                subcategory: parseInt(values.subcategory),
            }

            post('instructorCreateCourse ', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    toast.success(status.msg)
                    navigate('/instructor/course')
                    
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        },

    });

    const setSubcategorys = (e) =>{
        props?.setCheckCategory(true);
        props?.setSubCategoryId(e.target.value);
        console.log("e.target",e.target.value);
        formik.setFieldValue('subcategory', e.target.value)
    }

    const getSubCategories = (e) => {
        getSubCategoriesById(e.target.value)
        props?.setSubCategoryId(0);
        props?.setCheckCategory(true);
        props?.setCategoryId(e.target.value);
    }
   
    const getFilterTopic = (e) => {
    
        props?.setcheckFilter(true);
        props?.setFilterId(e.target.value);
        formik.setFieldValue('category', e.target.value)
        getSubCategoriesById(e.target.value)
    }
    //const [subCategories, setSubCategories] = useState(null)
    const getSubCategoriesById = (catId) => {
        let subCategoryOptions = []
        if (props?.dropdownsData?.subCategories) {
            props?.dropdownsData?.subCategories.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatId, label: item.subCatName})
                }
            })
        }
        console.log("catId",catId,subCategoryOptions,props?.dropdownsData?.subCategoriesOptions);
       
        props.setSubCategories(subCategoryOptions)
    }
    
    return(
        <>
    <div className="container mb-4">
    <div className="row bg-light ml-6 custom-instructor-details-1">
    <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="mt-2 ps-md-0 pe-4 me-4 align-items-center">
            <div className="p-0">
            <form onSubmit={formik.handleSubmit}>

                <div className="formBox">

                    <Select
                        tertiary
                        label="Category"
                        name="category"
                        placeholder="Select Category"
                        value={formik.values.category}
                        onChange={getSubCategories}
                        onBlur={formik.handleBlur}
                        mandatory
                        options={props?.dropdownsData?.categoriesOptions}
                        errormessage={formik.touched.category && formik.errors.category ? (
                            formik.errors.category
                        ) : null}
                    />
                    
                </div>
            </form> 
                </div>
            </div>
        </div>
<div className="col-lg-4 col-md-6 col-sm-12">
<div className="ps-md-0 pe-4 me-4 mt-2 flex-row align-items-center">
<div className="p-0">
<form onSubmit={formik.handleSubmit}>

<div className="formBox">
<Select
        tertiary
        label="Sub Category"
        name="subcategory"
        placeholder="Select Sub Category"
        value={formik.values.subcategory}
        onChange={setSubcategorys}
        onBlur={formik.handleBlur}
        mandatory
        options={props.subCategories}
        errormessage={formik.touched.subcategory && formik.errors.subcategory ? (
            formik.errors.subcategory
        ) : null}
    />
    
</div>
</form>
                </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="ps-md-0 mb-3 mb-md-0 pe-4 me-4 d-flex flex-row align-items-center">
            <div className="p-0">
                <div className="row position-relative left-7 mt-2 mr-1">
                    <div className="col-md-10">
                    <div className="formBox">
                    <input
                    type="text"
                    label={props.search}
                    placeholder="Search for any topics..."
                    name="searchValue"
                    className="input-box"
                    value={formik.values.searchValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errormessage={formik.touched.searchValue && formik.errors.searchValue ? (
                        formik.errors.searchValue
                    ) : null}
                />
                
                    </div>
                    </div>
                    <div className="col-md-2">
                    <Button
                        type="submit"
                        text="search"
                        onChange={getFilterTopic}
                        className="btn-search mt-32"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                       
                    />
                    </div>
                </div>
            
                </div>
            </div>
        </div>
        
        
    </div>
    </div>

        
        </>
    )
}


export default TotalTopicSearch;


