import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import CourseDashboardView from "./view/courseDashboardView";
import LayoutPanel from "../../../components/layoutPanel";
import FavouriteDashboardView from "./view/favouriteDashboardView";



const   FavouriteDashboard = props => {

    const userInfo = useSelector(state => state.app.userInfo)

      


    return (
        <>
              <LayoutPanel {...props}  footer>
                <FavouriteDashboardView {...props} userInfo={userInfo}   />
            </LayoutPanel>
        </>
    )
      
}

export default FavouriteDashboard;
