import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import {toast} from "react-toastify";
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import useLogin from "../../../../authentication/login/hooks/login.hook";

const EditBankDetailsView = (props) => {
    const {post} = useHttpHandler()
    const {numbersOnly} = useLogin()
    const formik = useFormik({
        initialValues: {
            bankName: props?.bankDetails?.bankname ? props?.bankDetails?.bankname : '',
            accountHolder:  props?.bankDetails?.payeename ? props?.bankDetails?.payeename : '',
            accountNumber: props?.bankDetails?.accountnumber ? props?.bankDetails?.accountnumber : '',
        },
        validationSchema: Yup.object({
            bankName: Yup.string().required('Please enter a valid bank name'),
            accountHolder: Yup.string().required('Please enter a valid account holder'),
            accountNumber: Yup.string().required('Please enter a valid account number').matches(numbersOnly, 'Please enter a valid account number'),
        }),
        onSubmit: (values, actions) => {
            const postData = {
                bankname: values.bankName,
                payeename: values.accountHolder,
                accountnumber: values.accountNumber,
            }
            post('user_bankdetailsupdate', postData).then((res)=>{
                actions.setSubmitting(false);
                const status = res.data.status
                if(status.result === '200') {
                    props?.goToBankDetails();
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {actions.setSubmitting(false);})
        },
    });
    return (
        <>
           <div className='change-password mt-5 px-5 py-3 rounded-2'>
            <div className="d-flex align-items-center mb-20">
                <Text type="H3" text="Edit Bank Details" className="font-weight-500 mr-24 mb-0" />
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Bank Name"
                                type="text"
                                name="bankName"
                                placeholder=""

                                value={formik.values.bankName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.bankName && formik.errors.bankName ? (
                                    formik.errors.bankName
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Account Holder"
                                type="text"
                                name="accountHolder"
                                placeholder=""

                                value={formik.values.accountHolder}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountHolder && formik.errors.accountHolder ? (
                                    formik.errors.accountHolder
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Account Number"
                                type="text"
                                name="accountNumber"
                                placeholder=""

                                value={formik.values.accountNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountNumber && formik.errors.accountNumber ? (
                                    formik.errors.accountNumber
                                ) : null}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="action-buttons-end mt-1 mb-0">
                    <Button
                        light
                        type="button"
                        text="Cancel"
                        onClick={props?.goToBankDetails}
                    />
                    <Button
                        type="submit"
                        text="Save"
                        className="btn-save"
                    />
                </div>
            </form>
            </div>
        </>
    );
};

export default EditBankDetailsView;

