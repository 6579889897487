import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import EyeIcon from "../../../../components/svg/EyeIcon";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import FacebookIcon from "../../../../components/svg/facebookIcon";
import GoogleIcon from "../../../../components/svg/googleIcon";
import AppleIcon from "../../../../components/svg/appleIcon";
import {useFormik} from "formik";
import * as Yup from "yup";
import useRegister from "../hooks/register.hook";
import Checkbox from '../../../../components/checkbox';
import {useNavigate} from 'react-router-dom';
import {DEFAULT_COUNTRY_ID, FREEMIUM} from "../../../../constants/app.constants";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import useLogin from "../../../authentication/login/hooks/login.hook";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import logoImg from '../../../../assets/userimg/login-bg2.png';
import TimezoneSelect, { type ITimezone } from "react-timezone-select"
import { useTimezoneSelect, allTimezones } from "react-timezone-select"

const RegisterView = (props) => {
    const {get, post} = useHttpHandler()
    const {registerWithFacebook, registerWithGoogle} = props
    const [phoneNumber, setPhoneNumber] = useState('')
    const labelStyle = "original"
    const timezones = {
      ...allTimezones,
      "Europe/Berlin": "Frankfurt",
    }
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })
    const [returnSelectedTimeZone,setTimeZone] = useState({});

    const {
        redirectTo,
        storeUserInfo,
        setUserSessionInfo,
        lowercaseRegex,
        uppercaseRegex,
        numericRegex,
        nonalphanumeric,
        numbersOnly,
        phoneNumbersFormat
    } = useLogin()

    const navigate = useNavigate(); 

    const {
        showPassword,
        ShowPasswordClick,
        showConfirmPassword,
        ShowConfirmPasswordClick,
        goToRegisterAsGuru
    } = useRegister()
    const {text} = props
    const formik = useFormik({
        initialValues: {
            uname: '',
            email: '',
            mobile: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false
        },
        validationSchema: Yup.object({
            uname: Yup.string().required('Please enter a username'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter a email address'),
           
            password: Yup.string()
                .min(6, 'Your password must be atleast 6 digits')
                .matches(uppercaseRegex, 'Atleast one capital letter required')
                .matches(lowercaseRegex, 'Atleast one small letter required')
                .matches(numericRegex, 'Atleast one number required')
                .matches(nonalphanumeric, 'Atleast one Special Character required')
                .required('Please enter a password'),
            confirmPassword: Yup.string().min(6, 'Your password must be atleast 6 digits').required('Please re-enter password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
            acceptTerms: Yup.bool().oneOf([true], 'Please accept Terms of use')
        }),
        onSubmit: (values, actions) => {
            //const phone = values.mobile.replaceAll('+', '')
            const isd = values.mobile.substring(0, 3)
            const newPhone = values.mobile.substr(3);

            const refCode = sessionStorage.getItem('referralCode');

            var returnTimeZone = {};

            options.map((item, index) => {
        
                if(selectedTimezone?.value === undefined){
                            if(item.value === Intl.DateTimeFormat().resolvedOptions().timeZone){
                               // setTimeZone(item);
                                console.log("item",item);
                                returnTimeZone = item
                            }
                 }else{
                    if(item.value === selectedTimezone?.value){
                       // setTimeZone(item);
                        console.log("item",item);
                        returnTimeZone = item
                       
                    }
                 }
            })
       

            let timeZone = returnTimeZone.label.match(/\((.*?)\)/);

            if (timeZone) {

                    console.log(timeZone[1].replace('GMT', '')); // "GMT+2:00"
            
                 
                 


            let data = {
                country_id: isd,
                usertype_id: FREEMIUM,
                mobile: newPhone,
                email: values.email,
                uname: values.uname,
                password: values.password,
                ref_code: refCode ? refCode : 1,
                timezone:returnTimeZone.value,
                timezone_country:timeZone[1].replace('GMT', '')
         
            }

            post('userRegistration', data).then((res) => {
                actions.setSubmitting(false);
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    setUserSessionInfo(data).then(() => {
                        redirectTo(data.redirectcode, data.usertypeid)
                    })
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {actions.setSubmitting(false);})
        }
        },
    });
    useEffect(() => {
        localStorage.removeItem('token')
        sessionStorage.removeItem('isConvertUserFlow')
    }, [])
    useEffect(() => {
        formik.setFieldValue('mobile', phoneNumber)
    }, [phoneNumber])

    return (
        <>
        

<div class="register">

<main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center p-3">
      <div class="container">
        <div class="row justify-content-center">
        
        
          <div class="col-lg-7 col-md-6"> 
          <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
          </div>
        
        
          <div class="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">

            

            <div class="card mb-3 register_flow">

              <div class="card-body">

                <div class="pt-2 pb-2">
                  <h5 class="card-title text-center pb-0 fs-4">Create an Account</h5>
                  <p class="text-center small">Enter your personal details to create account

</p>
                </div>
                
                <Flex
                justifyContent="center"
                className="mb-20 flex-column flex-md-row"
            >
               
                <Button
                    linkDanger
                    text="Register as Instructor"
                    onClick={goToRegisterAsGuru}
                />
            </Flex>
            <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        autoFocus={true}
                        label="Username"
                        type="text"
                        name="uname"
                        placeholder=""
                        value={formik.values.uname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.uname && formik.errors.uname ? (
                            formik.errors.uname
                        ) : null}
                    />
                </div>
                <div className="formBox">
                    <Input
                        label="Email Address"
                        type="text"
                        name="email"
                        placeholder=""

                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.email && formik.errors.email ? (
                            formik.errors.email
                        ) : null}
                    />
                </div>

                <div className="formBox-01">    

                    <div className="form-field">
                        <label className="form-label">Phone Number <span
                            className="text-danger">*</span></label> 
                        <PhoneInput
                            className={formik.touched.mobile && formik.errors.mobile ? 'error' : ''}
                            defaultCountry={'GB'}
                            countries={['GB']}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            limitMaxLength="10"
                            international
                        />
                        </div>

                    <div className="formBox hiddenInput">
                        <Input
                            type="hidden"
                            maxlength="10"
                            name="mobile"
                            placeholder=""
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={formik.touched.mobile && formik.errors.mobile ? (
                                formik.errors.mobile
                            ) : null}
                        />
                    </div>
                </div>

                <div className='col-md-12'>
                    <label class="form-label ">Prefered Timezone </label>
                        <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
                  </div>
                <div className="formBox mt-10">
                    <Input
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="********"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.password && formik.errors.password ? (
                            formik.errors.password
                        ) : null}
                        icon={
                            <span onClick={ShowPasswordClick}><EyeIcon className="" visible={showPassword}/></span>
                        }
                    />
                </div>
                <div className="formBox">
                    <Input
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        placeholder="********"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            formik.errors.confirmPassword
                        ) : null}
                        icon={
                            <span onClick={ShowConfirmPasswordClick}><EyeIcon visible={showConfirmPassword}/></span>
                        }
                    />
                </div>
                <div className="formBox">
                    <Checkbox
                        name="acceptTerms"
                        value={formik.values.acceptTerms}
                        label={<>I have read and agree the  <Button type="button" className="ml-1" linkSecondary
                                                                              text="TERMS OF USE"/></>}
                        id="acceptTerms"
                        isChecked={formik.values.acceptTerms}
                        onChange={formik.handleChange}
                        errormessage={formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                            formik.errors.acceptTerms
                        ) : null}
                    />

                </div>
                <Flex justifyContent="center" className="mb-48">
                    <Button
                        type="submit"
                        text="Sign Up"
                        large
                        disabled={formik.isSubmitting}
                        isSubmitting={formik.isSubmitting}
                    />
                </Flex>
            </form>
            <Flex
                justifyContent="center"
                className="or"
            >
                <Text type="SPAN" text="or signup with"/>
            </Flex>
            <Flex
                justifyContent="center"
                className="connect-social"
            >
                <Button
                    link
                    text={
                        <FacebookIcon
                            width="14"
                            // height="46"

                        />
                    }
                    onClick={registerWithFacebook}
                />
                <Button
                    link
                    text={
                        <GoogleIcon
                            width="30"
                            // height="51"

                        />
                    }
                    onClick={registerWithGoogle}
                />
                <Button
                    link
                    text={
                        <AppleIcon
                            width="30"
                            // height="48"
                        />
                    }
                />
            </Flex>
            <Flex
                justifyContent="center"
            >
                Already Have An Account &nbsp; <Button linkDanger text="Login"
                                                            onClick={() => navigate('/login')}/>
            </Flex>

              </div>
            </div>


          </div>
        </div>
      </div>

    </section>

  </div>
</main>
                </div>
        </>
    );
};

export default RegisterView;

