import React, {useEffect, useState} from "react";
import Flex from "../flex";
import Text from "../text";
import Button from "../button";
import Icon from "../icon";
import './basicFinance.scss';
import VideoPlayerIcon from "../svg/videoPlayerIcon";
import ClockIcon from "../svg/clockIcon";
import VideoCard from "../videocard";
import Modal from "../modal";
import VideoPlayer from "../videoplayer";
import useLearnerMain from "../../pages/trainee/main/hooks/traineeMain.hook";
import Loader from "../loader";
import {useNavigate} from "react-router-dom";
import useHelper from "../../hooks/helpers.hook";

const BasicFinance = props => {
    const {getShortDescription} = useHelper()
    const navigate = useNavigate()
    const {videoid, video_category, videolevel, total_minutes, category_desc, totalvideos} = props?.videoInfo
    const learnerMainHook = useLearnerMain()
    const {videoDetails} = learnerMainHook
    const [isModal, setIsModal] = useState(false);
    const [readMore, setReadMore] = useState(false);
    const toggleisModal = () => {
        setIsModal(state => !state);
    }
    const [isModalContinueLearning, setIsModalContinueLearning] = useState(false);
    const toggleisModalContinueLearning = () => {
        setIsModalContinueLearning(state => !state);
    }

    const Description = props => {
        if (props?.readMore) {
            return (
                <Text
                    type="PARAGRAPH"
                    text={props.description}
                />

            )
        } else {
            return (
                <>
                    <Text
                        type="SPAN"
                        text={getShortDescription(props.description, 220) + '... '}
                    />
                    <Button
                        linkPrimary
                        text="read more"
                        onClick={() => {
                            props?.setReadMore(true)
                        }}
                    />
                </>

            )
        }

    }

    useEffect(() => {
        learnerMainHook?.getVideoDetailsById(videoid)
    }, [])


    if (learnerMainHook?.loading) {
        return <Loader/>
    }

    return (
        <>
            <div className="basic-finance-wrapper shadow">
                <div className="row row-mrl-0">
                    <div className="col-12 col-md-3">
                        <div className="basic-finance-left">
                            <Text
                                type="H4"
                                className="text-uppercase head-to-text"
                                text={videolevel}
                            />
                            <Text
                                type="H2"
                                className="text-uppercase text-light"
                                text={video_category}
                            />
                            {/*
                            <Text
                                type="PARAGRAPH"
                                
                                text={category_desc}
                            />*/}
                            <div className="basicfinance-list">
                                <Icon
                                    small
                                    cyan
                                    icon={
                                        <VideoPlayerIcon
                                            width="14"
                                            height="14"
                                        />
                                    }
                                />
                                <Text
                                    type="SPAN"
                                    text={`${totalvideos} Total videos`}
                                    className="mb-0 text-light"
                                />
                            </div>
                            <div className="basicfinance-list">
                                <Icon
                                    small
                                    cyan
                                    icon={
                                        <ClockIcon
                                            width="14"
                                            height="14"
                                        />
                                    }
                                />
                                <Text
                                    type="SPAN"
                                    text={`${total_minutes } Total mins`}
                                    className="mb-0 text-light"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="basic-finance-right">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-5">
                                    <div className="basic-finance-right-video">
                                        <VideoCard
                                            noPlay
                                            noCaption
                                            poster={process.env.REACT_APP_IMG_URL + videoDetails?.orginal_thumbnail}
                                            alt={videoDetails?.title}
                                            duration={videoDetails?.videoduration}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="basic-finance-right-content">
                                        <Text
                                            type="H3"
                                            className="text-capitalize"
                                            text={videoDetails?.title}
                                        />
                                        
                                        <Description
                                            description={videoDetails?.des}
                                            readMore={readMore}
                                            setReadMore={setReadMore}
                                        />
                                        <Flex justifyContent="end" className="mt-20">
                                            {videoDetails?.watchedduration > 0 ? (
                                                <Button large text="Continue Learning"
                                                        onClick={toggleisModalContinueLearning}/>
                                            ) : (
                                                <Button large
                                                        text='START LEARNING'
                                                        onClick={() => {
                                                            navigate('/trainee/my-learning/video-details/' + videoid)

                                                           // navigate('/trainee/video-meeting');
                                                        }}
                                                />
                                            )}
                                        </Flex>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModal &&
            <Modal
                onClose={() => setIsModal(false)}
                onOuterClose={() => setIsModal(false)}
                modalContent={
                    <>
                        <VideoPlayer
                            videourl="https://player.vimeo.com/video/115783408?h=def2b8dd8f"
                            width="100%"
                            height="460"
                        />
                    </>
                }
            />
            }
            {isModalContinueLearning &&
            <Modal
                modalMediumExtra
                onClose={() => setIsModalContinueLearning(false)}
                onOuterClose={() => setIsModalContinueLearning(false)}
                modalContent={
                    <>
                        <div className="continuelearningpopup-wrp">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <Text
                                        type="H3"
                                        text={`${videolevel} - ${videoDetails?.title}`}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <VideoCard
                                        noCaption
                                        poster={process.env.REACT_APP_IMG_URL + videoDetails?.orginal_thumbnail}
                                        alt={videoDetails?.title}
                                        duration={videoDetails?.videoduration}
                                        noPlay
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Flex justifyContent="between" flexColumn className="h-100">
                                        <Flex justifyContent="start" flexColumn className="mb-20">
                                            <Text type="H3" text="Resume Watching?"/>
                                            <Text
                                                type="PARAGRAPH"
                                                text={videoDetails?.des}
                                            />
                                        </Flex>
                                        <Flex justifyContent="end">
                                            <Button outline dark text="No Thanks" onClick={() => {
                                                setIsModalContinueLearning(false)
                                            }}/>
                                            <Button
                                                text="Resume"
                                                className="ml-24"
                                                onClick={() => {
                                                    navigate('/trainee/my-learning/video-details/' + videoid)
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
            }
        </>
    )
}

export default BasicFinance;
