import React from "react";
import "./process.css";
import AboutImg from "../../assets/img/course-details.jpg";

const ProcessCount = () => {
  return (
    <>
      <section className="bg-sect-col">
        <div className="container">
          <div class="row py-3 gy-5">
            <div class="col-lg-6">
              <h3 class="font-weight-bold text-transform-none text-7 line-height-2 mb-3">
                Our Mission
              </h3>
              <p class="mb-0">
                {" "}
                At Course Tsunami, our mission is to reshape the future of
                education by empowering individuals to unlock their full
                potential through accessible and engaging online learning. We
                are committed to providing a platform that transcends
                traditional barriers and connects learners with exceptional
                courses and instructors from around the globe. We strive to
                foster a vibrant learning community that supports personal
                growth, professional development, and lifelong learning.
              </p>
            </div>
            <div class="col-lg-6">
              <h3 class="font-weight-bold text-transform-none text-7 line-height-2 mb-3">
                Our Vision
              </h3>
              <p class="mb-10">
                {" "}
                Our vision is to be the leading global online education
                platform, recognized for our commitment to excellence,
                innovation, and learner-centricity. We aim to create a future
                where education is accessible to all, irrespective of
                geographical location or financial constraints. Through our
                cutting-edge technology, curated course offerings, and vibrant
                learning community, we envision Course Tsunami as the go-to
                destination for individuals seeking personal and professional
                growth.
              </p>

              <p class="mb-0">
                {" "}
                We envision a world where learners can explore their passions,
                gain new skills, and connect with like-minded individuals on a
                global scale. By leveraging technology and embracing the
                evolving needs of learners, we strive to set new standards in
                online education. We will continue to push the boundaries of
                innovation, expand our course catalogue, and forge strategic
                partnerships to provide learners with an unparalleled learning
                experience.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProcessCount;
