import {useEffect, useState} from "react"
import {toast} from "react-toastify";
import useHttpHandler from "./httphandler.hook";
import {DEFAULT_FILTERS, TEMP_DEFAULT_FILTERS} from "../constants/app.constants";

const useGetTableList = () => {

    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(false)
    const [filterKeys, setFilterKeys] = useState(DEFAULT_FILTERS)
    const [checkCategory, setCheckCategory] = useState(false);
    const [getCategoryId, setCategoryId] = useState(0);
    const [getUname, setUname] = useState("");
    const [getCourseType, setCourseType] = useState(-1);
    const [checkLevel, setLevel] = useState(false);
    const [getlevelId, setlevelId] = useState(0);
    const [getSubCategoryId, setSubCategoryId] = useState(0);
    const [tableData, setTableData] = useState([])
    const [displayType, setDisplayType] = useState("list");
    const [subCategories, setSubCategories] = useState(null);
    const [dropdownsData, setDropdownsData] = useState(null)
    
    const sortBy = (sortBy, sortType) => {
        DEFAULT_FILTERS.sortby = sortBy;
        DEFAULT_FILTERS.sortkey = sortType;
        getList(DEFAULT_FILTERS);
    }

    const nextPage = (currentPage) => {
        console.log("filerKeys", DEFAULT_FILTERS);
        DEFAULT_FILTERS.page = currentPage + 1;
        getList(DEFAULT_FILTERS);
    }

    const previousPage = (currentPage) => {
        console.log('previousPage')
        DEFAULT_FILTERS.page = currentPage - 1;
        getList(DEFAULT_FILTERS);
    }

    const getFilteredListData = (values) => {
        setKeys()
        DEFAULT_FILTERS.fromdate = values.fromdate
        DEFAULT_FILTERS.todate = values.todate
        getList(DEFAULT_FILTERS);
    }

    const setKeys = () => {
        Object.keys(DEFAULT_FILTERS).forEach(key => {
            delete DEFAULT_FILTERS[key];
        })
        let obj = Object.keys(TEMP_DEFAULT_FILTERS);
        obj.forEach((element, index) => {
            DEFAULT_FILTERS[element] = TEMP_DEFAULT_FILTERS[obj[index]];
        });
    }

    const getList = (keys) => {
        setLoading(true)
        post(keys.uri, keys).then((res) => {
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setTableData(data)
                var obj = Object.keys(keys);
                obj.forEach((element, index) => {
                    DEFAULT_FILTERS[element] = keys[obj[index]];
                });
                DEFAULT_FILTERS.testkey = "test";
                console.log("filerKeys 1", DEFAULT_FILTERS);
            }
        }).catch(() => setLoading(false))
    }

    const getNewList = (keys) => {
        
        post(keys.uri, keys).then((res) => {
          
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setTableData(data)
                var obj = Object.keys(keys);
                obj.forEach((element, index) => {
                    DEFAULT_FILTERS[element] = keys[obj[index]];
                });
                DEFAULT_FILTERS.testkey = "test";
                console.log("filerKeys 1", DEFAULT_FILTERS);
            }
        }).catch(() => setLoading(false))
    }


    const getListData = (keys) => {
      
        post(keys.uri, keys).then((res) => {
        
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setTableData(data)
                var obj = Object.keys(keys);
                obj.forEach((element, index) => {
                    DEFAULT_FILTERS[element] = keys[obj[index]];
                });
                DEFAULT_FILTERS.testkey = "test";
                console.log("filerKeys 1", DEFAULT_FILTERS);
            }
        }).catch(() => console.log("fail"))
    }
    
    const getDropDowns = async () => {
        setLoading(true)
        post('getCommonDropdownData').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                
                assingData(data);
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }

    function assingData(data){
        let studyLevelOptions = []
                let categoriesOptions = []
                let categories = []
                let subCategories = data?.subCategories;
                let subCategoriesOptions = [];
                let moduleOptions = data?.moduleLevel;

        data?.categories?.map((item, index)=>{
            categories.push(item);
            categoriesOptions.push({value:item.catId, label: item.catName,text: item.catName})
        })

        data?.subCategories.map((item, index)=>{
             subCategoriesOptions.push({value:item.subcatId, label: item.subCatName,text:item.subCatName})
            
        })

        setDropdownsData({
            subCategories,
            categoriesOptions,
            subCategoriesOptions,
            categories,
            moduleOptions
        })
    }


    const getCategories = (params)=> {
       // setLoading(true)
        post('commonGetCategories',params).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let categoriesOptions = []
                let categories = []
                data?.listData?.map((item, index)=>{
                    categories.push(item);
                    categoriesOptions.push({value:item.catId, label: item.catName,text: item.catName})
                    
                })

                setDropdownsData({
                    categoriesOptions,
                    categories
                })

            } else {
                toast.error(status.msg)
            }

          //  setLoading(false)
        })
    }

   

    


    return {
        getDropDowns,
        getCategories,
        loading,
        setLoading,
        filterKeys,
        setFilterKeys,
        getList,
        sortBy,
        tableData,
        nextPage,
        previousPage,
        setKeys,
        getFilteredListData,
        checkCategory, 
        setCheckCategory,
        getCategoryId, 
        setCategoryId,
        getlevelId,
        setlevelId,
        getSubCategoryId,
        setSubCategoryId,
        checkLevel,
        setLevel,
        subCategories, 
        setSubCategories,
        displayType, 
        setDisplayType,
        setTableData,
        getListData,
        getCourseType,
        setCourseType,
        dropdownsData,
        getUname, 
        setUname,
        getNewList
    
    }


}

export default useGetTableList;
