import React from 'react';
import Text from '../text'
import './footer.css';
import NavList from '../navlist';
import useTranslations from "../../hooks/translations.hook";
// import Image from '../image';
import Section from '../section';
// import InstagramImage001 from '../../assets/images/instagram_0001.jpg'
// import InstagramImage002 from '../../assets/images/instagram_0002.jpg'
// import InstagramImage003 from '../../assets/images/instagram_0003.jpg'
// import InstagramImage004 from '../../assets/images/instagram_0004.jpg'
// import InstagramImage005 from '../../assets/images/instagram_0005.jpg'
import FacebookIcon from '../svg/facebookIcon';
import TwitterIcon from '../svg/twitterIcon';
import LinkedinIcon from '../svg/linkedinIcon';
import YoutubeIcon from '../svg/youtubeIcon';
import InstagramIcon from '../svg/instagramIcon';
import Logo from '../svg/Logo';
import Icon from '../icon';
import MailIcon from '../svg/mailIcon';
import Flex from '../flex';

import { AiOutlineRight,AiOutlineInstagram,AiFillYoutube,AiFillLinkedin, AiFillTwitterCircle, AiFillRightCircle } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

const Footer = props => {
    const {text} = props

    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    return (
        <>
             <footer id="footer">

<div class="footer-top">
  <div class="container">
    <div class="row">

      <div class="col-lg-3 col-md-6 footer-contact">
        <h3>Course Tsunami Headquarters</h3>
        <p><strong>
        Strawberry Fields Digital HubEuxton Lane <br /> Chorley<br /> PR7 1PS</strong><br />United Kingdom<br />
          <strong>Phone:</strong> +44 1257 826 717<br />
          <strong>Email:</strong> info@coursetsunami.com<br />
        </p>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={'/'} className="route-link"> Home</NavLink></li>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={'/about-us'} className="route-link"> About us</NavLink></li>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={'/pricing-page'} className="route-link"> Pricing</NavLink></li>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={'/contact-us'} className="route-link"> Contact</NavLink></li>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={'/register'} className="route-link"> Register Now</NavLink></li>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={''} className="route-link"> Terms of service</NavLink></li>
          <li><AiOutlineRight size={'14px'} className='footer-arrow' /><NavLink to={''} className="route-link"> Privacy policy</NavLink></li>
        </ul>
      </div>

      <div class="col-lg-3 col-md-6 footer-links">
      
      </div>

      <div class="col-lg-4 col-md-6 footer-newsletter">
        <h4>Join Our Newsletter</h4>
        <p>Stay in the know and never miss out on exclusive offers, latest course releases, and valuable educational resources by signing up for our newsletter.</p>
        <form action="" method="post">
          <input type="email" name="email" /><input type="submit" value="Subscribe" />
        </form>
      </div>

    </div>
  </div>
</div>

 <div class="container py-4">

  <div className='row gy-2'>
    <div className='col-lg-6'>
    <div class="me-md-auto text-center text-md-start">
  <div class="copyright pt-1">
  &copy; Copyright <strong><span>COURSE TSUNAMI</span></strong>. All Rights Reserved
</div>
  </div>
    </div>
    <div className='col-lg-6 text-center text-lg-end'>
    <div class="social-links">
    <a href="https://www.instagram.com/coursetsunami/" target="_blank" class="instagram"><AiOutlineInstagram /> </a>
    <a href="https://www.facebook.com/CourseTsunamiOfficial/" target="_blank" class="facebook"><FaFacebookF /></a>
    <a href="https://twitter.com/CourseTsunami" target="_blank"><AiFillTwitterCircle/></a>
    <a href="https://www.pinterest.co.uk/CourseTsunami/" target="_blank"><AiFillRightCircle/></a>
    <a href="https://www.youtube.com/@CourseTsunami" target="_blank" class="youtube"><AiFillYoutube /></a>
    <a href="https://www.linkedin.com/company/course-tsunami" target="_blank" class="linkedin"><AiFillLinkedin /></a>
    
    
  </div>
    </div>
  </div>
 

</div>
</footer>
        </>
    );
};

export default Footer;
