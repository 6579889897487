import React, { useEffect, useState } from 'react';
import './image.scss';
import useVideosCommonHooks from '../../pages/instructor/createCourse/videosCommonHook';

const Image = props => {

    const commonHook = useVideosCommonHooks();
    

    const [imageUrl, setImageUrl] = useState('');
    

    useEffect(() => {
        console.log("props.image",props)
      // Fetch pre-signed URL from the backend
      commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':props.src}).then((data) => {
        //console.log("data.data",data.data)
        console.log("data.data",data.data)
        setImageUrl(data.data)
     
       
    });
    }, []);
  
    


    return (
        <div className="image-element">
            <img 
                src={imageUrl} 
                alt={props.alt} 
                width={props?.width + 'px'}
                height={props?.height + 'px'}
                className={`${props.widthFull ? 'width-full' : ''} ${props?.width || props?.height ? '' : 'img-fluid'} ${props?.className}` }
                style={{
                    borderRadius: props?.rounded + '%'}}
            />
        </div>
    );

};

export default Image;
