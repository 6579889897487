import React, {useEffect} from "react";
import LayoutGuru from '../../../../components/layoutGuru';
import useAccount from "./hooks/account.hook";
import AccountView from "./view/AccountView";
import Loader from "../../../../components/loader";

const Account = props => {
    const accountHook = useAccount()
    const {text} = props
    if (accountHook.loading) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }
    return (
        <>
            <LayoutGuru {...props}>
                <AccountView {...props} {...accountHook} />
            </LayoutGuru>
        </>
    )
}

export default Account;
