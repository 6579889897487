import React, {useEffect, useRef, useState} from 'react';
import TotalViewAndLikesList from "./TotalViewAndLikesList";
import Text from "../../../../../components/text";
import DropdownNew from "../../../../../components/dropdown/dropdownNew";
import Button from "../../../../../components/button";
import useGetTableList from "../../../../../hooks/getTableListCommonHook";
import {DEFAULT_FILTERS} from "../../../../../constants/app.constants";
import useHelper from "../../../../../hooks/helpers.hook";
import Loader from "../../../../../components/loader";
import LineChartGraph from "./LineChartGraph";
import Pdf from "react-to-pdf";

const TotalViewAndLikesView = props => {
    const ref = useRef()
    const {getMonthOptions, getYearOptions} = useHelper()
    const [monthOption, setMonthOption] = useState(getMonthOptions())
    const yearsOption = getYearOptions()
    const viewsOption = [{id: 'views', value: 'Views'}, {id: 'likes', value: 'Likes'},]
    const commonHook = useGetTableList();
    const [viewsSelected, setViewsSelected] = useState(viewsOption[0]);
    const [yearSelected, setYearSelected] = useState(yearsOption.at(-1));
    const [monthSelected, setMonthSelected] = useState('');
    const options = {

    };

    const getList = (monthSelected, yearSelected, viewsSelected) => {
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'guru_my_reports_views_likes';
        DEFAULT_FILTERS.month = monthSelected ? monthSelected?.id : -1;
        DEFAULT_FILTERS.year = yearSelected ? yearSelected?.id : 0;
        DEFAULT_FILTERS.type = viewsSelected?.id;
        commonHook.getList(DEFAULT_FILTERS)
        props?.getTotalViewAndLikesGraphData(
            {
                type: DEFAULT_FILTERS.type,
                month: DEFAULT_FILTERS.month,
                year: DEFAULT_FILTERS.year,
            }
        )
    }

    useEffect(() => {
        setMonthOption(getMonthOptions(yearSelected.id))
    }, [yearSelected])

    useEffect(() => {
        getList(monthSelected, yearSelected, viewsSelected)
    }, [yearSelected, monthSelected, viewsSelected])


    return (
        <>
            <div ref={ref} className="report-analytic-card">
                <div className="report-analytic-head align-items-start">
                    <div>
                        <Text
                            type="H3"
                            className="mb-10 line-height-1"
                            text="Total Views and Likes"
                        />
                        <Text
                            type="PARAGRAPH"
                            className="mb-0 line-height-1 font-weight-400"
                            text="Views Analytic"
                        />
                    </div>
                    <div className="filterby-main">
                        <div className="filterby mb-20">
                            <Text type="PARAGRAPH" text="Filter by:" className="text-white-half mb-0 mr-10"/>
                            <div className="filterby-lists">
                                <DropdownNew
                                    secondaryDropdown
                                    right
                                    selected={monthSelected}
                                    setSelected={setMonthSelected}
                                    options={monthOption}
                                    placeholder="Select Month"
                                />
                                <DropdownNew
                                    secondaryDropdown
                                    right
                                    selected={yearSelected}
                                    setSelected={setYearSelected}
                                    options={yearsOption}
                                    placeholder="Select Year"
                                />
                                <DropdownNew
                                    secondaryDropdown
                                    right
                                    selected={viewsSelected}
                                    setSelected={setViewsSelected}
                                    options={viewsOption}
                                    placeholder="Select Views"
                                />
                            </div>
                        </div>

                        <Pdf
                            targetRef={ref}
                            filename="total-views-and-likes-report.pdf"
                            options={options}

                        >
                            {({ toPdf }) => <Button
                                type="button"
                                text="Generate Report"
                                onClick={toPdf}
                            />}
                        </Pdf>
                    </div>
                </div>
                {props?.totalViewsLikesLoading ? (
                    <Loader/>
                ) : (
                    <div className="report-analytic-body">
                        <LineChartGraph graphData={props?.viewsLikesGraph}/>

                        <Text
                            type="PARAGRAPH"
                            className="text-white-half mb-30"
                            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "
                        />
                        <TotalViewAndLikesList commonHook={commonHook}/>
                    </div>
                )}
            </div>
        </>
    )
}
export default TotalViewAndLikesView;

