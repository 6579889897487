import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet'
import {useMediaQuery} from 'react-responsive';
import HeaderGuru from '../headerGuru';
import NavbarGuru from '../navbarGuru';
import './layoutGuru.scss'
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import GlobalInfoModal from "../globalInfoModal";
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import Sidebar from '../sidebar';
import FooterLearner from '../footerLearner';

import { CgProfile } from "react-icons/cg";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsBank } from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import { BiCategory } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";

import RedeemIcon from "../svg/redeemIcon";
import DashboardIcon from '../svg/dashboardIcon'
import Likes2Icon from '../svg/likes2Icon'
import AddressBookIcon from '../svg/addressBookIcon'
import ReportIcon from '../svg/reportIcon'
import BoxCheckIcon from '../svg/boxCheckIcon'

import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList
}from "react-icons/fa";
import { SiDiscourse } from 'react-icons/si';
import createCourseIcon from '../../assets/img/createCourseIcon.png'
import SmartDisplayIcon from '../svg/smartDisplayIcon';

const LayoutGuru = ({props, children, text, noBgContent,footer}) => {

    const {convertUser, isSwitchingUser} = useLogin()
    const [isOpenModal, setIsOpenModal] = useState(false);
    const navigate = useNavigate()
    const {pathname} = useLocation();
    const userInfo = useSelector(state => state.app.userInfo)
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 })
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const [sideMenu, setSideMenu] = useState(false);
    const SideMenuToggle = () => {
        setSideMenu(state => !state);
    }
    const hideItem = userInfo?.user_status_id == 2 || userInfo?.user_status_id == 0
    const allowPath = pathname == '/instructor/dashboard' || pathname == '/instructor/settings/profile' || pathname == '/instructor/settings/bank-details' || pathname == '/instructor/settings/account'


    useEffect(() => {
        if (hideItem && !allowPath) {
            setIsOpenModal(true)
            //navigate('/instructor/dashboard')
        }
    }, [])

    const menuItem=[
        {
            path:"/instructor/dashboard",
            name:"Dashboard",
            icon:<FaTh/>,
            items:[]
        },
        {
            path:"/instructor/course",
            name:"Courses",
            icon:<img src={createCourseIcon} className="icon-25"/>,
            items:[]
            
        },
/*
        {
            path:"/instructor/free-course",
            name:"Free Courses",
            icon:<SiDiscourse/>,
            items:[]
            
        },
       
        {
            name:"Total Topic Videos",
            path:'/instructor/my-video',
            icon:<SmartDisplayIcon />,
            items:[]
        },
        */
        {
            name:"My Commission",
            path: '/instructor/monetization/my-commission',
            icon:<BoxCheckIcon />,
            items:[]
        },/*
        {
            name:"Chat",
            path:'/instructor/chat-board',
            icon:<SmartDisplayIcon />,
            items:[]
        },*/
        {
            name:"Chat",
            path:'/instructor/chat',
            icon:<SmartDisplayIcon />,
            items:[]
        },
        {
            name:"Meeting",
            path:'/instructor/meeting',
            icon:<SmartDisplayIcon />,
            items:[]
        },
       
    ]


    return (
        <>
            <Helmet>
                <title>COURSETSUNAMI</title>
                <meta name="description" content="COURSETSUNAMI"/>
            </Helmet>
            <div className="layout-guru-wrapper">

                <HeaderGuru text={text}/>
                <div className={`lp-main-wrapper ${isTablet ? sideMenu ? '' : 'lp-main-tablet' : ''}`}>
                   {/* {!isMobile && (
                        <div className="lp-sidebar-wrapper">
                            <NavbarGuru
                                sideMenu={sideMenu}
                                SideMenuToggle={SideMenuToggle}
                                text={text}
                                {...text}
                            />
                        </div>
                    )}
                    <div className={`lp-content-main-wrapper ${noBgContent ? 'no-content-bg' : ''} `}>
                        <div className="main-wrapper">
                            {children}
                        </div>
                    </div>
                   */}


                    <Sidebar menuItem={menuItem} onMouseOut="">
                            <div className="lp-content-main-wrapper" style={{"width" : "100%"}}>
                                <div className="main-wrapper" style={{"width" : "100%"}}>
                                    {children}
                                    {footer && <FooterLearner {...text} />}
                                </div>
                            </div>
                    </Sidebar>

                </div>
            </div>
           {/** {isOpenModal && (
                <GlobalInfoModal
                    title='Your Application is Currently Being Reviewed'
                    subtitle='Please wait we are currently reviewing your appilcation you may'
                    buttonText='Sign Up as Learner'
                    canClose={false}
                    onClick={()=>{convertUser('be_a_learner')}}
                    isSubmitting={isSwitchingUser}
                    buttonType='linkSecondary'
                />
            )} */}
        </>
    );
};

export default LayoutGuru;
