import React, {useState} from 'react';
import Text from '../../../../components/text';
import 'swiper/css';
import Section from '../../../../components/section';
import Top10Videos from '../../dashboard/components/Top10Videos';
import Breadcrumbs from '../../../../components/breadcrumbs';
import LearnByTopics from '../../../../components/learnByTopics';

const TopTenVideosView = (props) => {

  const breadcrumbList = [
    {
        text: 'Dashboard',
        link: '/trainee/dashboard',
    },
    {
        text:"Courses",
        link:"/trainee/courses",
    },
    {
      text:"Top Ten Topics",
      link:"/trainee/top-ten-videos",
  },
]

    return (
      <>
         <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />

<Section className="pt-0 lbt-section rml-24 overflow-outer">
                <div className="section-head mt-2">
                    <Text
                        type="H3"
                        text="Top Ten Topics"
                        className="section-title text-uppercase"
                    />
                </div>
                <LearnByTopics {...props}/>
             </Section>   
      
      
     
      </>
    );
};

export default TopTenVideosView;

