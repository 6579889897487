import React from "react";
import './videoplayer.scss'

const VideoPlayer = props => {
    return(
        <iframe 
                src={props.videourl} 
                width={props.width} 
                height={props.height}
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen
                title={props.title}
            >
        </iframe>
    )
}

export default VideoPlayer;

VideoPlayer.defaultProps = {
    videourl: "https://player.vimeo.com/video/115783408?h=def2b8dd8f",
    width: "100%",
    height: "360",
    title: "Video Player",
}