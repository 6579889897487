import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useSelector} from "react-redux";
import useHelper from "../../../../hooks/helpers.hook";

const useReferenceLink = () => {
    const  {updateUserMetaData} = useHelper()
    const [userName, setUserName] = useState('')
    const  {post} = useHttpHandler()
    const navigate = useNavigate()

    const [isModal, setIsModal] = useState(false);
    const openReferenceLink = () => {
        navigate('/trainee/signup')
    }
    const goBackToSubscribe = () => {
        navigate('/trainee/subscribe')
    }
    const closeModal = ()=>{
        setIsModal(false)
        updateShareStatus()
    }
    const getUserInfoByReferralCode = (refcode)=>{
        post('get_user_name', {ref_code: refcode}).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result === '200') {
                setUserName(data?.fullname ? data?.fullname : data?.uname)
            } else {
                toast.error(status.msg)
            }
        })
    }
    const updateShareStatus = ()=>{
        post('update_share_status').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result === '200') {
                updateUserMetaData(data[0])
                if(data[0]?.is_profile_completed) {
                    navigate('/trainee/dashboard')
                } else {
                    if(data[0]?.is_survey_completed) {
                        navigate('/trainee/personal-information')

                    } else {
                        navigate('/trainee/survey-question-1')
                    }
                }
            } else {
                toast.error(status.msg)
            }
        })
    }
    return {
        isModal,
        setIsModal,
        openReferenceLink,
        goBackToSubscribe,
        closeModal,
        updateShareStatus,
        getUserInfoByReferralCode,
        userName
    }

}
export default useReferenceLink
