import React from "react";
import CategeoryDetailspageView from "./views/categeoryDetailsPageView";


const CategeoryDetaisPage =()=>{
    return(
        <>
        <CategeoryDetailspageView />
        
        </>
    )
}

export default CategeoryDetaisPage;