import React from "react";
import { NavLink } from "react-router-dom";
import './pageHeader.css';


const PageHeader =(props)=>{
    return(
        <>
          <section class="page-header page-header-modern page-header-background page-header-background-sm m-0 pageHeaderBack">
        <div class="container">
            <div class="row">
                <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
                    <h1 class="font-weight-bold text-8 mb-0">{props.title}</h1>
                </div>
                <div class="col-md-4 order-1 order-md-2 align-self-center">
                    <ul class="breadcrumb breadcrumb-light d-block text-md-end text-4 mb-0">
                        <li><NavLink to={'/'}>Home</NavLink></li>
                        <li class="active text-light px-2">/ {props.page}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}


export default PageHeader;