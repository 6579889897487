import React, {useEffect, useState,useRef} from "react";
import LayoutGuru from "../../../components/layoutGuru";

import useHelper from "../../../hooks/helpers.hook";
import {useSelector} from "react-redux";
import Text from "../../../components/text";
import Button from "../../../components/button";
import useLogin from "../../authentication/login/hooks/login.hook";
import Flex from "../../../components/flex";
import NoticeCard from "../../../components/noticeCard";
import IncompleteProfileIcon from "../../../components/svg/incompleteProfileIcon";
import PendingApprovalIcon from "../../../components/svg/pendingApprovalIcon";
import RejectIcon from "../../../components/svg/rejectIcon";


import Loader from "../../../components/loader";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../../../constants/app.constants";
import { message } from "antd";
import Modal from "../../../components/modal";
import ChatConfirmInstructor from "../../../components/chatConfirmationInstructor";

import MeetingDashBoardView from "./views/MeetingDashBoardView";
import useVideosCommonHooks from "../createCourse/videosCommonHook";
import useChatHook from "../../trainee/chat/hooks/chat.hook";
import useGuruDashboard from "../chat/hooks/guruDashboard.hook";




const MeetingDashboard = props => {
    
    const commonHook = useVideosCommonHooks
    const guruDashboardHook = useGuruDashboard()
  
    const userInfo = useSelector(state => state.app.userInfo)
    
     const chatHook = useChatHook();

     const [startTime,setStartTime] = useState(0);

    // const titlesObj = {title: "Chat Board",showDashboard:1}

     const popupEvets = (type) => {
        
      }
    
      useEffect(() => {
       
        guruDashboardHook.getMeedingDashboardDetails()
  
    }, [])
      


 

    if (guruDashboardHook?.loading) {
  
        return (
            <>
               <LayoutGuru {...props}>
                <Loader></Loader>
                </LayoutGuru>
            </>
        )
      
      } else {

    return (
        <>
            <LayoutGuru {...props} footer>
                
                { /*chatHook.isModal &&(<Modal  
               
                onOuterClose={() =>  chatHook.setIsModal(false)}
                
                modalContent={
                    <>
                     <ChatConfirmInstructor {...props} userInfo={userInfo}  {...chatHook} commonHook={commonHook}  />         </>
                }
            />) */}
            
           
                <MeetingDashBoardView {...props} userInfo={userInfo}  {...chatHook} commonHook={commonHook}  startTime={startTime} setStartTime={setStartTime}  guruDashboardHook={guruDashboardHook}/>
            </LayoutGuru>
        </>
    )
      }
}

export default MeetingDashboard;
