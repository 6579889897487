import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import WorkExperienceView from "./view/WorkExperienceView";
import InfomationNavSideGuru from "../../../components/infomationNavSideGuru";
import useWorkExperience from "./hooks/workExperience.hook";
import useProfile from "../settings/profile/hooks/profile.hook";
import Loader from "../../../components/loader";
import logoImg from '../../../assets/userimg/login-bg2.png';
import { useSelector } from "react-redux";

const WorkExperience = props => {
    const {text} = props
    const workExperienceHook = useWorkExperience()
    const profileHook = useProfile()

    const isLoaded = !profileHook.loading && !workExperienceHook?.loading

    const userInfo = useSelector(state => state.app.userInfo)
    
    let selectedCategory = [];
    
    if(profileHook.profileDetails?.selectedCategories != null || profileHook.profileDetails?.selectedCategories == ""){
        selectedCategory = profileHook.profileDetails?.selectedCategories;
        const selectedCategoryArray = selectedCategory.split(",");
        if(workExperienceHook.selectedTopicsOfInterestCategory.length <= 0){
                for(var i = 0;i<selectedCategoryArray.length;i++){
                    workExperienceHook.selectedTopicsOfInterestCategory.push(parseInt(selectedCategoryArray[i]))
                }
        }
    };

   


    useEffect(() => {
        workExperienceHook.getDropDowns()
        profileHook?.getProfileDetails()
    }, [])
    if (!isLoaded) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
         <div class="register_instructor">
        <main>
  <div class="container-fluid">

    <section class="section py-4">
      <div class="container">
      <div className="row">
      <div className="col-md-6">
            <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
            </div>
            <div className="col-md-6">
            <div class="row justify-content-center">
            <div class="col-lg-12 col-md-6 bg-light">
                <WorkExperienceView
                    {...props}
                    {...workExperienceHook}
                    profileDetails={profileHook.profileDetails}/>

</div>
            </div>
            </div>
        </div>
       
      </div>

    </section>

  </div>
</main>
</div>
            
        </>
    )

}

export default WorkExperience;
