import React, { useState } from 'react';
import Text from "../../../../components/text";
import VideoList from '../../../../components/videoList';
import CourseImage from '../../../../assets/images/video-list-poster-image.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from '../../../../components/input/select';
import FlowCard from '../../../../components/flowCard';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import useHelper from '../../../../hooks/helpers.hook';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Table from '../../../../components/table';
import CourseCard from '../../../../components/courseCard';
import Pagination from '../../../../components/pagination';
import Modal from '../../../../components/modal';
import TotalTopicSearch from '../../../../components/total_topic_search';
import TotalTopicSearchCard from '../../../../components/total_topic_card';
import Breadcrumbs from '../../../../components/breadcrumbs';

const GuruMyVideoView = (props) => {
    const {formatDateDDMMYY, getShortDescription} = useHelper()
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const tableData = props?.tableData?.listData
    const toggleIsModal = (video) => {
        setVideoDetails(video)
        setIsModal(state => !state);
    }
    const [videoDetails, setVideoDetails] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [yearSelected, setYearSelected] = useState('Year 2022');
	const [monthSelected, setMonthSelected] = useState('Select Month');
	
    

    const {
        dropdownsData,
        
    } = props
    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text:"Course",
            link:"/instructor/course",
        },
        {
            text:"All Topic Videos",
            link:"/instructor/my-video",
        },
    ]

    return (
        <>

<Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
            <Text type="H1" text="Course" />
            <Text type="H3" text="All Topic Videos" />
            <TotalTopicSearch
            search="Topic Search"
            dropdownsData ={props.dropdownsData}
            _props = {props}
            
            />




<Table
thead={
    <tr>
       
    </tr>
}
tbody={
    <>
       
       {tableData?.map(function (item, index) {
            return (
                <tr>
                
                   <TotalTopicSearchCard
                      poster={item.thumbnail}
                      categeoryname="categeoryname"
                      courseId={item.courseid}
                      categeorytitle={item.catName}
                      subcategeory="subcategeory"
                      subcategeorytitle={item.subCatName}
                      course="Course Name"
                      coursetitle={item.coursetitle}
                      modulename="module name"
                      moduletitle={item.moduletitle}
                      module="Topic name"
                      value={item.title}
                      availablevideos="Module Level"
                      valuevideo={item.videolevel}
                      status="Status"
                      active={item.statusbit}
                      inactive="InActive"
                      statusactive="Active"
                      link={"/instructor/modules/"+item.courseId}
                      desc={item.des}
                      button="Edit"
                      alt= "video poster"
                      onPlay={() => {
                        toggleIsModal(item)
                    }}
                    hideDescription
                    duration={item?.videoduration}
                    
                      />
                  <br />
                
                </tr>
              
            )
        })}
    </>
}
/>
<Pagination
totalPages={props?.tableData?.totalPages}
currentPage={props?.tableData?.currentPage}
totalRecords={props?.tableData?.totalRecords}
nextPage={props?.nextPage}
previousPage={props?.previousPage}
perPage={props?.filterKeys?.perpage}
/>
{isModal &&
            
            <Modal
               className="modal-light"
                onClose={() => {
                    setIsModal(false)
                    setShowVideoEnded(false)
                }}
                onOuterClose={() => {
                    setIsModal(false)
                    setShowVideoEnded(false)
                }}
                modalContent={
                    <>
                        <div className="homevideomodal-wrapper">
                            <div className="homevideomodal-main">
                                <StaticVideoPlayer videoDetails={videoDetails} showVideoEnded={showVideoEnded}
                                                  
                                                   videoEnded={setShowVideoEnded}/>


                            </div>
                            <div className="homevideomodal-content-bottom">
                                <Text
                                    type="H2"
                                    className=""
                                    text={videoDetails?.title}
                                />
                                {/*<Text
                                    type="PARAGRAPH"
                                    className="mt-0 mb-3 text-white-half"
                                    text={videoDetails?.title}
                                />*/}
                                <TextHtmlParser
                                    className="mb-0 text-white-half font-weight-400"
                                    text={videoDetails?.des}
                                />
                            </div>
                        </div>
                    </>
                }
            />
            
            }
        </>
    );
};

export default GuruMyVideoView;

