import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import * as Yup from "yup";

import Breadcrumbs from '../breadcrumbs';
import Button from '../button';
import FlowCard from '../flowCard';
import Image from '../image';
import Input from '../input';

import Select from '../input/select';
import Textarea from '../input/textarea';
import ProgressBarCard from '../programBarCard';
import Text from '../text';
import { UploadComponent } from '../../pages/instructor/uploadAttachments/view/UploadAttachmentsView';

import useHttpHandler from '../../hooks/httphandler.hook';
import useHelper from "../../hooks/helpers.hook";
import axios from "axios";
import {toast} from "react-toastify";
import useGuruUploadVideo from '../../pages/instructor/uploadVideo/hooks/guruUploadVideo.hook';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE } from '../../constants/app.constants';
import Flex from '../flex';
import InputFile from '../input/inputFile';
import Icon from '../icon';
import TickIcon from '../svg/tickIcon';
import CameraIcon from '../svg/cameraIcon';
import ProgramBarSecondaryCard from '../programBarCard/programBarSecondaryCard';
import Modal from '../modal'
import StaticVideoPlayer from '../videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../htmlparser';
import VideoPlayer from "../videoplayer";

import UploadVideoPreview from '../addTopicCompnent/videoUploadPreview';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import useNewCommonHook from '../../hooks/newCommonHook';
import TimeButton from '../timeSlot/timeButton';
import SelectNew from '../input/selectnew';



const RescheduleMeeting = (props) => {
    //const [progress, setProgress] = useState(null);
    const navigate = useNavigate();
    const {videoId} = useParams();
    const {post} = useHttpHandler();
    const {getFileExtension, getFileSize} = useHelper()
    const {goToUploadSuccess, goToGuruVideoGuideline} = useGuruUploadVideo();
    const location = useLocation();
    const [isModal, setIsModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
    const window_path = window.location.pathname
    var coursetype = 0;
    const test = window_path.includes("instructor/create-course");
    const commonHook = useNewCommonHook();
    const {formatDateYYYYMMDD,formatDateHms,formatDateHH} = useHelper();
    

    

    if(test){
        coursetype = 1;
    }else if(window_path.includes("/instructor/private-course")){
        coursetype = 2;
    }

   

    const [subCategories, setSubCategories] = useState(null)
    var videoSampleFile = [];

    const formik = useFormik({
        initialValues: {
            title: '',
            price:'',
            category:  '',
            subcategory: '',
            meetingtype:'',
            description: '',
            sampleFile: [],
            pdfsampleFile: [],
            videosampleFile:videoSampleFile,
        },
    
        validationSchema: Yup.object({
          
            category: Yup.string().required('Please enter a valid Categeory'),
            meetingtype: Yup.string().required('Please enter a valid Meeting Type'),
            price: Yup.string().required('Please enter a price'),
            subcategory: Yup.string().required('Please enter a valid Sub Categeory'),
            title: Yup.string().required('Please enter a valid title'),
           
            
        }),
    
        onSubmit: (values, actions) => {
            actions.setSubmitting(false)

            console.log("12123123123");
            
            submit(values,actions)


            
            

        },
    });   
       // formik.setFieldValue("price", 0)
        
    
    
   

    const setMeetingDropdown = (e) => {
        formik.setFieldValue('meetingtype', e.value)



   
    }

    const setSubCategoriesMethod = (e) =>{
        formik.setFieldValue('subcategory',e.value);
        
    }
   
    const getSubCategories = (e) => {
        formik.setFieldValue('category', e.value)
        getSubCategoriesById(e.value)
    }
    const getSubCategoriesById = (catId) => {
        let subCategoryOptions = []
        if (props?.dropdownsData?.subCategories) {
            props?.dropdownsData?.subCategories.filter((item, index) => {

                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatId, label: item.subCatName,text:item.subCatName})
                }
            })
        }

        console.log(subCategoryOptions,catId,props?.dropdownsData?.subCategoriesOptions);
        setSubCategories(subCategoryOptions);

    }
    
    
    

    const hasProfileImage =  '' ;
    const profileImage = '';

    const showInputFile = !hasProfileImage || formik.values.file

    const [date, setDate] = useState(new Date());

  
    const [callTypes , setCallTypes]  = useState("Consultations");
    const [showPicker , setshowPicker]  = useState(false); 
    const [selectedName , setSelectedName]  = useState("Search with name or mobile");
    const [orginaldefaultTimeSlot,setOrginalDefaultTimeSlot] = useState([]);
    const [defaultTimeSlot,setDefaultTimeSlot] = useState([]);
    const [selectedTimeSlot,setSelectedTimeSlot] = useState([]);
    const [selectedDate,setSelectedDate] = useState(formatDateYYYYMMDD(new Date()));
    const [disabledDays,setDisabledDays] = useState([]);
    const [meetingData,setMeetingData] = useState([]);
    const [showBackConfirmation,setShowBackConfirmation]= useState(false)
    const [sucessMsg,setSucessMsg] = useState("");
  
    var datesArr = [];
  
    
    useEffect(() => { 


        if(props.pageType == "course"){
          formik.setFieldValue('subcategory',0);
          formik.setFieldValue('category', 0)
        }
       
     
      for(var i=1;i<8;i++){
        const today = new Date();
        datesArr.push(formatDateYYYYMMDD(new Date(today.setDate(today.getDate() - i))))
      }
   
      setDisabledDays(datesArr)
      
      commonHook._promiseService({"uri":"getInstructorTimeSlots"}).then((data) => {
        console.log(data.data.data);
        if(data.data.status.result == 200){
          const tempArray = [];
          data.data.data.meetingTypes.map((item,index) => {
            tempArray.push(item.metting);
          })
          setMeetingData(data.data.data.meetingTypes);
          setPickerData(data.data.data.meetingTypes)
          setOrginalDefaultTimeSlot(data.data.data.defaultSlot);
          defaultTimeSlot(data.data.data.defaultSlot);
          setSelectedTimeSlot(data.data.data.todaySlot);
         
       //  checkDatesAvaliable(); 
        }
  
      });
  
    },[]); 
  
    useEffect(() => { 
  
      checkDatesAvaliable();
    },[selectedTimeSlot]); 
  
    useEffect(() => { 
  
      if(callTypes === "Webinars"){
  
      }
      
   
    },[callTypes]); 
  
    
    
    
    const checkDatesAvaliable = ()=>{
      
      var tempArray = [];
      for(var i =0;i<orginaldefaultTimeSlot.length;i++){
        tempArray.push(orginaldefaultTimeSlot[i]);
        tempArray[i]['avaliable'] = 0; 
      }
    
  
      const currentdata = formatDateYYYYMMDD(new Date())
      const currentTime = formatDateHH(new Date());
      console.log("selectedDate--->",selectedDate,currentdata,formatDateHH(new Date()),tempArray)
      for(var i =0;i<tempArray.length;i++){
  
        if(selectedDate == currentdata){
          console.log("--> ",formatDateHH(currentdata + " " + tempArray[i]['timeslot']) , formatDateHH(new Date()))
  
          if(formatDateHH(currentdata + " " + tempArray[i]['timeslot'])  < formatDateHH(new Date())){
            tempArray[i]['avaliable'] = -1; 
          }
        }
       
          for(var j =0;j<selectedTimeSlot.length;j++){
            
            
  
            if(tempArray[i]['idno'] == selectedTimeSlot[j]['slot_id']){
              tempArray[i]['avaliable'] = -1; 
            }
  
            
  
          } 
      }
      setDefaultTimeSlot(tempArray);
    }
  
    const [showContactsPopup , setShowContactsPopup]  = useState(false); 
  
   
  
    const [pickerData , setPickerData]  = useState([]); 
    const closePickerMethod = () =>{
      console.log("close")
      setshowPicker(false);
    }
  
    const [selected, setSelected] = useState('');
  
  
    
  
    let datesWhitelist = [{
      start: new Date(),
      end: new Date() // total 4 days enabled
    }];
    let datesBlacklist = [  ]; // 1 day disabled
  
    const dateSelected =(date) =>{
      console.log("datesWhitelist",datesWhitelist)
      
     
    }
  
    const handleDateSelected = (date) => {
      console.log('Selected date:', date);
      setDate(date);
      setSelectedDate(formatDateYYYYMMDD(date))
  
      
      setDefaultTimeSlot(orginaldefaultTimeSlot);
      commonHook._promiseService({"uri":"getInstructorDateWiseTimeSlots","date":formatDateYYYYMMDD(date)}).then((data) => {
        
        if(data.data.status.result == 200){
          console.log(data.data.data);
          setSelectedTimeSlot(data.data.data);
         
  
        }
  
      });
      // Your custom logic here
    };
  
    const submit = (values,actions) =>{
  
      var slot_data = "";
      var mettingId = 0;
    
      defaultTimeSlot.map((item,index) => {
  
        if(item.avaliable == 1){
          slot_data += item.idno + ','
        }
  
      })
  
      meetingData.map((item,index) => {
        console.log("slot_data",item,callTypes)
        if(item.metting === callTypes){
          mettingId = item.idno;
        }
  
      })
  
     
  
      if(selectedDate == ""){
        setSucessMsg("Please Selecet Valid Date")
        setShowBackConfirmation(true)
      }
      if(slot_data == ""){
        setSucessMsg("Please Selecet Valid Time Slot")
        setShowBackConfirmation(true)
      }
      

      if(props.pageType == "course"){
        values.course_id = props.courseId;
        values.category = 0;
        values.subcategory = 0;
      }
   
      commonHook._promiseService({"uri":"planMyDaySubmit","slot_data":slot_data,'slot_date':selectedDate,metting_type_id:values.meetingtype,"notes":values.description,"category_id":values.category,"subcategory_id":parseInt(values.subcategory),"meetingTitle":values.title,"price":values.price,"course_id":0}).then((data) => {
        
        if(data.data.status.result == 200){
          if(props.pageType == "course"){
            toast.success("sucessfully added meeting")
            props.setisShowPopUP(false);
          }else{
            actions.setSubmitting(false);
            toast.success("sucessfully added meeting")
            navigate('/instructor/meetings')
          }

        }else{
            actions.setSubmitting(false)
        }
      
  
      });
      
    }
  
    const noconfirm = () =>{
      setShowBackConfirmation(false);
    
    }
  
  
   

    useEffect(()=>{
       
    
        handleDateSelected(new Date());
},[])

    const [showConfirmation,setShowConfirmation] = useState(false);
    
  return (
    <div>
      
        
     {isModal &&
            <Modal
                onClose={() => setIsModal(false)}
                onOuterClose={() => setIsModal(false)}
                modalContent={
                    <>
                        <VideoPlayer
                            videourl={process.env.REACT_APP_IMG_URL + videoDetails.newfile_name}
                            width="100%"
                            height="460"
                        />
                    </>
                }
            />
    }


<div className='calendar-container'>
        
      </div>
     

    <form onSubmit={formik.handleSubmit}>

        
    <div className="card border-radius-5 pad-10 ">

          
          


<div className='row'>

    <div className=" col-md-4">
    
        <Calendar onChange={handleDateSelected} value={date} minDate={new Date()}   />

        
    
    </div>

    <div className="col-md-8">


    {defaultTimeSlot.map((item,index) => (
                        <>
                        
                          <TimeButton item={item} index={index} setDefaultTimeSlot={setDefaultTimeSlot} defaultTimeSlot={defaultTimeSlot} /> 
                        
                        </>
                       ))}



    </div>
 
</div>
     </div>   

        
    <div className="action-buttons-end">
                
                <Button
                    type="button"
                    text="cancel"
                    onClick={()=>{
                      
                      if(props.meetingtype == "individual"){
                        props?.meetingCancel();
                      }else{

                      props.setisShowPopUP(false);
                      
                      }


                    }
                      
                    
                    
                    
                    }
                />
                
                <Button
                    outline
                    type="submit"
                    text="save"
                    disabled={formik.isSubmitting}
                    isSubmitting={formik.isSubmitting}
                />
    </div>




                
      </form>


      

    </div>

  )
  
  
}





export default RescheduleMeeting;

