import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import BankDetailsView from "./view/SettingsBankDetailsView";
import useBankDetails from "./hooks/settingsBankDetails.hook";
import Loader from "../../../../components/loader";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import GlobalInfoModal from "../../../../components/globalInfoModal";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const BankDetails = props => {
    const navigate = useNavigate()
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const userInfo = useSelector(state => state.app.userInfo)
    const [isModal, setIsModal] = useState(!userInfo?.is_profile_completed)

    const bankDetailsHook = useBankDetails()
    useEffect(() => {
        if(!subscribePremiumModalHook.isFreemium) {
            bankDetailsHook.getBankDetails()
        }
    }, [])

    if (bankDetailsHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
  {/**  {subscribePremiumModalHook.isPremiumSubscribeModal &&
        <SubscribePremiumModal setIsModal={subscribePremiumModalHook.setIsPremiumSubscribeModal} navigateBack/>
        }
        {isModal && (
            <GlobalInfoModal
                title='In order to receive commissions and unlock contents, fill in complete all details.'
                buttonText='Fill in extra detail'
                canClose={true}
                smallModal
                onClick={()=>{navigate('/trainee/bank-details')}}
                setIsModal={setIsModal}
            />
        )} */}

    return (
        <>
            <LayoutPanel {...props}>
            
                <BankDetailsView {...props} {...bankDetailsHook} />
            </LayoutPanel>
        </>
    )
}

export default BankDetails;
