import { StyleSheet, Text, View, Pressable,Image } from "react";
import './timeButton.scss'
const TimeButton = (props) => {
  
  const item = {...props.item}
    return (
           <> 

                {props.item?.avaliable === -1 &&(
                  <button className="slotBlocked" onClick={()=>{ console.log("props.iten",props.item)}}>
                  <text className="">{item.title}</text>
                  </button>
                )}

                {props.item?.avaliable === 0 &&(
                 <button className="slotInactive" onClick={()=>{
                     console.log("props.iten",props.item)
                        item.avaliable = 1;
                        
                        const tempArray = [];
                          for(var i=0;i<props.defaultTimeSlot.length;i++){
                            if(item.idno == props.defaultTimeSlot[i].idno){
                              tempArray.push(item)
                            }else{
                              tempArray.push(props.defaultTimeSlot[i])
                            }
                            
                          }
                          props.setDefaultTimeSlot( tempArray); 
                  
                  
                  }}>
                 <text >{item.title}</text>
                </button>
                )}

                {props.item?.avaliable === 1   &&(
                <button className=" slotActive" onClick={()=>{
                  console.log("props.iten",props.item)
                     item.avaliable = 0;
                     
                     const tempArray = [];
                       for(var i=0;i<props.defaultTimeSlot.length;i++){
                         if(item.idno == props.defaultTimeSlot[i].idno){
                           tempArray.push(item)
                         }else{
                           tempArray.push(props.defaultTimeSlot[i])
                         }
                         
                       }
                       props.setDefaultTimeSlot( tempArray); 
               
               
               }}>
                   <text className="activeText" style={{color:'#fff' }}>{item.title}</text>
                </button>
              )}
             </>
 );
};


export default TimeButton;