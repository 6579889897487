import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive'
import Button from '../button';
import Logo from '../svg/Logo';
import './headerGuru.scss';
import useTranslations from "../../hooks/translations.hook";
import {ENGLISH, MALAY} from "../../languages/languages.constants";
import MenuIcon from '../svg/menuIcon'
import CloseIcon from '../svg/closeIcon'
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Search from '../search'
import DisplayProfile from '../displayProfile';
import NavbarGuru from '../navbarGuru'
import VideoPlusIcon from '../svg/videoPlusIcon';
import {useSelector} from "react-redux";
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import Flex from '../flex';
const HeaderGuru = props => {

    const {convertUser, isSwitchingUser} = useLogin()
    const userInfo = useSelector(state => state?.app?.userInfo)
    const navigate = useNavigate();
    const {text} = props
    const {setDefaultLanguage,lang} = useTranslations()
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const [menuBar, setMenuBar] = useState(false);
    const MenuToggle = () => {
        setMenuBar(true);
    }
    const MenuCloseToggle = () => {
        setMenuBar(false);
    }
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    const goToUploadVideo = () => {
        navigate('/instructor/upload-video')
    }
    const { pathname } = useLocation();
    return (
        <>
             <header className="header-guru">
                <div className="pt-2">
                    <div className="header-main">
                        <div className="header-left">
                            <div className="logo">
                                <NavLink to="/">
                                    <Logo/>
                                </NavLink>
                            </div>
                            {isMobile &&
                            <Button
                                text={<MenuIcon/>}
                                link
                                onClick={MenuToggle}
                            />
                            }
                        </div>
                        <div className={isMobile ? `menu-collase ${menuBar ? 'menu-open' : ''}` : 'header-right pr-2'}>
                            {isMobile &&
                            <div className="menu-close-button">
                                <Button
                                    text={<CloseIcon/>}
                                    link
                                    large
                                    onClick={MenuCloseToggle}
                                />
                            </div>
                            }

                          {lang == "malay" &&
                                 <select onChange={changeLanguage} className="form-control">
                                        <option value={ENGLISH}>English</option>
                                        <option value={MALAY} selected >Malay</option>
                                </select>
                            }

                            {lang == "en" &&
                                 <select onChange={changeLanguage} className="form-control">
                                        <option value={ENGLISH} selected>English</option>
                                        <option value={MALAY}  >Malay</option>
                                </select>
                            }

                            <div className="h-dp">
                            {/*
                             <Button
                                    link={!isSwitchingUser}
                                    active={isSwitchingUser}
                                    type="button"
                                    text="Trainee"
                                    className="beaguru-link"
                                    isSubmitting={isSwitchingUser}
                                    onClick={()=>{convertUser('learner')}}
                                />
                                <Button
                                    type="button"
                                    text="UPLOAD VIDEO"
                                    iconLeft={<VideoPlusIcon />}
                                    onClick={goToUploadVideo}
                                    disabled={pathname === '/instructor/upload-video' || pathname === '/instructor/video-details' ? true : ''}
                        />*/}
                                <DisplayProfile
                                    userInfo={userInfo}
                                    dpOption={[
                                        {
                                            text:"My Profile",
                                            link: "/instructor/settings/profile",
                                        },
                                        {
                                            text:"Change Password",
                                            link: "/instructor/settings/account",
                                        },
                                        {
                                            text:"Bank Details",
                                            link: "/instructor/settings/bank-details",
                                        },
                                        {
                                            text:"Logout",
                                            link: "/logout",
                                        },
                                    ]}
                                />
                            </div>
                            {isMobile &&
                                <div className="lp-sidebar-wrapper">
                                    <NavbarGuru />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

const ButtonUploadVideo = () =>{
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const goToUploadVideo = () => {
        navigate('/instructor/upload-video')
    }
    return(
        <Button
            type="button"
            text="UPLOAD VIDEO"
            iconLeft={<VideoPlusIcon />}
            onClick={goToUploadVideo}
            disabled={pathname === '/instructor/upload-video' || pathname === '/instructor/video-details' ? true : ''}
        />
    )
}

export default HeaderGuru;
