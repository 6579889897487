import React from 'react';

const VideoPlayerIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M57.4173 18.2499V41.7499C57.4173 50.4024 50.4031 57.4166 41.7506 57.4166H18.2507C9.59819 57.4166 2.58398 50.4024 2.58398 41.7499V18.2499C2.58398 9.59746 9.59819 2.58325 18.2507 2.58325H41.7506C50.4031 2.58325 57.4173 9.59746 57.4173 18.2499Z" stroke={props.color ? props.color : 'currentColor'} stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.65 30.4581L35.0917 36.0667C35.5788 36.4127 35.8682 36.9732 35.8682 37.5707C35.8682 38.1682 35.5788 38.7287 35.0917 39.0747L27.65 45.2082C27.0218 45.6984 26.1768 45.8086 25.4439 45.4959C24.711 45.1832 24.2058 44.497 24.125 43.7042V31.9542C24.2084 31.1629 24.7145 30.4791 25.4469 30.1682C26.1794 29.8573 27.0229 29.9683 27.65 30.4581V30.4581Z" stroke={props.color ? props.color : 'currentColor'} stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M57.4173 20.2499C58.5219 20.2499 59.4173 19.3545 59.4173 18.2499C59.4173 17.1453 58.5219 16.2499 57.4173 16.2499V20.2499ZM30.0007 16.2499C28.8961 16.2499 28.0007 17.1453 28.0007 18.2499C28.0007 19.3545 28.8961 20.2499 30.0007 20.2499V16.2499ZM2.58398 16.2499C1.47941 16.2499 0.583984 17.1453 0.583984 18.2499C0.583984 19.3545 1.47941 20.2499 2.58398 20.2499V16.2499ZM30.0007 20.2499C31.1052 20.2499 32.0007 19.3545 32.0007 18.2499C32.0007 17.1453 31.1052 16.2499 30.0007 16.2499V20.2499ZM28.0007 18.2499C28.0007 19.3545 28.8961 20.2499 30.0007 20.2499C31.1052 20.2499 32.0007 19.3545 32.0007 18.2499H28.0007ZM32.0007 2.58325C32.0007 1.47868 31.1052 0.583252 30.0007 0.583252C28.8961 0.583252 28.0007 1.47868 28.0007 2.58325H32.0007ZM57.4173 16.2499H30.0007V20.2499H57.4173V16.2499ZM2.58398 20.2499H30.0007V16.2499H2.58398V20.2499ZM32.0007 18.2499V2.58325H28.0007V18.2499H32.0007Z" fill={props.color ? props.color : 'currentColor'}/>
        </svg>
    );
};

export default VideoPlayerIcon;

VideoPlayerIcon.defaultProps = {
    width: '60',
    height: '60',
}