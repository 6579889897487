import { useNavigate } from "react-router-dom";
import {useState} from "react";
import useHttpHandler from "../../../../hooks/httphandler.hook";

const useGuruVideoGuideline = () => {
    const [guruVideoGuidelineDetails, setGuruVideoGuidelineDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const {post} = useHttpHandler()

    const navigate = useNavigate();
    const goToBack = () => {
        navigate('/instructor/upload-video')
    }
    const getGuruGuideLine = ()=> {
        post('admin_view_guru_guideLine').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setGuruVideoGuidelineDetails(data[0])
                setLoading(false)
            }
        }).catch(() => setLoading(false))
    }
    return {
        goToBack,
        loading,
        getGuruGuideLine,
        guruVideoGuidelineDetails
    }

}
export default useGuruVideoGuideline;


