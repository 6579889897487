import React from "react";
import Button from "../button";
import Flex from "../flex";
import AkarArrowleftIcon from "../svg/akarArrowLeftIcon";
import Text from "../text";

const PasswordResetSuccessful = props => {
    return(
        <>
            <Text type="H2" text="Password Reset Successful" className="mb-40 text-center" />
            <Text type="PARAGRAPH" text="Your password has been successfully reset. Click below to sign in manually." className="col-8 mx-auto mb-40 text-center" />
            <Flex justifyContent="center" className="mb-40">
                <Button
                    text="LOG IN"
                    large
                    onClick={props?.goBackToLogin}
                />
            </Flex>
            <Flex justifyContent="center">
                <Button
                    linkLittle
                    text="Back to login"
                    onClick={props?.goBackToLogin}
                    iconLeft={<AkarArrowleftIcon />}
                />
            </Flex>
        </>
    )
}

export default PasswordResetSuccessful;
