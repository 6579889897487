import React, {useEffect} from 'react';
import Layout from "../../components/layout";
import Loader from "../../components/loader";
import useHome from '../Home/hooks/home.hook';
import ContactUsPageView from './views/contactUsPageView';

const ContactUsPage = (props) => {
    const homeHook = useHome()
    if (homeHook?.loading) {
        return (
            <>
                <Layout {...props}>
                    <Loader/>
                </Layout>
            </>
        )
    } else {
        return (
            <>
                <Layout {...props}>
                    <ContactUsPageView />
                </Layout>
            </>
        )
    }
}

export default ContactUsPage;
