import { useNavigate } from "react-router-dom";

const useBankDetails = () => {
    const navigate = useNavigate()

    const goBackToAddressInformation = () =>{
        navigate('/trainee/address-information')
    }
    const goToComplete = () =>{
        navigate('/trainee/dashboard')
    }
    return {
        goBackToAddressInformation,
        goToComplete,
    }

}
export default useBankDetails


