import React from 'react';
import LogoAdmin from '../../assets/img/logo.svg';


const AdminLogo = props => {
    return (
       <img src={LogoAdmin} className='admin-logo' alt='logo-main' />
    );
};

export default AdminLogo;