import React, { useState } from 'react';
import Text from '../../../../../components/text';
import NavGuruMonetization from '../../../../../components/navGuruMonetization';
import DP from '../../../../../assets/images/dp-100.png';
import Avatar from '../../../../../components/avatar';
import ProgramBarSecondaryCard from '../../../../../components/programBarCard/programBarSecondaryCard';
import useHelper from "../../../../../hooks/helpers.hook";

const MyCommissionView = (props) => {
    const {getCurrentMonthRange} = useHelper()
    const {commissionDetails} = props

    const barPercentage = commissionDetails?.dashboard_videos_uploaded_this_month ? 100 / 10 * commissionDetails?.dashboard_videos_uploaded_this_month   : 0

    const videosLeft = commissionDetails?.dashboard_videos_uploaded_this_month ? 10 - commissionDetails?.dashboard_videos_uploaded_this_month : 0

    const profileImage = commissionDetails?.pic ? process.env.REACT_APP_IMG_URL+commissionDetails?.pic : DP

    return (
        <>

        <div className='change-password mt-2 mb-4 px-4 py-3 rounded-0 shadow-sm'>
        <Text
                type="H1"
                text="Monetization"
            />
          
            <div className="profile-dp mb-30">
                <Avatar
                    w100
                    src={profileImage}
                    alt={commissionDetails?.fullname}
                />
                <div className="profile-dp-details">
                    <Text type="H2" text={commissionDetails?.fullname} className="profile-dp-title text-dark mb-0" />
                    <Text type="PARAGRAPH" text={commissionDetails?.email} className="profile-dp-email text-gray" />
                </div>
            </div>
            <Text
                type="PARAGRAPH"
                className="mb-20 text-white-half"
                text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using."
            />
            <div className="mcgu-card mb-3">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="mcgu-left">
                            <div className="mcgu-top">
                                <div className="mcgu-top-left">
                                    <Text
                                        type="H4"
                                        text="Total Monthly Commission"
                                        className="text-light"
                                    />
                                    <Text
                                        type="SPAN"
                                        text={getCurrentMonthRange()}
                                        className="text-cyan"
                                    />
                                </div>
                            </div>
                            <Text
                                type="H1"
                                className="mb-0 text-light"
                                text={`$ ${Math.round(commissionDetails?.dashboard_total_monthly_commission * 100) / 100}`}
                            />
                            <ProgramBarSecondaryCard
                                width={barPercentage}
                                title={`${videosLeft} Videos left`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="text-cyan mb-0"
                                text="This is the total commission you gained for this month based on the video you have uploaded. You need to upload minimum of 10 videos (preliminary requirement) and Minimum 1 video per month (after preliminary requirement). You are not entitled to claim commission for the month if you failed to comply the requirements."
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="mcgu-right text-center">
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 mb-10 text-light"
                                text="Total Video Uploaded"
                            />
                            <Text
                                type="H3"
                                className="mb-48 text-cyan"
                                text={commissionDetails?.dashboard_total_videos_uploaded}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 mb-10 text-light"
                                text="Video uploaded this month"
                            />
                            <Text
                                type="H3"
                                className="mb-48 text-cyan"
                                text={commissionDetails?.dashboard_videos_uploaded_this_month}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 mb-10 text-light"
                                text="Viewing minutes this month"
                            />
                            <Text
                                type="H3"
                                className="text-cyan"
                                text={commissionDetails?.dashboard_viewing_minutes_this_month}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
           
        </>
    );
};

export default MyCommissionView;

