import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import CourseDashboardView from "./view/courseDashboardView";
import LayoutPanel from "../../../components/layoutPanel";



const   CoursesDashboard = props => {

    const userInfo = useSelector(state => state.app.userInfo)

      


    return (
        <>
              <LayoutPanel {...props}  footer>
                <CourseDashboardView {...props} userInfo={userInfo}   />
            </LayoutPanel>
        </>
    )
      
}

export default CoursesDashboard;
