import { useState } from "react";

const useGuruUploadVideoSuccess = () => {
    const [changeVideo, setChangeVideo] = useState('');
    const handleChangeVideo = () => {
        setChangeVideo(state => !state);
    }
    return {
        changeVideo,
        handleChangeVideo
    }

}
export default useGuruUploadVideoSuccess


