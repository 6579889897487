import React from "react";
import SignupLayout from "../../../components/signuplayout";
import ThankYouGuruView from "./view/ThankYouGuruView";

const ThankYouGuru = props => {
    const {text} = props
    return (
        <>
            <SignupLayout 
                {...props}
            >
                <ThankYouGuruView {...props} />
            </SignupLayout>
        </>
    )
}

export default ThankYouGuru;
