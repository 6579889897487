import React, { useState } from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from "../text";
import Input from "../input";
import Button from "../button";
import Flex from "../flex";
import AkarArrowleftIcon from "../svg/akarArrowLeftIcon";
import CheckYourEmail from "./checkyouremail";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../hooks/httphandler.hook";
import checkcircle from '../../assets/img/time.png';
import popupIcon from '../../assets/img/information.png'
const ForgotPassword = props => {
    const {text} = props
    const {post} = useHttpHandler()
    const navigate = useNavigate();
    const [checkYourEmailState, setCheckYourEmailState] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');

    const sendEmail = (values, actions)=> {
        post('forgot_password', values).then((res)=>{
        actions.setSubmitting(true)
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                setEmailAddress(values.email)
                setCheckYourEmailState(true);
                toast.success('email sent')
            } else {
                toast.error(status.msg)
            }
        }).catch(err => {actions.setSubmitting(false);})
    }

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Please enter a valid email address'),
        }),
        onSubmit: (values, actions) => {
            sendEmail(values, actions)
        },
    });
    return(
        <>
            {checkYourEmailState ? 
            <>
            <div className="row pop-up-header">
            <div class="col-md-12 div-center">
                <img src={popupIcon} class="icon-50" alt="" />
            </div>
        </div>
        <div className="row popup-padding mt-20">

            <CheckYourEmail email={emailAddress} sendEmail={sendEmail} /> 
            </div>
            </>
            
            :
            <>
                <div className="row pop-up-header " >
                <div class="col-md-12 div-center">
                          <img src={checkcircle} class="icon-50" alt="" />
                         
                </div>
                </div>
                <div className="row popup-padding">
                <Text
                    type="H5"
                    text={text?.FORGOT_PASSWORD}
                    className="mb-10 mt-10 text-center"
                />
                <form onSubmit={formik.handleSubmit}>
                    <div className="formBox">
                        <Input
                            label="Email Address"
                            type="text"
                            name="email"
                            placeholder="Your Email Address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={formik.touched.email && formik.errors.email ? (
                                formik.errors.email
                            ) : null}
                        />
                    </div>
                    <Flex justifyContent="center" className="mb-48">
                        <Button
                            type="submit"
                            text="Request New Password"
                            large
                        />
                    </Flex>
                </form>
                <Flex justifyContent="center">
                    <Button
                        linkLittle
                        text="Back to login"
                        iconLeft={<AkarArrowleftIcon />}
                        onClick={()=>navigate('/login')}
                    />
                </Flex>
                </div>
            </>
            }
        </>
    )
}

export default ForgotPassword;
