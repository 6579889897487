import React, { useEffect } from "react";
import SignupLayout from "../../../components/signuplayout";
import PaymentSuccessView from "../paymentStatus/view/PaymentSuccessView";
import LayoutPanel from '../../../components/layoutPanel';
import { useLocation } from "react-router-dom";
import Loader from "../../../components/loader";
import useVideosCommonHooks from "../../instructor/createCourse/videosCommonHook";



const PaymentSuccess = props => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const commonHook =  useVideosCommonHooks();

    
    


    if(commonHook.loading ) {
        return <Loader/>
    }else{
            return (
            <>
                <LayoutPanel {...props}>
                <PaymentSuccessView {...props} />
                </LayoutPanel>
            </>
            )

    }
    
}

export default PaymentSuccess;
