import React from "react";
import Nav from "../nav";
import './navLearnerFavouriteGuruDetails.scss';
import {useParams} from "react-router-dom";

const NavLearnerFavouriteGuruDetails = props => {
    const {guruId} = useParams()
    return(
        <>
            <div className="nav-tab-panel-wrp">
                <Nav
                    options={[
                        {
                            text:"Active Courses",
                            link: "/instructor/active-courses",
                        },
                        {
                            text:"InActive Courses",
                            link: "/instructor/inactive-courses",
                        },
                        {
                            text:"Create Courses",
                            link: "/instructor/course-upload",
                        },
                    ]}
                />
            </div>
        </>
    )
}

export default NavLearnerFavouriteGuruDetails;
