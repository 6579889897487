import React from "react";
import NavSide from "../navSide";
import './infomationNavSide.scss';

const InfomationNavSideGuru = props => {
    const {text} = props
    return(
        <NavSide
            title="Complete Instructor Profile"
            options={[
                {text: text?.PERSONAL_PARTICULARS, link: '/instructor/personal-information'},
                {text: text?.CORRESPONDENCE_ADDRESS, link: '/instructor/address-information'},
                {text: text?.WORK_AND_EXPERIENCE, link: '/instructor/work-experience'},
                {text: text?.BANK_DETAILS, link: '/instructor/bank-details'},
                {text: text?.UPLOAD_ATTACHMENT, link: '/instructor/upload-attachments'},
            ]}
        />
    )
}

export default InfomationNavSideGuru;