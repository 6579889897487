import React from 'react';

const TelegramIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4453 4C19.141 4 14.0539 6.10714 10.3032 9.85786C6.55245 13.6086 4.44531 18.6957 4.44531 24C4.44531 29.3043 6.55245 34.3914 10.3032 38.1421C14.0539 41.8929 19.141 44 24.4453 44C29.7496 44 34.8367 41.8929 38.5874 38.1421C42.3382 34.3914 44.4453 29.3043 44.4453 24C44.4453 18.6957 42.3382 13.6086 38.5874 9.85786C34.8367 6.10714 29.7496 4 24.4453 4Z" fill="#29B6F6"/>
            <path d="M34.3953 15L30.6493 34.126C30.6493 34.126 30.4883 35 29.4043 35C28.8283 35 28.5313 34.726 28.5313 34.726L20.4173 27.993L16.4473 25.992L11.3523 24.637C11.3523 24.637 10.4453 24.375 10.4453 23.625C10.4453 23 11.3783 22.702 11.3783 22.702L32.6943 14.234C32.6933 14.233 33.3453 13.999 33.8203 14C34.1123 14 34.4453 14.125 34.4453 14.5C34.4453 14.75 34.3953 15 34.3953 15Z" fill="white"/>
            <path d="M23.4461 30.5052L20.0201 33.8792C20.0201 33.8792 19.8711 33.9942 19.6721 33.9992C19.6031 34.0012 19.5291 33.9902 19.4531 33.9562L20.4171 27.9912L23.4461 30.5052Z" fill="#B0BEC5"/>
            <path d="M30.3423 18.1959C30.1733 17.9759 29.8613 17.9359 29.6413 18.1029L16.4453 25.9999C16.4453 25.9999 18.5513 31.8919 18.8723 32.9119C19.1943 33.9329 19.4523 33.9569 19.4523 33.9569L20.4163 27.9919L30.2483 18.8959C30.4683 18.7289 30.5093 18.4159 30.3423 18.1959Z" fill="#CFD8DC"/>
        </svg>
    );
};

export default TelegramIcon;

TelegramIcon.defaultProps = {
    width: '49',
    height: '48'
}