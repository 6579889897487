import React from "react";
import UploadPageView from "./views/uploadPageView";


const UploadPage =()=>{
    return(
        <>
        <UploadPageView />
        
        </>
    )
}

export default UploadPage;