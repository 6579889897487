import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Provider} from "react-redux";
import {FacebookAuthProvider, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../../../../config/firebase";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import useLogin from "../../../authentication/login/hooks/login.hook";
import {FREEMIUM, GURU, SECRETKEY} from "../../../../constants/app.constants";

const CryptoJS = require("crypto-js");


const useRegister = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const {get, post} = useHttpHandler()
    const providerFb = new FacebookAuthProvider();
    const provider = new GoogleAuthProvider();
    const [showPassword, setShowPassword] = useState(false);
    const [socialUserInfo, setSocialUserInfo] = useState(null);
    const ShowPasswordClick = () => {
        setShowPassword(state => !state);
    }
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const ShowConfirmPasswordClick = () => {
        setShowConfirmPassword(state => !state);
    }
    const goToRegisterAsGuru = () => {
        navigate('/instructor/signup')
    }

    const getUserType = () => {
        const urlPath = location.pathname

        if (urlPath === '/trainee/signup') {
            return FREEMIUM;
        } else {
            return GURU;
        }
    }


    const {
        redirectTo,
        setUserSessionInfo
    } = useLogin();

    const socialRegiser = (data) => {

        post('userRegistration', data).then((res) => {

            const status = res.data.status
            const data = res.data.data
            console.log("data", data);
            if (status.result == '200') {
                setUserSessionInfo(data).then(() => {
                    redirectTo(data.redirectcode, data.usertypeid)
                })
            } else {
                toast.error(status.msg)
            }
        }).catch(err => console.log(err))


    }


    const registerWithGoogle = () => {
        const isGuruFlow = location.pathname.includes('/instructor/signup')
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log('registerWithGoogle', result)
                setSocialUserInfo(result.user)
                if (result.user?.phoneNumber) {
                    const password = JSON.stringify({uid: result.user.uid});
                    let data = {
                        country_id: 1,
                        usertype_id: getUserType(),
                        socialtype: "Google",
                        email: result.user?.email,
                        mobile: result.user?.phoneNumber,
                        uname: result.user?.displayName.replace(" ", "_").trim(),
                        id: CryptoJS.AES.encrypt(password, SECRETKEY).toString(),
                        ref_code: sessionStorage.getItem('referralCode') | 1
                    }
                    socialRegiser(data);

                } else {
                    if(isGuruFlow) {
                        navigate('/instructor/signup/socialsignup?'+ 'socialtype=Google')
                    } else {
                        navigate('/trainee/signup/socialsignup?'+ 'socialtype=Google')
                    }

                }
            })
            .catch((error) => {
                toast(error);
            });
    }


    const registerWithFacebook = () => {
        console.log('signInWithFacebook')
        signInWithPopup(auth, providerFb)
            .then((result) => {
                console.log('credential', result)
                const credential = FacebookAuthProvider.credentialFromResult(result);
                console.log('credential', result)
                const tokenRes = credential.accessToken;
                // localStorage.setItem("token",tokenRes)

            })
            .catch((error) => {
                toast(error);
            });
    }


    return {
        showPassword,
        ShowPasswordClick,
        showConfirmPassword,
        ShowConfirmPasswordClick,
        goToRegisterAsGuru,
        registerWithGoogle,
        registerWithFacebook,
        socialUserInfo

    }

}
export default useRegister


