import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import {useSelector} from "react-redux";
import Button from '../../../../components/button';
import checkcircle from '../../../../assets/img/lowBalance.png';
import { useNavigate } from 'react-router-dom';


const LowBalanceView = (props) => {
    
    const navigate = useNavigate();


    return (
        <>
            <div className='row'>
            <div className='col-md-3'>
                    <div class="member">

                    <img src={checkcircle}  alt="" className='mt-20' />
                    
                    </div>
            </div>   
            <div className='col-md-9'>
                <div class="member">
                    <div class="mt-20">
                    <Text type="H2" text=" Opps! you having low Balance " className="dgsc-number text-theme-color"/>
                <Text type="H5" text=" Please add funds to wallet " className="dgsc-number mt-20"/>
                
                <label className="form-label mb-10"> Chat with your instructor </label>
               <br></br>
               <Button type="button" style={{marginLeft:25}}  text="Add Balance"  onClick={()=>{navigate('/trainee/wallet')}}/>
               <br></br>
                        <div class="mt-20">
                        </div>
                </div>
                </div>
            </div>


            </div>                
                   
       
        </>
    );
};


export default LowBalanceView;

