import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import {useSelector} from "react-redux";
import InstructorCard from '../../../../components/instructorCard';
import ChatMsgContainer from '../../../../components/chatMsgContainer';
import ChatTimerCard from '../../../../components/chatTimerCard';
import TrainerChatSizeBox from '../../../../components/TrainerChatSizeBox';

import Modal from '../../../../components/modal';
import AffiliateProgram from '../../../../components/referenceLink/affiliateProgram';
import Flex from '../../../../components/flex';
import Button from '../../../../components/button';
import ChatConfirmInstructor from '../../../../components/chatConfirmationInstructor';
import ConfirmationModal from '../../../../components/chatConfirmationInstructor';
import TrainerChatEndBox from '../../../../components/TrainerChatEndBox';



const ChatEndView = (props) => {
    
    console.log("Props--->",props.getchatDashboardData)

    return (
        <>
            
            <Text type="H5" text="Chat End Conversation" className=" mt-20"/>        
            <div className='row'>
            <div className='col-md-3'>
                    <div class="member">

                    <TrainerChatEndBox props={props.getchatDashboardData} _props={props} instructorID={0} />
                    
                    </div>
            </div>   
            <div className='col-md-9'>
                <div class="member">
                    <div class="mt-20">
                    <Text type="H2" text=" Your Conversation has been completed " className="dgsc-number text-theme-color"/>
                <Text type="H5" text=" We Hope you have cleared your doubts " className="dgsc-number mt-20"/>
                
                <label className="form-label mb-10"> Thanks for choosing CourseTsunami Chat </label>
                </div>
                </div>
            </div>


            </div>                
                   
       
        </>
    );
};


export default ChatEndView;

