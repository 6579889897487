import React, {useEffect} from "react";
import LayoutGuru from '../../../../components/layoutGuru';
import MyReportView from './view/MyReportView';
import useMyReport from "./hooks/myReport.hook";
import Loader from "../../../../components/loader";

const MyReport = props => {
    const myReportHook = useMyReport()
    const {text} = props

    useEffect(() => {
        myReportHook.getVideoCount()
    }, [])

    if (myReportHook?.reportHookLoading) {
        return (
            <LayoutGuru {...props}>
                <Loader/>
            </LayoutGuru>
        )
    }

    return (
        <LayoutGuru {...props}>
            <MyReportView {...props} {...myReportHook}/>
        </LayoutGuru>
    )
}

export default MyReport;
