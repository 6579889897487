import React, {useEffect} from "react";
import LayoutGuru from "../../../../components/layoutGuru";
import EditProfileView from "./view/EditProfileView";
import './profile.scss';
import useProfile from "./hooks/profile.hook";
import useAddressInformation from "../../../trainee/addressInformation/hooks/addressInformation.hook";
import Loader from "../../../../components/loader";

const EditProfile = props => {
    const {text} = props
    const profileHook = useProfile()
    const addressInformationHook = useAddressInformation()
    const isLoaded = !profileHook.loading && !addressInformationHook.loading
    
    useEffect(() => {
        if (!profileHook.profileDetails) {
            
            profileHook.getProfileDetails()
        }
       
    }, [])


    useEffect(() => {
      
     profileHook.getDropDowns()
   
     addressInformationHook.getCountries()

    }, [profileHook.profileDetails])
    if (!isLoaded) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }
    return (
        <>
            <LayoutGuru {...props}>
                <EditProfileView {...props} {...profileHook} />
            </LayoutGuru>
        </>
    )
}

export default EditProfile;
