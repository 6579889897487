import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Flex from '../../../../../components/flex';
import Avatar from '../../../../../components/avatar';
import DP from '../../../../../assets/images/dp-100.png';
import Icon from '../../../../../components/icon';
import TickIcon from '../../../../../components/svg/tickIcon';
import Input from '../../../../../components/input';
import Textarea from '../../../../../components/input/textarea';
import Select from '../../../../../components/input/select';
import TopicInterestItem from '../../../../../components/topicInterestItem';
import BudgetingImg from '../../../../../assets/img/logo.svg'
import useProfile from '../hooks/profile.hook';
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import InputFile from "../../../../../components/input/inputFile";
import axios from "axios";
import useUploadPhoto from "../../../../../hooks/upload.hook";
import useLogin from "../../../../authentication/login/hooks/login.hook";
import { Badge } from 'react-bootstrap';


const EditProfileView = (props) => {
    const {numbersOnly} = useLogin()
    const {text, dropdownsData, setSelectedOptions, profileDetails, goToProfile, selectedTopicsOfInterest} = props
    //const {countries, states, cities, getCitiesByState} = props.addressInformationHook
    const {post} = useHttpHandler()

    const [inputText, setInputText] = useState("");
    const [characterLimit] = useState(300);
    // event handler
    const handleChange = event => {
        
            setInputText(event.target.value);
        
    };

    const onChangeText = (event) => {
        if(event.target.value.length <=300){
        formik.setFieldValue("yourBiodata",event.target.value)
        handleChange(event)
        }
     
    }
   
    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL + profileDetails?.pic : DP
    const {uploadPhoto} = useUploadPhoto()
    const formik = useFormik({
        initialValues: {
            profilePhoto: '',
            file: '',
            
            yearsofExpertise: profileDetails?.years_of_exp ,
            email: profileDetails?.email ? profileDetails?.email : '',
            fullname: profileDetails?.fullname ? profileDetails?.fullname : '',
            preferredName: profileDetails?.preffered_name ? profileDetails?.preffered_name : '',
           
            occupation: profileDetails?.occupationid ? profileDetails?.occupationid : '',
            yourBiodata:profileDetails?.about_yourself

            
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Please enter a valid full name'),
            preferredName: Yup.string().required('Please enter a valid preferred name'),
            yourBiodata: Yup.string().required('Please enter a valid email address'),
            yearsofExpertise: Yup.string().required('Please enter a valid years of expertise'),
            occupation: Yup.string().required('Please enter a valid occupation'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            let postData = {
                fullname: values.fullname,
                preferred_name: values.preferredName,
                about_yourself: values.yourBiodata,
            
                years_of_exp: values.yearsofExpertise,
                occupationid: values.occupation,
                catId: selectedTopicsOfInterest.toString(),
            }

            uploadPhoto(values).then((responseData) => {
                let pic = profileDetails?.pic
                if (responseData?.data?.data) {
                    pic = responseData.data.data[0]
                }
                postData.pic = pic
                console.log("postData",postData);
                
                post('instructor_profileupdate', postData).then((res) => {
                    actions.setSubmitting(false);
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        goToProfile();
                    } else {
                        toast.error(status.msg)
                    }
                }).catch(err => {actions.setSubmitting(false);})

                
            })

          
        },
    });
    
    
    const UserStatusIcon = () => {
        if (profileDetails?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8"/>}/>

        } else if (profileDetails?.userstatus == 'Rejected') {
            return <Icon small danger icon={<TickIcon width="12" height="8"/>}/>
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8"/>}/>
        }

    }
    console.log("profileDetails",formik.values);
    
    useEffect(() => {
        
        props.dropdownsData?.topicOfInterest?.map((item, index) => {
            props.selectedTopicsOfInterest.push(item.catId)
         });

        formik.setFieldValue('country', '1')
    }, [])
    
    return (
        <>
        <div className='change-password mt-2 mb-4 px-5 pt-4 rounded-0 shadow-sm'>
            
            <Text
                type="H3"
                text="Edit Personal Info"
                className="font-weight-500 mb-48"
            />
            <div className="profile-dp mb-24">
                {formik.values.file && (
                    <Flex className="upload-image-wrap">
                        <InputFile
                            label="Profile Photo"
                            type="file"
                            name="profilePhoto"
                            placeholder=""
                            thumb={formik.values.file}
                            value={formik.values.profilePhoto}
                            /*onChange={formik.handleChange}*/
                            onChange={(event) => {
                                formik.setFieldValue("file", event.currentTarget.files[0]);
                            }}

                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                                formik.errors.profilePhoto
                            ) : null}
                            title="Change Picture"
                            rounded
                            marginBottom='0'
                            className="profile-change-picture"
                        />
                    </Flex>
                )}
                {profileImage && !formik.values.file && (
                    <Avatar
                        w100
                        src={profileImage}
                        alt={profileDetails?.fullname}
                    />
                )}
                <div className="profile-dp-details">
                    <Text type="PARAGRAPH" text={profileDetails?.fullname} className="profile-dp-title"/>
                    <Text type="PARAGRAPH" text={profileDetails?.email} className="profile-dp-email"/>
                    <div className="account-status">
                        <UserStatusIcon/>
                        <Text type="PARAGRAPH" text={profileDetails?.userstatus}/>
                    </div>
                </div>
            </div>
            <Flex justifyContent="start" className="">
                <Input
                    tertiary
                    label="Change picture"
                    type="file"
                    name="profilePhoto"
                    placeholder=""
                    thumb={formik.values.file}
                    value={formik.values.profilePhoto}
                    /*onChange={formik.handleChange}*/
                    onChange={(event) => {
                        formik.setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    onBlur={formik.handleBlur}
                    errormessage={formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                        formik.errors.profilePhoto
                    ) : null}
                    className="profile-change-picture"
                />
            </Flex>
            <div className="d-flex align-items-center mb-30">
                <Text type="H4" text="General Information" className="mr-24 mb-0"/>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  
                
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Full Name"
                                type="text"
                                name="fullname"
                                placeholder=""
                                value={formik.values.fullname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.fullname && formik.errors.fullname ? (
                                    formik.errors.fullname
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Preferred Name"
                                type="text"
                                name="preferredName"
                                characterLimit={300}
                                placeholder=""
                                value={formik.values.preferredName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.preferredName && formik.errors.preferredName ? (
                                    formik.errors.preferredName
                                ) : null}
                            />
                        </div>
                    </div>
                    {/*
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Facebook Link"
                                type="text"
                                name="facebook"
                                placeholder=""
                                value={formik.values.facebook}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.facebook && formik.errors.facebook ? (
                                    formik.errors.facebook
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Twitter Link"
                                type="text"
                                name="twitter"
                                placeholder=""
                                value={formik.values.twitter}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                readonly
                                errormessage={formik.touched.twitter && formik.errors.twitter ? (
                                    formik.errors.twitter
                                ) : null}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Instagram Link"
                                type="text"
                                name="instagram"
                                placeholder=""
                                value={formik.values.instagram}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                readonly
                                errormessage={formik.touched.instagram && formik.errors.instagram ? (
                                    formik.errors.instagram
                                ) : null}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Linkedin Link"
                                type="text"
                                name="linkedin"
                                placeholder=""
                                value={formik.values.linkedin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                readonly
                                errormessage={formik.touched.linkedin && formik.errors.linkedin ? (
                                    formik.errors.linkedin
                                ) : null}
                            />
                        </div>
                    </div>
                                */}
                    <div className="col-12 col-md-12">
                        <div className="">
                            <Textarea
                                tertiary
                                label="About Yourself"
                                type="text"
                                name="yourBiodata"
                                placeholder=""
                                value={formik.values.yourBiodata}
                                onChange={(event)=>{ onChangeText(event)}}
                                onBlur={formik.handleBlur}
                                mandatory
                                rows="5"
                                errormessage={formik.touched.yourBiodata && formik.errors.yourBiodata ? (
                                    formik.errors.yourBiodata
                                ) : null}
                            >
                            </Textarea>
                            <div className='text-right'>
                            <Badge  bg={`${inputText.length > characterLimit ? 'danger' : 'primary'}`}>{inputText.length}/{characterLimit}</Badge>
                            </div>
                            
                        </div>
                    </div>
                   
                    <div className="col-12">
                        <Text type="H4" text="Work and Experience" className="mb-24"/>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="Occupation"
                                name="occupation"
                                value={formik.values.occupation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.occupationOptions}
                                errormessage={formik.touched.occupation && formik.errors.occupation ? (
                                    formik.errors.occupation
                                ) : null}
                            />
                        </div>
                    </div>
                 
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Years of Experience"
                                type="text"
                                name="yearsofExpertise"
                                placeholder=""
                                value={formik.values.yearsofExpertise}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.yearsofExpertise && formik.errors.yearsofExpertise ? (
                                    formik.errors.yearsofExpertise
                                ) : null}
                            />
                        </div>
                    </div>
                    
                    <div className="col-12 col-md-12">
                        <div className="">
                            <div className="form-field form-field-tertiary">
                                <label className="form-label">Preferred Topic <span
                                    className="text-danger">*</span></label>
                            </div>
                            <div className="row row-cols-category">
                                {dropdownsData?.allCategories?.map((item, index) => {
                                    const imgsrc = process.env.REACT_APP_IMG_URL + item?.catPic
                                    var validation = false;
                                    console.log("citem.catId,item.catId",dropdownsData)
                                    dropdownsData?.topicOfInterest?.map((citem, cindex) => {
                                     
                                        if(citem.catId === item.catId){
                                           
                                            validation = true;
                                        }

                                    })
                                    return (
                                        <div className="col-2">
                                            <TopicInterestItem
                                                text={item?.catName}
                                                answerId={item?.catId}
                                                catId={item?.catId}
                                                src={imgsrc}
                                                isSelected = {validation}
                                                setSelectedOptions={setSelectedOptions}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                 
                    {/*
                 
                    <div className="col-12">
                        <Text type="H4" text="Bank Details" className="mb-24"/>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Bank Name"
                                type="text"
                                name="bankname"
                                placeholder=""
                                value={formik.values.bankname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.bankname && formik.errors.bankname ? (
                                    formik.errors.bankname
                                ) : null}
                            />
                        </div>
                    </div>
                    

                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Account Holder Name"
                                type="text"
                                name="bankname"
                                placeholder=""
                                value={formik.values.payeename}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.payeename && formik.errors.payeename ? (
                                    formik.errors.payeename
                                ) : null}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Account Number"
                                type="text"
                                name="bankname"
                                placeholder=""
                                value={formik.values.accountnumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountnumber && formik.errors.accountnumber ? (
                                    formik.errors.accountnumber
                                ) : null}
                            />
                        </div>
                    </div>
                                */}


                </div>
                <div className="action-buttons-end mt-2">
                    <Button
                        light
                        type="button"
                        text="Cancel"
                        onClick={goToProfile}
                    />
                    <Button
                        type="submit"
                        text="Save"
                        className="btn-save"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </div>
            </form>
            </div>
        </>
    );
};

export default EditProfileView;

