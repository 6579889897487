import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader'
import useHttpHandler from '../../../hooks/httphandler.hook'
import useGuruUploadVideo from '../../instructor/uploadVideo/hooks/guruUploadVideo.hook'
import CategeoryPaymentView from './views/categeoryPaymentView'

const CategeoryPayment = (props) => {
  const {post} = useHttpHandler()
  const {id} = useParams();
  const [messages, _setMessages] = useState('');
  const [paymentIntent, setPaymentIntent] = useState();
  const userInfo = useSelector(state => state.app.userInfo)
  console.log("userInfo",userInfo);
  const guruUploadVideoHook = useGuruUploadVideo()
  useEffect(() => {
  //  createSubscription(id,userInfo.pgid); 
   guruUploadVideoHook.getCategories({"perpage":100,"catId":id});
  }, [])



  if (guruUploadVideoHook.loading) {
    return <Loader/>
} else {
    return (
      <div>
        <LayoutPanel {...props} footer>
        <CategeoryPaymentView {...props} guruUploadVideoHook={guruUploadVideoHook} />
        </LayoutPanel>
      </div>
    )
    }

  
    
  
}

export default CategeoryPayment
