import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import Image from '../../../../components/image';
import RecentImage from '../../../../assets/images/video-details-img.jpg';
import Dropdown from '../../../../components/dropdown';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";

import {useSelector} from "react-redux";
import Icon from '../../../../components/icon';
import PlayIcon from '../../../../components/svg/playIcon';
import CoursePage from '../../../trainee/courses';
import CourseMainBar from '../../../../components/courseMainbar';
import {Popconfirm,Result,Tag } from 'antd';
import VideoPlayer from '../../../../components/videoplayer';
import ShareVia from '../../../../components/shareVia';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import Modal from '../../../../components/modal';
import rightIcon from '../../../../assets/img/rightIcon.png'
import { DEFAULT_FILTERS, SOCKET_URL, socket } from '../../../../constants/app.constants';
import SwitchButton from '../../../../components/switchButton';
import ChatSwitchButton from '../../../../components/ChatswitchButton';
import {toast} from "react-toastify";

import Input from '../../../../components/input';
import { useFormik } from 'formik';
import ChatMsgContainer from '../../../../components/chatMsgContainer';
import { io } from "socket.io-client";
import ChatOnlineStatus from '../../../../components/chatOnlineStaus';
import Breadcrumbs from '../../../../components/breadcrumbs';
import ChatMsgContainerHistory from '../../../../components/chatMsgContainerHistory';

const InstructorChatHistoryView = (props) => {
    
    const { goToLearnerSignup} = props
    const {getYearOptions,post} = useHelper()
    const viewsOption = [{id: 'views', value: 'Views'}, {id: 'likes', value: 'Likes'},]
    const [viewsSelected, setViewsSelected] = useState(viewsOption[0]);
    const yearsOption = getYearOptions()
    const [yearSelected, setYearSelected] = useState(yearsOption.at(-1));
    const {dashboardDetails} = {}
    const {kFormatter, getCurrentMonthRange} = useHelper()
    const navigate = useNavigate()
    const {text} =props
    const userInfo = useSelector(state => state.app.userInfo)
    const [isModal, setIsModal] = useState(false);
    const [isShareModal, setIsShareModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
    const [isVideoModal, setIsVideoModal] = useState(false);
   
    console.log("userInfo->props?.getchatDashboardData",props?.getchatDashboardData)

    const breadcrumbList = [
        {
            text: 'Chat Dashboard',
            link: '/instructor/chat',
        },
        {
            text:"Chat History",
            link:"/instructor/chat-history",
        },
    ]


    const onlineStatusChange = (statusbit) => {

       
      
       
     
    
    
       
        if(statusbit){
          
            props.socket.emit('disconnect',"");
           
        }else{


            const socket = io(SOCKET_URL)
            socket.on("connection", () => {
            console.log("connected to server");
            });
          
            socket.emit('setUsername', {uname:userInfo.uname,mongoId:userInfo.mongoId,pic:userInfo.pic,userId:userInfo.userid});
            socket.on(props?.roomName, message =>{
                console.log("message",message)
                props.setMessages(message);
                
            });

            if(props?.getchatDashboardData.presentRoomName != ""){
                socket.emit("instructions",{type:"JoinRoomByInstructor",roomName:props?.getchatDashboardData.presentRoomName});
                props.setRoomName(props?.getchatDashboardData.presentRoomName);
            }

            props.setSocket(socket);
            
        }
      
        /*
        post('onlineStatusChange ', {"courseId":props.data.courseid,"statusbit":status}).then((res) => {
           
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                toast.success(status.msg)
            }else{
                toast.success(status.msg)
            }
        }).catch(err => {
           
        })
        */
        


    };
  


  


console.log("props?.getchatDashboardData.onlineStatus",props?.getchatDashboardData);
      

    return (
        <>
            
            <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />


        

            <div className='row mt-20'>
                <div className='col-md-3'>
                <ChatOnlineStatus  {...props} userInfo={userInfo}  commonHook={props.commonHook}/> 
                </div>

                <div className='col-md-9'>
                <ChatMsgContainerHistory {...props} userInfo={userInfo}  commonHook={props.commonHook} ishidden={1}/> 
                </div>

            </div>


           

                                 


                    
       
        </>
    );
};


export default InstructorChatHistoryView;

const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}
