import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Flex from '../../../../../components/flex';
import Avatar from '../../../../../components/avatar';
import DP from '../../../../../assets/images/dp-100.png';
import Icon from '../../../../../components/icon';
import TickIcon from '../../../../../components/svg/tickIcon';
import Input from '../../../../../components/input';
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import InputFile from "../../../../../components/input/inputFile";
import useUploadPhoto from "../../../../../hooks/upload.hook";


const  EditFreemiumProfileView = (props) => {
    const {text, profileDetails, goToProfile} = props
    const {post} = useHttpHandler()
    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL + profileDetails?.pic : DP
    const {uploadPhoto} = useUploadPhoto()

    const formik = useFormik({
        initialValues: {
            profilePhoto: '',
            file: '',
            username: profileDetails?.uname ? profileDetails?.uname : '',
            email: profileDetails?.email ? profileDetails?.email : '',
            phoneNumber: profileDetails?.mobile ? profileDetails?.mobile : '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Please enter a valid username'),
            email: Yup.string().required('Please enter a valid email address'),
            phoneNumber: Yup.string().required('Please enter a valid phone number'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            let postData = {
                fullname: '',
                preferred_name: '',
                ic_passportno: '',
                address1: '',
                address2: '',
                cityid: '',
                zipcode: '',
                stateid: '',
                countryid: '',
                dob: '',
                occupationid: '',
                levelofeducation: '',
                monthlyIncome: '',
            }

            uploadPhoto(values).then((responseData) => {
                let pic = profileDetails?.pic
                if (responseData?.data?.data) {
                    pic = responseData.data.data[0]
                }
                postData.pic = pic
                post('premium_profile_update', postData).then((res) => {
                    actions.setSubmitting(false);
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        goToProfile();
                    } else {
                        toast.error(status.msg)
                    }
                }).catch(err => {actions.setSubmitting(false);})
            })
        },
    });

    const UserStatusIcon = () => {
        if (profileDetails?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8"/>}/>

        } else if (profileDetails?.userstatus == 'Rejected') {
            return <Icon small danger icon={<TickIcon width="12" height="8"/>}/>
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8"/>}/>
        }

    }
    useEffect(() => {
        formik.setFieldValue('country', '1')
    }, [])

    return (
        <>
            <div className='change-password my-4 px-5 py-3 rounded-0'>
            <form onSubmit={formik.handleSubmit}>
                {/* <Text
                    type="H1"
                    text="Settings"
                /> */}
                <Text
                    type="H3"
                    text="Edit Personal Info"
                    className="font-weight-500 mb-20"
                />
                <div className="profile-dp mb-24">
                    {formik.values.file && (
                        <Flex className="upload-image-wrap">
                            <InputFile
                                label={text?.PROFILE_PHOTO}
                                type="file"
                                name="profilePhoto"
                                placeholder=""
                                thumb={formik.values.file}
                                value={formik.values.profilePhoto}
                                /*onChange={formik.handleChange}*/
                                onChange={(event) => {
                                    formik.setFieldValue("file", event.currentTarget.files[0]);
                                }}

                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                                    formik.errors.profilePhoto
                                ) : null}
                                title="Change Picture"
                                rounded
                                marginBottom='0'
                                className="profile-change-picture"
                            />
                        </Flex>
                    )}
                    {profileImage && !formik.values.file && (
                        <Avatar
                            w100
                            src={profileImage}
                            alt={profileDetails?.fullname}
                        />
                    )}

                    <div className="profile-dp-details">
                        <Text type="PARAGRAPH" text={profileDetails?.fullname} className="profile-dp-title"/>
                        <Text type="PARAGRAPH" text={profileDetails?.email} className="profile-dp-email"/>
                        <div className="account-status">
                            <UserStatusIcon/>
                            <Text type="PARAGRAPH" text={profileDetails?.userstatus}/>
                        </div>
                    </div>
                </div>
                <Flex justifyContent="start" className="mb-20">
                    <Input
                        tertiary
                        label="Change picture"
                        type="file"
                        name="profilePhoto"
                        placeholder=""
                        thumb={formik.values.file}
                        value={formik.values.profilePhoto}
                        /*onChange={formik.handleChange}*/
                        onChange={(event) => {
                            formik.setFieldValue("file", event.currentTarget.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                            formik.errors.profilePhoto
                        ) : null}
                        className="profile-change-picture"
                    />
                </Flex>
                <div className="d-flex align-items-center mb-30">
                    <Text type="H4" text="General Information" className="mr-24 mb-0"/>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Username"
                                type="text"
                                name="username"
                                placeholder=""
                                readonly
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.username && formik.errors.username ? (
                                    formik.errors.username
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Email Address"
                                type="text"
                                name="email"
                                placeholder=""
                                readonly
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.email && formik.errors.email ? (
                                    formik.errors.email
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Phone Number"
                                type="text"
                                name="phoneNumber"
                                placeholder=""
                                readonly
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                    formik.errors.phoneNumber
                                ) : null}
                            />
                        </div>
                    </div>
                </div>
                <div className="action-buttons-end">
                    <Button
                        light
                        type="button"
                        text="Cancel"
                        onClick={goToProfile}
                        className="mb-0"
                    />
                    <Button
                        type="submit"
                        text="Save"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </div>
            </form>
            </div>
        </>
    );
};

export default EditFreemiumProfileView;

