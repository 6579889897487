import React, {useState} from 'react';
import Text from '../../../../../components/text';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import Pagination from '../../../../../components/pagination';
import Flex from '../../../../../components/flex';
import DropdownNew from '../../../../../components/dropdown/dropdownNew';
import Button from '../../../../../components/button';
import Table from '../../../../../components/table';
import useHelper from "../../../../../hooks/helpers.hook";
import Card from '../../../../../components/card';
import { useFormik } from 'formik';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/select';
import * as Yup from 'yup';
import SubscribeView from '../../../subscribe/view/SubscribeView';
import TrainerCategoryBar from '../../../../../components/trainerCategoryBar';
import {toast} from "react-toastify";
import SelectNew from '../../../../../components/input/selectnew';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../../stripe/checkoutForm';
import {loadStripe} from '@stripe/stripe-js';
import WalletCheckOutFrom from '../../../stripe/walletCheckOutFrom';

const WalletView = (props) => {
    const {text} = props
    const {formatDateDDMMYYHms} = useHelper()
    const tableData = props?.tableData?.listData

    const [yearSelected, setYearSelected] = useState('Year 2022');
    const [monthSelected, setMonthSelected] = useState('Select Month');

    const stripePromise = loadStripe(process.env.REACT_APP_PAYMENTGATEWAYKEY);


const formik = useFormik({
    initialValues: {
        amount: '',
      
    },
    validationSchema: Yup.object({
        amount: Yup.string('Please enter a Amount'),
        
    }),
    onSubmit: (values, actions) => {
       
        actions.setSubmitting(true)
        values.uri = "AddToWallet";
        
      
        
        
        props.promiseService(values).then((data) => {

      
            
            if(data.data.status.result == 200){
                toast.success(data.data.status.msg);
                props?.setReloadPage(true);
               
            }else{
                toast.error(data.data.status.msg);
            }
          
        
        });
        


    },
});


const CourseTyeChange = (e) => {    

  

   
   props.setPriceId(e);
        
   // props?._props.setCourseType(e.value);
 //   getData("courseType",e.value);
}

   
    const yearsOption = [
        {
            value: 'Debit',
        },
        {
            value: 'Credit',
        },
       
    ];
    return (
        <>

           <Text
                type="H1"
                text="Wallet"
            />

            <div className='row' >
                
             
                
                <div className='row'>
                  
                    <Text type="PARAGRAPH" text={"Current Wallet : " + props?.walletAmount} className="text-dark mb-0 mr-10" />
                   

                    <SelectNew  
                tertiary
                label="Select Amount"
                name="course_type"
                placeholder={props.priceId.text}
                value={formik.values.category}
                onChange={CourseTyeChange}
                onBlur={formik.handleBlur}
                mandatory
                options={props.getPriceData}
                errormessage={formik.touched.category && formik.errors.category ? (
                    formik.errors.category
                ) : null}
            />

                <div className='mt-3' >

                
                            <WalletCheckOutFrom priceId={props.priceId.value}/>
                            </div> 

                </div>
                

               

   
</div>
  


               

         
        </>
    );
};

export default WalletView;

