import { useNavigate } from "react-router-dom";
import useTranslations from "../../../../hooks/translations.hook";

const useAddressInformation = () => {
    const navigate = useNavigate()
    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    const goBackToAddressInformation = () =>{
        navigate('/instructor/personal-information')
    }
    const goToWorkExperience = () =>{
        navigate('/instructor/work-experience')
    }
    return {
        goBackToAddressInformation,
        goToWorkExperience

    }

}
export default useAddressInformation


