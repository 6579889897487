import React from "react";
import Button from "../button";
import Flex from "../flex";
import AkarArrowleftIcon from "../svg/akarArrowLeftIcon";
import shareIcon from '../../assets/img/shareIconWhite.png'
import Text from "../text";
import useLogin from "../../pages/authentication/login/hooks/login.hook";

const CheckYourEmail = props => {
    const {openSetNewPassword, goBackToLogin} = useLogin()
    return(
        <>

      

            <Text type="H2" text="Check Your Email" className="text-center" />
            <Text type="PARAGRAPH" text="We have sent a password reset link to " className="text-center" />
            <Text type="H2" text={props?.email} className=" text-center" />
            <Flex justifyContent="center" className="mt-10">
                <Button
                    text="Open Email App"
                    large
                    onClick={openSetNewPassword}
                />
            </Flex>
            <Flex justifyContent="center" className="mt-10">
                Didn’t receive the email? Click &nbsp; <Button linkSecondary text="here" onClick={()=>{props?.sendEmail({email: props?.email})}} /> &nbsp; to resend.
            </Flex>
            <Flex justifyContent="center">
                <Button
                    linkLittle
                    text="Back to login"
                    iconLeft={<AkarArrowleftIcon />}
                    onClick={goBackToLogin}

                />
            </Flex>
            
        </>
    )
}

export default CheckYourEmail;
