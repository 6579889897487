import React from "react";
import './watchNextCard.scss';
import Text from "../text";
import useHelper from "../../hooks/helpers.hook";
import Image from "../image";
import Flex from "../flex";

const WatchNextVideoCard = props => {
    const {kFormatter} = useHelper()
    const {secondsToMinutes} = useHelper()
    const duration = secondsToMinutes(props.duration)
    return(
        <>
            <div className="watch-next-video-item-card pointer" onClick={props.onClick}>
                <div className="watch-next-video-item-poster">
                    <Image
                        src={props.videoPoster}
                        alt={props.title}
                        width={props.imgWidth}
                        height={props.imgHeight}
                    />
                </div>
                <div className="watch-next-video-item-details">
                    <Text
                        type="H3"
                        className="watch-next-video-item-detail-title"
                        text={props.title}
                    />
                    <Text
                        type="PARAGRAPH"
                        text={props.description}
                        className="watch-next-video-item-detail-desc"
                    />
                    {props.views > 0 &&
                        <Text
                            type="PARAGRAPH"
                            className="watch-next-video-item-views mb-0"
                            text={`${kFormatter(props.views)} ${props.views > 1 ? 'Views':'View'}`}
                        />
                    }
                    <Flex justifyContent="end">
                        <Text
                            type="SPAN"
                            text={duration}
                            className="watch-next-video-item-duration"
                        />
                    </Flex>
                </div>
            </div>
        </>
    )
}

export default WatchNextVideoCard;
