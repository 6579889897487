import axios from "axios";

const useUploadPhoto = () => {

    const uploadPhoto = async (data) => {
        if (data.file) {
            let formData = new FormData();
            formData.append("file", data.file);
            const apiUrl = process.env.REACT_APP_API_URL + '/upload'
            return axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).catch((error) => {
                console.log("ERROR " + error.message)
            });
        } else {
            return true
        }
    }

    return {
        uploadPhoto
    }
}
export default useUploadPhoto


