import React from 'react';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Icon from '../../../../../components/icon';
import EditIcon from '../../../../../components/svg/editIcon';
import TextReadonly from '../../../../../components/textReadonly'
import NavLearnerMember from '../../../../../components/navLearnerMember';

const BankDetailsView = (props) => {
    return (
        <>
            <Text
                type="H1"
                text="Settings"
            />
           
            <div className="d-flex align-items-center mb-48">
                <Text type="H3" text="Bank Details" className="font-weight-500 mr-24 mb-0" />
                <Button
                    linkPrimary
                    small
                    text="Edit"
                    iconLeft={<Icon small square icon={<EditIcon />} />}
                    onClick={props.goToEditBankDetails}
                />
            </div>
            <div className="row row-cols-5">
                <div className="col">
                    <TextReadonly
                        label="Bank Name"
                        text={props?.bankDetails?.bankname}
                    />
                </div>
                <div className="col">
                    <TextReadonly
                        label="Account Holder"
                        text={props?.bankDetails?.payeename}
                    />
                </div>
            </div>
            <div className="row row-cols-5">
                <div className="col">
                    <TextReadonly
                        label="Account Number"
                        text={props?.bankDetails?.accountnumber}
                    />
                </div>
            </div>
        </>
    );
};

export default BankDetailsView;

