import React, {useEffect} from "react";
import LayoutGuru from '../../../../components/layoutGuru';
import ProfileView from "./view/ProfileView";
import './profile.scss';
import useProfile from "./hooks/profile.hook";
import Loader from "../../../../components/loader";

const Profile = props => {
    const {text} = props
    const profileHook = useProfile()
    useEffect(() => {
        profileHook.getProfileDetails()
    }, [])

    if (profileHook.loading) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }

    return (
        <>
            <LayoutGuru {...props}>
                <ProfileView {...props} {...profileHook} />
            </LayoutGuru>
        </>
    )
}

export default Profile;
