import React, {useState} from 'react';
import Text from "../../../../../components/text";
import DropdownNew from "../../../../../components/dropdown/dropdownNew";
import Button from "../../../../../components/button";
import Table from "../../../../../components/table";
import Pagination from "../../../../../components/pagination";
import Loader from "../../../../../components/loader";
import useHelper from "../../../../../hooks/helpers.hook";

const TotalViewAndLikesList = props => {
    const {kFormatter, formatDateDDMMYY2} = useHelper()
    const {commonHook} = props
    const tableData = commonHook?.tableData?.listData

    if(commonHook?.loading) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <Table
                thead={
                    <tr>
                        <th>Title of video</th>
                        <th>Uploaded Date</th>
                        <th>Total View</th>
                        <th>Total Like</th>
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{formatDateDDMMYY2(item.dot)}</td>
                                    <td>{item.views}</td>
                                    <td>{item.likes}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td colSpan="2" className="text-right text-white-half">Total</td>
                            <td className="font-weight-600 text-white">{kFormatter(commonHook?.tableData?.customdata?.total_views)}</td>
                            <td className="font-weight-600 text-white">{kFormatter(commonHook?.tableData?.customdata?.total_likes)}</td>
                        </tr>
                    </>
                }
            />
            <Pagination
                totalPages={commonHook?.tableData?.totalPages}
                currentPage={commonHook?.tableData?.currentPage}
                totalRecords={commonHook?.tableData?.totalRecords}
                nextPage={commonHook?.nextPage}
                previousPage={commonHook?.previousPage}
                perPage={commonHook?.filterKeys?.perpage}
            />
        </>
    )
}
export default TotalViewAndLikesList;

