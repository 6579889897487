import React from 'react';
import GalleryIcon from '../svg/galleryIcon';
import Text from '../text';
import './input.scss'
import './inputFile.scss'

class Thumb extends React.Component {
    state = {
        loading: false,
        thumb: undefined,
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.file) {
            return;
        }

        this.setState({loading: true}, () => {
            let reader = new FileReader();

            reader.onloadend = () => {
                this.setState({loading: false, thumb: reader.result});
            };

            reader.readAsDataURL(nextProps.file);
        });
    }

    render() {
        const {file} = this.props;
        const {loading, thumb} = this.state;

        if (!file) {
            return null;
        }

        if (loading) {
            return <p>loading...</p>;
        }
        console.log('file', file)

        return (
            <img src={thumb}
                 alt={file.name}
                 className="img-thumbnail"
                 height={144}
                 width={144}/>
        );
    }
}

const InputFile = props => {
    return (
        <div
            className={`form-field form-field-file ${props.tertiary ? 'form-field-tertiary' : props.secondary ? 'form-field-secondary' : props.marginBottom ? 'mb-'+props.marginBottom : ''}`}>
            {props.label && <label className={`form-label`}>{props.label} {props.mandatory &&
            <span className="text-danger">*</span>}</label>}
            <div className={`upload-content ${props.rounded ? 'rounded' : ''}`}>
                <input
                    className={`form-control ${props.formControlClassName ? props.formControlClassName : ''} ${props.errormessage ? 'error' : ''}`}
                    type={props.type}
                    name={props.name}
                    value={props.value}
                    id={props.id}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    required={props.required}
                    autoFocus={props.autoFocus}
                />
                <Thumb file={props.thumb}/>
                <div className="icon">
                    {props.icon}
                </div>
                <Text type="SPAN" text={props.title}/>
            </div>
            {props.errormessage && <div className="message-error"><small>{props.errormessage}</small></div>}
        </div>
    );
};

export default InputFile;

InputFile.defaultProps = {
    icon: <GalleryIcon/>,
    title: 'Upload Photo',
}
