import React, { useState } from "react";
import "../courseDetails/courseDetail.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import { toast } from "react-toastify";
import Input from "../input";
import Search from "../search";
import Button from "../button";
import Icon from "../icon";
import TickIcon from "../svg/tickIcon";
import Text from "../text";
import Flex from "../flex";
import rightIcon from "../../assets/img/rightIcon.png";

const ConfirmSucess = (props) => {
  return (
    <>
      <div className="row pop-up-header ">
        <div class="col-md-12 div-center">
          {/* <img src={rightIcon} class="icon-50" alt="" /> */}
          <Icon
                              icon={<TickIcon width="24" height="24" />}
                              success
                            />
        </div>
      </div>
      <div className="row popup-padding">
        <Text type="H5" text={props.text} className="mb-10 mt-10 text-center" />

        <Flex justifyContent="center" className="mb-2 mt-40">
          <Button
            type="primary"
            key="console"
            text="Yes"
            className="px-5"
            onClick={() => {
              props.event(props.type);
            }}
          />
          ,
          <Button
            type="primary"
            key="console"
            text="No"
            className="px-5"
            onClick={() => {
              props.close(props.type);
            }}
          />
        </Flex>
      </div>
    </>
  );
};

export default ConfirmSucess;
