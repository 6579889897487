import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive'
import Button from '../button';
import Logo from '../svg/Logo';
import './header.scss';
import MenuIcon from '../svg/menuIcon'
import CloseIcon from '../svg/closeIcon'
import Navbar from '../navbar';
import { NavLink, useNavigate } from "react-router-dom";
import {ENGLISH, MALAY} from "../../languages/languages.constants";
import useTranslations from "../../hooks/translations.hook";
import {useSelector} from "react-redux";
import {FREEMIUM, GURU, PREMIUM} from "../../constants/app.constants";
import useLogin from '../../pages/authentication/login/hooks/login.hook';

const Header = props => {
    const userInfo = useSelector(state => state?.app?.userInfo);
    const {redirectTo} = useLogin();
    const navigate = useNavigate();
    const {text} = props;
    const {setDefaultLanguage} = useTranslations();
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const [menuBar, setMenuBar] = useState(false);
    const MenuToggle = () => {
        setMenuBar(true);
    }
    const MenuCloseToggle = () => {
        setMenuBar(false);
    }
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    
  
    const gotoDashboard = () => {
        console.log('gotoDashboard',userInfo?.redirectcode,userInfo?.usertypeid)
        redirectTo(userInfo?.redirectcode,userInfo?.usertypeid);

        /*if(userInfo?.usertypeid === FREEMIUM || userInfo?.usertypeid === PREMIUM) {
            navigate('/trainee/dashboard')
        } else if(userInfo?.usertypeid === GURU) {
            navigate('/instructor/dashboard')
        }*/
    }
    return (
        <>
            <header className="header-landing">
                <div className="container">
                    <div className="header-main">
                        <div className="header-left">
                            <div className="logo">
                                <NavLink to="/">
                                    <Logo/>
                                </NavLink>
                            </div>
                            {isMobile &&
                            <Button
                                text={<MenuIcon/>}
                                link
                                onClick={MenuToggle}
                            />
                            }
                        </div>
                        <div className={isMobile ? `menu-collase ${menuBar ? 'menu-open' : ''}` : 'header-right'}>
                            {isMobile &&
                            <div className="menu-close-button">
                                <Button
                                    text={<CloseIcon/>}
                                    link
                                    large
                                    onClick={MenuCloseToggle}
                                />
                            </div>
                            }
                            
                            <Navbar userInfo={userInfo} text={text?.MENU} />
                            
                            <div className="h-buttons">
                            
                                {!userInfo && (<>
                                
                                    <Button
                                    text="Sign up here"
                                    className={"btn-xs"}
                                    
                                    onClick={()=>navigate('/register')}
                                />
                                
                                <Button
                                    text="Login"
                                    className={"btn-xs"}
                                    
                                    onClick={()=>navigate('/login')}
                                />
                                </>)}
                                {userInfo && (
                                    <>
                                        <Button
                                            text={"Dashboard"}
                                           
                                            className={"btn-xs"}
                                            onClick={gotoDashboard}
                                        />
                                        <Button 
                                            text={"Logout"} 
                                            className={"btn-xs"}
                                            
                                            onClick={()=>navigate('/logout')}
                                        />
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
