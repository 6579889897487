import React from 'react';
import "./loader.scss";
import { AiOutlineArrowUp } from "react-icons/ai";


const Loader = props => {
    return (
        <>
        <div id="preloader"></div>
        <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><AiOutlineArrowUp /></a>
        </>
    );
};

export default Loader;

Loader.defaultProps = {
    message: "Loading please wait..."
}
