import React from 'react';

const VideoPlayerTwoIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.008 5.17516L10.169 7.32316C10.3738 7.44273 10.4997 7.66203 10.4997 7.89916C10.4997 8.13629 10.3738 8.35559 10.169 8.47516L7.008 10.8252C6.408 11.2322 5.5 10.8852 5.5 10.2492V5.74916C5.5 5.11616 6.409 4.76816 7.008 5.17516Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 7V9C1 12.3137 3.68629 15 7 15H9C12.3137 15 15 12.3137 15 9V7C15 3.68629 12.3137 1 9 1H7C3.68629 1 1 3.68629 1 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>        
    );
};

export default VideoPlayerTwoIcon;

VideoPlayerTwoIcon.defaultProps = {
    width: '16',
    height: '16',
}