import React from 'react';

const GoogleIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0815 30.2176L9.34097 36.7152L2.9795 36.8497C1.07836 33.3235 0 29.2891 0 25.0018C0 20.8561 1.00824 16.9466 2.79541 13.5042H2.79678L8.46027 14.5425L10.9412 20.172C10.422 21.6858 10.1389 23.3109 10.1389 25.0018C10.1391 26.8371 10.4716 28.5954 11.0815 30.2176Z" fill="#FBBB00"/>
            <path d="M49.5633 20.3308C49.8504 21.8432 50.0001 23.405 50.0001 25.0013C50.0001 26.7912 49.8119 28.5372 49.4534 30.2213C48.2364 35.9523 45.0563 40.9566 40.6509 44.4979L40.6496 44.4965L33.5161 44.1326L32.5065 37.8301C35.4297 36.1158 37.7141 33.4329 38.9175 30.2213H25.5488V20.3308H39.1125H49.5633Z" fill="#518EF8"/>
            <path d="M40.6536 44.4959L40.655 44.4972C36.3705 47.941 30.928 50.0015 25.0033 50.0015C15.4824 50.0015 7.20465 44.68 2.98193 36.8486L11.0839 30.2166C13.1952 35.8513 18.6308 39.8625 25.0033 39.8625C27.7424 39.8625 30.3085 39.1221 32.5104 37.8294L40.6536 44.4959Z" fill="#28B446"/>
            <path d="M40.957 5.75568L32.8577 12.3864C30.5788 10.9619 27.885 10.139 24.999 10.139C18.4822 10.139 12.9449 14.3342 10.9394 20.171L2.79482 13.5032H2.79346C6.95435 5.48096 15.3365 0 24.999 0C31.0651 0 36.6271 2.16082 40.957 5.75568Z" fill="#F14336"/>
        </svg>
        
    );
};

export default GoogleIcon;

GoogleIcon.defaultProps = {
    width: '50',
    height: '51'
}