import React from "react";
import SignupLayout from "../../../components/signuplayout";
import UploadAttachmentsView from "./view/UploadAttachmentsView";
import logoImg from '../../../assets/userimg/login-bg2.png';

const BankDetails = props => {
    const {text} = props
    return (
        <>
              <div class="register_instructor">
        <main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
      <div className="row">
      <div className="col-md-6">
            <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
            </div>
            <div className="col-md-6">
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-6 bg-light">
                <UploadAttachmentsView {...props} />
                </div>
                </div>
              </div>
        </div>
       
      </div>

    </section>

  </div>
</main>
</div>
            
        </>
    )
}

export default BankDetails;
