import React from 'react';

const AlertWarningIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5605 9.26544L8.95613 1.78577C8.65239 1.27297 8.10061 0.958496 7.50461 0.958496C6.90861 0.958496 6.35683 1.27297 6.05309 1.78577L1.44796 9.26544C1.09975 9.81067 1.06981 10.5005 1.36948 11.0739C1.66915 11.6472 2.25264 12.0165 2.89909 12.0418H12.1093C12.7558 12.0165 13.3393 11.6472 13.6389 11.0739C13.9386 10.5005 13.9087 9.81067 13.5605 9.26544Z" stroke="#FF2424" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5026 7.29199L7.5026 3.33366" stroke="#FF2424" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        
    );
};

export default AlertWarningIcon;

AlertWarningIcon.defaultProps = {
    width: "15",
    height: "13",
}