import {useFormik} from 'formik';
import * as Yup from 'yup';
import Loader from '../../../../components/loader';
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import { useEffect, useState } from 'react';
import { DEFAULT_FILTERS, PREMIUM } from '../../../../constants/app.constants';
import Pagination from '../../../../components/pagination';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import useHelper from '../../../../hooks/helpers.hook';
import editImg from '../../../../assets/img/Edit.png'
import viewImg from '../../../../assets/img/Eye.png'
import { CgNametag } from 'react-icons/cg';
import Radio from '../../../../components/radio';
import Text from '../../../../components/text';
import GoBackButton from '../../../../components/goBackButton';
import Flex from '../../../../components/flex';
import ParticipateQuiz from './participateQuiz';

const ParticipateQuizMainFrame = (props) => {
    const [i,setI] = useState(0);
    const [isShowPopUP,setShowPopUP] = useState(false);
    const [data,setData] = useState({});
    const [options,setOptions] = useState([]);
    
    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'iGetCourseQuizQuestions';
        DEFAULT_FILTERS.moduleId = props.moduleId;
        DEFAULT_FILTERS.perpage = 100
        commonHook.getList(DEFAULT_FILTERS)
      
    },[])

    useEffect(()=>{
        if(commonHook?.tableData?.listData?.length >= 1){
            setData(commonHook?.tableData?.listData[0]);
            setShowPopUP(true);
            setOptions(commonHook?.tableData?.customdata);
        }
      
    },[!commonHook?.loading])



    if (commonHook?.loading) {
        return (
            <>
            
            
              
            </>
        )
    } else {
        
        

    
    return (
        <>

            
            {isShowPopUP &&(
                <>
                  <ParticipateQuiz data={data} options={options} count={commonHook?.tableData?.listData.length} i={i} />
                </>)
            }


          



            <Flex justifyContent="center" className="mb-48">
                <div className='row'>
                <div className='col-md-5'>
                    {i>=1   &&(
                        <button class="btn-purchase_1 mt-1" onClick={() => {
                            
                            console.log("i-->",i-1)
                           var dec = i;
                            setShowPopUP(false)
                          
                           
                                setData(commonHook?.tableData?.listData[i-1]);
                                setI(dec - 1)
                          
                           
                            setShowPopUP(true);
                            

                    }}>Previous</button>  
                    )
                    
                    }
                    
                    </div>
                    <div className='col-md-5'> 
                    
                    { i < commonHook?.tableData?.listData?.length &&(
                    <button class="btn-purchase_1 mt-1 ml-10"  onClick={() => {

                        console.log("i-->next",i-1)
                        setShowPopUP(false) 
                           
                                setI(i+1)
                                setData(commonHook?.tableData?.listData[i]);
                                
                                
                           

                            setShowPopUP(true);
                            

                    }}>Next</button> 
                    
                )}
                    </div>
                </div>
               
             
            </Flex>


      

                </>


    )

    }
  

};

export default ParticipateQuizMainFrame;
