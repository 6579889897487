import React,{useEffect} from 'react'

import Loader from "../../../../components/loader";
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';


import useMyVideo from '../../../instructor/myVideo/hooks/myVideo.hook';
import LayoutPanel from '../../../../components/layoutPanel';
import { useParams } from 'react-router-dom';
import MyMeetingSlots from '../../../../components/myMeetingSlots/myMeetingSlots';

const UpcommingMeetings =(props)=>{
  const guruMyVideoHook = useMyVideo()
  const {userId} = useParams();
  const commonHook = useGetTableList();
 
  

 
  return (
      <>
        <LayoutPanel {...props}>
        <MyMeetingSlots courseId={0}/>
      </LayoutPanel>
      </>
  )
}

export default UpcommingMeetings;
