import React from "react";
import AlertWarningIcon from "../svg/alertWarningIcon";
import Text from "../text";
import './programBarCard.scss';
import useHelper from "../../hooks/helpers.hook";

const ProgramBarSecondaryCard = props => {
    const {decimalFormatter} = useHelper()
    const minutesLeftText = (minutesLeft) => {
        if (minutesLeft >= 0) {
            return decimalFormatter(props.minutesLeft) + ' minutes left'
        } else {
            return props?.textTargetCompleted
        }
    }
    return (
        <>
            <div className={`progress-bar-secondary-wrapper ${props.small ? 'progress-bar-small' : ''}`} {...props}>
                {props.title ?
                    <Text
                        type="PARAGRAPH"
                        className="progress-bar-text text-right"
                        text={props.title}
                    />
                    :
                    <Text
                        type="PARAGRAPH"
                        className="progress-bar-text text-right"
                        text={minutesLeftText(props.minutesLeft)}
                    />
                }
                <div class="progress-bar-main">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example with label"
                        style={{width: `${props.width}%`}}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgramBarSecondaryCard;

ProgramBarSecondaryCard.defaultProps = {
    width: '25'
}
