import React, {useEffect} from "react";
import useSubscribePremiumModal from "../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import LayoutPanel from "../../../components/layoutPanel";
import SubscribePremiumModal from "../../../components/subscribePremiumModal";
import LikedVideoView from "./view/topTenVideosView";
import useLikedVideo from "./hooks/likedVideo.hook";
import Loader from "../../../components/loader";
import GlobalInfoModal from "../../../components/globalInfoModal";
import useGuruUploadVideo from "../../instructor/uploadVideo/hooks/guruUploadVideo.hook";
import TopTenVideosView from "./view/topTenVideosView";
import useGetTableList from "../../../hooks/getTableListCommonHook";

const TopTenVideos = props => {

    const commonHook = useGetTableList();

    if(commonHook.loading || commonHook.loading) {
      return <Loader/>
  }else{

    return (
        <>
            <LayoutPanel {...props}>
               {/** <SubscribePremiumModal {...props} /> */}

                <TopTenVideosView {...props} />
            </LayoutPanel>
        </>
    )
}

}
export default TopTenVideos;
