import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import SignupLayout from "../../../components/signuplayout";
import useReferenceLink from "./hooks/referenceLink.hook";
import ReferenceLinkView from "./view/ReferenceLinkView";
import Modal from "../../../components/modal";
import AffiliateProgram from "../../../components/referenceLink/affiliateProgram";
import './referenceLink.scss'

const ReferenceLink = props => {
    const location = useLocation()
    const isReferFriend = location?.pathname === '/trainee/refer-friend'
    let { referralCode } = useParams();

    const {
        isModal,
        openReferenceLink,
        closeModal,
        getUserInfoByReferralCode,
        userName,
        updateShareStatus
    } = useReferenceLink()
    useEffect(()=>{
        sessionStorage.setItem('referralCode', referralCode)
        getUserInfoByReferralCode(referralCode)
    },[])
    return (
        <>
            <SignupLayout {...props}>
                <ReferenceLinkView
                    {...props}
                    onSignupNow={openReferenceLink}
                    userName={userName}
                />
                {(isModal || isReferFriend) &&
                    <Modal
                        onClose={closeModal}
                        className="modal-signup-right-area modal-secondary"
                        onOuterClose={closeModal}
                        modalContent={<AffiliateProgram {...props} updateShareStatus={updateShareStatus} />}
                    />
                }
            </SignupLayout>
        </>
    )
}

export default ReferenceLink;
