import moment from "moment";
import {FREEMIUM, GURU, PREMIUM} from "../constants/app.constants";
import useHttpHandler from "./httphandler.hook";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../store/reducers/app.reducer";

const useHelper = () => {

    const {post} = useHttpHandler()
    const dispatch = useDispatch()

    const getProfile = () => {
        post('getProfile').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                updateUserMetaData(data)
            }
        })
    }
    const updateUserMetaData = (data) => {
        localStorage.setItem('currentUserTypeId', data.usertypeid)
        localStorage.setItem('currentSessionUserIds', data.otherUserIds)
         dispatch(setUserInfo(data))
    }

    const getToken = () => {
        return localStorage.getItem('token')
    }

    const hasPermissions = (userTypeId = null) => {
        if (hasToken()) {
            const currentIds = getCurrentSessionUserIds()
            return currentIds?.includes(userTypeId)
        } else {
            return false
        }
    }

    const isMember = (userTypeId1 = null, userTypeId2 = null,userTypeId3 = null) => {
        if (hasPermissions(userTypeId1)) {
            return true
        } else if (hasPermissions(userTypeId2)) {
            return true
        } else if (hasPermissions(userTypeId3)) {
            return true
        } else {
            return false
        }
    }

    const getUserTypeId = () => {
        return localStorage.getItem('currentUserTypeId')
    }

    const getCurrentSessionUserIds = () => {
        return localStorage.getItem('currentSessionUserIds')
    }

    const hasToken = () => {
        const token = getToken()
        return token ? true : false
    }

    const formatDateDDMMYY = (date) => {
        return moment(date).format('DD-MM-YYYY')
    }

    const fromatGetDate = (date) => {
        return moment(date).format('DD')
    }

    const formatDateDDMMMMYYYY = (date) => {
        return moment(date).format('DD MMMM YYYY');
    }

    const formatDateDDMMYY2 = (date) => {
        return moment(date).format('DD/MM/YYYY');
    }

    const formatMinutes = (seconds) => {
        if (seconds !== null) {
            const mom = moment(seconds, 'HHmmss');
            return mom.format('HH:mm')
        } else {
            return ''
        }
    }

    const secondsToMinutes = (seconds) => {
        const secs = moment.utc(seconds * 1000)
        if (seconds !== null) {
            if (seconds > 60) {
                return secs.format('mm:ss ' ) + " Hrs";
            } else {
                return secs.format('mm:ss') + " Mins";
            }
        } else {
            return ''
        }
    }

    

    const toSeconds = (seconds) => {
        return moment.duration(seconds).asSeconds()
    }


    const todayYesterdayMonth = (date) => {

        const dateDiff = dateDiffInDays(new Date(),new Date( date));

        console.log("dateDiff--->",dateDiff)

        return dateDiff + " Days"
/*
        if(dateDiff == 0){
            return "Today"
        }
        else if(dateDiff == 1){
            return "Yesterday"
        }else{
        
        return moment(date).format('MMM DD, YYYY')
        }*/
    }

    const dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      console.log(a,a.getFullYear(), a.getMonth(), a.getDate(),b.getFullYear(), b.getMonth(), b.getDate())
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }



    const formatDateDDMMYYHms = (date) => {
        return moment(date).format('DD-MM-YYYY, H:mm:ss')
    }

    const formatDateHms = (date) => {
        return moment(date).format('hh:mm A')
    }

    const formatDateYYYYMMDD = (date) => {
        return moment(date).format('YYYY-MM-DD')
    }

    const formatDateMMMDDYYYY = (date) => {
        return moment(date).format('MMM DD, YYYY')
    }

    const isGuruUser = (userInfo) => {
        if (hasToken()) {
            return getUserTypeId() == GURU
        } else {
            return false
        }
    }

    const isPremiumMember = (userInfo) => {
        if (hasToken()) {
            return getUserTypeId() == PREMIUM
        } else {
            return false
        }
    }

    const isFreemiumMember = (userInfo) => {
        if (hasToken()) {
            return getUserTypeId() == FREEMIUM
        } else {
            return false
        }
    }

    const getFileExtension = (filename) => {
        if (filename)
            return filename.split('.').pop();
    }

    const getFileSize = (filesize) => {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let l = 0, n = parseInt(filesize, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    const getShortDescription = (text, length = null) => {
        let maxLength = length ? length : 30
        if (text != null) {
            let cut = text.indexOf(' ', maxLength);
            if (cut == -1) return text;
            return text.substring(0, cut)
        }
        return ''
    }

    const getShortDescription_dot = (text, length = null) => {
        let maxLength = length ? length : 30
        if (text != null) {
            let cut = text.indexOf(' ', maxLength);
            if (cut == -1) return text;
            if(text.length > length){
                return text.substring(0, cut) + "..."
            }else{
                return text.substring(0, cut)
            }
            
        }
        return ''
    }

    const getDaysRemaining = (date) => {
        const given = moment(date, "YYYY-MM-DD");
        const current = moment().startOf('day');
        const days = moment.duration(given.diff(current)).asDays();
        return isNaN(days) ? '' : days
    }

    const getCurrentMonthRange = () => {

        const startOfMonth = moment().startOf('month').format('DD/MM/YYYY');
        const endOfMonth = moment().endOf('month').format('DD/MM/YYYY');

        return startOfMonth + ' - ' + endOfMonth
    }

    const getCurrentMonthRangeByMonth = (month) => {
        const startOfMonth = moment().month(month).startOf('month').format('DD/MM');
        const endOfMonth = moment().month(month).endOf('month').format('DD/MM');
        return {
            month: month, range: startOfMonth + ' - ' + endOfMonth
        }
    }

    const getMonthOptions = (year) => {
        let currentYear = new Date().getFullYear()
        let monthOptions = []
        const loopTime = year == currentYear ? moment().month() + 1 : 12
        for (let i = 0; i < loopTime; i++) {
            const monthRange = getCurrentMonthRangeByMonth(i)
            monthOptions.push({id: monthRange.month + 1, value: monthRange.range})
        }
        return monthOptions
    }

    const getYearOptions = () => {
        let currentYear = new Date().getFullYear(), years = [];
        let startYear = currentYear - 1;
        while (startYear <= currentYear) {
            const val = startYear++
            years.push({id: val, value: val})
        }
        return years;
    }

    const kFormatter = (num) => {
        if(num) {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
        } else {
            return 0
        }
    }

    const decimalFormatter = (num) => {
        if(num) {
            return Math.round(num * 100) / 100
        } else {
            return 0
        }
    }

    const minuteFormatter = (num)=>{
        let min = num
        if(num < 1) {
            min = num * 60 / 100
        }
        return decimalFormatter(min)
    }



    const formatGraphData = (data) => {
        let labels = []
        let graphData = []
        data.forEach((item)=>{
            labels.push(item.label)
            graphData.push(item.value)
        })
        return {
            labels,
            graphData
        }
    }
    const getMonthYear = (date) => {
        return moment(date).format('MMMM YYYY')
    }

    const formatDateHH = (date) => {
        return moment(date).format('HH')
    }

    const onlyServerDateFormat = (date) => {
        return moment(date).format('YYYY-MM-DD')
    }


    const medicalSlotBlockDay = (date) => {


        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayIndex =  moment(date).day();
        const dayTitle = days[dayIndex];
      

        return  moment(date).format('DD') + "-" +   moment(date).format( 'MMMM-YYYY')
    }


    return {
        getToken,
        formatDateDDMMYYHms,
        isGuruUser,
        isPremiumMember,
        isFreemiumMember,
        isMember,
        hasToken,
        formatDateDDMMYY,
        formatDateYYYYMMDD,
        getProfile,
        hasPermissions,
        getFileExtension,
        getFileSize,
        getShortDescription,
        getShortDescription_dot,
        formatMinutes,
        formatDateDDMMMMYYYY,
        formatDateHms,
        updateUserMetaData,
        secondsToMinutes,
        todayYesterdayMonth,
        formatDateDDMMYY2,
        getDaysRemaining,
        formatDateMMMDDYYYY,
        kFormatter,
        getCurrentMonthRange,
        getMonthOptions,
        getYearOptions,
        formatGraphData,
        decimalFormatter,
        minuteFormatter,
        getMonthYear,
        toSeconds,
        formatDateHH,
        onlyServerDateFormat,
        medicalSlotBlockDay,
        fromatGetDate
    }

}
export default useHelper


