import React from "react";
import Avatar from '../avatar';
import DP from '../../assets/images/dp-100.png';
import Icon from '../icon';
import TickIcon from '../svg/tickIcon';
import Flex from '../flex';
import Button from '../button';
import MinusCircleIcon from '../svg/minusCircleIcon';
import Text from "../text";
import useMySubscription from '../../pages/trainee/member/mySubscription/hooks/mySubscription.hook';

const SubscriptionFreemium = props => {
    const {userInfo} = props
    const { goToProfile, goToSubscribe } = useMySubscription();
    const profileImage = userInfo?.pic ? process.env.REACT_APP_IMG_URL+userInfo?.pic : DP
    const UserStatusIcon = () => {
        if (userInfo?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8"/>}/>

        } else if (userInfo?.userstatus == 'Rejected') {
            return <Icon small danger icon={<TickIcon width="12" height="8"/>}/>
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8"/>}/>
        }

    }
    return(
        <>
            <div className="profile-dp mb-20">

                <Avatar
                    large
                    src={profileImage}
                    alt={userInfo?.fullname}
                    width="100"
                    height="100"
                />
                <div className="ml-80 profile-dp-details">
                    <Text type="PARAGRAPH" text={userInfo?.fullname} className="profile-dp-title" />
                    <Text type="PARAGRAPH" text={'ID: ' + userInfo?.userid} className="profile-dp-subtitle" />
                    <Text type="PARAGRAPH" text="Freemium" className="text-gray" />
                    <div className="account-status">
                        <UserStatusIcon/>
                        <Text type="PARAGRAPH" text={userInfo?.userstatus}/>
                    </div>
                </div>
            </div>
            <Flex justifyContent="start" className="mb-40">
                <Button
                    type="button"
                    linkPrimary
                    text="View Profile"
                    onClick={goToProfile}
                />
            </Flex>
            <div className="compare-main-wrapper">
                <div className="compare-head-main-wrapper">
                    <div className="row">
                        <div className="col-6 col-md-6">
                        </div>
                        <div className="col-3 col-md-3 d-flex flex-column align-items-center justify-content-end">
                            <Text type="H3" text="Freemium" className="mb-20" />
                            <Text type="PARAGRAPH" text="YOUR CURRENT PLAN" className="compare-head-current-plan mb-0" />
                        </div>
                        <div className="col-3 col-md-3 d-flex flex-column align-items-center justify-content-end">
                            <Text type="H3" text="Premium" className="mb-0" />
                            <Flex justifyContent="end" className="align-items-end line-height-1 mb-20">
                                <Text type="H2" text={<>RM {props.packages[0]?.price}/</>} className="mb-0" />
                                <Text type="H4" text="Month" className="mb-0" />
                            </Flex>
                            <Button
                                type="button"
                                small
                                text="Subscribe Now"
                                onClick={props.subscribeNow}
                            />
                        </div>
                    </div>
                </div>
                <div className="compare-content-main-wrapper">
                    <div className="compare-card">
                        <CompareRow
                            title="Video Management"
                            freemiumStatus="Yes"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Course assignment with custom messaging"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Custom User groups"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Advanced group Management"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                    </div>
                    <div className="compare-card">
                        <CompareRow
                            title="Analytics & Reports"
                            freemiumStatus="Yes"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Course assignment with custom messaging"
                            freemiumStatus="Yes"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Custom User groups"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Advanced group Management"
                            freemiumStatus="Yes"
                            premiumStatus="Yes"
                        />
                    </div>
                    <div className="compare-card">
                        <CompareRow
                            title="Integration"
                            freemiumStatus="Yes"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Course assignment with custom messaging"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Custom User groups"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                        <CompareRow
                            title="Advanced group Management"
                            freemiumStatus="No"
                            premiumStatus="Yes"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionFreemium;

const CompareRow = props => {
    return(
        <div className="compare-row">
            <div className="row">
                <div className="col-6 col-md-6">
                    <Text type="SPAN" text={props.title} className="compare-text-left mb-0" />
                </div>
                <div className="col-3 col-md-3 d-flex justify-content-center text-center">
                    {props.freemiumStatus === 'Yes' && (
                        <Icon success small icon={<TickIcon />} />
                    )}
                    {props.freemiumStatus === 'No' && (
                        <Icon gray small icon={<MinusCircleIcon />} />
                    )}
                </div>
                <div className="col-3 col-md-3 d-flex justify-content-center text-center">
                    {props.premiumStatus === 'Yes' && (
                        <Icon success small icon={<TickIcon />} />
                    )}
                    {props.premiumStatus === 'No' && (
                        <Icon gray small icon={<MinusCircleIcon />} />
                    )}
                </div>
            </div>
        </div>
    )
}
