import React from 'react';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Avatar from '../../../../../components/avatar';
import DP from '../../../../../assets/images/dp-100.png';
import Icon from '../../../../../components/icon';
import TickIcon from '../../../../../components/svg/tickIcon';
import EditIcon from '../../../../../components/svg/editIcon';
import TextReadonly from '../../../../../components/textReadonly'
import ShareAffiliateLink from '../../../../../components/shareAffiliateLink';
import NavLearnerProfile from '../../../../../components/navLearnerProfile';
import useHelper from "../../../../../hooks/helpers.hook";
 
const PremiumProfileView = (props) => {
    const {formatDateDDMMYY} = useHelper()
    const { goToEditProfile, profileDetails } = props;
    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL+profileDetails?.pic : DP
    const role_of_user = profileDetails?.user_type ? 'Learner ('+profileDetails?.user_type+')':'Learner'
    const UserStatusIcon = ()=>{
        if(profileDetails?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8" />} />

        } else if(profileDetails?.userstatus == 'Rejected'){
            return <Icon small danger icon={<TickIcon width="12" height="8" />} />
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8" />} />
        }

    }
    const fullAddress = ()=>{
        const address1 = profileDetails?.address1 ? profileDetails?.address1 : ''
        const address2 = profileDetails?.address2 ? profileDetails?.address2 : ''
        const city = profileDetails?.city ? profileDetails?.city : ''
        const statetitle = profileDetails?.statetitle ? profileDetails?.statetitle : ''
        const country = profileDetails?.country ? profileDetails?.country : ''
        const zipcode = profileDetails?.zipcode ? profileDetails?.zipcode : ''

        return address1 +' '+ address2 +' '+ ' '+ city  +' '+ statetitle +' '+ country +' '+zipcode

    }
    return (
        <>
        <div className='change-password my-4 px-5 py-3 rounded-0'>
        <div className='container-flied mt-20'>
            <div className="row ">
                <div className="col-12 col-md-12">
                   <div className='row'>
                        <div className='col-md-6'>
                        <Text
                        type="H2"
                        text="Personal Information"
                        className="font-weight-500 my-3"
                    />
                    <div className="profile-dp mb-20">
                        <Avatar
                            w100
                            src={profileImage}
                            alt={profileDetails?.fullname}
                        />
                        <div className="profile-dp-details">
                            <Text type="PARAGRAPH" text={profileDetails?.fullname} className="profile-dp-title" />
                            <Text type="PARAGRAPH" text={profileDetails?.email} className="profile-dp-email" />
                            <div className="account-status">
                                <UserStatusIcon/>
                                <Text type="PARAGRAPH" text={profileDetails?.userstatus} />
                            </div>
                        </div>
                    </div>
                        </div>
                        <div className='col-md-6'>

                        <div className=''>
                    <ShareAffiliateLink {...props}/>
                    </div>

                        </div>
                    </div>
                    
                    <div className='row mt-5'>
                        <div className='col-md-6'>
                        <div className="d-flex align-items-center mb-20">
                        <Text type="H4" text="General Information" className="mr-24 mb-0" />
                        <Button
                            linkPrimary
                            text="Edit"
                            iconLeft={<Icon small square icon={<EditIcon />} />}
                            onClick={goToEditProfile}
                        />
                    </div>
                        <div className="row">
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Role of User"
                                text={role_of_user}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Member ID"
                                text={profileDetails?.userid}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Name"
                                text={profileDetails?.fullname}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Email Address"
                                text={profileDetails?.email}
                                className="text-ellipsis"
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="UserName"
                                text={profileDetails?.uname}
                                className="text-ellipsis"
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="NRIC"
                                text={profileDetails?.ic_passportno}
                                className="text-ellipsis"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Date Of Birth"
                                text={formatDateDDMMYY(profileDetails?.dob)}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Level Of Education"
                                text={profileDetails?.education}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Occupation"
                                text={profileDetails?.occupation}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Monthly Income Range"
                                text={profileDetails?.incomerange}
                            />
                        </div>
                    </div>
                        </div>
                        <div className='col-md-6'>
                        <Text type="H4" text="Contact Information" className="mb-30" />
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Phone Number"
                                text={profileDetails?.mobile}
                            />
                        </div>
                        <div className="col-12 col-md-8">
                            <TextReadonly
                                label="Address"
                                text={fullAddress()}
                            />
                        </div>
                    </div>
                        </div>
                    </div>        

                </div>              
            </div>
        </div>   
        </div>
        </>
    );
};

export default PremiumProfileView;

