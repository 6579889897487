import React from "react";
import useHelper from "../../hooks/helpers.hook";
import Image from "../image";
import Text from "../text";
import Flex from '../flex';
import './favouriteGuruCard.scss';
import VideoPlayerTwoIcon from '../svg/videoPlayerTwoIcon'

const FavouriteGuruCard = props => {
    const {kFormatter} = useHelper()
    return(
        <div className="favourite-guru-card pointer" onClick={props.onClick}>
        <div className="favourite-guru-item-image">
            <Image
                src={props.src}
                alt={props.title}
                height={315}
                width={234}
            />
        </div>
        <div className="favourite-guru-item-content">
            <Text
                type="H3"
                className="mb-0"
                text={props.title}
            />
            <Text
                type="PARAGRAPH"
                className="font-size-13 break-all"
                text={props.subtitle}
            />
            {props.videos > 0 &&
                <>
                    <Flex justifyContent="start" alignItemsCenter>
                        <VideoPlayerTwoIcon width="14" height="14"/>
                        <Text
                            type="SPAN"
                            className="font-size-13 mb-0 ml-2"
                            text={`${kFormatter(props.videos)} ${props.videos > 1 ? 'Videos':'Video'}`}
                        />
                    </Flex>
                </>
            }
        </div>
    </div>
    )
}

export default FavouriteGuruCard;
