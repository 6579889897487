import React from "react";
import { AiOutlineRightCircle } from "react-icons/ai";

import Text from "../text";

const HomeDashboardBoxes = props => {
   
             const {text} =props
    return (
        <div className="dash-guru-status-card py-lg-4 h-100">
            <div className="">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            
           
            {/*
            <AiOutlineRightCircle className="fa-solid dash-icon-c" />            
            */}
            
            
           
             
            <div className="chat-box mt-2">            
            
           
            </div>
            </div>
            <div className="view-icon">
            {props.icon == 0 &&(
                <AiOutlineRightCircle  className="fa-solid dash-icon-c" size={30}  onClick={props.onClick}/>
            )}

            {props.icon != 0 &&(
                <></>
            )}
                   </div>
            
            <img src={props?.img}  alt="copy" className='dash-icon-50' />
            <Text type="H2" text={props.number} className="dgsc-number dash-text-dashboard"/>
           
        </div>
    )
    
  
}

export default HomeDashboardBoxes;