import React from "react";
import AlertWarningIcon from "../svg/alertWarningIcon";
import Text from "../text";
import './programBarCard.scss';

const ProgressBarCard = props => {
    return (
        <>
            <div className="progress-bar-wrapper" {...props}>
                {!props?.hideText && (
                    <>
                    {props.error ?
                        <Text
                            type="PARAGRAPH"
                            className="progress-bar-text text-danger"
                            text={
                                <><AlertWarningIcon width="15" height="13"/> Upload Failed! Please check your video
                                    settings.</>
                            }
                        />
                        :
                        <Text
                            type="PARAGRAPH"
                            className="progress-bar-text"
                            text={`Uploading.... ${props.width}% Completed`}
                        />
                    }
                </>
                )}
                <div class="progress-bar-main">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example with label"
                        style={{width: `${props.width}%`}}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgressBarCard;

ProgressBarCard.defaultProps = {
    width: '0',
    hideText: false
}
