import React from "react";
import Button from "../button";
import Flex from "../flex";
import MoreIcon from "../svg/moreIcon";

import Text from "../text";
import './shareVia.scss'
import useShareVia from './hooks/shareVia.hook'
import Modal from '../modal'
import More from "./more";
import shareIcon from '../../assets/img/shareIconWhite.png'
import {
    FacebookIcon,
    FacebookShareButton, TwitterIcon,
    TwitterShareButton, WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

const ShareVia = props => {
    const {
        isModal,
        setIsModal,
        openMore,
    } = useShareVia()
    const {text, affiliateLink} = props
    return(
        <>
            
            <div className="row">
            {/* <div class="col-md-6">
                <img src={shareIcon} class="icon-50" alt="" />
            </div> */}
            <div className="col-md-12">
            <div className="">
                <More affiliateLink={affiliateLink} />
            </div>
            </div>
        </div>


                {/*<Flex justifyContent='between'  className="align-items-center">
                  
                    <More affiliateLink={affiliateLink} />
                    
                    <Flex justifyContent='between'>

                   

                        <FacebookShareButton
                            url={affiliateLink}
                            className="mr-10"
                        >
                            <FacebookIcon
                                size={44}
                                round={true}
                            />
                        </FacebookShareButton>

                        
                        <WhatsappShareButton url={affiliateLink}
                                             className="mr-10" >
                            <WhatsappIcon size={44} round={true}/>
                        </WhatsappShareButton>
                        <TwitterShareButton url={affiliateLink}
                                            className="mr-10" >
                            <TwitterIcon size={44} round={true}/>
                        </TwitterShareButton>

                    </Flex>
                    <Button
                        style={{textDecoration: 'none',flexDirection: 'column'}}
                        linkPrimary
                        onClick={openMore}
                        text={
                            <>
                                <MoreIcon width="27" height="7" />
                                <span className="mt-10">{text?.MORE}</span>
                            </>}
                        />
                        </Flex>*/}
            
            {isModal &&
                <Modal
                    onClose={()=>setIsModal(false)}
                    className="modal-signup-right-area"
                    onOuterClose={()=>setIsModal(false)}
                    modalContent={<More affiliateLink={affiliateLink} />}
                />
            }
        </>
    )
}

export default ShareVia;
