import React from 'react';
import { useState } from 'react';
import { NavLink } from "react-router-dom";
import Button from '../button';
import "./dropdown.scss"
import ChevronDownIcon from '../svg/chevronDownIcon'


const Dropdown = props => {
    const [ isOpen, setIsOpen ] = useState(false);
    return (
        <>
            <div className={`dropdown ${props.right ? 'dropdown-right' : ''} ${props.secondaryDropdown ? 'dropdown-secondary' : ''}`}>
                <Button
                    className="dropdown-toggle"
                    onClick={(e) => setIsOpen(!isOpen)}
                    text={props.text}
                    icon={
                        <ChevronDownIcon up={isOpen}/>
                    }
                    gray
                    outline
                />
                {isOpen &&
                    <>
                        <ul className={`dropdown-menu ${props.className ? props.className : ''}`}>
                            {props?.options?.map(function(d, idx){
                                return (
                                    <>
                                        <li className="dropdown-item">
                                            <NavLink key={idx} to={d.link} activeClassName="active" className="dropdown-link">
                                                {d.name}
                                            </NavLink>
                                        </li>
                                    </>
                                )
                            })}
                        </ul>
                        <div className="dropdown-backdrop" onClick={()=>setIsOpen(false)}></div>
                    </>
                }
            </div>
        </>
    );
};

export default Dropdown;

Dropdown.defaultProps = {
    text: "Title",
    options: [
        {
            name:"Something 1",
            link: "/"
        },
        {
            name:"Something 2",
            link: "/"
        },
        {
            name:"Something 3",
            link: "/"
        },
    ]
};
