import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import LayoutPanel from "../../../components/layoutPanel";
import WalletDashboardView from "./view/walletDashboardView";

const WalletDashboard = props => {

    const userInfo = useSelector(state => state.app.userInfo)
    const [walletAmount,setWallentAmount] = useState(0);
    
    return (
        <>
              <LayoutPanel {...props}  footer>
                <WalletDashboardView {...props} userInfo={userInfo}  setWallentAmount={setWallentAmount} walletAmount={walletAmount} />
            </LayoutPanel>
        </>
    )
      
}

export default WalletDashboard;
