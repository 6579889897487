import React from 'react';

const WeChatIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2812 6C9.48125 6 2.28125 12 2.28125 19.5C2.28125 23.8 4.58125 27.5 8.28125 30L6.28125 36L12.5812 32.1C14.2812 32.7 16.2812 33 18.2812 33C27.0812 33 34.2812 27 34.2812 19.5C34.2812 12 27.0812 6 18.2812 6Z" fill="url(#paint0_linear_3876_5254)"/>
            <path opacity="0.05" d="M19.2812 29C19.2812 30.371 19.5642 31.684 20.0602 32.912C28.0302 32.167 34.2812 26.487 34.2812 19.5C34.2812 18.655 34.1773 17.832 34.0023 17.031C33.7623 17.021 33.5243 17 33.2812 17C25.5613 17 19.2812 22.383 19.2812 29Z" fill="black"/>
            <path opacity="0.07" d="M19.7812 29C19.7812 30.35 20.0702 31.641 20.5742 32.846C28.2952 31.908 34.2812 26.335 34.2812 19.5C34.2812 18.83 34.2043 18.178 34.0933 17.535C33.8232 17.521 33.5553 17.5 33.2812 17.5C25.8373 17.5 19.7812 22.659 19.7812 29Z" fill="black"/>
            <path d="M46.2813 29C46.2813 22.9 40.4813 18 33.2813 18C26.0813 18 20.2812 22.9 20.2812 29C20.2812 35.1 26.0813 40 33.2813 40C35.0813 40 36.7813 39.7 38.2813 39.2L43.2813 42L41.8813 37.2C44.5813 35.2 46.2813 32.3 46.2813 29Z" fill="url(#paint1_linear_3876_5254)"/>
            <path d="M14.2812 15C14.2812 16.1 13.3812 17 12.2812 17C11.1812 17 10.2812 16.1 10.2812 15C10.2812 13.9 11.1812 13 12.2812 13C13.3812 13 14.2812 13.9 14.2812 15ZM24.2812 13C23.1812 13 22.2812 13.9 22.2812 15C22.2812 16.1 23.1812 17 24.2812 17C25.3812 17 26.2812 16.1 26.2812 15C26.2812 13.9 25.3812 13 24.2812 13Z" fill="#135D36"/>
            <path d="M30.2812 26.5C30.2812 27.3 29.5812 28 28.7812 28C27.9812 28 27.2812 27.3 27.2812 26.5C27.2812 25.7 27.9812 25 28.7812 25C29.5812 25 30.2812 25.7 30.2812 26.5ZM37.7812 25C36.9812 25 36.2812 25.7 36.2812 26.5C36.2812 27.3 36.9812 28 37.7812 28C38.5813 28 39.2812 27.3 39.2812 26.5C39.2812 25.7 38.5813 25 37.7812 25Z" fill="#474747"/>
            <defs>
            <linearGradient id="paint0_linear_3876_5254" x1="3.15525" y1="5.771" x2="25.1973" y2="31.217" gradientUnits="userSpaceOnUse">
            <stop stop-color="#33C481"/>
            <stop offset="1" stop-color="#21A366"/>
            </linearGradient>
            <linearGradient id="paint1_linear_3876_5254" x1="20.7592" y1="17.541" x2="43.8233" y2="38.647" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F0F0F0"/>
            <stop offset="1" stop-color="#BBC1C4"/>
            </linearGradient>
            </defs>
        </svg>
    );
};

export default WeChatIcon;

WeChatIcon.defaultProps = {
    width: '49',
    height: '48'
}