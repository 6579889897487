import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../../components/breadcrumbs'
import LearnByTopics from '../../../../components/learnByTopics'
import Section from '../../../../components/section'
import Text from '../../../../components/text'
import { DEFAULT_FILTERS, DEFAULTFILTERS } from '../../../../constants/app.constants'
import Button from '../../../../components/button'
import listImg from "../../../../assets/img/listImage.png";
import coloumImg from "../../../../assets/img/columnImage.png";
import TopicsList from '../../../../components/topicsList'
import Modal from '../../../../components/modal'
import VideoPlayer from '../../../../components/videoplayer'
import Pagination from '../../../../components/pagination'
import CategorySubcatModal from '../../../../components/courseSearch/categorySubcatModal'
import { useLocation } from 'react-router-dom'

const FavouriteCourseView = (props) => {

  const { text } = props;

  const breadcrumbList = [
    {
      text: "Dashboard",
      link: "/trainee/dashboard",
    },
    {
      text: "Courses",
      link: "/trainee/courses",
    },
  ];

  const [isVideoModal, setIsVideoModal] = useState(false);
  const [vidoePlayUrl, setVidoePlayUrl] = useState("");


  const location = useLocation();

  const [getCategoryId,setCategoryId] = useState(0);
  const [getSubCategoryId,setSubCategoryId] = useState(0);
  const [getSearchName,setSearchName] = useState('');

  const playVideo = (val) => {
    props.commonHook.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
      console.log("getImagePath--->",data.data)
      setVidoePlayUrl(data.data);
      setIsVideoModal(true);
    });
  };

  useEffect(() => {
    if(getCategoryId !=0  ){
    //  props?.commonHook.setkeys();
      DEFAULT_FILTERS.uri = 'trainerFavourites';
      if(location.pathname === "/trainee/favourite/favourite-course"){
        DEFAULT_FILTERS.type = "favourite";
      }

      if(location.pathname === "/trainee/favourite/liked-course"){
        DEFAULT_FILTERS.type = "liked";
      }

      if(location.pathname === "/trainee/favourite/favourite-course"){
        DEFAULT_FILTERS.type = "favourite";
      }
      
      DEFAULT_FILTERS.sortkey = "asc";
      DEFAULT_FILTERS.fromdate = "";
      DEFAULT_FILTERS.todate = "";
      DEFAULT_FILTERS.page = 1;
      DEFAULT_FILTERS.instructorId= 0;
      DEFAULT_FILTERS.perpage = 6;
     props?.commonHook.InnergetList(DEFAULT_FILTERS)
    }
},[getCategoryId,getSubCategoryId,getSearchName])


  return (
    <div>
      {/* <Breadcrumbs
        breadcrumbList={breadcrumbList}
      /> */}
      <Section className="pt-3 lbt-section rml-24 overflow-outer">
        <div className="section-head mt-lg-2 mt-5">
        
          {props.pathname == "/trainee/favourite/favourite-course" &&(

          <Text
          type="H3"
          text="Favourite Courses"
          className="section-title text-uppercase fs-4"
          />

          )}
        
        
          {props.pathname == "/trainee/favourite/liked-course" &&(

                    <Text
                    type="H3"
                    text="Liked Courses"
                    className="section-title text-uppercase fs-4"
                    />

          )}
          
        </div>

        

        
        <CategorySubcatModal
                            commonHook={props.commonHook}
                            getCategoryId={getCategoryId}
                            getSubCategoryId={getSubCategoryId}
                            setCategoryId={setCategoryId}
                            setSubCategoryId={setSubCategoryId}
                            getSearchName={getSearchName}
                            setSearchName={setSearchName}
                    />

        <div className="row shadow bg-body rounded  p-2 text-right mb-4">
          <div className="col-md-12">
            <Button
              link
              text={
                <img src={listImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                props.setDisplayType("list");
              }}
              type="button"
            />

            <Button
              link
              text={
                <img src={coloumImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                props.setDisplayType("coloum");
              }}
              type="button"
            />
          </div>
        </div>

        
        <div className="row ">


            
          <TopicsList {...props} {...props.commonHook} playVideo={playVideo} displayType={props.displayType} />
          <Pagination
                                totalPages={props?.commonHook.tableData?.totalPages}
                                currentPage={props?.commonHook.tableData?.currentPage}
                                totalRecords={props?.commonHook.tableData?.totalRecords}
                                nextPage={props.commonHook.nextPage}
                                previousPage={props.commonHook.previousPage}
                                perPage={5}
                                />
                    
      
        </div>

          
      </Section>

      {isVideoModal && (
        <Modal
          title=""
          centered
          open={isVideoModal}
          onOk={() => setIsVideoModal(false)}
          onCancel={() => setIsVideoModal(false)}
          zIndex={10000}
          width={window.innerWidth - window.innerWidth / 5}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <VideoPlayer videourl={vidoePlayUrl} width="100%" height="460" />
        </Modal>
      )}
    </div>
  );
}

export default FavouriteCourseView
