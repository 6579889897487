import React, {useEffect} from "react";
import useSubscribePremiumModal from "../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import LayoutPanel from "../../../components/layoutPanel";
import SubscribePremiumModal from "../../../components/subscribePremiumModal";
import FavouriteGuruDetailsView from "./view/FavouriteInstructorDetailsView";
import {DEFAULT_FILTERS} from "../../../constants/app.constants";
import useGetTableList from "../../../hooks/getTableListCommonHook";
import {useLocation, useParams} from "react-router-dom";

const FavouriteGuruDetails = props => {
    const {guruId} = useParams()
    const {pathname} = useLocation()
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const commonHook = useGetTableList();


    let type = 0

    if(pathname.includes('/favourite-instructor/favourite-instructor-details/newest/')) {
        type = 2

    } else if (pathname.includes('/favourite-instructor/favourite-instructor-details/top-viewed')){
        type = 3
    } else {
        type = 1
    }



    useEffect(() => {
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'user_favourite_guru_all_videos';
        DEFAULT_FILTERS.type = type;
        DEFAULT_FILTERS.guruid = guruId;
        commonHook.getList(DEFAULT_FILTERS)
    }, [type])



    return (
        <>
            <LayoutPanel {...props}>

                <SubscribePremiumModal {...props}/>
                <FavouriteGuruDetailsView {...props} {...commonHook} />
            </LayoutPanel>
        </>
    )
}

export default FavouriteGuruDetails;
