import React,{useEffect, useState} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import Loader from '../../../components/loader';
import ChatWithInstructorView from './views/chatWithInstructorView';
import LayoutPanel from '../../../components/layoutPanel';
import useChatWithInstructor from './hook/chatWithInstructor.hook';



const ChatWithInstructor =(props)=>{
  
  const commonHook = useGetTableList();
  const thisHook = useChatWithInstructor();
  var pagetype = 0;
  useEffect(()=>{
    commonHook.getCategories({perpage:100});
  

    
  },[])

  const window_path = window.location.pathname
  const test = window_path.includes("/trainee/instructor/chat-with-instructor");
if(test){
    
  pagetype = 1;
}
 
  



  if (commonHook?.loading) {
  
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
  
  } else {

    return (
      <>
      <LayoutPanel {...props}>
      <ChatWithInstructorView  {...props}  dropdownsData={commonHook.dropdownsData} {...commonHook} {...thisHook} pagetype={pagetype}/>
      </LayoutPanel>
      </>
    )
  
  }
  
}

export default ChatWithInstructor;
