import React from "react";

import LayoutPanel from "../../../components/layoutPanel";
import nodata from "../../../assets/img/unauthorised.avif";



const   UnauthorizedView = props => {



      


    return (
        <>
              <LayoutPanel {...props}  footer>
                
              <div className="row justify-content-center">
          <div className="col-md-6 text-center ">
              <img src={nodata} className="img-fluid" />
            </div>
          </div>
            </LayoutPanel>
        </>
    )
      
}

export default UnauthorizedView;
