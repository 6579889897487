import {useFormik} from 'formik';
import * as Yup from 'yup';
import Loader from '../../../../components/loader';
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import { useEffect, useState } from 'react';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';
import Pagination from '../../../../components/pagination';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import useHelper from '../../../../hooks/helpers.hook';
import editImg from '../../../../assets/img/Edit.png'
import viewImg from '../../../../assets/img/Eye.png'
import { CgNametag } from 'react-icons/cg';
import Radio from '../../../../components/radio';
import Text from '../../../../components/text';
import GoBackButton from '../../../../components/goBackButton';
import Flex from '../../../../components/flex';

const ParticipateQuiz = (props) => {
    const {formatDateMMMDDYYYY} = useHelper();
    const commonHook = useGetTableList();
    console.log("props.options",props.options)
    useEffect(()=>{
      /*  commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'iGetCourseQuizQuestions';
        DEFAULT_FILTERS.moduleId =props.selectedData.srno;
        DEFAULT_FILTERS.perpage = 2
        commonHook.getList(DEFAULT_FILTERS)
      */
    },[])

    const [answer, setAnswer] = useState(false);

    const setValue = (e)=> {
        setAnswer(e.target.value)
    }

    if (commonHook?.loading) {
        return (
            <>
            
            
              
            </>
        )
    } else {
  
    return (
        <>
            <div className='row'>

                <div className='col-md-9'>
                <Text
                type="H3"
                text={props.data.question}
                className="font-weight-500 text-center mb-48"
            />
                </div>
                <div className='col-md-3'>
                <Text
                type="H5"
                text={props.i + 1 + "/" + props.count}
                className="font-weight-500 text-center mb-48"
            />
                </div>

            </div>
            
            

            
            <div>

            {props?.options?.map(function (item, index) {

                if(item.questionId == props?.data.srno){

                        return (
                            <div className='row'>
                            <div className='offset-lg-3 col-md-6'>
                            <Radio
                                radioSecondary
                                active={answer === item.srno}
                                id={item.srno}
                                text={item.optionValue}
                             
                                checked={answer === item.srno}
                                value={item.srno}
                                name="quizOptions"
                                onChange={(e)=>{setAnswer(item.srno)}}
                               
                             
                            />
                            </div>
                            </div>
                        )
                   
                }
            })
            }

      
          

               
               
                      
            </div>
          

                </>


    )
  }

};

export default ParticipateQuiz;
