import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useState} from "react";

const useWorkExperience = () => {
    const [dropdownsData, setDropdownsData] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {post} = useHttpHandler()

    const goBackToAddressInformation = () =>{
        navigate('/instructor/address-information')
    }
    const goToBankDetails = () =>{
      //  navigate('/instructor/dashboard')
      navigate('/instructor/upload-attachments')
    }
    let selectedTopicsOfInterestCategory = []
    let finalSelected = [];
 
    const setSelectedOptions = (isSelected, optionId)=>{
        if(isSelected) {
            
            finalSelected = finalSelected.filter(e=>e !== optionId);
            selectedTopicsOfInterestCategory.push(optionId)
            console.log("selected",optionId,selectedTopicsOfInterestCategory,finalSelected.length,finalSelected);
        } else {
            
            finalSelected.push(optionId);
            //finalSelected = finalSelected.filter(e=>e !== optionId);
            selectedTopicsOfInterestCategory = selectedTopicsOfInterestCategory.filter(e => e !== optionId); 
            console.log("unselected",optionId,selectedTopicsOfInterestCategory,finalSelected.length,finalSelected);
            /*
            const index = selectedTopicsOfInterestCategory.indexOf(optionId);
            if (index > -1) { // only splice array when item is found
                selectedTopicsOfInterestCategory.splice(index, 1); // 2nd parameter means remove one item only
            }*/
        }
    }

   

    const getDropDowns = ()=> {
        setLoading(true)
        post('fetchInstructorWorkExperience').then((res)=>{
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let occupationOptions = []
                let topicOfInterest = data?.categories;
                let subTopic = data?.subCategories;
                
                data?.occupation?.map((item, index)=>{
                    occupationOptions.push({value:item.occupationid, label: item.occupation,text:item.occupation})
                })
                setDropdownsData({
                    
                    occupationOptions,
                    topicOfInterest,
                    subTopic
                })
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        }).catch(err => {setLoading(false);})
    }

    const submitWorkExperience = (values)=>{

    }

    return {
        goBackToAddressInformation,
        goToBankDetails,
        getDropDowns,
        dropdownsData,
        loading,
        submitWorkExperience,
        setSelectedOptions,
        selectedTopicsOfInterestCategory,
        finalSelected
        
    }

}
export default useWorkExperience


