import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import PaymentView from "./view/PaymentView";
import usePayment from "./hooks/payment.hook";
import Loader from "../../../components/loader";
import useSubscribe from "../subscribe/hooks/subscribe.hook";

const Payment = props => {
    const paymentHook = usePayment()
    const subscribeHook = useSubscribe()

    useEffect(()=>{
    //    subscribeHook.getPackages()
    },[])

    if (paymentHook.loading || subscribeHook.loading) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }

    return (
        <>
            <SignupLayout {...props}>
                <PaymentView {...props} {...paymentHook} packages={subscribeHook?.packages} />
            </SignupLayout>
        </>
    )
}

export default Payment;
