import useHttpHandler from "../../../hooks/httphandler.hook";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const useVideoPlayers = () => {

    const navigate = useNavigate()
    const [isModal, setIsModal] = useState(false);
    const [docId, setDocId] = useState('')
    const {post} = useHttpHandler()

    const getVideoDocId = (videoId) => {
        post('user_video_check_viewed', {videoid: videoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                console.log('getVideoDocId', data[0].docid)
                sessionStorage.setItem('docId', data[0].docid)
                setDocId(data[0].docid)
            } else {

            }
        }).catch(err=>{})
    }

    const updatePresentVideoDuration = (docId, duration) => {

        console.log('docid',docId , 'duration', duration)

        post('update_present_video_duration', {docid: docId, watchduration: duration}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {

            } else {

            }
        }).catch(err=>{})
    }

    const finishedPlayingVideo = async (docId) => {

        await post('user_video_finished', {docid: docId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {

            } else {

            }
        }).catch(err=>{})
    }

    const goToNextVideo = ()=>{
        const nextVideoId = sessionStorage.getItem('nextVideoId')
        if(nextVideoId) {
            navigate('/trainee/my-learning/video-details/' + parseInt(nextVideoId))
        }
    }

    return {
        getVideoDocId,
        updatePresentVideoDuration,
        docId,
        isModal,
        setIsModal,
        finishedPlayingVideo,
        goToNextVideo
    }
}
export default useVideoPlayers


