import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import * as Yup from "yup";

import Breadcrumbs from '../breadcrumbs';
import Button from '../button';
import FlowCard from '../flowCard';
import Image from '../image';
import Input from '../input';

import Select from '../input/select';
import Textarea from '../input/textarea';
import ProgressBarCard from '../programBarCard';
import Text from '../text';
import { UploadComponent } from '../../pages/instructor/uploadAttachments/view/UploadAttachmentsView';

import useHttpHandler from '../../hooks/httphandler.hook';
import useHelper from "../../hooks/helpers.hook";
import axios from "axios";
import {toast} from "react-toastify";
import useGuruUploadVideo from '../../pages/instructor/uploadVideo/hooks/guruUploadVideo.hook';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE } from '../../constants/app.constants';
import Flex from '../flex';
import InputFile from '../input/inputFile';
import Icon from '../icon';
import TickIcon from '../svg/tickIcon';
import CameraIcon from '../svg/cameraIcon';
import ProgramBarSecondaryCard from '../programBarCard/programBarSecondaryCard';
import Modal from '../modal'
import StaticVideoPlayer from '../videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../htmlparser';
import VideoPlayer from "../videoplayer";

import UploadVideoPreview from '../addTopicCompnent/videoUploadPreview';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import useNewCommonHook from '../../hooks/newCommonHook';
import TimeButton from '../timeSlot/timeButton';
import SelectNew from '../input/selectnew';
import MonthButton from '../monthButton/monthButton';
import TimeButtonLearner from '../timeSlotLearner/timeButtonLearner';
import Table from '../table';
import Section from '../section';
import { useSelector } from 'react-redux';



const MyFinishedMeetingSlots = (props) => {
    //const [progress, setProgress] = useState(null);
    const navigate = useNavigate();
    const userInfo = useSelector(state => state.app.userInfo)
  const commonHook = useNewCommonHook()
    const {formatDateYYYYMMDD,formatDateHms,formatDateHH,formatDateMMMDDYYYY,onlyServerDateFormat,secondsToMinutes,todayYesterdayMonth} = useHelper();
    const [todayDate,setTodaydate] = useState(onlyServerDateFormat(new Date()))
    const [date, setDate] = useState(new Date());
    
    const [selectedDate,setSelectedDate] = useState(formatDateYYYYMMDD(new Date()));
    
    const handleDateSelected = (date) => {
      console.log('Selected date:', date);
      setDate(date);
      setSelectedDate(formatDateYYYYMMDD(date))
     
      /*commonHook._promiseService({"uri":"getInstructorDateWiseTimeSlots","date":formatDateYYYYMMDD(date)}).then((data) => {
        
        if(data.data.status.result == 200){
          console.log(data.data.data);
          setSelectedTimeSlot(data.data.data);
         
  
        }
  
      });*/
      // Your custom logic here
    };
  
 


  
  
   

    useEffect(()=>{
       
    
        handleDateSelected(new Date());
},[])


const [presentMettings,setPresentMettings] = useState([]);






const checkBooking = (itemdata) =>{

  
  
  commonHook._promiseService({"uri":"getVoipFinishedMeeting"}).then((data) => {
        
    if(data.data.status.result == 200){

      
      setPresentMettings(data.data.data)
      
  
    }

  });
  

}



  
  useEffect(()=>{
    checkBooking();
   
  },[])




  return (
    <>
    
    
    
    <Section className="bg-white">
    <div>
      
      <div className='calendar-container'>
      </div>
        
      <div className="card border-radius-5 pad-10 ">
       <h5 class="text-hash-color text-center mt-10">Your Finished Meeting</h5 >
          <div className='row'>
          <div className="col-md-12">
          {presentMettings.length ===0 &&(
            <>
            <Text type="H4" text="No Meetings Available" style={{textAlign:'center'}}/>
            </>
          )}

          {presentMettings.length >0 &&(


                          <Table
                              thead={
                                  <tr>
                                  <th role="columnheader">Slot Date</th>
                                  <th role="columnheader">Slot Time</th>
                                  <th role="columnheader">Meeting Type</th>
                                  <th role="columnheader">Amount</th>
                                 
                                 
                                  </tr>
                            }
                          tbody={
                              <>
                                  {presentMettings?.map(function (item, index) {
                                      return (
                                          <tr role="row">
                                            
                                              <td role="cell">{formatDateMMMDDYYYY(item.slot_date)}</td>
                                              <td role="cell">{item.slot_time}</td>
                                              <td role="cell">
                                                {item.metting}
                                            </td> 
                                              <td role="cell">{'$' + item.price}</td>
                                            
                                      
                                      
                                          </tr>
                                      )
                                  })}
                                
                              </>
                          }
                      />

                    )             }

          </div>

          </div>
     </div>   


    </div>
    </Section>

</>
  )
  
}



export default MyFinishedMeetingSlots;

