import React from "react";
import Button from "../button";
import Flex from "../flex";
import Text from "../text";
import TelegramIcon from '../svg/telegramIcon'
import InstagramIcon from '../svg/instagramIcon'
import WeChatIcon from '../svg/weChatIcon'
import FacebookMessageIcon from '../svg/facebookMessageIcon'
import GmailIcon from '../svg/gmailIcon'
import MessageSMSIcon from '../svg/messageSMSIcon'
import {
    EmailShareButton,
    FacebookIcon, FacebookMessengerShareButton,
    FacebookShareButton, TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

const More = props => {
    const {affiliateLink} = props
    return (
        <>
            <Text
                type="H5"
                text="Share link via"
                className="mb-20 font-weight-500 text-dark"
                
            />
            <div class="share-social-more">
                <div className="sm-item-box">
                    <FacebookShareButton url={affiliateLink}>
                        <SM
                            text="Facebook"
                            icon={<FacebookIcon round={true} width="19" height="18"/>}
                        />
                    </FacebookShareButton>
                </div>
                <div className="sm-item-box">
                    <WhatsappShareButton url={affiliateLink}>
                        <SM
                            text="Whatsapp"
                            icon={<WhatsappIcon round={true} width="19" height="18"/>}
                        />
                    </WhatsappShareButton>
                </div>
                {/*<div className="sm-item-box">
                    <TwitterShareButton url={affiliateLink}>
                        <SM
                            text="Twitter"
                            icon={<TwitterIcon round={true} width="49" height="48"/>}
                        />
                    </TwitterShareButton>
                </div>*/}
                <div className="sm-item-box">
                    <TelegramShareButton url={affiliateLink}>
                        <SM
                            text="Telegram"
                            icon={<TelegramIcon round={true} width="19" height="18"/>}
                        />
                    </TelegramShareButton>
                </div>
                {/*<SM
                    text="Instagram"
                    icon={<InstagramIcon width="49" height="48"/>}
                />*/}

                <div className="sm-item-box">
                    <FacebookMessengerShareButton url={affiliateLink}>
                        <SM
                            text="Messenger"
                            icon={<FacebookMessageIcon width="19" height="18"/>}
                        />
                    </FacebookMessengerShareButton>
                </div>
                <div className="sm-item-box">
                    <EmailShareButton url={affiliateLink}>
                        <SM
                            text="Email"
                            icon={<GmailIcon width="19" height="18"/>}
                        />
                    </EmailShareButton>
                </div>

                {/*
                 <div className="sm-item-box">
                <a href={`sms:;body=${affiliateLink}`} className="text-dark text-decoration-none">
                    <SM
                        text="Message"
                        icon={<MessageSMSIcon width="49" height="48"/>}
                    />
                    
                </a>
                </div>
                {/*<SM
                    text="WeChat"
                    icon={<WeChatIcon width="49" height="48"/>}
                />*/}
                
            </div>
        </>
    )
}

export default More;

const SM = props => {
    return (
        <div className="sm-item">
            <span className="btn-icon-left">{props.icon}</span>
            {props.text}
        </div>
    )
}
