import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import AddressInformationView from "./view/AddressInformationView";
import InfomationNavSideGuru from "../../../components/infomationNavSideGuru";
import useAddressInformation from "../../trainee/addressInformation/hooks/addressInformation.hook";
import useProfile from "../settings/profile/hooks/profile.hook";
import Loader from "../../../components/loader";

const AddressInformation = props => {
    const {text} = props
    const addressInformationHook = useAddressInformation()
    const profileHook = useProfile()
    const isLoaded = !profileHook.loading && !addressInformationHook?.loading

    useEffect(() => {
        addressInformationHook?.getCountries()
        addressInformationHook?.getStatesByCountry()
        profileHook?.getProfileDetails()
    }, [])
    useEffect(() => {
        if (profileHook?.profileDetails?.stateid) {
            addressInformationHook?.getCitiesByState(profileHook?.profileDetails?.stateid)
        }
    }, [profileHook?.profileDetails])
    if (!isLoaded) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
            <div class="register">
        <main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div class="row justify-content-center">
        
        
          <div class="col-lg-6 col-md-6">
                <InfomationNavSideGuru text={text?.INFORMATION_MENU}/>
                </div>
                <div class="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <AddressInformationView
                    {...props}
                    {...addressInformationHook}
                    profileDetails={profileHook.profileDetails}
                />
                  </div>
            </div>
      </div>

    </section>

  </div>
</main>
</div>
            
        </>
    )
}

export default AddressInformation;
