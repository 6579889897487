import React from 'react';

const CloseIcon = props => {
    return (
        <svg width={props.width} height={props.height} fill="#FFD109" viewBox="0 0 14 14"  xmlns="http://www.w3.org/2000/svg">
            <path d="M0.635686 12.3024C0.342792 12.5952 0.342792 13.0701 0.635686 13.363C0.928579 13.6559 1.40345 13.6559 1.69635 13.363L0.635686 12.3024ZM7.52968 7.52968C7.82257 7.23679 7.82257 6.76191 7.52968 6.46902C7.23679 6.17613 6.76191 6.17613 6.46902 6.46902L7.52968 7.52968ZM6.46902 6.46902C6.17613 6.76191 6.17613 7.23679 6.46902 7.52968C6.76191 7.82257 7.23679 7.82257 7.52968 7.52968L6.46902 6.46902ZM13.363 1.69635C13.6559 1.40345 13.6559 0.928579 13.363 0.635686C13.0701 0.342792 12.5952 0.342792 12.3024 0.635686L13.363 1.69635ZM7.52968 6.46902C7.23679 6.17613 6.76191 6.17613 6.46902 6.46902C6.17613 6.76191 6.17613 7.23679 6.46902 7.52968L7.52968 6.46902ZM12.3024 13.363C12.5952 13.6559 13.0701 13.6559 13.363 13.363C13.6559 13.0701 13.6559 12.5952 13.363 12.3024L12.3024 13.363ZM6.46902 7.52968C6.76191 7.82257 7.23679 7.82257 7.52968 7.52968C7.82257 7.23679 7.82257 6.76191 7.52968 6.46902L6.46902 7.52968ZM1.69635 0.635686C1.40345 0.342792 0.928579 0.342792 0.635686 0.635686C0.342792 0.928579 0.342792 1.40345 0.635686 1.69635L1.69635 0.635686ZM1.69635 13.363L7.52968 7.52968L6.46902 6.46902L0.635686 12.3024L1.69635 13.363ZM7.52968 7.52968L13.363 1.69635L12.3024 0.635686L6.46902 6.46902L7.52968 7.52968ZM6.46902 7.52968L12.3024 13.363L13.363 12.3024L7.52968 6.46902L6.46902 7.52968ZM7.52968 6.46902L1.69635 0.635686L0.635686 1.69635L6.46902 7.52968L7.52968 6.46902Z" fill="currentColor"/>
        </svg>
        
    );
};

export default CloseIcon;

CloseIcon.defaultProps = {
    width: "14",
    height: "14"
}