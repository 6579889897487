import {useState} from "react";
import {useNavigate} from "react-router-dom";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {setLearnerProfileDetails} from "../../../../store/reducers/app.reducer";
import {toast} from "react-toastify";

const useTop10Videos = () => {
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [videosList, setVideosList] = useState(null)

    const getTop10Videos = (type) => {
        setLoading(true)
        post('userGetTop10Videos',{category_id: 0, type: type}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideosList(data)

            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    const getTrendingVideos = (type) => {
        setLoading(true)
        post('video_most_viewed',{category_id: 0, type: type}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideosList(data)

            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }


    return {
        loading,
        getTop10Videos,
        getTrendingVideos,
        videosList
    }

}
export default useTop10Videos


