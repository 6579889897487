import {useNavigate} from "react-router-dom";
import useHttpHandler from "../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {
    HOME_VIDEOS_ABOUT_REZKILAH_ID, HOME_VIDEOS_BANNER_ID, HOME_VIDEOS_FEATURED_VIDEOS_BY_OUR_GURUS_ID,
    HOME_VIDEOS_FINANCIAL_TOPICS_ID, HOME_VIDEOS_KEY_OPINION_LEADER_ID, HOME_VIDEOS_OUR_EXCLUSIVE_GURUS_ID,
    HOME_VIDEOS_WHAT_PEOPLE_SAY_ABOUT_REZKILAH_ID
} from "../../../constants/app.constants";

const useHome = () => {
    const [loading, setLoading] = useState(true)
    const [homeVideos, setHomeVideos] = useState(null)

    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const goToLearnerSignup = () => {
        navigate('/trainee/signup')
    }
    const goToGuruSignup = () => {
        navigate('/instructor/signup')
    }

    const getNonUserHomepageVideos = () => {
        setLoading(true)
        post('getHomePageDetails').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {

                setHomeVideos(data)

                let bannerVideos = []
                let freeCourses = data[0]['FreeCourses'];
                let paidCourses = data[0]['PaidCourses'];
                let Instructors = data[0]['Instructors'];
                

                setHomeVideos({
                    bannerVideos,
                    freeCourses,
                    paidCourses,
                    Instructors,
                    
                })


            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }
  
    useEffect(() => {
        getNonUserHomepageVideos()
    }, [])
  
    return {
        goToLearnerSignup,
        goToGuruSignup,
        loading,
        homeVideos
    }

}
export default useHome


