import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import {useSelector} from "react-redux";
import InstructorCard from '../../../../components/instructorCard';
import ChatMsgContainer from '../../../../components/chatMsgContainer';
import ChatTimerCard from '../../../../components/chatTimerCard';
import TrainerChatSizeBox from '../../../../components/TrainerChatSizeBox';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../../../components/modal';
import AffiliateProgram from '../../../../components/referenceLink/affiliateProgram';
import Flex from '../../../../components/flex';
import Button from '../../../../components/button';
import ChatConfirmInstructor from '../../../../components/chatConfirmationInstructor';
import ConfirmationModal from '../../../../components/chatConfirmationInstructor';



const TrainerChatContainerView = (props) => {
    
    const userInfo = useSelector(state => state.app.userInfo)
    const [isModal, setIsModal] = useState(false);
    const navigate = useNavigate();
    const {userId} = useParams();
   
    const closeModal = ()=>{
        setIsModal(false)
       
    }

    

    const popConfirm = () =>{
        props?.socket.emit("instructions",{type:"endConversation",roomName:props?.roomName,from:"trainee"});
        localStorage.setItem('currentRoomName',"");
        console.log("props--->",props)
        navigate('/trainee/chat-conversation-end/' + props?.sessionId);
        setIsModal(false)
     
        
    }

    const popCancel = () => {
        setIsModal(false)
    }
  

    console.log("instructorId",userId);

    return (
        <>
            
            <Text type="H5" text="Instructor Details" className=" mt-20"/>        
            <div className='row'>
            <div className='col-md-3'>
            <TrainerChatSizeBox props={props.getchatDashboardData} _props={props} instructorID={userId} setIsModal={setIsModal}/>
                        </div>    
                        <div className='col-md-9' >
            <ChatMsgContainer {...props} ishidden={0} />                      
            </div>
            </div>                
                    {isModal &&(
                     <Modal
                        onClose={closeModal}
                        className="modal-signup-right-area modal-secondary"
                        onOuterClose={closeModal}
                        modalContent={
                          
                        <ConfirmationModal text="are you sure want to end converstion?" continueTxt="yes" cancleText="No" popConfirm={popConfirm} popCancel={popCancel}/>

                        }
                    />)}
                    
       
        </>
    );
};


export default TrainerChatContainerView;

