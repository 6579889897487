import axios from "axios";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import useHelper from "../../hooks/helpers.hook";
import useHttpHandler from "../../hooks/httphandler.hook";
import Button from "../button";
import Icon from "../icon";
import ProgressBarCard from "../programBarCard";
import CameraIcon from "../svg/cameraIcon";
import TickIcon from "../svg/tickIcon";
import Text from "../text";
import Image from "../image";
import useVideosCommonHooks from "../../pages/instructor/createCourse/videosCommonHook";

const UploadVideoPreview = (props) => {
    const {getFileExtension, getFileSize} = useHelper()
    const apiUrl = process.env.REACT_APP_API_URL + '/videoUpload'
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [getNewImg, setNewImg] = useState();
    const commonHook = useVideosCommonHooks();

    const {setFieldValue} = props;
    const [errors, setErrors] = useState("");
    const {post} = useHttpHandler();
    const [imageUrl, setImageUrl] = useState("");


    useEffect(() => {
        
           
            commonHook
              ?.promiseServiceNonLoading({
                uri: "getImagePath",
                imagePath: getNewImg,
              })
              .then((data) => {
                console.log("props.uploadedImages", data.data);
                setImageUrl(data.data);
              });
        
      }, [getNewImg]);
    
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'video/*': ['.mp4', '.mpeg'],
        },
        onDrop: (acceptedFiles) => {
            setErrors('')
            console.log("props.fieldName",props.fieldName)
            setFieldValue(props.fieldName, acceptedFiles);
            props.setUploadedFilesInfo(null)
            acceptedFiles.forEach((file) => {

                setFiles(file);
                const data = new FormData();
                data.append('file', file);

                axios.post(apiUrl, data, {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        //"Content-Type": "multipart/form-data",
                        "Content-Type": "video/mp4",
                    },
                    onUploadProgress: (progressEvent) => {
                        props.setIsUploading(true)
                        const {loaded, total} = progressEvent;
                        let percentage = Math.floor((loaded * 100) / total);
                        if (percentage <= 100) {
                            setProgress(percentage)
                        }
                    }
                }).then((responseData) => {
                    //console.log('responseData', responseData)
                    let postData = {
                        filename: responseData.data.data[0].newfile_name,
                        file_size: responseData.data.data[0].size,
                        file_type: responseData.data.data[0].mimetype,
                        thumbnail: responseData.data.data[0].thumbnail,
                        duration: responseData.data.data[0].duration
                    }

                  


                    if(props?.istopicVideo){
                            post('videoUploadEntry', postData).then((res) => {
                                const status = res.data.status
                                const data = res.data.data
                                console.log(" res.data.data", res.data.data)
                                if (status.result == '200') {
                                    console.log("videoId",data[0].videoid );
                                    props.setVideoId(data[0].videoid + "");

                                
                                    
                                } else {
                                //   toast.error(status.msg)
                                }
                               //hell
                            }).catch(err => {
                                
                            })
                    }
                    props.setUploadedFilesInfo(responseData.data.data)
                    props.setIsUploading(false)
                    setNewImg(postData.thumbnail);
                    /*post('getImagePath', {'imagePath':postData.thumbnail}).then((res) => {
                        console.log("res.data",res.data)
                        setNewImg(res.data);
                    }).catch(err => {
                        
                    })
                        */
                  


                 
                }).catch((error) => {
                    console.log("ERROR " + error.message)
                });
            });
        }
    });

    const fileSize = files ? getFileSize(files?.size) : ''
    const fileFormat = files ? getFileExtension(files?.name) : ''

    return (
        <>
            <div className="thumbnail-main-wrapper">
                <div className="thumbnail-wrapper">
                    <div className="thumbnail-upload-file-wrp">
                    
                        <div className="upload-content" {...getRootProps({className: 'dropzone'})}>
                        
                        
                        
                                <div class="create_course_image_v">
                                {imageUrl !== "" && (
                //props?.uploadedImages?.map((file, i) => (
                <>
                   <img
                      src={imageUrl}
                      className="course-upload-wrap"
                      alt=""
                    />
                </>

                //))
              )}
                                           
                                        <input {...getInputProps()} />
                                        
                                       
                                </div>
                           
                        </div>
                    </div>
                </div>
                
            </div>
            {files && (
                    <div className="filesdetails">
                        
                        <ProgressBarCard width={progress}/>
                      {/*  <div className='row mt-20'>
                                                        <div className='col-3'>
                                                        <Icon
                                                            icon={<TickIcon width="24" height="24"/>}
                                                            success
                                                        />
                                                        </div>
                                                     

                                                        <div className='col-8' style={{marginTop:'5px'}} >
                                                        <Button
                                                            type="button"
                                                            text='Play Video'
                                                            
                                                            
                                                        />
            
                                                        </div>


            </div> */}
                    </div>
                )}
        </>
    );
}

export default UploadVideoPreview;