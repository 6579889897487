import React from "react";
import Icon from "../icon";
import Image from "../image";
import VideoPlayerTwoIcon from "../svg/videoPlayerTwoIcon";
import UserTwoIcon from "../svg/userTwoIcon";
import Text from "../text";
import './topicInterestSecondary.scss';

const TopicInterestSecondary = props => {
    return(
        <>
            <div className="tis-card pointer" {...props} onClick={props?.onClick}>
                <Image src={"ct/" + props.src} width={props.imgWidth} height={props.imgHeight} />
                <div className="tis-content">
                    {props.icon && <Icon icon={props.icon} />}
                    <Text type="H4" text={props.title} className="title" />
                    <div className="tis-list-item">
                        <div className="tis-list-item-icon">
                            <VideoPlayerTwoIcon />
                        </div>
                        <Text type="SPAN" text={`${props.totalVideos} Courses`} className="tis-list-item-text" />
                    </div>
                    <div className="tis-list-item">
                        <div className="tis-list-item-icon">
                            <UserTwoIcon />
                        </div>
                        <Text type="SPAN" text={`${props.totalGuru} Instructors`} className="tis-list-item-text" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopicInterestSecondary;
