import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useState} from "react";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useSelector} from "react-redux";

const useGuruUploadVideo = () => {
    var incNum = 0;
    const [uploadedImages, setUploadedImages] = useState(null)
    const [pdfuploadedImages, pdfsetUploadedImages] = useState(null)
    const [uploadedVideosInfo, setUploadedVideosInfo] = useState(null)
    const [getFilterTopic, setFilterTopic] = useState(null)
    const [getVideoId,setVideoId] = useState("0");
    const userInfo = useSelector(state => state.app.userInfo)
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [videoDetails, setVideoDetails] = useState(null)
    const [dropdownsData, setDropdownsData] = useState(null)
    const {videoId} = useParams();
    const [serviceData, setCustomData] = useState(null)
    const [checkLevel, setLevel] = useState(true);
    const [getCheck, setCheck] = useState(true);
    const [isUploading, setIsUploading] = useState(false)
    var videoSampleFile = []
    
    const navigate = useNavigate();
    const goToUploadSuccess = (video) => {
        if(video.videoid) {
            navigate('/instructor/video-details/' + video.videoid);
        }
    }
    const goToGuruVideoGuideline = () => {
        navigate('/instructor/video-guideline')
    }
    const getVideoDetailsById = async () => {
        setLoading(true)
        await post('get_video_full_details', {'video_id': videoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideoDetails(data)
                setUploadedImages(data[0]?.tumbnails)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }



    const uploadVideo = (postData)=> {
        post('videoUploadEntry', postData).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                return data
            } else {
                toast.error(status.msg)
            }
        }).catch(err => {})
    }


    const getDropDowns = async () => {
        setLoading(true)
        post('getCommonDropdownData').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let studyLevelOptions = []
                let categoriesOptions = []
                let categories = []
                let subCategories = data?.subCategories;
                let subCategoriesOptions = [];
                let moduleOptions = data?.moduleLevel;
                let mettingTypes = data?.mettingtypes;

                data?.categories?.map((item, index)=>{
                    categories.push(item);
                    categoriesOptions.push({value:item.catId, label: item.catName,text: item.catName})
                })

                data?.subCategories.map((item, index)=>{
                     subCategoriesOptions.push({value:item.subcatId, label: item.subCatName,text:item.subCatName})
                    
                })

                

                



                setDropdownsData({
                    subCategories,
                    categoriesOptions,
                    subCategoriesOptions,
                    categories,
                    moduleOptions,
                    mettingTypes
                })
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }



    const getCategories = (params)=> {
        setLoading(true)
        post('commonGetCategories',params).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let categoriesOptions = []
                let categories = []
                data?.listData?.map((item, index)=>{
                    categories.push(item);
                    categoriesOptions.push({value:item.catId, label: item.catName})
                    
                })

                setDropdownsData({
                    categoriesOptions,
                    categories
                })

            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }


    const getCategoriesDetails = (params)=> {
        setLoading(true)
        post('fetchCategoryDetails',params).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let categoriesOptions = []
                let categories = []
                data?.listData?.map((item, index)=>{
                    categories.push(item);
                    categoriesOptions.push({value:item.catId, label: item.catName})
                    
                })


                setDropdownsData({
                    categoriesOptions,
                    categories
                })
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }


    
    const getSubCategories = (params)=> {
        setLoading(true)
        post('fetchSubCategory',params).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
              
                let subCategories = data?.listData
                let customData = data?.customdata[0];
                setDropdownsData({
                    subCategories,
                    customData
                })
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }

    const getCustomData = (keys) => {
        setLoading(true)
        post(keys?.uri, keys).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setCustomData(data)
                setTimeout(()=>{
                    setLoading(false)
                },500)
            }
        }).catch(() => setLoading(false))
    }


    return {
        goToUploadSuccess,
        goToGuruVideoGuideline,
        getVideoDetailsById,
        loading,
        videoDetails,
        getDropDowns,
        dropdownsData,
        userInfo,
        uploadedImages,
        setUploadedImages,
        pdfuploadedImages,
        pdfsetUploadedImages,
        uploadedVideosInfo,
        setUploadedVideosInfo,
        getVideoId,
        setVideoId,
        getFilterTopic,
        setFilterTopic,
        getCategories,
        getSubCategories,
        uploadVideo,
        isUploading,
        setIsUploading,
        videoSampleFile,
        getCustomData,
        serviceData,
        checkLevel,
        setLevel,
        getCheck, 
        setCheck,


    }

}
export default useGuruUploadVideo


