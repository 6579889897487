import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import useVideosCommonHooks from '../../instructor/createCourse/videosCommonHook';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const userInfo = useSelector(state => state.app.userInfo)
  console.log("userInfo.email",userInfo.email,userInfo)
  const [email, setEmail] = useState(userInfo.email);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const commonHook =  useVideosCommonHooks();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { email },
    });

    if (error) {
      console.error(error);
      setIsProcessing(false);
      return;
    }

    // Call the backend to create a subscription
    const response = await fetch(process.env.REACT_APP_API_URL + '/create-subscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        paymentMethodId: paymentMethod.id,
       // priceId: 'price_1QCP4qDLYMXimwFTt2xFxoXo', // Replace with your Stripe Price ID
        paymentTypeId:props.paymentTypeId 
      }),
    });

    const subscriptionResponse = await response.json();
    const { clientSecret: subscriptionClientSecret } = subscriptionResponse;

    setClientSecret(subscriptionClientSecret);


    

    const confirmResult = await stripe.confirmCardPayment(subscriptionClientSecret);
    if (confirmResult.error) {
      console.error(confirmResult.error);
    } else {
      if (confirmResult.paymentIntent.status === 'succeeded') {
        console.log('Subscription successful!');
        
        commonHook?.promiseServiceNonLoading({"uri":"paymentCategorySuccess","paymentIntent":confirmResult.paymentIntent,catId:props.paymentTypeId}).then((data) => {
          //console.log("data.data",data.data)
          console.log("data.data",data.data)
        
          if(data.data.status.result == 200){
            navigate('/trainee/payment-success');
          }else{
            navigate('/trainee/payment-failed');
          }
         
      });
      }
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="hidden"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <CardElement />
      <button type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? 'Processing...' : 'Subscribe'}
      </button>
    </form>
  );
};

export default CheckoutForm;