import React from 'react';

const FacebookIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6632 16.6938L15.4459 11.5179H10.6242V8.15357C10.6242 6.73828 11.2974 5.35534 13.4499 5.35534H15.6729V0.947732C14.3784 0.732884 13.0703 0.616652 11.7592 0.599976C7.79069 0.599976 5.19981 3.08279 5.19981 7.57128V11.5179H0.800781V16.6938H5.19981V29.213H10.6242V16.6938H14.6632Z" fill="#337FFF"/>
        </svg>
    );
};

export default FacebookIcon;

FacebookIcon.defaultProps = {
    width: '16',
    height: '30'
}