import { Form, InputGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import '../tableFormat.css';

const TableFormatPageView=()=>{
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-12 mb-4'>
                <h3 className='mb-3'>Coach-course creator</h3>
                <h3>Student-To Learn and Share</h3>
            </div>
       <div className='col-md-12 bg-table'>
    <Table bordered hover className='table'>
      <thead>
        <tr className='bg-th'>
          <th colspan="10">Keyword that you Provided</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><Form.Check type="checkbox" className='checkbox' label="coach" /></td>
          <td>165,000</td>
          <td>+49%</td>
          <td>+49%</td>
          <td>High</td>
          <td>-</td>
          <td>£0.27</td>
          <td>£3.78</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td><Form.Check type="checkbox" className='checkbox' label="instructor" /></td>
          <td>2,400</td>
          <td>-33%</td>
          <td>-16%</td>
          <td>Low</td>
          <td>-</td>
          <td>£0.27</td>
          <td>£3.78</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td><Form.Check type="checkbox" className='checkbox' label="course creator" /></td>
          <td>260</td>
          <td>-19%</td>
          <td>+53%</td>
          <td>Medium</td>
          <td>-</td>
          <td>£0.27</td>
          <td>£3.78</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td><Form.Check type="checkbox"  className='checkbox' label="guru" /></td>
          <td>14,800</td>
          <td>-18%</td>
          <td>0%</td>
          <td>Low</td>
          <td>-</td>
          <td>£0.27</td>
          <td>£3.78</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </Table>
    </div>
    </div>
    </div>
  );
}

export default TableFormatPageView;