import SignupLayout from "../../../components/signuplayout";
import useRegister from "./hooks/register.hook";

import RegisterView from "./view/RegisterView";
import Modal from "../../../components/modal";
import React from "react";
import {useLocation} from "react-router-dom";
import SocialRegisterView from "./view/SocialRegisterView";

const Register = props => {
    const {pathname} = useLocation()
    const isSocialSignupFlow = pathname.includes('socialsignup')
    const registerHook = useRegister();

    if (isSocialSignupFlow) {
        return (
            <SignupLayout {...props}>
            <Modal
                className="modal-signup-right-area"
                onOuterClose={false}
                noscroll
                modalContent={<SocialRegisterView {...props} {...registerHook} />}
            />
            </SignupLayout>
        )
    }
    return (
        <>
            
                <RegisterView {...props} {...registerHook}/>
            
        </>
    )
}

export default Register;
