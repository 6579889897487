import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';



// Your web app's Firebase configuration
/*
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID,
  vapidKey:process.env.REACT_APP_FIREBASE_VAPIDKEY,
};
*/

const firebaseConfig = {

  apiKey: "AIzaSyA9tb0_CF5iIQsTaPLIVGVMISP7m8m3lR0",

  authDomain: "coursetsunami.firebaseapp.com",

  projectId: "coursetsunami",

  storageBucket: "coursetsunami.appspot.com",

  messagingSenderId: "1096795272666",

  appId: "1:1096795272666:web:e4c19d8536f77fdf1cbce3",

  measurementId: "G-8YFLMSMLPP",
  vapidKey: '8A7DGXSAi4zoKBHS2vGxlc-i-zatCVDJXacvRGMCO84',
};



// Initialize Firebase
export const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const notificationMessaging = getMessaging(app);

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. token', payload);
  // ...
});

export const getTokenFMC = () =>{

  if (Notification.permission === 'granted') {

 const messaging = getMessaging();


  getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
  if (currentToken) {
    console.log("currentToken",currentToken)
    // Send the token to your server and update the UI if necessary
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});


  } else {
    // Request permission
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // Permission granted, now call getToken()
        getToken(notificationMessaging, {vapidKey: "8A7DGXSAi4zoKBHS2vGxlc-i-zatCVDJXacvRGMCO84"}).then((token) => {
          console.log('Token:', token);
        });;
      } else {
        console.log('Notification permission denied');
      }
    });
  }

}



export const gettingMsg =()=>{

  

  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
  });
} 



export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY, serviceWorkerRegistration }));

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));


  

//export const app = ''
//export const auth = ''



