import React, {useEffect} from "react";
import LayoutGuru from '../../../../components/layoutGuru';
import BankDetailsView from "./view/BankDetailsView";
import useBankDetails from "./hooks/bankDetails.hook";
import Loader from "../../../../components/loader";

const BankDetails = props => {
    const {text} = props
    const bankDetailsHook = useBankDetails()
    useEffect(() => {
        bankDetailsHook.getBankDetails()
    }, [])
    if (bankDetailsHook.loading) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }
    return (
        <>
            <LayoutGuru {...props}>
                <BankDetailsView {...props} {...bankDetailsHook} />
            </LayoutGuru>
        </>
    )
}

export default BankDetails;
