import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";


const MembersDashboardView = (props) => {
    
   
    const {getYearOptions,post} = useHelper()
   
    const navigate = useNavigate()
   

   

  


    return (
        <>
            
            <div className="row top-main-boxes course-main-box"  >

                  <div className="row all-main-box"  style={ {marginLeft: '0px',padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                      
                         

                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="My Reports"
                                 
                                  onClick={() => {
                                    navigate('/trainee/members/my-report')
                                  }}
                                  {...props}
                              />
                          </div>


                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="My Subscription"
                                  onClick={() => {
                                      navigate('/trainee/members/my-subscription')
                                  }}
                                  {...props}
                              />
                          </div>


                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="My Network"
                                  onClick={() => {
                                      navigate('/trainee/members/my-network')
                                  }}
                                  {...props}
                              />
                          </div>
                        

                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="My Commission"
                                  
                                  onClick={() => {
                                      navigate('/trainee/members/my-commission')
                                  }}
                                  {...props}
                              />
                          </div>


                     




                   


                   

                 
                      </div>            
                      </div>    
                    

                      
   
         
  
  
             
            {/*props.titlesObj.showDashboard == 1 &&(
                  
            )

            */}
          


       
           

                                 


                    
       
        </>
    );
};


export default MembersDashboardView;

const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}

const DashGuruStatusTitle = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text={props.buttonTitle}  onClick={props.onClick}/>
        </div>
    )
}