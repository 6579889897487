import React from 'react';
import Text from '../../../../components/text'
import Button from '../../../../components/button'
import Flex from "../../../../components/flex";

const ReferenceLinkView = (props) => {
    const {text}=props
    return (
        <div className="reference-wrapper">
            <div className="reference-main">
                <Text
                    type="H2"
                    //text={`Help ${props?.userName} receive his REZKILAH affiliate income`}
                    text={text?.HELP_RECEIVE_HIS_REZKILAH_AFFILIATE_INCOME.replace("[DYNAMIC_USER_NAME]", props?.userName)}
                />
                {/*<Text
                    type="H1"
                    text="RM 20.80"
                /> */}
                <Text
                    type="H3"
                    text={text?.BY_REGISTERING_AT_REZKILAH}
                    className="font-weight-400"
                />
                <Text
                    type="PARAGRAPH"
                    text={text?.YOU_HAVE_OPPURTUNITY_TO_EARN_AFFILIATE_INCOME_AND_OTHER_COMMISSION}
                    className="col-11 mx-auto mt-48 mb-48"
                />
                <Flex justifyContent='center'>
                <Button
                    large
                    text={text?.SIGN_UP_NOW}
                    onClick={props.onSignupNow}
                />
                </Flex>
            </div>
        </div>
    );
};

export default ReferenceLinkView;

