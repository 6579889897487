import React, { useEffect, useState } from "react";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import Flex from "../../../../components/flex";
import Image from "../../../../components/image";
import useHelper from "../../../../hooks/helpers.hook";
import { useNavigate } from "react-router-dom";
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import Icon from "../../../../components/icon";
import PlayIcon from "../../../../components/svg/playIcon";
import CourseMainBar from "../../../../components/courseMainbar";
import VideoPlayer from "../../../../components/videoplayer";
import ShareVia from "../../../../components/shareVia";
import StaticVideoPlayer from "../../../../components/videoPlayers/staticVideoPlayer";
import TextHtmlParser from "../../../../components/htmlparser";
import Modal from "../../../../components/modal";
import rightIcon from "../../../../assets/img/rightIcon.png";
import { DEFAULT_FILTERS, URL } from "../../../../constants/app.constants";
import ChatSwitchButton from "../../../../components/ChatswitchButton";
import listImg from "../../../../assets/img/listImage.png";
import coloumImg from "../../../../assets/img/columnImage.png";
import thumbIcon from '../../../../assets/img/thumbIcon.png'
import eyeIcon from '../../../../assets/img/eyeIcon.png'
import moneyIcon from '../../../../assets/img/moneyIcon.png'
import peopleIcon from '../../../../assets/img/peopleIcon.png'
import InstructorCourseCard from "../../../../components/instructorCourseCard";
import { useMediaQuery } from "react-responsive";
import useVideosCommonHooks from "../../createCourse/videosCommonHook";
import Chart from "react-google-charts";
import HomeDashboardBoxes from "../../../../components/dashboardBoxes/homeDashboard";

const InstructorDashboardView = (props) => {

  const { goToLearnerSignup } = props;


  const { dashboardDetails } = props?.guruDashboardHook;
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.app.userInfo);
  const [isModal, setIsModal] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [showVideoEnded, setShowVideoEnded] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);
  const [displayType, setDisplayType] = useState("list");

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  console.log(
    "props.guruDashboardHook?.dashboardDetails?.onlineStatus",
    props.guruDashboardHook?.dashboardDetails?.onlineStatus
  );
  const [isVideoModal, setIsVideoModal] = useState(false);
  const commonHook = useVideosCommonHooks();
    
  const closeVideo = () => {
    setIsVideoModal(false);
  };

  const closeShare = () => {
    setIsShareModal(false);
  };
  const [vidoePlayUrl, setVidoePlayUrl] = useState("");

  //const recentUploadImage = dashboardDetails?.recently_upload?.thumbnail ? process.env.REACT_APP_IMG_URL + dashboardDetails?.recently_upload?.thumbnail : RecentImage
  //console.log("dashboardDetails",dashboardDetails)
  const playVideo = (val) => {
    console.log("val",val)
    commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath': val}).then((data) => {
      setVidoePlayUrl(data.data);
      setIsVideoModal(true);
    });
    
  };

  useEffect(() => {
    if (isMobile === true) {
      setDisplayType("coloum");
    }
  }, []);

  const [selectedCourseId, setSelectedCourseId] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const deleteCourse = (data) => {
    setSelectedCourseId(data.courseid);
    setIsDelete(true);
  };


  const data = [
    ["Task", "Hours per Day"],
    ["Videos", dashboardDetails?.top_total_income],
    ["Chat : ",  dashboardDetails?.earnedOnChat],
    ["Meetings",  dashboardDetails?.earnedOnMeetings],
    
  ];

  const options = {
    title: "Generated Revenue  ",
  };

  const onlineStatusChange = (statusbit) => {
    if (statusbit) {
      props.commonHook
        .promiseServiceNonLoading({
          uri: "onlineStatusChange",
          mongoId: dashboardDetails?.mongoId,
          status: 0,
        })
        .then((data) => {});
    } else {
      props.commonHook
        .promiseServiceNonLoading({
          uri: "onlineStatusChange",
          mongoId: dashboardDetails?.mongoId,
          status: 1,
        })
        .then((data) => {});
    }

    console.log(statusbit);
  };

  return (
    <>
      <div className="row m-lg-0 top-main-boxes">
        <div className="col-md-8  ">
          <Text
            className="m-0"
            type="H5"
            text={
              "Hi " + userInfo.uname + "  WELCOME TO YOUR INSTRUCTOR DASHBOARD"
            }
          />
        </div>
        <div className="col-md-2 col-sm-6 live-chat py-3">
          {/* <Image src={liveChatImg}  className="pull-left" width="20" />   */}
          LIVE CHAT
        </div>

        <div className="col-md-2 col-sm-6 onlineStatus text-right pt-2">
          <ChatSwitchButton
            status={dashboardDetails?.onlineStatus}
            courseStatusChange={onlineStatusChange}
            onChange={() => {}}
          />
        </div>
      </div>

      <div className="row mr-lg-0 pt-4 align-items-stretch justify-content-between ">
        <div className="col-md-6  count-row pl-lg-0">
          <div className="row all-main-box">
            <div className="col-12 col-md-6">

            <HomeDashboardBoxes
                className="dash-guru-status-card"
                icon={0}
                img={thumbIcon}
                title="Total Videos Likes"
                number={dashboardDetails?.top_total_likes}
                onClick={() => {
                  navigate("/instructor/dashboard");
                }}
                {...props}
              />

            
            </div>
            <div className="col-12 col-md-6">
            <HomeDashboardBoxes
                className="dash-guru-status-card"
                icon={0}
                img={eyeIcon}
                title="Total Videos Views"
                number={dashboardDetails?.top_total_views}
                onClick={() => {
                  navigate("/instructor/dashboard");
                }}
                {...props}
              />

            </div>
          </div>
          <div className="row all-main-box mt-2">
            <div className="col-12 col-md-6 ml-lg-0">
            <HomeDashboardBoxes
                className="dash-guru-status-card"
                icon={0}
                img={moneyIcon}
                title="Total Commission"
                number={dashboardDetails?.top_total_income}
                onClick={() => {
                  navigate("/instructor/dashboard");
                }}
                {...props}
              />

            </div>
            <div className="col-12 col-md-6">
            <HomeDashboardBoxes
                className="dash-guru-status-card"
                icon={1}
                img={peopleIcon}
                title="Total Active Subscribers"
                number={dashboardDetails?.activeUsers}
                onClick={() => {
                  navigate("/instructor/dashboard");
                }}
                {...props}
              />

            </div>
          </div>
        </div>
        <div className="col-md-3 pl-lg-0">
          <div className="row h-100 all-main-box">
            <div className="col-12 col-md-12 ">
              <div className="dash-guru-status-card h-100 text-color-dark">
              <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"250px"}
    />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mr-lg-0 video-poster-bg align-items-center ">
          <div className="p-2">
            <div className="video-poster ">


            <Image src={"ct/" + dashboardDetails?.videoThumbnail} alt={props.alt} className='img-fluid' />      

              
              <div className="dashboard-video-play">
                <Button
                  link
                  text={<Icon icon={<PlayIcon />} />}
                  onClick={() => {
                    playVideo(dashboardDetails?.video);
                  }}
                  type="button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row all-main-box mx-lg-0 shadow bg-body rounded  p-2 text-right mt-3">
        <div className="col-md-6">
          <Text type="H5" text="Your Courses" className="text-left pt-2 m-0" />
        </div>
        {!isMobile && (
          <div className="col-md-6">
            <Button
              link
              text={
                <img src={listImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                setDisplayType("list");
              }}
              type="button"
            />

            <Button
              link
              text={
                <img src={coloumImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                setDisplayType("coloum");
              }}
              type="button"
            />
          </div>
        )}
      </div>

      {displayType === "list" && (
        <>
       
            {dashboardDetails?.recently_upload?.map((item, index) => {
              return (
                <div className="row py-3 mt-4 w-100 mx-lg-0 shadow bg-body rounded">
                <CourseMainBar
                  index={index}
                  playVideo={playVideo}
                  data={item}
                  setSelectedCourseId={setSelectedCourseId}
                  deleteCourse={deleteCourse}
                  showButtons={true}
                  setIsShareModal={setIsShareModal}
                />
                  </div>
              );
            })}
        
        </>
      )}
      {displayType === "coloum" && (
        <>
          <div className="row mt-1 gy-4">
            {dashboardDetails?.recently_upload?.map((item, index) => {
              return (
                <>
                  <div className="col-12 col-md-4">
                    <InstructorCourseCard
                      props={props}
                      data={item}
                      index={index}
                      playVideo={playVideo}
                      onClick={() => {
                       // navigate("/trainee/course-details/" + item.courseid);
                      }}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}

      {isModal && (
        <>
          <Modal
            className="modal-signup-right-area"
            onOuterClose={setIsShareModal(false)}
            modalContent={
              <>
                <div className="homevideomodal-wrapper">
                  <div className="homevideomodal-main">
                    <StaticVideoPlayer
                      videoDetails={videoDetails}
                      showVideoEnded={showVideoEnded}
                      goToLearnerSignup={goToLearnerSignup}
                      videoEnded={setShowVideoEnded}
                    />
                  </div>
                  <div className="homevideomodal-content-bottom">
                    <Text type="H2" className="" text={videoDetails?.title} />

                    <TextHtmlParser
                      className="mb-0 text-white-half font-weight-400"
                      text={videoDetails?.des}
                    />
                  </div>
                </div>
              </>
            }
          />
        </>
      )}

      {isShareModal && (
        <>
          <Modal
            className="modal-white"
            canClose={closeShare}
            onClose={closeShare}
            modalContent={
              <ShareVia
                {...props}
                affiliateLink={
                  URL +
                  "/course/" +
                  selectedCourseId +
                  "-0-" +
                  userInfo?.myrefcode
                }
              />
            }
          />
        </>
      )}

      {isVideoModal && (
        <>
          <Modal
            className=""
            canClose="onClose"
            onClose={closeVideo}
            onOuterClose={closeVideo}
            modalContent={
              <VideoPlayer videourl={vidoePlayUrl} width="100%" height="460" />
            }
          />
        </>
      )}

      {isDelete && (
        <>
          <Modal
            className="modal-30-per"
            modalContent={
              <>
                <div className="row pop-up-header ">
                  <div class="col-md-12 div-center">
                    <img src={rightIcon} class="icon-50" alt="" />
                    <div className="col-12 col-md-3">
                      <div className="">
                        <div className="video-poster mt-2">
                          <img
                            src={
                              process.env.REACT_APP_IMG_URL +
                              dashboardDetails?.videoThumbnail
                            }
                            alt={props.alt}
                            className="dashboard_video_card"
                          />
                          <div className="dashboard-video-play">
                            <Button
                              link
                              text={<Icon icon={<PlayIcon />} />}
                              onClick={() => {
                                playVideo(dashboardDetails?.video);
                              }}
                              type="button"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row popup-padding">
                  <Text
                    type="H5"
                    text="Are you sure want to delete this course"
                    className="mb-10 mt-10 text-center"
                  />

                  <Flex justifyContent="center" className="mb-48 mt-40">
                    <Button
                      type="primary"
                      key="console"
                      text="Yes"
                      onClick={() => {
                        props.guruDashboardHook.instrutorDeleteCourse({
                          uri: "instrutorDeleteCourse",
                          courseId: selectedCourseId,
                        });
                      }}
                    />
                    <Button
                      type="primary"
                      key="console"
                      text="No"
                      onClick={() => {
                        setIsDelete(false);
                      }}
                    />
                  </Flex>
                </div>
              </>
            }
          />
        </>
      )}

      {props.guruDashboardHook.deletedCourse && (
        <>
          <Modal
            className="modal-30-per"
            modalContent={
              <>
                <div className="row pop-up-header ">
                  <div class="col-md-12 div-center">
                    <img src={rightIcon} class="icon-50" alt="" />
                  </div>
                </div>
                <div className="row popup-padding">
                  <Text
                    type="H5"
                    text="Sucessfully deleted course"
                    className="mb-10 mt-10 text-center"
                  />

                  <Flex justifyContent="center" className="mb-48 mt-40">
                    <Button
                      type="primary"
                      key="console"
                      text="Done"
                      onClick={() => {
                        setIsDelete(false);
                        props.guruDashboardHook.setDeletedCourse(false);
                        DEFAULT_FILTERS.uri = "fetchInstructorDashboard";
                        DEFAULT_FILTERS.userid = "g";
                        DEFAULT_FILTERS.category_id = props.getCategoryId;
                        DEFAULT_FILTERS.subcategory_id = props.getSubCategoryId;
                        DEFAULT_FILTERS.coursetype = 1;
                        props.getList(DEFAULT_FILTERS);
                      }}
                    />
                  </Flex>
                </div>
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default InstructorDashboardView;

const DashGuruStatus = (props) => {
  const { text } = props;
  return (
    <div className="dash-guru-status-card">
      <div class="feature-box-icon">
        <BsFillHandThumbsUpFill className="fa-solid dash-icon-c" />
      </div>
      <div className="">
        <Text type="p" text={props.title} className="dgsc-title mb-2" />
        {/* <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/> */}
        <Text type="H2" text={props.number} className="dgsc-number" />
      </div>

      {/* <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/> */}
    </div>
  );
};
