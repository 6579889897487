import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import Button from '../../../../components/button';
import Text from "../../../../components/text";
import Flex from '../../../../components/flex';
import {useDropzone} from "react-dropzone";
import Icon from '../../../../components/icon';
import UploadIcon from '../../../../components/svg/uploadIcon'
import useGuruUploadVideo from '../hooks/guruUploadVideo.hook';
import useUploadPhoto from "../../../../hooks/upload.hook";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import axios from "axios";
import {setLearnerProfileDetails} from "../../../../store/reducers/app.reducer";
import ProgressBarCard from "../../../../components/programBarCard";

const GuruUploadVideoView = (props) => {
    const {post} = useHttpHandler()
    const {goToUploadSuccess, goToGuruVideoGuideline} = useGuruUploadVideo();
    const [progress, setProgress] = useState(null);
    const formik = useFormik({
        initialValues: {
            sampleFile: [],
        },
        validationSchema: Yup.object({
            sampleFile: Yup.mixed().required('Please select videos'),
        }),


        onSubmit: (values, actions) => {

            console.log('sampleFile', values.sampleFile)
            actions.setSubmitting(true)

            let formData = new FormData();
            values?.sampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/videoUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    let percentage = Math.floor((loaded * 100) / total);
                    if (percentage <= 100) {
                        setProgress(percentage)
                    }
                }
            }).then((responseData) => {
                console.log('responseData', responseData)
                const response = responseData.data.data[0]
                let postData = {
                    filename: response.newfile_name,
                    file_size: response.size,
                    file_type: response.mimetype,
                    duration: Math.round(Math.ceil(response.duration))
                }

                post('videoUploadEntry', postData).then((res) => {
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        console.log('data', data)
                        goToUploadSuccess(data[0])
                    } else {
                        toast.error(status.msg)
                    }
                    actions.setSubmitting(false)
                }).catch(err => {
                    actions.setSubmitting(false)
                })


            }).catch((error) => {
                actions.setSubmitting(false)
                console.log("ERROR " + error.message)
            });

        },
    });
    return (
        <>
            <Text
                type="H1"
                text="Upload video"
                className="text-center"
            />
            <Text
                type="PARAGRAPH"
                text="Upload your videos here, can drop or browse the file."
                className="text-center text-white-half mb-10"
            />
            <Text
                type="PARAGRAPH"
                className="text-center text-white-half mb-30"
                text={
                    <>
                        View Video Guideline
                        <Button
                            type="button"
                            linkSecondary
                            style={{fontSize: 'inherit', color: 'inherit', fontWeight: 'inherit', marginLeft: '5px'}}
                            text="here."
                            onClick={goToGuruVideoGuideline}
                        />
                    </>
                }
            />
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <div className="formBox">
                    <div className="form-field">
                        <UploadComponent setFieldValue={formik.setFieldValue}/>
                        {formik.touched.sampleFile && formik.errors.sampleFile ?
                            (
                                <div className="message-error"><small>{formik.errors.sampleFile}</small></div>
                            ) : null
                        }
                    </div>

                    <Text
                        type="H4"
                        className="font-weight-500 mb-24"
                        text={`Uploaded Files (${formik?.values?.sampleFile?.length})`}
                    />
                    {formik?.values?.sampleFile &&
                    formik?.values?.sampleFile?.map((file, i) => (
                        <div key={i}>
                            {`File: ${file.name}`}{" "}
                        </div>
                    ))
                    }
                    {progress && (
                        <ProgressBarCard width={progress}/>
                    )}
                    <Text
                        type="H3"
                        className="text-center col-md-9 mx-auto font-weight-400 text-white-half mt-40 mb-40"
                        text={
                            <>
                                By submitting your videos to COURSETSUNAMI, you acknowledge that you agree to
                                COURSETSUNAMI <Button type="button" linkSecondary text="Terms of Service." style={{
                                fontSize: 'inherit',
                                color: 'inherit',
                                fontWeight: 'inherit'
                            }}/>
                            </>}
                    />


                </div>
                <Flex justifyContent="center" className="mb-48 mt-48">
                    <Button
                        type="submit"
                        text="Submit"
                        large

                        disabled={formik.isSubmitting}
                        isSubmitting={formik.isSubmitting}
                    />
                </Flex>
            </form>
        </>
    );
};

export default GuruUploadVideoView;

const UploadComponent = props => {
    const [errors, setErrors] = useState("");
    const {setFieldValue} = props;
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'video/*': ['.mp4', '.mpeg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles, fileRejections) => {
            setErrors('')
            setFieldValue("sampleFile", acceptedFiles);
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "file-too-large") {
                        setErrors(`Error: ${err.message}`);
                    }
                    if (err.code === "file-invalid-type") {
                        setErrors(`Error: ${err.message}`);
                    }
                    if (err.code === "too-many-files") {
                        setErrors(`Error: ${err.message} please select maximum of 1`);
                    }
                });
            });
        },
    });
    return (
        <div className="mupload-wrapper mupload-secondary-wrapper">
            <div className="mupload-main-wrapper">
                {}
                <div {...getRootProps({className: "dropzone"})}>
                    <input {...getInputProps()} />
                    <div className="d-flex flex-column align-items-center">
                        <Icon
                            extra
                            light
                            icon={<UploadIcon width="42" height="42"/>}
                        />
                        <Text type="H4" text="Drag and Drop here" className="mt-20"/>
                        <Text type="PARAGRAPH" text="or"/>
                        <div className="btn btn-primary">+ SELECT FILEs</div>
                    </div>
                </div>
            </div>
            <Flex justifyContent="between">
                <Text type="PARAGRAPH" text="Format MP3, MP4,MPEG" className="mb-0"/>
                <Text type="PARAGRAPH" text="Secure" className="mb-0"/>
            </Flex>
            <Text type="PARAGRAPH" className="text-danger mt-10" text={errors}/>
        </div>
    );
};
