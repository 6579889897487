

import React, { useEffect, useState } from "react";
import './instructorCard.scss';
import useHelper from "../../hooks/helpers.hook";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../button";
import ChatTimerCard from "../chatTimerCard";

const TrainerChatSizeBox = props => {
    const navigate = useNavigate();
    const [status,setStatus] = useState(0);
    const [conversationCharge,setConversationCharge] = useState(0);
    const [walletBalance,setWalletBalance] = useState(0);
    const timerCallBack = () =>{
      props?._props.promiseServiceNonLoading({"uri":"chatTimerUpdate","sessionId":props?._props?.sessionId,"chatRoom":props?._props?.roomName,"instructorId":props.instructorID}).then((data) => {
            //console.log("data.data.status",data.data.status.DeductionAmount);
            if(data.data.status.balance <= 0){
              props?._props?.socket.emit("instructions",{type:"endConversation",roomName:props?._props?.roomName,from:"trainee"});

              navigate('/trainee/low-balance');
            }
            setWalletBalance(data.data.status.balance);
            setConversationCharge(data.data.status.DeductionAmount);
            if(data.data.status.balance <= props?.props?.chatCharges){

            }
      });
    } 

    useEffect(()=>{

        
        for(var i=0;i<props?._props?.onlineStatus?.length;i++){

            if(props?._props?.onlineStatus[i]?._id == props?.props?.mongoId ){
                setStatus(props?._props?.onlineStatus[i]?.onlineStatus);
            }

        }

     },[props?._props?.onlineStatus])

     useEffect(()=>{

      timerCallBack();
     
     },[])

     useEffect(() => {
      const interval = setInterval(() => {
        
      }, 10000);

    return () => clearInterval(interval);
    }, [props.mongoIds]);

     
    

    const {kFormatter,getShortDescription} = useHelper()
    return(
        <>
        
        <div class="member mb-0">
          <div className="instru-img">
          <img src={process.env.REACT_APP_IMG_URL + props?.props?.pic} class="img-fluid mt-0" alt="" />
          </div>
              
              <div class="member-content">
                <h4>{props?.props?.preffered_name} {props?.props?.uname}</h4>
                {status == 1 &&
                      <span class="online_dot" > <span className="online_text"> Online </span></span>
                }
                {status == 0 &&
                    <span class="ofline_dot" > <span className="online_text"> Offline </span></span>
                }
                
                <span>{props?.props?.catName}</span>
                <p>
                  {props?.props?.about_yourself}
                </p>

                <label className="form-label mb-10">Per Minute : ${props?.props?.chatCharges} </label>
                <br></br>

                <label className="form-label mb-10">Your Conversation Charge Till Now : {conversationCharge}</label>
                <br></br>
                
              
              <label className="form-label mb-10">Wallet Balance : {walletBalance}</label>
              <Button type="button" style={{marginLeft:25}}  text="Add Balance"  onClick={()=>{navigate('/trainee/wallet')}}/>
              </div>
              
              <Button type="button" style={{marginLeft:25}}  text="End Conversation"  onClick={()=>{props?.setIsModal(true)}}/>
             <div className="py-2">
             <ChatTimerCard props={props._props} timerCallBack={timerCallBack}/>
             </div>
             
            </div>

           
        
        </>
    )
}

export default TrainerChatSizeBox;
