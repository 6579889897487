import React, { useState } from 'react';
import './input.scss'
import Select from 'react-select';


const SelectNew = props => {
        

  /*
    
       
       const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#8081ff",
         
        }),
      
      };
      
      */


   
      const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            color: isFocused ? "#fff" : "#fff",
            color: isSelected ? '#000' : '#000',
          };

        },
        option: provided => ({
          ...provided,
          color: 'black'
        }),
        control: provided => ({
          ...provided,
          color: 'black'
        }),
        singleValue: provided => ({
          ...provided,
          color: 'white'
        }),
        control: (base, state) => ({
          ...base,
          background: state.isSelected ? '#8081ff' : '#8081ff',
          color: state.isSelected ? '#fff' : '#fff',
          
         
          
        }),
        
      
      };

      




    return (

        <div >
            {props.label && <label className={`form-label`}>{props.label}</label>}
            <Select
              placeholder={<div className="select-placeholder-text">{props.placeholder}</div>} 
              value={props.selectedOption}
              id={props.id}
              options={props?.options}
              styles={customStyles}
              onChange={props.onChange}
              disabled={props.disabled}
              required={props.required}
              multiple={props.multiple}
              getOptionLabel={e => (
              <div style={{ display: 'flex', alignItems: 'center' }} className='select-new-text-lable'>
                   {e.text}
              </div>
              )}
               
            >
            
            </Select>
       
       </div>
       
    );
};

export default SelectNew;
