import React from "react";
import Button from "../button";
import Text from "../text";
import './totalCtaCard.scss';

const TotalCtaCard = props => {
    const {text} =props
    return(
        <>
             <div className="total-cta-card">
                <Text
                    type="H4" 
                    className="text-light"
                    text={props.title} 
                />
                <Text 
                    type="SPAN" 
                    className="text-light mb-20" 
                    text={`${props.fromDate ? props.fromDate : ''} - ${props.toDate ? props.toDate : ''}`}
                />
                <Text type="H1" className="text-cyan" text={props.number} />
                <Button 
                    type="button"  
                    text="View Details"
                    className="btn-light"
                />
                {props.icon && (
                    <div className="total-cta-icon">
                        {props.icon}
                    </div>
                )}
            </div>
        </>
    )
}

export default TotalCtaCard;
