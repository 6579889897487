import React from 'react';

const StarIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 30 30" fill={props.fill ? 'currentColor' : 'none'} xmlns="http://www.w3.org/2000/svg">
            <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M18.698 8.03L15 1L11.302 8.03C11.0408 8.54372 10.6626 8.98899 10.198 9.33C9.73112 9.67276 9.19086 9.90214 8.62 10L1 11.494L6.32 17.856C7.04755 18.6534 7.39085 19.7286 7.26 20.8L6.248 29L13.264 25.6C13.8052 25.3379 14.3986 25.2011 15 25.2C15.5642 25.2017 16.1198 25.3389 16.62 25.6L23.8 28.79L22.788 20.688C22.6601 19.6189 23.004 18.5471 23.73 17.752L29 11.494L21.38 10C20.8084 9.90244 20.2675 9.67304 19.8 9.33C19.3361 8.98876 18.9587 8.54351 18.698 8.03Z" 
                stroke={props.fill ? 'none' : 'currentColor'}
                file={props.fill ? 'currentColor' : 'none'} 
                stroke-width="1.5" 
                stroke-linecap="round" 
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default StarIcon;

StarIcon.defaultProps = {
    width: "30",
    height: "30",
}