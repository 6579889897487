import React from 'react';
import Text from '../../../../../components/text';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import Button from '../../../../../components/button';
import ProgramBarSecondaryCard from '../../../../../components/programBarCard/programBarSecondaryCard';
import useHelper from "../../../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";

const MyCommissionView = (props) => {
    const {commissionDetails} = props
    const {formatDateDDMMYY2, decimalFormatter, minuteFormatter, toSeconds} = useHelper()
    const navigate = useNavigate()
    const {text} =props
    const totalAffiliateIncomeMonthDates = formatDateDDMMYY2(commissionDetails?.first_day) + ' - ' + formatDateDDMMYY2(commissionDetails?.last_day)
    const videoMinutesViewedThisMonthDates = formatDateDDMMYY2(commissionDetails?.first_day) + ' - ' + formatDateDDMMYY2(commissionDetails?.last_day)
    const totalCreditRewardPointsDates = formatDateDDMMYY2(commissionDetails?.joined_date) + ' - Now'
    const barPercentage = 100 / toSeconds(commissionDetails?.watched_this_month_minutes) * toSeconds(commissionDetails?.min_minutes)

    return (
        <>
            <Text
                type="H1"
                text="Member Commission"
            />
           {/* <NavLearnerMember {...props} />*/}
           <Text
                type="H4"
                text="Affiliate Income"
                className="font-weight-500"
            />
            <Text
                type="PARAGRAPH"
                className="text-white-half"
                text="In order for you to receive affiliate income, you should have referred atleast 1 member  and have completed the monthly payment upon registration as a member. Total income will be payable if you fulfill the target videos watched duration."
            />
            <div className="mc-card">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="mc-left">
                            <div className="mc-top">
                                <div className="mc-top-left">
                                    <Text
                                        type="H4"
                                        className="text-light"
                                        text="Total Affiliate Income for this Month"
                                    />
                                    <Text
                                        type="SPAN"
                                        text={totalAffiliateIncomeMonthDates}
                                        className="text-cyan"
                                    />
                                </div>
                                <Button
                                    type="button"
                                    text="View report"
                                    className="btn-light"
                                    onClick={()=>{navigate('/trainee/member/my-report')}}
                                />
                            </div>
                            <Text
                                type="H1"
                                className="text-light"
                                text={`$ ${commissionDetails?.this_month_affiliate_income}`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="text-cyan mb-0"
                                text="The referral commission is based on monthly basis and maximum up to 5 tiers only.The referral commission is based on monthly basis and maximum up to 5 tiers only."
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="mc-right">
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-light mb-10"
                                text="Total Income Paid"
                            />
                            <Text
                                type="H3"
                                className="mb-48 text-cyan"
                                text={`$ ${commissionDetails?.total_income_paid}`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-light mb-10"
                                text="Total Referred Members"
                            />
                            <Text
                                type="H3"
                                className="text-cyan"
                                text={commissionDetails?.total_referred_members}
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            {/*<Text
                type="H4"
                text="Credit Reward Point"
                className="font-weight-500"
            />
            <Text
                type="PARAGRAPH"
                className="text-white-half"
                text="The total credit reward point is generated based on the total minutes viewed. 1 minute video viewing equivalent to 1 point. You can watch video repeatedly and the minutes will be counted."
            />
            <div className="mc-card">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="mc-left">
                            <div className="mc-top">
                                <div className="mc-top-left">
                                    <Text
                                        type="H4"
                                        className="text-light"
                                        text="Total Credit Reward Points"
                                    />
                                    <Text
                                        type="SPAN"
                                        text={totalCreditRewardPointsDates}
                                        className="text-cyan"
                                    />
                                </div>
                            </div>
                            <Text
                                type="H1"
                                className="text-light"
                                text={minuteFormatter(commissionDetails?.total_credit_reward_points)}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="text-cyan mb-0"
                                text="Credit reward points can be redeemed based on view points accumulated. 1 Minute of video viewing is equivalent to 1 view points."
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="mc-right">
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-light mb-10"
                                text="Reward Points this Month "
                            />
                            <Text
                                type="H3"
                                className="mb-48 text-cyan"
                                text={`${minuteFormatter(commissionDetails?.this_month_reward_points)} Pts`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-light mb-10"
                                text="Reward Points  last Month "
                            />
                            <Text
                                type="H3"
                                className="text-cyan"
                                text={`${minuteFormatter(commissionDetails?.last_month_reward_points)} Pts`}
                            />
                        </div>
                    </div>
                </div>
    </div>*/}
            <Text
                type="H4"
                text="Video viewing"
                className="font-weight-500"
            />
            <Text
                type="PARAGRAPH"
                className="text-white-half"
                text="Every month there is a specific target video viewing minutes for you to achieve. It is mandatory for you to achieve target minutes viewing to enjoy affiliate income. Video viewing time accumulation only applicable for videos from Guru and not promotional videos from COURSE_TSUNAMI."
            />
            <div className="mc-card">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="mc-left">
                            <div className="mc-top">
                                <div className="mc-top-left">
                                    <Text
                                        type="H4"
                                        className="text-light"
                                        text="Video minutes viewed this month"
                                    />
                                    <Text
                                        type="SPAN"
                                        text={videoMinutesViewedThisMonthDates}
                                        className="text-cyan"
                                    />
                                </div>
                                <Button
                                    type="button"
                                    text="watch video"
                                    className="btn-light"
                                    onClick={()=>{navigate('/trainee/dashboard')}}
                                />
                            </div>
                            <Text
                                type="H1"
                                className="mb-0 text-light"
                                text={commissionDetails?.watched_this_month_minutes}
                            />
                            <ProgramBarSecondaryCard width={barPercentage} minutesLeft={commissionDetails?.remaining_minutes_to_watch} />
                            <Text
                                type="PARAGRAPH"
                                className="text-cyan mb-0"
                                text="You are required to fulfil the target minutes of the month to claim commission from the system. If you does not fulfill the requirement, then the commission collected would be forfeited."
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="mc-right">
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-light mb-10"
                                text="Total Video Minutes Viewed"
                            />
                            <Text
                                type="H3"
                                className="mb-48 text-cyan"
                                text={`${commissionDetails?.total_video_minutes_viewed} minutes`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-light mb-10"
                                text="Target Minutes Per Month"
                            />
                            <Text
                                type="H3"
                                className="text-cyan"
                                text={`${commissionDetails?.min_minutes} minutes`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyCommissionView;
