import React, { useEffect } from "react";
import Icon from "../icon";
import Image from "../image";
import './topicInterestItem.scss'
import TickIcon from '../svg/tickIcon'
import Text from "../text";
import { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Flex from "../flex";
import infoImg from '../../assets/img/information.png'


const TopicInterestItem = props => {


    const [selectItem, setSelectItem] = useState(props?.isSelected);
    const onSelect = () => {
        setSelectItem(state => !state)
        console.log("props?.catId",props?.catId);
        props?.setSelectedOptions(!selectItem, props?.catId)
       
    }




    const [subCategories, setSubCategories] = useState([]);

    const getSubCategoriesById = (catId) => {
        
        let subCategoryOptions = []
        if (props?.dropdownsData?.subTopic) {
            console.log("props?.dropdownsData?.subTopic",props?.dropdownsData?.subTopic,catId);
            props?.dropdownsData?.subTopic.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push(item);
                }
            })
        }
      
        setSubCategories(subCategoryOptions)
        setShow(true);
    }


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);


    function handleShow(id){ 
        console.log("id",id);
        getSubCategoriesById(id);
        
        
    }
    return(
        <>
        <div className="topic-interest-box">
            <div className={`topic-interest-card ${selectItem ? 'selected' : ''} `} onClick={onSelect}>
                <Image src={"ct/" + props.src} alt={props.text} />
                
                <div className="topic-interest-content">
                    {selectItem &&
                        <div className="topic-interest-icon">
                            <Icon
                                icon={<TickIcon />}
                                small
                            />
                        </div>
                    }
                    
                    
                </div>


            </div>
            <Flex justifyContent="center">
            <Text type="SPAN" text={props.text} className="category-heading-txt" style={{textAlignVertical: "center",textAlign: "center"}}/>    
                                                            {/* <Button
                                                            link
                                                            text={
                                                                <img src={infoImg}  alt="information" className='icon-50' />
                                                            }
                                                            style={{margin:'0px',padding:'1px'}}
                                                            onClick={() => 
                                                                handleShow(props.catId)
                                                            }
                                                            type="button"
                                                        />*/}
            
                        </Flex>

                        
            
            

              </div>
         

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sub-Categeories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                       {
                            subCategories.map((item, index) => {
                                const imgsrc = process.env.REACT_APP_IMG_URL+item?.image
                                return(
                                    <>

                <div className="col-md-6">
                    <div className="topic-interest-box">
                        
                           {/*         
                            <Image src={imgsrc} alt={item.subCatName} />
                                 <div className="topic-interest-content">
                                    <Text type="H4" text={item.subCatName} className="topic-interest-title" />
                                </div>
                           */}

<div className={`topic-interest-card ${selectItem ? 'selected' : ''} `} onClick={onSelect}>
                <Image src={imgsrc} alt={item.subCatName} />
                <div className="topic-interest-content">
                    
                    
                    <Text type="SPAN" text={item.subCatName} className="category-heading-txt" style={{textAlignVertical: "center",textAlign: "center"}}/>    
                </div>


            </div>
                        
                    </div>
                    </div>      
                                    </>
                                )
                                
                            })

                       } 

                </div>
            </div>
        
        </Modal.Body>
          </Modal>
        </>
    )
}

export default TopicInterestItem;

TopicInterestItem.defaultProps = {
    text: "Something",
    src: "https://picsum.photos/600",
}
