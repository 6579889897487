import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useSurveyQuestionThree = () => {
    const navigate = useNavigate()
    const [earningAffiliate, setEarningAffiliate] = useState(false);
    const goToPersonalInformation = () => {
        navigate('/trainee/personal-information')
    }
    const goBackToSurveryQuestion2 = () =>{
        navigate('/trainee/survey-question-2')
    }
    return {
        goBackToSurveryQuestion2,
        goToPersonalInformation,
        earningAffiliate,
        setEarningAffiliate
    }

}
export default useSurveyQuestionThree


