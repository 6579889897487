import React, {useEffect} from 'react';
import HomeView from "./views/HomeView";
import Layout from "../../components/layout";
import useHome from "./hooks/home.hook";
import Loader from "../../components/loader";

const HomePage = (props) => {
    const homeHook = useHome()

    if (homeHook?.loading) {
        return (
            <>
                <Layout {...props}>
                    <Loader/>
                </Layout>
            </>
        )
    } else {
        return (
            <>
                <Layout {...props}>
                    <HomeView {...props} {...homeHook} />
                </Layout>
            </>
        )
    }

}

export default HomePage;
