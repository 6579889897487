import React from "react";
import AboutUsHeroSection from "../../../components/heroSection";
import PageHeader from "../../../components/pageHeader";
import ProcessCount from "../../../components/processCount";



const AboutUsPageView =()=>{
    return(
        <>
        <PageHeader 
          title={"About Us"}
          page={"About Us"}
        />
        <AboutUsHeroSection />
        <ProcessCount />
        
        </>
    )
}


export default AboutUsPageView;