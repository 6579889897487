import React, { useState } from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import usePersonalInformation from '../hooks/personalInformation.hook'
import Text from '../../../../components/text';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import Textarea from '../../../../components/input/textarea';
import GalleryIcon from '../../../../components/svg/galleryIcon';
import InputFile from '../../../../components/input/inputFile';
import {useNavigate} from "react-router-dom";
import Avatar from "../../../../components/avatar";
import DP from "../../../../assets/images/dp.png";
import RecentImage from "../../../../assets/images/video-details-img.jpg";
import Image from "../../../../components/image";
import GoBackButton from "../../../../components/goBackButton";
import NavSide from '../../../../components/navSide';
import { Badge } from 'antd';

const PersonalInformationView = (props) => {
    const navigate = useNavigate()

    const [inputText, setInputText] = useState("");
    const [characterLimit] = useState(500);
    // event handler
    const handleChange = event => {
        
            setInputText(event.target.value);
        
    };

    const onChangeText = (event) => {
        if(event.target.value.length <=300){
        formik.setFieldValue("aboutyourself",event.target.value)
        handleChange(event)
        }
     
    }
   
    const {
        text,
        goBackToOTPVerification,
        updateGuruProfile,
        onClickFillUpLater,
        profileDetails,
        isSubmitting
    } = props

    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL + profileDetails?.pic : '';
    const hasProfileImage = profileDetails?.pic !== '' ;
    var validationSchema = {};
  
    if(hasProfileImage){
        validationSchema = Yup.object({
            fullname: Yup.string().required('Please enter a valid full name'),
            preferredname: Yup.string().required('Please enter a valid preferred name'),
            aboutyourself: Yup.string().required('Please enter a valid about yourself'),
        })
    
    }else{

        validationSchema =   Yup.object({
            file:Yup.mixed().required('Please upload thumbnail')
            .test('fileFormat', 'Please upload thumbnail, jpeg/png', (value) => {
              console.log(value); return value && ['image/jpeg','image/png'].includes(value.type);
            }),
            fullname: Yup.string().required('Please enter a valid full name'),
            preferredname: Yup.string().required('Please enter a valid preferred name'),
            aboutyourself: Yup.string().required('Please enter a valid about yourself'),
        })

    }
     
    

    const formik = useFormik({
        initialValues: {
            file: '',
            fullname: profileDetails?.fullname ? profileDetails?.fullname : '',
            preferredname: profileDetails?.preffered_name ? profileDetails?.preffered_name : '',
            aboutyourself: profileDetails?.about_yourself ? profileDetails?.about_yourself : '',
            linkedin:profileDetails?.linkedin ? profileDetails?.linkedin : '',
            facebook:profileDetails?.facebook ? profileDetails?.facebook : '',
            instagram:profileDetails?.instagram ? profileDetails?.instagram : '',
            twitter:profileDetails?.twitter? profileDetails?.twitter : '',
        },

        validationSchema:validationSchema,
        onSubmit: (values, actions) => {
            console.log("values",values);
            values.pic = profileDetails?.pic
            updateGuruProfile(values);
        },
    });

    

    var fileobj = { name: "sample_1.png", lastModified: 1668196167817, webkitRelativePath: "", size: 349419, type: "image/png" }

    if(hasProfileImage !== '' ){
     //   formik.setFieldValue("file", fileobj); 
    }
    
    const showInputFile = !hasProfileImage || formik.values.file

    return (
        <>

            <div class="p-3 mb-3">

              <div class="card-body">

                <div class="pt-4">
                  <h5 class="card-title text-center pb-0 fs-4">INSTRUCTOR PROFILE</h5>
                  <p class="text-center small">Create Your Profile: Share Your Expertise with Students</p>
                  <NavSide
                title=""
            options={[
                {text:"", link: '/instructor/personal-information'},
                {text: "", link: '/instructor/work-experience'},
                {text: "", link: '/instructor/upload-attachments'},
                {text: "", link: '/instructor/bank-details'},
            ]}
        />
                </div>

         

            <form onSubmit={formik.handleSubmit}>
                <div className='row'>
                    <div className='="col-12'>
                    <div className="formBox">
                    <div className="form-field align-item-center mb-10">
                        <label className="form-label">{text?.PROFILE_PHOTO} <span className="text-danger">*</span></label>
                    </div>
                    {showInputFile && (
                        <Flex className="upload-image-wrap">
                            <InputFile
                                type="file"
                                name="profilePhoto"
                                placeholder=""
                                thumb={formik.values.file}
                                value={formik.values.profilePhoto}
                                /*onChange={formik.handleChange}*/
                                onChange={(event) => {
                                    formik.setFieldValue("file", event.currentTarget.files[0]);
                                }}
                                
                                mandatory
                                onBlur={formik.handleBlur}
                                
                                errormessage={formik.touched.file && formik.errors.file ? (
                                    formik.errors.file
                                ) : null}
                            />
                        </Flex>
                    )}
                    {!showInputFile && (
                        <div className="mb-20">
                        <Image src={profileImage}
                               width="144"
                               height="144"
                        />
                        </div>
                    )}
                    {hasProfileImage &&(
                        <Input
                        tertiary
                        label={text?.PROFILE_PHOTO}
                        type="file"
                        name="profilePhoto"
                        placeholder=""
                        thumb={formik.values.file}
                        value={formik.values.profilePhoto}
                        /*onChange={formik.handleChange}*/
                        onChange={(event) => {
                            formik.setFieldValue("file", event.currentTarget.files[0]);
                        }}
                        mandatory
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.file && formik.errors.file ? (
                            formik.errors.file
                        ) : null}
                        className="text-black"
                        />
                    )}


                </div>
                    </div>
                </div>
               
                <div className="formBox">
                    <Input
                        label="Full Name"
                        type="text"
                        name="fullname"
                        placeholder={text?.FULL_NAME}
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.fullname && formik.errors.fullname ? (
                            formik.errors.fullname
                        ) : null}
                    />
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="formBox">
                            <Input
                                label={text?.PREFERRED_NAME}
                                type="text"
                                name="preferredname"
                                placeholder="Nick Name"
                                value={formik.values.preferredname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.preferredname && formik.errors.preferredname ? (
                                    formik.errors.preferredname
                                ) : null}
                            />
                        </div>
                    </div>
                    {/*
                    <div className='col-md-6'>
                    <div className="formBox">
                            <Input
                                label="Facebook Link"
                                type="text"
                                name="facebook"
                                placeholder="Facebook Link"
                                value={formik.values.facebook}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errormessage={formik.touched.facebook && formik.errors.facebook ? (
                                    formik.errors.facebook
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                            <Input
                                label="Twitter Link"
                                type="text"
                                name="twitter"
                                placeholder="Twitter Link"
                                value={formik.values.twitter}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errormessage={formik.touched.twitter && formik.errors.twitter ? (
                                    formik.errors.twitter
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                            <Input
                                label="Instagram Link"
                                type="text"
                                name="instagram"
                                placeholder="Instagram Link"
                                value={formik.values.instagram}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                                errormessage={formik.touched.instagram && formik.errors.instagram ? (
                                    formik.errors.instagram
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                            <Input
                                label="Linkedin Link"
                                type="text"
                                name="linkedin"
                                placeholder="Linkedin Link"
                                value={formik.values.linkedin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                                errormessage={formik.touched.linkedin && formik.errors.linkedin ? (
                                    formik.errors.linkedin
                                ) : null}
                            />
                        </div>
                    </div>
                                */}
                    <div className="col-12">
                        <div className="formBox">
                            <Textarea
                                label={text?.ABOUT_YOURSELF}
                                type="text"
                                name="aboutyourself"
                                placeholder="Please write about yourself"
                                value={formik.values.aboutyourself}
                                onBlur={formik.handleBlur}
                                onChange={(event)=>{ onChangeText(event)}}
                                mandatory
                                errormessage={formik.touched.aboutyourself && formik.errors.aboutyourself ? (
                                    formik.errors.aboutyourself
                                ) : null}
                            />

<Badge style={{marginTop:-10}} bg={`${inputText.length > characterLimit ? 'danger' : 'primary'}`}>{inputText.length}/{characterLimit}</Badge>
                        </div>
                    </div>
                </div>
                <Flex justifyContent="center" className="mt-3 mb-2">
                    <Button
                        type="button"
                        text={text?.GOBACK}
                        large
                        light
                        className="mt1" 
                        outline
                        onClick={()=>{navigate('/')}}

                    />
                    
                    <Button
                        type="submit"
                        className="btn-verification"
                        text={text?.PROCEED}
                        large
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                    />
                </Flex>
                
            </form>

              </div>
            </div>  
        </>
    );
};

export default PersonalInformationView;

