import React from 'react';

const PendingApprovalIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.5833 70.3332C43.25 70.3332 39.5417 68.7915 36.4583 65.7082C33.375 62.6248 31.8333 58.9165 31.8333 54.5832C31.8333 50.2498 33.375 46.5415 36.4583 43.4582C39.5417 40.3748 43.25 38.8332 47.5833 38.8332C51.9167 38.8332 55.625 40.3748 58.7083 43.4582C61.7917 46.5415 63.3333 50.2498 63.3333 54.5832C63.3333 58.9165 61.7917 62.6248 58.7083 65.7082C55.625 68.7915 51.9167 70.3332 47.5833 70.3332ZM52.4167 62.9998L54.75 60.6665L48.5 54.4165V45.0832H45.25V55.5832L52.4167 62.9998ZM5 65.8332C3.61111 65.8332 2.43056 65.3471 1.45833 64.3748C0.486111 63.4026 0 62.2221 0 60.8332V12.3332C0 10.9443 0.430556 9.76373 1.29167 8.7915C2.15278 7.81928 2.88889 7.33317 3.5 7.33317H20.3333C20.7222 5.38873 21.6806 3.7915 23.2083 2.5415C24.7361 1.2915 26.5 0.666504 28.5 0.666504C30.5 0.666504 32.2639 1.2915 33.7917 2.5415C35.3194 3.7915 36.2778 5.38873 36.6667 7.33317H53.5C54.8889 7.33317 56.0694 7.81928 57.0417 8.7915C58.0139 9.76373 58.5 10.9443 58.5 12.3332V36.9165C57.6667 36.4165 56.8472 35.9859 56.0417 35.6248C55.2361 35.2637 54.3889 34.9443 53.5 34.6665V12.3332H44.6667V23.1665H13.8333V12.3332H5V60.8332H27.75C28.0278 61.6665 28.3611 62.4859 28.75 63.2915C29.1389 64.0971 29.6111 64.9443 30.1667 65.8332H5ZM30 11.9998C30.9444 11.9998 31.7361 11.6804 32.375 11.0415C33.0139 10.4026 33.3333 9.61095 33.3333 8.6665C33.3333 7.72206 33.0139 6.93039 32.375 6.2915C31.7361 5.65261 30.9444 5.33317 30 5.33317C29.0556 5.33317 28.2639 5.65261 27.625 6.2915C26.9861 6.93039 26.6667 7.72206 26.6667 8.6665C26.6667 9.61095 26.9861 10.4026 27.625 11.0415C28.2639 11.6804 29.0556 11.9998 30 11.9998Z" fill="#ECCB09"/>
        </svg>
    );
};

export default PendingApprovalIcon;

PendingApprovalIcon.defaultProps = {
    width: '64',
    height: '71',
}