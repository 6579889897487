import {useFormik} from 'formik';
import * as Yup from 'yup';
import Loader from '../../../../components/loader';
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import { useEffect } from 'react';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';
import Pagination from '../../../../components/pagination';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import useHelper from '../../../../hooks/helpers.hook';
import editImg from '../../../../assets/img/Edit.png'
import viewImg from '../../../../assets/img/Eye.png'

const RepliesQuizView = (props) => {
    const {formatDateMMMDDYYYY} = useHelper();
    console.log("props.selectedData.questionI",props.selectedData)
    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'iGetCourseQuizReplies';
        DEFAULT_FILTERS.questionId =props.questionId;
        commonHook.getList(DEFAULT_FILTERS)
      
    },[])



    if (commonHook?.loading) {
        return (
            <>
            
            
              
            </>
        )
    } else {
  
    return (
        <>
      <div>
        
        <Table
                thead={
                    <tr>
                        <th role="columnheader">Srno</th>
                        <th role="columnheader">UserName</th>
                        <th role="columnheader">Email</th>
                        <th role="columnheader">Answered Option</th>
                        <th role="columnheader">Replied On</th>
                   
                        
                    </tr>
                }
                tbody={
                    <>
                  
                        {commonHook?.tableData?.listData?.map(function (item, index) {
                            return (
                         
                                <tr role="row">
                                <td role="cell">{index + 1}</td>
                                <td role="cell">{item.fullname}</td>
                                <td role="cell">{item.email}</td>
                                <td role="cell">{item.optionValue}</td>
                                <td role="cell">{formatDateMMMDDYYYY(item.updated_on)}</td>
                            </tr>
                            
                            )
                        })}
                   
                    </>
                }
            />
            <Pagination
                totalPages={commonHook?.tableData?.totalPages}
                currentPage={commonHook?.tableData?.currentPage}
                totalRecords={commonHook?.tableData?.totalRecords}
                nextPage={commonHook?.nextPage}
                previousPage={commonHook?.previousPage}
                perPage={commonHook?.filterKeys.perpage}
            />

        <div className="action-buttons-end ">
                     <Button
                        type="button"
                        text="Close"
                        onClick={()=>{ 
                        
                            props.callViewQuiz()
                        }}
                    />
        </div>


      </div>


      

                </>


    )
  }

};

export default RepliesQuizView;
