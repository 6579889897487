import React from "react";
import Input from "../input";
import './checkbox.scss'

const Checkbox = props => {
    return(
        <div>
        <div className="form-check">
        <input class="form-check-input" type="checkbox" name="acceptTerms" value={props.value} id={props.id}
                onChange={props.onChange}
                checked={props.isChecked}
                errormessage={props.errormessage} />&nbsp;
            <label className="form-check-label" for={props.id}>
                {props.label}
            </label>

        </div>
            {props.errormessage && <div className="message-error"><small>{props.errormessage}</small></div>}
        </div>
    )
}

export default Checkbox;
