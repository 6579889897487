import React, {useEffect, useState} from "react";
import LayoutPanel from "../../../components/layoutPanel";

import './main.scss';
import useLearnerMain from "./hooks/courseMain.hook";
import Loader from "../../../components/loader";
import useVideoPlayers from "../../../components/videoPlayers/hooks/videoPlayers.hook";
import useGetAllVideosWatched from "../../../hooks/getallvideoswatched.hook";
import {useLocation, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { GURU } from "../../../constants/app.constants";
import LayoutGuru from "../../../components/layoutGuru";
import CourseMainView from "./view/courseMainView";
import useCourseMain from "./hooks/courseMain.hook";

const CoursePreview = props => {
    const {videoId} = useParams()
    const learnerMainHook = useCourseMain();
    const videoPlayersHook = useVideoPlayers()
    const getAllVideosHook = useGetAllVideosWatched()
    const {pathname} = useLocation();
    var isMyLearningPage = pathname.includes('my-learning')
    const userInfo = useSelector(state => state?.app?.userInfo);
   
      //  isMyLearningPage = true;
  
    useEffect(() => {

        learnerMainHook.getVideoDetailsById(null, isMyLearningPage)
        
    }, []);

    if (learnerMainHook.loading || learnerMainHook?.loading ) {
        return (
            <>
                <LayoutPanel {...props} footer>
                    <Loader/>
                </LayoutPanel>
            </>
        )

    } else {
        return (
            <>
                {userInfo?.usertypeid === GURU &&(

                        <LayoutGuru {...props} footer>
                            <CourseMainView {...props} {...learnerMainHook} {...videoPlayersHook} isMyLearningPage={isMyLearningPage}  {...getAllVideosHook}/>
                        </LayoutGuru>

                )
                }

                {userInfo?.usertypeid != GURU &&(

                <LayoutPanel {...props} footer>
                    <CourseMainView {...props} {...learnerMainHook} {...videoPlayersHook} isMyLearningPage={isMyLearningPage} {...getAllVideosHook}/>
                </LayoutPanel>

                )
                }
            </>
        )
    }


}

export default CoursePreview;
