import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../components/text';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useNavigate} from "react-router-dom";
import useLogin from "../../../authentication/login/hooks/login.hook";

const BankDetailsView = (props) => {
    const {numbersOnly} = useLogin()
    const navigate = useNavigate()
    const {post} = useHttpHandler()
    const {text, goToComplete, profileDetails} = props
    const formik = useFormik({
        initialValues: {
            bankName: profileDetails?.bankname ? profileDetails?.bankname : '',
            accountHolderName: profileDetails?.payeename ? profileDetails?.payeename : '',
            accountNumber: profileDetails?.accountnumber ? profileDetails?.accountnumber : '',
        },
        validationSchema: Yup.object({
            bankName: Yup.string().required('Please enter a valid bank name'),
            accountHolderName: Yup.string().required('Please enter a valid account holder name'),
            accountNumber: Yup.string().required('Please enter a valid account number').matches(numbersOnly, 'Please enter a valid account number'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)
            post('user_bankdetailsentry', values).then((res)=>{
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if(status.result == '200') {
                    goToComplete()
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {actions.setSubmitting(false);})
        },
    });
    return (
        <>
            <Text
                type="H4"
                text={text?.BANK_DETAILS}
                className="page-title"
            />
            <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        label={text?.BANK_NAME}
                        type="text"
                        name="bankName"
                        placeholder={text?.YOUR_BANK_NAME}

                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.bankName && formik.errors.bankName ? (
                            formik.errors.bankName
                        ) : null}
                    />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="formBox">
                            <Input
                                label={text?.ACCOUNT_HOLDER_NAME}
                                type="text"
                                name="accountHolderName"
                                placeholder={text?.ACCOUNT_HOLDER}

                                value={formik.values.accountHolderName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountHolderName && formik.errors.accountHolderName ? (
                                    formik.errors.accountHolderName
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="formBox">
                            <Input
                                label={text?.ACCOUNT_NUMBER}
                                type="text"
                                name="accountNumber"
                                placeholder={text?.ACCOUNT_NUMBER}

                                value={formik.values.accountNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountNumber && formik.errors.accountNumber ? (
                                    formik.errors.accountNumber
                                ) : null}
                            />
                        </div>
                    </div>
                </div>
                <Flex justifyContent="center" className="mb-48 mt-48">
                    <Button
                        type="button"
                        text={text?.GOBACK}
                        large
                        light
                        outline
                        onClick={()=>{navigate(-1)}}

                    />
                    <Button
                        type="submit"
                        text={text?.COMPLETE}
                        large
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </Flex>
                <Flex justifyContent="center">
                    <Button
                        type="button"
                        linkSecondary
                        text={text?.FILL_UP_LATER}
                        onClick={()=>{navigate('/trainee/dashboard')}}
                    />
                </Flex>
            </form>
        </>
    );
};

export default BankDetailsView;

