import React, {useEffect, useState} from "react";
import LayoutPanel from "../../../components/layoutPanel";
import SearchGuruView from "./view/SearchGuruView";
import {DEFAULT_FILTERS, GURU} from "../../../constants/app.constants";
import SearchTopicsView from "./view/SearchTopicsView";
import useSearchGuru from "./hooks/searchGuru.hook";
import Loader from "../../../components/loader";
import useGetTableList from "../../../hooks/getTableListCommonHook";
import {useParams, useSearchParams} from "react-router-dom";
import CategoryButtons from "../../../components/categoryButtons";

const SearchController = props => {
    const [isActive, setIsActive] = useState({catId: 0, catName: 'All topics'})
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const value = searchParams.get('value')
    const commonHook = useGetTableList();
    const getSearchList = (catId, subCatId) => {
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'member_search_guru_topic';
        DEFAULT_FILTERS.type = parseInt(id);
        DEFAULT_FILTERS.search = value;
        DEFAULT_FILTERS.perpage = 6;
        DEFAULT_FILTERS.category = catId ? catId : 0;
        DEFAULT_FILTERS.sub_category_id = subCatId ? subCatId : 0;
        commonHook.getList(DEFAULT_FILTERS)
    }
    useEffect(() => {
        getSearchList()
    }, [id, value])

    if (commonHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    } else {
        if (parseInt(id) === GURU) {
            return (
                <>
                    <LayoutPanel {...props}>

                        <div className="overflow-outer">
                            <CategoryButtons
                                getList={getSearchList}
                                active={isActive}
                                setActive={setIsActive}
                            />
                            <SearchGuruView {...props} {...commonHook} searchKeyword={value} selectedCategory={isActive}/>
                        </div>
                    </LayoutPanel>
                </>
            )
        } else {
            return (
                <>
                    <LayoutPanel {...props}>
                        <div className="overflow-outer">
                            <CategoryButtons
                                getList={getSearchList}
                                active={isActive}
                                setActive={setIsActive}
                            />
                            <SearchTopicsView {...props} {...commonHook} searchKeyword={value} selectedCategory={isActive}/>
                        </div>
                    </LayoutPanel>
                </>
            )
        }
    }
}

export default SearchController;
