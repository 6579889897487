import React from 'react';

const MoreIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 27 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27998 3.16001C6.27998 4.48549 5.20546 5.56001 3.87998 5.56001C2.5545 5.56001 1.47998 4.48549 1.47998 3.16001C1.47998 1.83453 2.5545 0.76001 3.87998 0.76001C4.5165 0.76001 5.12695 1.01287 5.57704 1.46295C6.02712 1.91304 6.27998 2.52349 6.27998 3.16001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8801 3.16001C15.8801 4.48549 14.8056 5.56001 13.4801 5.56001C12.1546 5.56001 11.0801 4.48549 11.0801 3.16001C11.0801 1.83453 12.1546 0.76001 13.4801 0.76001C14.8056 0.76001 15.8801 1.83453 15.8801 3.16001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4802 3.16001C25.4802 4.48549 24.4057 5.56001 23.0802 5.56001C21.7547 5.56001 20.6802 4.48549 20.6802 3.16001C20.6802 1.83453 21.7547 0.76001 23.0802 0.76001C24.4057 0.76001 25.4802 1.83453 25.4802 3.16001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default MoreIcon;

MoreIcon.defaultProps = {
    width: '27',
    height: '7'
}