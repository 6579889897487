import React from "react";
import { useMediaQuery } from "react-responsive";
import Icon from "../icon";
import TickIcon from "../svg/tickIcon";
import Text from "../text";
import './listen.scss';

const Listen = props => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return(
        <ul className={`list-ul ${props.className ? props.className : ''} ${props.listColTwo ? 'list-ul-column-two' : ''}`}>
            {props?.listen?.map(function(d, idx){
                return (
                    <li className="list-item" key={idx}>
                        <Icon
                            success
                            smallExtra={isMobile ? true : false}
                            small={isMobile ? false : true}
                            icon={<TickIcon />}
                        />
                        <div className="list-item-text">
                            {d?.text ?
                                <>
                                    <Text type="H4" text={d.text} />
                                    {d.description && <Text type="PARAGRAPH" text={d.description} />}
                                </>
                            :
                                <Text type="SPAN" text={d} />
                            }
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default Listen;
