import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import useTranslations from '../../../../hooks/translations.hook';

const useOTPVerification = () => {
    const [submittingOtp, setSubmittingOtp] = useState(false)
    const  {post} = useHttpHandler()
    const navigate = useNavigate()
    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    const [oTPInput, setOTPInput] = useState('');
    const handleChangeOTP = (oTPInput) => {
        setOTPInput(oTPInput)
    }
    
    const [counter, setCounter] = useState(59);
    useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const goBackToRegister = () => {
        navigate('/instructor/signup')
    }
    const goToPersonalInformation = () => {
        navigate('/instructor/personal-information')
    }
    const verifyOtp = ()=> {
        setSubmittingOtp(true)
        post('OTPValidation', {otp: oTPInput}).then((res)=>{
            setSubmittingOtp(false)
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                goToPersonalInformation()
            } else {
                toast.error(status.msg)
            }
        }).catch(err => {setSubmittingOtp(false)})
    }

    const resendOtp = ()=> {
        setSubmittingOtp(true)
        post('ResendOTP').then((res)=>{
            setSubmittingOtp(false)
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                toast.msg("Please check your email once")
            }
        }).catch(err => {setSubmittingOtp(false)})
    }

    return {
        oTPInput,
        handleChangeOTP,
        counter,
        setCounter,
        goBackToRegister,
        goToPersonalInformation,
        verifyOtp,
        submittingOtp,
        resendOtp
    }

}
export default useOTPVerification


