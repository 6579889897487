import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";


const ModuleCard =(props)=>{
	const navigate = useNavigate();
	const {post} = useHttpHandler();


	console.log("props?._props.checkLevel",props?._props.getlevelId);

	const formik = useFormik({
        initialValues: {
            modulelevel:  props?._props.getlevelId,
           
        },
        validationSchema: Yup.object({
            modulelevel: Yup.string().required('Please enter a valid Categeory'),
           
        }),
        onSubmit: (values, actions) => {

            actions.setSubmitting(true)
            let postData = {
                
                modulelevel: values.modulelevel,
               
            }

            post('instructorCreateCourse ', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    toast.success(status.msg)
                    navigate('/instructor/course')
                    
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        },

    });

	const setLevelId = (e) =>{
		
        props?._props.setLevel(true);
		//console.log(props?._props.checkLevel)
        props?._props.setlevelId(e.target.value);
        formik.setFieldValue('modulelevel', e.target.value)
    } 
   
    return(
        <>
	<div className="container p-4">
	<div className="row bg-light module_card_height">
		<div className="position-relative col-12 col-md-3 lazyloaded">
			<img src={process.env.REACT_APP_IMG_URL + props.imgsrc} className="img-fluid mt-2 image-content" alt={props.alt} />
		</div>
		<div className="col-md-9 p-1 mt-3">
			<div className="row mb-4">
				<div className="col-md-12">
					<div className="d-flex mb-2 mr-3 flex-row bg-subcolor_2 text-start align-items-center">
					
							<p className="mb-0 text-1 mr-3 font-weight-bold text-dark text-uppercase"> {props.course} </p>
							<h4 className="mb-0 text-2 text-color-secondary"> : {props.coursetitle}</h4>
						
					</div>
				</div>
				<div className="col-md-2">
					<div className="position-relative left-6 border-right align-items-center">
					<div className="p-0">
							<p className="mb-3 text-center text-1 font-weight-bold text-dark text-uppercase">{props.module}</p>
							<h4 className="mb-0 text-center text-6 text-color-secondary">{props.value}<br/></h4>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className=" border-right align-items-center">
					<div className="p-0">
					<form onSubmit={formik.handleSubmit}>
					<div className="text-1 text-center position-relative left-7">
						<p className="text-dark font-weight-bold text-uppercase">{props.modulelevel}</p>
						<Select
							
							name="modulelevel"
							className="form-select"
							placeholder="All the Above"
							value={formik.values.modulelevel}
							onChange={setLevelId}
							onBlur={formik.handleBlur}
							mandatory
							options={props?.dropdownsData?.moduleOptions}
							errormessage={formik.touched.modulelevel && formik.errors.modulelevel ? (
								formik.errors.modulelevel
							) : null}
						/>
					</div>
					</form>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="align-items-center">
					<div className="p-0">
							<p className="mb-3 text-center text-1 font-weight-semibold text-dark text-uppercase">{props.availablevideos}</p>
							<h4 className="mb-0 text-center text-6 text-color-secondary">{props.valuevideo} <br/></h4>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		
	</div>
	</div>

        
        </>
    )
}


export default ModuleCard;

