import React, {useEffect, useRef, useState} from "react";
import VideoJs from "../videoPlayers/videoJs";
import videojs from '../videoPlayers/videoJs';
import Modal from "../modal";
import Text from "../text";
import Listen from "../listen";
import PremiumAccountSubscribeNow from "../premiumAccountSubscribeNow";
import useSubscribe from "../../pages/trainee/subscribe/hooks/subscribe.hook";
import useHelper from "../../hooks/helpers.hook";
import {FREEMIUM_MINIMUM_VIDEO_PLAY_SECONDS} from "../../constants/app.constants";
import RecommendedVideosOverlay from "../../pages/trainee/main/view/RecommendedVideosOverlay";
import useVideosCommonHooks from "../../pages/instructor/createCourse/videosCommonHook";


const CourseIntroPlayer = props => {
    

    const commonHook = useVideosCommonHooks();
    const [videoLoaded,setVideoLoaded] = useState(false);

    const [imageUrl, setImageUrl] = useState('');
    

    useEffect(() => {
        console.log("props.image",props)
      // Fetch pre-signed URL from the backend
      commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':props?.videofilename}).then((data) => {
        //console.log("data.data",data.data)
        console.log("data.data",data.data)
        setImageUrl(data.data)
        setVideoLoaded(true)
       
    });
    }, []);
    
    const {
        isModal,
        setIsModal,
        updatePresentVideoDuration,
        finishedPlayingVideo,
        isMyLearningPage,
    } = props
    
    
    const playerRef = useRef(null);


    const videoSrc = props?.videofilename ? process.env.REACT_APP_IMG_URL + props?.videofilename : ''

    console.log("videoSrc",videoSrc );

    const videoJsOptions = {
        liveui: true,
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: imageUrl,
            type: props?.videofiletype
        }],
    };

   
    
    return (
        <>
         {videoLoaded == true &&(
            <VideoJs options={videoJsOptions}  {...props}/>
         )}
        </>
    );
}
export default CourseIntroPlayer;
