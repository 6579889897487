import React from "react";
import CourseImg from '../../../../../assets/img/logo.svg';
import '../../instructor.css';

const CategeoryDetailspageView =()=>{
    return(
        <>
        <div className="page-bg pt-5 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                   <div className="card p-3 instructor-card">
                    <h4 className="categeory-title">Categeory Details</h4>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                <div className="form-group">
                              <label className="mb-2">Title</label><br/>
                              <input type="text" name="title" id="title" className="form-control"  placeholder="Week 1" required />
                            </div>
                                </div>
                                <div className="col-md-12">
                                <div className="form-group">
                            <label className="mb-2">Description</label><br/>
                            <textarea rows="6" cols="70" className="form-control"></textarea>
                            </div>
                                </div>
                            </div>
                            
                           
                        </form>
                    </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className="card mb-3 instructor-card">
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <label className="form-label font-weight-bold categeory-label">Visibility</label><br/>
                                <input type="radio" name="option" id="option" value="Published"/> Published<br/>
                                <input type="radio" name="option" id="option" value="Published" /> Draft<br/>
                                <input type="radio" name="option" id="option" value="Published" /> Locked <br/> <p className="product-description">This category will become for your members till the selected postis completed.</p>
                                <input type="radio" name="option" id="option" value="Published" /> Drip<br/><p className="product-description">This Categeory will become available for your members on a specified number of days after they acquire the Product.</p>
                            </div>
                        </form>
                    </div>
                   </div>
                     <div className="card p-3 instructor-card">
                      <h5>Categeory Thumbnail</h5>
                      <img src={CourseImg} className="img-fluid" alt="course-img" />
                      <div className="card-body">
                        <p className="product-description">This image  will displayed for categeory when your members view their library.</p>
                        <p className="product-description">Recomended dimensions of 1280x720</p>
                        <div className="row">
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6">
                                <h6 className="categeory-remove-text">Remove</h6>
                            </div>
                        </div>
                      </div>
                     </div>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}


export default CategeoryDetailspageView;