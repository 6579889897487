import React,{useEffect, useState} from 'react'
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import Loader from '../../../../components/loader';
import LayoutPanel from '../../../../components/layoutPanel';
import useChatWithInstructor from './hook/chatWithInstructor.hook';
import ScheduleInstructorView from './views/scheduleInstructorView';



const ScheduleMeeting =(props)=>{
  
  const commonHook = useGetTableList();
  const thisHook = useChatWithInstructor();

  useEffect(()=>{
    commonHook.getCategories({perpage:100});
  },[])

  

  var pagetype = 0;
  const window_path = window.location.pathname
    const test = window_path.includes("/trainee/instructor/schedule-meetings");
  if(test){
      
    pagetype = 0;
  }


  if (commonHook?.loading) {
  
    return (
        <>
           <LayoutPanel {...props}>
            <Loader></Loader>
            </LayoutPanel>
        </>
    )
  
  } else {

    return (
      <>
      <LayoutPanel {...props}>
      <ScheduleInstructorView  {...props}  dropdownsData={commonHook.dropdownsData} {...commonHook} {...thisHook} pagetype={pagetype}/>
      </LayoutPanel>
      </>
    )
  
  }
  
}

export default ScheduleMeeting;
