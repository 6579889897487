import React from "react";
import Image from "../image";
import './memberTreeUserCard.scss';
import UserTreePic from '../../assets/images/user-tree-pic.png'
import Text from "../text";

const MemberTreeUserCard = props => {
    const profileImage = props.src ? process.env.REACT_APP_IMG_URL + props.src : UserTreePic
    return(
        <div className="member-tree-user-box">
            <div className="member-tree-user-card">
                <Text type="H4" text={props.text} className="mtu-title text-capitalize" />
                <Image src={profileImage} alt={props.text} rounded="50" />
                {props.code &&(<Text type="PARAGRAPH" text={props.code} className="mtu-code" />)}
            </div>
        </div>
    )
}

export default MemberTreeUserCard;
