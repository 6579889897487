import React from "react";
import LayoutGuru from '../../../../components/layoutGuru';
import MyCommissionView from './view/MyCommissionView';
import './myCommission.scss';
import useMyCommission from "./hooks/myCommission.hook";
import Loader from "../../../../components/loader";

const MyCommission = props => {
    const commissionHook = useMyCommission()
    const {text} = props

    if (commissionHook?.loading) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }

    return (
        <>
            <LayoutGuru {...props}>
                <MyCommissionView {...props} {...commissionHook} />
            </LayoutGuru>
        </>
    )
}

export default MyCommission;
