import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import useAddressInformation from '../hooks/addressInformation.hook';
import Text from '../../../../components/text';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import Select from '../../../../components/input/select';
import {useNavigate} from "react-router-dom";
import {FREEMIUM, GURU, PREMIUM} from "../../../../constants/app.constants";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useSelector} from "react-redux";

const AddressInformationView = (props) => {
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const {text, countries, states, cities, getCitiesByState, profileDetails} = props
    const {
        goBackToAddressInformation,
        goToWorkExperience
    } = useAddressInformation()
    const formik = useFormik({
        initialValues: {
            address_1: profileDetails?.address1 ? profileDetails?.address1 : '',
            address_2: profileDetails?.address2 ? profileDetails?.address2 : '',
            city_id: profileDetails?.cityid ? profileDetails?.cityid : '',
            zip_code: profileDetails?.zipcode ? profileDetails?.zipcode : '',
            state_id: profileDetails?.stateid ? profileDetails?.stateid : '',
            country_id: profileDetails?.countryid ? profileDetails?.countryid : '',
        },
        validationSchema: Yup.object({
            address_1: Yup.string().required('Please enter a valid address line 1'),
            address_2: Yup.string().required('Please enter a valid address line 2'),
            city_id: Yup.string().required('Please enter a valid city'),
            zip_code: Yup.string()
                .required('Please enter a valid postal zip code')
                .matches(/^[0-9]+$/, "Please enter a valid postal zip code")
                .min(5, 'Please enter a valid postal zip code')
                .max(5, 'Please enter a valid postal zip code'),

            state_id: Yup.string().required('Please enter a valid state'),
            country_id: Yup.string().required('Please enter a valid country'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);

            post('user_addressentry', values).then((res) => {
                actions.setSubmitting(false);
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    goToWorkExperience()
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {actions.setSubmitting(false);})

        },
    });
    const getCities = (e) => {
        formik.setFieldValue('state_id', e.target.value)
        getCitiesByState(e.target.value)
    }
    useEffect(() => {
        formik.setFieldValue('country_id', '1')
    }, [])
    return (
        <>
           <div class="card mb-3">

<div class="card-body">

  <div class="pt-4 pb-2">
    <h5 class="card-title text-center pb-0 fs-4">{text?.CORRESPONDENCE_ADDRESS}</h5>
    <p class="text-center small">Enter your personal details to create account

</p>
  </div>
           
            <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        label={text?.ADDRESS}
                        type="text"
                        name="address_1"
                        placeholder={text?.ADDRESS_LINE_1}
                        value={formik.values.address_1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.address_1 && formik.errors.address_1 ? (
                            formik.errors.address_1
                        ) : null}
                    />
                </div>
                <div className="formBox">
                    <Input
                        type="text"
                        name="address_2"
                        placeholder={text?.ADDRESS_LINE_2}
                        value={formik.values.address_2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.address_2 && formik.errors.address_2 ? (
                            formik.errors.address_2
                        ) : null}
                    />
                </div>
                <div className="row">
                    
                    <div className="col-12">
                        <div className="formBox">
                            <Input
                                label={text?.POSTAL_ZIP}
                                type="text"
                                name="zip_code"
                                maxlength="5"
                                placeholder=""
                                value={formik.values.zip_code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.zip_code && formik.errors.zip_code ? (
                                    formik.errors.zip_code
                                ) : null}
                                
                            />
                        </div>
                    </div>
                   
                </div>
                <Flex justifyContent="center" className="mb-3 mt-4">
                    <Button
                        type="button"
                        text={text?.GOBACK}
                        large
                        light
                        outline
                        onClick={()=>{navigate(-1)}}

                    />
                    <Button
                        type="submit"
                        className="btn-verification"
                        text={text?.PROCEED}
                        large
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </Flex>
                <Flex justifyContent="center">
                    <Button
                        type="button"
                        linkSecondary
                        text={text?.FILL_UP_LATER}
                        onClick={() => {
                            navigate('/instructor/dashboard')
                        }}/>
                </Flex>
            </form>
            </div>
            </div>
        </>
    );
};

export default AddressInformationView;

