import React, {useEffect, useState,useRef} from "react";
import LayoutGuru from "../../../components/layoutGuru";

import {useSelector} from "react-redux";
import Loader from "../../../components/loader";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../../../constants/app.constants";
import useVideosCommonHooks from "../../instructor/createCourse/videosCommonHook";
import LayoutPanel from "../../../components/layoutPanel";
import { useParams } from "react-router-dom";
import useChatHook from "./hooks/chat.hook";

import TrainerChatContainerView from "./view/ChatContainerView";
import Modal from "../../../components/modal";
import ChatConfirmInstructor from "../../../components/chatConfirmationInstructor";
import ChatConfirmTrainer from "../../../components/chatConfirmationTrainer";
import ChatEndView from "./view/ChatEndView";

const ChatEndConversation = props => {
    const{userId} = useParams();
    const commonHook = useVideosCommonHooks();
    const chatHook = useChatHook();
    const userInfo = useSelector(state => state.app.userInfo)
    const {sessionId} = useParams()
    
    console.log("userInfo",userInfo)

    useEffect(() => {


        commonHook.promiseService({"uri":"chatEndService","sessionId":sessionId,"chatRoom":userInfo.usertypeid}).then((data) => {
                console.log("data-->",data)
            chatHook.setchatDashboardData(data.data.status);

          
      });


      
    }, [])

    if(userInfo.usertypeid == 8000){

        if (commonHook?.loading) {
            return (
                <>
                   <LayoutGuru {...props}>
                    <Loader></Loader>
                    </LayoutGuru>
                </>
            )
          } else {
    
            return (
            <>
                <LayoutGuru {...props} footer>
                    <ChatEndView {...props} {...commonHook} userInfo={userInfo} {...chatHook}/>
                </LayoutGuru>
            </>
        )
          }
    }else{

    if (commonHook?.loading) {
        return (
            <>
               <LayoutPanel {...props}>
                <Loader></Loader>
                </LayoutPanel>
            </>
        )
      } else {

        return (
        <>
            <LayoutPanel {...props} footer>
                <ChatEndView {...props} {...commonHook} userInfo={userInfo} {...chatHook}/>
            </LayoutPanel>
        </>
    )
      }
    }

}

export default ChatEndConversation;
