import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import Input from "../input";
import Search from "../search";
import Button from "../button";
import Text from "../text";
import Flex from "../flex";
import rightIcon from '../../assets/img/rightIcon.png'

const ConfirmationModal =(props)=>{
    
    return(
        <>


<div className="row popup-padding " style={{background:"#fff"}}>
<Text
    type="H5"
    text={props?.text}
    className="mb-10 mt-10 text-center text-black-color"
/>


    <Flex justifyContent="center" className="mb-48 mt-40">
    <Button type="primary" key="console" text={props?.continueTxt}  onClick={()=>{props?.popConfirm()}}/>
    <Button type="primary" className="btn-danger" key="console" text={props?.cancleText}  onClick={()=>{
        props?.popCancel();
    }}/>
       
    </Flex>


</div>

        </>
    )
}


export default ConfirmationModal;


