import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate, useParams} from "react-router-dom";

import {useSelector} from "react-redux";
import ChatSwitchButton from '../../../../components/ChatswitchButton';
import DashboardBoxes from '../../../../components/dashboardBoxes';
import { FREEMIUM } from '../../../../constants/app.constants';
import useNewCommonHook from '../../../../hooks/newCommonHook';

const CourseDashboardView = (props) => {
    
    const [dashboardDetails,setDashboardDetails] = useState({});
    const {kFormatter, getCurrentMonthRange} = useHelper()
    const navigate = useNavigate()

    const commonHook = useNewCommonHook();


        
    useEffect(() => {

        commonHook._promiseService({"uri":"trainerCourseDashboard"}).then((data) => {
          //  console.log("data-->",data.data.data[0])
            if(data.data.status.result == 200){
                setDashboardDetails(data.data.data[0]);
            }
        
          });
      
    }, [])

    
 

  


    return (
        <>
            
            <div className="row course-main-box"  >

                  <div className="row all-main-box"  style={ {marginLeft: '0px',padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                       

                          <div className="col-12 col-md-3">
                          <DashboardBoxes
                                icon={0}
                                  title="Free Courses"
                                  number={kFormatter(dashboardDetails?.freeCourse)}
                                  onClick={() => {
                                    navigate('/trainee/courses/free-courses')
                                  }}
                                  {...props}
                              />
                          </div>



                          <div className="col-12 col-md-3">
                                            <DashboardBoxes
                                            icon={0}
                                                title="Your Subscription Courses"
                                                number={kFormatter(dashboardDetails?.subscriptionCourse)}
                                                onClick={() => {
                                                    navigate('/trainee/courses/subscription-courses')
                                                }}
                                                {...props}
                                            />
                                </div>



                         
                                <div className="col-12 col-md-3">
                                            <DashboardBoxes
                                            icon={0}
                                                title="Total Subscription Courses"
                                                number={kFormatter(dashboardDetails?.allSubscribedCourses)}
                                                onClick={() => {
                                                    navigate('/trainee/courses/total-subscription-courses')
                                                }}
                                                {...props}
                                            />
                                </div>
                      


                          <div className="col-12 col-md-3">
                          <DashboardBoxes
                                icon={0}
                                  title="Today Added Courses"
                                  number={dashboardDetails?.todayAddedCourseCount}
                                  onClick={() => {
                                      navigate('/trainee/courses/today-added-courses')
                                  }}
                                  {...props}
                              />
                          </div>
                        


                      <div className="col-12 col-md-3 mt-20">
                     
                      <DashboardBoxes
                                icon={0}
                                  title="Favourite Courses"
                                  buttonTitle = "View Details"
                                  number={dashboardDetails.favouriteCourses }
                                  onClick={() => {
                                    navigate('/trainee/courses/favourite-course')
                                  }}
                                  {...props}
                              />

                            
                     </div>


                   


                    <div className="col-12 col-md-3 mt-20">
                    <DashboardBoxes
                                icon={0}
                                        title="Top Ten Courses"
                                        buttonTitle = "View Details"
                                        number={10 }
                                        onClick={() => {
                                            navigate('/trainee/courses/top-ten-videos')
                                        }}
                                        {...props}
                                    />
                    </div>
                    {/*

                    <div className="col-12 col-md-3 mt-20">
                    <DashboardBoxes
                                icon={0}
                                        title="Trending Topic"
                                        buttonTitle = "View Details"
                                        number={0 }
                                        onClick={() => {
                                            navigate('/trainee/courses/trending-topics')
                                        }}
                                        {...props}
                                    />
                    </div>
                    */}

                 
                      </div>            
                      </div>    
                    

                      
   
         
  
  
             
            {/*props.titlesObj.showDashboard == 1 &&(
                  
            )

            */}
          


       
           

                                 


                    
       
        </>
    );
};


export default CourseDashboardView;
/*
const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}
    */

const DashGuruStatusTitle = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text={props.buttonTitle}  onClick={props.onClick}/>
        </div>
    )
}