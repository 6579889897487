import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import EyeIcon from '../../../../../components/svg/EyeIcon';
import useAccount from '../hooks/account.hook';
import NavLearnerProfile from '../../../../../components/navLearnerProfile';
import AccountPassword from '../../../../../components/input/accountPassword';

const LearnerAccountView = (props) => {
    const {
        showExistingPassword,
        ShowExistingPasswordOnClick,
        showNewPassword,
        ShowNewPasswordOnClick,
        showConfirmNewPassword,
        ShowConfirmNewPasswordOnClick
    } = useAccount();
    const formik = useFormik({
        initialValues: {
            existingPassword: 'Maybank Berhad',
            newPassword: 'Pardeep Mohan',
            confirmNewPassword: '1234 5687 1456 8799',
        },
        validationSchema: Yup.object({
            existingPassword: Yup.string().required('Please enter a valid existing password'),
            newPassword: Yup.string().required('Please enter a valid new password'),
            confirmNewPassword: Yup.string().required('Please enter a valid confirm new password'),
        }),
        onSubmit: (values, actions) => {
        },
    });
    return (
        <>
            {/* <Text
                type="H1"
                text="Settings"
            /> */}
          <div className='change-password mt-5 px-5 py-3 rounded-2'>
          
            <form onSubmit={formik.handleSubmit} >
                <div className='row'>
                    <div className='col-md-12'>
                    <div className="d-flex align-items-center mb-20">
                <Text type="H3" text="Change Password" className="font-weight-500 mr-24 mb-0" />
            </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="">
                            <AccountPassword
                                tertiary
                                label="Existing Password"
                                type={showExistingPassword ? 'text' : 'password'}
                                name="existingPassword"
                                placeholder=""
                                value={formik.values.existingPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.existingPassword && formik.errors.existingPassword ? (
                                    formik.errors.existingPassword
                                ) : null}
                                icon={
                                    <span onClick={ShowExistingPasswordOnClick}><EyeIcon visible={showExistingPassword} /></span>
                                }
                            />
                        </div>
                    </div>
                
                    <div className="col-12 col-md-4">
                        <div className="">
                            <AccountPassword
                                tertiary
                                label="New Password"
                                type={showNewPassword ? 'text' : 'password'}
                                name="newPassword"
                                placeholder=""
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.newPassword && formik.errors.newPassword ? (
                                    formik.errors.newPassword
                                ) : null}
                                icon={
                                    <span onClick={ShowNewPasswordOnClick}><EyeIcon visible={showNewPassword} /></span>
                                }
                            />
                        </div>
                    </div>
                    
                    <div className="col-12 col-md-4">
                        <div className="">
                            <AccountPassword
                                tertiary
                                label="Confirm New Password"
                                type={showConfirmNewPassword ? 'text' : 'password'}
                                name="confirmNewPassword"
                                placeholder="password "
                                value={formik.values.confirmNewPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                                    formik.errors.confirmNewPassword
                                ) : null}
                                icon={
                                    <span onClick={ShowConfirmNewPasswordOnClick}><EyeIcon visible={showConfirmNewPassword} /></span>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                    <div className="action-buttons-end my-1">
                    <Button
                        light
                        type="button"
                        text="Cancel"
                    />
                    <Button
                        type="submit"
                        text="Save"
                        className="btn-save ml-3"
                    />
                </div>
                    </div>
                
                </div>
            </form>
          </div>
            
        </>
    );
};

export default LearnerAccountView;

