import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/button';
import Text from "../../../../components/text";
import Icon from '../../../../components/icon';
import ProgressBarCard from '../../../../components/programBarCard';
import EditIcon from '../../../../components/svg/editIcon';
import Input from '../../../../components/input';
import Flex from '../../../../components/flex';
import Select from '../../../../components/input/select';
import Textarea from '../../../../components/input/textarea';
import Avatar from '../../../../components/avatar';
import AvatarPic from '../../../../assets/images/avatar_pic.jpg';
import useGuruUploadVideoSuccess from '../hooks/guruUploadVideoSuccess.hook';
import CameraIcon from '../../../../components/svg/cameraIcon';
import TickIcon from '../../../../components/svg/tickIcon';
import useHelper from "../../../../hooks/helpers.hook";
import {UploadComponent} from "../../uploadAttachments/view/UploadAttachmentsView";
import axios from "axios";
import Image from "../../../../components/image";
import {useNavigate, useParams} from "react-router-dom";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import StaticVideoPlayer from "../../../../components/videoPlayers/staticVideoPlayer";
import {toast} from "react-toastify";

const GuruUploadVideoSuccessView = (props) => {
    const navigate = useNavigate();
    const {post} = useHttpHandler();
    const {videoId} = useParams()
    const {getFileExtension, getFileSize} = useHelper()
    const {
        dropdownsData,
        userInfo,
        videoDetails,
        uploadedImages,
        setUploadedImages,
        uploadedVideosInfo,
        setUploadedVideosInfo
    } = props?.guruUploadVideoHook

    const [selectedFile, setSelectedFile] = useState(null);

    const {changeVideo, handleChangeVideo} = useGuruUploadVideoSuccess();
    const previewProfileImage = userInfo?.pic ? process.env.REACT_APP_IMG_URL + userInfo?.pic : AvatarPic
    const [subCategories, setSubCategories] = useState(null)

    let fileFormat = ''
    let fileSize = ''
    let videoName = ''

    const canChangeVideo = videoDetails[0]?.likescount == 0 && videoDetails[0]?.watched == 0 && videoDetails[0]?.videostatus == 'Pending'

    if (videoDetails) {
        fileFormat = getFileExtension(videoDetails[0]?.videofilename)
        fileSize = getFileSize(videoDetails[0]?.videosize)
        videoName = videoDetails[0]?.videofilename
    }

    const formik = useFormik({
        initialValues: {
            title: videoDetails[0]?.title ? videoDetails[0]?.title : '',
            category: videoDetails[0]?.videocategoryid ? videoDetails[0]?.videocategoryid : '',
            studyLevel: videoDetails[0]?.videolevelid ? videoDetails[0]?.videolevelid : '',
            subCategory: videoDetails[0]?.subcatid ? videoDetails[0]?.subcatid : 0,
            description: videoDetails[0]?.des ? videoDetails[0]?.des : '',
            sampleFile: [],
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Please enter a valid title'),
            category: Yup.string().required('Please enter a valid category'),
            studyLevel: Yup.string().required('Please enter a valid study level'),
            subCategory: Yup.string(),
            description: Yup.string().required('Please enter a valid description'),
            sampleFile: Yup.array().min(1,'Please Upload Thumbnail').required('Please Upload Thumbnail'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)

            let postData = {
                title: values.title,
                category_id: values.category,
                sub_category_id: parseInt(values.subCategory),
                studylevelid: values.studyLevel,
                description: values.description,
                video_id: videoId,
                thumbnail1: uploadedImages && uploadedImages[0]?.newfile_name ? uploadedImages[0]?.newfile_name : '',
                thumbnail2: uploadedImages && uploadedImages[1]?.newfile_name ? uploadedImages[1]?.newfile_name : '',
                thumbnail3: uploadedImages && uploadedImages[2]?.newfile_name ? uploadedImages[2]?.newfile_name : '',
                languageid: 1,
                roletoview: 207,
                videosection: 1,
                isguidelist: 0,
                priority: 0,
            }

            post('addVideoDetails', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    navigate('/instructor/my-video')
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        },
    });

    const getSubCategories = (e) => {
        formik.setFieldValue('category', e.target.value)
        getSubCategoriesById(e.target.value)
    }

    const getSubCategoriesById = (catId) => {
        let subCategoryOptions = []
        if (dropdownsData?.subCategoriesOptions) {
            dropdownsData?.subCategoriesOptions.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatid, label: item.subCatName})
                }
            })
        }

        setSubCategories(subCategoryOptions)
    }

    useEffect(() => {
        getSubCategoriesById(videoDetails[0]?.videocategoryid)
    }, [])

    useEffect(() => {
        if (formik.values.sampleFile.length > 0) {
            let formData = new FormData();
            formik?.values?.sampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructor_upload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    setUploadedImages(data)
                }
            })
                .catch((error) => {
                });
        }
    }, [formik.values.sampleFile])
    return (
        <>
            <Text
                type="H1"
                text="Course Content"
                className="mt-40"
            />
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="video-details-main">
                            <div className="video-details-card">
                                <div className="row">
                                    <div className="col-12">
                                        <Flex>
                                            {canChangeVideo ? (
                                                <UploadVideoPreview
                                                    text="Drag and Drop Files"
                                                    setUploadedFilesInfo={setUploadedVideosInfo}

                                                    videoname={videoName}
                                                    filesize={fileSize}
                                                    fileformat={fileFormat}
                                                    videoId={videoId}
                                                    getVideoDetailsById={props?.guruUploadVideoHook?.getVideoDetailsById}
                                                />
                                            ) : (
                                                <div className="filesdetails">
                                                    <Text type="H4" className="mb-10 text-white-half" text={videoName}/>
                                                    <Text type="PARAGRAPH" className="my-0 font-size-12 text-white-half"
                                                          text={`File size: ${fileSize}`}/>
                                                    <Text type="PARAGRAPH"
                                                          className="mt-0 mb-10 font-size-12 text-white-half"
                                                          text={`File format: ${fileFormat}`}/>

                                                </div>
                                            )}
                                        </Flex>
                                    </div>

                                </div>
                            </div>
                            <div className="">
                                <div className="formBox">
                                    <Input
                                        tertiary
                                        label="Topic Title"
                                        type="text"
                                        name="title"
                                        placeholder="e.g Introduction to Investment"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        mandatory
                                        errormessage={formik.touched.title && formik.errors.title ? (
                                            formik.errors.title
                                        ) : null}
                                    />
                                </div>
                                <div className="formBox">
                                    <Input
                                        tertiary
                                        label="Subtopic Title"
                                        type="text"
                                        name="title"
                                        placeholder="e.g Introduction to Investment"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        mandatory
                                        errormessage={formik.touched.title && formik.errors.title ? (
                                            formik.errors.title
                                        ) : null}
                                    />
                                </div>
                                <div className="row">
                                   {/* <div className="col-12 col-md-6">
                                        <div className="formBox">
                                            <Select
                                                tertiary
                                                label="Category"
                                                name="category"
                                                placeholder="Select Category"
                                                value={formik.values.category}
                                                onChange={getSubCategories}
                                                onBlur={formik.handleBlur}
                                                mandatory
                                                options={dropdownsData?.categoriesOptions}
                                                errormessage={formik.touched.category && formik.errors.category ? (
                                                    formik.errors.category
                                                ) : null}
                                            />
                                        </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                        <div className="formBox">
                                            <Select
                                                tertiary
                                                label="Sub Category"
                                                name="subCategory"
                                                placeholder="Select Level"
                                                value={formik.values.subCategory}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                options={subCategories}
                                                errormessage={formik.touched.subCategory && formik.errors.subCategory ? (
                                                    formik.errors.subCategory
                                                ) : null}
                                            />
                                        </div>
                                    </div>*/}
                                    <div className="col-12 col-md-12">
                                        <div className="formBox">
                                            <Select
                                                tertiary
                                                label="Priority Level"
                                                name="studyLevel"
                                                placeholder="Select Level"
                                                value={formik.values.studyLevel}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                mandatory
                                                options={dropdownsData?.studyLevelOptions}
                                                errormessage={formik.touched.studyLevel && formik.errors.studyLevel ? (
                                                    formik.errors.studyLevel
                                                ) : null}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-12 col-md-12">
                                        <div className="formBox">
                                            <div className="form-field form-field-tertiary">
                                                <label className="form-label mb-10">Thumbnail <span
                                                    className="text-danger">*</span></label>
                                                <Text
                                                    type="PARAGRAPH"
                                                    className="text-white-half"
                                                    text="Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention"
                                                />


                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        <UploadComponent
                                                            setFieldValue={formik.setFieldValue}
                                                            maxFilesUpload="3"
                                                            hideFooter
                                                            hideButton
                                                            thumbNailWrap
                                                            onFileSelectSuccess={(file) => setSelectedFile(file)}
                                                            onFileSelectError={({ error }) => alert(error)}
                                                        />
                                                        {formik.touched.sampleFile && formik.errors.sampleFile ?
                                                            (
                                                                <div className="message-error">
                                                                    <small>{formik.errors.sampleFile}</small></div>
                                                            ) : null
                                                        }
                                                    </div>

                                                    {uploadedImages && uploadedImages.length > 0 &&
                                                    uploadedImages?.map((file, i) => (
                                                        <div key={i} className="col-12 col-md-4">
                                                            {file?.newfile_name && (
                                                                <Image
                                                                    src={process.env.REACT_APP_IMG_URL + file?.newfile_name}
                                                                />
                                                            )}
                                                        </div>
                                                    ))
                                                    }
                                                    {!uploadedImages &&
                                                    (<>
                                                        <div className="col-12 col-md-4">
                                                            <div className="thumbimgempty"></div>
                                                        </div>
                                                        <div className="col-12 col-md-4">
                                                            <div className="thumbimgempty"></div>
                                                        </div>
                                                    </>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="formBox">
                                            <Textarea
                                                tertiary
                                                label="Description"
                                                type="text"
                                                name="description"
                                                placeholder="Write your description here..."

                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                mandatory
                                                rows="5"
                                                errormessage={formik.touched.description && formik.errors.description ? (
                                                    formik.errors.description
                                                ) : null}
                                            >
                                            </Textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="video-preview-details-wrapper">
                            <Text type="H4" text="Preview" className="text-light"/>
                            <StaticVideoPlayer videoDetails={videoDetails[0]}/>
                            <div className="video-details-info">
                                <Avatar
                                    src={previewProfileImage}
                                    alt={userInfo.fullname}
                                />
                                <div className="video-details-info-content">
                                    <Text
                                        type="H4"
                                        text={formik.values.title ? formik.values.title : 'Title'}
                                        className="video-details-info-content-title"
                                    />
                                    <Text
                                        type="PARAGRAPH"
                                        text={`by ${userInfo.fullname}`}
                                        className="video-details-info-content-by text-cyan"
                                    />
                                    <Text
                                        type="PARAGRAPH"
                                        className="text-cyan"
                                        text={formik.values.description ? formik.values.description : 'Description '}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-buttons-end">
                    <Button
                        light
                        type="button"
                        text="Cancel"
                        onClick={()=>{navigate('/instructor/my-video')}}
                    />
                    <Button
                        type="submit"
                        text="Save"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </div>
            </form>
        </>
    );
};

const ThumbnailPreviews = props => {
    const [files, setFiles] = useState([]);
    const [fileSelectItem, setFileSelectItem] = useState(false);
    const onFileSelect = () => {
        setFileSelectItem(state => !state)
    }
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files?.map((file, index) => (
        <div key={file.name} className="col-12 col-md-4">
            <div
                className={`thumbnail-upload-wrap ${fileSelectItem ? 'selected' : ''} ${file.preview ? 'thumbnail-upload-added' : ''}`}
                onClick={onFileSelect}
            >
                <img
                    src={file.preview}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview)
                    }}
                    className="img-fluid"
                />
                {fileSelectItem &&
                <div className="thumbnail-select-wrap">
                    <div className="thumbnail-select-icon">
                        <Icon
                            icon={<TickIcon/>}
                            small
                        />
                    </div>
                </div>
                }
            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <>
            <div className="thumbnail-wrapper">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="thumbnail-upload-file-wrp">
                            <div className="upload-content" {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className="icon">
                                    <CameraIcon/>
                                </div>
                                <Text type="SPAN" text="Upload Thumbnail"/>
                            </div>
                        </div>
                    </div>
                    {thumbs}
                </div>
            </div>
        </>
    );
}
const UploadVideoPreview = props => {
    const {post} = useHttpHandler()
    const {getFileExtension, getFileSize} = useHelper()
    const apiUrl = process.env.REACT_APP_API_URL + '/videoUpload'
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState(null);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'video/*': ['.mp4', '.mpeg'],
        },
        onDrop: (acceptedFiles) => {
            props.setUploadedFilesInfo(null)
            acceptedFiles.forEach((file) => {

                setFiles(file);
                const data = new FormData();
                data.append('file', file);

                axios.post(apiUrl, data, {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        let percentage = Math.floor((loaded * 100) / total);
                        if (percentage <= 100) {
                            setProgress(percentage)
                        }
                    }
                }).then((responseData) => {
                    console.log('responseData', responseData)
                    let postData = {
                        filename: responseData.data.data[0].newfile_name,
                        file_size: responseData.data.data[0].size,
                        file_type: responseData.data.data[0].mimetype,
                        duration: Math.round(Math.ceil(responseData.data.data[0].duration)),
                        video_id: props?.videoId
                    }

                    post('update_uploaded_video', postData).then((res) => {
                        const status = res.data.status
                        const data = res.data.data
                        if (status.result == '200') {
                            console.log('data', data)
                            props?.getVideoDetailsById()

                        } else {
                            toast.error(status.msg)
                        }
                    }).catch(err => {
                    })


                }).catch((error) => {
                    console.log("ERROR " + error.message)
                });
            });
        }
    });

    const fileSize = files ? getFileSize(files?.size) : ''
    const fileFormat = files ? getFileExtension(files?.name) : ''

    const finalVideoName = files?.name ? files?.name : props.videoname
    const finalFileFormat = fileFormat ? fileFormat : props.fileformat
    const finalFileSize = fileSize ? fileSize : props.filesize

    return (
        <>
            <div className="thumbnail-main-wrapper">
                <div className="thumbnail-wrapper">
                    <div className="thumbnail-upload-file-wrp">
                        <div className="upload-content" {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="icon">
                                <CameraIcon/>
                            </div>
                            <Text type="SPAN" text={props.text}/>
                            {!progress && (
                                <Button
                                    className="change-video-btn"
                                    linkPrimary
                                    type="button"
                                    style={{fontSize: '1rem', textDecoration: 'none'}}
                                    text="Change video"
                                    iconLeft={
                                        <Icon square small
                                              icon={<EditIcon width="12" height="14"/>}/>
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="filesdetails">
                    <Text type="H4" className="mb-10 text-white-half" text={finalVideoName}/>
                    <Text type="PARAGRAPH" className="my-0 font-size-12 text-white-half"
                          text={`File size: ${finalFileSize}`}/>
                    <Text type="PARAGRAPH" className="mt-0 mb-10 font-size-12 text-white-half"
                          text={`File format: ${finalFileFormat}`}/>

                    {progress && (
                        <ProgressBarCard width={progress}/>
                    )}

                </div>


            </div>
        </>
    );
}

export default GuruUploadVideoSuccessView;
