import React from 'react';

const ClockIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.37479 9.75044C7.46063 9.75057 9.25649 8.27818 9.66524 6.23279C10.074 4.18739 8.98194 2.13789 7.05626 1.33636C5.13057 0.534828 2.90684 1.20421 1.7436 2.93556C0.580351 4.66691 0.801126 6.97869 2.27104 8.45857C3.09228 9.28537 4.20945 9.75037 5.37479 9.75044Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.69531 4.22754V6.36004H6.73719" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>        
    );
};

export default ClockIcon;

ClockIcon.defaultProps = {
    width: '11',
    height: '11',
}