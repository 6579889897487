import React from 'react';

const AddressBookIcon = props => {
    return (
        <>
            <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3V13C3 14.1046 3.89543 15 5 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H5C3.89543 1 3 1.89543 3 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.55108 12.3992C2.21926 12.6471 2.15126 13.1171 2.39919 13.4489C2.64713 13.7807 3.11711 13.8487 3.44892 13.6008L2.55108 12.3992ZM14.5511 13.6008C14.8829 13.8487 15.3529 13.7807 15.6008 13.4489C15.8487 13.1171 15.7807 12.6471 15.4489 12.3992L14.5511 13.6008ZM1 4.25C0.585786 4.25 0.25 4.58579 0.25 5C0.25 5.41421 0.585786 5.75 1 5.75V4.25ZM3 5.75C3.41421 5.75 3.75 5.41421 3.75 5C3.75 4.58579 3.41421 4.25 3 4.25V5.75ZM1 7.25C0.585786 7.25 0.25 7.58579 0.25 8C0.25 8.41421 0.585786 8.75 1 8.75V7.25ZM3 8.75C3.41421 8.75 3.75 8.41421 3.75 8C3.75 7.58579 3.41421 7.25 3 7.25V8.75ZM1 10.25C0.585786 10.25 0.25 10.5858 0.25 11C0.25 11.4142 0.585786 11.75 1 11.75V10.25ZM3 11.75C3.41421 11.75 3.75 11.4142 3.75 11C3.75 10.5858 3.41421 10.25 3 10.25V11.75ZM3.44892 13.6008C6.7409 11.141 11.2591 11.141 14.5511 13.6008L15.4489 12.3992C11.6245 9.54158 6.37551 9.54158 2.55108 12.3992L3.44892 13.6008ZM1 5.75H3V4.25H1V5.75ZM1 8.75H3V7.25H1V8.75ZM1 11.75H3V10.25H1V11.75Z" fill="currentColor"/>
            </svg>
        </>
    );
};

export default AddressBookIcon;

AddressBookIcon.defaultProps = {
    width: '16',
    height: '16'
}