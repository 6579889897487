import React,{useEffect, useState} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import CourseContentPageView from './views/totalViewsPageView';
import useHome from '../../Home/hooks/home.hook';
import Loader from '../../../components/loader';
import useGuruDashboard from '../dashboard/hooks/guruDashboard.hook';
import TotalLikesPageView from './views/totalViewsPageView';
import TotalViewsPageView from './views/totalViewsPageView';
import TotalViewAndLikesList from '../monetization/myReport/components/TotalViewAndLikesList';



const TotalViews =(props)=>{
  const guruUploadVideoHook = useGuruUploadVideo()
  const commonHook = useGetTableList();
  const guruDashboardHook = useGuruDashboard();
  useEffect(()=>{
      commonHook.setKeys();
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "g";
      DEFAULT_FILTERS.coursetype = 1;
      commonHook.getList(DEFAULT_FILTERS)
      guruUploadVideoHook.getDropDowns()
  },[])

  
 
 


  if (commonHook?.loading) {
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
} else {
  return (
    <>
    <LayoutGuru {...props}>
    <TotalViewAndLikesList  {...props}  {...commonHook} guruDashboardHook={guruDashboardHook} />
    </LayoutGuru>
    </>
)
}
  
}

export default TotalViews;
