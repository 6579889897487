import React from 'react';

const GmailIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3876_5270)">
            <path d="M45.9834 11.5127L42.9192 42.2823H6.1152L3.64648 12.1082L24.5172 23.7934L45.9834 11.5127Z" fill="#F2F2F2"/>
            <path d="M44.9435 5.7168L24.5167 24.9052L4.08984 5.7168H24.5167H44.9435Z" fill="#F2F2F2"/>
            <path d="M6.11558 14.5776V42.283H2.78061C1.5312 42.283 0.517578 41.2695 0.517578 40.02V10.4971L4.17383 10.5967L6.11558 14.5776Z" fill="#F14336"/>
            <path d="M48.5179 10.497V40.02C48.5179 41.2694 47.5043 42.283 46.2537 42.283H42.9199V14.5775L44.766 10.2158L48.5179 10.497Z" fill="#D32E2A"/>
            <path d="M48.5176 7.97983V10.4965L42.9196 14.5771L24.5176 27.9897L6.11558 14.5771L0.517578 10.4965V7.97983C0.517578 6.73042 1.5312 5.7168 2.78061 5.7168H4.09077L24.5176 20.6051L44.9444 5.7168H46.2533C47.504 5.7168 48.5176 6.73033 48.5176 7.97983Z" fill="#F14336"/>
            <path d="M6.11558 14.5776L0.517578 13.7097V10.4971L6.11558 14.5776Z" fill="#D32E2A"/>
            </g>
            <defs>
            <clipPath id="clip0_3876_5270">
            <rect width="48" height="48" fill="white" transform="translate(0.517578)"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export default GmailIcon;

GmailIcon.defaultProps = {
    width: '49',
    height: '48'
}