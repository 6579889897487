

import React, { useEffect, useState } from "react";
import VideoCard from "../videocard";
import './instructorCard.scss';
import TImage001 from '../../assets/img/logo.svg';
import Avatar from '../avatar'
import Text from "../text";
import useHelper from "../../hooks/helpers.hook";
import { NavLink, useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { AiFillLinkedin, AiOutlineHeart } from "react-icons/ai";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Button from "../button";
import ChatTimerCard from "../chatTimerCard";

const TrainerChatEndBox = props => {
  const navigate = useNavigate();
    const [status,setStatus] = useState(0);
    const [conversationCharge,setConversationCharge] = useState(0);
    const [walletBalance,setWalletBalance] = useState(0);
    /*const timerCallBack = () =>{
      props?._props.promiseServiceNonLoading({"uri":"chatTimerUpdate","sessionId":props?._props?.sessionId,"chatRoom":props?._props?.roomName,"instructorId":props.instructorID}).then((data) => {
            console.log("data.data.status",data.data.status.DeductionAmount);
            setWalletBalance(data.data.status.balance);
            setConversationCharge(data.data.status.DeductionAmount);

            if(data.data.status.balance <= props?.props?.chatCharges){

            }
      });
    }
    */
  
     
    

    return(
        <>
        
        <div class="member">
            

            
              <img src={process.env.REACT_APP_IMG_URL + props?.props?.pic} class="img-fluid-height-150 mt-10" alt="" />
              <div class="member-content">
                <h4>{props?.props?.preffered_name} {props?.props?.uname}</h4>
                
                <span>{props?.props?.catName}</span>
                <p>
                  {props?.props?.about_yourself}
                </p>

         

<label className="form-label mb-10">Per Minute : ${props?.props?.tillAmount / props?.props?.Minutes } </label>
<br></br>

                <label className="form-label mb-10">Number of Minutes : ${props?.props?.Minutes} </label>
                <br></br>

                <label className="form-label mb-10">Your Conversation Charge Till Now : {props?.props?.tillAmount}</label>
                <br></br>
                
              
          
              
              </div>
              
            </div>

           
        
        </>
    )
}

export default TrainerChatEndBox;
