import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyNetworkView from "./view/MyNetworkView";
import './myNetwork.scss';
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useMyNetwork from "./hooks/myNetwork.hook";
import useHelper from "../../../../hooks/helpers.hook";
import Loader from "../../../../components/loader";
import GlobalInfoModal from "../../../../components/globalInfoModal";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const MyNetworkMainView = props => {
    const {loading} = props

    if (loading) {
        return <Loader/>
    } else {
        return <MyNetworkView {...props} />
    }
}

const MyNetwork = props => {
    const navigate = useNavigate()
    const userInfo = useSelector(state => state.app.userInfo)
    const [isModal, setIsModal] = useState(!userInfo?.is_profile_completed)
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const myNetworkHook = useMyNetwork()
    const {isPremiumMember} = useHelper()
    useEffect(() => {
        if (isPremiumMember()) {
            myNetworkHook.getMemberNetworkData()
        }
    }, [])
    return (
        <>
            <LayoutPanel {...props}>
                {subscribePremiumModalHook.isPremiumSubscribeModal && (
                    <GlobalInfoModal
                        smallModal={true}
                        title={props?.text?.BECOME_SUBSCRIBER_MODAL_CONTENT}
                        canClose={true}
                        setIsModal={subscribePremiumModalHook.setIsPaymentUnderProcess}
                        onClick={subscribePremiumModalHook.goToPayment}
                        buttonText={props?.text?.SUBSCRIBE_NOW}
                        navigateBack
                    />
                )}

                {isModal && (
                    <GlobalInfoModal
                        title={props?.text?.IN_ORDER_TO_RECEIVE_COMMISSION}
                        buttonText={props?.text?.FILL_IN_EXTRA_DETAIL}
                        canClose={true}
                        smallModal
                        onClick={() => {
                            navigate('/trainee/personal-information')
                        }}
                        setIsModal={setIsModal}
                    />
                )}

                <MyNetworkMainView {...props} {...myNetworkHook} userInfo={userInfo}/>


            </LayoutPanel>
        </>
    )
}

export default MyNetwork;
