import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../../components/button'
import CourseCard from '../../../../components/courseCard'

import { useMediaQuery } from 'react-responsive';
import useHelper from "../../../../hooks/helpers.hook";

import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
/*import Select from '../../../../components/input/select';*/

import TrainerCategoryBar from '../../../../components/trainerCategoryBar';
import GetInstructors from '../../../../components/getInstructors';
import ChatOnlineStatus from '../../../../components/chatOnlineStaus';
import ChatMsgContainer from '../../../../components/chatMsgContainer';
import { useSelector } from 'react-redux';


const ChatHistoryView = (props) => {
 
    const customData = props?.tableData?.customdata;
    const userInfo = useSelector(state => state.app.userInfo)
    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Chat History",
            link:"/trainee/chat-history",
        },
    ]

    

    




  return (
    
    <>
    
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
    <div className='row'>
        <div className='col-md-9'>
      <Text
        type="H1"
        text="Chat History"
        className="mt-0 main_heading_color ml-3 mb-0"
    />
    </div>

  
    </div>  
             <div className='row'>
                <div className='col-md-3 pr-0'>
                <ChatOnlineStatus  {...props} userInfo={userInfo}  commonHook={props.commonHook}/> 
                </div>

                <div className='col-md-9 pl-0'>
                <ChatMsgContainer {...props} userInfo={userInfo}  commonHook={props.commonHook} ishidden={1}/> 
                </div>

            </div>

       


    </>
  )
}

export default ChatHistoryView
    
CourseCard.defaultProps = {
    videoListOption: []
}
