import React, {useState} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from "../text";
import Input from "../input";
import Button from "../button";
import Flex from "../flex";
import ListStep from "../listStep";
import ShareVia from "../shareVia";
import {toast} from "react-toastify";
import {useNavigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import useHttpHandler from "../../hooks/httphandler.hook";
import useHelper from "../../hooks/helpers.hook";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const AffiliateProgram = props => {
    console.log(props)
    const userInfo = useSelector(state => state?.app?.userInfo)
    const  {post} = useHttpHandler()
    const navigate = useNavigate()
    const [isCopied, setIsCopied] = useState(false)
    const {text} = props
    const affiliateLink = process.env.REACT_APP_URL+'/referral/'+userInfo?.myrefcode

    const onCopy = ()=>{
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }

    const shareLater = ()=>{
        props?.updateShareStatus()
       /* if(userInfo?.is_profile_completed) {
            navigate('/trainee/dashboard')
        } else {
            navigate('/trainee/survey-question-1')
        }*/
    }

    const formik = useFormik({
        initialValues: {
            affiliateLink: affiliateLink,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values, actions) => {
        },
    });
    return(
        <>
            <Text
                type="H2"
                text={text?.AFFILIATE_PROGRAM}
                className=" text-center"
            />
            <Text
                type="PARAGRAPH"
                text={text?.INVITE_YOUR_FRIEND_TO_RECEIVE_AFFILIATE_INCOME}
                className="text-center"
            />
            <ListStep />
            <Text
                type="H3"
                text={text?.SHARE_THE_AFFILIATE_LINK}
                className="text-center"
            />
            <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        secondary
                        type="text"
                        name="affiliateLink"
                        placeholder=""
                        value={formik.values.affiliateLink}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        formControlClassName="text-center"
                    />
                </div>
                <Flex justifyContent="center" className="mb-48">
                    <CopyToClipboard onCopy={onCopy} text={affiliateLink}>
                    <Button
                        type="submit"
                        text={isCopied ? text?.COPIED_LINK : text?.COPY_LINK}
                        large
                    />
                    </CopyToClipboard>
                    <Button
                        type="button"
                        light
                        small
                        text={text?.SHARE_LATER}
                        onClick={shareLater}
                    />
                </Flex>
            </form>
            <ShareVia {...props} affiliateLink={affiliateLink}/>
        </>
    )
}

export default AffiliateProgram;
