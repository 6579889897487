import React, { useState } from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Button from "../button";
import Flex from "../flex";
import AkarArrowleftIcon from "../svg/akarArrowLeftIcon";
import Text from "../text";
import Input from "../input";
import PasswordResetSuccessful from "./passwordresetsuccessful";
import EyeIcon from "../svg/EyeIcon";
import PasswordResetFailed from "./passwordresetfailed";
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import useHttpHandler from "../../hooks/httphandler.hook";


const PasswordResetForm = props => {
    const {
        goBackToLogin,
        lowercaseRegex,
        uppercaseRegex,
        numericRegex,
        nonalphanumeric,
    } = useLogin()
    const {post} = useHttpHandler()
    const [showNewPassword, setShowNewPassword] = useState(false);
    const ShowNewPasswordClick = () => {
        setShowNewPassword(state => !state);
    }
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const ShowConfirmNewPasswordClick = () => {
        setShowConfirmNewPassword(state => !state);
    }
    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
    const [passwordFailed, setPasswordFailed] = useState(false);
    const updatePassword = (values)=> {
        const postData = {
            email: sessionStorage.getItem('email'),
            password: values.newpassword
        }
        post('change_password', postData).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setPasswordResetSuccessful(true);
            } else {
                setPasswordFailed(true);
            }
        })

    }
    const formik = useFormik({
        initialValues: {
            newpassword: '',
            confirmnewpassword: '',
        },
        validationSchema: Yup.object({
            newpassword: Yup.string()
                .min(6, 'Your password must be atleast 6 digits')
                .matches(uppercaseRegex, 'Atleast one capital letter required')
                .matches(lowercaseRegex, 'Atleast one small letter required')
                .matches(numericRegex, 'Atleast one number required')
                .matches(nonalphanumeric, 'Atleast one Special Character required')
                .required('Please enter a password'),
            confirmnewpassword: Yup.string().min(6, 'Your password must be atleast 6 digits').required('Please re-enter password').oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
        }),
        onSubmit: (values, actions) => {
            updatePassword(values)
        },
    });
    return(
        <>
            {passwordResetSuccessful ?
                <>
                    {passwordFailed ? <PasswordResetFailed /> : <PasswordResetSuccessful goBackToLogin={goBackToLogin} />}
                </>
                :
                <>
                    <Text type="H2" text="Set New Password" className="mb-40 text-center" />
                    <Text type="PARAGRAPH" text="Your new password must be different than previously used password" className="col-12 col-md-8 mx-auto mb-40 text-center" />
                    <form onSubmit={formik.handleSubmit}>
                        <div className="formBox">
                            <Input
                                label="New Password"
                                type={showNewPassword ? 'text' : 'password'}
                                name="newpassword"
                                placeholder="********"
                                value={formik.values.newpassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errormessage={formik.touched.newpassword && formik.errors.newpassword ? (
                                    formik.errors.newpassword
                                ) : null}
                                icon={
                                    <span onClick={ShowNewPasswordClick}><EyeIcon visible={showNewPassword} /></span>
                                }
                            />
                        </div>
                        <div className="formBox">
                            <Input
                                label="Confirm New password"
                                type={showConfirmNewPassword ? 'text' : 'password'}
                                name="confirmnewpassword"
                                placeholder="********"
                                value={formik.values.confirmnewpassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errormessage={formik.touched.confirmnewpassword && formik.errors.confirmnewpassword ? (
                                    formik.errors.confirmnewpassword
                                ) : null}
                                icon={
                                    <span onClick={ShowConfirmNewPasswordClick}><EyeIcon visible={showConfirmNewPassword} /></span>
                                }
                            />
                        </div>
                        <Flex justifyContent="center" className="mb-48">
                            <Button
                                type="submit"
                                text="Change Password"
                                large
                            />
                        </Flex>
                    </form>
                    <Flex justifyContent="center">
                        <Button
                            linkLittle
                            text="Back to login"
                            iconLeft={<AkarArrowleftIcon />}
                            onClick={goBackToLogin }
                        />
                    </Flex>
                </>
            }
        </>
    )
}

export default PasswordResetForm;
