import React, {useEffect, useState} from 'react';
import Text from '../../../../components/text';
import Button from '../../../../components/button';
import Loader from "../../../../components/loader";
import useHelper from "../../../../hooks/helpers.hook";
import TopicVideoCard from "../../../../components/topicVideoCard";
import JoinGuru from '../../../../components/joinGuru';
import topicVideoPoster from "../../../../assets/images/topic-video-poster.jpg";
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import CategoryButtons, {SubCatButton} from "../../../../components/categoryButtons";
import Section from '../../../../components/section';
import { useFormik } from 'formik';
import Select from '../../../../components/input/select';
import * as Yup from 'yup';
import Breadcrumbs from '../../../../components/breadcrumbs';
import LikedVideoCard from '../../../../components/topicVideoCard/likedVideoCard';
import CourseSearch from '../../../../components/courseSearch';
import Pagination from '../../../../components/pagination';
import CategorySubcatModal from '../../../../components/courseSearch/categorySubcatModal';
import { DEFAULTFILTERS } from '../../../../constants/app.constants';

const LikedVideosList = (props) => {
    const navigate = useNavigate()
    const {getShortDescription} = useHelper()
    
    
    if (props.commonHook.loading) {
        return <Loader/>
    } else {
        if (props?.commonHook?.tableData.listData?.length > 0) {
           
              return(  
                props.commonHook?.tableData?.listData?.map((item, index) => {
             
                return (
                    <>
                   
                    <div className="col-12 col-md-4 mb-4">
                    <LikedVideoCard
                        secondary
                        data={item}
                        onClick={() => {
                            navigate('/trainee/favourite/my-learning/video-details/' + item.videoid)
                        }}
                    />


                </div>
                
          </>
                )
            }))

           
           
        

        }else{
        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No videos available"/></div>
        }
    }
}

const LikedVideoView = (props) => {
 

    const breadcrumbList = [
    ]

    const [getCategoryId,setCategoryId] = useState(0);
    const [getSubCategoryId,setSubCategoryId] = useState(0);
    const [getSearchName,setSearchName] = useState('');

    useEffect(() => {
        if(getCategoryId !=0  ){
        props?.commonHook.setkeys();
        DEFAULTFILTERS.uri = 'user_liked_videos';
        DEFAULTFILTERS.perpage = 6;
        DEFAULTFILTERS.category_id = getCategoryId;
        DEFAULTFILTERS.sub_category_id =getSubCategoryId;
        DEFAULTFILTERS.searchName = getSearchName;
        props?.commonHook.InnergetList(DEFAULTFILTERS)
        console.log("getCategoryId",getCategoryId,"getSubCategoryId",getSubCategoryId)
        }
    },[getCategoryId,getSubCategoryId,getSearchName])

    return (
        <>
              <Breadcrumbs  breadcrumbList={breadcrumbList}       />
                <Text
                    type="H1"
                    text="Favourite Videos"
                />
             
                    <CategorySubcatModal
                            commonHook={props.commonHook}
                            getCategoryId={getCategoryId}
                            getSubCategoryId={getSubCategoryId}
                            setCategoryId={setCategoryId}
                            setSubCategoryId={setSubCategoryId}
                            getSearchName={getSearchName}
                            setSearchName={setSearchName}
                    />


                    <div className="row">
                    
                            <LikedVideosList {...props} /> 
                            
                            <Pagination
                                totalPages={props?.commonHook.tableData?.totalPages}
                                currentPage={props?.commonHook.tableData?.currentPage}
                                totalRecords={props?.commonHook.tableData?.totalRecords}
                                nextPage={props.commonHook.nextPage}
                                previousPage={props.commonHook.previousPage}
                                perPage={5}
                                />
                    
                    </div>
            
            
        </>
    );
};

export default LikedVideoView;

