import { useNavigate } from "react-router-dom";
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import {useState} from "react";

const useBankDetails = () => {
    const [loading, setLoading] = useState(false)
    const [bankDetails, setBankDetails] = useState(null)
    const navigate = useNavigate()
    const {post} = useHttpHandler()
    const goToEditBankDetails = () => {
        navigate('edit-bank-details');
    }
    const goToBankDetails = () => {
        navigate('../bank-details');
    }
    const getBankDetails = ()=> {
        setLoading(true)
        post('user_bankdetailsget').then((res) => {
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setBankDetails(data)
            } else {
                //toast.error(status.msg)
            }
        }).catch(err => {setLoading(false);})
    }
    return {
        goToEditBankDetails,
        goToBankDetails,
        bankDetails,
        getBankDetails,
        loading
    }

}
export default useBankDetails
