import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import useChatHook from "../chat/hooks/chat.hook";
import LayoutPanel from "../../../components/layoutPanel";
import MembersDashboardView from "./view/membersDashboardView";



const MembersDashboard = props => {

    const userInfo = useSelector(state => state.app.userInfo)
    
    return (
        <>
              <LayoutPanel {...props}  footer>
                
                <MembersDashboardView {...props} userInfo={userInfo}   />
            </LayoutPanel>
        </>
    )
      
}

export default MembersDashboard;
