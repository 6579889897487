import {useFormik} from 'formik';
import * as Yup from 'yup';
import Loader from '../../../../components/loader';
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import { useEffect } from 'react';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';
import Pagination from '../../../../components/pagination';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import useHelper from '../../../../hooks/helpers.hook';
import editImg from '../../../../assets/img/Edit.png'
import viewImg from '../../../../assets/img/Eye.png'
import { CgNametag } from 'react-icons/cg';
import Text from '../../../../components/text';

const ViewQuizView = (props) => {
    const {formatDateMMMDDYYYY} = useHelper();
    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'iGetCourseQuizQuestions';
        DEFAULT_FILTERS.moduleId =props.selectedData.srno;
        DEFAULT_FILTERS.perpage = 2
        commonHook.getList(DEFAULT_FILTERS)
      
    },[])



    if (commonHook?.loading) {
        return (
            <>
            
            
              
            </>
        )
    } else {
  
    return (
        <>
      <div>
        
        <Table
                thead={
                    <tr>
                        <th role="columnheader">Srno</th>
                        <th role="columnheader">Question</th>
                        <th role="columnheader">Option's</th>
                        <th role="columnheader">Correct Answer</th>
                        <th role="columnheader">No Of Participate</th>
                        <th role="columnheader">Created On</th>
                        <th role="columnheader">Action</th>
                        
                    </tr>
                }
                tbody={
                    <>
                  
                        {commonHook?.tableData?.listData?.map(function (item, index) {
                            return (
                         
                                <tr role="row">
                                <td role="cell">{index + 1}</td>
                                <td role="cell" className='table-quiz-question-colm'> <Text type="PARAGRAPH" text={item.question} className="dgsc-subtitle"/></td>
                                <td role="cell">
                                    {commonHook?.tableData?.customdata?.map(function (citem, cindex) {

                                            if(citem.questionId == item.srno){
                                                
                                                return(
                                                  <>
                                                  <div className='mt-10'>
                                                  { cindex + 1 + ") " + citem.optionValue}
                                                  </div>
                                                  </>  

                                                )

                                            }

                                    })
                                    }
                                   

                                </td>
                                <td role="cell">{item.correct_answer}</td>
                                <td role="cell">{item.replied} 
                                
                                <Button
                                toolTip="View Quiz"
                                link
                                text={
                                    <img src={viewImg}  alt="copy" className='icon-25' />
                                }
                                style={{margin:'0px',padding:'1px'}}
                                onClick={() => {

                                    props.setQuestionid(item.srno)
                                     props.callViewReplies();
                                    
                                    
                                }
                                }
                                type="button"
                                /></td>
                                
                                <td role="cell">{formatDateMMMDDYYYY(item.updated_on)}</td>
                                <td role="cell">


                                <Button
                                  toolTip="Add Quiz"
                                link
                                text={
                                    <img src={editImg}  alt="copy" className='icon-25' />
                                }
                                style={{margin:'0px',padding:'1px'}}
                                onClick={() => {

                                    
                                }
                                }
                                type="button"
                                />

                                



                                </td>
                                
                            </tr>
                            
                            )
                        })}
                   
                    </>
                }
            />
            <Pagination
                totalPages={commonHook?.tableData?.totalPages}
                currentPage={commonHook?.tableData?.currentPage}
                totalRecords={commonHook?.tableData?.totalRecords}
                nextPage={commonHook?.nextPage}
                previousPage={commonHook?.previousPage}
                perPage={commonHook?.filterKeys.perpage}
            />

<div className="action-buttons-end ">
                     <Button
                        type="button"
                        text="Close"
                        onClick={()=>{ 

                            props.setisShowPopUP(false);


                        }}
                    />
                   
        </div>


      </div>


      

                </>


    )
  }

};

export default ViewQuizView;
