import React from 'react';
import {NavLink, useNavigate, useParams,} from "react-router-dom";
import './navVideoDetailsSidePanelLists.scss'
import Text from '../text';
import { GURU } from '../../constants/app.constants';

const NavVideoDetailsSidePanelLists = ({props, options, getAllVideosWatched,usertypeid}) => {

    const {videoId} = useParams()
    const navigate = useNavigate()
    
    const onClickNavItem = (video)=>{
        getAllVideosWatched()
    
        if(usertypeid === GURU){
            navigate('/instructor/course-video-details/' + +video.videoid)    
        }else{
            navigate('/trainee/my-learning/video-details/' + +video.videoid)
        }

        
    }

    const getCurrentClass= (item)=>{
        const isVideoActive = videoId == item.videoid
        let activeClass = ''
        /*if(status == 2) {
            activeClass = 'lesson-watched-done'
        } else if(status == 1) {
            activeClass = 'lesson-watched-half-way'
        } else if(status == 0) {
            activeClass = 'lesson-watch-current'
        }*/

        if(isVideoActive) {
            activeClass = 'lesson-watched-half-way'
        } else {
            if(item?.check_video_status == 2) {
                activeClass = 'lesson-watched-done'
            } else if(item?.check_video_status == 1) {
                activeClass = 'lesson-watch-current'
            } else if(item?.check_video_status == 0) {
                activeClass = ''
            }
        }




        return 'lesson-item-block ' + activeClass
    }
    return (
        <>
            <ul className="nav-lesson" style={{marginTop:'0px',marginLeft:'5px'}}>
                {options?.map((d, idx) => {
                    return (
                        <>
                            <li className="lesson-item" onClick={()=>{onClickNavItem(d)}}>
                                <div
                                    key={idx}
                                    className={getCurrentClass(d)}
                                >
                                    <div className="lesson-item-left">
                                        <Text
                                            type="SPAN"
                                            text={idx + 1}
                                            className="lesson-number"
                                        />
                                    </div>
                                    <div className="lesson-item-right">
                                        <Text type="SPAN" text={`Lesson ${idx + 1}`}/>
                                        <Text type="H6" text={d.title} className="lesson-title"/>
                                    </div>
                                </div>
                            </li>
                        </>
                    )
                })}
            </ul>
        </>
    );
};

export default NavVideoDetailsSidePanelLists;

NavVideoDetailsSidePanelLists.defaultProps = {
    options: []
}
