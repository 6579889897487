import React from 'react';
import './categeory.css';

import { BsArrowRight } from "react-icons/bs";
import { NavLink } from 'react-router-dom';

const CategeoryList = () => {
  return (
    <div>
    
    <NavLink to={'/trainee/member/categories-commission'} className="text-decoration-none"><a className="text-decoration-none">
    <span className="thumb-info custom-thumb-info-style-1 mb-4 pb-1 thumb-info-hide-wrapper-bg">
        <span className="thumb-info-wrapper m-0">
            <img src="" className="img-fluid" alt="" />
        </span>
        <span className="thumb-info-caption bg-color-secondary p-1 pt-5 pb-4">
            <span className="custom-thumb-info-title">
            <span className="custom-thumb-info-name font-weight-semibold text-color-dark text-4">INFORMATION TECHNOLOGY</span>
            <span className="custom-thumb-info-desc font-weight-light">₹300</span>
            </span>
            <span className="custom-arrow">
                <BsArrowRight  classNameName='right-icon'/>
            </span>
        </span>
    </span>
    </a></NavLink>
    
    </div>
  )
}

export default CategeoryList;
