import {useEffect, useState} from "react";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";

const useLearnerDashboard = () => {
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [dashboardDetails, setDashboardDetails] = useState(null)

    const getDashboardDetails = () => {
        setLoading(true)
        post('memebers_dashboard').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setDashboardDetails(data)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getDashboardDetails()
    }, [])


    return {
        loading,
        dashboardDetails,
        getDashboardDetails
    }

}
export default useLearnerDashboard


