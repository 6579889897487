import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import Modal from "../../../components/modal";
import ForgotPassword from "../../../components/forgotpassword";

import useLogin from "./hooks/login.hook";
import SetNewPassword from "../../../components/setNewPassword";
import {useLocation, useParams} from "react-router-dom";
import RegisterView from "./views/RegisterView";

const Register = props => {
    const location = useLocation()
    const loginHook = useLogin()


    useEffect(() => {

    }, [])

    return (
        <>
          
                <RegisterView {...props} {...loginHook} />

               
          
        </>
    )
}

export default Register;
