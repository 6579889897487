import React, {useEffect, useRef, useState} from 'react';
import Text from "../../components/text";
import Section from '../../components/section';

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import { Pagination } from "swiper";
import "swiper/css/pagination";


import AvatarPic from '../../assets/images/avatar_pic.jpg';
import topicVideoPoster from '../../assets/images/topic-video-poster.jpg'
import useHelper from "../../hooks/helpers.hook";
import Loader from "../../components/loader";
import useTop10Videos from "../../pages/trainee/dashboard/hooks/top10Videos.hook";
import {useNavigate, useSearchParams} from "react-router-dom";
import SwiperPrevIcon from '../../components/svg/swiperPrevIcon';
import SwiperNextIcon from '../../components/svg/swiperNextIcon';
import Flex from '../../components/flex';
import Button from '../../components/button';
import useLearnByTopics from '../../components/learnByTopics/hooks/learnByTopics.hook';
import CategeoryList from './index';

const CategoriesListSlide = (props) => {

    const navigate = useNavigate()
    const {getShortDescription} = useHelper()
    const trendingVideosHook = useTop10Videos()
    const swiperTrendingRef = useRef();

    useEffect(() => {
        trendingVideosHook.getTrendingVideos('get_today_added_course')
    }, [])

    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get('catId')
    const categoryName = searchParams.get('catName')
    
    
    const learnByTopicsHook = useLearnByTopics(categoryId, categoryName)
    if(props?.extraParams?.type == "get_today_added_course"){
     //console.log("date->",useHelper.formatDateYYYYMMDD(new Date()));
     
        learnByTopicsHook.filterKeys.fromdate = props?.extraParams?.from_date
    }else{

    }
    
    
        const [active, setActive] = useState({catId: 0, catName: 'All topics'});
        const showLoadMore = learnByTopicsHook?.videosList && Object.keys(learnByTopicsHook?.videosList).length >= 8 && learnByTopicsHook?.videosApiData?.totalRecords > Object.keys(learnByTopicsHook?.videosList).length


    if (trendingVideosHook?.loading) {
        return <Loader/>
    } else {
        if (trendingVideosHook?.videosList && Object.keys(trendingVideosHook?.videosList).length === 0) {
            return <></>

        } else {
            return (
                <>
                    <Section className="pb-0 overflow-outer rml-24">
                        <div className="section-head">
                            <Text
                                type="H3"
                                text="Categories Enrolled"
                                className="section-title ml-2 text-uppercase"
                            />
                            <Flex justifyContent="end">
                                <Button
                                    circlePrimary
                                    text=""
                                    icon={<SwiperPrevIcon width="11" height="18"/>}
                                    onClick={() => swiperTrendingRef.current?.slidePrev()}
                                />
                                <Button
                                    circlePrimary
                                    text=""
                                    icon={<SwiperNextIcon width="11" height="18"/>}
                                    onClick={() => swiperTrendingRef.current?.slideNext()}
                                />
                            </Flex>
                        </div>
                        <div className="slider-main max-slider">
                            <Swiper
                                spaceBetween={12}
                                slidesPerView={1}
                                onBeforeInit={(swiper) => {
                                    swiperTrendingRef.current = swiper;
                                }}
                                modules={[Pagination]}
                                pagination={false}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        pagination: false,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        pagination: false,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        pagination: false,
                                    },
                                }}
                            >
                                {trendingVideosHook?.videosList && trendingVideosHook?.videosList?.map((item, index) => {
                                    const posterImage = item?.thumbnail ? process.env.REACT_APP_IMG_URL + item?.thumbnail : topicVideoPoster
                                    const avatarImage = item?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + item?.uploaded_by_pic : AvatarPic
                                    return (
                                        <SwiperSlide>
                                              <CategeoryList/>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </Section>
                </>
            );
        }
    }
};

export default CategoriesListSlide;

