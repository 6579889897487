import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import {Tree, TreeNode,} from "react-organizational-chart";
import MemberTreeUserCard from "../../../../../components/memberTreeUserCard";
import Select from "react-select";

const RecursiveComponent = ({list}) => {
    const hasChildren = list && list.length > 0
    if (hasChildren) {
        return (
            <>
                {list?.map((child, index) => {
                        if (child?.downline?.length > 0) {
                            return (
                                <TreeNode key={index} label={
                                    <MemberTreeUserCard
                                        src={child?.pic}
                                        text={child?.uname}
                                        code={child?.userid}
                                    />}>
                                    <RecursiveComponent list={child?.downline}/>
                                </TreeNode>
                            )
                        } else {
                            return (
                                <TreeNode key={index} label={
                                    <MemberTreeUserCard
                                        src={child?.pic}
                                        text={child?.uname}
                                        code={child?.userid}
                                    />}/>
                            )
                        }
                    }
                )}
            </>
        );
    }
}

const MyNetworkView = (props) => {
    const {memberNetworkData, userInfo, text} = props
    const formik = useFormik({
        initialValues: {
            memberTreeSearch: '',
        },
        validationSchema: Yup.object({
            memberTreeSearch: Yup.string().required('Please enter a valid downline member'),
        }),
        onSubmit: (values, actions) => {
            console.log(values?.memberTreeSearch)
            props?.getMemberNetworkData(values?.memberTreeSearch)
        },
    });
    return (
        <>
            <Text
                type="H1"
                text="Member Network"
            />
            <NavLearnerMember {...props}/>
            <Text
                type="H4"
                text="Member Tree"
                className="font-weight-500 mb-30"
            />
            <div className="member-tree-search">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <div className="row row-mrl-4 justify-content-center">
                            <div className="col-4 col-md-4">
                                <div className="mts-box">
                                    <Text type="PARAGRAPH" text={text?.TOTAL_DOWNLINE}/>
                                    <Text type="H3" text={memberNetworkData?.total_downline} className="mts-title"/>
                                </div>
                            </div>
                            <div className="col-4 col-md-4">
                                <div className="mts-box">
                                    <Text type="PARAGRAPH" text="Current Tier"/>
                                    <Text type="H3" text={memberNetworkData?.current_tier} className="mts-title"/>
                                </div>
                            </div>
                            <div className="col-4 col-md-4">
                                <div className="mts-box">
                                    <Text type="PARAGRAPH" text="Max Tier"/>
                                    <Text type="H3" text={memberNetworkData?.max_tier} className="mts-title"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="member-tree-search-form">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="formBox">
                                    <div className="row row-mrl-6">
                                        <div className="col-12 col-md-8">
                                            {/*react select  here*/}
                                            {/*<Input
                                                secondary
                                                type="text"
                                                name="memberTreeSearch"
                                                placeholder="Search for your downline member"
                                                value={formik.values.memberTreeSearch}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                mandatory
                                                errormessage={formik.touched.memberTreeSearch && formik.errors.memberTreeSearch ? (
                                                    formik.errors.memberTreeSearch
                                                ) : null}
                                            />*/}
                                            <div className="form-field">
                                                <Select
                                                    className="form-select-container"
                                                    classNamePrefix="form-select-styled"
                                                    name="memberTreeSearch"
                                                    onChange={((e)=>{formik.setFieldValue('memberTreeSearch', e.value)})}
                                                    options={props?.memberList}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <Button
                                                type="submit"
                                                text={text?.SEARCH}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {memberNetworkData && (
                  <div className="row">
                  <div className="col-12">
                      <div className="member-tree-wrapper">
                      <Tree
                          label={
                              <MemberTreeUserCard
                                  src={memberNetworkData?.user_info?.pic}
                                  text={memberNetworkData?.user_info?.uname}
                                  code={memberNetworkData?.user_info?.userid}
                              />}
                          lineBorderRadius="0px"
                          lineColor="white"
                          lineHeight="30px"
                          lineWidth="3px"
                      >
                          <RecursiveComponent list={memberNetworkData?.downline_data}/>
                      </Tree>
                      </div>
                  </div>
              </div>
            )}

{!memberNetworkData && (
     <div className="row">
     <div className="col-12">
            No Network Available
     </div>
 </div>
)
}


        </>
    );
};

export default MyNetworkView;
