import React,{useEffect} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import Loader from '../../../components/loader';
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import TopicCardEditPageView from './views/TopicCardEditPageView';

const TopicCardEdit = (props) => {
    const guruUploadVideoHook = useGuruUploadVideo()
  useEffect(()=>{
          guruUploadVideoHook.getDropDowns()
  },[])

  if (guruUploadVideoHook?.loading) {
    return (
        <>
           <LayoutGuru {...props}>
            <Loader />
            </LayoutGuru>
        </>
    )
} else {
  return (
    
        <LayoutGuru {...props}>
           <TopicCardEditPageView {...props} guruUploadVideoHook={guruUploadVideoHook}/>
        </LayoutGuru>
  )
}
}

export default TopicCardEdit;
