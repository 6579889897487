import React from "react";
import "./heroSection.css";
import AboutImg from "../../assets/img/about.jpg";

const AboutUsHeroSection = () => {
  return (
    <>
      <section class="section border-0 m-0">
        <div class="container position-relative z-index-3">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 pe-lg-5 mb-4 mb-lg-0">
              <img src={AboutImg} class="img-fluid box-shadow-3" />
            </div>
            <div class="col-lg-6">
              <h2 class="text-color-primary font-weight-medium positive-ls-3 fs-5 p-0">
                Course TSunami
              </h2>
              <h3 class="font-weight-bold text-transform-none text-9 line-height-2 mb-3">
                Company Overview
              </h3>

              <p class="mb-0">
                Course Tsunami is an innovative and transformative online
                education platform that is revolutionizing the way people learn.
                With a focus on accessibility, engagement, and community, we
                provide a diverse range of high-quality courses across various
                disciplines. Our cutting-edge technology and user-friendly
                interface create an immersive learning experience that empowers
                learners worldwide.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsHeroSection;
