import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useState} from "react";
import {toast} from "react-toastify";

const useFavouriteGuru = () => {

    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(false)
    const [gurusListLoading, setGurusListLoading] = useState(false)
    const [gurusList, setGurusList] = useState(null)

    const getFavouriteGurusByCatId = (catId)=> {
        setGurusListLoading(true)
        post('user_favoutitegurus', {category_id:catId}).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                setGurusList(data)
            } else {
                toast.error(status.msg)
            }

            setGurusListLoading(false)
        }).catch(err => setGurusListLoading(false))
    }

    return {
        loading,
        gurusListLoading,
        getFavouriteGurusByCatId,
        gurusList
    }

}
export default useFavouriteGuru
