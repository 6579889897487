import React, { useEffect } from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import useBankDetails from '../bankDetails/hooks/bankDetails.hook';
import useProfile from '../settings/profile/hooks/profile.hook';
import SubscribeDetailInformationView from './views/subscribeDetailInformationView'

const SubscribeDetailInformation = (props) => {
  const {text} = props;
  const bankDetailsHook = useBankDetails()
  const profileHook = useProfile();
  const isLoaded = !profileHook.loading && !bankDetailsHook?.loading
  useEffect(() => {
      profileHook?.getProfileDetails()
      //bankDetailsHook.getBankDetails()
  }, [])
  return (
    <div>
        <LayoutPanel {...props} footer>
      <SubscribeDetailInformationView {...props} {...bankDetailsHook} profileDetails={profileHook.profileDetails} />
      </LayoutPanel>
    </div>
  )
}

export default SubscribeDetailInformation
