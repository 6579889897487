import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyReportView from "./views/InstructorWalletView";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import Loader from "../../../../components/loader";
import useGetTableList from "../../../../hooks/getTableListCommonHook";
import {DEFAULTFILTERS} from "../../../../constants/app.constants";
import WalletView from "./views/InstructorWalletView";
import useVideosCommonHooks from "../../../instructor/createCourse/videosCommonHook";
import InstructorWalletView from "./views/InstructorWalletView";
import LayoutGuru from "../../../../components/layoutGuru";

const InstructorWallet = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const commonHook = useVideosCommonHooks();
    const [walletAmount,setWalletAmount] = useState(0);
    const [reloadPage,setReloadPage] = useState(0);

    useEffect(()=>{
        commonHook.setkeys();
        DEFAULTFILTERS.uri = 'fetchInstructorTotalPaidMiuntes';
        DEFAULTFILTERS.type = 4;
        commonHook.getList(DEFAULTFILTERS)
    },[reloadPage]);

    useEffect(()=>{
        console.log("commonHook.tableData?.customdata?.length",commonHook.tableData.totalRecords)
        if(commonHook.tableData?.totalRecords >=1){
            console.log("commonHook.tableData?.customdata?.length--->",commonHook.tableData?.customdata)
        setWalletAmount(commonHook.tableData?.customdata.chatCharges)
        }
    },[commonHook.loading]);

    


    if (commonHook.loading) {
        return (
            <>
                <LayoutGuru {...props}>
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }else{
    return (
        <>
            <LayoutGuru {...props}>
                <InstructorWalletView {...props} {...commonHook} walletAmount={walletAmount} setReloadPage={setReloadPage}/>
            </LayoutGuru>
        </>
    )
    }
}   

export default InstructorWallet;
