import React from "react";
import Layout from "../../components/layout";
import Loader from "../../components/loader";
import useHome from "../Home/hooks/home.hook";
import WaitingListPageView from "./views/waitingListPageView";




const WaitingListPage = (props) => {
    const homeHook = useHome()
    if (homeHook?.loading) {
        return (
            <>
                <Layout {...props}>
                    <Loader/>
                </Layout>
            </>
        )
    } else {
        return (
            <>
                <Layout {...props}>
                    <WaitingListPageView />
                </Layout>
            </>
        )
    }
}

export default WaitingListPage;
