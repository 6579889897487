import React from 'react';

const CateTypeIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6921 1H5.30807C4.15914 1.00635 3.0598 1.46885 2.25189 2.28576C1.44398 3.10268 0.993684 4.20708 1.00007 5.356V10.644C0.993684 11.7929 1.44398 12.8973 2.25189 13.7142C3.0598 14.5311 4.15914 14.9937 5.30807 15H10.6921C11.841 14.9937 12.9403 14.5311 13.7482 13.7142C14.5562 12.8973 15.0064 11.7929 15.0001 10.644V5.356C15.0064 4.20708 14.5562 3.10268 13.7482 2.28576C12.9403 1.46885 11.841 1.00635 10.6921 1Z" stroke="#5E706C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.68359 6.5293H12.0296V9.8643" stroke="#5E706C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.0296 6.5293L7.82959 10.8493C7.46177 11.2169 6.87354 11.2413 6.47659 10.9053L5.57659 10.1403C5.36678 9.96201 5.09274 9.87812 4.81908 9.9084C4.54542 9.93868 4.29635 10.0805 4.13059 10.3003C4.09159 10.3523 4.05359 10.4053 4.00959 10.4533L1.68359 13.0003" stroke="#5E706C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default CateTypeIcon;

CateTypeIcon.defaultProps = {
    width: '16',
    height: '16',
}