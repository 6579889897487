import React from 'react';

const IncompleteProfileIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 80C34.5333 80 29.3667 78.95 24.5 76.85C19.6333 74.75 15.3833 71.8833 11.75 68.25C8.11667 64.6167 5.25 60.3667 3.15 55.5C1.05 50.6333 0 45.4667 0 40C0 34.4 1.03333 29.1833 3.1 24.35C5.16667 19.5167 8.03333 15.3 11.7 11.7L40 40V0C45.5333 0 50.7333 1.05 55.6 3.15C60.4667 5.25 64.7 8.1 68.3 11.7C71.9 15.3 74.75 19.5333 76.85 24.4C78.95 29.2667 80 34.4667 80 40C80 45.4667 78.95 50.6333 76.85 55.5C74.75 60.3667 71.9 64.6167 68.3 68.25C64.7 71.8833 60.4667 74.75 55.6 76.85C50.7333 78.95 45.5333 80 40 80Z" fill="#70FF00"/>
        </svg>
    );
};

export default IncompleteProfileIcon;

IncompleteProfileIcon.defaultProps = {
    width: '80',
    height: '80',
}