import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import Breadcrumbs from '../../../../components/breadcrumbs';
import Button from '../../../../components/button';
import FlowCard from '../../../../components/flowCard';
import Image from '../../../../components/image';
import Input from '../../../../components/input';
import Select from '../../../../components/input/select';
import Textarea from '../../../../components/input/textarea';
import ProgressBarCard from '../../../../components/programBarCard';
import Text from '../../../../components/text';
import { UploadComponent } from '../../uploadAttachments/view/UploadAttachmentsView';
import CourseImage from '../../../../assets/img/logo.svg';
import useHttpHandler from '../../../../hooks/httphandler.hook';
import axios from "axios";
import Flex from '../../../../components/flex';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE } from '../../../../constants/app.constants';
import TopicFlowCard from '../../../../components/topic_flow_Card';

const TopicUploadPageView = (props) => {
    const [progress, setProgress] = useState(null);
    const navigate = useNavigate();
    
    const {courseId,moduleId,topicId} = useParams();
    const {post} = useHttpHandler();

    const {
        dropdownsData,
        
        uploadedImages,
        setUploadedImages,
        pdfuploadedImages,
        pdfsetUploadedImages,
        userInfo,
        uploadedVideosInfo,
        setUploadedVideosInfo,
        setVideoId,
        getVideoId
    } = props?.guruUploadVideoHook

    const formik = useFormik({

        
        initialValues: {
            title: '',
            
            prioritylevel:'',
            description: /*videoDetails[0]?.des ? videoDetails[0]?.des*/'',
            sampleFile: [],
            videosampleFile:[],
        },
        validationSchema: Yup.object({
          title: Yup.string().required('Please enter a valid title'),
         prioritylevel: Yup.string().required('Please enter a valid priority level'),
         description: Yup.string().required('Please enter a valid description'),
        sampleFile:Yup.array().min(1,'Please Upload thumbnail').required("Please Upload thumbnail"),
        videosampleFile:Yup.array().min(1,'Please Upload Video File').required("Please Upload Video File"),

        }),
        onSubmit: (values, actions) => {
            console.log("postdata->",getVideoId,props.getVideoId);
            console.log("submit");
            actions.setSubmitting(true)
            
            /*
            let postData = {
                moduleid:moduleId,
                title: values.title,
                category_id: values.category,
                sub_category_id: parseInt(values.subcategory),
                description: values.description,
                video_id: videoId,
                modulethumbnail: 
                pdfthumbnail: uploadedImages && uploadedImages[1]?.newfile_name ? uploadedImages[1]?.newfile_name : '',
                coursevideo: uploadedVideosInfo && uploadedVideosInfo[0]?.newfile_name ? uploadedVideosInfo[0]?.newfile_name : '',
               
            }
            */
            

            

            let postData = {
                moduleId:moduleId,
                title: values.title,
                categoryId: 1,
                subCategoryId: 1,
                studylevelid: 1,
                description: values.description,
                videoId: getVideoId,
                thumbNail1: uploadedImages && uploadedImages[0]?.newfile_name ? uploadedImages[0]?.newfile_name : '',
                languageid: 1,
                view: 8001,
                section: 1,
                isguidelist: 1,
                priority:  values.prioritylevel,
            }

            console.log("postdata->",postData);

    



            post('addVideoDetails', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    navigate('/instructor/topics/' +moduleId)
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        }
      });
      const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text:"Course",
            link:"/instructor/course",
        },
        {
            text:"Module",
            link:"/instructor/modules/" +courseId,
        },
        {
            text:"Topic",
            link:"/instructor/topics/" +moduleId,
        },
        {
            text: 'Create Topic',
            link: '/instructor/create-topic/' +moduleId,
        },
    ]

    useEffect(() => {
        if (formik.values.sampleFile.length > 0) {
            let formData = new FormData();
            formik?.values?.sampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructorUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    setUploadedImages(data)
                }
            })
                .catch((error) => {
                });
        }
    }, [formik.values.sampleFile])


    const testobj = {
        'image/*': ['.png', '.jpeg', '.jpg'],
        'video/*':['.mp4','.mov'],
    }

    useEffect(() => {
        if (formik.values.videosampleFile.length > 0) {
            let formData = new FormData();
            formik?.values?.videosampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/videoUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {

                const response = res.data.data[0]
                let postData = {
                    filename: response.newfile_name,
                    file_size: response.size,
                    file_type: response.mimetype,
                    duration: Math.round(Math.ceil(response.duration))
                }

                post('videoUploadEntry', postData).then((res) => {
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        console.log("videoId",data[0].videoid );
                        setVideoId(data[0].videoid + "");
                        setUploadedVideosInfo(data);
                        console.log("videoId-->",data[0].videoid );
                        console.log('filename->',uploadedVideosInfo.video_file,uploadedVideosInfo)
                       
                    } else {
                     //   toast.error(status.msg)
                    }
                   
                    //hell
                }).catch(err => {
                    
                })

               
            })
                .catch((error) => {
                });
        }
    }, [formik.values.videosampleFile])
  return (
    <div>
         <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
         <Text
        type="H1"
        className="font-weight-500 main_heading_color mb-24"
        text="Create Topic"
    />
{/**
<TopicFlowCard
    imgsrc={CourseImage}
    course="Course"
    coursetitle="React"
    module="Modules"
    value="Props"
    availablevideos="Topics"
    valuevideo="4"
    /> */}
      <form onSubmit={formik.handleSubmit}>
      <div className="formBox">
            <Input
                
                label="Priority Level"
                name="prioritylevel"
                value={formik.values.prioritylevel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                
                
            />{formik.touched.prioritylevel && formik.errors.prioritylevel ? (
                formik.errors.prioritylevel
            ) : null}
        </div>
        
    <div className="formBox">
    <Input
        autoFocus={true}
        label="Topic Title"
        type="text"
        name="title"
        placeholder=""
        value={formik.values.title}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        mandatory
        
    />{formik.touched.title && formik.errors.title ? (
        formik.errors.title
    ) : null}
       </div>
      
       
       
        <div className="formBox">
            <Textarea
                tertiary
                label="Description"
                type="text"
                name="description"
                placeholder="Write your description here..."

                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                rows="5"
                
            >{formik.touched.description && formik.errors.description ? (
                formik.errors.description
            ) : null}
            </Textarea>
        </div>
        <div className="formBox">
            <div className="form-field form-field-tertiary">
                <label className="form-label mb-10">Thumbnail <span
                    className="text-danger">*</span></label>
                <Text
                    type="PARAGRAPH"
                    className="text-white-half"
                    text="Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention"
                />

                   
                <div className="row">
                    <div className="col-12 col-md-4">
                        <UploadComponent
                            setFieldValue={formik.setFieldValue}
                            maxFilesUpload="3"
                            hideFooter
                            hideButton
                            thumbNailWrap
                            thumb="Upload Thumbnail"
                            fieldName = "sampleFile"
                            accpetFileType = {IMAGE_FILE_TYPE}
                        />
                        {formik.touched.sampleFile && formik.errors.sampleFile ?
                            (
                                <div className="message-error">
                                    <small>{formik.errors.sampleFile}</small></div>
                            ) : null
                        }
                    </div>

                    {uploadedImages && uploadedImages.length > 0 &&
                    uploadedImages?.map((file, i) => (
                        <div key={i} className="col-12 col-md-4">
                            {file?.newfile_name && (
                                <Image
                                    src={process.env.REACT_APP_IMG_URL + file?.newfile_name}
                                />
                            )}
                        </div>
                    ))
                    }
                    {!uploadedImages &&
                    (<>
                        <div className="col-12 col-md-4">
                            <div className="thumbimgempty"></div>
                        </div>
                    </>)
                    }
                
                    </div>
                
                
                
            <div className="form-field form-field-tertiary mt-5">
                <label className="form-label mb-10">Upload Video <span
                    className="text-danger">*</span></label>
                <Text
                    type="PARAGRAPH"
                    className="text-white-half"
                    text="Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention"
                />
               { <div className="row">
                    <div className="col-12 col-md-4">
                        <UploadComponent
                            setFieldValue={formik.setFieldValue}
                            maxFilesUpload="3"
                            hideFooter
                            hideButton
                            thumbNailWrap
                            thumb="Upload Video"
                            fieldName = "videosampleFile"
                            accpetFileType = {VIDEO_FILE_TYPE}
                        />
                        {formik.touched.videosampleFile && formik.errors.videosampleFile ?
                            (
                                <div className="message-error">
                                    <small>{formik.errors.videosampleFile}</small></div>
                            ) : null
                        }
                    </div>

                    {uploadedVideosInfo && uploadedVideosInfo.length > 0 &&
                    uploadedVideosInfo?.map((file, i) => (
                        <div key={i} className="col-12 col-md-4">
                            {file?.video_file && (
                                
                                    <video class="vjs-tech" id="vjs_video_3_html5_api" tabindex="-1" src={process.env.REACT_APP_IMG_URL +  file?.video_file} width="400px" height="160px"  autoplay="autoplay"></video>
                            )}
                        </div>
                    ))
                    }
                    {!uploadedVideosInfo &&
                    (<>
                        <div className="col-12 col-md-4">
                            <div className="thumbimgempty"></div>
                        </div>
                    </>)
                    }
                </div>}
                </div>
           
            </div> 
        </div>
       
                 
        <div className="action-buttons-end">
                     <Button
                        type="buttton"
                        text="cancel"
                        onClick={()=>{navigate('/instructor/video-details/123')}}
                    />
                    <Button
                        light
                        type="submit"
                        text="save"
                        className="mt-20"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                       
                    />
                    
                </div>
      </form>
    </div>
  )
}

export default TopicUploadPageView;
