import {createSlice} from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        defaultLanguage: null,
        languageText: null,
        userInfo: null,
        surveyQuestions: null,
        guruProfileDetails: null,
        learnerProfileDetails:null,
        categoriesList:null,
    },
    reducers: {
        setLanguageText: (state, action) => {
            state.languageText = action.payload
        },
        setUserInfo: (state, action)=> {
            state.userInfo = action.payload
        },
        setSurveyQuestions: (state, action)=> {
            state.surveyQuestions = action.payload
        },
        setGuruProfileDetails: (state, action)=> {
            state.guruProfileDetails = action.payload
        },
        setLearnerProfileDetails: (state, action)=> {
            state.learnerProfileDetails = action.payload
        },
        setCategoriesList: (state, action)=> {
            state.categoriesList = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const {setLanguageText, setUserInfo, setSurveyQuestions, setGuruProfileDetails,setLearnerProfileDetails, setCategoriesList} = appSlice.actions

export default appSlice.reducer
