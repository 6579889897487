import React, { useContext, useEffect, useRef, useState } from "react";
import LayoutGuru from "../../../components/layoutGuru";
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import Loader from "../../../components/loader";
import ModulePageView from "./views/modulePageView";
import useVideosCommonHooks from "../../instructor/createCourse/videosCommonHook";
import { useNavigate, useParams } from "react-router-dom";
import NewModulePageView from "./views/newModulePageView";
import { NestedListComponent } from "./views/DragComponent/NestedListComponent";

//import {Modal, Popconfirm,Tag } from 'antd';
import VideoPlayer from "../../../components/videoplayer";
import CourseMainBar from "../../../components/courseMainbar";
import useGuruDashboard from "../dashboard/hooks/guruDashboard.hook";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import ShareVia from "../../../components/shareVia";
import { URL } from "../../../constants/app.constants";
import PlayVideoCompnent from "../../../components/playVideoCompnent";

const ModuleUploadpage = (props) => {
  const commonHook = useVideosCommonHooks();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [vidoePlayUrl, setVidoePlayUrl] = useState("");
  const guruDashboardHook = useGuruDashboard();
  const [isShareModal, setIsShareModal] = useState(false);
  const [randomNum, setRandomNum] = useState(0);
  const closeShare = () => {
    setIsShareModal(false);
  };
  const playVideo = (val) => {
   
    commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
      setVidoePlayUrl(data.data);
      setIsVideoModal(true);
    });
 
  
  };

  useEffect(() => {
    commonHook
      ?.promiseServiceNonLoading({
        uri: "instructorCourseModuleList",
        courseid: courseId,
        levelid: 0,
      })
      .then((data) => {
        commonHook.setCustomData(data.data.data);
        commonHook.getDropDowns().then(() => {});
      });
      
  }, [randomNum]);

  if (commonHook.dropdownsData == null) {
    return <Loader />;
  } else {
    return (
      <LayoutGuru {...props}>
     


       
        <div style={{ marginTop: "0px" }}>
          <NestedListComponent
            {...props}
            playVideo={playVideo}
            data={commonHook.serviceData?.moduleData}
            {...commonHook}
            courseId={courseId}
            guruDashboardHook={guruDashboardHook}
            setLevel={commonHook.setLevel}
            setCustomData={commonHook.setCustomData}
            setIsShareModal={setIsShareModal}
            commonHook={commonHook}
            setRandomNum={setRandomNum}
          />
        </div>

        {isShareModal && (
          <>
            <Modal
              className="modal-white"
              canClose={closeShare}
              onClose={closeShare}
              modalContent={
                <ShareVia {...props} affiliateLink={URL + courseId} />
              }
            />
          </>
        )}

        {isVideoModal && (
          <>
            <Modal
              onClose={() => setIsVideoModal(false)}
              onOuterClose={() => setIsVideoModal(false)}
              modalContent={
                <>
                  <VideoPlayer
                    videourl={vidoePlayUrl}
                    width="100%"
                    height="460"
                  />
                </>
              }
            />
          </>
        )}
      </LayoutGuru>
    );
  }
};

export default ModuleUploadpage;
