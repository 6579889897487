import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyReportView from "./view/WithDrawView";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import Loader from "../../../../components/loader";
import useGetTableList from "../../../../hooks/getTableListCommonHook";
import {DEFAULTFILTERS} from "../../../../constants/app.constants";
import WalletView from "./view/WithDrawView";
import useVideosCommonHooks from "../../../instructor/createCourse/videosCommonHook";
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js'
import CheckoutForm from '../../stripe/checkoutForm';
import WithDrawView from "./view/WithDrawView";


const Withdraw = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const commonHook = useVideosCommonHooks();
    const [walletAmount,setWalletAmount] = useState(0);
    const [reloadPage,setReloadPage] = useState(0);
    const [getPriceData,setPriceData] = useState([]);

    const [priceId,setPriceId] =useState({});



    if (commonHook.loading) {
        return (
            <>
                <Loader/>
            </>
        )
    }
    return (
            <>
                <WithDrawView {...props} {...commonHook} walletAmount={props.walletAmount} setReloadPage={setReloadPage} getPriceData={getPriceData} priceId={priceId }setPriceId={setPriceId} />
            </>
    )
}

export default Withdraw;
