import React from 'react';

const LikesIcon = props => {
    return (
        <>
            <svg width={props.width} height={props.height} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    fill-rule="evenodd" 
                    clip-rule="evenodd" 
                    d="M4.147 6.85515C3.79385 6.07726 3.01628 5.57977 2.162 5.58515C0.983769 5.56958 0.0159101 6.51192 0 7.69015V11.9022C0.01864 13.0784 0.985693 14.0177 2.162 14.0022C3.08991 14.0095 3.9186 13.4228 4.22 12.5452M4.147 6.85562C4.26396 7.11844 4.32427 7.40294 4.324 7.69062V11.9026C4.32552 12.1222 4.29176 12.3407 4.224 12.5496M4.147 6.85562C4.31554 6.51697 4.5574 6.22011 4.855 5.98662C5.0362 5.85407 5.16897 5.6658 5.233 5.45062L6.314 1.25062C6.57816 0.342884 7.52042 -0.186277 8.433 0.0606163C8.87076 0.167977 9.24698 0.446842 9.477 0.834471C9.70702 1.2221 9.77152 1.68593 9.656 2.12162L9.038 4.36462C8.97171 4.62085 9.02901 4.89334 9.19289 5.10118C9.35676 5.30902 9.60837 5.4283 9.873 5.42362H13.405C14.0841 5.41427 14.7392 5.6752 15.2259 6.14896C15.7126 6.62271 15.991 7.27047 16 7.94962V11.3166C15.9908 11.9956 15.7122 12.6431 15.2255 13.1166C14.7388 13.5902 14.084 13.851 13.405 13.8416H6.486C5.55315 13.8463 4.68893 13.3521 4.22 12.5456" 
                    fill="none"
                    stroke="currentColor"
                />
            </svg>
        </>
    );
};

export default LikesIcon;

LikesIcon.defaultProps = {
    width: '16',
    height: '15'
}