import React, {useState} from 'react';
import Text from '../../../../../components/text';
import NavGuruMonetization from '../../../../../components/navGuruMonetization';
import Flex from '../../../../../components/flex';
import Button from '../../../../../components/button';
import NoVideoUploadedYetIcon from '../../../../../components/svg/noVideoUploadedYetIcon';
import Section from '../../../../../components/section';
import TotalViewAndLikesView from "../components/TotalViewAndLikesView";
import TotalIncomeView from "../components/TotalIncomeView";
import {useNavigate} from "react-router-dom";

const ReportViews = props => {
    const navigate = useNavigate()

    if (props?.videosCount == 0) {
        return (
            <Section>
                <div className="row">
                    <div className="col-10 mx-auto text-center">
                        <Flex justifyContent="center" alignItemsCenter flexColumn>
                            <div className="mb-30">
                                <NoVideoUploadedYetIcon/>
                            </div>
                            <Text type="H3" text="No Video uploaded yet..."/>
                            <Text
                                type="PARAGRAPH"
                                className="mb-40 text-white-half"
                                text="You are required to upload video to view the report generated by the system. Please do upload the video to view the report in this section."
                            />
                            <Button type="button" text="UPLOAD VIDEO NOW" onClick={()=>{navigate('/instructor/upload-video')}}/>
                        </Flex>
                    </div>
                </div>
            </Section>
        )
    } else {
        return (
            <>
                <TotalViewAndLikesView {...props} />
                <TotalIncomeView {...props} />
            </>
        )
    }
}

const MyReportView = (props) => {
    return (
        <>
            <Text
                type="H1"
                text="My Videos"
            />
            
        </>
    );
};

export default MyReportView;

