import React from 'react';

const MinusCircleIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00019C1.00024 4.66068 3.35944 1.78639 6.63483 1.1351C9.91021 0.483818 13.1895 2.23693 14.4673 5.32231C15.7451 8.40769 14.6655 11.966 11.8887 13.8212C9.11186 15.6764 5.41127 15.3117 3.05 12.9502C1.73728 11.6373 0.999866 9.85675 1 8.00019Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6281 8H5.32812" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    );
};

export default MinusCircleIcon;

MinusCircleIcon.defaultProps = {
    width: "16",
    height: "16",
}