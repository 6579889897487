import React, {useEffect, useRef, useState} from "react";
import './categoryButtons.scss'
import {toast} from "react-toastify";
import useHttpHandler from "../../hooks/httphandler.hook";
import Button from "../button";
import Loader from "../loader";
import {setCategoriesList} from "../../store/reducers/app.reducer";
import {useDispatch, useSelector} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import {Pagination} from "swiper";
import "swiper/css/pagination";
import SwiperPrevIcon from "../svg/swiperPrevIcon";
import SwiperNextIcon from "../svg/swiperNextIcon";
import Flex from "../flex";


export const SubCatButton = props => {
    const {active, setActive, category, subcategories} = props
    const [isHover, setIsHover] = useState(false);
    const [subCatActive, setSubCatActive] = useState(null);

    const isMenuActive = (item, active, category) => {
        if (subCatActive?.subcatid === item?.subcatid) {
            return 'active'
        } else {
            return ''
        }
    }

    return (
        <div
            className="sub-cat-button-wrapper"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <Button
                text={category?.catName}
                inactive={active?.catId != subCatActive?.parent_id}
                active={active?.catId == subCatActive?.parent_id}
            />
            {isHover && (
                <div className={`category-dropdown ${active?.catId === 1 || isHover ? 'active' : ''}`}>
                    <div className="category-dropdown-main">
                        {subcategories && subcategories?.map((item, index) => {
                            return (
                                <div className="category-dropdown-item">
                                    <Button
                                        key={index}
                                        className={isMenuActive(item, active, category)}
                                        white
                                        onClick={() => {
                                            setActive({catId: item?.parent_id ? item?.parent_id : 0, catName: ''})
                                            setSubCatActive(item)
                                            props.onClick(item?.parent_id, 1, item?.subcatid)
                                        }}
                                        text={item?.subCatName}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}


const CategoryButtons = props => {
    const dispatch = useDispatch()
    const categories = useSelector(state => state?.app?.categoriesList)
    const [loading, setLoading] = useState(false);
    const {post} = useHttpHandler()
    const {text, active, setActive} = props
    const swiperCatBtnsRef = useRef();

    const getDropDowns = () => {
        setLoading(true)
        
        post('getCommonDropdownData').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                let topicOfInterest = data?.categories
                dispatch(setCategoriesList(topicOfInterest))
                setLoading(false)
            } else {
                toast.error(status.msg)
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!categories) {
            getDropDowns()
        }
    }, [])

    if (loading) {
        return <Loader/>
    } else {
        return (
            <div className="">
                <div className="category-button-group">
                    <Flex justifyContent="end">                        
                        <div className="slider-arrow-up">
                            <Button
                                circlePrimary
                                text=""
                                icon={<SwiperPrevIcon width="11" height="18"/>}
                                onClick={() => swiperCatBtnsRef.current?.slidePrev()}
                            />
                            <Button
                                circlePrimary
                                text=""
                                icon={<SwiperNextIcon width="11" height="18"/>}
                                onClick={() => swiperCatBtnsRef.current?.slideNext()}
                            />
                        </div>
                    </Flex>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={12}
                        modules={[Pagination]}
                        onBeforeInit={(swiper) => {
                            swiperCatBtnsRef.current = swiper;
                        }}
                    >
                        <SwiperSlide>
                            <Button
                                inactive={active?.catId != 0 || active?.catId == null}
                                active={active?.catId == 0}
                                text="All topics"
                                onClick={() => {
                                    setActive({catId: 0, catName: 'All topics'})
                                    props.getList(0)
                                }}
                            />

                        </SwiperSlide>
                        {categories && categories?.map((item, index) => {
                            return (
                                <SwiperSlide>
                                    {item?.sub_categories?.length > 0 ? (
                                        <SubCatButton
                                            category={item}
                                            key={index}
                                            subcategories={item?.sub_categories}
                                            onClick={props.getList}
                                            active={active}
                                            setActive={setActive}
                                        />
                                    ) : (
                                        <Button
                                            key={index}
                                            inactive
                                            inactive={active?.catId === item?.catId ? false : true}
                                            text={item?.catName}
                                            onClick={() => {
                                                setActive({catId: item?.catId, catName: item.catName})
                                                props.getList(item?.catId)
                                            }}
                                        />
                                    )}
                                </SwiperSlide>                            )
                        })}
                    </Swiper>
                </div>
            </div>
        )
    }


}

export default CategoryButtons;
