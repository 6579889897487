import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {useState} from "react";
import useHelper from "../../../../../hooks/helpers.hook";

const useMyReport = () => {
    const {post} = useHttpHandler()
    const {formatGraphData} = useHelper()
    const [reportHookLoading, setReportHookLoading] = useState(true)
    const [totalIncomeLoading, setTotalIncomeLoading] = useState(false)
    const [totalViewsLikesLoading, setTotalViewsLikesLoading] = useState(false)
    const [videosCount, setVideosCount] = useState(null)
    const [viewsLikesGraph, setViewsLikesGraph] = useState(null)
    const [totalIncomeGraph, setTotalIncomeGraph] = useState(null)

    const   getVideoCount = () => {
        setReportHookLoading(true)
        post('guru_video_count').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideosCount(data?.videos_count)
                setReportHookLoading(false)
            } else {
            }
        }).catch(err => {
            setReportHookLoading(false)
        })
    }

    const getTotalViewAndLikesGraphData = (data) => {
        setTotalViewsLikesLoading(true)
        post('guru_my_reports_views_likes_graph', data).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setViewsLikesGraph(formatGraphData(data))
                setTotalViewsLikesLoading(false)
            } else {
            }
        }).catch(err => {
            setTotalViewsLikesLoading(false)
        })
    }

    const getTotalIncomeGraphData = (data) => {
        setTotalIncomeLoading(true)
        post('guru_my_reports_views_likes_graph', data).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setTotalIncomeGraph(formatGraphData(data))
                setTotalIncomeLoading(false)
            } else {
            }
        }).catch(err => {
            setTotalIncomeLoading(false)
        })
    }



    return {
        reportHookLoading,
        getVideoCount,
        videosCount,
        getTotalViewAndLikesGraphData,
        totalViewsLikesLoading,
        viewsLikesGraph,

        getTotalIncomeGraphData,
        totalIncomeLoading,
        totalIncomeGraph
    }
}
export default useMyReport
