import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";

const useSubscribe = () => {
    const [loading, setLoading] = useState(true)
    const [packages, setPackages] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [memberSubscriptionDetails, setMemberSubscriptionDetails] = useState(null);
    const [memberCommissionDetails, setMemberCommissionDetails] = useState(null);
    const [reports, setReports] = useState(null);

    const {post} = useHttpHandler()
    const navigate = useNavigate()

    const goToPayment = () => {
        navigate('/trainee/payment')
    }

    const subscribeNow = ()=> {
        setIsSubmitting(true)
        post('select_package', {package_id: packages[0].packageid}).then((res)=>{
            setIsSubmitting(false)
            const status = res.data.status

            if(status.result == '200') {
                goToPayment()
            } else {
                toast.error(status.msg)
            }

        }).catch(err=>{setIsSubmitting(false)})
    }

    const getPackages = () => {
        post('get_packages').then((res) => {
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setPackages(data)
            } else {
                toast.error(status.msg)
            }
        })
    }

    const getMemberSubscriptionDetails = ()=> {
        setLoading(true)
        post('member_my_subscription').then((res)=>{
            setLoading(false)
            const status = res.data.status

            const data = res.data.data
            if(status.result == '200') {
                setMemberSubscriptionDetails(data)

            } else {
                toast.error(status.msg)
            }

        }).catch(err=>{setLoading(false)})
    }

    const getMemberCommissionDetails = ()=> {
        setLoading(true)
        post('member_my_comission').then((res)=>{
            setLoading(false)
            const status = res.data.status

            const data = res.data.data
            if(status.result == '200') {
                setMemberCommissionDetails(data)

            } else {
                toast.error(status.msg)
            }

        }).catch(err=>{setLoading(false)})
    }

    const getReportList = ()=> {
        setLoading(true)
        post('member_affilate_monthly_report').then((res)=>{
            setLoading(false)
            const status = res.data.status

            const data = res.data.data
            if(status.result == '200') {
                setReports(data)

            } else {
                toast.error(status.msg)
            }

        }).catch(err=>{setLoading(false)})
    }

    return {
        getPackages,
        goToPayment,
        packages,
        loading,
        subscribeNow,
        isSubmitting,
        getMemberSubscriptionDetails,
        memberSubscriptionDetails,
        getMemberCommissionDetails,
        memberCommissionDetails,
        getReportList,
        reports
    }

}
export default useSubscribe


