import React from 'react';

const RibbonIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 43 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43 70.4131L21.5 49.8696L0 70.4131V0.413086H43V70.4131Z" fill={props.bgcolor} />
        </svg>
    );
};

export default RibbonIcon;

RibbonIcon.defaultProps = {
    width: '43',
    height: '71',
}