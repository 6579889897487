import React from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Input from "../input";
import * as Yup from "yup";
import { useFormik } from "formik";
import useHttpHandler from "../../hooks/httphandler.hook";
import Button from "../button";

const TopicFlowCard =(props)=>{
	const navigate = useNavigate();
	const {post} = useHttpHandler();
	const {courseId,moduleId,topicId} = useParams();
	const formik = useFormik({

        
        initialValues: {
            title: '',
        },
        validationSchema: Yup.object({
      /*      title: Yup.string().required('Please enter a valid uname'),
            modulelevel: Yup.string().required('Please enter a valid module level'),
            prioritylevel: Yup.string().required('Please enter a valid priority level'),
            sampleFile: Yup.mixed().required('Please upload thumbnails '),
             description: Yup.string().required('Please enter a valid description'),
        */    
        }),
        onSubmit: (values, actions) => {
            console.log("submit");
            actions.setSubmitting(true)
            
            /*
            let postData = {
                moduleid:moduleId,
                title: values.title,
                category_id: values.category,
                sub_category_id: parseInt(values.subcategory),
                description: values.description,
                video_id: videoId,
                modulethumbnail: 
                pdfthumbnail: uploadedImages && uploadedImages[1]?.newfile_name ? uploadedImages[1]?.newfile_name : '',
                coursevideo: uploadedVideosInfo && uploadedVideosInfo[0]?.newfile_name ? uploadedVideosInfo[0]?.newfile_name : '',
               
            }
            */
           
            let postData = {
               
                title: values.title,
               
            }

            console.log("postdata->",postData);

    



            post('addVideoDetails', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    navigate('/instructor/topics/' +moduleId)
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        }
      });

    return(
        <>
	<div className="container mt-2 mb-4">
		<div className="d-flex flex-wrap bg-light custom_topic_card">
			<div className="position-relative col-12 col-md-3 lazyloaded">
				<img src={process.env.REACT_APP_IMG_URL + props.imgsrc} className="img-fluid topic_flow_image" alt={props.alt} />
			</div>
			<div className="col-md-9 p-3">
				<div className="row">
					<div className="col-md-6 mb-4 mt-1">
						<div className="bg-subcolor d-flex flex-row align-items-center">
								<p className="mb-0 text-1 mr-3 font-weight-bold text-dark text-uppercase"> {props.course} </p>
								<h4 className="mb-0 text-2 text-color-secondary"> : {props.coursetitle}</h4>
						</div>
					</div>
					<div className="col-md-6">
						<div className="position-absolute ps-md-0 mb-3 mb-md-0 pe-4 me-4 align-items-center">
						<div className="p-0">
						<form onSubmit={formik.handleSubmit}>
						<div className="formBox">
							<div className="row mr-2">
								<div className="col-md-9 mt-3">
								<Input
							autoFocus={true}
							type="text"
							name="title"
							placeholder=""
							value={formik.values.title}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>{formik.touched.title && formik.errors.title ? (
							formik.errors.title
						) : null}
								</div>
                            <div className="col-md-3">
							<Button
                        light
                        type="submit"
                        text="search"
                        className="btn-primary mt-10"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
							</div>

							</div>

							</div>
						
							</form>
							</div>
						</div>
					</div>
					<div className="col-md-12 mb-2">
						<div className="bg-subcolor d-flex flex-row align-items-center">
								<p className="mb-0 text-1 mr-2 font-weight-bold text-dark text-uppercase"> {props.module} </p>
								<h4 className="mb-0 text-2 text-color-secondary"> : {props.value}<br/></h4>
						
						</div>
					</div>
					<div className="col-md-12">
						<div className="d-flex flex-row ps-md-0 mb-3 mb-md-0 pe-4 me-4 align-items-center">
								<p className="mb-0 text-1 mr-5 ml-2 font-weight-semibold text-dark text-uppercase">{props.availablevideos}</p>
								<h4 className="mb-0 text-2 text-color-secondary"> {props.valuevideo} <br/></h4>
						
						</div>
					</div>					
				</div>
				
			</div>
			
		</div>
			
			</div>
        </>
    )
}


export default TopicFlowCard;

