import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyCommissionView from "./view/MyCommissionView";
import './myCommission.scss';
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribe from "../../subscribe/hooks/subscribe.hook";
import Loader from "../../../../components/loader";
import GlobalInfoModal from "../../../../components/globalInfoModal";
import {useSelector} from "react-redux";
import moment from "moment";

const MyCommission = props => {
    const {membership_expirydate} = useSelector(state => state?.app?.userInfo)
    const isPremiumActive = moment(membership_expirydate).diff(new Date(), 'days') >= 0

    const [isOpenModal, setIsOpenModal] = useState(!isPremiumActive);
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const subscribeHook = useSubscribe()

    useEffect(()=>{
            subscribeHook.getMemberCommissionDetails()
    },[])

    if(subscribeHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel {...props}>

               {/** <SubscribePremiumModal {...props}/> */}
                <MyCommissionView {...props} commissionDetails={subscribeHook?.memberCommissionDetails} />
            </LayoutPanel>
        </>
    )
}

export default MyCommission;
