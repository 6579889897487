import { useNavigate } from "react-router-dom";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useState} from "react";
import {toast} from "react-toastify";

const useMyVideo = () => {
    const [loading, setLoading] = useState(true)
    const [videoList, setVideoList] = useState(null)
    const {post} = useHttpHandler();
    const navigate = useNavigate();

    const getVideos = () => {
        setLoading(true)
        post('guru_get_my_videos').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideoList(data)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })

    }

    return {
        loading,
        videoList,
        getVideos
    }

}
export default useMyVideo


