import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import BankDetailsView from "./view/BankDetailsView";
import NavSide from '../../../components/navSide'
import InfomationNavSideGuru from "../../../components/infomationNavSideGuru";
import useProfile from "../settings/profile/hooks/profile.hook";
import useBankDetails from "./hooks/bankDetails.hook";
import Loader from "../../../components/loader";
import logoImg from '../../../assets/userimg/login-bg2.png';


const BankDetails = props => {
    const {text} = props
    const profileHook = useProfile()

    const bankDetailsHook = useBankDetails()
    useEffect(() => {
        profileHook?.getProfileDetails()
    }, [])
    if (profileHook.loading) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
          <div class="register_instructor">
        <main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
      <div className="row">
      <div className="col-md-6">
            <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
            </div>
            <div className="col-md-6">
            <div class="row justify-content-center">
               <div class="col-lg-12 col-md-6 bg-light">
                <BankDetailsView
                    {...props}
                    {...bankDetailsHook}
                    profileDetails={profileHook?.profileDetails}
                />
                </div>
                </div>
            </div>
        </div>
        
            </div>
      

    </section>
    </div>
    </main>
  </div>

        </>
    )
}

export default BankDetails;
