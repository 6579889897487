import React, {useEffect, useRef, useState} from 'react';
import Text from '../../../../components/text';
import Button from '../../../../components/button';
import FavouriteGuruCard from '../../../../components/favouriteGuruCard';
import FavGuruImage from '../../../../assets/img/logo.svg';
import {DEFAULT_FILTERS} from "../../../../constants/app.constants";
import {useNavigate} from "react-router-dom";
import Section from "../../../../components/section";
import Flex from "../../../../components/flex";
import SwiperPrevIcon from "../../../../components/svg/swiperPrevIcon";
import SwiperNextIcon from "../../../../components/svg/swiperNextIcon";
import {Swiper, SwiperSlide} from "swiper/react";
import useGetTableList from "../../../../hooks/getTableListCommonHook";
import 'swiper/css';
import {Pagination} from "swiper";


const FavouriteGuruSwiper = (props) => {
    const swiperFavouriteGurusRef = useRef();
    const commonHook = useGetTableList();

    useEffect(() => {
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'user_favoutitegurus';
        DEFAULT_FILTERS.category_id = 0;
        
        DEFAULT_FILTERS.perpage = 1000;
        commonHook.getList(DEFAULT_FILTERS)
    }, [])


    const tableData = commonHook?.tableData?.listData
    const navigate = useNavigate()

    if (tableData && tableData.length > 0) {

        return (
            <Section className="pb-0 favourite-guru-section overflow-outer rml-24 ">
                <div className="section-head">
                    <Text
                        type="H3"
                        text="FAVOURITE INSTRUCTORS"
                        className="section-title text-uppercase"
                    />
                    <Flex justifyContent="end">
                        <div className="slider-arrow-up">
                            <Button
                                circlePrimary
                                text=""
                                icon={<SwiperPrevIcon width="11" height="18"/>}
                                onClick={() => swiperFavouriteGurusRef.current?.slidePrev()}
                            />
                            <Button
                                circlePrimary
                                text=""
                                icon={<SwiperNextIcon width="11" height="18"/>}
                                onClick={() => swiperFavouriteGurusRef.current?.slideNext()}
                            />
                        </div>
                    </Flex>
                </div>
                <div className="slider-main max-slider">
                    <Swiper
                        spaceBetween={12}
                        slidesPerView={1}
                        onBeforeInit={(swiper) => {
                            swiperFavouriteGurusRef.current = swiper;
                        }}
                        modules={[Pagination]}
                        pagination={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                pagination: true,
                            },
                            768: {
                                slidesPerView: 4,
                                pagination: true,
                            },
                            1024: {
                                slidesPerView: 4,
                                pagination: false,
                            },
                        }}
                    >
                        {tableData?.map((item, index) => {
                            const topicImage = item?.pic ? process.env.REACT_APP_IMG_URL + item?.pic : FavGuruImage
                            return (
                                <SwiperSlide>
                                    <FavouriteGuruCard
                                        src={topicImage}
                                        title={item?.fullname}
                                        //subtitle={item?.preferred_topic}
                                        subtitle={item?.expertise}
                                        videos={item?.video_count}
                                        onClick={() => {
                                            navigate('/trainee/favourite-instructor/favourite-instructor-details/all-videos/' + item.guruid)
                                        }}
                                    />

                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </Section>
        );
    } else {
        return <></>
    }

};

export default FavouriteGuruSwiper;

