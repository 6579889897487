import React from 'react';

const VideoPlusIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 0.979492H11.5C14.0773 0.979492 16.1667 3.06883 16.1667 5.64616V5.79199L18.43 4.37683C19.1493 3.92727 20.0559 3.90346 20.7978 4.31465C21.5397 4.72585 22 5.50726 22 6.35549V9.60349C22.0017 10.4526 21.5421 11.2355 20.7998 11.6479C20.0576 12.0602 19.15 12.0368 18.43 11.5868L16.1667 10.167V10.3128C16.1667 12.8902 14.0773 14.9795 11.5 14.9795H5.66667C3.08934 14.9795 1 12.8902 1 10.3128V5.64616C1 3.06883 3.08934 0.979492 5.66667 0.979492Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.9167 5.79183C16.9167 5.37762 16.5809 5.04183 16.1667 5.04183C15.7525 5.04183 15.4167 5.37762 15.4167 5.79183H16.9167ZM15.4167 10.1668C15.4167 10.581 15.7525 10.9168 16.1667 10.9168C16.5809 10.9168 16.9167 10.581 16.9167 10.1668H15.4167ZM9.33333 5.646C9.33333 5.23178 8.99755 4.896 8.58333 4.896C8.16912 4.896 7.83333 5.23178 7.83333 5.646H9.33333ZM7.83333 7.97933C7.83333 8.39354 8.16912 8.72933 8.58333 8.72933C8.99755 8.72933 9.33333 8.39354 9.33333 7.97933H7.83333ZM9.33333 7.97933C9.33333 7.56512 8.99755 7.22933 8.58333 7.22933C8.16912 7.22933 7.83333 7.56512 7.83333 7.97933H9.33333ZM7.83333 10.3127C7.83333 10.7269 8.16912 11.0627 8.58333 11.0627C8.99755 11.0627 9.33333 10.7269 9.33333 10.3127H7.83333ZM8.58333 8.72933C8.99755 8.72933 9.33333 8.39354 9.33333 7.97933C9.33333 7.56512 8.99755 7.22933 8.58333 7.22933V8.72933ZM6.25 7.22933C5.83579 7.22933 5.5 7.56512 5.5 7.97933C5.5 8.39354 5.83579 8.72933 6.25 8.72933V7.22933ZM8.58333 7.22933C8.16912 7.22933 7.83333 7.56512 7.83333 7.97933C7.83333 8.39354 8.16912 8.72933 8.58333 8.72933V7.22933ZM10.9167 8.72933C11.3309 8.72933 11.6667 8.39354 11.6667 7.97933C11.6667 7.56512 11.3309 7.22933 10.9167 7.22933V8.72933ZM15.4167 5.79183V10.1668H16.9167V5.79183H15.4167ZM7.83333 5.646V7.97933H9.33333V5.646H7.83333ZM7.83333 7.97933V10.3127H9.33333V7.97933H7.83333ZM8.58333 7.22933H6.25V8.72933H8.58333V7.22933ZM8.58333 8.72933H10.9167V7.22933H8.58333V8.72933Z" fill="currentColor"/>
        </svg>
    );
};

export default VideoPlusIcon;

VideoPlusIcon.defaultProps = {
    width: "23",
    height: "16",
}