import React, {useEffect} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyReportView from "./view/MyReportView";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import Loader from "../../../../components/loader";
import useGetTableList from "../../../../hooks/getTableListCommonHook";
import {DEFAULT_FILTERS} from "../../../../constants/app.constants";

const MyReport = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()

    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'member_affilate_monthly_report';
        commonHook.getList(DEFAULT_FILTERS)
    },[])


    if (commonHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel {...props}>

               {/** <SubscribePremiumModal {...props} /> */}
                <MyReportView {...props} {...commonHook} />
            </LayoutPanel>
        </>
    )
}

export default MyReport;
