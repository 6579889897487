import React, { useEffect, useRef } from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Flex from '../flex';
import Button from '../button';
import Text from "../text";
import useMySubscription from '../../pages/trainee/member/mySubscription/hooks/mySubscription.hook';
import ProgramBarSecondaryCard from "../programBarCard/programBarSecondaryCard";
import TotalCtaCard from "../totalCtaCard";
import ShareAffiliateLink from "../shareAffiliateLink";
import Modal from "../modal";
import RadioCircle from "../radio/radioCircle";
import Input from "../input";
import Checkbox from "../checkbox";
import useHelper from "../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";
import CategeoryList from "../categeory_section";
import Section from "../section";
import SwiperPrevIcon from "../svg/swiperPrevIcon";
import SwiperNextIcon from "../svg/swiperNextIcon";
import Swiper from "swiper";
import { SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import useTop10Videos from "../../pages/trainee/dashboard/hooks/top10Videos.hook";
import topicVideoPoster from '../../assets/images/topic-video-poster.jpg';
import AvatarPic from '../../assets/images/avatar_pic.jpg';
import CategoriesListSlide from "../categeory_section/categorySlide";

const ConfirmUnsubscribe = props => {
    const {toggleIsModal, setModalComponent, closeModal, getReasons} = props


    return (
        <>
             <Text type="H2" text="Are you sure you want to unsubscribe Premium Account in COURSE_TSUNAMI?"
                  className="mb-10 text-center"/>

            <Text type="PARAGRAPH"
                  text="By unsubscribing, you are agreeing to our Terms of Conditions and all your information will be deleted to our system. "
                  className="mb-30 text-center"/>

            <Flex justifyContent="center">
                <Button
                    secondary
                    outline
                    large
                    text="Cancel"
                    onClick={closeModal}
                />
                <Button
                    large
                    text="Confirm"
                    onClick={() => {
                        getReasons()
                        setModalComponent('reason')
                    }}
                />
            </Flex>
        </>
    )
}

const ReasonForm = props => {
    const {
        closeModal,
        reasons,
        updateReasons
    } = props;
    const formik = useFormik({
        initialValues: {
            reasonForUnsubscribe: '',
            statetheReason: '',
            acceptTerms: false,
        },
        validationSchema: Yup.object({
            acceptTerms: Yup
                .bool()
                .oneOf([true], 'Please accept terms'),
        }),
        onSubmit: (values, actions) => {
            updateReasons(values, actions)
        },
    });
    return (<>
        <form onSubmit={formik.handleSubmit}>
            <Text type="H3" className="mb-24" text="Reason for Unsubscribe"/>

            {reasons?.map((item, index) => {
                return (
                    <RadioCircle
                        active={formik.values.reasonForUnsubscribe == item.idno}
                        text={item.reason}
                        name="reasonForUnsubscribe"
                        value={item.idno}
                        checked={formik.values.reasonForUnsubscribe == item.idno}
                        onChange={(e) => {
                            formik.setFieldValue('reasonForUnsubscribe', e.target.value)
                        }}
                    />
                )
            })}
            {formik.values.reasonForUnsubscribe == '8' &&
            <div className="formBox ml-48 mt--24">
                <Input
                    nolabel
                    lineBottom
                    type="text"
                    name="statetheReason"
                    placeholder="State the reason"
                    value={formik.values.statetheReason}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    mandatory
                    errormessage={formik.touched.statetheReason && formik.errors.statetheReason ? (
                        formik.errors.statetheReason
                    ) : null}
                />
            </div>
            }
            <div className="formBox mb-0">
                <Checkbox
                    name="acceptTerms"
                    value={formik.values.acceptTerms}
                    label={<>By unsubscribing, you are agreeing to our <Button type="button" linkSecondary
                                                                               text="Terms of use."/></>}
                    id="acceptTerms"
                    isChecked={formik.values.acceptTerms}
                    onChange={formik.handleChange}
                    errormessage={formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                        formik.errors.acceptTerms
                    ) : null}
                />
            </div>
            <div className="action-buttons-center mt-24 mb-0">
                <Button
                    large
                    light
                    type="button"
                    text="Cancel"
                    outline
                    onClick={closeModal}
                />
                <Button
                    large
                    type="submit"
                    text="Confirm"
                    isSubmitting={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                />
            </div>
        </form>
    </>)

}

const SuccessUnsubscribe = props => {
     const {text} =props
    const {closeModal} = props


    return (
        <>
            <Text type="H2"
                  text={props?.YOU_HAVE_SUCCESSFULLY_UNSUBSCRIBED_TO_REZKILAH_YOUR_MEMBERSHIP_CANCELLTATION_WILL_BE_EFFECTIVE_ON_YOUR_NEXT_BILLING_DATE}
                  className="mb-80 text-center"/>


            <Flex justifyContent="center">
                <Button
                    large
                    text="Continue"
                    onClick={closeModal}
                />
            </Flex>
        </>
    )
}

const UnsubscribeModalContent = props => {
    const {modalComponent, reasons} = props


    if (modalComponent == 'confirm') {
        return <ConfirmUnsubscribe {...props}/>

    } else if (modalComponent == 'reason' && reasons && reasons.length > 0) {
        return <ReasonForm {...props}/>

    } else if (modalComponent == 'success') {
        return <SuccessUnsubscribe {...props}/>

    } else {
        return ''
    }


}

const SubscriptionPremium = props => {
    const navigate = useNavigate();
    const {decimalFormatter, formatDateDDMMYY2} = useHelper()
    const {memberSubscriptionDetails, text} = props
    const mySubscriptionHook = useMySubscription()
    const {
        isModal,
        setIsModal,
        toggleisModal,
    } = mySubscriptionHook;

    
    const swiperTrendingRef = useRef();



    const totalAffiliateIncomeFromDate = memberSubscriptionDetails?.first_day ? formatDateDDMMYY2(memberSubscriptionDetails?.first_day)  : ''
    const totalAffiliateIncomeToDate = memberSubscriptionDetails?.last_day ? formatDateDDMMYY2(memberSubscriptionDetails?.last_day)  : ''
    const totalRewardPointsFromDate = memberSubscriptionDetails?.joined_date ? formatDateDDMMYY2(memberSubscriptionDetails?.joined_date)  : ''

    const barPercentage = 100 / memberSubscriptionDetails?.min_minutes * memberSubscriptionDetails?.watched_this_month_minutes

    return (
        <>
            {/*<NavLearnerMember {...props}/>
            <div className="row mb-24">
                <div className="col-12 col-md-12">
                    <DaysRemaining 
                        package_amount={memberSubscriptionDetails?.package_amount}
                        days_remaining={memberSubscriptionDetails?.days_remaining}
                        next_billing_date={memberSubscriptionDetails?.next_billing_date}
                        text = {props}

                    />
                </div>
               
            </div>*/}
            
               <div className="row mt-30">
                <div className="col-12 col-md-6">
                    <TotalCtaCard
                        title="Total Affiliate Income"
                        fromDate={totalAffiliateIncomeFromDate}
                        toDate={totalAffiliateIncomeToDate}
                        number={`$  ${decimalFormatter(memberSubscriptionDetails?.this_month_affiliate_income)}`}
                        {...props}
                    />
                </div>
             {/**   <div className="col-12 col-md-4">
                    <TotalCtaCard
                        title="Total Reward Points"
                        fromDate={totalRewardPointsFromDate}
                        toDate="Now"
                        number={`${decimalFormatter(memberSubscriptionDetails?.total_reward_points)} pts`}
                        icon={<TrophyIcon/>}
                        {...props}
                    />
                </div> */}
                <div className="col-12 col-md-6">
                    <ShareAffiliateLink {...props}/>
                </div>
           
            </div>

          <CategoriesListSlide />
           
           {/** <div class="row"  style={{position:"relative",padding:'10px'}}>
            <div class="col-lg-4 isotope-item">
            <CategeoryList/>
            </div>
            <div class="col-lg-4 isotope-item">
            <CategeoryList/>
            </div>
            <div class="col-lg-4 isotope-item">
            <CategeoryList/>
            </div>
            </div> 
            <div className="mc-card">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="mc-left">
                            <Text
                                type="H3"
                                className="text-light"
                                text="Total video viewing minutes"
                            />
                            <Text
                                type="SPAN"
                                className="text-cyan mb-20"
                                text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it."
                            />
                            <ProgramBarSecondaryCard small width={barPercentage} minutesLeft={memberSubscriptionDetails?.remaining_minutes_to_watch}/>
                            <Flex justifyContent="start">
                                <Button
                                    type="button"
                                    text={props?.VIEW_DETAILS}
                                    
                                />
                            </Flex>
                        </div>
                    </div>
                    
                    <div className="col-12 col-md-4">
                        <div className="mc-right text-center">
                            <Text
                                type="H2"
                                className="mb-10 font-weight-600 text-light font-size-36"
                                text={`${decimalFormatter(memberSubscriptionDetails?.min_minutes)} mins`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-cyan mb-48"
                                text="Target Minutes to watch This Month"
                            />
                            <Text
                                type="H2"
                                className="mb-10 font-weight-600 text-light font-size-36"
                                text={`${decimalFormatter(memberSubscriptionDetails?.watched_this_month_minutes)} mins`}
                            />
                            <Text
                                type="PARAGRAPH"
                                className="font-weight-500 text-cyan mb-0"
                                text="Total Video Minutes viewed This Month"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Text
                type="H2"
                className="font-weight-500"
                text="Report Generation"
            />
            <Text
                type="PARAGRAPH"
                className="text-white-half mb-24"
                text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distributio"
            />
            <Flex justifyContent="start">
                <Button
                    type="button"
                    text="view Report"
                />
            </Flex>
            <hr/>
            <Text
                type="H2"
                className="font-weight-500"
                text="Pause or Cancel Subscription"
            />
            <Flex justifyContent="between" className="mb-48">
                <Text
                    type="PARAGRAPH"
                    className="text-white-half"
                    text="By cancelling your account you will lose all your data and currently active subscriptions as well"
                />
                <Button
                    type="button"
                    text="Unsubscribe"
                    linkDanger
                    onClick={toggleisModal}
                    style={{fontSize: 'inherit', fontWeight: '400',}}
                />
                {isModal &&
                <Modal
                    light
                    modalMedium
                    onClose={() => setIsModal(false)}
                    onOuterClose={() => setIsModal(false)}
                    modalContent={<UnsubscribeModalContent {...props} {...mySubscriptionHook} />}
                />
                }
            </Flex>
            */}
        </>
    )
}

export default SubscriptionPremium;
