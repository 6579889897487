import React from 'react';

const RejectIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3307 36.2498H50.6641V31.2498H17.3307V36.2498ZM33.9974 67.3332C29.4418 67.3332 25.1363 66.4582 21.0807 64.7082C17.0252 62.9582 13.4835 60.5693 10.4557 57.5415C7.42795 54.5137 5.03906 50.9721 3.28906 46.9165C1.53906 42.8609 0.664062 38.5554 0.664062 33.9998C0.664062 29.3887 1.53906 25.0554 3.28906 20.9998C5.03906 16.9443 7.42795 13.4165 10.4557 10.4165C13.4835 7.4165 17.0252 5.0415 21.0807 3.2915C25.1363 1.5415 29.4418 0.666504 33.9974 0.666504C38.6085 0.666504 42.9418 1.5415 46.9974 3.2915C51.0529 5.0415 54.5807 7.4165 57.5807 10.4165C60.5807 13.4165 62.9557 16.9443 64.7057 20.9998C66.4557 25.0554 67.3307 29.3887 67.3307 33.9998C67.3307 38.5554 66.4557 42.8609 64.7057 46.9165C62.9557 50.9721 60.5807 54.5137 57.5807 57.5415C54.5807 60.5693 51.0529 62.9582 46.9974 64.7082C42.9418 66.4582 38.6085 67.3332 33.9974 67.3332ZM33.9974 62.3332C41.8863 62.3332 48.5807 59.5693 54.0807 54.0415C59.5807 48.5137 62.3307 41.8332 62.3307 33.9998C62.3307 26.1109 59.5807 19.4165 54.0807 13.9165C48.5807 8.4165 41.8863 5.6665 33.9974 5.6665C26.1641 5.6665 19.4835 8.4165 13.9557 13.9165C8.42795 19.4165 5.66406 26.1109 5.66406 33.9998C5.66406 41.8332 8.42795 48.5137 13.9557 54.0415C19.4835 59.5693 26.1641 62.3332 33.9974 62.3332Z" fill="#FF0000"/>
        </svg>
    );
};

export default RejectIcon;

RejectIcon.defaultProps = {
    width: '68',
    height: '68',
}