import React from 'react';
import Helmet from 'react-helmet'
import './signuplayout.scss'
import Button from '../button';
import AkarArrowLeftIcon from '../svg/akarArrowLeftIcon'
import WelcomeUS from '../welcomeus';
import useTranslations from "../../hooks/translations.hook";
import {ENGLISH, MALAY} from "../../languages/languages.constants";
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {FREEMIUM, GURU, PREMIUM} from "../../constants/app.constants";
import {toast} from "react-toastify";
import useHttpHandler from "../../hooks/httphandler.hook";
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import Flex from '../flex';
import logoImg from '../../assets/userimg/login-bg2.png';
import Text from '../text';
const SignupLayout = ({props, children, text, navSideLayout}) => {
    const {post} = useHttpHandler()
    const {setUserSessionInfo} = useLogin()
    const navigate = useNavigate();
    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    
    const navigateToHome =()=>{
        navigate('/')
    }

    const backToMainPage = () => {
        const isConversionFlow = sessionStorage.getItem('isConvertUserFlow')
        if (isConversionFlow) {
            post('swapUser').then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result === '200') {
                    setUserSessionInfo(data).then(()=>{
                        sessionStorage.removeItem('isConvertUserFlow')
                        navigateToHome()
                    })
                } else {
                    toast.error(status.msg)
                }
            })
        } else {
            navigateToHome()
        }


        /*if (token) {
            if (userTypeId == FREEMIUM || userTypeId == PREMIUM) {
                navigate('/trainee/dashboard')
            } else if (userTypeId == GURU) {
                navigate('/instructor/dashboard')
            } else {
                navigate('/')
            }
        }*/
    }
        return (
<>
            <div class="register">
        

            <main>
              <div class="container-fluid">
            
             
            
              <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-7 col-md-6">
                      <div className='branding-logo'>
                        <img src={logoImg} className='img-fluid' alt='logo' />
                      </div>
                     
                         </div>
                      <div class="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <div class="card mb-3 register_flow">
                          <div class="card-body">
            

                            
                          {children}
                     
                     
                        
                          </div>
                        </div>
            
            
                      </div>
                    </div>
                  </div>
            
                </section>
            
              </div>
            </main>
            </div>
            
</>

            
        );
    };

    export default SignupLayout;
