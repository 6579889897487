import React, { useState } from "react";
import './navbarLearner.scss'
import DashboardIcon from '../svg/dashboardIcon'
import Likes2Icon from '../svg/likes2Icon'
import AddressBookIcon from '../svg/addressBookIcon'
import ReportIcon from '../svg/reportIcon'
import BoxCheckIcon from '../svg/boxCheckIcon'
import Nav from '../nav'
import UserDoubleIcon from "../svg/userDoubleIcon";
import RedeemIcon from "../svg/redeemIcon";
import IDCardIcon from "../svg/idCardIcon";
import WalletIcon from "../svg/walletIcon";
import LockIcon from "../svg/lockIcon";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "../svg/menuIcon";
import Button from "../button";
import { SiDiscourse } from "react-icons/si";
import { MdScreenSearchDesktop } from "react-icons/md";
import { HiOutlineTrendingUp } from "react-icons/hi";
import { BsLaptop } from "react-icons/bs";

const Navbar = props => {
    const {text} = props
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 })
    return (
        <>
           <nav className={`navbar-learner ${isTablet ? props.sideMenu ? 'navbar-side-expand' : 'navbar-side-minimize' : ''}`}>
                {isTablet && (
                    <Button
                        text={<MenuIcon />}
                        link
                        className="nav-side-toggle"
                        onClick={props.SideMenuToggle}
                    />
                )}
                <Nav
                    options={[
                        {
                            text: "Dashboard",
                            link:'/trainee/dashboard',
                            icon:<DashboardIcon />,
                        },
                        {
                            text:"Availabe Categories",
                            link: '/trainee/subscribe',
                            icon:<SiDiscourse />,
                        },
                        {
                            text:"Courses",
                            link: '/trainee/courses',
                            icon:<SiDiscourse />,
                        },
                        {
                            text:"Today Added Course",
                            link: '/trainee/today-added-courses',
                            icon:<MdScreenSearchDesktop />,
                        },
                        {
                            text:"Trending Courses",
                            link: '/trainee/trending-topic',
                            icon:<HiOutlineTrendingUp />,
                        },
                       
                    ]}
                /><hr className="solid-hr"/>
                 <Nav
                    options={[
                        {
                            text:"Top Ten Courses",
                            link: '/trainee/top-ten-videos',
                            icon:<BsLaptop />,
                        },
                        {
                            text:"Favourite Instructors",
                            link: '/trainee/favourite-instructor',
                            icon:<AddressBookIcon />,
                        },
                        {
                            text:"Favourite Course",
                            link:'/trainee/favourite-course',
                            icon:<Likes2Icon />,
                        },
                        
                        
                        {
                            text:"Favourite Videos",
                            link: '/trainee/favourite-videos',
                            icon:<Likes2Icon />,
                        },
                        
                    ]}
                /><hr className="solid-hr"/>
                <Nav
                    title={isTablet ? '' : 'Member'}
                    options={[
                        {
                            text:"Member Report",
                            link:'/trainee/member/my-report',
                            icon:<ReportIcon />,
                        },
                        
                        {
                            text:"Member Subscription",
                            link: '/trainee/member/my-subscription',
                            icon:<BoxCheckIcon />,
                        },
                        
                        {
                            text: "Member Commission",
                            link: '/trainee/member/my-commission',
                            icon:<RedeemIcon />,
                        },
                    ]}
                />
            </nav>
        </>
    )
}

export default Navbar;
