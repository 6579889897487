import React from 'react';
import parse from 'html-react-parser';


const TextHtmlParser = props => {
    
    return (

        <div className="text-readonly-item">
            {props.label && (
                <label className="text-readonly-item-label">{props.label}</label>
            )}

            {props.text && (
                <div className={props.className}>
                    {parse(props.text)}
                </div>
            )}


        </div>


    );
};

export default TextHtmlParser;
