import React from "react";
import './trainer_card.css';
import TrainerImg1 from '../../assets/img/logo.svg';
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import Image from "../image";

const TrainerCard =(props)=>{
    return(
        <>
          <div class="member">
            <div className="faculty-img">

              img-fluid-height-150 

              <Image src={props.trainerimg} alt="" className='img-fluid-height-150 opacity-30' />      
            
            </div>
              
              <div class="member-content">
                <h4>{props.trainername}</h4>
                <span>{props.categeory}</span>
                <p>
                  {props.about_yourself}
                </p>
                <div class="social">
                  <a href=""><BsTwitter className="trainer-social-links" /></a>
                  <a href=""><FaFacebookF className="trainer-social-links"/></a>
                  <a href=""><BsInstagram className="trainer-social-links"/></a>
                  <a href=""><AiFillLinkedin className="trainer-social-links"/></a>
                </div>
              </div>
            </div>
        </>
    )
}


export default TrainerCard;