import Button from '../button';
import Input from '../input';
import './chat.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, {useEffect, useState} from 'react';
import useHelper from '../../hooks/helpers.hook';
import { useNavigate } from 'react-router-dom';

const ChatMsgContainer = (props) => {
  const navigate = useNavigate();
  const msgerChat = get(".msger-chat");

  const [pageNum,setPageNum] = useState(1);
  
  
  
  
  
  // Utils
  function get(selector, root = document) {
    return root.querySelector(selector);
  }
  
  function formatDate(date) {
    const h = "0" + date.getHours();
    const m = "0" + date.getMinutes();
  
    return `${h.slice(-2)}:${m.slice(-2)}`;
  }
  
  function random(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  
  useEffect(() => {
    //endConversation
    console.log("props.getMessage",props.getMessage)
    
    
      messagePassing(props.getMessage)
  }, [props.getMessage])
  
  useEffect(() => {
  
   
  
    console.log("props.appendMessages.length",props.appendMessages.length - 1,"->")
    if(props.pageNum == 1){
    for(var i=props.appendMessages.length - 1;i>=0;i--){
      //for(var i=0;i<props.appendMessages.length;i--){
      console.log("i-->",i)
      
      appendMessage_arr(props.appendMessages[i],0)
      
     
    }
  }else{
    for(var i=0 ;i<props.appendMessages.length;i++){
    appendMessage_arr(props.appendMessages[i],1)
    }
  
  }
    
    
  }, [props.appendMessages,props.roomName])
    
   
  
  
  
  
  
  
  
  const formik = useFormik({
      initialValues: {
          msgerInput:''
         
      },
      validationSchema: Yup.object({
        
      }),
      onSubmit: (values, {setSubmitting, resetForm}) => {
        formik.setFieldValue('msgerInput', "")
        
          const msgText = values.msgerInput;
          if (!msgText) return;
          console.log("socketSend",props.socket)
          if(props.messageCount == 0){
            props.setMessageCount(1)
            props.socket.emit("instructions", {"type":"firstMessage",roomName:props?.roomName});
          }
          props.socket.emit("roomMessage", { room: props.roomName,message:values.msgerInput});
          console.log("props?.sessionId",props);

          
      },
  });
  
  function appendMessage_arr(arr,type) {
    
    const msgerChat = get(".msger-chat");
  
    var side = "left";
  
    if(arr[1] === props.userInfo.uname){
     side = "right";
    }
  
   //   Simple solution for small apps
   
   var msgHTML = `
     <div class="msg ${side}-msg">
       <div class="msg-img" style="background-image: url(${ process.env.REACT_APP_IMG_URL + arr[2]})"></div>
  
       <div class="msg-bubble">
         <div class="msg-info  d-none">
      <div class="msg-info-name d-none">${arr[1]}</div>
      </div>
      
      <div class="msg-text">${arr[3]}</div>`;


    console.log("props?.ishidden",props?.ishidden,arr)
      if(props?.ishidden === 0){
        
        msgHTML += `<div class="msg-info-time">${formatDate(new Date())}</div>`;
      }else{

        const date = new Date(arr[4]);
        const formattedDateWithTime = date.toLocaleString('en-GB', { hour12: true });
        msgHTML += `<div class="msg-info-time">${formattedDateWithTime}</div>`;
  //    msgHTML += `<div class="msg-info-time">${formatDate(arr[3])}</div>`;
      }
  
        
         
     msgHTML += `</div></div>`;





  if(type == 1){
   msgerChat.insertAdjacentHTML("afterbegin", msgHTML);
  }else{
    msgerChat.insertAdjacentHTML("beforeend", msgHTML);
  }
   msgerChat.scrollTop += 500;
  }
  
  
  const messagePassing = (message)=>{
      if(message != ""){
       
          const message_array = message?.split('%!#@')
          console.log("message-->",message)

          if(message_array[3] == "endConversation"){
             
              if(message_array[4] == "instructor"){
                props.socket.emit('disconnectUser',{});
                navigate('/trainee/chat-conversation-end/' + props.sessionId);
              }
              if(message_array[4] == "trainee"){
                props.socket.emit('disconnectUser',{});
                navigate('/instructor/chat-conversation-end/' + props.sessionId);
              }

             
          }else{
            appendMessage_arr(message_array,0);
          }
  
          
    }
  
  }
  
  
  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0 ) {
      // Load more messages when the user scrolls to the top
      props.setPageNum(props.pageNum + 1);
     console.log("loadMOre")
    }
  };
  
  
  return(
      <>
  
    
      <div  class="msger border-0">
      <div className='row mx-0'>
      <div className='col-md-12 p-3 bg-light'>
      {props.userInfo.uname}</div>
        </div>
          <main class="msger-chat" onScroll={handleScroll}>
          
  
          
          </main>
          {props?.ishidden == 0 &&(
            <>
          <form  className='container p-3' onSubmit={formik.handleSubmit} >
    <div className='row'>
      <div className='col-md-10'>
    
          <Input
              
              className="mb-0"
              type="text"
              name="msgerInput"
              autoFocus={true}
              value={formik.values.msgerInput}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          />
          </div>
          <div className='col-md-2'>
             
              <Button
                  outline
                  type="submit"
                  text="Send"
                  className="w-100"
              />
              </div></div>
          </form>
     
      </>
      )}
       </div>
   
      </>
  
  )


}

export default ChatMsgContainer;