import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/button'
import CourseCard from '../../../../components/courseCard'
import InstructorImage from '../../../../assets/img/logo.svg';
import CourseImg from '../../../../assets/img/logo.svg';
import Table from '../../../../components/table';
import Pagination from '../../../../components/pagination';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import Image from '../../../../components/image';
import { useMediaQuery } from 'react-responsive';
import useHelper from "../../../../hooks/helpers.hook";
import Status from "../../../../components/status";
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
/*import Select from '../../../../components/input/select';*/
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FlowCard from '../../../../components/flowCard';
import CourseImage from '../../../../assets/img/logo.svg';
import { Popconfirm,Result,Tag } from 'antd';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import VideoCard from '../../../../components/videocard';
import ImageVideoPopup from '../../../../components/imageVideoPopup';
import CourseMainBar from '../../../../components/courseMainbar';
import VideoPlayer from '../../../../components/videoplayer';
import Bin from '../../../../assets/img/Bin.png';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';

import cash from '../../../../assets/img/cash.png';
import free_course from '../../../../assets/img/free-course.png';
import ShareVia from '../../../../components/shareVia';
import ForgotPassword from '../../../../components/forgotpassword';
import Modal from '../../../../components/modal';
import Flex from '../../../../components/flex';
import rightIcon from '../../../../assets/img/rightIcon.png'
import PlayVideoCompnent from '../../../../components/playVideoCompnent';
import useVideosCommonHooks from '../../createCourse/videosCommonHook';


const TotalViewsPageView = (props) => {
    const {formatDateDDMMYY, getShortDescription} = useHelper()
	const {text, goToLearnerSignup, goToGuruSignup} = props
	const {formatDateMMMDDYYYY} = useHelper()
	const [yearSelected, setYearSelected] = useState('Year 2022');
	const [monthSelected, setMonthSelected] = useState('Select Month');
	const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const navigate = useNavigate();
    const tableData = props?.tableData?.listData
    const customData = props?.tableData?.customdata;
    const {homeVideos} = props
    const toggleIsModal = (video) => {
        setVideoDetails(video)
        setIsModal(state => !state);
    }
    const [isModal, setIsModal] = useState(false);
    const [isShareModal, setIsShareModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);

    const [isVideoModal, setIsVideoModal] = useState(false);
    const [vidoePlayUrl, setVidoePlayUrl] = useState("");
    const commonHook = useVideosCommonHooks();
    
    const playVideo = (val) => {
        console.log(val);
       
        commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
            setVidoePlayUrl(data.data);
            setIsVideoModal(true);
          });


    };

    const closeVideo = () => {
        setIsVideoModal(false);
    };

    const closeShare = () => {
        setIsShareModal(false);
    };
    
    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text:"Total Likes",
            link:"/instructor/dashboard/total-likes",
        },
    ]

    const {
        dropdownsData,
        no_of_courses,
        no_of_videos,
        setCheckCategory,
        setCategoryId,
        getCategoryId,
        getSubCategoryId,
        setSubCategoryId,
        subCategories, 
        setSubCategories
        
    } = props

    const [selectedCourseId, setSelectedCourseId] = useState(0);
    const [isDelete, setIsDelete] = useState(false);
    const deleteCourse = (data) => {
        setSelectedCourseId(data.courseid);
        setIsDelete(true);
    }

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#8081ff",
         
        }),
      
      };


  
     
      const [selectedOption, setSelectedOption] = useState(null);
     
      const handleChange = e => {
        setSelectedOption(e);
        if(e.value == 1){
            navigate('/instructor/create-course')
        }else{
            navigate('/instructor/free-create-course')
        }
        
      }
     


  return (
    
    <>
    
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
    <div className='row' style={{background:"#fff"}}>
    



<Text
                type="H1"
                text="Total Likes"
            />
           
           

            <Table
                thead={
                    <tr>
                        <th role="columnheader">Date</th>
                        <th role="columnheader">User Name</th>
                        <th role="columnheader">Course Title</th>
                        <th role="columnheader">Module Title</th>
                        <th role="columnheader">Video Title</th>
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr role="row">
                                    <td role="cell">{formatDateMMMDDYYYY(item.updated_on)}</td>
                                    <td role="cell">{item.memberid}</td>
                                    <td role="cell">{item.fullname}</td>
                                    <td role="cell">{item.type_details}</td>
                                    <td role="cell">{item.amount}</td>
                                </tr>
                            )
                        })}
                        {props?.tableData?.customdata?.total_amount > 0 && (
                            <tr>
                                <td colSpan="4" className="text-right text-white-half">Total</td>
                                <td className="font-weight-600 text-black">
                                    RM {props?.tableData?.customdata?.total_amount}</td>
                            </tr>
                        )}
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
       

        </div>  
            


     



    </>
  )
}

export default TotalViewsPageView
    
CourseCard.defaultProps = {
    videoListOption: []
}
