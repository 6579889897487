import React, { useState } from 'react'

import Button from '../../../../components/button'
import Text from '../../../../components/text'
import { useNavigate, useParams } from 'react-router-dom'
import TopicCard from '../../../../components/topicCard'
import Table from '../../../../components/table'
import useHelper from "../../../../hooks/helpers.hook";
import Pagination from '../../../../components/pagination'
import CourseCard from '../../../../components/courseCard'
import Modal from '../../../../components/modal'
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer'
import { useMediaQuery } from 'react-responsive'
import TextHtmlParser from '../../../../components/htmlparser'
import Status from "../../../../components/status";
import Image from '../../../../components/image';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import Breadcrumbs from '../../../../components/breadcrumbs'
import FlowCard from '../../../../components/flowCard'
import CourseImage from '../../../../assets/img/logo.svg';
import TopicFlowCard from '../../../../components/topic_flow_Card'


const TopicPageView = (props) => {
  const navigate = useNavigate();
  const {courseId,moduleId} = useParams();
  const {formatDateDDMMYY,getShortDescription} = useHelper()
  const tableData = props?.tableData?.listData
  const customData = props?.tableData?.customdata;
  const [yearSelected, setYearSelected] = useState('Year 2022');
  const [monthSelected, setMonthSelected] = useState('Select Month');
  const {homeVideos} = props
  const toggleIsModal = (video) => {
    setVideoDetails(video)
    setIsModal(state => !state);
}
const [isModal, setIsModal] = useState(false);
const [showVideoEnded, setShowVideoEnded] = useState(false);
const [videoDetails, setVideoDetails] = useState(null);

const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const {text, goToLearnerSignup, goToGuruSignup} = props

    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text: 'Course',
            link: '/instructor/course',
        },
        {
            text: 'Module',
            link: '/instructor/modules/' + customData?.courseid,
        },
        {
            text: 'Topics',
            link: '/instructor/topics/' +moduleId,
        },
    ]

  return (
    <>

<Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
      <div className='row'>
        <div className='col-md-9'>
        <Text
        type="H1"
        text="Topics"
        className="mt-0 main_heading_color ml-2 mb-0"
    />
        </div>
        <div className='col-md-3'>
       <div className='btn_move'>
                    <Button
                        primary
                        type="button"
                        text="Create Topic"
                        onClick={()=>{navigate('/instructor/create-topic/' +moduleId)}}
                    />
       </div>
        </div>
      </div>
     

<TopicFlowCard
    imgsrc={customData?.thumbnail}
    course="Course"
    coursetitle={customData?.coursetitle}
    module="Modules"
    value={customData?.moduletitle}
    availablevideos="Topics"
    valuevideo={customData?.topics_video_count}
    alt="course image"
    />
   
    


     <Table
                thead={
                    <tr>
                       
                    </tr>
                }
                tbody={
                    <>
                    <div className='row'>
                        {tableData?.map(function (item, index) {
                            return (
                            <div className='col-md-12'>
                                <tr>      
                                 <TopicCard
                                     poster={item.thumbnail}
                                     moduleid={moduleId}
                                      course="Topic Name"
                                      coursetitle={item.title}
                                      availablevideos="Priority Level"
                                      valuevideo={item.priority}
                                      status="Status"
                                      active={item.statusbit}
                                      inactive="InActive"
                                      des={item.des}
                                      statusactive="Active"
                                      link="/trainee/topic/edit"
                                      edit="Edit"
                                      delete="Delete"
                                      editlink={'/instructor/edit-topic/' +moduleId}
                                      onPlay={() => {
                                        toggleIsModal(item)
                                    }}
                                    hideDescription
                                    duration={item?.videoduration}
                                    readMoreText={text?.READ_MORE}
                                    alt="topic video"
                                      />
                                    
                                  <br />
                                </tr> 
                                </div>
                            )
                        })}
                         </div>
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
{isModal &&
            
            <Modal
               className="modal-light"
                onClose={() => {
                    setIsModal(false)
                    setShowVideoEnded(false)
                }}
                onOuterClose={() => {
                    setIsModal(false)
                    setShowVideoEnded(false)
                }}
                modalContent={
                    <>
                        <div className="homevideomodal-wrapper">
                            <div className="homevideomodal-main">
                                <StaticVideoPlayer videoDetails={videoDetails} showVideoEnded={showVideoEnded}
                                                  
                                                   videoEnded={setShowVideoEnded}/>


                            </div>
                            <div className="homevideomodal-content-bottom">
                                <Text
                                    type="H2"
                                    className=""
                                    text={videoDetails?.title}
                                />
                                {/*<Text
                                    type="PARAGRAPH"
                                    className="mt-0 mb-3 text-white-half"
                                    text={videoDetails?.title}
                                />*/}
                                <TextHtmlParser
                                    className="mb-0 text-white-half font-weight-400"
                                    text={videoDetails?.des}
                                />
                            </div>
                        </div>
                    </>
                }
            />
            
            }
    </>
  )
}

export default TopicPageView
