import React, {useEffect, useState} from 'react';
import Text from '../../../../../components/text';
import NavLearnerProfile from '../../../../../components/navLearnerProfile';
import Flex from '../../../../../components/flex';
import Dropdown from '../../../../../components/dropdown/dropdownNew';
import MagazineCard from '../../../../../components/magazineCard';
import Paginnation from '../../../../../components/pagination';

import MagazineImg1 from '../../../../../assets/img/logo.svg';
import {DEFAULT_FILTERS} from "../../../../../constants/app.constants";
import useHelper from "../../../../../hooks/helpers.hook";
import Pagination from "../../../../../components/pagination";

const MagazineView = (props) => {

    const {getYearOptions, getMonthYear} = useHelper()
    const yearsOption = getYearOptions();
    const [selected, setSelected] = useState(yearsOption.at(-1));
    const tableData = props?.tableData?.listData

    const getList = (year) => {
        props.setKeys();
        DEFAULT_FILTERS.year = year.id;
        DEFAULT_FILTERS.uri = 'get_magazine';
        props.getList(DEFAULT_FILTERS)
    }

    useEffect(() => {
        if (selected) {
            getList(selected)
        }
    }, [selected])

    return (
        <>
            <Text
                type="H1"
                text="Settings"
            />
            <NavLearnerProfile/>
            <Flex justifyContent="between" className="mb-48 flex-column flex-md-row">
                <Text type="H3" text="COURSE_TSUNAMI Magazine" className="font-weight-500 mr-24 mb-0"/>
                <div className="filterby">
                    <Text type="PARAGRAPH" text="Filter by:" className="text-white-half mb-0 mr-10"/>
                    <div className="filterby-lists">
                        <Dropdown
                            secondaryDropdown
                            right
                            selected={selected}
                            setSelected={setSelected}
                            options={yearsOption}
                        />
                    </div>
                </div>
            </Flex>
            <div className="row">
                {tableData?.map(function (item, index) {
                    return (
                        <div className="col-6 col-md-3">
                            <MagazineCard
                                src={item?.thumbnail1 ? process.env.REACT_APP_IMG_URL + item?.thumbnail1 : MagazineImg1}
                                date={getMonthYear(item?.createdon)}
                                title={item?.title}
                                id={item?.srno}
                                alt={item?.title}
                                file={process.env.REACT_APP_IMG_URL + item?.pdffile}
                            />
                        </div>
                    )
                })}
            </div>
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
        </>
    );
};

export default MagazineView;

