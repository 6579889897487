import React, {useEffect} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import GuruVideoGuidelineView from "./view/GuruVideoGuidelineView";
import './videoGuideline.scss';
import useGuruVideoGuideline from "./hooks/guruVideoGuideline.hook";
import Loader from "../../../components/loader";

const GuruVideoGuideline = props => {

    const gurusVideoGuidelineHook = useGuruVideoGuideline()

    useEffect(() => {
        gurusVideoGuidelineHook.getGuruGuideLine()
    }, [])

    if (gurusVideoGuidelineHook.loading) {
        return (
            <>
                <LayoutGuru {...props} noBgContent="true">
                    <Loader/>
                </LayoutGuru>
            </>
        )
    }

    return (
        <>
            <LayoutGuru {...props} noBgContent="true">
                <GuruVideoGuidelineView {...props} {...gurusVideoGuidelineHook} />
            </LayoutGuru>
        </>
    )
}

export default GuruVideoGuideline;
