import React, { useState } from "react";
import SignupLayout from "../../../components/signuplayout";
import RegisterView from "./view/RegisterView";
import {useLocation} from "react-router-dom";
import SocialRegisterView from "./view/SocialRegisterView";
import useRegister from "../../trainee/register/hooks/register.hook";
import Modal from "../../../components/modal";
import {toast} from "react-toastify";

const Register = props => {
    const {pathname} = useLocation()
    const isSocialSignupFlow = pathname.includes('socialsignup')
    const registerHook = useRegister();
    const [isModal, setIsModal] = useState(true);

    if (isSocialSignupFlow) {
        return (
            <SignupLayout {...props}>
                <SocialRegisterView {...props} {...registerHook} />
                
            </SignupLayout>
        )
    }



    return (
        <>
         

                <RegisterView {...props} {...registerHook} setIsModal={setIsModal} toast={toast}/>
                
        </>

        
    )
}

export default Register;
