import React, { useEffect, useState } from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useCourseDropDown from './hook/courseDropDown.hook';
import CoursePageView from './views/coursePageView'
import useVideosCommonHooks from '../../instructor/createCourse/videosCommonHook';
import { useLocation } from 'react-router-dom';

const FreeCoursePage = (props) => {
  const commonHook =  useVideosCommonHooks()
  const [getCategoryId, setCategoryId] = useState(0);
  const [displayType,setDisplayType] = useState("list")


  const location = useLocation();

  useEffect(() => {
    commonHook?.getDropDowns().then(()=>{
    })
  },[])

  useEffect(() => {
    
   
    if(location.pathname === "/trainee/courses/free-courses"){
      DEFAULT_FILTERS.coursetype = 0;
    }

    if(location.pathname === "/trainee/courses/subscription-courses"){
      DEFAULT_FILTERS.coursetype = 9;
    }

    if(location.pathname === "/trainee/courses/total-subscription-courses"){
      DEFAULT_FILTERS.coursetype = 1;
    }


      //commonHook.setCategoryId(commonHook.getCategoryId)
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "l";
   
      DEFAULT_FILTERS.category_id = 0;
      DEFAULT_FILTERS.subcategory_id = 0;
      DEFAULT_FILTERS.sortkey = "asc";
      DEFAULT_FILTERS.fromdate = "";
      DEFAULT_FILTERS.todate = "";
      DEFAULT_FILTERS.page = 1;
      DEFAULT_FILTERS.perpage = 6;
     commonHook.getList(DEFAULT_FILTERS)
      console.log("Helll",commonHook.checkCategory,commonHook.getCategoryId,"--> sub",commonHook.getSubCategoryId);
    
  }, [commonHook?.dropdownsData?.categoriesOptions]);

  if(commonHook.loading || commonHook.loading) {
    return <Loader/>
  }else{
          return (
            <div>
          
                <LayoutPanel {...props}  footer>
              <CoursePageView {...props}  commonHook={commonHook}   setDisplayType={setDisplayType} displayType={displayType}/>
              </LayoutPanel>
            </div>
          )
  }

}

export default FreeCoursePage
