import React from 'react';

const HousingIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.431 13.4709L18.438 6.71243L13.6278 1.91743C12.1762 0.472186 9.8294 0.472186 8.37779 1.91743L3.57812 6.70076V13.4674C3.58199 15.514 5.24387 17.1702 7.29046 17.1669H14.7186C16.7639 17.1702 18.4252 15.5161 18.431 13.4709Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7497 8.25923C12.7317 9.22127 11.9405 9.98835 10.9783 9.97643C10.0162 9.96451 9.24416 9.17807 9.25003 8.21588C9.25591 7.25369 10.0375 6.47673 10.9997 6.47656C11.4682 6.48087 11.9157 6.67112 12.2439 7.00544C12.5721 7.33976 12.754 7.79076 12.7497 8.25923V8.25923Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.1946 1.72371C19.1952 1.3095 18.8599 0.973238 18.4457 0.972657C18.0315 0.972076 17.6952 1.30739 17.6946 1.7216L19.1946 1.72371ZM18.4376 6.71249L17.6876 6.71144C17.6874 6.91111 17.7667 7.10266 17.9081 7.24364L18.4376 6.71249ZM19.8051 9.1348C20.0985 9.42724 20.5733 9.42651 20.8658 9.13317C21.1582 8.83982 21.1575 8.36495 20.8641 8.07251L19.8051 9.1348ZM4.10615 7.23196C4.39949 6.93952 4.40022 6.46465 4.10778 6.17131C3.81533 5.87796 3.34046 5.87724 3.04712 6.16968L4.10615 7.23196ZM1.13845 8.07252C0.84511 8.36496 0.844383 8.83983 1.13683 9.13317C1.42927 9.42652 1.90415 9.42724 2.19749 9.1348L1.13845 8.07252ZM8.66797 12.8552C8.25376 12.8552 7.91797 13.1909 7.91797 13.6052C7.91797 14.0194 8.25376 14.3552 8.66797 14.3552V12.8552ZM13.3346 14.3552C13.7488 14.3552 14.0846 14.0194 14.0846 13.6052C14.0846 13.1909 13.7488 12.8552 13.3346 12.8552V14.3552ZM17.6946 1.7216L17.6876 6.71144L19.1876 6.71354L19.1946 1.72371L17.6946 1.7216ZM17.9081 7.24364L19.8051 9.1348L20.8641 8.07251L18.9671 6.18134L17.9081 7.24364ZM3.04712 6.16968L1.13845 8.07252L2.19749 9.1348L4.10615 7.23196L3.04712 6.16968ZM8.66797 14.3552H13.3346V12.8552H8.66797V14.3552Z" fill="white"/>
        </svg>
    );
};

export default HousingIcon;

HousingIcon.defaultProps = {
    width: '22',
    height: '18',
}