
import React from 'react';
import { NavLink } from "react-router-dom";
import '../navlist/navlist.scss'

const NavList = props => {
    return (
        <>
            <div className="navlist-wrp">
                <ul className={`nav ${props.className ? props.className : ''}`}>
                    {props?.options?.map(function(d, idx){
                        return (
                            <>
                                <li className="nav-item">
                                    {d?.external && (
                                        <a href={d.link} target="_blank">{d.name}</a>
                                    )}
                                    {!d?.external && (
                                    <NavLink key={idx} to={d.link} activeClassName="active" className="nav-link">
                                        {d.name}
                                    </NavLink>
                                    )}
                                </li>
                            </>
                        )
                    })}
                </ul>
            </div>
        </>
    );
};

export default NavList;

NavList.defaultProps = {
    options: [
        {
            name:"Something 1",
            link: "/"
        },
        {
            name:"Something 2",
            link: "/"
        },
        {
            name:"Something 3",
            link: "/"
        },
    ]
};
