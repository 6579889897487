import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import { useSelector } from "react-redux";
import { GURU } from "../../../../constants/app.constants";

const useLearnerMain = () => {
    const {videoId} = useParams();
    const [videoDetails, setVideoDetails] = useState(null)
    const {post} = useHttpHandler()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [isSideOpen, setIsSideOpen] = useState(true);
    const [getCourseData,setCourseData] = useState([]);
    
    const onOpenIsSide = () => {
        setIsSideOpen(isSideOpen => !isSideOpen);
    }
    const [isLike, setIsLike] = useState(0)
    const [isGuruFavourite, setIsGuruFavourite] = useState(0)
    const [isFavouriteVideo, setIsFavouriteVideo] = useState(0)
    const [favouriteVideoCount, setFavouriteVideoCount] = useState(0)
    const [videoLikesCount, setVideoLikesCount] = useState(0)
    const [guruFavouritedCount, setGuruFavouritedCount] = useState(0)
    const userInfo = useSelector(state => state?.app?.userInfo);

    const getVideoDetailsById = (id, isMyLearningPage=null) => {
        
        const path = window.location.pathname;
      

        
     

        const currentVideoId = id ? id : videoId
        setLoading(true)
        var apiUrl = isMyLearningPage ? 'trainerGetVideoDetails' : 'get_video_full_details'

        if(path.includes("/course-module-video")){
            apiUrl = "getIntroVideoDetails";
        }
        
       
        post(apiUrl, {'video_id': currentVideoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
         
            if (status.result == '200') {
                setIsLike(data[0].is_liked)
                setIsGuruFavourite(data[0].is_guru_favourite)
                setIsFavouriteVideo(data[0].is_favourite)
                setFavouriteVideoCount(data[0].video_favourite_count)
                setVideoLikesCount(data[0].likescount)
                setGuruFavouritedCount(data[0].guru_favourite_count)

                sessionStorage.setItem('videoId', data[0].videoid)
            console.log("this_video_details",data[0]);
                setVideoDetails(data[0])
            } else {
                if(userInfo?.usertypeid != GURU){
                navigate('/trainee/courses/unauthorized-view')
                console.log("this_video_details",status);
                }
                //toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
     
    }


    const favoriteVideo = () => {
        post('user_videoadd_favourite', {'videoid': videoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setFavouriteVideoCount(favouriteVideoCount + 1)
                setIsFavouriteVideo(1)
            } else if (status.result == '201') {
                setFavouriteVideoCount(favouriteVideoCount - 1)
                setIsFavouriteVideo(0)
            } else {
                toast.error(status.msg)
            }
        })
    }
    const favoriteGuru = (guru_id) => {
        post('user_add_guru_favouriate', {'guru_id': guru_id}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setIsGuruFavourite(isGuruFavourite + 1)
                setGuruFavouritedCount(guruFavouritedCount + 1)
            } else if (status.result == '201') {
                setIsGuruFavourite(isGuruFavourite - 1)
                setGuruFavouritedCount(guruFavouritedCount - 1)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        })

    }
    const likeVideo = () => {
        post('video_like', {'video_id': videoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setIsLike(1)
                setVideoLikesCount(videoLikesCount + 1)
            } else if (status.result == '201') {
                setIsLike(0)
                setVideoLikesCount(videoLikesCount - 1)
            } else {
                toast.error(status.msg)
            }
        })

    }

    useEffect(()=>{
        if(isSideOpen){
            setIsSideOpen(false)
        }

    },[videoId])

    return {
        isSideOpen,
        onOpenIsSide,
        getVideoDetailsById,
        loading,
        videoDetails,
        favoriteVideo,
        favoriteGuru,
        likeVideo,
        isLike,
        isGuruFavourite,
        isFavouriteVideo,
        favouriteVideoCount,
        videoLikesCount,
        guruFavouritedCount,
        setCourseData,
        getCourseData
    }

}
export default useLearnerMain


