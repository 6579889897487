import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import { NavLink, useNavigate } from "react-router-dom";
import Button from '../button';
import ViewContent from "../viewContent";
import { BsPlayFill } from "react-icons/bs";
import Image from "../image";
import PlayIcon from "../svg/playIcon";
import ProgressBarCard from "../programBarCard";
import Text from "../text";
import TextHtmlParser from "../htmlparser";
import Icon from "../icon";
import useHelper from "../../hooks/helpers.hook";
import ModuleImage from '../../assets/img/logo.svg';


const TopicCard =(props)=>{
	const [showDescription, setShowDescription] = useState(!props.hideDescription)
    const {secondsToMinutes, toSeconds} = useHelper()
    const duration = secondsToMinutes(props.duration)
    const watchedSeconds = toSeconds(props?.watchedupto)
    const barPercentage = props.duration ? (watchedSeconds / props.duration) * 100 : 0
	const navigate = useNavigate();
    return(
        <>
		<div class="container mb-3">
	<div class="row bg-light custom-link-hover-effects custom-instructor-details-2">
		<div class="position-relative col-12 col-md-3 lazyloaded">
		<div className="video-card">
		<div className="video-poster">
		<div className="mt-2">
		<img
	src={process.env.REACT_APP_IMG_URL + props.poster}
	alt={props.alt}
     className="guru_img_topic"
/>
</div>	
{!props.noPlay &&
<div className="video-play">
	<Button
		link
		text={
			<Icon icon={<PlayIcon/>}/>
		}
		onClick={props.onPlay}
		type="button"
	/>
</div>
}	
</div>
		</div>
		</div>
		<div class="col-md-9 p-3">
			<div className="row">
				<div className="col-md-12 mt-1 mb-1">
				<div class="d-flex flex-row bg-subcolor_2 align-items-center">
							<p class="mt-3 text-1 font-weight-bold text-dark text-uppercase"> {props.course} </p>&nbsp;
							<h4 class="mt-1 text-2  text-color-secondary">: {props.coursetitle}</h4>
						</div>
				</div>
			
			<div class="row mb-4">
				<div class="col-md-5">
					<div class="ps-md-0 mb-md-0 pe-4 me-4 border-right align-items-center">
						<div class="p-0">
							<p class="mb-3 text-1 font-weight-bold text-dark text-uppercase">{props.availablevideos}</p>
							<h4 class="mb-0 text-6 text-color-secondary">{props.valuevideo}</h4>
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="ps-md-0 mb-md-0 pe-4 me-4">
						
							<p class="mb-2 text-1 font-weight-bold text-dark text-uppercase">{props.status}</p>
							<h4 class="mb-0 text-6 text-color-secondary">{props.active}</h4>
							<div className="row">
								<div className="col-md-6">
								<ViewContent title={props.inactive} link=""/>
								</div>
								<div className="col-md-6">
								<ViewContent title={props.statusactive} link=""/>
								</div>
							</div>
						
					</div>
				</div>
				<div className="col-md-2">
				<div class="d-flex flex-column align-items-center h-100">
					<h4 class="text-2 mb-3 text-center link_color"><NavLink to={props?.editlink} className="link_color"> {props.edit} </NavLink> </h4>	
				<h4 class="text-2 text-center link_color"><NavLink to={props?.link} className="link_color"> {props.delete} </NavLink> </h4>	
					</div>
				</div>
				</div>
			</div>
			<div className="row position-relative top5">
			<div className="col-12">
			<p class="text-1 text-start">{props.des} </p>
			</div>
			</div>
		</div>
		
		
		
	</div>
	</div>

        
        </>
    )
}


export default TopicCard;

