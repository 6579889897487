import React from 'react';

const FacebookMessageIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.4805 4C13.9805 4 5.48047 12.1 5.48047 22C5.48047 27.2 7.78047 31.8 11.4805 35.1V44L19.2805 39.3C20.8805 39.7 22.6805 40 24.4805 40C34.9805 40 43.4805 31.9 43.4805 22C43.4805 12.1 34.9805 4 24.4805 4Z" fill="#448AFF"/>
        <path d="M12.4805 28L22.4805 17L27.4805 22L36.4805 17L26.4805 28L21.4805 23L12.4805 28Z" fill="white"/>
    </svg>
    );
};

export default FacebookMessageIcon;

FacebookMessageIcon.defaultProps = {
    width: '49',
    height: '48'
}