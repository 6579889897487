import React, {useEffect} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import GuruMyVideoView from "./view/GuruMyVideoView";
import useMyVideo from "./hooks/myVideo.hook";
import Loader from "../../../components/loader";
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import useGetTableList from "../../../hooks/getTableListCommonHook";
import { DEFAULT_FILTERS } from "../../../constants/app.constants";

const GuruMyVideo = props => {
    const guruMyVideoHook = useMyVideo()
    const guruUploadVideoHook = useGuruUploadVideo()
    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'guru_course_all_topic_list';
        DEFAULT_FILTERS.userid = 0;
        commonHook.getList(DEFAULT_FILTERS)
        DEFAULT_FILTERS.topic_name = commonHook.getFilterTopic;
        guruUploadVideoHook.getDropDowns()
       console.log("----->",commonHook.checkCategory);
     
    },[])
  
    
    useEffect(() => {
      if (commonHook.checkCategory) {
        commonHook.setCheckCategory(false);
        DEFAULT_FILTERS.uri = 'guru_course_all_topic_list';
        DEFAULT_FILTERS.userid = 0;
        DEFAULT_FILTERS.category_id = commonHook.getCategoryId;
        DEFAULT_FILTERS.subcategory_id = commonHook.getSubCategoryId;
        commonHook.getList(DEFAULT_FILTERS)
        console.log("----->",commonHook.checkCategory,commonHook.getCategoryId,"--> sub",commonHook.getSubCategoryId);
      } 
    }, [commonHook.checkCategory]);

    useEffect(() => {
        if (commonHook.checkFilter) {
          commonHook.setcheckFilter(false);
          DEFAULT_FILTERS.uri = 'guru_course_all_topic_list';
          DEFAULT_FILTERS.userid = 0;
          DEFAULT_FILTERS.topic_name = commonHook.getFilterTopic;
          commonHook.getList(DEFAULT_FILTERS)
         
        } 
      }, [commonHook.checkFilter]);
   
      if (commonHook?.loading) {
        return (
            <>
               <LayoutGuru {...props}>
                <Loader />
                </LayoutGuru>
            </>
        )
    } else {
    return (
        <>
            <LayoutGuru {...props} noBgContent="true">
                <GuruMyVideoView {...props} {...guruUploadVideoHook} {...commonHook} />
            </LayoutGuru>
        </>
    )
}
}

export default GuruMyVideo;
