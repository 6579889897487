import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../../../store/reducers/app.reducer";

const usePayment = () => {

    const userInfo = useSelector(state => state?.app?.userInfo)

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const onClickPayLater = () => {
        sessionStorage.setItem('yesPayLater', 'true')
        post('skip_payment').then((res) => {
            setLoading(false)
            const status = res.data.status
            let data = res.data.data

            if (status.result == '200') {
                if (userInfo?.is_profile_completed) {
                    navigate('/trainee/dashboard')
                } else {
                    navigate('/trainee/survey-question-1')
                }

            } else {
                toast.error(status.msg)
            }

        }).catch(err => {
            setLoading(false);
        })

    }

    const subscribeNow = async (packages)=> {
        await post('select_package', {package_id: packages[0].packageid}).then((res)=>{
            const status = res.data.status

            if(status.result == '200') {

            } else {

            }

        }).catch(err=>{})
    }



    const generateInvoiceApiCall = (formikActions, values) => {
        const postData = {
            fname: values?.fullname,
            email: userInfo?.email,
            mobilenumber: userInfo?.mobile,
            idvalue: userInfo?.userid,
            billingAddress:values?.billingAddress,
            ic_passportno:values?.ic_passportno,
            id_type:values?.id_type,
        }
        post('generate_invoice', postData).then((res) => {
            formikActions.setSubmitting(false)
            setLoading(false)
            const status = res.data.status
            let data = res.data.data
            data.trno = Math.floor(1000 + Math.random() * 9000)

            if (status.result == '200') {
                //paymentUpdate(data)
                //makePayment(data, values)
                localStorage.setItem('invoiceid', data.invoiceid)
                window.open(data?.payment_url)

            } else {
                toast.error(status.msg)
            }

        }).catch(err => {
            setLoading(false);
        })
    }

    const generateInvoice = async (formikActions, values, packages) => {
        console.log('generateInvoice')
        await subscribeNow(packages)
        generateInvoiceApiCall(formikActions, values)

    }

    const onClickContinue = ()=>{
        navigate('/trainee/refer-friend')
    }



    const paymentUpdate = (data) => {
        post('payment_update', data).then((res) => {
            const status = res.data.status
            const data = res.data.data

            if (status.result == '200') {
                dispatch(setUserInfo(data))
            } else {
                toast.error(status.msg)
            }
        })
    }


    return {
        onClickPayLater,
        generateInvoice,
        paymentUpdate,
        onClickContinue
    }

}
export default usePayment
