import {useState} from "react";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";

const useTopicsYouAreInterested = () => {
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [videosList, setVideosList] = useState(null)

    const getTopicsInterested = () => {
        setLoading(true)
        post('topic_intrest').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideosList(data)

            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }


    return {
        loading,
        getTopicsInterested,
        videosList
    }

}
export default useTopicsYouAreInterested


