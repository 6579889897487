import React, { useState } from 'react'
import {Modal, Popconfirm,Tag } from 'antd';
import AddTopicComponent from '../../../../../components/addTopicCompnent/addTopicCompnent';
import Button from '../../../../../components/button';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import useHttpHandler from '../../../../../hooks/httphandler.hook';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Image from '../../../../../components/image';
import useHelper from "../../../../../hooks/helpers.hook";
import PlayIcon from '../../../../../components/svg/playIcon';
import VideoPlayer from '../../../../../components/videoplayer';
import dragula from "dragula";


const TopicPageView = (props) => {
  
var finalLessonsData = props.lessonsData;

  var lessonsData = [];
  finalLessonsData.map((item,index) =>(

        lessonsData.push(
              {
                  key: index + 1 + "",
                  name: item.title,
                  moduleLevel: "item.module_level",
                  thumbnail:item.thumbnail,
                  addedvideos:"item.avaliable_videos",
                  courseId:"item.courseid",
                  moduleId:"item.moduleid",
                  created_on:"formatDateDDMMYYHms()"   
              }
          )

      ))

      const container = document.querySelector(".ant-table-tbody");
      const drake = dragula([container], {
        moves: (el) => {
            console.log("start");
        
          return true;
        },
      });
      
  const {formatDateDDMMYYHms} = useHelper();
    const [dataSource, setDataSource] = useState(lessonsData);
    const [isShowTopicPop,setIsShowTopicPop] = useState(false);
    console.log("dataSource--> abcd",dataSource);
    interface DataType {
        key: string;
        name: string;
        thumbnail:String;
        moduleLevel:String;
        addedvideos: number;
        action:string;
        courseId:string;
        moduleId:String;
    }
    const columns: ColumnsType<DataType> = [
        {
            title: 'Sort Order',
          key: 'sort',
        },
        {
          title: 'Topic',
          dataIndex:'title',
          /*render: (_, record) => (
           <>
            record.title
           </>

          )*/},
        {
            title: 'Topic Name',
            dataIndex: 'name',
        },
        {
          title: 'Created On',
          dataIndex: 'created_on',
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) =>
              dataSource.length >= 1 ? (
                <>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                 
                  <Tag color='red' key={record.moduleId}  >
              Delete
            </Tag>
                </Popconfirm>
                <Tag color='yellow' key={record.moduleId}  >
              View Details
            </Tag>
                
                </>

              ) : null,
          },
    ];



    /*
        var finalData = [];
    //if(props.serviceData?.totalRecords){
      finalData = props.selectedData;
      console.log("finalData",finalData);
      var filteredData = [];
      finalData.map((item,index) =>(

          filteredData.push(
              {
                  key: index + 1 + "",
                  name: "item.title",
                  moduleLevel: "item.module_level",
                  thumbnail:"item.thumbnail",
                  addedvideos:"item.avaliable_videos",
                  courseId:"item.courseid",
                  moduleId:"item.moduleid",
                  created_on:"formatDateDDMMYYHms()"   
              }
          )

      ))


      if(dataSource.length == 0){
        setDataSource(filteredData)
      }
    //}
    */
    
    
           
    interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
        'data-row-key': string;
    }
    
    const Row = ({ children, ...props }: RowProps) => {
        const {
          attributes,
          listeners,
          setNodeRef,
          setActivatorNodeRef,
          transform,
          transition,
          isDragging,
        } = useSortable({
          id: props['data-row-key'],
        });
      
        const style: React.CSSProperties = {
          ...props.style,
          transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
            /translate3d\(([^,]+),/,
            'translate3d(0,',
          ),
          transition,
          ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
        };
        
      
        return (
          <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
              if ((child).key === 'sort') {
                return React.cloneElement(child,{
                  children: (
                    <MenuOutlined
                      ref={setActivatorNodeRef}
                      style={{ touchAction: 'none', cursor: 'move' }}
                      {...listeners}
                    />
                  ),
                });
              }
              return child;
            })}
          </tr>
        );
    };
    

  
  
    
    const handleDelete = (key) => {
    //  props.setLevel(true);  
       console.log(key);
    //  const newData = dataSource.filter((item) => item.key !== key);
    //  setDataSource(newData);
    };

   
    
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
          setDataSource((previous) => {
            const activeIndex = previous.findIndex((i) => i.key === active.id);
            const overIndex = previous.findIndex((i) => i.key === over?.id);
            return arrayMove(previous, activeIndex, overIndex);
          });
         
        }
        
    };

    
    
    

    const handleAdd = () => {
      console.log("1231312")
      setIsShowTopicPop(true)
    };

    function closePopUp(){
      console.log("close")
      this.setState(setIsShowTopicPop(false))
      ;
    };

    

    var moduleId = props.moduleId;

    function callTopics(record){
      
        console.log("moduleId",moduleId);
        moduleId = record.moduleId;
        console.log("1231231->new",moduleId);
      //  setIsAddMoudle(true);
      setIsShowTopicPop(true);     
  
      }
  

  
    //setDataSource(filteredData)
    console.log("props.courseId_1",props);
  return (

  <div>

       <Button
         onClick={handleAdd}
         type="primary"
         style={{
          marginBottom: 16,
         }}
         text="Add Lesson"
        />



      {isShowTopicPop &&(

        <>
                {console.log("test")}

      <Modal

        closable={false}
        title=""
        centered
        open={isShowTopicPop}
        onOk={() => setIsShowTopicPop(false)}
        onCancel={() => setIsShowTopicPop(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        width={1000}
        zIndex={10000}
      >

<AddTopicComponent   setIsShowTopicPop={setIsShowTopicPop}    closePopUp={closePopUp} closePopUp = {closePopUp} setLevel = {props.setLevel}  
          setCustomData = {props.setCustomData} selectedData={props.selectedData}  />

        

      </Modal>

      </>
      )}  

      

<DndContext onDragEnd={onDragEnd} >
        <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
        >
       
       <Table
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="key"
          columns={columns}
          dataSource={dataSource}
        />

        </SortableContext>

    </DndContext>

{/*     style={{transform: "translateY(0)"}} */}


    </div>

    
  



  )
}

export default TopicPageView
