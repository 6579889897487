import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";
import Wallet from '../../wallet/Wallet';
import WithDrawView from '../../wallet/WithDraw/view/WithDrawView';
import Withdraw from '../../wallet/WithDraw';


const WalletDashboardView = (props) => {
    
   
    const {getYearOptions,post} = useHelper()
   
    const navigate = useNavigate()
   

   

  


    return (
        <>
            
            <div className="row  course-main-box"  >

                  <div className="row "  style={ {marginLeft: '0px',padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                  <div className='row mt-3 bg-wallet-top p-10' >
                          <div className="col-12 col-md-6 top-main-boxes">
                             <Wallet setWallentAmount={props.setWallentAmount}/>
                          </div>


                          <div className="col-12 col-md-6">
                              <DashGuruStatus
                                  title="Add to Wallet History"
                                  onClick={() => {
                                      navigate('/trainee/wallet/added-wallet-history')
                                  }}
                                  {...props}
                              />
                                <div className='mt-3'>
                                        <DashGuruStatus
                                        title="Wallet Dedication History"
                                        onClick={() => {
                                            navigate('/trainee/wallet/deducted-wallet-history')
                                        }}
                                        {...props}
                                        />
                              </div>
                          </div>

                        </div>


                        <div className='row mt-3 bg-withdraw-top p-10'>
                          <div className="col-12 col-md-6 top-main-boxes">
                             <Withdraw walletAmount={props.walletAmount}/>
                          </div>


                          <div className="col-12 col-md-6">
                              <DashGuruStatus
                                  title="Withdraw History"
                                  onClick={() => {
                                      navigate('/trainee/wallet/added-wallet-history')
                                  }}
                                  {...props}
                              />
                          </div>
                          </div>

                     
                        

                       
                      </div>            
            </div>    
                    
       
          

                    
       
        </>
    );
};


export default WalletDashboardView;

const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}

const DashGuruStatusTitle = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text={props.buttonTitle}  onClick={props.onClick}/>
        </div>
    )
}