import React,{useEffect} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import Loader from "../../../components/loader";
import useGetTableList from '../../../hooks/getTableListCommonHook';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';

import InstructorCoursesPageView from './views/instructorCoursesPageView';
import useMyVideo from '../../instructor/myVideo/hooks/myVideo.hook';
import LayoutPanel from '../../../components/layoutPanel';
import { useParams } from 'react-router-dom';

const InstructorCoursesPage =(props)=>{
  const guruMyVideoHook = useMyVideo()
  const {userId} = useParams();
  const commonHook = useGetTableList();
  useEffect(()=>{
      commonHook.setKeys();
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = userId;
      DEFAULT_FILTERS.coursetype = 1;
      commonHook.getList(DEFAULT_FILTERS)
      guruMyVideoHook.getVideos()
  },[])
  

 
  return (
      <>
        <LayoutPanel {...props}>
      <InstructorCoursesPageView  {...props} {...commonHook} />
      </LayoutPanel>
      </>
  )
}

export default InstructorCoursesPage;
