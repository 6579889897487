import React, {useEffect, useState} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import useHelper from "../../../hooks/helpers.hook";
import {useSelector} from "react-redux";
import useLogin from "../../authentication/login/hooks/login.hook";
import useGuruDashboard from "./hooks/guruDashboard.hook";
import InstructorDashboardView from "./view/instructorDashboardView";
import { getFirebaseToken } from '../../../config/firebase';
import useVideosCommonHooks from "../createCourse/videosCommonHook";
import Loader from "../../../components/loader";

const InstructorDashboard = props => {
    const guruDashboardHook = useGuruDashboard()
    const userInfo = useSelector(state => state.app.userInfo)
    
    const commonHook = useVideosCommonHooks();
    const {
        redirectTo,
    } = useLogin()

const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');
    useEffect(() => {
        handleGetFirebaseToken();
        guruDashboardHook.getDashboardDetails()
     /*   commonHook.promiseService({"uri":"getInstructorChatDashboard"}).then((data) => {
            guruDashboardHook.setchatDashboardData(data.data.data[0]);
        })*/
    }, [])
    
    const handleGetFirebaseToken = () => {
        getFirebaseToken()
          .then((firebaseToken) => {
            
            if (firebaseToken) {
                console.log('Firebase token-->: ', firebaseToken);
                commonHook.getCustomData({"uri":"saveToken",'token':firebaseToken});
                setShowNotificationBanner(false);
            }
          
        }).catch((err) => console.error('An error occured while retrieving firebase token. ', err))
      }
  
      if (commonHook?.loading) {
  
        return (
            <>
               <LayoutGuru {...props}>
                <Loader></Loader>
                </LayoutGuru>
            </>
        )
      
      } else {
    return (
        <>
            <LayoutGuru {...props} footer>
                <InstructorDashboardView {...props} userInfo={userInfo} guruDashboardHook={guruDashboardHook} commonHook={commonHook}/>
            </LayoutGuru>
        </>
    )
      }
}

export default InstructorDashboard;
