import React, { useEffect, useState } from 'react';
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import PlanSummaryCard from '../../../../components/planSummaryCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreditDebitCard from '../../../../components/PaymentMethods/creditDebitCard';






const PaymentView = (props) => {
    
    const {text, onClickPayLater} = props
    return (
        <>
            <Text
                type="H2"
                text={text?.SUBSCRIBE_TO_OUR_MONTHLY_PACKAGE}
                className="mb-40"
            />
            <PlanSummaryCard {...props} />
         
            <Tabs className="tabs-wrapper">
          
                <div className="tabs-content">
                    <TabPanel className="tab-panel-content">
                        <CreditDebitCard {...props} />
                    </TabPanel>
          
                </div>
            </Tabs>
            <Flex
                justifyContent="center"
                className="text-center col-12 col-md-9 mx-auto"
            >
                <Text
                    type="PARAGRAPH"
                    text={
                    <>
                        {text?.IF_YOU_WISH_TO_PAY_LATER_YOU_WILL_HAVE_LIMITED_ACCESS_TO_OUR_100000_VIDEOS_AND_CONTENTS_WISH_TO_PROCEED}
                        &nbsp;<Button linkSecondary text={text?.YES_PAY_LATER} style={{fontSize: 'inherit'}}  onClick={onClickPayLater} />
                    </>}
                />


            </Flex>
        </>
    );
};

export default PaymentView;

