import React from "react";
import VideoCard from "../videocard";
import './topTenVideoCard.scss';
import Avatar from '../avatar'
import Text from "../text";
import RibbonIcon from "../svg/ribbonIcon";
import StarIcon from "../svg/starIcon";

const TopTenVideoCard = props => {
    return(
        <>
            <div className="top-ten-video-card" onClick={props.onClick}>
                <div className="tag-ribbon">
                    <RibbonIcon width="29" height="42" bgcolor="#ECCB09" />
                    <span className="tag-ribbon-icon"><StarIcon width="14" height="14" /></span>
                </div>
                <VideoCard
                    noCaption
                    noPlay
                    poster={props.videoPoster}
                    alt={props.title}
                    duration={props.duration}
                    imgHeight={props.imgHeight}
                    imgWidth={props.imgWidth}
                    watchedupto={props?.watchedupto}
                />
                <div className="top-ten-video-details">
                    <div className="top-ten-video-details-number">
                        {props.number}
                    </div>
                    <div className="top-ten-video-details-content">
                        <Text type="H3" text={props.title} className="top-ten-video-details-content-title" />
                        {props.by && <Text type="PARAGRAPH" text={`by ${props.by}`} className="top-ten-video-details-content-by" />}
                        {props.shortDescription && <Text type="PARAGRAPH" text={`${props.shortDescription}...`} className="top-ten-video-details-content-short-desc" />}
                        {props.views > 0 && <Text type="PARAGRAPH" text={`${props.views} ${props.views > 1 ? 'Views':'View'}`} className="top-ten-video-details-content-views" />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopTenVideoCard;
