import {useState} from "react";
import { useNavigate } from "react-router-dom";

const useGuruRegister = () => {
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);
    const ShowPasswordClick = () => {
        setShowPassword(state => !state);
    }
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const ShowConfirmPasswordClick = () => {
        setShowConfirmPassword(state => !state);
    }
    const goToRegisterAsLearner = () => {
        navigate('/trainee/signup')
    }


    return {
        showPassword,
        ShowPasswordClick,
        showConfirmPassword,
        ShowConfirmPasswordClick,
        goToRegisterAsLearner
    }

}
export default useGuruRegister


