import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import axios from "axios";
import {useState} from "react";
import useUploadPhoto from "../../../../hooks/upload.hook";
import useTranslations from "../../../../hooks/translations.hook";

const usePersonalInformation = () => {
    const {uploadPhoto} = useUploadPhoto()
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {post} = useHttpHandler()
    const goToWorkExperience = () => {
        navigate('/instructor/work-experience')
    }
    const goBackToOTPVerification = () => {
        navigate('/instructor/otp-verification')
    }
    const onClickFillUpLater = () => {
        navigate('/')
    }
    const updateGuruProfile = (data) => {
        setIsSubmitting(true)
        let postData = {
            aboutyourself: data.aboutyourself,
            fullname: data.fullname,
            passportno: data.passportno,
            preferredname: data.preferredname,
            facebook:data.facebook,
            twitter:data.twitter,
            linkedin:data.linkedin,
            instagram:data.instagram
        }
        uploadPhoto(data).then((responseData) => {
            if (responseData?.data?.data) {
                postData.profilePhoto = responseData.data.data[0]
            } else {
                postData.profilePhoto = data.pic
            }
            post('updateInstructorPersonalProfile', postData).then((res) => {
                setIsSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    goToWorkExperience()
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {setIsSubmitting(false);})
        })

    }


    return {
        goToWorkExperience,
        goBackToOTPVerification,
        updateGuruProfile,
        onClickFillUpLater,
        isSubmitting
    }

}
export default usePersonalInformation


