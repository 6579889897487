import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet'
import { useMediaQuery } from 'react-responsive';
import HeaderLearner from '../headerLearner';
import NavbarLearner from '../navbarLearner';
import FooterLearner from '../footerLearner';
import './layoutPanel.scss';
import GlobalInfoModal from '../globalInfoModal';
import useSubscribePremiumModal from '../subscribePremiumModal/hooks/subscribePremiumModal.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidebar from '../sidebar';
import BoxCheckIcon from '../svg/boxCheckIcon'
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList
}from "react-icons/fa";
import { SiDiscourse } from 'react-icons/si';
import SmartDisplayIcon from '../svg/smartDisplayIcon';
import RedeemIcon from '../svg/redeemIcon';
import ReportIcon from '../svg/reportIcon';
import AddressBookIcon from '../svg/addressBookIcon';
import { BsLaptop } from 'react-icons/bs';
import { HiOutlineTrendingUp } from 'react-icons/hi';
import { MdScreenSearchDesktop } from 'react-icons/md';
import DashboardIcon from '../svg/dashboardIcon';
import Likes2Icon from '../svg/likes2Icon'
import useHelper from '../../hooks/helpers.hook';
const LayoutPanel = ({props, children, text, footer}) => {
   const {isFreemiumMember} = useHelper();
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  var dashboard = false;
  /*
  if(window.location.pathname == "/trainee/dashboard" ||window.location.pathname == "/trainee/trending-topic" ||window.location.pathname == "/trainee/today-added-courses" ||window.location.pathname == "/trainee/subscribe-information" ||window.location.href.indexOf("/trainee/categeory-payment") > -1  ||window.location.pathname == "/trainee/settings/reset-password" || window.location.pathname == "/trainee/settings/profile"|| window.location.pathname == "/trainee/purchase-categeory" || window.location.href.indexOf("/trainee/purchase-sub-categeory/") > -1 ){
    dashboard = false;  
  }
  
  if(params.get("payment_intent_client_secret")){
    dashboard = false;  
  }
  //window.location.href.indexOf("/trainee/payment-success/") == -1
  */
  


  const userInfo = useSelector(state => state?.app?.userInfo)

  console.log("params",window.location.pathname,userInfo,dashboard,"userInfo?.usertypeid",userInfo?.usertypeid);

  if(userInfo?.usertypeid == 8001 || userInfo?.usertypeid == 8000 || userInfo?.usertypeid == 8004){
    dashboard = false;  
  }
  const [isOpenModal, setIsOpenModal] = useState(false);
  


    const subscribePremiumModalHook = useSubscribePremiumModal()
    const navigate = useNavigate()
    const closeModal = () => {
        props.setIsModal(false)
        if (props.navigateBack) { 
            navigate(-1)
        }
    }
    const onClickOuterClose = () => {
        if (props.outerClose) {
            props.setIsModal(false)
        }
    }

    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const [sideMenu, setSideMenu] = useState(false);
    const SideMenuToggle = () => {
        setSideMenu(state => !state);
    }


    const hideItem = userInfo?.user_status_id == 2 || userInfo?.user_status_id == 0

    useEffect(() => {
        if (hideItem ) {
            setIsOpenModal(true)
            //navigate('/instructor/dashboard')
        }
    }, [])

    const FreemiumArray=[
       
        {
            name: "Dashboard",
            path:'/trainee/dashboard',
            icon:<DashboardIcon />,
            items:[]
        },
        {
            name:"Subscribe Now",
            path: '/trainee/subscribe',
            icon:<SiDiscourse />,
            items:[]
        },
        {
            name:"Courses",
            path: '/trainee/courses',
            icon:<SiDiscourse />,
            items:[]
        },
        {
            name:"Interact With Instructors",
            path: '/trainee/instructors',
            icon:<AddressBookIcon />,
            items:[]
        },

        {
            name:"Favourite",
            path: '/trainee/favourite',
            icon:<Likes2Icon />,
            items:[]
        },

      


       
       
    ]


    const PremimumArray=[
       
        {
            name: "Dashboard",
            path:'/trainee/dashboard',
            icon:<DashboardIcon />,
            items:[]
        },
       
        {
            name:"Courses",
            path: '/trainee/courses',
            icon:<SiDiscourse />,
            items:[]
        },
        {
            name:"Interact With Instructors",
            path: '/trainee/instructors',
            icon:<AddressBookIcon />,
            items:[]
        },

        {
            name:"Favourite",
            path: '/trainee/favourite',
            icon:<Likes2Icon />,
            items:[]
        },

        {
            name:"Members",
            path:'/trainee/members',
            icon:<ReportIcon />,
            items:[]
        },


        {
            name:"Wallet",
            path:'/trainee/wallet',
            icon:<ReportIcon />,
            items:[]
        },



       
       
    ]

    const isFreemium = isFreemiumMember()

    


    var menuItem = [];

    if(isFreemium){
        menuItem = FreemiumArray;  
    }else{
        menuItem = PremimumArray;
    }

    return (
        <>
            <Helmet>
            <title>COURSETSUNAMI</title>
            <meta name="description" content="COURSETSUNAMI" />
            </Helmet>
            <div className="layout-guru-wrapper">
                <HeaderLearner text={text}/>

                <div className={`lp-main-wrapper ${isTablet ? sideMenu ? '' : 'lp-main-tablet' : ''}`}>
                   {/* {!isMobile && (
                        <div className="lp-sidebar-wrapper">
                            <NavbarGuru
                                sideMenu={sideMenu}
                                SideMenuToggle={SideMenuToggle}
                                text={text}
                                {...text}
                            />
                        </div>
                    )}
                    <div className={`lp-content-main-wrapper ${noBgContent ? 'no-content-bg' : ''} `}>
                        <div className="main-wrapper">
                            {children}
                        </div>
                    </div>
                   */}


                    <Sidebar menuItem={menuItem}>
                            <div className="lp-content-main-wrapper" style={{"width" : "100%"}}>
                                <div className="main-wrapper mt-0" style={{"width" : "100%"}}>
                                    {children}
                                    {footer && <FooterLearner {...text} />}
                                </div>
                            </div>
                    </Sidebar>

                </div>
                

                {/*
                <div className={`lp-main-wrapper ${isTablet ? sideMenu ? '' : 'lp-main-tablet' : ''}`}>
                    {!isMobile &&
                        <div className="lp-sidebar-wrapper">
                            <NavbarLearner
                                sideMenu={sideMenu}
                                SideMenuToggle={SideMenuToggle}
                                text={text}
                            />
                        </div>
                    }

                    
                    <div className="lp-content-main-wrapper">
                        <div className="main-wrapper">
                            {children}
                            {footer && <FooterLearner {...text} />}
                        </div>
                    </div>
                </div>
                */}

            </div>
            
            {dashboard && (
                    <GlobalInfoModal
                    smallModal={true}
                    title={props?.text?.BECOME_SUBSCRIBER_MODAL_CONTENT}
                    canClose={true}
                    setIsModal={subscribePremiumModalHook.setIsPaymentUnderProcess}
                    onClick={subscribePremiumModalHook.goToPayment}
                    buttonText={props?.text?.SUBSCRIBE_NOW}
                    navigateBack
                />        
                )

            }
            

        </>
    );
};

export default LayoutPanel;
