
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../../hooks/httphandler.hook";

const useMyCommission = () => {
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [commissionDetails, setCommissionDetails] = useState(null)

    const getCommissionDetails = () => {
        setLoading(true)
        post('guru_my_comission').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setCommissionDetails(data)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getCommissionDetails()
    }, [])


    return {
        loading,
        commissionDetails,
        getCommissionDetails
    }

}
export default useMyCommission



