import React from 'react';

const ViewEyeIcon = props => {
    return (
        <>
            <svg width={props.width} height={props.height} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 5.5C15 7.985 11.866 10 8 10C4.134 10 1 7.985 1 5.5C1 3.015 4.134 1 8 1C11.866 1 15 3.015 15 5.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.751 5.49962C9.77262 6.13895 9.44388 6.73918 8.89353 7.06524C8.34318 7.39131 7.65882 7.39131 7.10848 7.06524C6.55813 6.73918 6.22939 6.13895 6.251 5.49962C6.22939 4.8603 6.55813 4.26007 7.10848 3.934C7.65882 3.60794 8.34318 3.60794 8.89353 3.934C9.44388 4.26007 9.77262 4.8603 9.751 5.49962V5.49962Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </>
    );
};

export default ViewEyeIcon;

ViewEyeIcon.defaultProps = {
    width: '16',
    height: '11'
}