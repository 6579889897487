import React, {useEffect, useState, useRef} from "react";
import './videoPlayers.scss';
import MainVideoPlayersImage from '../../assets/images/main-video-players-image.jpg'

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoJs = props => {


    const videoRef = useRef()
    const playerRef = useRef(null);

    const {options, onReady} = props;

    
    useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });
            let myButton = player.controlBar.addChild('button', {}, 1);

            let myButtonDom = myButton.el();

// now setting the innerhtml of our button to the DOM
            myButtonDom.innerHTML = '<span class="next-icon"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.62 1.48926L12.2067 7.22259C12.7025 7.57585 12.9969 8.1471 12.9969 8.75593C12.9969 9.36475 12.7025 9.93599 12.2067 10.2893L4.62 16.5559C3.979 17.0684 3.10867 17.1883 2.353 16.8682C1.59732 16.5481 1.07789 15.8396 1 15.0226V3.02259C1.07789 2.20563 1.59732 1.49709 2.353 1.177C3.10867 0.856918 3.979 0.976796 4.62 1.48926Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '            <path d="M16.9987 1.02246V17.0225" stroke="white" stroke-width="1.5" stroke-linecap="round"/>\n' +
                '        </svg></span>';

// setting the onClick method of our button to the DOM
            myButtonDom.onclick = function () {
                props?.goToNextVideo()
            };

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            // const player = playerRef.current;

            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <>
            <div data-vjs-player>
                <video ref={videoRef} className='video-js vjs-big-play-centered'/>
            </div>
        </>
    )
}

export default VideoJs;

VideoJs.defaultProps = {
    src: MainVideoPlayersImage,
    alt: "Preview",
    timeCurrent: '0:00',
    timeDuration: '5:00',
}
