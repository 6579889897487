import { StyleSheet, Text, View, Pressable,Image } from "react";
import './monthButton.scss'
const MonthButton = (props) => {
  
  const item = {...props.item}
    return (
           <> 


                  <button   className={item.monthName == props.monthActive ? "monthSlotActive" : "monthSlotInactive" } onClick={()=>{ props.monthButtonClick(props.item)}}>
                  <text className="">{item.monthName + " - " + item.yearName}</text>
                  </button>
                

                

                
             </>
 );
};


export default MonthButton;