import React from 'react';
import './input.scss'

const Input = props => {
    return (
        <div className={`form-field ${props.lineBottom ? 'form-field-line-bottom' : props.tertiary ? 'form-field-tertiary' : props.secondary ? 'form-field-secondary' : ''} ${props.className ? props.className : ''}`}>

            {props.label && <label className={`form-label ${props.labelClass ? props.labelClass : '' }` }>{props.label} {props.mandatory && <span className="text-danger">*</span>}</label>}

          
            <input
                className={` bg-light form-control fs-6 ${props.value ? 'form-control-value' : ''} ${props.formControlClassName ? props.formControlClassName : ''} ${props.errormessage ? 'error' : ''}`}
                type={props.type}
                name={props.name}
                value={props.value}
                id={props.id}
                placeholder={props.placeholder}
                onChange={props.onChange}
                readOnly={props.readonly}
                disabled={props.disabled}
                required={props.required}
                autoFocus={props.autoFocus}
                maxLength={props.maxLength}
            />
            {props.icon && <div className="form-icon mt-2">{props.icon}</div>}
            {props.errormessage && props.type != 'checkbox' && <div className="message-error"><small>{props.errormessage}</small></div>}
        </div>
    );
};

export default Input;
