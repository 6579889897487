import React from "react";
import Nav from "../nav";
import './navLearnerProfile.scss';

const NavLearnerProfile = props => {
    const {text} =props
    return(
        <>
            <div className="nav-tab-panel-wrp">
                <Nav
                    options={[
                        {
                            text:"My Profile",
                            link: "/trainee/settings/profile",
                        },
                        {
                            text:"Bank Details",
                            link: "/trainee/settings/bank-details",
                        },
                        {
                            text:"Reset Password",
                            link: "/trainee/settings/reset-password",
                        },
                    ]}
                />
            </div>
        </>
    )
}

export default NavLearnerProfile;
