import React from 'react';
import Text from '../text'
import './footerLearner.scss';
import NavList from '../navlist';


const FooterLearner = () => {
    const OptionsTerm = [
        {
            name: "Term of Use",
            link: "/termOfUse"
        },
        {
            name: "Privacy Policy",
            link: "/privacyPolicy"
        },
    ]
    return (
        <>{/*
              <footer className="footer-learner">
                <div className="container">
                    
                    <div className="footer-main">
                      
                        <Text
                            type="PARAGRAPH"
                            text="© 2022 COURSE_TSUNAMI. All right reserved."
                            className="copyrights"
                        />              
                    </div>
                </div>
            </footer> */}
        </>
    );
};

export default FooterLearner;
