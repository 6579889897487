import React, { useEffect } from 'react'
import LayoutGuru from '../../../components/layoutGuru'
import CreateCoursePageView from './views/createCoursePageView'
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import Loader from "../../../components/loader";
import CreateCourseStepBPageView from './views/createCourseStepBPageView';


const CreateCourseStepBPage = (props) => {
    
    const guruUploadVideoHook = useGuruUploadVideo()
    useEffect(()=>{
        guruUploadVideoHook.getDropDowns()
    },[])
  
    if(guruUploadVideoHook.loading) {
        return (
            <>
                <LayoutGuru {...props} noBgContent="true">
                    <Loader />
                </LayoutGuru>
            </>
        )
    }

 
  return (
    <div>
        <LayoutGuru {...props}>
      <CreateCourseStepBPageView {...props} guruUploadVideoHook={guruUploadVideoHook} />
      </LayoutGuru>
    </div>
  )
}

export default CreateCourseStepBPage
