import React, {useEffect, useState} from "react";
import SignupLayout from "../../../components/signuplayout";
import {useParams, useSearchParams} from "react-router-dom";
import {paymentStatusCodes} from "./statusCodes";
import PaymentFailedView from "./view/PaymentFailedView";
import PaymentSuccessView from "./view/PaymentSuccessView";
import usePayment from "../payment/hooks/payment.hook";
import useHttpHandler from "../../../hooks/httphandler.hook";

const StatusView = props => {
    if (props.payStatusSuccess) {
      //  return <PaymentSuccessView {...props}/>
    } else {
      //  return <PaymentFailedView {...props}/>
    }
}

const PaymentSuccess = props => {

    const {post} = useHttpHandler()
    const paymentHook = usePayment()
    const [payStatusSuccess, setPayStatusSuccess] = useState(null)
    const [payStatusMessage, setPayStatusMessage] = useState('')
    const [searchParams] = useSearchParams();

    /*
        const queryParams = {
            curlec_method,
            fpx_fpxTxnId,
            fpx_sellerExOrderNo,
            fpx_fpxTxnTime,
            fpx_sellerOrderNo,
            fpx_sellerId,
            fpx_txnCurrency,
            fpx_txnAmount,
            fpx_buyerName,
            fpx_buyerBankId,
            fpx_debitAuthCode,
            fpx_type,
            fpx_notes
        }*/


        const paymentIntent =  searchParams.get('payment_intent');


        console.log("payStatus",paymentIntent);

        let message = "Success";
        let status = true;

        post('paymentIntent',{"paymentIntent":paymentIntent}).then((res)=>{
            console.log("msg",res)
        })


    useEffect(() => {

      

     



        /*

        let transactionInfo = {
            invoice_id: localStorage.getItem('invoiceid'),
            trno: searchParams.get('fpx_fpxTxnId'),
            status: status ? 'SUCCESS' : 'FAILURE',
            remark: payStatus[0]?.code + ', ' + message,
            fpx_fpxTxnId : searchParams.get('fpx_fpxTxnId'),
            fpx_sellerExOrderNo: searchParams.get('fpx_sellerExOrderNo'),
            fpx_fpxTxnTime: searchParams.get('fpx_fpxTxnTime'),
            fpx_sellerOrderNo: searchParams.get('fpx_sellerOrderNo'),
            fpx_sellerId: searchParams.get('fpx_sellerId'),
            fpx_txnCurrency: searchParams.get('fpx_txnCurrency'),
            fpx_txnAmount: searchParams.get('fpx_txnAmount'),
            fpx_buyerName: searchParams.get('fpx_buyerName'),
            fpx_buyerBankId: searchParams.get('fpx_buyerBankId'),
            fpx_type: searchParams.get('fpx_type'),
            fpx_debitAuthCode: searchParams.get('fpx_debitAuthCode'),
            fpx_notes: searchParams.get('fpx_notes'),
        }
        */
     //   console.log('transactionInfo', transactionInfo)
        //paymentHook.paymentUpdate(transactionInfo)

      //  setPayStatusSuccess(status)
       // setPayStatusMessage(message)
       // localStorage.removeItem('invoiceid')

    }, [])
    

/*
    return (
        <>
            <SignupLayout {...props}>
                <StatusView
                    payStatusSuccess={payStatusSuccess}
                    payStatusMessage={payStatusMessage}
                    {...props}
                    onClickContinue={paymentHook.onClickContinue}
                />
            </SignupLayout>
        </>
    )
*/
}

export default PaymentSuccess;
