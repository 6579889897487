import React from 'react'
import { NavLink } from 'react-router-dom'
import { FiArrowRight } from "react-icons/fi";


const ViewContent = (props) => {
  
  return (
   <>
   <NavLink to={props?.link}>

    <p className='flow-links link-color'> {props.title}</p>
    </NavLink>
   </>
  )
}

export default ViewContent
