import {useNavigate} from "react-router-dom";
import useTranslations from '../../../hooks/translations.hook';
import {useState} from "react";

const useShareVia = () => {
    const navigate = useNavigate()
    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    
    const [isModal, setIsModal] = useState(false);
    const openMore = () => {
        setIsModal(true);
    }
    return {
        isModal,
        setIsModal,
        openMore,
    }

}
export default useShareVia