import React, { useState } from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../components/text';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import TopicInterestItem from '../../../../components/topicInterestItem';
import Select from '../../../../components/input/select';
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useNavigate} from "react-router-dom";
import GoBackButton from "../../../../components/goBackButton";
import NavSide from '../../../../components/navSide';
import Modal from 'react-bootstrap/Modal';
import { BiExit } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import ConfirmMesage from '../../../../components/confirm_sucess';
import rightIcon from '../../../../assets/img/information.png'
import useNewCommonHook from '../../../../hooks/newCommonHook';
import SelectNew from '../../../../components/input/selectnew';

const WorkExperienceView = (props) => {
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const [showError, setShowError] = useState(false);
    const {text, dropdownsData, setSelectedOptions, goToBankDetails, selectedTopicsOfInterestCategory, profileDetails} = props
    const newHook = useNewCommonHook();
    const [request,setRequest] = useState("");

    var final = selectedTopicsOfInterestCategory;
    
    function removeDuplicates(arr) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }


    final = removeDuplicates(final);

    for(var i=0;i<props.finalSelected.length;i++){
        final = final.filter(e=>e !== props.finalSelected[i]);
    }

    console.log("final",final,selectedTopicsOfInterestCategory)
    

    const formik = useFormik({
        initialValues: {
           // sampleFile: [],
            yearsOfExperience: profileDetails?.years_of_exp ? profileDetails?.years_of_exp : '',
            occupation: profileDetails?.occupationid ? profileDetails?.occupationid : '',
        },
        validationSchema: Yup.object({
            //sampleFile:Yup.array().min(1,text?.PLEASE_UPLOAD_VIDEO).required(text?.PLEASE_UPLOAD_VIDEO),
            yearsOfExperience: Yup.string().required('Please enter a valid years of experience'),
            occupation: Yup.string().required('Please enter a valid occupation'),
        }),
        onSubmit: (values, actions) => {

            
            if(final.length > 0){
                setShowError(false);

            
            actions.setSubmitting(true)
            let postData = {
                
                option_id: final.toString(),
                years_of_exp: values.yearsOfExperience,
                occupationid: values.occupation
            }

            post('instructorWorkExperienceUpdate', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                   goToBankDetails();
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {actions.setSubmitting(false);})
            }else{
                setShowError(true);
                actions.setSubmitting(false)
            }
        },
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const courseData = [
        {
            value: -1,
            text: 'All Courses',
            
          },
          {
            value: 0,
            text: 'Free Course',
            
          },
        {
          value: 1,
          text: 'Paid Course',
          
        },
       
        {
            value: 2,
            text: 'Private Course',
          
        },
       
      ];
   

    return (
        <>

<div class="p-3 mb-3">

<div class="card-body">

  <div class="pt-4">
    <h5 class="card-title text-center pb-0 fs-4">{text?.WORK_AND_EXPERIENCE}</h5>
    <p class="text-center small">Enter your personal details to create account</p>
<NavSide
    title=""
options={[
    {text:"", link: '/instructor/personal-information'},
    {text: "", link: '/instructor/work-experience'},
    {text: "", link: '/instructor/upload-attachments'},
    {text: "", link: '/instructor/bank-details'},
]}
/>
  </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                   
                    <div className="col-6">
                        <div className="formBox">
                            <Input
                                label={text?.YEARS_OF_EXPERIENCE}
                                type="number"
                                name="yearsOfExperience"
                                placeholder=""
                                maxLength={2}
                                value={formik.values.yearsOfExperience}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.yearsOfExperience && formik.errors.yearsOfExperience ? (
                                    formik.errors.yearsOfExperience
                                ) : null}
                            />
                        </div> 
                    </div>
                    <div className="col-6">
                        <div className="">
{/*
                        <SelectNew  
                            tertiary
                            label={text?.OCCUPATION}
                            name="occupation"
                            placeholder="Select Occupation"
                            value={formik.values.occupation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            options={dropdownsData?.occupationOptions}
                            errormessage={formik.touched.occupation && formik.errors.occupation ? (
                                formik.errors.occupation
                            ) : null} 
                        />
                        */}  

                        
                    
                                <Select
                                tertiary
                                    label={"Occupation"}
                                    name="occupation"
                                     placeholder="Select Category"
                                    value={formik.values.occupation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    mandatory
                                    options={dropdownsData?.occupationOptions}
                                    errormessage={formik.touched.occupation && formik.errors.occupation ? (
                                        formik.errors.occupation
                                    ) : null}
                                />
                     
                        </div>
                    </div>
                   
                    <div className="col-12">
                        <div className="formBox">
                            <div className="form-field">
                                <label className="form-label" >Select the topic of your Interest<span class="text-danger">*</span> 

                                {showError &&
                                <span className="text-danger"  >Please select any one category</span>    
                                }
                                </label>

                                <div className="topic-interest-group">
                                
                                   
                {dropdownsData?.topicOfInterest.map((item,index)=>{

                        let selectVal = false;
                        for(var i = 0;i<selectedTopicsOfInterestCategory.length;i++){
                            if(item.catId  == selectedTopicsOfInterestCategory[i]){
                                selectVal = true;
                                break;
                            }
                        }


                        const imgsrc = process.env.REACT_APP_IMG_URL+ item?.catPic
                        return (
                            <TopicInterestItem
                                text={item?.catName}
                                catId={item?.catId}
                                src={imgsrc}
                                setSelectedOptions={setSelectedOptions}
                                isSelected = {selectVal}
                                dropdownsData = {dropdownsData}
                            
                            />
                        )
                })}
                            </div>
                        </div>
                        </div>
                    </div>

                </div>


                <Flex justifyContent="center" className="mb-2 mt-2">
                <Button
                        type="button"
                        text={text?.GOBACK}
                        large
                        light
                        outline
                        className=""
                        onClick={()=>{navigate('/instructor/work-experience')}}

                    />
                    <Button
                        type="submit"
                        className="btn-verification"
                        text={text?.PROCEED}
                        large
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />

                 
                </Flex>


            
            </form>
            <div className='row'>

                <div className='col-md-12 text-center'>
                <a className='btn-Req-Cat'  onClick={handleShow}>Request Categeory</a>        
                </div>
            
            </div>
            
          
            </div>
            </div>
     
     
      <Modal show={show} onHide={handleClose}>



        <div className="row pop-up-header">
            <div class="col-md-12 div-center">
                <img src={rightIcon} class="icon-50" alt="" />
            </div>
        </div>
        <div className="row popup-padding mt-20">

                    <div className="col-md-1">

                    </div>
                    <div className="col-md-10">
                       <div className="formBox">
                            <Input
                                label="Request Categeory"
                                type="text"
                                name="RequestCategeory"
                                placeholder="Request Categeory"
                                onChange={(e) => {setRequest(e.target.value);console.log(request)}}
                                onBlur={formik.handleBlur}
                                mandatory
                            
                            />
                        </div>
                    </div>

                  

                <Flex justifyContent="center" className="mb-48 mt-40">
                <Button type="primary" key="console" text="REQUEST NEW CATEGORY"  onClick={()=>{
                    
                        newHook._promiseServiceNonLoading({"uri":"requestCategory","category":request}).then((data) => {
                
                            if(data.data.status.result == 200){
                                handleClose();
                            }
                    
                        });
                        
                    }}/>
                
                </Flex>
        </div>
        
        {/*
        <Modal.Header closeButton>
          <Modal.Title>Request New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        
        <div className="formBox">
                            <Input
                                label="Request Categeory"
                                type="text"
                                name="RequestCategeory"
                                placeholder="Request Categeory"
                                value={formik.values.RequestCategeory}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.RequestCategeory && formik.errors.RequestCategeory ? (
                                    formik.errors.RequestCategeory
                                ) : null}
                            />
                        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger" onClick={handleClose} type="button" text="Close" />
            
          <Button className="btn-primary" value="Send Request" onClick={handleClose} text="Send"/>
            
          
                            </Modal.Footer>*/}
      </Modal>
        </>
    );
};

export default WorkExperienceView;

