import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import * as Yup from "yup";
import "../css/createCourse.scss"
import Breadcrumbs from '../../../../components/breadcrumbs';
import Button from '../../../../components/button';
import FlowCard from '../../../../components/flowCard';
import Image from '../../../../components/image';
import Input from '../../../../components/input';
import AvatarPic from '../../../../assets/images/avatar_pic.jpg';
import Select from '../../../../components/input/select';
import Textarea from '../../../../components/input/textarea';
import ProgressBarCard from '../../../../components/programBarCard';
import Text from '../../../../components/text';
import { UploadComponent } from '../../uploadAttachments/view/UploadAttachmentsView';
import CourseImage from '../../../../assets/img/logo.svg';
import useHttpHandler from '../../../../hooks/httphandler.hook';
import useHelper from "../../../../hooks/helpers.hook";
import axios from "axios";
import {toast} from "react-toastify";
import useGuruUploadVideo from '../../uploadVideo/hooks/guruUploadVideo.hook';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE } from '../../../../constants/app.constants';
import Flex from '../../../../components/flex';
import InputFile from '../../../../components/input/inputFile';
import Icon from '../../../../components/icon';
import TickIcon from '../../../../components/svg/tickIcon';
import CameraIcon from '../../../../components/svg/cameraIcon';
import ProgramBarSecondaryCard from '../../../../components/programBarCard/programBarSecondaryCard';
import Modal from '../../../../components/modal'
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import VideoPlayer from "../../../../components/videoplayer";
import TrainerImg from '../../../../assets/img/logo.svg';
import UploadVideoPreview from '../../../../components/addTopicCompnent/videoUploadPreview';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import useNewCommonHook from '../../../../hooks/newCommonHook';
import TimeButton from '../../../../components/timeSlot/timeButton';
import SelectNew from '../../../../components/input/selectnew';
import CreateMeeting from '../../../../components/createMeeting/createMeeting';



const MeetingView = (props) => {
    //const [progress, setProgress] = useState(null);
   
    const window_path = window.location.pathname
    var coursetype = 0;
    const test = window_path.includes("instructor/create-course");
    const commonHook = useNewCommonHook();
    const {formatDateYYYYMMDD,formatDateHms,formatDateHH} = useHelper();
    const navigate = useNavigate();

    

    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text:"Meetings",
            link:"/instructor/meetings",
        },
        
    ]

    var title = "";


   
    const meetingCancel = () => {
      navigate("/instructor/meetings")
    }
  
  

   
    title = "Meetings"
    
   

    
  return (
    <div>
        <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
         <Text
        type="H1"
        className="font-weight-500 main_heading_color mb-24"
        text={title}
    />
     
     <CreateMeeting dropdownsData={props.dropdownsData} pageType="individual" meetingCancel={meetingCancel} courseId={0}/>
      

    </div>

  )
  

  
  
}




export default MeetingView;

