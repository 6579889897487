import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {setUserInfo} from "../../../store/reducers/app.reducer";
import {useDispatch} from "react-redux";
import {auth} from "../../../config/firebase";

const Logout = props => {
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const goToHome = () => {
        navigate('/')
    }

    useEffect(() => {
        console.log('currentUser', auth.currentUser)

        /*auth.onAuthStateChanged(function(user) {
            console.log('onAuthStateChanged', user)
            if (user) {
                // User is signed in.
                auth.signOut()
            } else {
                // No user is signed in.
            }
        });*/


       /* firebase.auth().signOut().then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });*/

        const currentLang = localStorage.getItem('language')
        localStorage.removeItem('token')
        sessionStorage.removeItem('isConvertUserFlow')
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('language', currentLang)
        dispatch(setUserInfo(null))

        goToHome()
    }, [])

    return (
        <>

        </>
    )
}

export default Logout;
