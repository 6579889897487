import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import Breadcrumbs from '../../../../components/breadcrumbs';
import Button from '../../../../components/button';
import FlowCard from '../../../../components/flowCard';
import Image from '../../../../components/image';
import Input from '../../../../components/input';
import Select from '../../../../components/input/select';
import Textarea from '../../../../components/input/textarea';
import ProgressBarCard from '../../../../components/programBarCard';
import Text from '../../../../components/text';
import { UploadComponent } from '../../uploadAttachments/view/UploadAttachmentsView';
import CourseImage from '../../../../assets/img/logo.svg';
import useHttpHandler from '../../../../hooks/httphandler.hook';
import axios from "axios";
import ModuleCard from '../../../../components/moduleCard';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE } from '../../../../constants/app.constants';


const ModuleCardEditView = (props) => {
    const [progress, setProgress] = useState(null);
    const navigate = useNavigate();
    const {videoId} = useParams();
    const {courseId} = useParams()
    const {post} = useHttpHandler();
    const {
        dropdownsData,
        
        uploadedImages,
        setUploadedImages,
        pdfuploadedImages,
        pdfsetUploadedImages,
        userInfo,
        setUploadedVideosInfo
    } = props?.guruUploadVideoHook

    const formik = useFormik({
        initialValues: {
            modulelevel: /*videoDetails[0]?.videolevelid ? videoDetails[0]?.videolevelid */ '',
            title: '',
            prioritylevel:'',
            description: /*videoDetails[0]?.des ? videoDetails[0]?.des*/'',
            sampleFile: [],
            pdfsampleFile: [],
        },
        validationSchema: Yup.object({
            modulelevel: Yup.string().required('Please enter a valid module level'),
            title: Yup.string().required('Please enter a valid title'),
            description: Yup.string().required('Please enter a valid description'),
            prioritylevel: Yup.string().required('Please enter a valid priority level'),
            sampleFile:Yup.array().min(1,'Please Upload thumbnail').required("Please Upload thumbnail"),
            pdfsampleFile:Yup.array().min(1,'Please Upload PDF Document').required("Please Upload PDF Document"),
        }),
        onSubmit: (values, actions) => {

            console.log("test");
           
            actions.setSubmitting(true)

            let postData = {

                courseid:courseId,
                modulelevelid:values.modulelevel,
                moduletitle: values.title,
                priority:values.prioritylevel,
                desc: values.description,
                attachment:pdfuploadedImages && pdfuploadedImages[0]?.newfile_name ? pdfuploadedImages[0]?.newfile_name : '',
                thumbnail: uploadedImages && uploadedImages[0]?.newfile_name ? uploadedImages[0]?.newfile_name : '', 
            }

            post('instructorCreateModule', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    navigate('/instructor/modules/'+ courseId)
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        },
      });
      const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text:"Course",
            link:"/instructor/course",
        },
        {
            text:"Modules",
            link:"/instructor/modules/" +courseId,
        },
        {
            text: 'Create Module',
            link: '/instructor/create-module/' +courseId,
        },
        {
            text: 'Edit Module',
            link: '/instructor/edit-module/' +courseId,
        },
    ]


    useEffect(() => {
        formikChangeMethod(formik)
    }, [formik.values.sampleFile])


    function formikChangeMethod(formik){
        if (formik.values.sampleFile.length > 0) {
            let formData = new FormData();
            formik?.values?.sampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructorUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    setUploadedImages(data)
                }
            })
                .catch((error) => {
                });
        }
    }


    useEffect(() => {
        if (formik.values.pdfsampleFile.length > 0) {
            
            let formData = new FormData();
            formik?.values?.pdfsampleFile?.map((pdffile, index) => {
                formData.append("files", pdffile);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructorUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    pdfsetUploadedImages(data)
                }
            })
                .catch((error) => {
                });
        }
    }, [formik.values.pdfsampleFile])

  return (
    <div>
        <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
         <Text
        type="H1"
        className="font-weight-500 main_heading_color mb-24"
        text="Edit Modules"
    />
    

      <form onSubmit={formik.handleSubmit}>
      <div className="formBox mt-3">
        <Select
            tertiary
            label="Module Level"
            name="modulelevel"
            placeholder="Select Level"
            value={formik.values.modulelevel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            mandatory
            options={dropdownsData?.studyLevelOptions}
            errormessage={formik.touched.modulelevel && formik.errors.modulelevel ? (
                formik.errors.modulelevel
            ) : null}
        />
    </div>   
       <div className="formBox">
            <Select
                tertiary
                label="Priority Level"
                name="prioritylevel"
                placeholder="Select Level"
                value={formik.values.prioritylevel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                options={dropdownsData?.studyLevelOptions}
                errormessage={formik.touched.prioritylevel && formik.errors.prioritylevel ? (
                    formik.errors.prioritylevel
                ) : null}
            />
        </div>
        <div className="formBox">
    <Input
        autoFocus={true}
        label="Module Title"
        type="text"
        name="title"
        placeholder=""
        value={formik.values.title}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        mandatory
        errormessage={formik.touched.title && formik.errors.title ? (
            formik.errors.title
        ) : null}
    />
       </div>
        <div className="formBox">
            <Textarea
                tertiary
                label="Description"
                type="text"
                name="description"
                placeholder="Write your description here..."

                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                rows="5"
                errormessage={formik.touched.description && formik.errors.description ? (
                    formik.errors.description
                ) : null}
            >
            </Textarea>
        </div>
        <div className="formBox">
            <div className="form-field form-field-tertiary">
                <label className="form-label mb-10">Thumbnail <span
                    className="text-danger">*</span></label>
                <Text
                    type="PARAGRAPH"
                    className="text-white-half"
                    text="Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention"
                />

                   
                <div className="row">
                    <div className="col-12 col-md-4">
                        <UploadComponent
                            setFieldValue={formik.setFieldValue}
                            maxFilesUpload="3"
                            hideFooter
                            hideButton
                            thumbNailWrap
                            thumb="Upload Thumbnail"
                            fieldName = "sampleFile"
                            accpetFileType = {IMAGE_FILE_TYPE}
                        />
                        {formik.touched.sampleFile && formik.errors.sampleFile ?
                            (
                                <div className="message-error">
                                    <small>{formik.errors.sampleFile}</small></div>
                            ) : null
                        }
                    </div>

                    {uploadedImages && uploadedImages.length > 0 &&
                    uploadedImages?.map((file, i) => (
                        <div key={i} className="col-12 col-md-4">
                            {file?.newfile_name && (
                                <Image
                                    src={process.env.REACT_APP_IMG_URL + file?.newfile_name}
                                />
                            )}
                        </div>
                    ))
                    }
                    {!uploadedImages &&
                    (<>
                        <div className="col-12 col-md-4">
                            <div className="thumbimgempty"></div>
                        </div>
                    </>)
                    }
                
                    </div>
                
                <div className="form-field form-field-tertiary mt-5">
                <label className="form-label mb-10">Upload PDF <span
                    className="text-danger">*</span></label>
                <Text
                    type="PARAGRAPH"
                    className="text-white-half"
                    text="Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention"
                />
               { <div className="row">
                    <div className="col-12 col-md-4">
                        <UploadComponent
                            setFieldValue={formik.setFieldValue}
                            maxFilesUpload="3"
                            hideFooter
                            hideButton
                            thumbNailWrap
                            thumb="Upload PDF"
                            fieldName = "pdfsampleFile"
                            accpetFileType = {DOC_FILE_TYPE}
                        />
                        {formik.touched.pdfsampleFile && formik.errors.pdfsampleFile ?
                            (
                                <div className="message-error">
                                    <small>{formik.errors.pdfsampleFile}</small></div>
                            ) : null
                        }
                    </div>

                    {pdfuploadedImages && pdfuploadedImages.length > 0 &&
                    pdfuploadedImages?.map((pdffile, i) => (
                        <div key={i} className="col-12 col-md-4">
                            {pdffile?.newfile_name && (
                                <>
                                <Text
                                    type="H5"
                                    className="font-weight-500 mb-24"
                                    text={process.env.REACT_APP_IMG_URL + pdffile?.newfile_name}
                                />
                                 <Button
                                 btn-link-primary
                                type="button"
                                text="Click here"
                             
                            />
                                </>

                               
                            )}
                        </div>
                    ))
                    }
                  
                </div>}
                </div>
           
            </div> 
        </div>
        
        <div className="action-buttons-end">
                     <Button
                        type="button"
                        text="cancel"
                        onClick={()=>{navigate('/instructor/modules/123')}}
                    />
                    <Button
                        light
                        type="submit"
                        text="save"
                        className="mt-20"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                       
                    />
                    
                </div>
      </form>
    </div>
  )
}

export default ModuleCardEditView;
