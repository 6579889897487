import React from 'react';

const RibbonLeftIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 61 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M61 0L43.0978 21L61 42L0 42L0 0L61 0Z" fill="#8081ff"/>
        </svg>        
    );
};

export default RibbonLeftIcon;

RibbonLeftIcon.defaultProps = {
    width: '61',
    height: '42',
}