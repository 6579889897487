/*import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './view/CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51N3JeWDLYMXimwFT1li7BtcI9A8EYU2mI4wG5JMzqx4fvoDLXDU8MisILX9HImHfrC8I6DclL9COqizSnKAwBTBP009lxVqc7C');

export default function CheckoutPage() {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{sk_test_51N3JeWDLYMXimwFTLpB9KUP5vLhz8PKjN3YcROhaImupbcK5IzspHiq2Ih0RqthVewd8cuEUeONeWjhYKRhf1GP000fujpqJJS}',
  };

  return (


<stripe-buy-button
  buy-button-id="price_1QCP4qDLYMXimwFTt2xFxoXo"
  publishable-key="pk_test_51N3JeWDLYMXimwFT1li7BtcI9A8EYU2mI4wG5JMzqx4fvoDLXDU8MisILX9HImHfrC8I6DclL9COqizSnKAwBTBP009lxVqc7C"
>
</stripe-buy-button>

  );
};
*/


import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm';


const stripePromise = loadStripe(process.env.REACT_APP_PAYMENTGATEWAYKEY); // Use your Publishable Key


const CheckoutPage = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm/>
  </Elements>
);

export default CheckoutPage


