import React, { useEffect, useState } from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import FavouriteCourseView from './views/favouriteCourseView'
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useCourseDropDown from '../courses/hook/courseDropDown.hook';
import CoursePageView from '../courses/views/coursePageView';
import { useLocation } from 'react-router-dom';
import useVideosCommonHooks from '../../instructor/createCourse/videosCommonHook';

const FavouriteCoursePage = (props) => {
  const commonHook =  useVideosCommonHooks();

  const location = useLocation()
  console.log("location.pathname",location.pathname);
  const [getCategoryId, setCategoryId] = useState(0);
  const [displayType,setDisplayType] = useState("list")

  var coursetype = 0;
  const window_path = window.location.pathname
    const test = window_path.includes("/trainee/courses/paid-courses");
  if(test){
    coursetype = 1;
  }
console.log(window_path,test);
/*  
useEffect(()=>{
    commonHook.setLoading(true)
    guruUploadVideoHook.getDropDowns()
  },[])
*/

useEffect(() => {

  commonHook?.getDropDowns().then(()=>{
   

    })

  

 
},[])




useEffect(() => {

      commonHook.setkeys();
      DEFAULT_FILTERS.uri = 'trainerFavourites';
      if(location.pathname === "/trainee/favourite/favourite-course"){
        DEFAULT_FILTERS.type = "favourite";
      }

      if(location.pathname === "/trainee/favourite/liked-course"){
        DEFAULT_FILTERS.type = "liked";
      }

      if(location.pathname === "/trainee/courses/favourite-course"){
        DEFAULT_FILTERS.type = "favourite";
      }
      
      DEFAULT_FILTERS.sortkey = "asc";
      DEFAULT_FILTERS.fromdate = "";
      DEFAULT_FILTERS.todate = "";
      DEFAULT_FILTERS.page = 1;
      DEFAULT_FILTERS.instructorId= 0;
      DEFAULT_FILTERS.perpage = 6;
      commonHook.getList(DEFAULT_FILTERS)

 
},[commonHook?.dropdownsData?.categoriesOptions])
 

if (commonHook?.loading) {
  return (
      <>
          <LayoutPanel {...props}>
              <Loader/>
          </LayoutPanel>
      </>
  )
}
return (
        
            <div>
          
                <LayoutPanel {...props}  footer>
                  <FavouriteCourseView {...props}  commonHook={commonHook}  coursetype={coursetype} setDisplayType={setDisplayType} displayType={displayType} />
              </LayoutPanel>
            </div>
          )


}

export default FavouriteCoursePage
