import React from 'react';

const ChevronIcon = props => {
    return (
        <svg 
            width={props.width} 
            height={props.height} 
            viewBox="0 0 13 25" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: props.down ? 'rotate(270deg)' : props.right ? 'rotate(180deg)' : props.up ? 'rotate(90deg)' : 'rotate(0deg)', 
                transition: 'transform ease-in 0.2s'
            }}
        >
            <path 
                d="M12 1L1 12.5L12 24" 
                stroke="currentColor" 
                stroke-width="2" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
        </svg>
    );
};

export default ChevronIcon;

ChevronIcon.defaultProps = {
    width: '13',
    height: '25'
}