import React, { useState } from 'react';

import Button from '../../../../components/button';
import Text from '../../../../components/text';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/table';
import useHelper from "../../../../hooks/helpers.hook";
import Pagination from '../../../../components/pagination';
import CourseCard from '../../../../components/courseCard';
import ModuleImage from '../../../../assets/img/logo.svg';
import CourseImage from '../../../../assets/img/logo.svg';
import { useMediaQuery } from 'react-responsive';
import Status from "../../../../components/status";
import Image from '../../../../components/image';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import Breadcrumbs from '../../../../components/breadcrumbs';
import ModuleCard from '../../../../components/moduleCard';
import ModuleCourseCard from '../../../../components/moduleCourseCard';
import {useLocation, useParams} from "react-router-dom";

const TopicUploadpageView = (props) => {
  const navigate = useNavigate();
  const {text} = props
  const {courseId} = useParams();
  const {moduleId} = useParams();
  const {formatDateMMMDDYYYY} = useHelper()
  const tableData = props?.tableData?.listData
   
  const customData = props?.tableData?.customdata;

  const [yearSelected, setYearSelected] = useState('Year 2022');
  const [monthSelected, setMonthSelected] = useState('Select Month');
  const isMobile = useMediaQuery({query: '(max-width: 767px)'});
  const {formatDateDDMMYY, getShortDescription} = useHelper()
    console.log("courseId",courseId);
  const breadcrumbList = [
    {
        text: 'Dashboard',
        link: '/instructor/dashboard',
    },
    {
        text:"Course",
        link:"/instructor/course",
    },
    {
        text:"Modules",
        link:"/instructor/modules/" +courseId,
    },
]


  return (
    <>
     
 <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
 <div className='row'>
        <div className='col-md-9'>
     <Text
        type="H1"
        text="Modules"
        className="mt-0 main_heading_color ml-3 mb-0"
    />
</div>

<div className="col-md-3">
    <div className='btn_move'>
        <Button
            primary
            type="button"
            text="Create Module"
            onClick={()=>{navigate('/instructor/create-module/'+courseId)}}
        />
    </div>
    </div>

    <ModuleCard 
    imgsrc={customData?.thumbnail}
    course="Course"
    alt="course image"
    coursetitle={customData?.course_name}
    module="Modules"
    modulelevel="Module Level"
    value={customData?.no_of_modules}
    availablevideos="Total Viewing Hrs"
    valuevideo={customData?.video_duration}
    dropdownsData ={props.dropdownsData}
    _props = {props}
    />
     </div>

     <Table
                thead={
                    <tr>
                       
                    </tr>
                }
                
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
                                   <ModuleCourseCard
                                     moduleId={item.moduleid}
                                      imgsrc={item.thumbnail}
                                      course="Module Name"
                                      coursetitle={item.moduletitle}
                                      modulesavailable="Topics available"
                                      modulesviewcontent="View Topics"
                                      mdoulesvalue={item.topics}
                                      module="Module Level"
                                      value={item.module_level}
                                      availablevideos="Priority Level"
                                      valuevideo={item.priority}
                                      status="Status"
                                      active={item.statusbit}
                                      viewcontent="View Topic"
                                      viewcontent1="View videos"
                                      des={item.desc}
                                      inactive="InActive"
                                      statusactive="Active"
                                      link={"/instructor/topics/" +item.moduleid}
                                      edit="Edit"
                                      delete="Delete"
                                      editlink={'/instructor/edit-module/' +courseId}
                                      />
                                  <br />
                                </tr>
                            )
                        })}
                       
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />

    </>
  )
}

export default TopicUploadpageView
