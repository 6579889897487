export const translations = {
    SIGN_UP: 'Daftar',
    SIGN_UP_v2: 'Mendaftar',
    LOGIN: 'Log Masuk',
    LOG_IN: 'Log Masuk',
    LOGOUT: 'Logout',
    LOG_OUT: 'Log Out',
    
    DASHBOARD: 'Dashboard',
    BE_A_GURU: 'Jom Jadi Guru',
    MENU: {
        MENU_HOME:'RUMAH',
        MENU_ABOUT: 'Utama',
        MENU_BENEFITS: 'Manfaat',
        MENU_PRICING:'penetapan',
        MENU_TOPICS: 'Topik',
        MENU_TESTIMONY: 'Testimoni',
        MENU_FEATURED_GURUS: 'Senarai Gurus',
        MENU_KOL: 'Kol',
        MENU_PLAN: 'Pelan',
        MENU_CONTACT:'kenalan',
        MENU_BE_A_GURU: 'Jom Jadi Guru',
    },
    ABOUT_REZKILAH: 'MENGENAI COURSETSUNAMI',
    FORGOT_PASSWORD: 'Lupa kata laluan?',
    EMAIL_ADDRESS: 'Emel Pengguna',
    EMAIL_ADDRESS_PROFILE: 'Emel',
    PASSWORD: 'Kata laluan',
    CHANGE_PASSWORD:'Tukar Kata Laluan',
    CONFIRM_PASSWORD: 'Sahkan Kata Laluan',
    CONFIRM: 'Sahkan',
    CANCEL: 'Batal',
    OR_CONTINUE_WITH: 'Atau Teruskan dengan',
    DONT_HAVE_ACCOUNT: 'Tidak mempunyai akaun?',
    REGISTER_NOW: 'Daftar sekarang',
    WELCOME_TO_REZKILAH: 'Selamat datang ke COURSETSUNAMI',
    WELCOME_BACK: 'SELAMAT KEMBALI',
    WELCOME_DESCRIPTION: 'COURSETSUNAMI Pilihan Utama Platform Pembelajaran Kewangan Anda',
    GURU_WELCOME_DESCRIPTION: 'COURSETSUNAMI Pilihan Utama Platform Pembelajaran Kewangan Anda',
    OR_SIGN_UP_WITH: 'Atau Daftar dengan',
    ALREADY_HAVE_ACCOUNT: 'Sudah mempunyai akaun?',
    REGISTER_AS_GURU: 'Pendaftaran Guru',
    REGISTER_AS_LEARNER: 'Pendaftaran Ahli',
    I_HAVE_READ_AND_AGREE_TO_THE: 'Saya telah membaca dan bersetuju dengan',
    TERMS_OF_USE: 'Terma dan syarat tertakluk.',
    CREATE_A_LEARNER_ACCOUNT: 'Pendaftaran AHLI',
    AUTOFILLED: '(autofilled)',
    AUTOFILLED_DESC: 'The data’s are fetched and from your social media. Please fill in all the blank field and set password for COURSETSUNAMI',
    CREATE_A_GURU_ACCOUNT: 'PENDAFTARAN Guru',
    OTP_VERIFICATION: 'Pengesahan OTP',
    WE_WILL_SEND_YOU_ONE_TIME_CODE_VERIFICATION_TO: 'Kami akan menghantar pengesahan kod satu kali kepada anda',
    RESEND_OTP_IN: 'Hantar semula OTP',
    Go_BACK: 'Kembali',
    SUBMIT: 'Hantar',
    SUBSCRIBE_TO_OUR_MONTHLY_PACKAGE: 'Langgan pakej bulanan kami',
    REZKILAH_PREMIUM_ACCOUNT: 'Akaun Premium COURSETSUNAMI',
    MONTHLY_SUBSCRIPTION: 'Langganan bulanan',
    CHOOSE_OUR_MONTHLY_PACKAGE_TO_VIEW_EXCLUSIVE_REZKILAH_FINANCE_VIDEO_AND_EARN_AFFILIATE_INCOME: 'Pilih pakej bulanan kami untuk melihat video kewangan COURSETSUNAMI eksklusif dan memperoleh pendapatan gabungan',
    IF_YOU_WISH_TO_PAY_LATER_YOU_WILL_HAVE_LIMITED_ACCESS_TO_OUR_100000_VIDEOS_AND_CONTENTS_WISH_TO_PROCEED: 'Jika anda ingin membayar kemudian, anda akan mempunyai akses terhad kepada 100,000+ video dan kandungan kami. Ingin meneruskan?',
    YES_PAY_LATER: 'Ya, bayar kemudian',
    THANK_YOU_FOR_THE_PAYMENT: 'Terima kasih atas pembayaran!',
    PAYMENT_SUCCESSFUL: 'Pembayaran Berjaya',
    CONTINUE: 'Teruskan',
    SYSTEM_FAILED_TO_MAKE_PAYMENT: 'Sistem gagal membuat pembayaran!',
    PAYMENT_FAILED_PLEASE_CHECK_YOUR_INTERNET_CONNECTION_OR_CONTACT_YOUR_BANK_IMMEDIATELY: 'Pembayaran gagal. Sila semak sambungan internet anda. Atau hubungi bank anda dengan segera.',
    RETURN_TO_PAYMENT: 'kembali kepada pembayaran',
    AFFILIATE_PROGRAM: 'Program Affiliasi',
    INVITE_YOUR_FRIEND_TO_RECEIVE_AFFILIATE_INCOME: 'Jemput rakan anda untuk menerima pendapatan gabungan',
    SHARE_THE_AFFILIATE_LINK_EARN_REWARDS: 'JOM KONGSI PAUTAN & PEROLEH GANJARAN!',
    COPY_LINK: 'Salin pautan',
    COPIED_LINK: 'Tersalin  !',
    SHARE_VIA: 'Kongsi melalui',
    SHARE_LATER: 'Share Later',
    MORE: 'Lagi',
    HELP_US_TO_ACCOMODATE_YOUR_FINANCIAL_LEARNING_BY_ANSWERING_3_QUESTIONS: 'Bantu kami untuk menampung pembelajaran kewangan anda dengan menjawab 3 soalan',
    STEP_1_OF_3: 'Langkah 1 daripada 3',
    STEP_2_OF_3: 'Langkah 2 daripada 3',
    STEP_3_OF_3: 'Langkah 3 daripada 3',
    WHAT_ARE_YOU_LOOKING_FORWARD_TO_LEARNING_AT_REZKILAH: 'Apa yang anda nantikan untuk belajar di COURSETSUNAMI?',
    WHAT_KIND_OF_TOPIC_INTERESTS_YOU_THE_MOST: 'Apakah jenis topik yang paling menarik minat anda?',
    ARE_YOU_INTERESTED_IN_EARNING_AFFILIATE_INCOME: 'Adakah anda berminat untuk menjana pendapatan affiliate?',
    TO_LEARN_BASIC_FINANCIAL_KNOWLEDGE: 'Untuk mempelajari pengetahuan asas kewangan',
    TO_EXPLORE_MORE_ABOUT_FINANCE_AND_OTHER_RELATED_KNOWLEDGES: 'Untuk meneroka lebih lanjut tentang kewangan dan pengetahuan lain yang berkaitan',
    I_HAVE_ADVANCED_THE_KNOWLEDGE_IN_MY_OWN_FIELD_AND_WISH_TO_ADVANCED_MY_SKILLS_IN_OTHER_FIELDS: 'Saya telah memajukan ilmu dalam bidang saya sendiri dan ingin memajukan kemahiran saya dalam bidang lain',
    ALL_OF_THE_ABOVE: 'Semua di atas',
    PROCEED: 'Teruskan',
    GOBACK: 'Kembali',
    COMPLETE: 'Lengkap',
    YES: 'ya',
    NO: 'Tidak',
    INFORMATION_MENU: {
        PERSONAL_PARTICULARS: 'Butiran peribadi',
        CORRESPONDENCE_ADDRESS: 'Alamat Surat-menyurat',
        BANK_DETAILS: 'Butiran Bank',
        WORK_AND_EXPERIENCE: 'Kerja dan Pengalaman',
        UPLOAD_ATTACHMENT: 'Muat Naik Lampiran',
    },
    PERSONAL_PARTICULARS: 'Butiran peribadi',
    CORRESPONDENCE_ADDRESS: 'Alamat Surat-menyurat',
    BANK_DETAILS: 'Butiran Bank',
    WORK_AND_EXPERIENCE: 'Kerja dan Pengalaman',
    UPLOAD_ATTACHMENT: 'Muatnaik Dokumen',
    FULL_NAME_AS_PER_IC: 'Nama Penuh (seperti didalam MyKad)',
    IDENTIFICATION_NUMBER_PASSPORT: 'MyKad/Paspot',
    DATE_OF_BIRTH: 'Tarikh lahir',
    LEVEL_OF_EDUCATION: 'Tahap pendidikan',
    OCCUPATION: 'Pekerjaan',

    MONTHLY_RANGE_OF_INCOME_RM: 'Julat Pendapatan Bulanan (RM)',
    FILL_UP_LATER: 'Isi Nanti',
    FULL_NAME: 'Full Name',
    ADDRESS: 'Alamat',
    ADDRESS_LINE_1: 'Alamat Baris 1',
    ADDRESS_LINE_2: 'Alamat Baris 2',
    CITY: 'Bandar',
    POSTAL_ZIP: 'Poskod',
    STATE: 'Negeri',
    COUNTRY: 'Negara',
    BANK_NAME: 'Nama bank',
    YOUR_BANK_NAME: 'Nama bank anda',
    ACCOUNT_HOLDER_NAME: 'Nama Pemegang Akaun',
    ACCOUNT_HOLDER: 'Pemegang akaun',
    ACCOUNT_NUMBER: 'Nombor akaun',
    PROFILE_PHOTO: 'Gambar profil',
    PREFERRED_NAME:'Nama Pilihan',
    ABOUT_YOURSELF: 'Tentang Diri Anda',
    THANK_YOU_FOR_COMPLETING_YOUR_PROFILE: 'Terima kasih kerana menghantar profil anda',
    YOU_APPLICATION_IS_CURRENTLY_BEING_REVIEWED_BY_ADMIN_YOU_WILL_BE_CONTACTED_WITHIN_2_WEEKS: 'Permohonan anda sedang disemak oleh Kami,jika berjaya anda akan dihubungi dalam masa 3-5 hari',
    GO_TO_DASHBOARD: 'Kembali ke Halaman Utama',
    FIELD_OF_EXPERTISE: 'Bidang kepakaran',
    YEARS_OF_EXPERIENCE: 'Tahun Pengalaman',
    SELECT_THE_TOPIC_OF_YOUR_INTEREST: 'Pilih topik pilihan anda',
    WHAT_REZKILAH_OFFERS_TO_YOU: 'TAWARAN COURSETSUNAMI KEPADA ANDA',
    AFFORDABLE_AND_WORTHWHILE_MONTHLY_FEES: 'Yuran bulanan yang mampu milik dan berbaloi.',
    ACCESS_TO_EXCLUSIVE_VIDEOS: 'Akses Ekslusif untuk Ahli',
    ACCESS_TO_EXCLUSIVE_VIDEOS_DESCRIPTION: 'Akses penuh ke video-video khusus untuk ahli berdaftar.',
    CONNECT_WITH_EXPERTS: 'Berhubung dengan Pakar',
    CONNECT_WITH_EXPERTS_DESCRIPTION: 'Ahli berdaftar boleh memilih dan menyertai kursus pembelajaran yang lebih mendalam serta secara one-to-one dengan pakar-pakar yang bertauliah.',
    GET_REWARDED: 'Beroleh Keuntungan',
    GET_REWARDED_DESCRIPTION: 'Anda akan mendapat keuntungan dengan hanya menjadi ahli! Ingin tahu bagaimana?',
    CLICK_ON_THIS_NETWORK: 'Tekan jaringan ini',
    E_MAGAZINE: 'E-Majalah',
    MAGAZINE: 'Majalah',
    E_MAGAZINE_DESCRIPTION: 'Ingin mendapat info-info terkini dan menguasai tip kewangan yang mantap? Sertai langganan kami sekarang!',
    FINANCIALTOPICS: 'TOPIK KEWANGAN',
    AFFILIATES_INCOME: 'Pendapatan Afiliasi',
    AFFILIATES_INCOME_DESCRIPTION: 'AHLI / GURUs: Insentif kredit sehingga xx%',
    AFFILIATION_GURU: 'AHLI / GURUs:',
    CREDIT_INCENTIVE_UP_AFFILIATE: 'Insentif kredit sehingga xx%',
    COMMISSION_UP_TO: 'Komisen Sehingga xx% - xx%',
    SUBJECT_TO_THE_TERMS_AND_CONDITIONS: '(Tertakluk kepada terma dan syarat)',
    WHAT_PEOPLE_SAY_ABOUT_REZKILAH: 'APA KATA MEREKA TENTANG COURSETSUNAMI',
    WHAT_PEOPLE_SAY_ABOUT_US: 'APA KATA MEREKA TENTANG COURSETSUNAMI',
    OUR_EXCLUSIVE_GURUS: 'SENARAI GURU DI COURSETSUNAMI',
    FEATURED_VIDEOS_BY_OUR_GURUS: 'VIDEO EKSKLUSIF GURU',
    KEY_OPINION_LEADER: 'PENDAPAT KEY OPINION LEADERS (KOLs) DI COURSETSUNAMI',
    KOL: 'KEY OPINION LEADERS',
    REZKILAH: 'COURSETSUNAMI',
    ARE_YOU_READY_TO_LEARN_AND_EARN_WITH_REZKILAH: 'ADAKAH ANDA BERSEDIA UNTUK BELAJAR DAN MERAIH KEUNTUNGAN BERSAMA COURSETSUNAMI?',
    GET_STARTED_NOW: 'JOM SERTAI SEKARANG!',
    FREE: 'Percuma',
    FEATURES: 'Ciri Khas',
    FREEMIUM_LIST_1: 'Lebih 100 kursus peringkat eksekutif',
    FREEMIUM_LIST_2: 'Bimbingan & mentor bertauliah',
    FREEMIUM_LIST_3: 'Analisis & cerapan yang dikuasai AI',
    FREEMIUM_LIST_4: 'Kepelbagaian pembelajaran',
    FREEMIUM_LIST_5: 'Secara Langsung & Segerak',
    FREEMIUM_LIST_6: 'Tugasan Kursus',
    FREEMIUM_LIST_7: 'Akses Terhad ke Video Tertentu',
    EXCLUSIVE_FEATURES: 'Ciri Eksklusif',
    PREMIUM_LIST_1: 'Lebih 100 kursus peringkat eksekutif',
    PREMIUM_LIST_2: 'Kemudahan untuk membimbing dan melatih ahli oleh mentor bertauliah persendirian',
    PREMIUM_LIST_3: 'Analisis & cerapan yang dikuasai AI',
    PREMIUM_LIST_4: 'Kepelbagaian pembelajaran',
    PREMIUM_LIST_5: 'Kelas bersiaran secara Langsung & Segerak',
    PREMIUM_LIST_6: 'Platform berdaya mudah alih',
    PREMIUM_LIST_7: 'Akses Penuh ke Semua Video',
    GURU_LIST_1: 'Keahlian PERCUMA',
    GURU_LIST_2: 'Pendedahan di semua liputan media sosial di COURSETSUNAMI',
    GURU_LIST_3: 'Keuntungan tinggi dari sistem afliasi ‘profit sharing pool’',
    GURU_LIST_4: 'Studio rakaman & suntingan professional PERCUMA (bernilai lebih daripada RM 20,000)',
    GURU_LIST_5: 'Penghormatan & pengiktirafan oleh Lembaga Penasihat COURSETSUNAMI',
    GURU_LIST_6: '',
    DISCLAIMER: 'Portal Web COURSETSUNAMI adalah platfom pembelajaran atas talian yang memfokuskan khusus kepada topik kos sara hidup, perbelanjaan kewangan dan penjanaan pendapatan. Keseluruhan isi kandungan video ini akan disediakan oleh setiap GURUs yang mempunyai kepakaran dalam bidang masing-masing.',
    SHARE_LEARN_PROFIT: 'KONGSI | BELAJAR | UNTUNG',
    LOGO_DESCRIPTION: 'Platform Pendidikan kewangan berevolusi untuk menggalakkan kaum Bumiputera berkongsi, belajar dan memperoleh keuntungan.',
    QUICK_LINKS: 'PAUTAN PANTAS',
    GURU: 'Guru',
    LEARNER: 'Pelajar',
    LANDING_PAGE: 'Laman Utama',
    GURU_SIGN_UP: 'Daftar Guru',
    MEMBER_SIGN_UP: 'Daftar AHLI',
    CONTACT: 'HUBUNGI KAMI',
    FREQUENTLY_ASKED_QUESTIONS: 'Soalan Lazim',
    CONTACT_US: 'Hubungi Kami',
    LEGAL: 'SAH',
    TERM_OF_USE: 'Terma Penggunaan',
    PRIVACY_POLICY: 'Dasar Privasi',
    TERMS_CONDITION: 'Terma & Syarat Penggunaan',
    BACK_TO_MAIN_PAGE: 'Kembali ke laman utama',
    FOR_ANY_INFORMATION_AND_QUESTIONS: 'Untuk sebarang maklumat dan pertanyaan, sila hubungi kami melalui',
    LOGIN_TO_YOUR_ACCOUNT: 'Log masuk ke akaun anda',
    REZKILAH_BY_THE_NUMBERS:'KEAHLIAN TERKINI @COURSETSUNAMI',
    LET_YOUR_FRIENDS_AND_FAMILY_LEARN_FINANCIAL_TOPICS_TO_ENHANCE_THEIR_KNOWLEDGE_HELP_THEM_WHILE_LEARNING_EXITING_REWARDS:'Ajak rakan dan ahli keluarga anda mempelajari ilmu kewangan yang mampu memberikan pengetahuan lebih cekap dan membantu mereka mencapai keuntungan yang berganda!',
    TOTAL_MEMBERS_SUBSCRIBED_IN_REZKILAH:'Jumlah Ahli Berdaftar Keseluruhan',
    TOTAL_MEMBERS_ENROLLED_BY_YOU:'Jumlah Ahli Afiliasi Anda',
    TOTAL_VIDEO_VIEWED_BY_ALL_MEMBERS:'Jumlah video ditonton oleh semua Ahli berdaftar',
    TOTAL_VIDEO_VIEWED_BY_YOU:'Jumlah video ditonton oleh Anda',
    TOTAL_ACCUMULATED_EARNING_BY_YOU:'Jumlah Keseluruhan Pendapatan Anda',
    MY_LEARNING:'Pembelajaran Mengikut Bimbingan',
    TOPICS_YOUARE_INTERESTED_WITH:'Topik yang diminati',
    TOP_10_VIDEOS: '10 Video Popular',
    MAIN_PAGE:'UTAMA',
    MONTHLY_RANGE_OF_INCOME:'Pendapatan Bulanan',
    PLANNING_TO_SHARE_YOUR_KNOWLEDGE_JOIN_US_NOW_AND_BECOME_A_GURU: 'Berhasrat berkongsi kepakaran anda? Mari sertai kami dan menjadi GURU',
    TRENDING:'Terkini',
    LIKED_VIDEOS:'Video Digemari',
    ALL_TOPICS:'Semua Subjek',
    LOAD_MORE:'Muat turun lagi',
    TO_CONTINUE_WATCHING_SUBSCRIBE_TO_OUR_PREMIUM_SUBSCRIPTION:'DAFTAR SEBAGAI AHLI PREMIUM UNTUK TERUSKAN MENONTON',
    TO_CONTINUE_WATCHING_ALL_OUR_CONTENT_ON_REZKILAH_WE_ENCOURAGE_YOU_TO_SUBSCRIBE_TO_OUR_MONTHLY_SUBSCRIPTION_AND_ENJOY_ALL_OUR_SERVICES_AND_fEATURES:'Untuk terus menonton kesemua sesi pembelajaran di COURSETSUNAMI, kami menyarankan anda untuk melanggan secara bulanan dan seterus menikmati seluruh servis dan eksklusif.',
    EXECUTIVE_LEVEL_COURSES_FROM_TOP_UNIVERSITIES:'Lebih 100 subjek kursus eksklusif daripada Universiti-universiti terbaik',
    FACILITATION_TO_GUIDE_AND_COACH_PARTICIPANTS:'Kemudahan garis panduan dan mentor kepada ahli',
    AI_POWERED_ANALYTICS_AND_INSIGHTS:'Analisa yang mendalam dengan dikuasakan oleh Artificial Intelligent (AI)',
    MULTIMODAL_LEARNING:'Kepelbagaian sistem pembelajaran',
    LIVE_SYNCHRONOUS_EVENTS:'Disiarkan secara langsung dan serentak',
    COURSE_ASSIGNMENT_WITH_CUSTOM_MESSAGING:'Penghantaran tugasan kursus mengunakan sistem bersuaian',
    MOBILE_ENABLED_PLATFORM:'Platfom mudah alih dan di mana-mana',
    SETTINGS:'Pengaturcaraan',
    PERSONAL_INFO:'Maklumat Peribadi',
    ACCOUNT_ACTIVE:'Status Aktif',
    GENERAL_INFORMATION:'Maklumat Asas',
    ROLE_OF_USER:'Status Keahlian',
    MEMBERID:'Nombor Keahlian',
    NAME:'Nama',
    USERNAME:'Nama Pengguna',
    NRIC:'MyKAD',
    PHONE_NUMBER:'Nombor Telefon',
    CONTACT_INFORMATION:'Nombor untuk Dihubungi ',
    SHARE_THE_AFFILIATE_LINK:'Kongsi Pautan Afiliasi',
    MEMBER_COMISSION:'Afiliasi dan Ganjaran',
    MEMBER_NETWORK:'Rangkaian Anda',
    REPORT:'Laporan',
    OVERVIEW:'Overview',
    GENERATE_REPORT:'Jana Laporan',
    DATE:'Tarikh',
    REFERAL_NAME:'Nama Afiliasi',
    TYPE_DETAIL:'Butiran',
    AMOUNT:'Amaun',
    MEMBER_ID:'Member Id',




    BENEFITS_INCLUDED:'Senarai Faedah',
    FULL_ACCESS_FOR_FINANCE_VIDEO:'Akses penuh video kewangan',
    MONTHLY_REZKILAH_MAGAZINE:'Majalah Bulanan COURSETSUNAMI',
    CONNECT_WITH_FINANCE_EXPERT:'Berhubung dgn Pakar Kewangan',
    GET_EXCLUSIVE_PAID_COMMISION:'Pendapatan tambahan afiliasi eksklusif ',
    RECIEVED_REWARD_POINTS:'Mata Ganjaran',
    DAYS_REMAINING:'hari berbaki',
    TOTAL_AFFILIATE_INCOME:'Jumlah Pendapatan Afliasi',
    TOTAL_REWARD_POINTS:'Jumlah Mata Ganjaran',
    TOTAL_VIDEO_VIEWING_MINUTES:'Jumlah Tontonan Minit Video',
    TOTAL_VIDEO_VIEWING_MINUTES_DESC:'Anda perlu memenuhi jumlah minit tontonan video yang ditetapkan (120 minit) untuk layak mengeluarkan pendapatan afiliasi anda. Minit tontonan hanya akan diambil kira semasa anda menonton video-video pembelajaran yang disediakan oleh guru-guru kami.',
    TARGET_COMPLETED:'Target Tercapai',
    Report_GENERATION:'Jana Laporan',
    PAUSE_OR_CANCEL_SUBSCRIPTION:'Berhenti Melanggan',
    HELP_RECEIVE_HIS_REZKILAH_AFFILIATE_INCOME:'Jom [DYNAMIC_USER_NAME] menjana pendapatan afiliasi',
    BY_REGISTERING_AT_REZKILAH:'Dengan mendaftar sebagai Ahli @COURSETSUNAMI',
    YOU_HAVE_OPPURTUNITY_TO_EARN_AFFILIATE_INCOME_AND_OTHER_COMMISSION:'**Anda jugak BOLEH menjana pendapatan afliasi dan juga pelbagai ganjaran lain!',
    SIGN_UP_NOW:'DAFTAR SEKARANG!',
    LEARNER_SIDEBAR_MENU: {
        MAIN_PAGE: 'UTAMA',
        LIKED_VIDEO: 'Video Digemar',
        FAVOURITE_GURU: 'GURU Digemari',
        MY_REPORT: 'Laporan Anda',
        MY_SUBSCRIPTION: 'Pakej Langganan Anda',
        MY_NETWORK:'Rangkaian Anda',
        MY_COMMISSION:'Afiliasi dan Ganjaran',
        PROFILE:'Profil',
        BANK_DETAILS:'Maklumat Perbankan',
        ACCOUNT:'Akaun',
    },
    SEARCH_RESULT_FOR:'HASIL CARIAN UNTUK',
    SEARCH_NOT_FOUND:'CARIAN TIDAK BERJAYA',
    YOUR_SEARCH_DID_NOT_MATCH_ANY_RESULTS:'Carian Anda Tidak Tepati Sebarang Hasil',
    VIEW_DETAILS:'Lihat Maklumat',
    MONTH:'sebulan',
    SUBSCRIBE_NOW:'DAFTAR SEKARANG',
    PAY_LATER:'Bayar kemudian',
    VIEW_REPORT:'Lihat Laporan',
    YOU_HAVE_SUCCESSFULLY_UNSUBSCRIBED_TO_REZKILAH_YOUR_MEMBERSHIP_CANCELLTATION_WILL_BE_EFFECTIVE_ON_YOUR_NEXT_BILLING_DATE:'Anda telah membuat pembatalan langganan di COURSETSUNAMI. Pembatalan Keahlian akan berkuatkuasa pada tarikh penyata bil berikutnya.',
    MY_VEDIO:'Video Saya',
    MY_PROFILE:'Profil',
    UPLOAD_COPY_OF_IDENTIFICATION_CARD_FRONT_AND_BACK:'Muatnaik Salinan Mykad (Hadapan & Belakang)',
    DRAG_AND_DROP_HERE:'Seret & Lepas Di Sini Atau Semak Imbas Fail',
    UPLOADED_FILES:'Dokumen telah dimuatnaik',
    PLAN_SUMMARY: 'PAKEJ PLAN',
    TOTAL : 'Jumlah ',
    PER_MONTH: 'Bulan',
    PREMIUM_SUBSCRIPTION:  'Premium Subscription',
    BILLING_ADDRESS : 'Alamat Penyata Bil',
    DISCLAIMER_PAYMENT : 'Dengan bersetuju menekan butang dibawah, COURSETSUNAMI akan mengenakan caj secara automatik untuk yuran pelan yang dipilih pada kaedah pembayaran yang diluluskan oleh Ahli.',
    PAY: 'BAYAR',
    READ_MORE: 'Baca Selanjutnya',
    SEARCH_GURU_NAME: 'Nama Guru',
    SEARCH_VIDEO_TOPICS: 'Topik Video',
    TARGET_MINUTES_TO_WATCH_THIS_MONTH: 'Jumlah baki minit tontonan yang perlu ditonton',
    TOTAL_VIDEO_MINUTES_VIEWED_THIS_MONTH: 'Jumlah minit tontonan untuk bulan ini',
    BY_CANCELLING:'Dengan membatalkan langganan anda, anda akan menghilang kelayakan untuk mendapat pendapatan afilisai. Akses kepada COURSETSUNAMI adalah dihadkan.',
    TOTAL_AFFILIATE_INCOME_FOR_THIS_MONTH: 'Jumlah Pendapatan Afiliasi Terkini',
    THE_REFERRAL_COMMISSION: 'Pendapatan afiliasi ini adalah dijanakan secara bulanan. Kiraan jumlah pendapatan afiliasi adalah berdasarkan rangkaian ahli-ahli afiliasi anda ke atas 8 peringkat sahaja.',
    TOTAL_INCOME_PAID: 'Jumlah Pendapatan Dibayar',
    TOTAL_REFERRED_MEMBERS: 'Jumlah Ahli Afiliasi',
    CREDIT_REWARD_POINT:'Kredit Pembelajaran',
    THE_TOTAL_CREDIT_REWARD_POINT:'Kredit pembelajaran dikira berdasarkan minit tontonan video pembelajaran. 1 minit tontonan = 1 kredit pembelajaran',
    TOTAL_CREDIT_REWARD_POINTS: 'Jumlah Kredit Pembelajaran ',
    REWARDS_POINTS_THIS_MONTH: 'Kredit Pembelajaran untuk Bulan ini',
    REWARDS_POINTS_LAST_MONTH: 'Kredit Pembelajaran terkumpul',
    CREDIT_REWARDS_POINTS_DESC: 'Anda boleh menebus ganjaran yang lumayan menggunakan kredit pembelajaran ini.',

    VIDEO_VIEWING: 'Tontonan Video',
    EVERY_MONTH_THERE_IS: 'Anda perlu memenuhi jumlah minit tontonan video yang ditetapkan (120 minit) untuk layak mengeluarkan pendapatan afiliasi anda. Minit tontonan hanya akan diambil kira semasa anda menonton video-video pembelajaran yang disediakan oleh guru-guru kami.',
    VIDEO_MINUTES_VIEWED_THIS_MONTH: 'Jumlah minit tontonan terkumpul untuk bulan ini',
    WATCH_VIDEO: 'Akses Ke Video',
    TOTAL_VIDEO_MINUTES_VIEWED: 'Jumlah minit tontonan setakat ini',
    TARGET_MINUTES_PER_MONTH:'Baki jumlah minit tontonan untuk bulan ini',
    REQUIRED_TO_FULFIL_THE_TARGET:'Anda wajib mencapai minit tontonan yang ditetapkan pada setiap bulan supaya dapat mengeluarkan pendapatan afiliasi bulanan anda. Jika tidak, pendapatan afiliasi anda akan ditarik balik.',
    TOTAL_DOWNLINE:'Jumlah Ahli Afiliasi',
    SEARCH: 'Cari',
    MONTH_V2: 'Bulan',
    IN_ORDER_TO_RECEIVE_COMMISSION:'Anda kini boleh membuat tuntutan pendapatan afliasi dengan mengisi maklumat seterusnya.',
    FILL_IN_EXTRA_DETAIL:'Isikan butiran tambahan',
    BECOME_SUBSCRIBER_MODAL_CONTENT:'Anda perlu menjadi pelanggan untuk membuka kunci kandungan',
    AGREE_UNSUBSCRIBE:'Anda telah membuat pembatalan langganan di COURSETSUNAMI. Pembatalan Keahlian akan berkuatkuasa pada tarikh penyata bil berikutnya.',
    ARE_YOU_SURE_UNSUBSCRIBE:'Adakah anda pasti mahu menyahlanggan Akaun Premium di COURSETSUNAMI?',
    NEXT_BILLING_DATE_ON: 'Tarikh Pembayaran Seterusnya',
    PLEASE_ENTER_A_ADDRESS:'Sila isikan alamat anda',






}



