import { useState } from "react";

const useAccount = () => {
    const [showExistingPassword, setShowExistingPassword] = useState(false);
    const ShowExistingPasswordOnClick = () => {
        setShowExistingPassword(state => !state);
    }
    const [showNewPassword, setShowNewPassword] = useState(false);
    const ShowNewPasswordOnClick = () => {
        setShowNewPassword(state => !state);
    }
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const ShowConfirmNewPasswordOnClick = () => {
        setShowConfirmNewPassword(state => !state);
    }
    return {
        showExistingPassword,
        ShowExistingPasswordOnClick,
        showNewPassword,
        ShowNewPasswordOnClick,
        showConfirmNewPassword,
        ShowConfirmNewPasswordOnClick
    }
}
export default useAccount
