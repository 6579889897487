import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import AddressInformationView from "./view/AddressInformationView";
import InfomationNavSide from "../../../components/infomationNavSide";
import useAddressInformation from "./hooks/addressInformation.hook";
import useProfile from "../settings/profile/hooks/profile.hook";
import Loader from "../../../components/loader";

const AddressInformation = props => {
    const {text} = props
    const addressInformationHook = useAddressInformation()
    const profileHook = useProfile()
    const isLoaded = !profileHook.loading && !addressInformationHook?.loading

    useEffect(() => {
        addressInformationHook?.getCountries()
        addressInformationHook?.getStatesByCountry()
        profileHook?.getProfileDetails()
    }, [])
    useEffect(() => {
        addressInformationHook?.getCitiesByState(profileHook?.profileDetails?.stateid)
    }, [profileHook?.profileDetails])

    if (!isLoaded) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }

    return (
        <>
            <SignupLayout
                {...props}
                navSideLayout={<InfomationNavSide text={text?.INFORMATION_MENU}/>}
            >
                <AddressInformationView
                    {...props}
                    {...addressInformationHook}
                    profileDetails={profileHook.profileDetails}
                />
            </SignupLayout>
        </>
    )
}

export default AddressInformation;
