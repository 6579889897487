import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import PersonalInformationView from "./view/PersonalInformationView";
import InfomationNavSideGuru from "../../../components/infomationNavSideGuru";
import useProfile from "../settings/profile/hooks/profile.hook";
import usePersonalInformation from "./hooks/personalInformation.hook";
import Loader from "../../../components/loader";
import logoImg from '../../../assets/userimg/login-bg2.png';
const PersonalInformation = props => {
    const {text} = props
    const profileHook = useProfile()
    const personalInformationHook = usePersonalInformation()
    const isLoaded = !profileHook.loading && !personalInformationHook?.loading
    useEffect(() => {
        profileHook?.getProfileDetails()
    }, [])
    if (!isLoaded) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
        <div class="register_instructor">
        <main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div className="row">
            <div className="col-md-6">
            <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
            </div>
            <div className="col-md-6">
               <div class="row justify-content-center">
            <div class="col-lg-12 col-md-6 bg-light">
                <PersonalInformationView
                    {...props}
                    {...personalInformationHook}
                    profileDetails={profileHook.profileDetails}
                />
            
            </div>
            </div> 
            </div>
        </div>
        
      </div>

    </section>

  </div>
</main>
</div>
        </>
    )
}

export default PersonalInformation;
