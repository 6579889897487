import React from "react";
import ProductPageView from "./views/productpageView";


const ProductPage =()=>{
    return(
        <ProductPageView />
    )
}

export default ProductPage;