import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {
    FREEMIUM,
    GURU, REDIRECT_TO_GURU_BANK_DETAILS,
    REDIRECT_TO_GURU_COMMUNICATION_DETAILS, REDIRECT_TO_GURU_DASHBOARD,
    REDIRECT_TO_GURU_OTP,
    REDIRECT_TO_GURU_PROFILE, REDIRECT_TO_instructorUpload_ATTACHMENTS,
    REDIRECT_TO_GURU_WORK_EXPERIENCE,
    REDIRECT_TO_OTP,
    REDIRECT_TO_PAYMENT,
    REDIRECT_TO_SHARE_LINK,
    REDIRECT_TO_SUBSCRIPTION,
    REDIRECT_TO_SURVEY_1,
    REDIRECT_TO_SURVEY_2,
    REDIRECT_TO_SURVEY_3,
    REDIRECT_TO_PERSONAL_INFO,
    REDIRECT_TO_ADDRESS_INFO,
    REDIRECT_TO_LEARNER_BANK_DETAILS,
    REDIRECT_TO_LEARNER_DASH_BOARD,
    SECRETKEY
} from "../../../../constants/app.constants";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../../../../store/reducers/app.reducer";
import { ToastContainer,toast } from 'react-toastify'; // <- add ToastContainer
import useHttpHandler from "../../../../hooks/httphandler.hook";

import {GoogleAuthProvider, FacebookAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../../../../config/firebase";
var CryptoJS = require("crypto-js");

const useLogin = () => {
    
    const providerFb = new FacebookAuthProvider();
    const provider = new GoogleAuthProvider();
    const [isSwitchingUser, setIsSwitchingUser] = useState(false)
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const [showPassword, setShowPassword] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const openForgotPassword = () => {
        navigate('/forgotPassword')
    }
    const ShowPasswordClick = () => {
        setShowPassword(state => !state);
    }
    const openSetNewPassword = () => {
        window.location.href = 'mailto:'+ sessionStorage.getItem('email')
       //navigate('/setNewPassword')
    }
    const goBackToLogin = () => {
        navigate('/login')
    }

    const storeUserInfo = async (data) => {
        return dispatch(setUserInfo(data))
    }

    const setUserSessionInfo = async (data)=>{
        localStorage.setItem('token',data?.sessiontoken)
        localStorage.setItem('currentUserTypeId',data?.usertypeid)
        localStorage.setItem('currentSessionUserIds',data?.otherUserIds)
        
        storeUserInfo(data).then(()=>{
            return data
        })
    }

    const onSubmitLogin = (values, actions, type)=>{
        post('login', values).then((res)=>{
            if(actions != "social"){ actions.setSubmitting(false) };
            const status = res.data.status
            const data = res.data.data
            if(status.result === '200') {

                
                setUserSessionInfo(data).then(()=>{
                    console.log("--> redirect","abcdefg");    
                    redirectTo(data.redirectcode, data.usertypeid)
                })
            } else {
                toast.error(status.msg)
            }
        }).catch(err => {if(actions == "social"){actions.setSubmitting(false)}else{toast.error("Something went wrong")};})
    }

    const redirectTo = (redirectCode, userTypeId) => {
        console.log('redirectTo', redirectCode, userTypeId)
        let redirectUrl = ''
        redirectCode = parseInt(redirectCode)
        if (redirectCode == REDIRECT_TO_OTP) {
            redirectUrl = userTypeId === GURU ? '/instructor/otp-verification' : '/trainee/otp-verification'
        }
        else if (redirectCode === REDIRECT_TO_GURU_DASHBOARD){
            redirectUrl = '/instructor/dashboard'
        }
        
         else if (redirectCode === REDIRECT_TO_SUBSCRIPTION) {
            redirectUrl = '/trainee/dashboard'
        } else if (redirectCode === REDIRECT_TO_PAYMENT) {
            redirectUrl = '/trainee/payment'
        } else if (redirectCode === REDIRECT_TO_SHARE_LINK) {
            redirectUrl = '/trainee/refer-friend'
        } else if (redirectCode === REDIRECT_TO_SURVEY_1) {
            redirectUrl = '/trainee/survey-question-1'
        } else if (redirectCode === REDIRECT_TO_SURVEY_2) {
            redirectUrl = '/trainee/survey-question-2'
        } else if (redirectCode === REDIRECT_TO_SURVEY_3) {
            redirectUrl = '/trainee/survey-question-3'
        }else if(redirectCode === REDIRECT_TO_PERSONAL_INFO){
            redirectUrl = '/trainee/personal-information'
        }else if(redirectCode === REDIRECT_TO_ADDRESS_INFO){
            redirectUrl = '/trainee/address-information'
        }else if(redirectCode === REDIRECT_TO_LEARNER_BANK_DETAILS){
            redirectUrl = '/trainee/bank-details'
        }else if(redirectCode === REDIRECT_TO_LEARNER_DASH_BOARD){
            redirectUrl = '/trainee/dashboard'
        }else if (redirectCode === REDIRECT_TO_GURU_OTP) {
            redirectUrl = '/instructor/otp-verification'
        } else if (redirectCode === REDIRECT_TO_GURU_PROFILE) {
            redirectUrl = '/instructor/personal-information'
        } else if (redirectCode === REDIRECT_TO_GURU_COMMUNICATION_DETAILS) {
            redirectUrl = '/instructor/address-information'
        } else if (redirectCode === REDIRECT_TO_GURU_WORK_EXPERIENCE) {
            redirectUrl = '/instructor/work-experience'
        } else if (redirectCode === REDIRECT_TO_GURU_BANK_DETAILS) {
            redirectUrl = '/instructor/bank-details'
        } else if (redirectCode === REDIRECT_TO_instructorUpload_ATTACHMENTS) {
            redirectUrl = '/instructor/upload-attachments'
        } else if (redirectCode === REDIRECT_TO_GURU_DASHBOARD) {
            redirectUrl = '/instructor/dashboard'
        } else {
            redirectUrl = '/'
        }
        console.log("redirectUrl",redirectUrl)
        navigate(redirectUrl)
    }

    const lowercaseRegex = /(?=.*[a-z])/;
    const uppercaseRegex = /(?=.*[A-Z])/;
    const numericRegex = /(?=.*[0-9])/;
    const nonalphanumeric = /(?=.*[@$!%*#?&])/;
    const numbersOnly = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneNumbersFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    const convertUser = (userType) => {
        let apiUrl = userType == 'guru' ? 'be_a_guru' : 'be_a_learner'
        setIsSwitchingUser(true)
        post(apiUrl).then((res) => {
            setIsSwitchingUser(false)
            sessionStorage.setItem('isConvertUserFlow', location.pathname)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setUserSessionInfo(data).then(()=>{
                    redirectTo(data.redirectcode, data.usertypeid)
                })
            } else {
                setIsSwitchingUser(false)
                toast.error(status.msg)
            }
        }).catch(err=>{setIsSwitchingUser(false)})
    }

    const signInWithGoogle = () => {
        console.log('signInWithGoogle')
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const tokenRes = credential.accessToken;

                console.log('credential', result)

                const password = JSON.stringify({uid:result.user.uid});
                let data = {
                    socialtype: "Google",
                    uname: result.user?.email,
                    password:CryptoJS.AES.encrypt(password, SECRETKEY).toString(),
                    os:"web",

                }

                onSubmitLogin(data,"social")


            })
            .catch((error) => {
                toast(error);
            });
    }

    const signInWithFacebook = () => {
        console.log('signInWithFacebook')
        signInWithPopup(auth, providerFb)
            .then((result) => {
                const credential = FacebookAuthProvider.credentialFromResult(result);
                console.log('credential', result)
                const tokenRes = credential.accessToken;
                // localStorage.setItem("token",tokenRes)

            })
            .catch((error) => {
                toast(error);
            });
    }

    return {
        showPassword,
        ShowPasswordClick,
        isModal,
        setIsModal,
        openSetNewPassword,
        openForgotPassword,
        goBackToLogin,
        redirectTo,
        storeUserInfo,
        lowercaseRegex,
        uppercaseRegex,
        numericRegex,
        nonalphanumeric,
        numbersOnly,
        convertUser,
        setUserSessionInfo,
        isSwitchingUser,
        signInWithGoogle,
        signInWithFacebook,
        onSubmitLogin
    }
}
export default useLogin


