import React, {useState} from "react";
import PasswordResetSuccessful from "./passwordresetsuccessful";
import PasswordResetFailed from "./passwordresetfailed";
import PasswordResetForm from "./passwordResetForm";

const SetNewPassword = props => {

    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
    const [passwordFailed, setPasswordFailed] = useState(false);


    if (passwordResetSuccessful) {
        return <PasswordResetSuccessful/>
    } else if (passwordFailed) {
        return <PasswordResetFailed/>
    }

    return (
        <PasswordResetForm {...props}/>
    )
}

export default SetNewPassword;
