import { useState } from "react";
import { toast } from "react-toastify";
import useHttpHandler from "../../../../../hooks/httphandler.hook";

const useAccount = () => {
    const {post} = useHttpHandler();
    const [loading, setLoading] = useState(false)

    const updatePassword = (data, actions) =>{
        //setLoading(true);
        
        
    }
    const [showExistingPassword, setShowExistingPassword] = useState(false);
    const ShowExistingPasswordOnClick = () => {
        setShowExistingPassword(state => !state);
    }
    const [showNewPassword, setShowNewPassword] = useState(false);
    const ShowNewPasswordOnClick = () => {
        setShowNewPassword(state => !state);
    }
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const ShowConfirmNewPasswordOnClick = () => {
        setShowConfirmNewPassword(state => !state);
    }

    return {
        updatePassword,
        loading,
        showExistingPassword,
        ShowExistingPasswordOnClick,
        showNewPassword,
        ShowNewPasswordOnClick,
        showConfirmNewPassword,
        ShowConfirmNewPasswordOnClick
    }

}
export default useAccount
