import { useNavigate } from "react-router-dom";

const useThankYouGuru = () => {
    const navigate = useNavigate()
    const goToAddressInformation = () =>{
        navigate('/instructor/address-information')
    }
    const goToGuruDashboard = () =>{
        navigate('/instructor/dashboard')
    }
    return {
        goToAddressInformation,
        goToGuruDashboard
    }

}
export default useThankYouGuru


