import React, { useEffect, useState } from "react";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import useHelper from "../../../../hooks/helpers.hook";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ChatSwitchButton from "../../../../components/ChatswitchButton";
import { BiEditAlt } from "react-icons/bi";
import { BsArrowDownSquare } from "react-icons/bs";
import DashboardBoxes from "../../../../components/dashboardBoxes";
import chatMonthly from "../../../../assets/img/chatMonthly.png";
import chat1 from "../../../../assets/img/chat1.png";
import chat2 from "../../../../assets/img/chat2.png";
import chat3 from "../../../../assets/img/chat3.png";
import chat4 from "../../../../assets/img/chat4.png";
import chat5 from "../../../../assets/img/chat5.png";
import chatHistory from '../../../../assets/img/chatHistory.png'

const ChatDashBoardView = (props) => {
  const { dashboardDetails } = props?.guruDashboardHook;
  const { kFormatter, getCurrentMonthRange } = useHelper();
  const navigate = useNavigate();

  const onlineStatusChange = (statusbit) => {
    if (statusbit) {
      props.commonHook
        .promiseServiceNonLoading({
          uri: "onlineStatusChange",
          mongoId: dashboardDetails?.mongoId,
          status: 0,
        })
        .then((data) => {});
    } else {
      props.commonHook
        .promiseServiceNonLoading({
          uri: "onlineStatusChange",
          mongoId: dashboardDetails?.mongoId,
          status: 1,
        })
        .then((data) => {});
    }
  };

  return (
    <>
    <div
          className="row all-main-box"
          style={{
            marginLeft: "0px",
            padding: "0px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
        <div className="col-md-12">
          <div className="row gy-lg-4">
            <div className="col-12 col-md-3 ">
              <div className="dash-guru-status-card py-lg-4 h-100">
                <Text
                  type="H1"
                  text={"Chat Status"}
                  className="dgsc-title mb-2"
                />
                <ChatSwitchButton
                  status={dashboardDetails?.onlineStatus}
                  courseStatusChange={onlineStatusChange}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 ">
              <DashboardBoxes
              className="h-100"
                icon={0}
                img={chat1}
                title="Total Paid Minutes"
                number={kFormatter(dashboardDetails?.totalPaidMinutes)}
                onClick={() => {
                  navigate("/instructor/chat/total-minutes");
                }}
                {...props}
              />
            </div>

            <div className="col-12 col-md-3">
              <DashboardBoxes
                icon={0}
                img={chat2}
                title="Earning This Month"
                number={kFormatter(dashboardDetails?.thisMonthEarned)}
                onClick={() => {
                  navigate("/instructor/chat/earning-month");
                }}
                {...props}
              />
            </div>

            <div className="col-12 col-md-3">
              <DashboardBoxes
                icon={0}
                img={chat3}
                title="Total Amount"
                number={"$" + dashboardDetails?.totalEarnedOnChat}
                onClick={() => {
                  navigate("/instructor/chat/total-earnings");
                }}
                {...props}
              />
            </div>

            <div className="col-12 col-md-3">
              <DashboardBoxes
                icon={BiEditAlt}
                img={chat4}
                title="Edit Chat Price"
                buttonTitle="Edit Price"
                number={"$" + dashboardDetails?.chatPrice}
                onClick={() => {
                  navigate("/instructor/chat/instructor-wallet");
                }}
                {...props}
              />
            </div>

            <div className="col-12 col-md-3">
              <DashboardBoxes
                className="h-100"
                icon={0}
                img={chatHistory}
                title="Chat History"
                number={dashboardDetails?.chatHistory}
                onClick={() => {
                  navigate("/instructor/chat/chat-history");
                }}
                {...props}
              />
            </div>

            <div className="col-12 col-md-3 ">
                <div className="h-100">
                        <DashboardBoxes
                        icon={BsArrowDownSquare}
                        img={chat5}
                        title="Withdraw"
                        buttonTitle="Withdraw"
                        number={"$" + dashboardDetails?.avaliable_balance}
                        onClick={() => {
                        navigate("/instructor/dashboard/total-commission");
                        }}
                        {...props}
                    />
                </div>

            </div>
          </div>
        </div>
      </div>
  


      {/*props.titlesObj.showDashboard == 1 &&(
                  
            )

            */}
    </>
  );
};

export default ChatDashBoardView;

const DashGuruStatusTitle = (props) => {
  const { text } = props;
  return (
    <div className="dash-guru-status-card">
      <Text type="H1" text={props.title} className="dgsc-title mb-2" />
      <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle" />
      <Text type="H2" text={props.number} className="dgsc-number" />
      <Button
        type="button"
        className="btn-primary-dashboard"
        text={props.buttonTitle}
        onClick={props.onClick}
      />
    </div>
  );
};
