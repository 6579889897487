import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import SelectNew from "../input/selectnew";
import { DEFAULT_FILTERS } from "../../constants/app.constants";


const FlowCard =(props)=>{


    
    console.log("commonHook.getCategoryId-->",props._props.getCategoryId)
    const navigate = useNavigate();
    const {post} = useHttpHandler();
    const formik = useFormik({
        initialValues: {
            category: props._props.getCategoryId,
            subcategory: props._props.getSubCategoryId,
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Please enter a valid Categeory'),
            subcategory: Yup.string().required('Please enter a valid Sub Categeory'),
        }),
        onSubmit: (values, actions) => {

            actions.setSubmitting(true)
            let postData = {
                
                category: values.category,
                subcategory: parseInt(values.subcategory),
            }

            post('instructorCreateCourse ', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    toast.success(status.msg)
                    navigate('/instructor/course')
                    
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })

        },

    });

  
    
    const setSubcategorys = (e) =>{
     
        props?._props.setSubCategoryId(e.value);
        //formik.setFieldValue('subcategory', e.value)
        getData("subCategory",e.value);
      
    }

    const CourseTyeChange = (e) => {
        
        props?._props.setCourseType(e.value);
        getData("courseType",e.value);
    }

    const getSubCategories = (e) => {
        
        console.log("props",props?.setTableData)
        props?._props.setTableData([]);
        props?._props.setCategoryId(e.value);
        getSubCategoriesById(e.value)
        getData("category",e.value);
        
    }
    
    const getSubCategoriesById = (catId) => {
        
        let subCategoryOptions = []
        if (props?.dropdownsData?.subCategories) {
            props?.dropdownsData?.subCategories.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatId, label: item.subCatName,text: item.subCatName})
                }
            })
        }
        console.log("catId",catId,subCategoryOptions,props?.dropdownsData?.subCategoriesOptions);
        props._props?.setSubCategories(subCategoryOptions)
       
        
    }

    
    const getData = (type,id)=>{
        DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
        DEFAULT_FILTERS.userid = "g";
        DEFAULT_FILTERS.coursetype = props?._props.getCourseType;
        DEFAULT_FILTERS.category_id = props?._props.getCategoryId;
        DEFAULT_FILTERS.subcategory_id = props?._props.getSubCategoryId;

        if(type == "courseType"){
            DEFAULT_FILTERS.coursetype = id;
        }

        if(type == "category"){
            DEFAULT_FILTERS.category_id = id;
        }

        if(type == "subCategory"){
            DEFAULT_FILTERS.subcategory_id = id;
        }
        


        props._props?.getListData(DEFAULT_FILTERS)
    }

    const courseData = [
        {
            value: -1,
            text: 'All Courses',
            
          },
          {
            value: 0,
            text: 'Free Course',
            
          },
        {
          value: 1,
          text: 'Paid Course',
          
        },
       
        {
            value: 2,
            text: 'Private Course',
          
        },
       
      ];
    
    return(
        <>
    
    <div className="row shadow bg-body rounded py-3 mb-3">
   
    <div className="col-lg-4 col-md-2 col-sm-12 mt-10">
            
                <div className="p-0">
            <form onSubmit={formik.handleSubmit}>

        <div className="">
        {/* formBox */} 
      
            <SelectNew  
                tertiary
                label="Course Type"
                name="course_type"
                placeholder="Select Course Type"
                value={formik.values.category}
                onChange={CourseTyeChange}
                onBlur={formik.handleBlur}
                mandatory
                options={courseData}
                errormessage={formik.touched.category && formik.errors.category ? (
                    formik.errors.category
                ) : null}
            />
        </div>
</form>
                </div>
         
        </div>

    <div className="col-lg-4 col-md-2 col-sm-12 mt-10">
            
                <div className="p-0">
            <form onSubmit={formik.handleSubmit}>

        <div className="">
        {/* formBox */}

       

      
            <SelectNew
                tertiary
                label="Category"
                name="category"
                placeholder="Select Category"
                value={formik.values.category}
                onChange={getSubCategories}
                onBlur={formik.handleBlur}
                mandatory
                options={props?.dropdownsData?.categoriesOptions}
                errormessage={formik.touched.category && formik.errors.category ? (
                    formik.errors.category
                ) : null}
            />
        </div>
</form>
                </div>
         
        </div>
    
    <div className="col-lg-4 col-md-6 col-sm-12 mt-10">

                <div className="p-0">           
                    <form onSubmit={formik.handleSubmit}>
                    {/* formBox */}
                        <div className="">
                            <SelectNew
                                tertiary
                                label="Sub Category"
                                name="subcategory"
                                placeholder="Select Sub Category"
                                value={formik.values.subcategory}
                                onChange={setSubcategorys}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={props._props?.subCategories}
                                errormessage={formik.touched.subcategory && formik.errors.subcategory ? (
                                    formik.errors.subcategory
                                ) : null}
                            />
                        </div>
                    </form>
      
                </div>
    </div>

    {/*
        <div className="col-lg-1 col-md-12 col-sm-12">
            <div className="ps-md-0 mb-3 mb-md-0 pe-4 me-4 d-flex flex-row align-items-center">
            <div className="p-1 ml1">
                    <p className="mb-1 text-1 font-weight-bold text-dark text-uppercase">{props.module}</p>
                    <h4 className="mb-0 text-center text-6 text-color-secondary">{props.value}<br/></h4>
                </div>
            </div>
        </div>
        <div className="col-lg-1 col-md-12 col-sm-12">
            <div className="ps-md-0 mb-3 mb-md-0 pe-4 me-4 d-flex flex-row align-items-center">
            <div className="p-1 ml2">
                    <p className="mb-1 mt-1 text-1 font-weight-bold text-dark text-uppercase">{props.availablevideos}</p>
                    <h4 className="mb-0 text-center text-6 text-color-secondary">{props.valuevideo} <br/></h4>
                </div>
            </div>
        </div>
        
                                */}    
    </div>
    


        
        </>
    )
}


export default FlowCard;


