import React from "react";
import ContactUsHero from "../../../components/contactUsHero";
import ContactUsLinks from "../../../components/contactUsLinks";
import PageHeader from "../../../components/pageHeader";


const ContactUsPageView =()=>{
    return(
        <>
        <PageHeader
          title={"Contact Us"}
          page={"Contact Us"}
        />
        <ContactUsHero />
        
        </>
    )
}


export default ContactUsPageView;