import React, {useState} from 'react';
import './switchButton.scss';
import Text from '../text';

const ChatSwitchButton = props => {
    const [isToggled, setIsToggled] = useState(props?.status);
    
    const onToggle = () => {
        setIsToggled(!isToggled)
        changestatus(props);
    };

    function changestatus(props){
        props?.onChange()
        console.log("isToggled-->",isToggled)
        props?.courseStatusChange(isToggled)
    }


    return (
        <label className="toggle-switch">
            <input type="checkbox" checked={isToggled} onChange={onToggle}/>
            <span className="switch">


                    {isToggled == 1 &&(
                        
                        <>
                            <Text type="H5" text="Online" className="text-right switch-innertext p-r-40"/>
                           
                        </>

                    )

                    }

                    {isToggled == 0 &&(
                        <>
                             <Text type="H5" text="Offline" className="text-left switch-innertext p-l-35" />
                           

                        </>

                    )

                    }


            </span>
        </label>
    );
};

export default ChatSwitchButton;
