import {useFormik} from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from '../../../../components/breadcrumbs';
import Card from '../../../../components/card';
import Flex from '../../../../components/flex';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select  from "react-select";
import {toast} from "react-toastify";
import { FaRegTimesCircle } from "react-icons/fa";

import {DEFAULT_PAGE_COUNT, FREEMIUM, PREMIUM} from "../../../../constants/app.constants";

import { useState } from 'react';
import Textarea from '../../../../components/input/textarea';
import { Table } from 'react-bootstrap';
import Pagination from '../../../../components/pagination';
import { useParams } from 'react-router-dom';

const AddQuizView = (props) => {
    
    const [answers,setAnswers] = useState([{label:"Option 1",value:""},{label:"Option 2",value:""}]);
    const tableData = props?.tableData?.listData

    const {courseId} = useParams();
    const addAnswers = ()=>{
        //tempAns.append({"title":"Answer " + parseInt(tempAns.length + 1)})
        //console.log(tempAns);
        //setAnswers(tempAns);
        setAnswers(current => [...current,{label:"Option " + parseInt(answers.length + 1),value:""}]);
    }

    const handleChange = event => {
        
        answers[parseInt(event.target.name)].value = event.target.value;
        console.log('value is:', event.target.value, event.target.name,answers);

    };

    const BreadcrumbList = [
        {
            text: "Add Quiz",
            link: "/instructor/add-quiz/"
        },
      
    ]

   
   

    const formik = useFormik({
        initialValues: {
            courseId: courseId,
            moduleId:props.selectedData.srno,
            question: '',
            correct_answer:'',
           
        },
        validationSchema: Yup.object({
            question: Yup.string('Please enter a Valid Question').required('Please enter a Valid Question'),
                        
        }),
        onSubmit: (values, actions) => {
           
            actions.setSubmitting(true)
            values.uri = "/iCreateQuestion";
            values.options = answers;
            console.log("values",values)
            
            
            props.promiseService(values).then((data) => {

            console.log("data----->",data.data.status.result);
                
                if(data.data.status.result == 200){
                    toast.success(data.data.status.msg);
                   
                }else{
                    toast.error(data.data.status.msg);
                }
                props.setisShowPopUP(false);
              
            
            });
            
          
        },
    });

    return (
        <>

            
          

          <form onSubmit={formik.handleSubmit}>

               

<div className="row">

<div className="col-12 col-md-12 py-2 flex-space-between border-bottom">
<h3 className='text-theme-color'>Add Quiz</h3>     <div className='fs-5 text-primary'> <FaRegTimesCircle /> </div>
</div>
                <div className="col-12 col-md-12">
                    <div className="formBox pt-3">
                        <Textarea
                            label="Question"
                            type="text"
                            name="question"
                            autoFocus={true}
                            value={formik.values.question}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errormessage={formik.touched.question && formik.errors.address1 ? (
                                formik.errors.question
                            ) : null}
                        />
                    </div>
                </div>
                

                
               

                {answers?.map(function (item, index) {
                return (
                    
                    <div className="col-12 col-md-6">
                            <div className="formBox">
                                <Input
                                    label={item.label}
                                    type="text"
                                    name={index}
                                    autoFocus={true}
                                    onChange={handleChange}
                                  
                                   
                                />
                            </div>
                    </div>
                    
                
                )
                })}     
                <div className="col-12 col-md-6 mb-20 mt--10">  
        <Flex justifyContent="start">
            <Button
                type="button"
                text="+ Add Option"
                className="btn-link-primary px-3 py-2 mt-4"
                onClick={(e) => {
                    console.log("11")
                   // console.log("221")
                   

                   addAnswers();

                   
                }}
            />
        </Flex>
    </div>     

 

                             
       
              

               




</div>
<div className='row'>
<div className="col-6 col-md-6">
                    <div className="text-center">
                     
                     
                            <Select
                            placeholder="Choose Correct Answer above"
                                className="form-select-container"
                                classNamePrefix="form-select-styled"
                                name="correct_answer"
                                onChange={((e)=>{formik.setFieldValue('correct_answer', e.label)})}  
                                options={answers}
                            />
                        
                    </div>
                </div>
<div className='col-6 col-md-6'>
<div className="action-buttons-end mt-0 pt-1">
                     <Button
                        type="button"
                        text="cancel"
                        onClick={()=>{ 

                            props.setisShowPopUP(false);


                        }}
                    />
                   <Button
                        outline
                        type="submit"
                        text="save"
                     
                        disabled={formik.isSubmitting}
                        isSubmitting={formik.isSubmitting}
                    />
                    
        </div>
</div>
</div>





</form>
           

       
        </>
    );
};

export default AddQuizView;
