import React from "react";

import LayoutPanel from "../../../../components/layoutPanel";
import WalletPaymentFailedView from "../views/walletPaymentFailedView";

const WalletPaymentFailed = props => {

    return (
        <>
            <LayoutPanel {...props}>
                <WalletPaymentFailedView {...props} />
            </LayoutPanel>
        </>
    )
}

export default WalletPaymentFailed;
