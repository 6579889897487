import React from "react";
import Nav from "../nav";
import './navLearnerMember.scss';

const NavLearnerMember = props => {
    const {text}=props
    return(
        <>
            <div className="nav-tab-panel-wrp">
                <Nav
                    options={[
                        {
                            text:"My Profile",
                            link: "/instructor/settings/profile",
                        },
                        {
                            text:"Bank Details",
                            link: "/instructor/settings/bank-details",
                        },
                        {
                            text:"Reset Password",
                            link: "/instructor/settings/account",
                        },
                    ]}
                />
            </div>
        </>
    )
}

export default NavLearnerMember;
