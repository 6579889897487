import React from 'react';

const LockIcon = props => {
    return (
        <>
            <svg width={props.width} height={props.height} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.61547 15H4.38547C2.48448 14.9677 0.969283 13.401 1.00047 11.5V8C0.983141 6.42628 2.02358 5.03658 3.53847 4.61V4.15C3.48798 2.46276 4.81332 1.0533 6.50047 1C8.18763 1.0533 9.51296 2.46276 9.46247 4.15V4.61C10.9774 5.03658 12.0178 6.42628 12.0005 8V11.5C12.0317 13.401 10.5165 14.9677 8.61547 15Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.75006 11.5C5.75006 11.9142 6.08585 12.25 6.50006 12.25C6.91428 12.25 7.25006 11.9142 7.25006 11.5H5.75006ZM7.25006 9.75C7.25006 9.33579 6.91428 9 6.50006 9C6.08585 9 5.75006 9.33579 5.75006 9.75H7.25006ZM9.2696 5.33489C9.66995 5.44118 10.0807 5.2028 10.1869 4.80246C10.2932 4.40212 10.0549 3.99141 9.65452 3.88512L9.2696 5.33489ZM8.61606 4.5V5.25L8.61675 5.25L8.61606 4.5ZM4.38506 4.5L4.38437 5.25H4.38506V4.5ZM3.3466 3.88512C2.94626 3.99141 2.70788 4.40212 2.81418 4.80246C2.92047 5.2028 3.33118 5.44118 3.73152 5.33489L3.3466 3.88512ZM7.25006 11.5V9.75H5.75006V11.5H7.25006ZM9.65452 3.88512C9.31549 3.7951 8.96616 3.74968 8.61537 3.75L8.61675 5.25C8.83713 5.2498 9.0566 5.27833 9.2696 5.33489L9.65452 3.88512ZM8.61606 3.75H4.38506V5.25H8.61606V3.75ZM4.38575 3.75C4.03497 3.74968 3.68564 3.7951 3.3466 3.88512L3.73152 5.33489C3.94452 5.27833 4.16399 5.2498 4.38437 5.25L4.38575 3.75Z" fill="currentColor"/>
            </svg>
        </>
    );
};

export default LockIcon;

LockIcon.defaultProps = {
    width: '13',
    height: '16'
}