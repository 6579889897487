import React from 'react';
import Flex from "../flex";
import Text from "../text";
import Button from "../button";
import Modal from "../modal";
import {useNavigate} from "react-router-dom";
import GlobalInfoModal from "../globalInfoModal";
import useSubscribePremiumModal from "./hooks/subscribePremiumModal.hook";

const SubscribePremiumModal = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const navigate = useNavigate()
    const closeModal = () => {
        props.setIsModal(false)
        if (props.navigateBack) {
            navigate(-1)
        }
    }
    const onClickOuterClose = () => {
        if (props.outerClose) {
            props.setIsModal(false)
        }
    }

    if (subscribePremiumModalHook.isPremiumSubscribeModal) {
        return (
            <GlobalInfoModal
                smallModal={true}
                title={props?.text?.BECOME_SUBSCRIBER_MODAL_CONTENT}
                canClose={true}
                setIsModal={subscribePremiumModalHook.setIsPaymentUnderProcess}
                onClick={subscribePremiumModalHook.goToPayment}
                buttonText={props?.text?.SUBSCRIBE_NOW}
                navigateBack
            />
        )
    } else if (subscribePremiumModalHook.isProfileInComplete) {
        return (
            <GlobalInfoModal
                title={props?.text?.IN_ORDER_TO_RECEIVE_COMMISSION}
                buttonText={props?.text?.FILL_IN_EXTRA_DETAIL}
                canClose={true}
                smallModal
                onClick={() => {
                    navigate('/trainee/personal-information')
                }}
                setIsModal={subscribePremiumModalHook.setIsProfileInComplete}

            />
        )
    } else if (subscribePremiumModalHook.isPaymentUnderProcess) {
        return (
            <GlobalInfoModal
                title='Payment is under process'
                subtitle='kindly ensure your bank/credit card have sufficient amount to continue enjoy the full benefit from our platform'
                canClose={true}
                setIsModal={subscribePremiumModalHook.setIsPaymentUnderProcess}
                onClick={() => {
                }}
            />
        )
    } else {
        return <></>
    }


};

export default SubscribePremiumModal;
SubscribePremiumModal.defaultProps = {
    navigateBack: false,
    outerClose: false,
}


