import React from "react";
import ContactUsImg from "../../assets/img/about.jpg";
import "./contactUsHero.css";
import { IoLocationSharp, IoShareSocial } from "react-icons/io5";
import {
  BsAlarmFill,
  BsFillTelephoneFill,
  BsMailbox,
  BsMailbox2,
} from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";

const ContactUsHero = () => {
  return (
    <>
      <section class="section bg-color-grey-scale-1">
        <div class="container">
          <div class="row align-items-center gy-4">
            <div class="col-md-6">
              <div className="row gy-4 ">
                <div class="col-md-6">
                  <div class="info-item">
                    <div class="feature-box-icon">
                      <IoLocationSharp className="text-color-light fa-2x" />
                    </div>
                    <h3>Address</h3>
                    <p>
                      Strawberry Fields Digital HubEuxton Lane Chorley, PR7 1PS,
                      United Kingdom{" "}
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="info-item">
                    <div class="feature-box-icon">
                      <BsFillTelephoneFill className="fa-solid fa-phone text-color-light fa-2x" />
                    </div>
                    <h3>Call Us</h3>
                    <p>+44 1257 826 717</p>
                    <p>+44 1257 826 717</p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="info-item">
                    <div class="feature-box-icon">
                      <BsMailbox2 className="fa-light fa-email text-color-light fa-2x" />
                    </div>
                    <h3>Email Us</h3>
                    <p>info@coursetsunami.com</p>
                    <p>info@coursetsunami.com</p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="info-item">
                    <div class="feature-box-icon">
                      <BsAlarmFill className="fa-light fa-share-nodes text-color-light fa-2x" />
                    </div>
                    <h3>Open Hours</h3>
                    <p>Monday - Friday</p>
                    <p>9:00AM - 05:00PM</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-lg-5">
              <div class="px-4">
                <h2 class="font-weight-bold line-height-1 mb-3">Contact Us</h2>

                <form
                  class="contact-form form-style-2 pe-xl-5"
                  action="php/contact-form.php"
                  method="POST"
                  novalidate="novalidate"
                >
                  <div class="contact-form-success alert alert-success d-none mt-4">
                    <strong>Success!</strong> Your message has been sent to us.
                  </div>

                  <div class="contact-form-error alert alert-danger d-none mt-4">
                    <strong>Error!</strong> There was an error sending your
                    message.
                    <span class="mail-error-message text-1 d-block"></span>
                  </div>

                  <div class="row">
                    <div class="form-group col">
                      <input
                        type="text"
                        value=""
                        data-msg-required="Please enter your name."
                        maxlength="100"
                        class="form-control"
                        name="name"
                        placeholder="Name"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <input
                        type="email"
                        value=""
                        data-msg-required="Please enter your email address."
                        data-msg-email="Please enter a valid email address."
                        maxlength="100"
                        class="form-control"
                        name="email"
                        placeholder="Email"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <input
                        type="text"
                        value=""
                        data-msg-required="Please enter the subject."
                        maxlength="100"
                        class="form-control"
                        name="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <textarea
                        maxlength="5000"
                        data-msg-required="Please enter your message."
                        rows="4"
                        class="form-control"
                        name="message"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col text-right mt-2">
                      <input
                        type="submit"
                        value="SUBMIT"
                        class="btn btn-primary btn-rounded font-weight-semibold px-5 btn-py-2"
                        data-loading-text="Loading..."
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsHero;
