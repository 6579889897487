import React from 'react';
import usePersonalInformation from '../hooks/thankYouGuru.hook'
import Text from '../../../../components/text';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';

const ThankYouGuruView = (props) => {
    const {text} = props
    const {
        goToAddressInformation,
        goToGuruDashboard
    } = usePersonalInformation()
    return (
        <>
            <Flex
                justifyContent="center"
            >
                <Text
                    type="H2"
                    text={text?.THANK_YOU_FOR_COMPLETING_YOUR_PROFILE}
                    className="text-center"

                />
            </Flex>
            <Flex
                justifyContent="center"
                className=""
            >
                <Text
                    type="PARAGRAPH"
                    text={text?.YOU_APPLICATION_IS_CURRENTLY_BEING_REVIEWED_BY_ADMIN_YOU_WILL_BE_CONTACTED_WITHIN_2_WEEKS}
                    className="col-7 mx-auto text-center mb-80"
                />
            </Flex>
            <Flex justifyContent="center">
                <Button large text={text?.GO_TO_DASHBOARD} onClick={goToGuruDashboard}/>
            </Flex>
        </>
    );
};

export default ThankYouGuruView;

