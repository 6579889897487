import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {useState} from "react";

const useMyNetwork = () => {
    const [loading, setLoading] = useState(false)
    const [memberNetworkData, setMemberNetworkData] = useState(null)
    const [memberList, setMemberList] = useState(null)
    const {post} = useHttpHandler()
    const defaultUserId = 0

    const getMemberNetworkData = (userid) => {
        setLoading(true)
        post('getmemberchain', {userid: userid ? userid :defaultUserId}).then((res) => {
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setMemberNetworkData(data)

                let memberOptions = [];
                res?.data?.search_data?.map((item, index) => {
                    memberOptions.push({value: item.userid, label: item.uname})
                })
                setMemberList(
                    memberOptions
                )
            } else {
                setMemberNetworkData(null)

            }
        }).catch(err=>{setLoading(false)})
    }

    return {
        getMemberNetworkData,
        memberNetworkData,
        loading,
        memberList
    }

}
export default useMyNetwork
