import React from 'react';
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import Icon from '../../../../components/icon';
import CloseTwoIcon from '../../../../components/svg/closeTwoIcon'
import {useNavigate} from "react-router-dom";

const WalletPaymentFailedView = (props) => {
    const navigate = useNavigate()
    const {text} = props
    return (
        <>
        <Flex justifyContent="center" className="flex-column align-items-center text-center">
            <Text
                type="H2"
                text={text?.SYSTEM_FAILED_TO_MAKE_PAYMENT}
                className="mb-40"
            />
            <Icon
                icon={<CloseTwoIcon width="41" height="41" />}
                extralarge
                danger
            />
            <Text
                type="PARAGRAPH"
                text={props?.payStatusMessage ? props?.payStatusMessage : text?.PAYMENT_FAILED_PLEASE_CHECK_YOUR_INTERNET_CONNECTION_OR_CONTACT_YOUR_BANK_IMMEDIATELY}
                className="mt-20 mb-64 text-danger font-size-20 col-12 col-md-10 mx-auto"
            />
            <Button large text={text?.RETURN_TO_PAYMENT} onClick={()=>{navigate('/trainee/payment')}} />
        </Flex>
        </>
    );
};

export default WalletPaymentFailedView;

