import React from "react";
import ProductCard from "../../../../components/productCard";
import '../../instructorpages/instructor.css';


const ProductPageView =()=>{
    
    return(
        <>
        <div className="page-bg pt-4 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                  <p className="product-description">7 Products</p>
                </div>
                <div className="col-md-6 text-end">
                <p className="product-description"> <span className="sort-title">Sort:Newest</span></p>
                </div>
                <div className="col-md-4 mb-3">
                <ProductCard  title="Thomas Course #2 Template"/>
                </div>
                <div className="col-md-4 mb-3">
                <ProductCard  title="Sara Course #1 Template"/>
                </div>
                <div className="col-md-4 mb-3">
                <ProductCard title="Membership"/>
                </div>
                <div className="col-md-4">
                <ProductCard title="Sara Course #1 Clone"/>
                </div>
                <div className="col-md-4">
                <ProductCard title="Membership"/>
                </div>
                <div className="col-md-4">
                <ProductCard title="Membership"/>
                </div>
            </div>
        </div>
        </div>
        
        </>
    )
}


export default ProductPageView;