import React from 'react'
import Breadcrumbs from '../../../../components/breadcrumbs'
import LearnerCard from '../../../../components/traineeCard'
import Pagination from '../../../../components/pagination'
import Section from '../../../../components/section'
import Table from '../../../../components/table'
import Text from '../../../../components/text'
import InstructorImage from '../../../../assets/img/logo.svg';
import TopicVideoCard from '../../../../components/topicVideoCard'
import { useNavigate } from 'react-router-dom'
import LearnByTopics from '../../../../components/learnByTopics'

const TrendingTopicPageView = (props) => {
  const tableData = props?.tableData?.listData
  const navigate = useNavigate()

  const breadcrumbList = [
    {
        text: 'Dashboard',
        link: '/trainee/dashboard',
    },
    {
        text:"Courses",
        link:"/trainee/courses",
    },
    {
      text:"Trending Topics",
      link:"/trainee/trending-topic",
  },
]
  return (
    <div>
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
       <Section className="pt-0 lbt-section rml-24 overflow-outer">
                <div className="section-head mt-2">
                    <Text
                        type="H3"
                        text="Trending Courses"
                        className="section-title text-uppercase"
                    />
                </div>
                <LearnByTopics {...props}/>
           </Section>
    </div>
  )
}

export default TrendingTopicPageView
