import React from "react";
import InstructorDashboardpageView from "./views/instructorDashboardPageView";


const InstructorDashboardpage =()=>{
    return(
        <>
        <InstructorDashboardpageView />
        
        </>
    )
}

export default InstructorDashboardpage;