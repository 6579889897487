import React from "react";
import Image from "../image";
import CateExperienceIcon from "../svg/cateExperienceIcon";
import CateTypeIcon from "../svg/cateTypeIcon";
import VideoPlayerTwoIcon from "../svg/videoPlayerTwoIcon";
import Text from "../text";
import './searchGuruCard.scss'

const SearchGuruCard = props => {
    return(
        <div className="search-guru-card pointer" onClick={props?.onClick}>
            <div className="search-guru-image">
                <Image
                    src={props.src}
                    alt={props.name}
                    width="212"
                    height="284"
                />
            </div>
            <div className="search-guru-content">
                <Text type="H3" className="search-guru-content-title" text={props.name} />
                <div className="search-guru-content-cate">
                    <SearchCategoryList
                        icon={<CateTypeIcon />}
                        catetitle={props?.preferredTopic}
                    />
                    <SearchCategoryList
                        icon={<CateExperienceIcon />}
                        catetitle={`${props?.experience} years`}
                    />
                    <SearchCategoryList
                        icon={<VideoPlayerTwoIcon />}
                        catetitle={`${props?.videosCount} videos`}
                    />
                </div>
                <Text type="PARAGRAPH" className="mb-10" text={props.subtitle} />
                <Text type="PARAGRAPH" className="search-guru-content-description" text={props.description} />
            </div>
        </div>
    )
}

export default SearchGuruCard;

const SearchCategoryList = props => {
    return(
        <div className="search-guru-content-cate-items">
            <span className="cate-items-icon">{props.icon}</span>
            <span className="cate-items-title">{props.catetitle}</span>
        </div>
    )
}
