import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from "../breadcrumbs";
import Button from "../button";
import FlowCard from "../flowCard";
import Image from "../image";
import Input from "../input";
import Select from "../input/select";
import Textarea from "../input/textarea";
import ProgressBarCard from "../programBarCard";
import Text from "../text";
import { UploadComponent } from "../../pages/instructor/uploadAttachments/view/UploadAttachmentsView";
import binImg from "../../assets/img/Bin.png";
import tickImg from "../../assets/img/checkcircle.png";
import downloadImg from "../../assets/img/download.png";
import useHttpHandler from "../../hooks/httphandler.hook";
import AvatarPic from "../../assets/images/avatar_pic.jpg";
import axios from "axios";
import ModuleCard from "../moduleCard";
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE } from "../../constants/app.constants";
import useHelper from "../../hooks/helpers.hook";
import useGuruUploadVideo from "../../pages/instructor/uploadVideo/hooks/guruUploadVideo.hook";
import { toast } from "react-toastify";
import Modal from "../modal";
import VideoPlayer from "../videoplayer";
import Icon from "../icon";
import TickIcon from "../svg/tickIcon";
import { useDropzone } from "react-dropzone";
import CameraIcon from "../svg/cameraIcon";
import { Result } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import ThumbNailUpload from "./videoUpload";
import PdfUpload from "./pdfUpload";
import VideoUpload from "./videoUpload";
import UploadVideoPreview from "./videoUploadPreview";
import EditUploadVideoPreview from "./EditVideoUploadPreview";
import informationIcon from "../../assets/img/information.png";
import Flex from "../flex";

const EditModuleComponent = (props) => {
  const guruUploadVideoHook = useGuruUploadVideo();
  const [progress, setProgress] = useState(null);
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { videoId } = useParams();
  const { post } = useHttpHandler();
  const { getFileExtension, getFileSize } = useHelper();
  const { goToUploadSuccess, goToGuruVideoGuideline } = useGuruUploadVideo();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [isSucess, setSucess] = useState(false);
  const [showVideoEnded, setShowVideoEnded] = useState(false);
  const [videoSampleFile, setvideoSampleFile] = useState([
    {
      newfile_name: props.data.introvideo,
      filename: "responseData.data.data[0].newfile_name",
      file_size: "responseData.data.data[0].newfile_name",
      file_type: "responseData.data.data[0].newfile_name",
      thumbnail: "responseData.data.data[0].newfile_name",
      duration: "responseData.data.data[0].newfile_name",
      thumbnail: props.data.thumbnail,
      filename: props.data.thumbnail,
      newfile_name: props.data.thumbnail,
    },
  ]);

  const [videoDetails, setVideoDetails] = useState(null);
  const toggleIsModal = (video) => {
    console.log("video", video);
    setVideoDetails(video[0]);

    setIsModal((state) => !state);
  };
  const moduleOptions = props.moduleOptions;

  const {
    dropdownsData,
    uploadedImages,
    setUploadedImages,
    pdfuploadedImages,
    pdfsetUploadedImages,
    userInfo,
    uploadedVideosInfo,
    setUploadedVideosInfo,
    isUploading,
    setIsUploading,
    setCheck,
    getCheck,
  } = guruUploadVideoHook;

  if (getCheck) {
    setCheck(false);
    setUploadedImages([
      {
        fieldname: "files",
        size: 275059,
        newfile_name: props.data.thumbnail,
        filename: props.data.introvideo,
      },
    ]);
    if (props.data.attacheddoc != "") {
      pdfsetUploadedImages([{ newfile_name: props.data.attacheddoc }]);
    }
  }

  const formik = useFormik({
    initialValues: {
      modulelevel: 1000,
      title: props?.data?.title,
      prioritylevel: props?.data?.priority,
      description: props?.data?.desc,
      sampleFile: [],
      pdfsampleFile: [],
      videosampleFile: videoSampleFile,
    },
    validationSchema: Yup.object({
      /* modulelevel: Yup.string().required('Please enter a valid module level'), */
      title: Yup.string().required("Please enter a valid Title"),
      description: Yup.string().required("Please enter a valid description"),
      videosampleFile: Yup.array()
        .min(1, "Please Upload Video File")
        .required("Please Upload Video File"),
      /*  prioritylevel: Yup.string().required('Please enter a valid priority level'),
            sampleFile:Yup.array().min(1,'Please Upload thumbnail').required("Please Upload thumbnail"),
            pdfsampleFile:Yup.array().min(1,'Please Upload PDF Document').required("Please Upload PDF Document"),**/
    }),
    onSubmit: (values, actions) => {
      console.log("123123");

      actions.setSubmitting(false);

      var attachment = "";
      //if(pdfuploadedImages != null){

      if (pdfuploadedImages?.length >= 1) {
        attachment = pdfuploadedImages[0].newfile_name;
      }
      //}
      var videoFile = videoSampleFile[0].newfile_name;

      if (uploadedVideosInfo != null) {
        if (
          videoSampleFile[0].newfile_name != uploadedVideosInfo[0].newfile_name
        ) {
          videoFile = uploadedVideosInfo[0].newfile_name;
        }
      }

      let postData = {
        moduleId: props.data.srno,
        courseid: props.courseId,
        modulelevelid: 1000,
        moduletitle: values.title,
        priority: 1,
        desc: values.description,
        attachment: attachment,
        thumbnail: uploadedImages[0].newfile_name,
        introvideo: videoFile,
      };
      console.log("postData", postData);

      post("instructorEditModule", postData)
        .then((res) => {
          actions.setSubmitting(false);
          const status = res.data.status;
          const data = res.data.data;
          if (status.result == "200") {
            // navigate('/instructor/create-module/'+ props.courseId)
            //  toast.success("Sucessfull update module");

            setSucess(true);

            //props.setisShowPopUP(false);
            // props.setCustomData(null);
            //props.setLevel(true);
          }
        })
        .catch((err) => {
          actions.setSubmitting(false);
        });
    },
  });

  const handleDownload = (blob, filename) => {
    if (!window) {
      return;
    }
    const blobUrl = blob;
    const anchor = window.document.createElement("a");
    anchor.download = filename;
    anchor.href = blobUrl;
    anchor.click();
    window.URL.revokeObjectURL(blobUrl, "_blank");
  };

  const testobj = {
    "image/*": [".png", ".jpeg", ".jpg"],
    "video/*": [".mp4", ".mov"],
  };

  useEffect(() => {
    if (formik.values.sampleFile.length > 0) {
      let formData = new FormData();
      formik?.values?.sampleFile?.map((file, index) => {
        formData.append("files", file);
      });

      const apiUrl = process.env.REACT_APP_API_URL + "/instructorUpload";

      axios
        .post(apiUrl, formData, {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const status = res.data.status;
          const data = res.data.data;
          if (status.result == "200") {
            setUploadedImages(data);
          }
        })
        .catch((error) => {});
    }
  }, [formik.values.sampleFile]);

  useEffect(() => {
    console.log("pdf_2", formik.values.pdfsampleFile);
    if (formik.values.pdfsampleFile.length > 0) {
      let formData = new FormData();
      formik?.values?.pdfsampleFile?.map((pdffile, index) => {
        formData.append("files", pdffile);
      });

      const apiUrl = process.env.REACT_APP_API_URL + "/instructorUpload";

      axios
        .post(apiUrl, formData, {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const status = res.data.status;
          const data = res.data.data;
          if (status.result == "200") {
            pdfsetUploadedImages(data);
          }
        })

        .catch((error) => {});
    }
  }, [formik.values.pdfsampleFile]);

  return (
    <div>
      {isSucess && (
        <>
          <Modal
            className="modal-signup-right-area"
            onOuterClose={() => {
              props.setisShowPopUP(false);
              props.setCustomData(null);
              props.setLevel(true);
            }}
            modalContent={
              <>
                <div className="homevideomodal-wrapper">
                  <div className="homevideomodal-content-bottom">
                    <Text
                      type="H"
                      className=""
                      text="Successfully Edited Module"
                    />

                    <Button
                      type="primary"
                      key="console"
                      text="Done"
                      onClick={() => {
                        console.log("close");
                        props.setisShowPopUP(false);
                        props.setCustomData(null);
                        props.setLevel(true);
                      }}
                    />
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
      {!isSucess && (
        <form onSubmit={formik.handleSubmit}>
          <b>Edit Module</b>
          <div className="card border-radius-5 pad-10">
            <div className="col-12 cl-xs-12">
              {/*
                        <div className="formBox">
                            <Select
                                name="modulelevel"
                                placeholder="All The Above"
                                value={formik.values.modulelevel}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={moduleOptions}
                                errormessage={formik.touched.modulelevel && formik.errors.modulelevel ? (
                                    formik.errors.modulelevel
                                ) : null}
                            />
                        </div>
                        
                    */}

              <div className="formBox">
                <Input
                  star={true}
                  autoFocus={true}
                  type="text"
                  name="title"
                  placeholder="Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  mandatory
                  errormessage={
                    formik.touched.title && formik.errors.title
                      ? formik.errors.title
                      : null
                  }
                />
              </div>

              <div className="formBox">
                <Textarea
                  star={true}
                  tertiary
                  name="description"
                  placeholder="Write your description here..."
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  mandatory
                  rows="5"
                  errormessage={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                ></Textarea>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 mtb-10 ">
                <div className="card border-radius-5 pad-10 h-100 px-3">
                  <div className="row">
                    <div className='="col-12'>
                      <div className="">
                        <div className="form-field mb-10">
                          <Text
                            type="H5"
                            className="font-weight-500 mb-24"
                            text="Step -1"
                          />

                          <label className="form-label">Thumbnail </label>
                        </div>

                        <div className="col-12 create_course_image">
                          <UploadComponent
                            setFieldValue={formik.setFieldValue}
                            maxFilesUpload="3"
                            hideFooter
                            hideButton
                            thumbNailWrap
                            thumb="Upload Thumbnail"
                            fieldName="sampleFile"
                            accpetFileType={IMAGE_FILE_TYPE}
                            uploadedImages={uploadedImages}
                          />
                          {formik.touched.sampleFile &&
                          formik.errors.sampleFile ? (
                            <div className="message-error">
                              <small>{formik.errors.sampleFile}</small>
                            </div>
                          ) : null}

                          {/*
                                                uploadedImages && uploadedImages.length > 0 &&
                                                uploadedImages?.map((file, i) => (
                                                    <div key={i} className="col-12 ">
                                                        {file?.newfile_name && (
                                                            <img src={process.env.REACT_APP_IMG_URL + file?.newfile_name} alt="" className='course-upload-wrap' style={{ marginTop:"-4px" }} />      
                                                        )}
                                                    </div>
                                                ))
                                                */}
                        </div>

                        {!uploadedImages && (
                          <>
                            <div className="col-12 ">
                              <div className="thumbimgempty"></div>
                            </div>
                          </>
                        )}

                        <Text
                          type="PARAGRAPH"
                          className="text-black-half my-2 mt-20"
                          text="This image will displayed for category when your members view their library."
                        />

                        <Text
                          type="PARAGRAPH"
                          className="text-black-half my-0"
                          text="Recommended dimensions of"
                        />

                        <Text
                          type="PARAGRAPH"
                          className="text-black my-0"
                          text="1024x720"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mtb-10 ">
                <div className="card border-radius-5 pad-10 h-100 px-3">
                  <div className="row">
                    <div className='="col-12'>
                      <div className="">
                        <div className="form-field mb-10">
                          <Text
                            type="H5"
                            className="font-weight-500 mb-24"
                            text="Step -2"
                          />

                          <label className="form-label">
                            Upload Video<span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 ">
                      <EditUploadVideoPreview
                        setFieldValue={formik.setFieldValue}
                        text="Drag and Drop Files"
                        setUploadedVideosInfo={setUploadedVideosInfo}
                        uploadedVideosInfo={uploadedVideosInfo}
                        setIsUploading={setIsUploading}
                        videoSampleFile={videoSampleFile}
                        fieldName="videosampleFile"
                      />
                      <Button
                        type="button"
                        text="Play Video"
                        isSubmitting={isUploading}
                        onClick={() => toggleIsModal(videoSampleFile)}
                        className="mt-3 fs-8"
                      />

                      {formik.touched.videosampleFile &&
                      formik.errors.videosampleFile ? (
                        <div className="message-error">
                          <small>{formik.errors.videosampleFile}</small>
                        </div>
                      ) : null}
                    </div>
                    <Text
                      type="PARAGRAPH"
                      className="my-0 font-weight-500"
                      text="Upload your video here, can drop or browse the file."
                    />

                    <Text
                      type="PARAGRAPH"
                      className="text-black-half my-0"
                      text="Format: MP4,MPEG"
                    />

                    {uploadedVideosInfo && (
                      <>
                        <div className="row mt-20">
                          <div className="col-3 col-md-4 col-xs-4">
                            <Icon
                              icon={<TickIcon width="24" height="24" />}
                              success
                            />
                          </div>
                          <div className="col-9 col-md-4 col-xs-6">
                            <Text
                              type="PARAGRAPH"
                              className="font-weight-500 text-black-half"
                              text="Upload Successful"
                            />
                          </div>

                          <div className="col-12 col-md-4 col-xs-12 p-10 text-center">
                            <Button
                              type="button"
                              text="Play Video"
                              isSubmitting={isUploading}
                              onClick={() => toggleIsModal(uploadedVideosInfo)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mtb-10 ">
                <div className="card border-radius-5 pad-10 h-100 px-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="">
                        <div className="form-field mb-10">
                          <Text
                            type="H5"
                            className="font-weight-500 mb-24"
                            text="Step -3"
                          />

                          <label className="form-label">Upload Document </label>
                        </div>

                        <div className="form-field form-field-tertiary">
                          {
                            <div className="row">
                              {pdfuploadedImages == null && (
                                <div className="col-12 col-md-12">
                                  <UploadComponent
                                    setFieldValue={formik.setFieldValue}
                                    maxFilesUpload="3"
                                    hideFooter
                                    hideButton
                                    thumbNailWrap
                                    thumb="Upload PDF"
                                    fieldName="pdfsampleFile"
                                    accpetFileType={DOC_FILE_TYPE}
                                  />
                                  {formik.touched.pdfsampleFile &&
                                  formik.errors.pdfsampleFile ? (
                                    <div className="message-error">
                                      <small>
                                        {formik.errors.pdfsampleFile}
                                      </small>
                                    </div>
                                  ) : null}
                                </div>
                              )}

                              <Text
                                type="PARAGRAPH"
                                className="text-white-half mt-20"
                                text="Upload your Document here. Drag and drop or browse the file"
                              />
                            {/*
                              {pdfuploadedImages &&
                                pdfuploadedImages.length > 0 &&
                                pdfuploadedImages?.map((pdffile, i) => (
                                  <div key={i} className="col-12 col-md-12">
                                    {pdffile?.newfile_name && (
                                      <>
                                        <Text
                                          type="H5"
                                          className="font-weight-500 mb-24"
                                          text={
                                            process.env.REACT_APP_IMG_URL +
                                            pdffile?.newfile_name
                                          }
                                        />

                                        <Flex>
                                          <Icon
                                            icon={
                                              <TickIcon
                                                width="24"
                                                height="24"
                                              />
                                            }
                                            success
                                          />

                                          <Button
                                            link
                                            text={
                                              <img
                                                src={binImg}
                                                alt="copy"
                                                className="icon-50"
                                              />
                                            }
                                            style={{
                                              margin: "0px",
                                              padding: "1px",
                                            }}
                                            onClick={() =>
                                              pdfsetUploadedImages(null)
                                            }
                                            type="button"
                                          />

                                          <Button
                                            link
                                            text={
                                              <img
                                                src={downloadImg}
                                                alt="copy"
                                                className="icon-50"
                                              />
                                            }
                                            style={{
                                              margin: "0px",
                                              padding: "1px",
                                            }}
                                            onClick={() =>
                                              handleDownload(
                                                process.env.REACT_APP_IMG_URL +
                                                  pdffile?.newfile_name,
                                                pdffile?.newfile_name
                                              )
                                            }
                                            type="button"
                                          />
                                        </Flex>
                                      </>
                                    )}
                                  </div>
                                ))}

                                */}


                                      <div  className="col-12 col-md-12">
                                       
                                          <>
                                            <Text
                                              type="H5"
                                              className="font-weight-500 mb-24"
                                              text={
                                                "process.env.REACT_APP_IMG_UR"
                                              }
                                            />
    
                                            <Flex>
                                             
                                              <Button
                                                link
                                                text={
                                                  <img
                                                    src={tickImg}
                                                    alt="copy"
                                                    className="icon-32"
                                                  />
                                                }
                                                style={{
                                                  margin: "0px",
                                                  padding: "1px",
                                                }}
                                                onClick={() =>
                                                  pdfsetUploadedImages(null)
                                                }
                                                type="button"
                                              />
    
                                              <Button
                                                link
                                                text={
                                                  <img
                                                    src={binImg}
                                                    alt="copy"
                                                    className="icon-32"
                                                  />
                                                }
                                                style={{
                                                  margin: "0px",
                                                  padding: "1px",
                                                }}
                                                onClick={() =>
                                                  pdfsetUploadedImages(null)
                                                }
                                                type="button"
                                              />
    
                                              <Button
                                                link
                                                text={
                                                  <img
                                                    src={downloadImg}
                                                    alt="copy"
                                                    className="icon-32"
                                                  />
                                                }
                                                style={{
                                                  margin: "0px",
                                                  padding: "1px",
                                                }}
                                                
                                                type="button"
                                              />
                                            </Flex>
                                          </>
                                     
                                      </div>
                                    
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="action-buttons-end mt_-10 mb_-30">
            <Button
              type="button"
              text="cancel"
              onClick={() => {
                props.setisShowPopUP(false);
              }}
            />
            <Button
              outline
              type="submit"
              text="save"
              disabled={formik.isSubmitting}
              isSubmitting={formik.isSubmitting}
            />
          </div>
        </form>
      )}

      {isModal && (
        <Modal
          onClose={() => setIsModal(false)}
          onOuterClose={() => setIsModal(false)}
          modalContent={
            <>
              <VideoPlayer
                videourl={
                  process.env.REACT_APP_IMG_URL + videoDetails.newfile_name
                }
                width="100%"
                height="460"
              />
            </>
          }
        />
      )}
    </div>
  );
};

export default EditModuleComponent;
