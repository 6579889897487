import { Draggable } from "react-beautiful-dnd";
import sortImg from '../../../../../../assets/img/sortModule.png'
import lessonSortImg from '../../../../../../assets/img/sortLesson.png'

export const Drag = ({ id, index, ...props }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <div {...provided.dragHandleProps} style={props.style}>
            {props.type == "module" &&(
              <img src={sortImg} alt="Sort"  />
            )
            }
            
            {props.type == "lesson" &&(
              <img src={lessonSortImg} alt="Sort" style={{marginLeft:'20px',marginTop:'10px'}}  />
            )
            }
            
            </div>
            {props.children}
          </div>
        );
      }}
    </Draggable>
  );
};
