import React from "react";
import Text from "../text";
import './numberCard.scss';
import { BsThreeDots } from "react-icons/bs";
import { CiUser } from "react-icons/ci";

const NumberCard = props => {
    return(
        <div className="number-card shadow  bg-body rounded">
                  <div className="dashboard">
        <div class="d-flex align-items-center">
    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
      <CiUser />
    </div>
  </div>
        </div>



            <Text 
                type="H2" 
                text={props.number}
                className="number pt-2"
            />

        <Text 
            type="H5" 
            text={props.title}
          />

              <div class="dashboard">
<div class="filter">
  <a class="icon" href="#" data-bs-toggle="dropdown"><BsThreeDots /></a>
  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
    <li class="dropdown-header text-start text-white">
      <h6>Filter</h6>
    </li>

    <li><a class="dropdown-item" href="#">Today</a></li>
    <li><a class="dropdown-item" href="#">This Month</a></li>
    <li><a class="dropdown-item" href="#">This Year</a></li>
  </ul>
</div>

</div>
            <Text 
                type="PARAGRAPH" 
                text={props.text}
                className="title"
            />
        </div>
    )
}

export default NumberCard;