import React, {useEffect} from "react";
import useSubscribePremiumModal from "../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import LayoutPanel from "../../../components/layoutPanel";
import SubscribePremiumModal from "../../../components/subscribePremiumModal";
import FavouriteGuruView from "./view/FavouriteGuruView";
import Loader from "../../../components/loader";
import useFavouriteGuru from "./hooks/favouriteGuru.hook";
import {DEFAULT_FILTERS} from "../../../constants/app.constants";
import useGetTableList from "../../../hooks/getTableListCommonHook";
import FavouriteGuruSwiper from "./view/FavouriteGuruSwiper";

const FavouriteGuru = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const favouriteGuruHook = useFavouriteGuru()
    const commonHook = useGetTableList();

    useEffect(() => {
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'user_favoutitegurus';
        DEFAULT_FILTERS.category_id = 0;
        commonHook.getList(DEFAULT_FILTERS)
    }, [])

    if(favouriteGuruHook?.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader />
                </LayoutPanel>
            </>
        )
    }

    return (
        <>
            <LayoutPanel {...props}>

                {/*<SubscribePremiumModal  {...props}/>*/}
                <FavouriteGuruSwiper/>
              
            </LayoutPanel>
        </>
    )
}

export default FavouriteGuru;
