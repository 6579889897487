
import { useRef } from "react"


export const SUPER_ADMIN  = 9000
export const ADMIN = 9001
export const GURU = 8000
export const PREMIUM = 8001
export const PREMIUMPRO = 8004
export const FREEMIUM = 8002
export const TOPICS = 110
export const DEFAULT_COUNTRY_ID = 1
export const SECRETKEY = "eReZkIlAhKeY";
export const  socket = useRef; 

//export const URL = "http://localhost:6001/";
//export const SOCKET_URL = "http://localhost:6002";

export const URL = "https://staging.coursetsunami.com/";
export const SOCKET_URL = "https://staging-chat.coursetsunami.com/";


export const FREEMIUM_MINIMUM_VIDEO_PLAY_SECONDS = 60;
export const sixPageCount = 6;
export const HOME_VIDEOS_BANNER_ID = '2'
export const HOME_VIDEOS_ABOUT_REZKILAH_ID = '3'
export const HOME_VIDEOS_FINANCIAL_TOPICS_ID = '4'
export const HOME_VIDEOS_WHAT_PEOPLE_SAY_ABOUT_REZKILAH_ID = '5'
export const HOME_VIDEOS_OUR_EXCLUSIVE_GURUS_ID = '6'
export const HOME_VIDEOS_FEATURED_VIDEOS_BY_OUR_GURUS_ID = '7'
export const HOME_VIDEOS_KEY_OPINION_LEADER_ID = '8'

export const REDIRECT_TO_OTP = 1
export const REDIRECT_TO_SUBSCRIPTION = 2
export const REDIRECT_TO_PAYMENT = 3
export const REDIRECT_TO_SHARE_LINK = 4
export const REDIRECT_TO_SURVEY_1 = 5
export const REDIRECT_TO_SURVEY_2 = 6
export const REDIRECT_TO_SURVEY_3 = 7
export const REDIRECT_TO_PERSONAL_INFO = 8
export const REDIRECT_TO_ADDRESS_INFO = 9
export const REDIRECT_TO_LEARNER_BANK_DETAILS = 10
export const REDIRECT_TO_LEARNER_DASH_BOARD = 11



export const REDIRECT_TO_GURU_OTP = 101 // -- otp validation
export const REDIRECT_TO_GURU_PROFILE = 8000 //102 // -- guru profile
export const REDIRECT_TO_GURU_COMMUNICATION_DETAILS = 103 // -- communication details
export const REDIRECT_TO_GURU_WORK_EXPERIENCE = 8002 //104 // -- work experience
export const REDIRECT_TO_GURU_BANK_DETAILS = 105 // -- bank details
export const REDIRECT_TO_instructorUpload_ATTACHMENTS = 106 // -- upload attachments
export const REDIRECT_TO_GURU_DASHBOARD = 107 // -- Guru Dashboard

export const IMAGE_FILE_TYPE = {
    'image': ['.png', '.jpeg', '.jpg']
}

export const DOC_FILE_TYPE = {
    'image': ['.pdf', '.doc', '.docx']
}

export const VIDEO_FILE_TYPE = {
    'image': ['.mp4', '.avi', '.mov']
}


export const DEFAULT_PAGE_COUNT = 10

export var DEFAULT_FILTERS = {

}

export var DEFAULTFILTERS = {

}

export var TEMP_DEFAULT_FILTERS = {
    sortby: 'asc',
    fromdate:'',
    todate:'',
    page: 1,
    perpage: DEFAULT_PAGE_COUNT,
}

export var TEMP_DEFAULTFILTERS = {
    sortby: 'desc',
    fromdate:'',
    todate:'',
    page: 1,
    perpage: DEFAULT_PAGE_COUNT,
    uri:"",

}
