import { Accordion, AccordionContext, Card, useAccordionButton } from "react-bootstrap";
import LearnerCourseMainbar from '../learnerCourseMainbar';
import LineModule from '../lineModule';
import Text from '../text';
import { useContext } from 'react';
import Button from "../button";
import Flex from "../flex";
import viewImg from '../../assets/img/viewiconWhite.png'
import LearnerModuleMainbar from "../learnerModuleMainBar";

const AccordionCourse =(props)=> {
  
  const {moudleDetails,topicsDetails} = props
console.log("topicsDetails",topicsDetails);
  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = activeEventKey === eventKey;
  

    return (

      <Button
      
      text={
         <Flex> <img src={viewImg}  alt="copy" className='icon-32' />   <Text
         type="PARAGRAPH"
         className=" text-white my-0 pl-2"
         text="View Lessons "
     /></Flex>
      }
     
      onClick={decoratedOnClick}
      type="button"
      children={children}
  />
     
    );
  }

 

  return (

    <Accordion defaultActiveKey="0">
    {moudleDetails?.map(function (item, index) {
      console.log("item",item);
      return(
      <>

        <Card className="mt-3">
          <Card.Header className="p-0">
          <LearnerModuleMainbar {...props} data={item} viewButton={<ContextAwareToggle eventKey={"" + index} playVideo={props.playVideo}></ContextAwareToggle>}/>
          
          </Card.Header>
          <Accordion.Collapse eventKey={"" + index}>
            <Card.Body className="pb-0">

            {topicsDetails?.map(function (topic, topicIndex) {
              console.log(topic)
                if(topic.moduleid == item.srno){
                    return (
                    <LineModule {...topic}/>
                    )
                }
              })}

            </Card.Body>
          </Accordion.Collapse>
        </Card>
      
      
      </>
      )
       
     
    
    })}

    


     

  </Accordion>
  )

    






}

export default AccordionCourse;