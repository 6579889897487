import React, {useState} from 'react';
import Text from '../text';
import Button from '../button';
import { useNavigate, useParams } from 'react-router-dom';
import { SECRETKEY, SOCKET_URL } from '../../constants/app.constants';
import CryptoJS from "crypto-js";
import { io } from "socket.io-client";
import { useSelector } from 'react-redux';
const NotificationCard = props => {
    const navigate = useNavigate();

    const userInfo = useSelector(state => state.app.userInfo)

    const redirect_to = () => {

   // const path =   CryptoJS.AES.encrypt("123", SECRETKEY).toString()
   // console.log("path,decrypt",path);
      //  navigate('/instructor/chat-board/' + props.body);

        window.location.href = '/instructor/chat-board/' + props.body;

    }

    console.log("props.body",props.body);
 
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text="You have new chat request" className="dgsc-title mb-2"/>
            <div className='row'>

                <div className='col-md-6'>

                        <Button type="button" className="btn-primary-dashboard" text="Accept"    
                            onClick={() => {
                                redirect_to();
                                
                            }}
                        />

                </div>

                <div className='col-md-6'>
                <Button type="button" className="btn-primary-dashboard" text="Cancel" onClick={()=>{ 

                    const socket = io(SOCKET_URL);
                    socket.on("connection", () => {
                    console.log("connected to server");
                    });
                    socket.emit('setUsername', {uname:userInfo.uname,mongoId:userInfo.mongoId,pic:userInfo.pic,userId:userInfo.userid});
                    socket.emit('instructions', {type:"CancelledByInstructor",obj:{}});
                    

                 }}/>
                </div>


            </div>
            
          
            


        </div>
    );
};

export default NotificationCard;
