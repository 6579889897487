import React from 'react';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Icon from '../../../../../components/icon';
import EditIcon from '../../../../../components/svg/editIcon';
import TextReadonly from '../../../../../components/textReadonly'
import NavLearnerProfile from '../../../../../components/navLearnerProfile';

const BankDetailsView = (props) => {
    return (
        <>
            
                  
          <div className='change-password mt-5 px-5 py-3 rounded-2'>
          <div className="d-flex align-items-center mb-48">
                <Text type="H3" text="Bank Details" className="font-weight-500 mr-24 mb-0" />                
                <Button
                    linkPrimary
                    small
                    text="Edit"
                    iconLeft={<Icon small square icon={<EditIcon />} />}
                    onClick={props.goToEditBankDetails}
                    className="pl-5"
                />
            </div>
            <div className="row row-cols-5">
                <div className="col">
                    <TextReadonly
                        label="Bank Name"
                        text={props?.bankDetails?.bankname ? props?.bankDetails?.bankname : '-'}
                    />
                </div>
                <div className="col">
                    <TextReadonly
                        label="Account Holder"
                        text={props?.bankDetails?.payeename ? props?.bankDetails?.payeename : '-'}
                    />
                </div>
                <div className="col">
                    <TextReadonly
                        label="Account Number"
                        text={props?.bankDetails?.accountnumber ? props?.bankDetails?.accountnumber : '-'}
                    />
                </div>
            </div>

          </div>
            

      

        </>
    );
};

export default BankDetailsView;

