import { useState } from "react";
import { useNavigate } from "react-router-dom"
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import useHelper from "../../../../../hooks/helpers.hook";

const useMySubscription = () => {
    const navigate = useNavigate();
    const [isModal, setIsModal] = useState(false);
    const [reasons, setReasons] = useState(null)
    const [ reasonForUnsubscribe, setReasonForUnsubscribe] = useState();
    const [ modalComponent, setModalComponent] = useState('confirm');
    const {post} = useHttpHandler()
    const {getProfile} = useHelper()
    const toggleisModal = () => {
        setIsModal(state => !state);
    }
    const closeModal = () => {
        setModalComponent('confirm')
        setIsModal(false);
    }
    const goToProfile = () => {
        navigate('/trainee/settings/profile')
    }
    const goToSubscribe = () => {
        navigate('../../subscribe')
    }


    const getReasons = () => {
        post('get_reasons').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setReasons(data)
            } else {

            }
        }).catch(err=>{})
    }

    const updateReasons = (values, actions) => {
        let postData = {
            reason_id: values.reasonForUnsubscribe,
            reason: values.statetheReason
        }
        actions.setSubmitting(true)
        post('user_unsubscribe', postData).then((res) => {
            actions.setSubmitting(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setModalComponent('success')
                getProfile()
            } else {

            }
        }).catch(err=>{actions.setSubmitting(false)})
    }




    return {
        goToProfile,
        goToSubscribe,
        isModal,
        setIsModal,
        toggleisModal,
        reasonForUnsubscribe,
        setReasonForUnsubscribe,
        modalComponent,
        setModalComponent,
        closeModal,
        reasons,
        getReasons,
        updateReasons
    }

}
export default useMySubscription
