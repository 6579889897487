import React from 'react';

const SwiperPrevIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.41602 1.91675L2.12435 9.00008L9.41602 16.0834" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default SwiperPrevIcon;

SwiperPrevIcon.defaultProps = {
    width: '11',
    height: '18'
}