import React, {useEffect} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MySubscriptionView from "./view/MySubscriptionView";
import './mySubscription.scss'
import useHelper from "../../../../hooks/helpers.hook";
import useSubscribe from "../../subscribe/hooks/subscribe.hook";
import {useSelector} from "react-redux";
import Loader from "../../../../components/loader";

const MySubscription = props => {
    const userInfo = useSelector(state => state?.app?.userInfo)
    const {isPremiumMember} = useHelper()
    const isPremium = isPremiumMember(userInfo)
    const {text} =props
    const subscribeHook = useSubscribe()

    useEffect(()=>{
        
        if(isPremium) {
            subscribeHook.getMemberSubscriptionDetails()
        }
    },[])

    if (subscribeHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }

    return (
        <>
            <LayoutPanel {...props}>
                <MySubscriptionView {...text} {...props} isPremium={isPremium} {...subscribeHook} userInfo={userInfo}/>
            </LayoutPanel>
        </>
    )
}

export default MySubscription;
