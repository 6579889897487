import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseCard from "../../../../../components/courseCard";
import { useMediaQuery } from "react-responsive";
import useHelper from "../../../../../hooks/helpers.hook";
import Status from "../../../../../components/status";
import Breadcrumbs from "../../../../../components/breadcrumbs";
import Pagination from "../../../../../components/pagination";
import Table from "../../../../../components/table";
import Text from "../../../../../components/text";

import NoData from "../../../../../components/noData";
const EarningThisMonthPageView = (props) => {
  const { formatDateDDMMYY, getShortDescription } = useHelper();
  const { text, goToLearnerSignup, goToGuruSignup } = props;
  const { formatDateMMMDDYYYY, formatDateDDMMYYHms } = useHelper();
  const tableData = props?.tableData?.listData;
  const customData = props?.tableData?.customdata;

  const [isModal, setIsModal] = useState(false);

  const breadcrumbList = [
    {
      text: "Chat Dashboard",
      link: "/instructor/chat",
    },
    {
      text: "Earning this Month",
      link: "/instructor/chat-dashboard/earning-month",
    },
  ];

  return (
    <>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
      <div className="row " style={{ background: "#fff" }}>
        <Text className="mt-2" type="H1" text="Earning this month" />

        {tableData?.length === 0 && (
          <>
         <NoData/>
 </>
         
        )}

        {tableData?.length > 0 && (
          <>
            <Table
              thead={
                <tr>
                  <th role="columnheader">Date</th>
                  <th role="columnheader">User Name</th>
                  <th role="columnheader">Amount</th>
                  <th role="columnheader">Reference Id</th>
                </tr>
              }
              tbody={
                <>
                  {tableData?.map(function (item, index) {
                    return (
                      <tr role="row">
                        <td role="cell">
                          {formatDateDDMMYYHms(item.date_time)}
                        </td>
                        <td role="cell">{item.uname}</td>
                        <td role="cell">{"$" + item.amount}</td>
                        <td role="cell">{item.sessionID}</td>
                      </tr>
                    );
                  })}
                  {props?.tableData?.customdata?.total_amount > 0 && (
                    <tr>
                      <td colSpan="4" className="text-right text-white-half">
                        Total
                      </td>
                      <td className="font-weight-600 text-black">
                        RM {props?.tableData?.customdata?.total_amount}
                      </td>
                    </tr>
                  )}
                </>
              }
            />
            <Pagination
              totalPages={props?.tableData?.totalPages}
              currentPage={props?.tableData?.currentPage}
              totalRecords={props?.tableData?.totalRecords}
              nextPage={props.nextPage}
              previousPage={props.previousPage}
              perPage={props.filterKeys.perpage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EarningThisMonthPageView;

CourseCard.defaultProps = {
  videoListOption: [],
};
