import React from 'react';
import ProductImg from '../../assets/img/logo.svg';
import { FaUserAlt } from "react-icons/fa";
import '../../pages/newPages/instructorpages/instructor.css';

const ProductCard =(props)=>{
    return(
        <>
        <div className='card product-card instructor-card'>
            <img src={ProductImg} className='img-fluid product-image' alt='course-img' />
            <div className='card-body'>
                <h4 className='product-title'>{props.title}</h4>
                <p className='product-description'><span className='description-bg'><FaUserAlt size={'15px'} className="user-icon"/>0 Members</span></p>
            </div>
        </div>
        
        
        </>
    )
}


export default ProductCard;