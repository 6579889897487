import React from "react";
import Avatar from "../avatar";
import DP from '../../assets/images/dp.png';
import Text from "../text";
import { useState } from "react";
import './displayProfile.scss';
import Nav from "../nav";

const DisplayProfile = props => {
    const {userInfo} = props
    const [ dropdownDP, setDropdownDP] = useState(false);
    const ToggleDP = () => {
        setDropdownDP( state => !state);
    }

   
    const profileImage = userInfo?.pic ? process.env.REACT_APP_IMG_URL+userInfo?.pic : DP
    return(
        <>
            <div className="dp-wrapper"  >
                <div className="dp-heading" onClick={ToggleDP}   >
                    <Avatar
                        large
                        src={profileImage}
                        alt={userInfo?.fullname}
                    />
                    <div className="dp-details">
                        <Text type="H4" text={userInfo?.fullname} />
                        <Text type="PARAGRAPH" text={userInfo?.email} />
                    </div>
                </div>
                {dropdownDP &&
                    <div className="dp-dropdown">
                        <Nav
                            options={props.dpOption}
                         
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default DisplayProfile;
