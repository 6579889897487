import React,{useEffect, useState} from 'react'
import { DEFAULT_FILTERS, TEMP_DEFAULT_FILTERS } from '../../../constants/app.constants';
import Loader from '../../../components/loader';
import useGuruUploadVideo from '../uploadVideo/hooks/guruUploadVideo.hook';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import LayoutGuru from '../../../components/layoutGuru';
import TotalMinutesPageView from '../chat/dashboard/views/totalMinutesPageView';
import EarningThisMonthPageView from '../chat/dashboard/views/earningThisMonthPageView';
import TotalEarningPageView from '../chat/dashboard/views/totalEarningPageView';
import MyMeetingList from './views/MyMeetingList';
import VideoConferenceMeetingPage from '../../videoConference/VideoConferenceMeetingPage';


const VideoConference =(props)=>{
  const guruUploadVideoHook = useGuruUploadVideo();
  const commonHook = useGetTableList();
  
  useEffect(()=>{
      commonHook.setKeys();
      TEMP_DEFAULT_FILTERS.uri = 'getInstructorAllEvents';
      
      commonHook.getList(TEMP_DEFAULT_FILTERS)
      
  },[])



  if (commonHook?.loading) {
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
} else {
  return (
    <>
    <LayoutGuru {...props}>
    <VideoConferenceMeetingPage/>
        
    </LayoutGuru>
    </>
)
}
  
}

export default VideoConference;
