import React from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MagazineView from "./view/MagazineView";
import useGetTableList from "../../../../hooks/getTableListCommonHook";

const Magazine = props => {
    const commonHook = useGetTableList();
    return (
        <>
            <LayoutPanel {...props}>
                <MagazineView {...props} {...commonHook} />
            </LayoutPanel>
        </>
    )
}

export default Magazine;
