import React from "react";
import Flex from "../flex";
import Text from "../text";
import './planSummaryCard.scss'

const PlanSummaryCard = props => {
    const {text}=props
    return(
        <div className="plan-summary-card">
            <Flex justifyContent="between" className="plan-summary-title">
                <Text type="H5" text={text?.PLAN_SUMMARY} className="plan-summary-title-left" />
                <Text type="H5" text={text?.TOTAL} />
            </Flex>
            <Flex justifyContent="between" className="plan-summary-body">
                <Text type="PARAGRAPH" text={text?.PREMIUM_SUBSCRIPTION + ' x ' + text?.PER_MONTH} className="plan-summary-body-left" />
                <Text type="PARAGRAPH" text={'RM 99.00/' + text?.PER_MONTH} className="plan-summary-body-total" />
            </Flex>
        </div>
    )
}
export default PlanSummaryCard;
