import React, {useEffect} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import EditBankDetailsView from "./view/EditSettingsBankDetailsView";
import useBankDetails from './hooks/settingsBankDetails.hook';
import Loader from "../../../../components/loader";

const EditBankDetails = props => {
    const bankDetailsHook = useBankDetails()
    useEffect(()=>{
        if(!bankDetailsHook?.bankDetails)
            bankDetailsHook?.getBankDetails()
    },[])
    if (bankDetailsHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel {...props}>
                <EditBankDetailsView {...props} {...bankDetailsHook} />
            </LayoutPanel>
        </>
    )
}

export default EditBankDetails;
