import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useHttpHandler from "../../../hooks/httphandler.hook";
import {DEFAULT_FILTERS} from "../../../constants/app.constants";

const useLearnByTopics = (categoryId, categoryName) => {

    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [videosListLoading, setVideosListLoading] = useState(false)
    const [videosList, setVideosList] = useState([])
    const [videosApiData, setVideosApiData] = useState(null)
    const [categories, setCategories] = useState(null)

    const defaultFilters = {
        category_id: categoryId ? parseInt(categoryId) : 0,
        sub_category_id: 0,
        userid:"l",
        page: 1,
        perpage: 8,
    }

    const [filterKeys, setFilterKeys] = useState(defaultFilters)

    const getDropDowns = () => {
        setLoading(true)
        post('getCommonDropdownData').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                let topicOfInterest = data?.categories

                setCategories(topicOfInterest)

            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }

    const setFilters = (catId, page, subCatId = null) => {
        let keys = {
            category_id: catId,
            sub_category_id: subCatId,
            page: page,
        }
        console.log('setFilters', keys)
        setFilterKeys({...filterKeys, ...keys})
    }

    const getVideos = () => {


        setVideosListLoading(true)
        post('fetchInstructorCourseList', filterKeys).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideosApiData(data)
                let currentList = videosList
                if (filterKeys.page == 1) {
                    currentList = []
                }
                data?.listData?.map((item) => {
                    currentList.push(item)
                })
                setVideosList(currentList)

            } else {
                toast.error(status.msg)
            }

            setVideosListLoading(false)
        }).catch(err => setVideosListLoading(false))
    }

    useEffect(() => {
        getVideos()
    }, [filterKeys])

    return {
        getDropDowns,
        loading,
        categories,
        videosListLoading,
        getVideos,
        videosList,
        filterKeys,
        setFilterKeys,
        setFilters,
        videosApiData
    }

}
export default useLearnByTopics
