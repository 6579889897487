import React from "react";
import nodata from "../../assets/img/nodata.jpg";

const NoData = ({props, children}) => {
    return(
        <>
                     <div className="row justify-content-center">
          <div className="col-md-6 text-center ">
              <img src={nodata} className="img-fluid" />
            </div>
          </div>
        </>
    )
}

export default NoData;