import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CourseCard from '../../../../../components/courseCard'
import Text from "../../../../../components/text";
import Breadcrumbs from '../../../../../components/breadcrumbs';
import TrainerCategoryBar from '../../../../../components/trainerCategoryBar';
import GetInstructors from '../../../../../components/getInstructors';
import CreateMeeting from '../../../../../components/createMeeting/createMeeting';
import LearnerCheckMeeting from '../../../../../components/learnerCheckMeeting';
import Section from '../../../../../components/section';
import AvaliableMeeting from '../../../../../components/avaliableMeeting/avaliableMeeting';
import {Modal, Popconfirm,Result,Tag } from 'antd';


const ScheduleInstructorView = (props) => {
  
    const customData = props?.tableData?.customdata;
    const [instructorId,setInstructorId] = useState(0);
    const [isShowPopUP,setisShowPopUP] = useState(false);
    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Schedule Meeting",
            link:"/trainee/instructor/schedule-meetings",
        },
    ]


  

    

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#8081ff",
         
        }),
      
    };




  return (
    
    <>
    
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
    <div className='row'>
        <div className='col-md-9'>
      <Text
        type="H1"
        text="Schedule Meeting"
        className="mt-0 main_heading_color ml-3 mb-0"
    />
    </div>

  
    </div>  
       
        <div className="row top-main-boxes  course-main-box "  style={ {padding:'0px'}} >
    
        <TrainerCategoryBar
            course="Instructor"
            coursetitle="Antonio"
            module="No.of Courses"
            value={customData?.no_of_courses}
            availablevideos="Total Videos"
            valuevideo={customData?.no_of_videos}
            dropdownsData ={props?.dropdownsData}
            _props = {props}   
        />

        </div>        

            <div className="row top-main-transparent-boxes  course-main-box "  style={ {padding:'0px'}} >
                   <GetInstructors {...props} getCategories={props.getCategories} pagetype={props.pagetype} setisShowPopUP={setisShowPopUP} setInstructorId={setInstructorId}/>
            </div>


            {isShowPopUP  &&(
                    <>
                      <Modal
                      closable={false}
                      title=""
                      centered
                      open={isShowPopUP}
                      onOk={() => setisShowPopUP(false)}
                      onCancel={() => setisShowPopUP(false)}
                      cancelButtonProps={{ style: { display: 'none' } }}
                      okButtonProps={{ style: { display: 'none' } }}
                      width={window.innerWidth - window.innerWidth/4}
                      zIndex={10000}
                    >

                    <Section className="bg-white">
                      
                         <AvaliableMeeting serviceData={[]} setChangesDone={true} type={"meeting"} instructorId={instructorId} setisShowPopUP={setisShowPopUP} />
                    </Section>

                    </Modal>

                    </>
                    )}


    </>

  )
}

export default ScheduleInstructorView
    
CourseCard.defaultProps = {
    videoListOption: []
}
