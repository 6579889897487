import React from "react";
import './chooseUsCard.css';
import { BiReceipt,BiCubeAlt,BiImages } from "react-icons/bi";
import { AiOutlineRight } from "react-icons/ai";

const ChooseUsCard =()=>{
    return(
        <>
        
        <section id="why-us" class="why-us bg-sect-light-grey">
      <div class="container">

        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch">
            <div class="content">
              <h4 className="fw-bold fs-1 pb-3"> Why COURSE TSUNAMI?</h4>
              <p >
              Experience the power of Course Tsunami: unlock a vast ocean of knowledge, learn from renowned experts, earn financial rewards as a course creator, engage in interactive learning experiences, and connect with a vibrant community of learners. Take your learning journey to new heights and unleash your full potential with Course Tsunami.
              </p>
              <div >
                <a href="about.html" class="more-btn">Learn More <AiOutlineRight /></a>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-sm-12 d-flex align-items-stretch">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-xl-4 col-md-6 col-sm-12 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0 ">
                    <BiReceipt  className="choose-us"/>
                    <h4  >WATCH</h4>
                    <p className="home-page-title-bold-2 " > Immerse Yourself in Engaging Course Content</p>
                    <p  >Get ready to embark on a visual learning journey as you watch captivating videos and interactive course content that brings your education to life. Discover a new way to absorb knowledge and stay engaged throughout your learning experience with Course Tsunami.</p>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                    <BiCubeAlt className="choose-us"/>
                    <h4  >LEARN</h4>
                    <p className="home-page-title-bold-2 " >Expand Your Horizons with Lifelong Learning</p>
                    <p >With Course Tsunami, learning never stops. Explore a vast array of courses across various subjects and industries, curated by renowned experts. Embrace the opportunity to acquire new skills, deepen your knowledge, and unlock your true potential through continuous learning.</p>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 col-sm-12 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                    <BiImages className="choose-us"/>
                    <h4  >EARN</h4>
                    <p className="home-page-title-bold-2 ">Turn Knowledge into Financial Success</p>
                    <p  >Course Tsunami empowers you to monetize your expertise and earn financial rewards. As a course creator, your captivating content can generate recurring income for every minute watched. Additionally, as a learner, you have the chance to earn commissions by referring others to join the platform. Unlock the potential to learn and earn with Course Tsunami.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
        
        </>
    )
}

export default ChooseUsCard;