import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import SubscribeView from "./view/SubscribeView";
import useSubscribe from "./hooks/subscribe.hook";
import Loader from "../../../components/loader";
import LayoutPanel from "../../../components/layoutPanel";

const Subscribe = props => {
    const subscribeHook = useSubscribe()

  
   
    return (
        <>
            <LayoutPanel {...props} footer>
      <SubscribeView />
      </LayoutPanel> 
        </>
    )
}

export default Subscribe;
