import React, { useState } from "react";
import Section from "../../../../components/section";
import Text from "../../../../components/text";
import CourseImg from "../../../../assets/img/logo.svg";
import CourseImg1 from "../../../../assets/img/logo.svg";
import CourseImg2 from "../../../../assets/img/logo.svg";
import { HiOutlineUsers } from "react-icons/hi";
import { MdOutlinePriceChange } from "react-icons/md";
import Flex from "../../../../components/flex";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../../../../components/input/select";
import Pagination from "../../../../components/pagination";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Image from "../../../../components/image";

const SubCategeoryPurchaseView = (props) => {
  const tableData = props?.tableData?.listData;
  const formik = useFormik({
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter a valid study level"),
    }),
    onSubmit: (values, actions) => {},
  });

  const getFilterTopic = (e) => {
    props?._props.setcheckFilter(true);
    props?._props.setFilterId(e.target.value);
    formik.setFieldValue("category", e.target.value);
    getSubCategoriesById(e.target.value);
  };
  const [subCategories, setSubCategories] = useState(null);
  const getSubCategories = (e) => {
    formik.setFieldValue("category", e.target.value);
    getSubCategoriesById(e.target.value);
  };
  const getSubCategoriesById = (catId) => {
    let subCategoryOptions = [];
    if (dropdownsData?.subCategoriesOptions) {
      dropdownsData?.subCategoriesOptions.filter((item, index) => {
        if (item.parent_id == catId) {
          subCategoryOptions.push({
            value: item.subcatid,
            label: item.subCatName,
          });
        }
      });
    }
    setSubCategories(subCategoryOptions);
  };

  const { dropdownsData } = props?.guruUploadVideoHook;

  console.log(dropdownsData?.customData);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-3">
            <Link to={"/trainee/subscribe/purchase-categeory"}>
              {" "}
              <AiOutlineArrowLeft color="#8081ff" />{" "}
              <Button linkPrimary text="Back to Category" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 ">
            <div class="purchase_card_sub text-bg-light bg-col-white h-100">
              <div class="container py-2">
                <div class="row">
                  <div class="col-md-1 col-sm-12 pt-2">


                  <Image src={"ct/" +  dropdownsData?.customData.catPic} alt={dropdownsData?.customData.catPic} className={"img-fluid "} />

                   
                  </div>
                  <div class="col-md-11">
                    <Text
                      type="H4"
                      className="mb-1 "
                      text={dropdownsData?.customData.catName}
                    />
                    <Text
                      type="PARAGRAPH"
                      className="mb-0"
                      text={
                        dropdownsData?.customData.catDesc.substring(0, 120) +
                        "...."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div class="count_card">
              <div className="row">
              
               
                  <Flex justifyContent="end">
                    <Link
                      to={
                        "/trainee/subscribe/categeory-payment/" +
                        dropdownsData?.customData.catId
                      }
                    >
                      <button class="btn-primary btn-donate_1">
                        Purchase Now{" "}
                      </button>
                    </Link>
                  </Flex>
               
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-8">
            <Text type="H2" text="SubCategeory" className="mb-2 text-start" />
            <Text
              type="PARAGRAPH"
              text="please Purchase any one Categeory to enjoy Learning and Earn Affiliate Income."
              className="mb-3 text-start"
            />
          </div>
          <div className="col-md-4">
           
          </div>
        </div>

        <div className="row mt-3">
          {dropdownsData?.subCategories?.map(function (item, index) {
            return (
              <>
                <div className="col-md-4 mt-10">
                  <div class="card box-shadow-4">
                    <div class="card-img-top position-relative">

                    <Image src={"ct/" + item.image} alt={item.image} className={"img-fluid w-20"} />
                     
                    </div>
                    <div class="card-body align-items-center mt-4 px-4">
                      <h4 class="font-weight-bold text-dark text-4 mb-1">
                        {item.subCatName}
                      </h4>
                      <p className="text-lightdark">{item.description}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <Pagination
          totalPages={props?.tableData?.totalPages}
          currentPage={props?.tableData?.currentPage}
          totalRecords={props?.tableData?.totalRecords}
          nextPage={props?.nextPage}
          previousPage={props?.previousPage}
          perPage={props?.filterKeys?.perpage}
        />
      </div>
    </div>
  );
};

export default SubCategeoryPurchaseView;
