import React, {useState} from "react";
import './heroslider.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import HeroImage from '../../assets/img/logo.svg'
import Text from "../text";
import Button from "../button";
import {NavLink, useNavigate} from "react-router-dom";
import StaticVideoPlayer from "../videoPlayers/staticVideoPlayer";


const HeroSlider = props => {
    const navigate = useNavigate();
    const {text} = props
    const [currentVideoDetails, setCurrentVideoDetails] = useState(null)
    return (
        <>
            <div className="heroslider">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    modules={[Navigation]}
                    navigation
                    onSlideChange={(swiper) => {
                        console.log('onSlideChange', swiper.activeIndex);
                        setCurrentVideoDetails(props?.options[swiper.activeIndex])
                    }}
                    onSwiper={(swiper) => {
                        let activeIndex = swiper.activeIndex;
                        let activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                        let activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                        console.log('onSwiper', activeSlideVideo)
                        activeSlideVideo.play();
                    }}
                    onTransitionEnd={(swiper) => {
                        let activeIndex = swiper.activeIndex;
                        let activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                        let activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                        console.log('activeSlide', activeSlide)
                        activeSlideVideo.play();
                    }
                    }
                    onTransitionStart={() => {
                        let videos = document.querySelectorAll('video');
                        Array.prototype.forEach.call(videos, function (video) {
                            video.pause();
                        });
                    }}
                >
                    {props?.options?.map(function (d, idx) {
                        return (
                            <SwiperSlide key={idx}>
                                <div className="hero-item">
                                    <video
                                        controls
                                        autoPlay
                                        muted
                                        src={d?.videofilename}
                                        preload="auto"
                                    ></video>
                                   
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

            </div>
        </>
    )
}
export default HeroSlider;

HeroSlider.defaultProps = {
    options: [
        {
            text: "LEARN AND GET REWARDS",
            src: 'asdas',
            alt: "Hero Image"
        },
        
    ]
};
