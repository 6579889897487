import React from 'react';

const InvestmentIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1394 0.833496H5.85811C4.5177 0.840902 3.23513 1.38049 2.29257 2.33355C1.35001 3.28662 0.824663 4.57509 0.832109 5.9155V12.0848C0.824663 13.4252 1.35001 14.7137 2.29257 15.6668C3.23513 16.6198 4.5177 17.1594 5.85811 17.1668H12.1394C13.4799 17.1594 14.7624 16.6198 15.705 15.6668C16.6475 14.7137 17.1729 13.4252 17.1654 12.0848V5.9155C17.1729 4.57509 16.6475 3.28662 15.705 2.33355C14.7624 1.38049 13.4799 0.840902 12.1394 0.833496Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.79688 7.28418H13.7005V11.175" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.6992 7.28418L8.79924 12.3242C8.37011 12.7531 7.68385 12.7815 7.22074 12.3895L6.17074 11.497C5.92596 11.289 5.60625 11.1911 5.28698 11.2265C4.96771 11.2618 4.67713 11.4272 4.48374 11.6837C4.43824 11.7443 4.39391 11.8062 4.34257 11.8622L1.62891 14.8337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default InvestmentIcon;

InvestmentIcon.defaultProps = {
    width: '18',
    height: '18',
}