import React, {useEffect} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import PremiumProfileView from "./view/PremiumProfileView";
import './profile.scss';
import useProfile from "./hooks/profile.hook";
import {useSelector} from "react-redux";
import useHelper from "../../../../hooks/helpers.hook";
import FreemiumProfileView from "./view/FreemiumProfileView";
import Loader from "../../../../components/loader";

const Profile = props => {
    const userInfo = useSelector(state => state.app.userInfo)
    const {isFreemiumMember} = useHelper()
    const {text} = props
    const profileHook = useProfile()
    useEffect(() => {
        profileHook.getProfileDetails()
    }, [])

    if (profileHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    } else {
        if (isFreemiumMember()) {
            return (
                <>
                    <LayoutPanel {...props}>
                        <FreemiumProfileView {...text} {...profileHook} />
                    </LayoutPanel>
                </>
            )
        }
        return (
            <>
                <LayoutPanel {...props}>
                    <PremiumProfileView {...text} {...profileHook} />
                </LayoutPanel>
            </>
        )
    }
}

export default Profile;
