import React from "react";
import Button from "../button";
import Flex from "../flex";
import AkarArrowleftIcon from "../svg/akarArrowLeftIcon";
import Text from "../text";

const PasswordResetFailed = props => {
    return(
        <>
            <Text type="H2" text="Password Reset Failed!" className="mb-40 text-center" />
            <Text type="PARAGRAPH" text="Your password failed to reset. Click below to reset password again." className="col-8 mx-auto mb-40 text-center" />
            <Flex justifyContent="center" className="mb-40">
                <Button 
                    text="Reset Again"
                    large
                />
            </Flex>
            <Flex justifyContent="center">
                <Button 
                    linkLittle
                    text="Back to login"
                    iconLeft={<AkarArrowleftIcon />}
                />
            </Flex>
        </>
    )
}

export default PasswordResetFailed;