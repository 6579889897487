import { PREMIUM } from "../constants/app.constants";
import useHttpHandler from "./httphandler.hook";
import {useEffect, useState} from "react";

const useGetAllVideosWatched = () => {
    const [loading, setLoading] = useState(true)
    
    const {post} = useHttpHandler()

    const getAllVideosWatched = () => {
        setLoading(true)
        if(localStorage?.getItem("token") !== null){
            if(localStorage?.getItem("currentUserTypeId") == PREMIUM){
            console.log("localStorage.",localStorage.getItem("token"))
            post('get_all_videos_watched').then((res) => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
            }else{
                setLoading(false)
            }
            
        }
        //setLoading(false)
       
    }
    useEffect(()=>{
        getAllVideosWatched()
    },[])

    return {
        loading,
        getAllVideosWatched
    }

}
export default useGetAllVideosWatched


