import React from "react";
import CourseImg from '../../../../../assets/img/logo.svg';
import { BsPlayBtn } from "react-icons/bs";
import { BiChevronLeft } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import { FaPlay } from "react-icons/fa";
import '../../instructor.css';


const UploadPageView =()=>{
    return(
        <>
        <div className="page-bg pt-4 pb-5">
          <div className="container">
            <div className="row">
                <div className="col-md-6 fl">
                  <p className="top-subtext"><BiChevronLeft />Sara Course #1 Template</p>
                  <h4 className="font-weight-bold">Lesson 1</h4>
                  <p><AiFillEye />Preview</p>
                </div>
                <div className="col-md-6"> 
                    <button className="btn btn-start px-3 py-1 rounded-0 fr br-10">Save</button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                  <div className="card mb-3 instructor-card">
                     <div className="card-body text-center">
                        <p className="mb-4">Select what media you would like to attach to this post.</p>
                        <BsPlayBtn  size={'50px'} className="mb-1"/><br/>
                        <span className="text-vedio justify-content-center align-item-center">Video</span>
                        <button className="btn btn-start btn-move px-3 py-2 rounded-0 mt-5 mb-3">Select Video</button>
                     </div>
                  </div>
                  <div className="card p-3 instructor-card">
                   <h5 className="upload-title">Post Details</h5>
                   <div className="card-body">
                    <form>
                        <div className="form-group">
                            <label className="mb-2">Title</label>
                            <input type="text" name="title" id="title" className="form-control mb-3" placeholder="Lesson 1" required />
                        </div>
                        <div className="form-group">
                            <label className="mb-2">Categeory</label>
                            <select className="form-select">
                                <option>Module 1</option>
                            <option>Module 2</option>
                            <option>Module 3</option>
                            <option>Module 4</option>
                            </select>
                        </div>
                    </form>
                   </div>
                  </div>
                </div>
                <div className="col-md-4">
                   <div className="card mb-3 instructor-card">
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                            <label className="form-label font-weight-bold categeory-label">Visibility</label><br/>
                                <input type="radio" name="option" id="option" value="Published" /> Published<br/>
                                <input type="radio" name="option" id="option" value="Published" /> Draft 
                            </div>
                            <p className="product-description">The  categeory "Module 1" is set for Published</p>
                        </form>
                    </div>
                   </div>
                   <div className="card p-3 mb-3 instructor-card">
                      <h5>Post Thumbnail</h5>
                      <img src={CourseImg} className="img-fluid" alt="course-img" />
                      <FaPlay size={'45px'} className="upload-playicon"/>
                      <div className="card-body">
                        <p>This image  will displayed for categeory when your members view their library.</p>
                        <p>Recomended dimensions of 1280x720</p>
                        <div className="row">
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6">
                                <h6 className="categeory-remove-text">Remove</h6>
                            </div>
                        </div>
                      </div>
                     </div>
                     <div className="card p-4 instructor-card">
                        <h4>Files</h4>
                        <button className="btn-add-files">Add Files</button>
                     </div>
                </div>
            </div>
          </div>


        </div>
        
        </>
    )
}

export default UploadPageView;