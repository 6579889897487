import React, { useEffect, useState } from "react";
import VideoCard from "../videocard";
import './instructorCard.scss';
import TImage001 from '../../assets/img/logo.svg';
import Avatar from '../avatar'
import Text from "../text";
import useHelper from "../../hooks/helpers.hook";
import { NavLink, useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { AiFillLinkedin, AiOutlineHeart } from "react-icons/ai";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Button from "../button";
import useTimer from "./useTimer";

const ChatTimerCard = props => {
  

  const { timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset } = useTimer(0)
  const [timeminutes,setMinutes] = useState(0);
  useEffect(()=>{
    
      console.log("timeminutes-->",timeminutes)
    props.timerCallBack(); 
  
 },[timeminutes])

 useEffect(()=>{
  console.log("close")
  handlePause();
  
 },[props.timerPause])

  const formatTime = (timer) => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)
    console.log("timeminutes-->",getSeconds,minutes,getMinutes)
    if(getSeconds == "00"){
      if(timeminutes != minutes){
       
       setMinutes(minutes);
      }
    }
   
    return `${getHours} : ${getMinutes} : ${getSeconds}`
  } 



  useEffect(() => {
    console.log("props.startTime",props)
    if(props.props.startTime === 1){
      handleStart();
    }
  }, [props.props.startTime]);

  

  return (
    <div class="member">
      <h4>Conversion Time</h4>
      <div className='stopwatch-card'>
        <h4>{formatTime(timer)}</h4>
        {/*
        <div className='buttons'>
          {
            !isActive && !isPaused ?
              <button onClick={handleStart}>Start</button>
              : (
                isPaused ? <button onClick={handlePause}>Pause</button> :
                  <button onClick={handleResume}>Resume</button>
              )
          }
          <button onClick={handleReset} disabled={!isActive}>Reset</button>
        </div>*/}
      </div>
    </div>
  );
  

  
    
}

export default ChatTimerCard;
