import React, { useState } from "react";
import Text from "../text";
import NoVideoUploadedYet from '../noVideoUploadedYet';
import Image from '../image';
import './videoList.scss';
import Status from "../status";
import Icon from '../icon'
import EditIcon from '../svg/editIcon'
import Button from "../button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useHelper from "../../hooks/helpers.hook";
import Table from "../table";
import CourseCard from "../courseCard";
import Pagination from "../pagination";
import TextHtmlParser from "../htmlparser";
import StaticVideoPlayer from "../videoPlayers/staticVideoPlayer";
import Modal from "../modal";



const VideoList = props => {


    

    return(
        <>

               
        </>
    )
}

export default VideoList;

VideoList.defaultProps = {
    videoListOption: []
}
