import React, {useState} from 'react';
import Text from '../../../../../components/text';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import Pagination from '../../../../../components/pagination';
import Flex from '../../../../../components/flex';
import DropdownNew from '../../../../../components/dropdown/dropdownNew';
import Button from '../../../../../components/button';
import Table from '../../../../../components/table';
import useHelper from "../../../../../hooks/helpers.hook";
import { useFormik } from 'formik';
import Input from '../../../../../components/input';
import * as Yup from 'yup';

import {toast} from "react-toastify";
import Breadcrumbs from '../../../../../components/breadcrumbs';
const InstructorWalletView = (props) => {
    const {text} = props
    const {formatDateMMMDDYYYY} = useHelper()
    const tableData = props?.tableData?.listData

    const [yearSelected, setYearSelected] = useState('Year 2022');
    const [monthSelected, setMonthSelected] = useState('Select Month');

      
    const breadcrumbList = [
        {
            text: 'Chat Dashboard',
            link: '/instructor/chat',
        },
        {
            text:"Edit Chat Price",
            link:"/instructor/chat-dashboard/instructor-wallet",
        },
    ]

const formik = useFormik({
    initialValues: {
        amount:  '',
      
    },
    validationSchema: Yup.object({
        amount: Yup.string('Please enter a Amount'),
        
    }),
    onSubmit: (values, actions) => {
       
        actions.setSubmitting(true)
        values.uri = "instructorChangePrice";
        
      
        
        
        props.promiseService(values).then((data) => {

      
            
            if(data.data.status.result == 200){
                toast.success(data.data.status.msg);
                props?.setReloadPage(true);
               
            }else{
                toast.error(data.data.status.msg);
            }
          
        
        });
        


    },
});



   
    const yearsOption = [
        {
            value: 'Debit',
        },
        {
            value: 'Credit',
        },
       
    ];
    return (
        <>
  <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
           <Text
                type="H1"
                text="Edit Chat Price"
            />
            <div className='row  py-2' >
            <div className='col-md-4 ' >
                        <div className='row bg-color-primary p-2' >                            
                        
                        <Text  type="H4" text={"Present Chat Charge   : $" + props?.walletAmount } className="text-white mb-0 mr-10 mt-10 mb-10" />
                    
                            <div className="top-main-boxes m-0 course-main-box p-3 w-100"  style={ {padding:'0px'}} >
                            <form onSubmit={formik.handleSubmit}>
                            <div className="formBox-01">    

                            <Input
                            className="lineBottom"
                            formControlClassName="form-control"
                            label="Update Amount"
                            type="text"
                            name="amount"
                            placeholder="Amount"
                            autoFocus={true}
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={formik.touched.amount && formik.errors.amount ? (
                                formik.errors.amount
                            ) : null}
                        />
                            <Button
                                outline
                                type="submit"
                                text="Update"
                                disabled={formik.isSubmitting}
                                isSubmitting={formik.isSubmitting}
                            />

            </div>
            </form>

                            </div>  
                            


                        </div>
</div>
                        <div className='col-md-8'>
                            <div className='bg-col-white h-100 p-3'>
                            <Flex justifyContent="between" className="mb-24 flex-column flex-md-row">
                <div>
                    {props?.tableData?.customdata?.total_amount > 0 && (
                        <>
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-black font-weight-400"
                                text="Current Income"
                            />
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-white-half mt-2"
                                text={`RM ${props?.tableData?.customdata?.total_amount}`}
                            />
                        </>
                    )}

                </div>
           {/*
             <div className="filterby">
                    <Text type="PARAGRAPH" text="Filter by:" className="text-dark mb-0 mr-10"/>
                    <div className="filterby-lists">
                       
                        <DropdownNew
                            secondaryDropdown
                            right
                            selected={yearSelected}
                            setSelected={setYearSelected}
                            options={yearsOption}
                            placeholder="Filter By"
                        />
                        <Button
                            type="button"
                            text="Search"
                        />
                    </div>
                    </div> */}
            </Flex>

            <Table
                thead={
                    <tr>
                        <th>Srno</th>
                        <th>Amount</th>
                     
                        <th>Created On</th>
                        
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.amount}</td>
                                  
                                    <td>{formatDateMMMDDYYYY(item.createdon)}</td>
                        
                                </tr>
                            )
                        })}
                        {props?.tableData?.customdata?.total_amount > 0 && (
                            <tr>
                                <td colSpan="4" className="text-right text-white-half">Total</td>
                                <td className="font-weight-600 text-black">
                                    RM {props?.tableData?.customdata?.total_amount}</td>
                            </tr>
                        )}
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
                            </div>
                      
                        </div>


            </div>           

            {/*<NavLearnerMember/>*/}
          
        </>
    );
};

export default InstructorWalletView;

