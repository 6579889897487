import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useState} from "react";
import useHelper from "../../../../hooks/helpers.hook";

const usePersonalInformation = () => {
    const  {updateUserMetaData} = useHelper()
    const [loading, setLoading] = useState(true)
    const [dropdownsData, setDropdownsData] = useState(null)
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const goBackToSurveryQuestion3 = () =>{
        navigate('/trainee/survey-question-3')
    }
    const goToAddressInformation = () =>{
        navigate('/trainee/address-information')
    }
    const getDropDowns = ()=> {
        post('getCommonDropdownData').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                
                let occupationOptions = []
                
                data?.occupation?.map((item, index)=>{
                    occupationOptions.push({value:item.occupationid, label: item.occupation})
                })
                
                setDropdownsData({
                    occupationOptions,
                 })
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        }).catch(err => {setLoading(false);})
    }
    const updateProfile = (data)=> {
        post('user_profileentry', data).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                updateUserMetaData(data)
                goToAddressInformation()
            } else {
                toast.error(status.msg)
            }
        })
    }
    return {
        goBackToSurveryQuestion3,
        goToAddressInformation,
        getDropDowns,
        dropdownsData,
        loading,
        updateProfile
    }

}
export default usePersonalInformation


