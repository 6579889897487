import React from "react";
import './courseTable.scss';

const Table = props => {
    return(
        <>
            <div className="table-responsive mt-10 ml-10px"   >
                <table>
                    <thead >
                        {props.thead}
                    </thead>
                    <tbody role="rowgroup">
                        {props.tbody}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Table; 