import React from "react";
import TableFormatPageView from "./views/tableFormatPageView";



const TableFormatPage =()=>{
    return(
        <>
        <TableFormatPageView />
        
        </>
    )
}


export default TableFormatPage;