import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LayoutPanel from "../../../../components/layoutPanel";
import Loader from "../../../../components/loader";
import useVideosCommonHooks from "../../../instructor/createCourse/videosCommonHook";
import WalletPaymentSuccessView from "../views/walletPaymentSuccessView";



const WalletPaymentSuccess = props => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const commonHook =  useVideosCommonHooks();
    const {sessionId} = useParams();
    //const sessionId = new URLSearchParams(window.location.search).get('session_id');
    console.log("sessionId",sessionId)
  
    useEffect(() => {
      if (sessionId) {
        fetch(process.env.REACT_APP_API_URL + `/retrieve-session/${sessionId}`)
          .then(res => res.json())
          .then(data => {});
      }
    }, [sessionId]);
    


    if(commonHook.loading ) {
        return <Loader/>
    }else{
            return (
            <>
                <LayoutPanel {...props}>
                <WalletPaymentSuccessView {...props}  />
                </LayoutPanel>
            </>
            )

    }
    
}

export default WalletPaymentSuccess;
