import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useHelper from "../../../../hooks/helpers.hook";

const useChatHook = () => {
    const {post} = useHttpHandler()
    const [getchatDashboardData,setchatDashboardData] = useState({});
    const [getMessage,setMessages] = useState("");
    const [socket,setSocket] = useState(undefined);
    const [roomName,setRoomName] = useState("");
    const [pageNum,setPageNum] = useState(1);
    const [appendMessages,setAppendMessages] = useState([]);
    const [userListData,setUserListData] = useState([]);
    const [checkOnlineUser,setOnlineUsers] = useState(0);
    const [headingMessage,setHeadingMessage] = useState({});
    const [tempRoomName,setTempRoomName] = useState("");
    const [isModal,setIsModal] = useState(false);
    const [sessionId,setSessionId] = useState("");
    const [startTime,setStartTIme] = useState(0);
    const [timerPause, setTimerPause] = useState(true);
    const [messageCount,setMessageCount] = useState(0);
    return {
       
        getchatDashboardData,
        setchatDashboardData,
        setMessages,
        getMessage, 
        socket,
        setSocket,
        roomName,
        setRoomName,
        pageNum,
        setPageNum,
        appendMessages,
        setAppendMessages,
        userListData,
        setUserListData,
        setOnlineUsers,
        checkOnlineUser,
        headingMessage,setHeadingMessage,
        tempRoomName,setTempRoomName,
        isModal,setIsModal,
        startTime,setStartTIme,
        sessionId,setSessionId,
        timerPause,setTimerPause,
        messageCount,setMessageCount
    }

}
export default useChatHook


