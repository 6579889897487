import React, { useEffect, useState } from 'react'
import CourseCard from '../../../../components/courseCard'
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import TrainerCategoryBar from '../../../../components/trainerCategoryBar';
import GetInstructors from '../../../../components/getInstructors';


const ChatWithInstructorView = (props) => {
  
    const customData = props?.tableData?.customdata;
  
    const breadcrumbList = [
      /*  {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Chat With Instructor",
            link:"/trainee/chat-with-instructor",
        },*/
    ]

    

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#8081ff",
         
        }),
      
    };




  return (
    
    <>
     <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
      
      <Text
        type="H1"
        text="Chat With Instructor"
        className="mt-0 main_heading_color  mb-0"
      />
    
       
        <div className="row top-main-boxes  course-main-box mt-2" >
    
        <TrainerCategoryBar
            course="Instructor"
            coursetitle="Antonio"
            module="No.of Courses"
            value={customData?.no_of_courses}
            availablevideos="Total Videos"
            valuevideo={customData?.no_of_videos}
            dropdownsData ={props?.dropdownsData}
            _props = {props}   
        />

        </div>        

            <div className="top-main-transparent-boxes course-main-box">                
                   <GetInstructors {...props} getCategories={props.getCategories} pagetype={1} />    
            </div>


    </>
  )
}

export default ChatWithInstructorView
    
CourseCard.defaultProps = {
    videoListOption: []
}
