import React from "react";
import './navbarGuru.scss'
import DashboardIcon from '../svg/dashboardIcon'
import Nav from '../nav'
import LockIcon from "../svg/lockIcon";
import WalletIcon from "../svg/walletIcon";
import IDCardIcon from "../svg/idCardIcon";
import BoxCheckIcon from "../svg/boxCheckIcon";
import ReportIcon from "../svg/reportIcon";
import SmartDisplayIcon from "../svg/smartDisplayIcon";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "../svg/menuIcon";
import Button from "../button";
import { GrDocumentVideo } from "react-icons/gr";
import { SiDiscourse } from "react-icons/si";

const Navbar = props => {
    const {text} = props
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 })
    return (
        <>
            <nav className={`navbar-guru ${isTablet ? props.sideMenu ? 'navbar-side-expand' : 'navbar-side-minimize' : ''}`}>
                {isTablet && (
                    <Button
                        text={<MenuIcon />}
                        link
                        className="nav-side-toggle"
                        onClick={props.SideMenuToggle}
                    />
                )}
                <Nav
                    options={[
                       /* {
                            text:"Profile",
                            link:'/instructor/settings/profile',
                            icon:<SiDiscourse />,
                        },*/

                        {
                            text:"Dashboard",
                            link:'/instructor/dashboard',
                            icon:<SiDiscourse />,
                        },
                        {
                            text:"Courses",
                            link:'/instructor/course',
                            icon:<SiDiscourse />,
                        },
                       
                        {
                            text:"My Commission",
                            link:'/instructor/monetization/my-commission',
                            icon:<SmartDisplayIcon />,
                        },

                        {
                            text:"Chat",
                            link:'/instructor/chat',
                            icon:<SmartDisplayIcon />,
                        },

                        {
                            text:"Meeting",
                            link:'/instructor/meetings',
                            icon:<SmartDisplayIcon />,
                        },

                      

                        {
                            text:"Logout",
                            link:'/logout',
                            icon:<SmartDisplayIcon />,
                        },
                        
                    ]}
                />
                {/*
                <hr className="solid-hr"/>
                <Nav
                    title={isTablet ? '' : 'INSTRUCTOR  VIDEO'}
                    options={[

                        {
                            text:"Profile",
                            link:'/instructor/settings/profile',
                            icon:<SiDiscourse />,
                        },

                        {
                            text:"Dashboard",
                            link:'/instructor/dashboard',
                            icon:<SiDiscourse />,
                        },
                        {
                            text:"Courses",
                            link:'/instructor/course',
                            icon:<SiDiscourse />,
                        },
                       
                        {
                            text:"My Commission",
                            link:'/instructor/monetization/my-commission',
                            icon:<SmartDisplayIcon />,
                        },

                        {
                            text:"Chat",
                            link:'/instructor/chat',
                            icon:<SmartDisplayIcon />,
                        },

                        {
                            text:"Meeting",
                            link:'/instructor/meetings',
                            icon:<SmartDisplayIcon />,
                        },

                      

                        {
                            text:"Logout",
                            link:'/logout',
                            icon:<SmartDisplayIcon />,
                        },
                        
                        
                    ]}
                /> */}
               
            </nav>
        </>
    )
}

export default Navbar;
