import React from 'react';
import Flex from '../../../../components/flex';
import SearchGuruCard from '../../../../components/searchGuruCard';
import Text from '../../../../components/text';
import SearchGuruImage from '../../../../assets/img/logo.svg';
import Pagination from '../../../../components/pagination';
import Breadcrumbs from '../../../../components/breadcrumbs';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";
import FavGuruImage from "../../../../assets/img/logo.svg";

const breadcrumbList = [
    {
        text: 'Main page',
        link: '/trainee/dashboard',
    },
    {
        text: 'Guru',
        link: '/trainee/search-guru',
    },
    {
        text: 'Budgeting',
        link: '/trainee/search-guru',
    },
]

const  SearchGuruView = (props) => {
    const {getShortDescription} = useHelper()
    const tableData = props?.tableData?.listData
    const navigate = useNavigate()
    const {text} =props

    if(tableData?.length > 0) {
        return (
            <>
                {/*<Breadcrumbs
                breadcrumbList={breadcrumbList}
            />*/}
                <Text
                    type="H2"
                    className="mb-0 mt-4"
                    text={text?.SEARCH_RESULT_FOR + ' ' + props.searchKeyword}
                />
                <Text
                    type="PARAGRAPH"
                    className="text-white-half mb-4 mb-md-5"
                    text={`${tableData?.length} GuruS has been selected based on the keyword used.`}
                />
                <Flex justifyContent="start" alignItemsEnd className="mb-5">
                    <Text
                        type="H3"
                        className="mb-0 mr-2"
                        text="GURU"
                    />
                    <Text
                        type="H4"
                        className="text-primary mb-0"
                        text={`${tableData?.length} guru found`}
                    />
                </Flex>
                <div className="row">
                    {tableData?.map(function(item, index){
                        const topicImage = item?.pic ? process.env.REACT_APP_IMG_URL + item?.pic : SearchGuruImage
                        return(
                            <div className="col-6 col-md-6">
                                <SearchGuruCard
                                    src={topicImage}
                                    name={item?.preffered_name ? item?.preffered_name : item?.fullname}
                                    subtitle="About"
                                    description={getShortDescription(item?.about_yourself)}
                                    videosCount={item?.uploaded_videos}
                                    preferredTopic={item?.preferred_topic}
                                    experience={item?.years_of_exp}
                                    onClick={()=>{
                                        navigate('/trainee/favourite-instructor/favourite-instructor-details/all-videos/'+item?.userid)
                                    }}
                                />
                            </div>
                        )

                    })}



                </div>

                <Pagination
                    totalPages={props?.tableData?.totalPages}
                    currentPage={props?.tableData?.currentPage}
                    totalRecords={props?.tableData?.totalRecords}
                    nextPage={props?.nextPage}
                    previousPage={props?.previousPage}
                    perPage={props?.filterKeys?.perpage}
                />
            </>
        );
    } else {
        return (
            <>
                <div className="search-no-found">
                    <Text type="H2" className="title" text={text?.SEARCH_NOT_FOUND} />
                    <Text type="H3" text={text?.YOUR_SEARCH_DID_NOT_MATCH_ANY_RESULTS} />
                </div>
            </>
        )
    }


};

export default SearchGuruView;

