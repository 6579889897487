import React, { useEffect, useState } from 'react';
import "./avatar.scss"
import useVideosCommonHooks from '../../pages/instructor/createCourse/videosCommonHook';

const Avatar = props => {


    const commonHook = useVideosCommonHooks();
    

    const [imageUrl, setImageUrl] = useState('');
    

    useEffect(() => {
        console.log("props.image",props)
      // Fetch pre-signed URL from the backend
      commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':props.src}).then((data) => {
        //console.log("data.data",data.data)
        console.log("data.data",data.data)
        setImageUrl(data.data)
     
       
    });
    }, []);
  
    



    return (
        <div
            className={`avatar ${props.w100 ? 'avatar-100' : props.large ? 'avatar-48' : 'avatar-40'}`}
            style={{maxWidth: props?.width + 'px' , maxHeight: props?.height  + 'px'}}
        >

<img 
                src={imageUrl} 
                alt={props.alt} 
              
            />
              
         
        </div>
    );
};



export default Avatar;

Avatar.defaultProps = {
    alt: "Avatar",
}
