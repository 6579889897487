import React, { useEffect } from 'react'
import LayoutGuru from '../../../components/layoutGuru'
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import Loader from "../../../components/loader";
import EditCourse from './views/editCourse';
import { useParams } from 'react-router-dom';


const EditCoursePage = (props) => {
    const {courseId} = useParams(); 
    const guruUploadVideoHook = useGuruUploadVideo()
    useEffect(()=>{

        if(guruUploadVideoHook.checkLevel){
            console.log("checkLevel");
            guruUploadVideoHook.getDropDowns().then(()=>{
                if(guruUploadVideoHook.serviceData == null){
                    console.log("commonHook.serviceData")
                    guruUploadVideoHook.setLevel(false);
                    guruUploadVideoHook.getCustomData({"uri":"instructorCourseDetails",'courseid':courseId});       
                }
            })
        }
    },[guruUploadVideoHook.checkLevel])
  
    if(guruUploadVideoHook.serviceData == null){
        return (
            <>
                <LayoutGuru {...props} noBgContent="true">
                    <Loader />
                </LayoutGuru>
            </>
        )
    }else{

 
        return (
            <div>
        
            <LayoutGuru {...props}>
                    <EditCourse {...props} guruUploadVideoHook={guruUploadVideoHook}  courseId={courseId} />
            </LayoutGuru>
        
            </div>
        )
    }
}

export default EditCoursePage
