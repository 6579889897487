import React from 'react';

const UserTwoIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18214 4.733C9.3222 6.63878 7.904 8.303 6.00014 8.467C4.09627 8.303 2.67807 6.63878 2.81814 4.733C2.67865 2.8276 4.09669 1.16401 6.00014 1C7.90358 1.16401 9.32163 2.8276 9.18214 4.733V4.733Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.48601 9.3999C-1.02499 10.5619 2.31401 14.9999 6.00001 14.9999C9.68601 14.9999 13.025 10.5619 9.51401 9.3999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>        
    );
};

export default UserTwoIcon;

UserTwoIcon.defaultProps = {
    width: '12',
    height: '16',
}