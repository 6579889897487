import React, { useEffect, useState } from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import useCourseDropDown from './hook/courseDropDown.hook';
import CoursePageView from './views/coursePageView'
import { useParams } from 'react-router-dom';

const LearnByTopicsPage = (props) => {
  const commonHook = useGetTableList();
  const [getCategoryId, setCategoryId] = useState(0);
  const [displayType,setDisplayType] = useState("list")
  const {catId,subCatId} = useParams();

  const guruUploadVideoHook = useCourseDropDown();
  var coursetype = -1;
  

  useEffect(()=>{
    commonHook.setLoading(true)
    guruUploadVideoHook.getDropDowns()
  },[])

  useEffect(() => {
    if (guruUploadVideoHook.checkCategory) {
      //commonHook.setCategoryId(commonHook.getCategoryId)
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "l";
      DEFAULT_FILTERS.coursetype = coursetype;
      DEFAULT_FILTERS.category_id = catId;
      DEFAULT_FILTERS.subcategory_id = subCatId;
      DEFAULT_FILTERS.sortkey = "asc";
      DEFAULT_FILTERS.fromdate = "";
      DEFAULT_FILTERS.todate = "";
      DEFAULT_FILTERS.page = 1;
      DEFAULT_FILTERS.perpage = 10;
     commonHook.getList(DEFAULT_FILTERS)
      console.log("Helll",commonHook.checkCategory,commonHook.getCategoryId,"--> sub",commonHook.getSubCategoryId);
    } 
  }, [guruUploadVideoHook.checkCategory]);

  if(commonHook.loading || commonHook.loading) {
    return <Loader/>
  }else{
          return (
            <div>
          
                <LayoutPanel {...props}  footer>
              <CoursePageView {...props} {...guruUploadVideoHook} commonHook={commonHook} setCategoryId={commonHook.setCategoryId} setSubCategoryId={commonHook.setSubCategoryId} coursetype={coursetype} setDisplayType={setDisplayType} displayType={displayType}/>
              </LayoutPanel>
            </div>
          )
  }

}

export default LearnByTopicsPage
