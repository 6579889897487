import React from 'react';

const RedeemIcon = props => {
    return (
        <>
            <svg width={props.width} height={props.height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 15.075V17.5H18.5V15.075H1.5ZM1.5 4H4.625C4.54167 3.85 4.475 3.6625 4.425 3.4375C4.375 3.2125 4.35 3.00833 4.35 2.825C4.35 2.04167 4.625 1.375 5.175 0.825C5.725 0.275 6.38333 0 7.15 0C7.66667 0 8.14167 0.129167 8.575 0.3875C9.00833 0.645833 9.35 0.975 9.6 1.375L10.025 2.025L10.425 1.375C10.6917 0.958333 11.0333 0.625 11.45 0.375C11.8667 0.125 12.3167 0 12.8 0C13.6 0 14.275 0.270833 14.825 0.8125C15.375 1.35417 15.65 2.025 15.65 2.825C15.65 3.00833 15.6292 3.19167 15.5875 3.375C15.5458 3.55833 15.4833 3.76667 15.4 4H18.5C18.9 4 19.25 4.15 19.55 4.45C19.85 4.75 20 5.1 20 5.5V17.5C20 17.9 19.85 18.25 19.55 18.55C19.25 18.85 18.9 19 18.5 19H1.5C1.1 19 0.75 18.85 0.45 18.55C0.15 18.25 0 17.9 0 17.5V5.5C0 5.1 0.15 4.75 0.45 4.45C0.75 4.15 1.1 4 1.5 4ZM1.5 12.4H18.5V5.5H12.275L14.775 8.975L13.575 9.875L10 4.925L6.425 9.875L5.225 8.975L7.725 5.5H1.5V12.4ZM7.175 4.15C7.54167 4.15 7.85417 4.02083 8.1125 3.7625C8.37083 3.50417 8.5 3.19167 8.5 2.825C8.5 2.45833 8.37083 2.14583 8.1125 1.8875C7.85417 1.62917 7.54167 1.5 7.175 1.5C6.80833 1.5 6.49583 1.62917 6.2375 1.8875C5.97917 2.14583 5.85 2.45833 5.85 2.825C5.85 3.19167 5.97917 3.50417 6.2375 3.7625C6.49583 4.02083 6.80833 4.15 7.175 4.15ZM12.8 4.15C13.1833 4.15 13.5042 4.02083 13.7625 3.7625C14.0208 3.50417 14.15 3.19167 14.15 2.825C14.15 2.45833 14.0208 2.14583 13.7625 1.8875C13.5042 1.62917 13.1833 1.5 12.8 1.5C12.45 1.5 12.1458 1.62917 11.8875 1.8875C11.6292 2.14583 11.5 2.45833 11.5 2.825C11.5 3.19167 11.6292 3.50417 11.8875 3.7625C12.1458 4.02083 12.45 4.15 12.8 4.15Z" fill="currentColor"/>
            </svg>
        </>
    );
};

export default RedeemIcon;

RedeemIcon.defaultProps = {
    width: '20',
    height: '19'
}