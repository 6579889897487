import React, { useEffect } from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import ModulesPageView from './views/modulesPageView';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGuruUploadVideo from '../uploadVideo/hooks/guruUploadVideo.hook';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/loader';

const Modulespage = (props) => {
 
  const guruUploadVideoHook = useGuruUploadVideo()
  const {courseId} = useParams(); 
  const commonHook = useGetTableList();
  useEffect(()=>{
    console.log("first")
    commonHook.setKeys();
    DEFAULT_FILTERS.uri = 'guru_course_module_list';
    DEFAULT_FILTERS.courseid = courseId;
    DEFAULT_FILTERS.levelid = commonHook.getlevelId;
    commonHook.getList(DEFAULT_FILTERS)
    guruUploadVideoHook.getDropDowns()
  },[])


  useEffect(() => {
    if (commonHook.checkLevel) {
      commonHook.setLevel(false);  
      DEFAULT_FILTERS.uri = 'guru_course_module_list';
      DEFAULT_FILTERS.courseid = courseId;
      DEFAULT_FILTERS.levelid = commonHook.getlevelId;
      commonHook.getList(DEFAULT_FILTERS)
      
    } 
  }, [commonHook.checkLevel]);
  

if (commonHook?.loading) {
  return (
      <>
         <LayoutGuru {...props}>
          <Loader />
          </LayoutGuru>
      </>
  )
} else {

  return (
    <div>
        <LayoutGuru {...props}>
      <ModulesPageView {...props} {...guruUploadVideoHook} {...commonHook}/>
      </LayoutGuru>
    </div>
  )
}
}

export default Modulespage;
