import React, {useEffect} from "react";
import useSubscribePremiumModal from "../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import LayoutPanel from "../../../components/layoutPanel";
import SubscribePremiumModal from "../../../components/subscribePremiumModal";
import LikedVideoView from "./view/LikedVideoView";
import useLikedVideo from "./hooks/likedVideo.hook";
import Loader from "../../../components/loader";
import GlobalInfoModal from "../../../components/globalInfoModal";
import useGuruUploadVideo from "../../instructor/uploadVideo/hooks/guruUploadVideo.hook";
import useVideosCommonHooks from "../../instructor/createCourse/videosCommonHook";
import { DEFAULTFILTERS } from "../../../constants/app.constants";

const LikedVideo = props => {
  

    const commonHook = useVideosCommonHooks();
    useEffect(() => {

        commonHook?.getDropDowns().then(()=>{
         
      
          })

        
      
       
    },[])


    useEffect(() => {

   
            commonHook.setkeys();
            DEFAULTFILTERS.uri = 'user_liked_videos';
            DEFAULTFILTERS.perpage = 6;
            commonHook.getList(DEFAULTFILTERS)
            
       
    },[commonHook?.dropdownsData?.categoriesOptions])


 
    if (commonHook?.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel {...props}>
                <LikedVideoView {...props}  commonHook={commonHook}/>
            </LayoutPanel>
        </>
    )
}

export default LikedVideo;
