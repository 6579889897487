import React from 'react';
import Layout from '../components/layout';

const About = () => {
    return (
        <>
            <Layout>
                <h2>About</h2>
            </Layout>
        </>
    );
};

export default About;
