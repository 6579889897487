import React from "react";
import './favouriteGuruUserInfo.scss';
import Avatar from "../avatar";
import Flex from "../flex";
import Text from "../text";
import StarIcon from '../svg/starIcon'
import Icon from "../icon";

const FavouriteGuruUserInfo = props => {
    return(
        <div className="fav-guru-user-detail-card rml-24">
            <Flex justifyContent="between">
                <Flex justifyContent="between" alignItemsCenter>
                    <Avatar
                        w100
                        src={props.avatar}
                        alt={props.fullname}
                        width="100"
                        height="100"
                    />
                    <div className="ml-4">
                        <Text 
                            type="H3" 
                            className="font-weight-700 mb-0" 
                            text={props.fullname} 
                        />
                        <Text 
                            type="PARAGRAPH" 
                            className="font-size-12 text-white-half mb-0" 
                            text="Expertise" 
                        />
                        <Text 
                            type="PARAGRAPH" 
                            className="text-white-half font-weight-600 mb-0"
                            text={props.category} 
                        />
                    </div>
                </Flex>
                <Flex justifyContent="end" flexColumn>
                    <Flex justifyContent="end" className="mb-4">
                        <Flex justifyContent="center" flexColumn className="text-center">
                            <Text 
                                type="H2" 
                                className="mb-10" 
                                text={props.totalFavourited} 
                            />
                            <Text 
                                type="SPAN" 
                                className="mb-0 font-size-12 text-white-half" 
                                text="Total Favourited" 
                            />
                        </Flex>
                        <Flex justifyContent="center" flexColumn className="text-center border-left-1-32">
                            <Text 
                                type="H2" 
                                className="mb-10" 
                                text={props.totalVideos} 
                            />
                            <Text 
                                type="SPAN" 
                                className="mb-0 font-size-12 text-white-half" 
                                text="Total Videos"
                            />
                        </Flex>
                    </Flex>
                    <Flex justifyContent="end">
                        <Text 
                            type="PARAGRAPH" 
                            className="mb-0 text-white-half mr-2" 
                            text="Added to favourites" 
                        />
                        <Icon 
                            small 
                            square 
                            icon={<StarIcon width="14" height="14" fill />} 
                        />
                    </Flex>
                </Flex>                
            </Flex>
        </div>
    )
}

export default FavouriteGuruUserInfo;