import React,{useEffect, useState} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import CourseContentPageView from './views/courseDetailsPageView';
import useHome from '../../Home/hooks/home.hook';
import Loader from '../../../components/loader';
import useGuruDashboard from '../dashboard/hooks/guruDashboard.hook';



const CourseContentPage =(props)=>{
  const guruUploadVideoHook = useGuruUploadVideo()
  const commonHook = useGetTableList();
  const guruDashboardHook = useGuruDashboard();
  useEffect(()=>{
    guruUploadVideoHook.getDropDowns();
      commonHook.setKeys();
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "g";
      DEFAULT_FILTERS.coursetype = -1;
      commonHook.getList(DEFAULT_FILTERS)
     
  },[])

  
  useEffect(() => {
    if (commonHook.checkCategory) {
    //
      commonHook.setCheckCategory(false);  
      commonHook.setCategoryId(commonHook.getCategoryId)
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "g";
      DEFAULT_FILTERS.coursetype = -1;
      DEFAULT_FILTERS.category_id = commonHook.getCategoryId;
      DEFAULT_FILTERS.subcategory_id = commonHook.getSubCategoryId;
      commonHook.getList(DEFAULT_FILTERS)
      console.log("----->",commonHook.checkCategory,commonHook.getCategoryId,"--> sub",commonHook.getSubCategoryId);
    } 
  }, [commonHook.checkCategory]);
 


  if (commonHook?.loading) {
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
} else {
  return (
    <>
    <LayoutGuru {...props}>
    <CourseContentPageView  {...props}  {...commonHook} dropdownsData={guruUploadVideoHook.dropdownsData} guruDashboardHook={guruDashboardHook} />
    </LayoutGuru>
    </>
)
}
  
}

export default CourseContentPage;
