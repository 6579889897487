import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import Button from '../../components/button'
import { NavLink, useNavigate } from "react-router-dom";
import ViewContent from "../viewContent";
import ProgressBarCard from "../programBarCard";
import TextHtmlParser from "../htmlparser";
import Text from "../text";
import Image from "../image";
import Icon from "../icon";
import PlayIcon from "../svg/playIcon";
import useHelper from "../../hooks/helpers.hook";
import ModuleImage from '../../assets/img/logo.svg';
import { BsArrowRight } from "react-icons/bs";



const CourseCard =(props)=>{
	const [showDescription, setShowDescription] = useState(!props.hideDescription)
    const {secondsToMinutes, toSeconds} = useHelper()
	const navigate = useNavigate();
	const duration = secondsToMinutes(props.duration)
    const watchedSeconds = toSeconds(props?.watchedupto)
    const barPercentage = props.duration ? (watchedSeconds / props.duration) * 100 : 0
	const {homeVideos} = props
  
    return(
        <>
	<div class="container mb-4">
	<div class="row d-flex flex-wrap bg-light custom-instructor-details_1">
		<div class="position-relative col-12 col-md-3 lazyloaded">
		<div className="video-card">
		<div className="video-poster">

		<img
	src={process.env.REACT_APP_IMG_URL + props.poster}
	alt={props.alt}
	className="guru_course_img"
/>
	
{!props.noPlay &&
<div className="video-play">
	<Button
		link
		text={
			<Icon icon={<PlayIcon />}/>
		}
		onClick={props.onPlay}
		type="button"
	/>
</div>
}


</div>
		</div>
		</div>
		<div class="col-md-9 p-1">
		<div class="edit_button text-end align-items-center">
		
		<h4 class="text-2 mb-3 text-center link_color"><NavLink to={props?.link} className="link_color"> {props.pdfdownload} </NavLink> </h4>	
				<h4 class="text-2 text-center link_color"><NavLink to={props?.link} className="link_color"> {props.delete} </NavLink> </h4>	
	
			</div>
			<div className="row">
			
				<div className="col-md-12 mb-4 mt-2">
				<div className="d-flex position-relative bg-subcolor flex-row align-item-center">
				<p class="mt-1 text-1 mr-2 text-center  font-weight-bold text-dark">{props.categeoryname}</p>
				<h4 class="mt-1 text-2 text-center text-color-secondary">: {props.categeorytitle}</h4>
				</div>
				</div>
			
				<div className="col-12 mb-4">
					<div className="d-flex bg-subcolor flex-row align-item-center">
				<p class="mt-1 text-1 mr-3 text-center  font-weight-bold text-dark">{props.subcategeory}</p>
				<h4 class="mt-1 text-2  text-color-secondary">: {props.subcategeorytitle}</h4>
				</div>
			</div>
			<div class="col-12 mb-3" >
					<div class="d-flex bg-subcolor align-items-center">
							<p class="mb-1 mr-4 text-1 text-center font-weight-bold text-dark top-3 p-relative">{props.course}</p>
							<h4 class="mb-0 text-2 text-center text-color-secondary">: {props.coursetitle}</h4>
						
					</div>
				</div>
				</div>
			<div className="row">
			<div class="col-4">
					<div class="d-flex bg-subcolor_1 align-items-center border-right">
							<div class="d-flex flex-row text-start">
								<p class="mb-1 text-1 mr-3 font-weight-bold text-dark">{props.module} </p>
								<h4 class="text-2 ml-2"><NavLink to={props?.link} className="link_color">  {props.value} <BsArrowRight /></NavLink></h4>
							</div>
					</div>
				</div>
				<div class="col-4">
					<div class="d-flex bg-subcolor_1 align-items-center border-right">
							<div class="d-flex flex-row">
								<p class="mb-1 text-1 mr-3 text-center font-weight-bold text-dark">{props.availablevideos} </p>
								<h4 class="text-2 text-center"><NavLink to={props?.link}><a className="link_color"> {props.valuevideo} <BsArrowRight /></a></NavLink> </h4>
							</div>
					</div>
				</div>
				<div class="col-3">
				<div className="d-flex flex-column bg-subcolor_1">
					<div className="d-flex flex-row mt-1 text-center">
							<p class=" mt-1 text-1  font-weight-bold text-dark">{props.status} </p>&nbsp;&nbsp;
							<h4 class="text-2 mt-1 text-color-secondary"> {props.active} </h4>
							
								</div>
								<div className="row position-relative top-4">
							<div className="col-md-6">
								<ViewContent title={props.inactive} link=""/><br/>
								</div>
								<div className="col-md-6">
								<ViewContent title={props.statusactive} link=""/>
								</div>
							</div>
								</div>
								</div>
								
				</div>
			</div>
			</div> 
	</div>
        </>
    )
}


export default CourseCard;



