import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import LayoutGuru from '../../../components/layoutGuru'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader'
import useVideoPlayers from '../../../components/videoPlayers/hooks/videoPlayers.hook'
import { FREEMIUM, GURU } from '../../../constants/app.constants'
import useGetAllVideosWatched from '../../../hooks/getallvideoswatched.hook'
import useVideosCommonHooks from '../../instructor/createCourse/videosCommonHook'
import useLearnerMain from '../main/hooks/traineeMain.hook'
import CourseDetailsView from './views/courseDetailsView'

const CourseDetailsPage = props => {
    const {courseId} = useParams()
    const isMyLearningPage = false;
    const userInfo = useSelector(state => state?.app?.userInfo);
    const commonHook =  useVideosCommonHooks();
    const [getChangeDone,setChangesDone] = useState(0)

    
    
    useEffect(() => {
        commonHook.getCustomData({"uri":"fetchCourseDetails","courseId":courseId});       
    }, [getChangeDone])

    if(commonHook.loading ) {
        return <Loader/>
    
    }else{
      
        return (
          <div>
            {userInfo?.usertypeid === GURU &&(
               <LayoutGuru {...props} footer>
               <CourseDetailsView {...props} isMyLearningPage={isMyLearningPage} {...commonHook} setChangesDone={setChangesDone} courseId={courseId}/>
               </LayoutGuru>
            )
            }

            {userInfo?.usertypeid != GURU &&(
                <LayoutPanel {...props} footer>
                <CourseDetailsView {...props} isMyLearningPage={isMyLearningPage} {...commonHook} setChangesDone={setChangesDone} courseId={courseId} />
                </LayoutPanel>
            )
            }

          </div>
        )
      
         
  }

}

export default CourseDetailsPage
