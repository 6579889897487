import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import BankDetailsView from "./view/BankDetailsView";
import InfomationNavSide from "../../../components/infomationNavSide";
import useBankDetails from "./hooks/bankDetails.hook";
import useProfile from "../settings/profile/hooks/profile.hook";
import Loader from "../../../components/loader";

const BankDetails = props => {
    const {text} = props
    const bankDetailsHook = useBankDetails()
    const profileHook = useProfile()
    const isLoaded = !profileHook.loading && !bankDetailsHook?.loading
    useEffect(() => {
        profileHook?.getProfileDetails()
        //bankDetailsHook.getBankDetails()
    }, [])
    if (!isLoaded) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
            <SignupLayout
                {...props}
                navSideLayout={<InfomationNavSide text={text?.INFORMATION_MENU}/>}
            >
                <BankDetailsView
                    {...props}
                    {...bankDetailsHook}
                    profileDetails={profileHook.profileDetails}
                />
            </SignupLayout>
        </>
    )
}

export default BankDetails;
