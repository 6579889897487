import axios from "axios";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import useHelper from "../../hooks/helpers.hook";
import useHttpHandler from "../../hooks/httphandler.hook";
import Button from "../button";
import Icon from "../icon";
import ProgressBarCard from "../programBarCard";
import CameraIcon from "../svg/cameraIcon";
import TickIcon from "../svg/tickIcon";
import Text from "../text";


const EditUploadVideoPreview = props => {
    const {getFileExtension, getFileSize} = useHelper()
    const apiUrl = process.env.REACT_APP_API_URL + '/videoUpload'
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [getImg, setImg] = useState(process.env.REACT_APP_IMG_URL + props?.videoSampleFile[0].thumbnail );
    const {setFieldValue} = props;
    const [errors, setErrors] = useState("");
    const {post} = useHttpHandler();
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'video/*': ['.mp4', '.mpeg'],
        },
        onDrop: (acceptedFiles) => {
            
            setErrors('')
            console.log("props.fieldName",props.fieldName)
            setFieldValue(props.fieldName, acceptedFiles);
            props.setUploadedVideosInfo(null)
            acceptedFiles.forEach((file) => {
                setFiles(file);
                const data = new FormData();
                data.append('file', file);
                axios.post(apiUrl, data, {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        props.setIsUploading(true)
                        const {loaded, total} = progressEvent;
                        let percentage = Math.floor((loaded * 100) / total);
                        if (percentage <= 100) {
                            setProgress(percentage)
                        }
                    }

                }).then((responseData) => {
                    
                    let postData = {
                        filename: responseData.data.data[0].newfile_name,
                        file_size: responseData.data.data[0].size,
                        file_type: responseData.data.data[0].mimetype,
                        thumbnail: responseData.data.data[0].thumbnail,
                        duration: responseData.data.data[0].duration
                    }
                    console.log('responseData', responseData.data.data[0],postData,postData.thumbnail)
                    props.setUploadedVideosInfo(responseData.data.data)
                    if(props?.istopicVideo){
                        post('videoUploadEntry', postData).then((res) => {
                            const status = res.data.status
                            const data = res.data.data
                            if (status.result == '200') {
                                console.log("videoId",data[0].videoid );
                                props.setVideoId(data[0].videoid + "");
                                
                            } else {
                            //   toast.error(status.msg)
                            }
                        
                            //hell
                        }).catch(err => {
                            
                        })
                }
                    props.videoSampleFile[0] = responseData.data.data;
                    props.setIsUploading(false)
                    setImg(process.env.REACT_APP_IMG_URL + postData.thumbnail);

                    

                }).catch((error) => {
                    console.log("ERROR " + error.message)
                });
            });
        }
    });

    const fileSize = files ? getFileSize(files?.size) : ''
    const fileFormat = files ? getFileExtension(files?.name) : ''

    return (
        <>
            <div className="thumbnail-main-wrapper">
                <div className="thumbnail-wrapper">
                    <div className="thumbnail-upload-file-wrp">
                    
                        <div className="upload-content" {...getRootProps({className: 'dropzone'})}>
                        
                        
                        
                                <div class="create_course_image">

                                        <img src={getImg} alt="" className='course-upload-wrap' />      
                                        <input {...getInputProps()} />
                                        
                                        <div className="icon create_course_h21 div-center">
                                            <CameraIcon/>
                                       
                                        <Text className="create_course_h21 " type="SPAN" text={ props.text}  />
                                        </div>
                                </div>
                           
                        </div>
                    </div>

                    <div className="filesdetails">
                {files && (
                     <> 
                        <Text type="H4" className="mb-10" text={files?.name}/>
                        <Text type="PARAGRAPH" className="my-0 font-size-12 text-black-half"
                              text={`File size: ${fileSize}`}/>
                        <Text type="PARAGRAPH" className="mt-0 mb-10 font-size-12 text-black-half"
                              text={`File format: ${fileFormat}`}/>
                        <ProgressBarCard width={progress}/>
                    
                    </>
                   
                )}
                {/* {!files && (
                    
                    <div className="col-12">
                        <Text type="PARAGRAPH" style={{display:"flex",marginLeft:"-15px"}} text={props?.videoSampleFile[0].filename}/>
                    </div>    
                   
                )} */}
                 </div>
                </div>
              
            </div>
        </>
    );
}
export default EditUploadVideoPreview;