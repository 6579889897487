import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader'
import useGuruUploadVideo from '../../instructor/uploadVideo/hooks/guruUploadVideo.hook'
import SubCategeoryPurchaseView from './views/subCategeoryPurchaseView'

const SubCategeoryPurchase = (props) => {
  const guruUploadVideoHook = useGuruUploadVideo()
const {id} = useParams();
  useEffect(() => {

    const params = {"perpage":100,"categoryId":id}
    guruUploadVideoHook.getSubCategories(params);
    console.log("dropdown",guruUploadVideoHook.dropdownsData)
 }, [])
 if(guruUploadVideoHook.loading) {
 
   return <Loader/>
 
 }else{
 
   

  return (
    <div>
       <LayoutPanel {...props} footer>
      <SubCategeoryPurchaseView {...props} guruUploadVideoHook={guruUploadVideoHook} />
      </LayoutPanel> 
    </div>
  )
 }
}

export default SubCategeoryPurchase;
