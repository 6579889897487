import React, { useEffect, useState } from "react";
import Button from "../button";

import HeroSlider from "../heroslider";
import './homeHero.css';
import { useNavigate } from "react-router-dom";
import useVideosCommonHooks from "../../pages/instructor/createCourse/videosCommonHook";


const HomeHero =(props)=>{

  const navigate = useNavigate();

  const commonHook = useVideosCommonHooks();


  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    console.log("props.image",props)
  // Fetch pre-signed URL from the backend
  commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':"ct/courseTsunamiHomepageVideo.mp4"}).then((data) => {
    //console.log("data.data",data.data)
    console.log("data.data--->homepage",data.data)
    setImageUrl(data.data)
 
   
});
}, []);
if(imageUrl != ""){
  return(
        <>
         <div id="about" class="about">
      <div class="container">
        <div class="row gy-4 align-items-center">
          
          <div class="col-lg-6 order-1 order-lg-2" >
          
          <HeroSlider options={[{"videofilename":imageUrl}]} {...props}  />
           
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content "> 
            <h3 style={{textTransform:'uppercase'}}>UNLEASH YOUR POTENTIAL </h3>
            <p class="home-page-title-bold">
            Join Course Tsunami and Experience the Power of Knowledge and Financial Rewards!
            </p>

            {/*<h3 style={{marginTop:'-40px'}}>Course Creators</h3> */}
            <p>Welcome to Course Tsunami, where knowledge and opportunity converge! Our 
platform empowers course creators to earn recurring income while learners gain 
knowledge and financial rewards by referring others. Join us to be part of a 
transformative journey in education and financial growth. Ride the wave of 
success with Course Tsunami!</p>
            {
            /*}
            <h3>Learners</h3>
            <p>Embark on a journey of lifelong learning and earn commissions for referring others to Course Tsunami. Explore a diverse selection of courses taught by industry experts and gain valuable skills and insights. Experience the joy of learning while unlocking opportunities for financial rewards.

            <br/><br/>               
            Join Course Tsunami and immerse yourself in a transformative learning experience that fuels personal growth and financial prosperity. Enrol now and unlock a world of knowledge and endless possibilities!</p>
            */
            }
            
            {
<Button
type="button"
text="Sign up Here"
onClick={()=>{ 
    navigate('/register')

}}
/>

            }
          </div>
        </div>

      </div>
    </div>
        
        
        </>
    )
  }
}


export default HomeHero;