import React from "react";
import Button from "../button";
import Flex from "../flex";
import PlayIcon from "../svg/playIcon";
import Text from "../text";
import viewImg from "../../assets/img/viewiconWhite.png";
import axios from "axios";
import fileDownload from "js-file-download";
import Icon from "../icon";
import Image from "../image";


const LearnerModuleMainbar = (props) => {
  var divclass = "row all-main-box ";
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <>
      <div className={"'" + divclass + "'"} style={{ marginBottom: "-40px" }}>
        <div className="col-12 all-main-box">
          <div className="dash-recent-upload-card shadow bg-body">
            <div className="row">
            <div className="text-center col-md-4">
              <div className="col-12 text-center">
                <div className="video-card">
                  <div className="video-poster">
                   


<Image src={props?.data?.thumbnail} alt={props?.data?.title} className='video_card' />      
                    

                    <div className="video-play">
                      <Button
                        link
                        text={<Icon icon={<PlayIcon />} />}
                        onClick={() => {
                          console.log("props?.data", props?.data);

                          
                          props?.playVideo(props?.data?.introvideo);
                        }}
                        type="button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 col-md-8">
              
                <div className="acco-module">
                  <div className="p-head">
                  <Text type="H5" text={props?.data?.moduletitle}  className='text-dark' />
                <Text
                  type="PARAGRAPH"
                  className="text-black-50"
                  text={props?.data?.desc}
                />
                  </div>
                  {/* dashboard-share-icon */}
                  <div className=" text-right">
                  <div className="">
                    {props.viewButton}
                    {props?.data?.attacheddoc != "" && (
                      /*
                     <Button
                        type="primary"
                        key="console"
                        text="Download"
                        onClick={() => {
                          const imgpath =
                            process.env.REACT_APP_IMG_URL +
                            props?.data?.attacheddoc;
                          handleDownload(imgpath, "test-download.jpg");
                        }}
                      />*/

                      <Button
                      
                        text={
                          <Flex>
                            {" "}
                            <img
                              src={viewImg}
                              alt="copy"
                              className="icon-32"
                            />{" "}
                            <Text
                              type="PARAGRAPH"
                              className=" text-white my-0 pl-2"
                              text="Download "
                            />
                          </Flex>
                        }
                    
                        onClick={() => {
                          const imgpath =
                            process.env.REACT_APP_IMG_URL +
                            props?.data?.attacheddoc;
                          handleDownload(imgpath, "test-download.jpg");
                        }}
                        type="button"
                      />

                      
                      
                    )}

<Button
                        text={
                          <Flex>
                            {" "}
                            <img
                              src={viewImg}
                              alt="copy"
                              className="icon-32 m-0"
                            />{" "}
                            <Text
                              type="PARAGRAPH"
                              className=" text-white my-0 pl-2"
                              text="Quiz "
                            />
                          </Flex>
                        }
                  
                        onClick={() => {
                          const imgpath =
                            process.env.REACT_APP_IMG_URL +
                            props?.data?.attacheddoc;
                          handleDownload(imgpath, "test-download.jpg");
                        }}
                        type="button"
                      />
                  </div>
                </div>
                </div>
                
                
              
            </div>
            </div>
           

          </div>
        </div>
      </div>
    </>
  );
};

export default LearnerModuleMainbar;
