import React from 'react';

const CloseTwoIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.82843 1.17157C5.26633 -0.390524 2.73367 -0.390524 1.17157 1.17157C-0.390524 2.73367 -0.390524 5.26633 1.17157 6.82843L6.82843 1.17157ZM17.6716 23.3284C19.2337 24.8905 21.7663 24.8905 23.3284 23.3284C24.8905 21.7663 24.8905 19.2337 23.3284 17.6716L17.6716 23.3284ZM23.3284 17.6716C21.7663 16.1095 19.2337 16.1095 17.6716 17.6716C16.1095 19.2337 16.1095 21.7663 17.6716 23.3284L23.3284 17.6716ZM34.1716 39.8284C35.7337 41.3905 38.2663 41.3905 39.8284 39.8284C41.3905 38.2663 41.3905 35.7337 39.8284 34.1716L34.1716 39.8284ZM23.3284 23.3284C24.8905 21.7663 24.8905 19.2337 23.3284 17.6716C21.7663 16.1095 19.2337 16.1095 17.6716 17.6716L23.3284 23.3284ZM1.17157 34.1716C-0.390524 35.7337 -0.390524 38.2663 1.17157 39.8284C2.73367 41.3905 5.26633 41.3905 6.82843 39.8284L1.17157 34.1716ZM17.6716 17.6716C16.1095 19.2337 16.1095 21.7663 17.6716 23.3284C19.2337 24.8905 21.7663 24.8905 23.3284 23.3284L17.6716 17.6716ZM39.8284 6.82843C41.3905 5.26633 41.3905 2.73367 39.8284 1.17157C38.2663 -0.390524 35.7337 -0.390524 34.1716 1.17157L39.8284 6.82843ZM1.17157 6.82843L17.6716 23.3284L23.3284 17.6716L6.82843 1.17157L1.17157 6.82843ZM17.6716 23.3284L34.1716 39.8284L39.8284 34.1716L23.3284 17.6716L17.6716 23.3284ZM17.6716 17.6716L1.17157 34.1716L6.82843 39.8284L23.3284 23.3284L17.6716 17.6716ZM23.3284 23.3284L39.8284 6.82843L34.1716 1.17157L17.6716 17.6716L23.3284 23.3284Z" fill="currentColor"/>
        </svg>
                
    );
};

export default CloseTwoIcon;

CloseTwoIcon.defaultProps = {
    width: "41",
    height: "41"
}