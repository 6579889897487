import React from 'react'
import Flex from '../../../../../components/flex';
import Pagination from '../../../../../components/pagination';
import Table from '../../../../../components/table';
import Text from '../../../../../components/text';
import useHelper from '../../../../../hooks/helpers.hook';

const CategeoryComissionView = (props) => {
    const {text} = props
    const {formatDateMMMDDYYYY} = useHelper()
    const tableData = props?.tableData?.listData
  return (
    <div>
             <Text
                type="H1"
                text="Categories Commission"
            />
            
            <Flex justifyContent="between" className="mb-24 flex-column flex-md-row">
                <div>
                    {props?.tableData?.customdata?.total_amount > 0 && (
                        <>
                            
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-white-half mt-2"
                                text={`RM ${props?.tableData?.customdata?.total_amount}`}
                            />
                        </>
                    )}

                </div>
            </Flex>

            <Table
                thead={
                    <tr>
                        <th>sno</th>
                        <th>Categories name</th>
                        <th>Status</th>
                        <th>Date Registered</th>
                        
                        <th>Income Generated</th>
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
                                    <td>{formatDateMMMDDYYYY(item.updated_on)}</td>
                                    <td>{item.memberid}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.type_details}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )
                        })}
                        {props?.tableData?.customdata?.total_amount > 0 && (
                            <tr>
                                <td colSpan="4" className="text-right text-white-half">Total</td>
                                <td className="font-weight-600 text-black">
                                    RM {props?.tableData?.customdata?.total_amount}</td>
                            </tr>
                        )}
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
    </div>
  )
}

export default CategeoryComissionView;
