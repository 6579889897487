import React from "react";
import LayoutGuru from "../../../components/layoutGuru";
import GuruUploadVideoView from "./view/GuruUploadVideoView";

const GuruUploadVideo = props => {

    return (
        <>
            <LayoutGuru {...props} noBgContent="true">
                <GuruUploadVideoView {...props} />
            </LayoutGuru>
        </>
    )
}

export default GuruUploadVideo;
