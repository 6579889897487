import React, {useEffect} from "react";
import LayoutPanel from "../../../components/layoutPanel";
import useHelper from "../../../hooks/helpers.hook";
import useGetAllVideosWatched from "../../../hooks/getallvideoswatched.hook";
import Loader from "../../../components/loader";
import useLearnerDashboard from "./hooks/traineeDashboard.hook";
import TraineeDashboardView from "./view/traineeDashboardView";
import useHome from "../../Home/hooks/home.hook";

const TraineeDashboard = props => {
    const {getProfile} = useHelper()
    const getAllVideosHook = useGetAllVideosWatched()
    const dashboardHook = useLearnerDashboard()
    useEffect(() => {
        if (!getAllVideosHook.loading) {
            getProfile()
        }
    }, [getAllVideosHook.loading])

    if(getAllVideosHook.loading || dashboardHook.loading) {
        return <Loader/>
    }else{
    return (
        <>
            <LayoutPanel {...props} footer>
                <TraineeDashboardView {...props} dashboardDetails={dashboardHook?.dashboardDetails} />
            </LayoutPanel>
        </>
    )
}
}

export default TraineeDashboard;
