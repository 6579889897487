import React from "react";
import Flex from "../../../components/flex";
import AboutUsHeroSection from "../../../components/heroSection";
import HeroSlider from "../../../components/heroslider";
import PageHeader from "../../../components/pageHeader";
import ProcessCount from "../../../components/processCount";
import checkcircle from '../../../assets/img/checkcircle.png';
import Input from "../../../components/input";
import { useFormik } from "formik";
import Button from "../../../components/button";
import * as Yup from "yup";

import { AiOutlineRight,AiOutlineInstagram,AiFillYoutube,AiFillLinkedin, AiFillTwitterCircle, AiFillRightCircle } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

const WaitingListPageView =()=>{

  const formik = useFormik({
    initialValues: {
        uname: '',
        email: '',
    },
    validationSchema: Yup.object({
        uname: Yup.string().required('Please enter a valid username'),
        email: Yup.string().required('Please enter a valid email address'),
    }),
    onSubmit: (values, actions) => {
        
        

    }
});


    return(
        <>

<section class="page-header viewcourse-btn">
        <div class="container ">
            <div class="row justify-content-center">
                <div className="col-md-8 text-center">
                <h2 class="font-weight-bold text-8 mb-0 text-center text-black-color">Unleash Your Learning Potential</h2>
                      <h3 class="font-weight-bold text-8 mb-0 text-center text-black-color">with</h3>
                      <h2 class="font-weight-bold text-8 mb-10 text-center text-theme-color">Course Tsunami!</h2>
                      <HeroSlider options={[{"videofilename":"pagesvideos/pre-launch-sign-up-page-video.mp4"}]}  />

                      <h2 class="font-weight-bold text-8  pt-5">COMING SOON!</h2>

                      <h1 class="text-hash-color text-center mt-10">Join our waiting list to be one of the <b >FIRST</b> to access a world of knowledge and growth.</h1>
                     

                      <h5 class="text-black-color text-center mt-20 text-transform-none">Don't miss out on this exciting opportunity to unleash your <b>Learning & Earning Potential.</b> Sign up now and secure your spot on the Course Tsunami waiting list. Be part of the e-learning revolution!</h5>

           
                      
                      <h4 class="text-black-color pt-5"><b>Why Join Our Waiting List?</b></h4>
                </div>
              
                     

            </div>

            
        <div className="row">
            <dic className="col-md-12 text-center">
            <div className="pt-3" >
                          <img src={checkcircle} class="icon-32" alt="" /> &nbsp;
                          <span class=""> <b>Exclusive Offers:</b> Gain access to special offers and discounts available only to our prelaunch learners.</span>
                </div>

                <div className="pt-3" >
                          <img src={checkcircle} class="icon-32" alt="" /> &nbsp;
                          <span class=""><b>Stay Informed:</b>  Receive updates and sneak peeks as we approach the official launch of Course Tsunami.</span>
                </div>

                <div className="pt-3" >
                          <img src={checkcircle} class="icon-32" alt="" /> &nbsp;
                          <span class=""><b>Early Access:</b> Get a head start and be the first to experience our ground breaking platform.</span>
                </div>
                {/* class="div-center" */}
            </dic>
        </div>
            
            <div class="row justify-content-center">             


                <div class="col-md-4  pt-5">
                <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        className="form-label"
                        formControlClassName="form-control"
                        placeholder="First Name"
                        type="text"
                        name="uname"
                        
                        autoFocus={true}
                        value={formik.values.uname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.uname && formik.errors.uname ? (
                            formik.errors.uname
                        ) : null}
                    />
                </div>
                <div className="formBox">
                <Input
                        className="form-label"
                        formControlClassName="form-control"
                        placeholder="Email address"
                        type="text"
                        name="email"
                        
                        autoFocus={true}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.email && formik.errors.email ? (
                            formik.errors.email
                        ) : null}
                    />
                   
                </div>
       
                <Flex justifyContent="center" className="mb-40 fs-1">
                    <Button
                        type="submit"
                        text="Join the Waiting List"
                        large
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </Flex>
            </form>
                </div>

            </div>


            


        </div>
    </section>
      
        
        </>
    )
}


export default WaitingListPageView;