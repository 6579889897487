import React from 'react';
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import Listen from '../../../../components/listen';
import Image from '../../../../components/image';
import SubscribeImage from '../../../../assets/img/logo.svg';
import usePayment from "../../payment/hooks/payment.hook";
import { NavLink } from 'react-router-dom';


const SubscribeView = (props) => {
    const {text, subscribeNow, packages, isSubmitting} = props
    const {onClickPayLater} = usePayment()

    return (
        <>
               <section class="parallax section section-text-light section-parallax mt-0 mb-0">
            <h2 className="text-center fs-1 fw-semibold">Pricing Plans</h2>
    <div class="container mt-5">
        <div class="pricing-table row ">
           
           
            <div class="col-md-6  d-flex align-content-stretch ">
                <div class="plan">
                <div class="plan-price  bg-color-prim">
                        <span class="price">Premium Pro</span>                            
                    </div>
                    <div class="plan-header bg-sect-col py-4">
                        <h3 class="text-color-dark"> Unlock Your Full Potential! </h3>
                    </div>
                    <div class="plan-features bg-sect-col">
                        <ul>
                        <li> <b>Unlimited Course Access: :</b> Enjoy unlimited access to our entire course library, covering a wide 
                            range of topics and skill levels.</li>
                            <li> <b>Exclusive Content: </b> Access premium courses, advanced lessons, and specialized modules 
                            available only to subscribers.</li>
                            <li> <b>Priority Support: </b> Receive dedicated support from our expert instructors and customer service 
                            team.</li>
                            <li> <b>Personalized Learning Path:</b> Get customized course recommendations based on your learning 
                            goals and progress.</li>
                            <li> <b>Certification:</b> Earn certificates of completion for every course you finish, showcasing your 
                            achievements and skills.</li>
                            <li> <b>Interactive Quizzes and Assignments:</b> Enhance your learning with comprehensive quizzes, 
                            assignments, and feedback.</li>
                            <li> <b>Live Sessions and Workshops:</b> Participate in live video sessions, workshops, and Q&A with 
                            instructors.</li>
                            <li> <b>Downloadable Resources: </b>Download course materials, handouts, and additional resources for 
                            offline learning.</li>
                            <li> <b>Direct Chat Support: </b>Get instant help with course content through our chat feature, connecting 
                            you directly with instructors for guidance.</li>
                            <li> <b>Earn Monthly Recurring Commissions:</b> Earn recurring monthly income for referring new 
                            subscribers.</li>
                           
                        </ul>
                        <div className="p-2"> <p>Ideal for serious learners and professionals looking to gain in-depth knowledge and skills with full 
                        access to all our resources.</p> </div>
                    </div>
                   
                    <div class="plan-footer">
                      
                        <NavLink
                                  to={"/trainee/subscribe/purchase-categeory"}
                                >
                                  <button class="btn btn-primary ">Select</button>{" "}
                                </NavLink>
                    </div>
                </div>
            </div>




            <div class="col-md-6  d-flex align-content-stretch ">
                <div class="plan">
                <div class="plan-price  bg-color-prim">
                        <span class="price">Premium Elite</span>                            
                    </div>
                    <div class="plan-header bg-sect-col py-4">
                        <h3 class="text-color-dark"> Unlock Your Full Potential! </h3>
                    </div>
                    <div class="plan-features bg-sect-col">
                        <ul>
                        <li> <b>Unlimited Course Access: :</b> Enjoy unlimited access to our entire course library, covering a wide 
                            range of topics and skill levels.</li>
                            <li> <b>Exclusive Content: </b> Access premium courses, advanced lessons, and specialized modules 
                            available only to subscribers.</li>
                            <li> <b>Priority Support: </b> Receive dedicated support from our expert instructors and customer service 
                            team.</li>
                            <li> <b>Personalized Learning Path:</b> Get customized course recommendations based on your learning 
                            goals and progress.</li>
                            <li> <b>Certification:</b> Earn certificates of completion for every course you finish, showcasing your 
                            achievements and skills.</li>
                            <li> <b>Interactive Quizzes and Assignments:</b> Enhance your learning with comprehensive quizzes, 
                            assignments, and feedback.</li>
                            <li> <b>Live Sessions and Workshops:</b> Participate in live video sessions, workshops, and Q&A with 
                            instructors.</li>
                            <li> <b>Downloadable Resources: </b>Download course materials, handouts, and additional resources for 
                            offline learning.</li>
                            <li> <b>Direct Chat Support: </b>Get instant help with course content through our chat feature, connecting 
                            you directly with instructors for guidance.</li>
                            <li> <b>Earn Monthly Recurring Commissions:</b> Earn recurring monthly income for referring new 
                            subscribers.</li>
                           
                        </ul>
                        <div className="p-2"> <p>Ideal for serious learners and professionals looking to gain in-depth knowledge and skills with full 
                        access to all our resources.</p> </div>
                    </div>
                   
                    <div class="plan-footer">

                    


                    <NavLink
                                  to={"/trainee/subscribe/premium-elite-purchase"}
                                >
                                  <button class="btn btn-primary ">Select</button>{" "}
                                </NavLink>
                    
                    </div>
                </div>
            </div>


            <div class="col-md-4 d-none">
                <div class="plan plan-featured">
                    <div class="plan-header py-4">
                        <h3>Standard</h3>
                    </div>
                    <div class="plan-price">
                        <span class="price"><span class="price-unit">$</span>17</span>
                        <label class="price-label">PER MONTH</label>
                    </div>
                    <div class="plan-features">
                        <ul>
                            <li>3GB Disk Space</li>
                            <li>25GB Monthly Bandwith</li>
                            <li>5 Email Accounts</li>
                            <li>Unlimited Subdomains</li>
                        </ul>
                    </div>
                    <div class="plan-footer">
                        <a href="#" class="btn btn-primary btn-modern py-2 px-4">Sign Up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    );
};

export default SubscribeView;

