import React, { useState } from "react";
import Button from "../button";
import Flex from "../flex";
import Image from "../image";
import Listen from "../listen";
import PlayIcon from "../svg/playIcon";
import BinIcon from "../svg/binIcon";
import Text from "../text";
import RecentImage from '../../assets/images/video-details-img.jpg'
import copyImg from '../../assets/img/copyIconWhite.png'
import shareImg from '../../assets/img/shareIconWhite.png'
import binImg from '../../assets/img/Bin.png'
import editImg from '../../assets/img/Edit.png'
import viewImg from '../../assets/img/viewicon.png'
import {toast} from "react-toastify";
import Icon from "../icon";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import useHelper from "../../hooks/helpers.hook";
import ShareVia from "../shareVia";
import { Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { URL } from "../../constants/app.constants";
import SwitchButton from "../switchButton";
import useHttpHandler from "../../hooks/httphandler.hook";
import useVideosCommonHooks from "../../pages/instructor/createCourse/videosCommonHook";



const CourseMainBar = props => {
    const commonHook = useVideosCommonHooks();
    const {post} = useHttpHandler();
    console.log("props",props)
    const index = props.index;
    const {courseId} = useParams();
    const navigate = useNavigate();
    var divclass = "row all-main-box course-main-box pading-left-right-15 ";
    const {getShortDescription_dot} = useHelper();
    const userInfo = useSelector(state => state.app.userInfo)
    console.log("userInfo",userInfo);
    const onCopy = ()=>{
       alert("Copied Course")
    }

    if(props.index > 0){
        divclass = "row all-main-box course-main-box pading-left-right-15 mt_-20 ";             
    }

    if(props.index == 100){
        divclass = "row all-main-box course-main-box ";             
    }

   
    const courseStatusChange = (statusbit) => {
        console.log("Statusbit",statusbit,props.data.courseid)
        var status = 0;
        if(statusbit == 1){
            status = 0;
        }else{
            status = 1;
        }
        post('instrutorCourseStatusChange ', {"courseId":props.data.courseid,"statusbit":status}).then((res) => {
           
            const status = res.data.status
       
            if (status.result === '200') {
                toast.success(status.msg)
            }else{
                toast.success(status.msg)
            }
        }).catch(err => {
           
        })



    };


    return (
        <> 


    
            
                <div className="col-12 col-md-4">                    

                        <div className="video-card-dashboard">
                                            <div className="video-poster">

                                            <Image src={ props.data?.thumbnail} alt={props.data?.title} />
                                               
                                                <div className="video-play">
                                                    <Button
                                                        link
                                                        text={
                                                            <Icon icon={<PlayIcon />}/>
                                                        }
                                                        onClick={() => {
                                                                console.log("props.data?.videofilename->",props.data?.videofilename)
                                                                props.playVideo(props.data?.videofilename)
                                                        
                                                            
                                                            
                                                        }}
                                                        type="button"/>
                                                </div>

                                            </div>
                        </div>
                            
                        
                </div>    
                <div className="col-12 col-md-6 text-left">
                    
                          
                        
                        
                            
                            <Text
                                        type="H5"
                                        text={props.data?.title}
                                    />
                     

                            <Text
                                type="PARAGRAPH"
                                className="text-dark"
                                text={getShortDescription_dot(props.data?.des,200)}
                                
                            />



                    <div className="top-main-boxes-primary">

                    <div className="p-2">
                        <div className="d-flex justify-content-between">


                        
                        <div className="text-color-white d-flex justify-content-between ">
                                                                                 
                           <div className=' d-flex justify-content-between pr-2' >
                            <Text
                                type="p"
                                text={"+" + props?.data.views }
                                className={"m-0 fw-bold pr-2"}
                                
                                
                            />
                             <Text type="p" className="text-color-white pr-2" text="Views"/> 
                            </div>

                            <div className='d-flex justify-content-between' >
                            <Text
                                type="p"
                                text={"+" + props?.data.likes }
                                className={"m-0 fw-bold pr-2"} 
                               
                            />
                          
                             <Text type="p" className="text-color-white pr-2" text="Likes"/> 
                            </div>

                          
                        </div> 
                        <div className=""> 
                                           
                        <div className="text-center d-flex justify-content-between">     

                              <Text type="p" className="text-color-white p-0" text="Copy URL"/>                       
                           <CopyToClipboard onCopy={onCopy} text={  URL + "course/" + props?.data.courseid + "-0-" + userInfo?.myrefcode  }>
                   
                            <Button
                            toolTip="Copy Course"
                                link
                                className={"pull-left px-2 pr-3"}
                                text={
                                    <img src={copyImg}  alt="copy" className='dashboard-share-icon' />
                                } 
                                
                                type="button"/>
                        </CopyToClipboard> 

                        <Text type="p" className="text-color-white pr-2" text="Share Your Course "/> 
                            <Button
                            link
                            toolTip="Share Course"
                            className={"pull-right"}
                            text={
                                <img src={shareImg}  alt="copy" className='dashboard-share-icon' />
                            }
                            onClick={() => {
                                props.setSelectedCourseId(props?.data.courseid);
                                props.setIsShareModal(true)
                            }}
                            style={{marginLeft: '0px'}}
                            type="button"/>

                       
                       </div>
                        </div>
                       

                        </div>
                        
                        <div className='input-share-height'>
                            <Text
                                type="PARAGRAPH"
                                text={  URL + "course/" + props?.data.courseid + "-0-" + userInfo?.myrefcode  }
                                className="dashboard-share-txt"
                            />  
                        </div>
                       
                 </div>
          
                        
                    </div>
                    
                </div>
                <div className="col-12 col-md-2">
                    
                    <div className="" >
                       
                         {props.showButtons && 

                         <>

                                    <div className="col-12 text-center dashboard-share-icon" > 

                                    {props.data?.price == 0 &&
                                      <Text
                                      type="H5"
                                      text={"Free"}
                                  />    
                                    }
                                  
                                    {props.data?.price != 0 &&
                                      <Text
                                      type="H5"
                                      text={"Price : " + props.data?.price}
                                  />    
                                    }                                                     
                                    
                                    <div className="d-flex justify-content-between">
                                        <div className="">
                                    <Button
                                            toolTip="View Course"
                                            link
                                            text={
                                                <img src={viewImg}  alt="copy" className='icon-32' />
                                            }
                                           
                                            onClick={() => navigate('/instructor/create-module/'+props?.data.courseid)}
                                            type="button" 
                                        />
                                        <Text
                                        type="PARAGRAPH"
                                        className="text-dark module-button-title-txt"
                                        text="View Course"
                                        />
                                        </div>
                                        <div className="">
                                        <Button
                                            toolTip="Edit Course"
                                            link
                                            text={
                                                <img src={editImg}  alt="copy" className='icon-32' /> 
                                
                                            }
                                           
                                            onClick={() => navigate('/instructor/edit-course/'+props?.data.courseid)}
                                            type="button" 
                                        />

                                        <Text
                                        type="PARAGRAPH"
                                        className="text-dark module-button-title-txt"
                                        text="Edit Intro"
                                        />

                                        </div>
                                        <div className="">
                                        <Button
                                        toolTip="Delete Course"
                                            link
                                            text={
                                                <img src={binImg}  alt="copy" className='icon-32' />
                                            }
                                           
                                            onClick={() => 
                                              
                                                props.deleteCourse(props?.data)
                                            }
                                            type="button" 
                                        />
                                        <Text
                                        type="PARAGRAPH"
                                        className="text-dark module-button-title-txt"
                                        text="Delete Course"
                                        />
                                        </div>
                                        </div>
                                        
                                
                                    </div>
                                    

                                  
                        </>

                         }   

<div className="col-12 mt-2">     

                         <div className="course-status-box-primary text-left">

                            <div className="p-2">

                             
                            <Text type="H5" text="Course Status" className="text-nocase"/>                   

                           
                                    <SwitchButton
                                        status={props?.data.statusbit}
                                        courseStatusChange ={courseStatusChange}
                                        onChange={() => {
                                          //  props?.changeUserStatus(item.userid, item.statusbit == 1 ? -1 : 1)

                                          //courseStatusChange(props?.data.statusbit)
                                         
                                        }}
                                    />        
                            </div>

                         </div>
        
                                   
                                 
                                   </div>
                      
                        
                    </div>

                    

                </div>                        

            

</>
    )
}

export default CourseMainBar;