import React from 'react';

const SwiperNextIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.58398 16.0833L8.87565 8.99992L1.58398 1.91658" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default SwiperNextIcon;

SwiperNextIcon.defaultProps = {
    width: '11',
    height: '18'
}