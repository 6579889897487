import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import usePersonalInformation from '../hooks/personalInformation.hook'
import Text from '../../../../components/text';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import Select from '../../../../components/input/select';
import {useNavigate} from "react-router-dom";
import useHelper from "../../../../hooks/helpers.hook";
import GoBackButton from "../../../../components/goBackButton";

const PersonalInformationView = (props) => {
    const {formatDateYYYYMMDD} = useHelper()
    const navigate = useNavigate()
    const {text, dropdownsData, profileDetails} = props
    const {
        updateProfile
    } = usePersonalInformation()
    const formik = useFormik({
        initialValues: {
            fullname: profileDetails?.fullname ? profileDetails?.fullname : '',
            passportno: profileDetails?.ic_passportno ? profileDetails?.ic_passportno : '',
            dob: profileDetails?.dob ? formatDateYYYYMMDD(profileDetails?.dob) : '',
            education_id: profileDetails?.educationid ? profileDetails?.educationid : '',
            occupationid: profileDetails?.occupationid ? profileDetails?.occupationid : '',
            incomerangeid: profileDetails?.incomeragneid ? profileDetails?.incomeragneid :'',
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Please enter a valid full name'),
            passportno: Yup.string().required('Please enter a valid identification number'),
            dob: Yup.string().required('Please enter a valid date of birth'),
            education_id: Yup.string().required('Please enter a valid level of education'),
            occupationid: Yup.string().required('Please enter a valid Occupation'),
            incomerangeid: Yup.string().required('Please enter a valid monthly range of income'),
        }),
        onSubmit: (values, actions) => {
            updateProfile(values)
        },
    });
    return (
        <>

<div class="register">

<main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div class="row justify-content-center">
        
        
          <div class="col-lg-7 col-md-6"> </div>
        
        
          <div class="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">

            

            <div class="card mb-3">

              <div class="card-body">

                <div class="pt-4 pb-2">
                  <h5 class="card-title text-center pb-0 fs-4">Create an Account</h5>
                  <p class="text-center small">Enter your personal details to create account

</p>
                </div>
                <Text
                type="H4"
                text={text?.PERSONAL_PARTICULARS}
                className="page-title"
            />
            <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        label={text?.FULL_NAME_AS_PER_IC}
                        type="text"
                        name="fullname"
                        placeholder={text?.FULL_NAME}

                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.fullname && formik.errors.fullname ? (
                            formik.errors.fullname
                        ) : null}
                    />
                </div>
                <div className="row">
                  
                    <div className="col-6">
                        <div className="formBox">
                            <Input
                                label={text?.DATE_OF_BIRTH}
                                type="date"
                                name="dob"
                                placeholder="Full Name"

                                value={formik.values.dob}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.dob && formik.errors.dob ? (
                                    formik.errors.dob
                                ) : null}
                            />
                        </div>
                    </div>


                    <div className="col-6">
                        <div className="formBox">
                            <Select
                                label={text?.LEVEL_OF_EDUCATION}
                                name="education_id"
                                value={formik.values.education_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.educationOptions}
                                errormessage={formik.touched.education_id && formik.errors.education_id ? (
                                    formik.errors.education_id
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="formBox">
                            <Select
                                label={text?.OCCUPATION}
                                name="occupationid"
                                value={formik.values.occupationid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.occupationOptions}
                                errormessage={formik.touched.occupationid && formik.errors.occupationid ? (
                                    formik.errors.occupationid
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="formBox">
                            <Select
                                label={text?.MONTHLY_RANGE_OF_INCOME_RM}
                                name="incomerangeid"
                                value={formik.values.incomerangeid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.salaryOptions}
                                errormessage={formik.touched.incomerangeid && formik.errors.incomerangeid ? (
                                    formik.errors.incomerangeid
                                ) : null}
                            />
                        </div>
                    </div>
                </div>
                <Flex justifyContent="center" className="mb-48 mt-48">
                    <GoBackButton text={text?.GOBACK} />
                    <Button
                        type="submit"
                        text={text?.PROCEED}
                        large
                    />
                </Flex>
                <Flex justifyContent="center">
                    <Button type="button" linkSecondary text={text?.FILL_UP_LATER}
                            onClick={()=>{navigate('/trainee/dashboard')}}/>
                </Flex>
            </form>
              </div>
            </div>


          </div>
        </div>
      </div>

    </section>

  </div>
</main>
</div>
            
        </>
    );
};

export default PersonalInformationView;

