import React from "react";
import Layout from "../../components/layout";
import Loader from "../../components/loader";
import useHome from "../Home/hooks/home.hook";
import AboutUsPageView from "./views/aboutUsPageView";



const AboutUsPage = (props) => {
    const homeHook = useHome()
    if (homeHook?.loading) {
        return (
            <>
                <Layout {...props}>
                    <Loader/>
                </Layout>
            </>
        )
    } else {
        return (
            <>
                <Layout {...props}>
                    <AboutUsPageView />
                </Layout>
            </>
        )
    }
}

export default AboutUsPage;
