import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";

const useCourseMain = () => {
    const {videoId} = useParams();
    const {courseId} = useParams()
    const [videoDetails, setVideoDetails] = useState(null)
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [isSideOpen, setIsSideOpen] = useState(true);
    const [getCourseData,setCourseData] = useState([]);
    
    const onOpenIsSide = () => {
        setIsSideOpen(isSideOpen => !isSideOpen);
    }
    const [isLike, setIsLike] = useState(0)
    const [isGuruFavourite, setIsGuruFavourite] = useState(0)
    const [isFavouriteVideo, setIsFavouriteVideo] = useState(0)
    const [favouriteVideoCount, setFavouriteVideoCount] = useState(0)
    const [videoLikesCount, setVideoLikesCount] = useState(0)
    const [guruFavouritedCount, setGuruFavouritedCount] = useState(0)


    const getVideoDetailsById = (id, isMyLearningPage=null) => {
        console.log("apiUrl");
        const currentVideoId = id ? id : videoId
        setLoading(true)
        const apiUrl = isMyLearningPage ? 'trainerGetVideoDetails' : 'get_video_full_details'
        var id = courseId.split("-");
        console.log("id-->",id)
        if(id.length == 3){
            sessionStorage.setItem('referralCode',id[2]);
            localStorage.setItem('referralCode',id[2]);
        }
        
        post("getCourseModuleVideos", {'id': id[0],'type':id[1]}).then((res) => {
            const status = res.data.status
            const data = res.data.data
          
            if (status.result == '200') {
                
                setVideoDetails(data)
                console.log("setVideoDetails",videoDetails,data)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
      //  setLoading(false)
    }


    const favoriteVideo = () => {
        post('user_videoadd_favourite', {'videoid': videoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setFavouriteVideoCount(favouriteVideoCount + 1)
                setIsFavouriteVideo(1)
            } else if (status.result == '201') {
                setFavouriteVideoCount(favouriteVideoCount - 1)
                setIsFavouriteVideo(0)
            } else {
                toast.error(status.msg)
            }
        })
    }
    const favoriteGuru = (guru_id) => {
        post('user_add_guru_favouriate', {'guru_id': guru_id}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setIsGuruFavourite(isGuruFavourite + 1)
                setGuruFavouritedCount(guruFavouritedCount + 1)
            } else if (status.result == '201') {
                setIsGuruFavourite(isGuruFavourite - 1)
                setGuruFavouritedCount(guruFavouritedCount - 1)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        })

    }
    const likeVideo = () => {
        post('video_like', {'video_id': videoId}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setIsLike(1)
                setVideoLikesCount(videoLikesCount + 1)
            } else if (status.result == '201') {
                setIsLike(0)
                setVideoLikesCount(videoLikesCount - 1)
            } else {
                toast.error(status.msg)
            }
        })

    }

    useEffect(()=>{
        if(isSideOpen){
            setIsSideOpen(false)
        }

    },[videoId])

    return {
        isSideOpen,
        onOpenIsSide,
        getVideoDetailsById,
        loading,
        videoDetails,
        favoriteVideo,
        favoriteGuru,
        likeVideo,
        isLike,
        isGuruFavourite,
        isFavouriteVideo,
        favouriteVideoCount,
        videoLikesCount,
        guruFavouritedCount,
        setCourseData,
        getCourseData
    }

}
export default useCourseMain


