import React from 'react';

const InstagramIcon = props => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.0946 29.743L9.92798 29.7565C6.81131 29.7593 4.25919 27.2122 4.25565 24.0955L4.24219 9.92884C4.23936 6.81217 6.78652 4.26004 9.90319 4.2565L24.0699 4.24304C27.1865 4.24021 29.7386 6.78738 29.7422 9.90404L29.7556 24.0707C29.7592 27.1881 27.2113 29.7402 24.0946 29.743Z" fill="url(#paint0_radial_2271_60243)"/>
            <path d="M24.0946 29.743L9.92798 29.7565C6.81131 29.7593 4.25919 27.2122 4.25565 24.0955L4.24219 9.92884C4.23936 6.81217 6.78652 4.26004 9.90319 4.2565L24.0699 4.24304C27.1865 4.24021 29.7386 6.78738 29.7422 9.90404L29.7556 24.0707C29.7592 27.1881 27.2113 29.7402 24.0946 29.743Z" fill="url(#paint1_radial_2271_60243)"/>
            <path d="M16.9993 21.9583C14.2659 21.9583 12.041 19.7341 12.041 17C12.041 14.2658 14.2659 12.0416 16.9993 12.0416C19.7328 12.0416 21.9577 14.2658 21.9577 17C21.9577 19.7341 19.7328 21.9583 16.9993 21.9583ZM16.9993 13.4583C15.0465 13.4583 13.4577 15.0471 13.4577 17C13.4577 18.9528 15.0465 20.5416 16.9993 20.5416C18.9522 20.5416 20.541 18.9528 20.541 17C20.541 15.0471 18.9522 13.4583 16.9993 13.4583Z" fill="white"/>
            <path d="M22.3125 12.75C22.8993 12.75 23.375 12.2743 23.375 11.6875C23.375 11.1007 22.8993 10.625 22.3125 10.625C21.7257 10.625 21.25 11.1007 21.25 11.6875C21.25 12.2743 21.7257 12.75 22.3125 12.75Z" fill="white"/>
            <path d="M21.2493 26.2083H12.7493C10.0159 26.2083 7.79102 23.9841 7.79102 21.25V12.75C7.79102 10.0158 10.0159 7.79163 12.7493 7.79163H21.2493C23.9828 7.79163 26.2077 10.0158 26.2077 12.75V21.25C26.2077 23.9841 23.9828 26.2083 21.2493 26.2083ZM12.7493 9.20829C10.7965 9.20829 9.20768 10.7971 9.20768 12.75V21.25C9.20768 23.2028 10.7965 24.7916 12.7493 24.7916H21.2493C23.2022 24.7916 24.791 23.2028 24.791 21.25V12.75C24.791 10.7971 23.2022 9.20829 21.2493 9.20829H12.7493Z" fill="white"/>
            <defs>
                <radialGradient id="paint0_radial_2271_60243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.7268 29.7749) scale(31.8035)">
                    <stop stop-color="#FFDD55"/>
                    <stop offset="0.328" stop-color="#FF543F"/>
                    <stop offset="0.348" stop-color="#FC5245"/>
                    <stop offset="0.504" stop-color="#E64771"/>
                    <stop offset="0.643" stop-color="#D53E91"/>
                    <stop offset="0.761" stop-color="#CC39A4"/>
                    <stop offset="0.841" stop-color="#C837AB"/>
                </radialGradient>
                <radialGradient id="paint1_radial_2271_60243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.34769 3.92451) scale(21.1175 14.0706)">
                    <stop stop-color="#4168C9"/>
                    <stop offset="0.999" stop-color="#4168C9" stop-opacity="0"/>
                </radialGradient>
            </defs>
        </svg>
    );
};

export default InstagramIcon;

InstagramIcon.defaultProps = {
    width: "34",
    height: "34"
}