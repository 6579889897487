import React from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import TrendingTopicPageView from './views/trendingTopicPageView'

const TrendingTopicPage = (props) => {
  const commonHook = useGetTableList();
  const _extraParams = {"type":"get_today_added_course","from_date":"2023-03-02"};
  if(commonHook.loading || commonHook.loading) {
    return <Loader/>
}else{
  return (
    <div>
        <LayoutPanel {...props} footer>
      <TrendingTopicPageView />
      </LayoutPanel>
    </div>
  )
}
}

export default TrendingTopicPage
