import Text from '../../../../components/text';
import Flex from "../../../../components/flex";
import Button from '../../../../components/button';
import useOTPVerification from '../hooks/otpVerification.hook';
import OtpInput from 'react-otp-input';
import {useSelector} from "react-redux";
import logoImg from '../../../../assets/userimg/login-bg2.png';
import {auth} from "../../../../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useEffect, useState } from 'react';
import Loader from '../../../../components/loader';
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
const OTPVerificationView = (props) => {
    const {text} = props
    const userInfo = useSelector(state => state.app.userInfo)
    const {email, isdcode} = userInfo
    const mobileNumber = email
    const  {post} = useHttpHandler();
    const {
        
        
        counter,
        setCounter,
        goBackToRegister,
        goToPersonalInformation,
        verifyOtp,
        resendOtp,
        submittingOtp,
        setSubmittingOtp,
        oTPInput, setOTPInput,handleChangeOTP
    } = useOTPVerification();

    

    /*
    useEffect(() => {
      //formik.setFieldValue('mobile', phoneNumber)
  }, [phoneNumber])
*/

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  
  const [count, setCount] = useState(0);

  // Similar to componentDidMount and componentDidUpdate:
 
  
    function onOTPVerify() {
        console.log("123213");
        verifyOtp()
     
    }



    return (
        
        <>
           


<div class="register">
 


<main>
  <div class="container-fluid">
  <div id="recaptcha-container"  ></div>

 
    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div class="row justify-content-center">
        
        
          <div class="col-lg-7 col-md-6"> 
          <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
          </div>
        
          
          <div class="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">

            

            <div class="card mb-3">

              <div class="card-body">


                <div class="pt-4 pb-2">

                <div>
      

                  <h5 class="card-title text-center pb-0 fs-4">WE NEED YOU TO VERIFY YOUR ACCOUNT</h5>
                  <p class="text-center small">We will send you a one time code to</p>
                </div>

               
            <Text
                type="H2"
                text={mobileNumber}
                className="text-center mb-40"
            />
            <Flex justifyContent="center" className="mb-40">
                <OtpInput
                    value={oTPInput}
                    onChange={handleChangeOTP}
                    numInputs={6}
                    containerStyle="otp-wrapper"
                    shouldAutoFocus={true}
                    isInputNum={true}
                />
            </Flex>
            {counter > 0 ?
                <>
                   <Text
                        type="PARAGRAPH"
                        text={text?.RESEND_OTP_IN + ' ' + '00:' + counter}
                        className="text-center"
                    />
                </>
             :
                <>
                    <Text
                        type="PARAGRAPH"
                        text="Didn’t receive OTP code?"
                        className="text-center"
                    />
                    <Flex justifyContent="center">
                        <Button
                            linkSecondary
                            text="Resend Code"
                            onClick={() => {
                              resendOtp();
                              setCounter(counter + 59)
                              }
                            }
                        />
                    </Flex>
                </>
             }
            <Flex justifyContent="center" className="mt-80 mb-40">
                <Button type="button" light large outline text={text?.Go_BACK} onClick={goBackToRegister} className="me-32" />
                <Button isSubmitting={submittingOtp} disabled={submittingOtp} type="button" className="btn-verification" text={text?.SUBMIT} onClick={onOTPVerify}
                        disabled={oTPInput.length < 4} />
            </Flex>
        
           
              </div>
            </div>


          </div>
        </div>
      </div>
</div>
    </section>
    

  </div>
</main>

</div>

        </>
    );
};

export default OTPVerificationView;

