import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import Modal from "../../../components/modal";
import ForgotPassword from "../../../components/forgotpassword";
import LoginView from "./views/LoginView";
import useLogin from "./hooks/login.hook";
import SetNewPassword from "../../../components/setNewPassword";
import {useLocation, useParams} from "react-router-dom";

import { ToastContainer,toast } from 'react-toastify'; // <- add ToastContainer



const Login = props => {
    const location = useLocation()
    const isSetNewPassword = location?.pathname === '/setNewPassword'
    const isForgotPassword = location?.pathname === '/forgotPassword'
    const isResetPassword = location?.pathname === '/resetPassword'
    let { token } = useParams();
    const loginHook = useLogin(toast);
   
    useEffect(() => {
       
    }, [])

    return (
        <>
          
                <LoginView {...props} {...loginHook} />

                {isForgotPassword &&
                <Modal
                    className="modal-signup-right-area"
                    onOuterClose={loginHook.goBackToLogin}
                    modalContent={<ForgotPassword {...props} />}
                />
                }
                {isSetNewPassword &&
                <Modal
                    className="modal-signup-right-area"
                    onOuterClose={loginHook.goBackToLogin}
                    modalContent={<SetNewPassword {...props} />}
                />
                }
          
        </>
    )
}

export default Login;
