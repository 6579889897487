import React, { useEffect } from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader'
import useGuruUploadVideo from '../../instructor/uploadVideo/hooks/guruUploadVideo.hook'
import CategeoryPurchaseView from './views/categeoryPurchaseView'

const CategeoryPurchase = (props) => {
  const guruUploadVideoHook = useGuruUploadVideo()
  useEffect(() => {
     guruUploadVideoHook.getCategories({"perpage":100});
     console.log("dropdown",guruUploadVideoHook.dropdownsData)
  }, [])
  if(guruUploadVideoHook.loading) {
  
    return <Loader/>
  
  }else{
  
    return (
    <div>
      <LayoutPanel {...props} footer> 
      <CategeoryPurchaseView {...props} guruUploadVideoHook={guruUploadVideoHook} />
      </LayoutPanel>
    </div>
  )
}
}

export default CategeoryPurchase
