import React, {useEffect} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import GuruUploadVideoSuccessView from "./view/GuruUploadVideoSuccessView";
import './uploadVideoSuccess.scss'
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import Loader from "../../../components/loader";

const GuruUploadVideoSuccess = props => {
    const guruUploadVideoHook = useGuruUploadVideo()
    useEffect(()=>{
        guruUploadVideoHook.getVideoDetailsById().then(()=>{
            guruUploadVideoHook.getDropDowns()
        })
    },[])

    if(guruUploadVideoHook.loading) {
        return (
            <>
                <LayoutGuru {...props} noBgContent="true">
                    <Loader />
                </LayoutGuru>
            </>
        )
    }

    return (
        <>
            <LayoutGuru {...props} noBgContent="true">
                <GuruUploadVideoSuccessView {...props} guruUploadVideoHook={guruUploadVideoHook} />
            </LayoutGuru>
        </>
    )
}

export default GuruUploadVideoSuccess;
