import React, { useEffect } from 'react'
import LayoutPanel from '../../../../components/layoutPanel';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';
import useGetTableList from '../../../../hooks/getTableListCommonHook';
import CategeoryComissionView from './views/categeoryComissionView';

const CategeoryPage = (props) => {
    const commonHook = useGetTableList();
    useEffect(()=>{
        commonHook.setKeys();
        DEFAULT_FILTERS.uri = 'member_affilate_monthly_report';
        commonHook.getList(DEFAULT_FILTERS)
    },[])

  return (
    <div>
         <LayoutPanel {...props}>
      <CategeoryComissionView {...props} {...commonHook} />
      </LayoutPanel>
    </div>
  )
}

export default CategeoryPage;
