import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";

const useBankDetails = () => {
    const navigate = useNavigate()

    const goBackToWorkExperience = () =>{
        navigate('/instructor/work-experience')
    }
    const goToUploadAttachments = () => {
      //  navigate('/instructor/upload-attachments')
      navigate('/instructor/dashboard')
    }
    const updateBankDetails = (data) =>{

    }
    return {
        goBackToWorkExperience,
        goToUploadAttachments,
        updateBankDetails
    }

}
export default useBankDetails


