import React,{useEffect, useState} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGuruUploadVideo from "../../instructor/uploadVideo/hooks/guruUploadVideo.hook";
import CourseContentPageView from './views/chatWithInstructorView';
import useHome from '../../Home/hooks/home.hook';
import Loader from '../../../components/loader';

import ChatWithInstructorView from './views/chatWithInstructorView';
import LayoutPanel from '../../../components/layoutPanel';
import useChatWithInstructor from './hook/chatWithInstructor.hook';
import ChatHistoryView from './views/chatHistoryView';
import useChatHook from '../chat/hooks/chat.hook';
import useVideosCommonHooks from '../../instructor/createCourse/videosCommonHook';

const ChatHistory =(props)=>{
  
  const commonHook = useVideosCommonHooks();

  const chatHook = useChatHook();


  
  useEffect(() => {
  
    commonHook?.promiseServiceNonLoading({"uri":"getChatRoomMessages","chatRoom":chatHook.roomName,"pageNum":chatHook.pageNum}).then((data) => {
       
     
      var temparr = [];
      console.log("message_array",data.data)
          for(var i=0;i<data.data.status.length;i++){
            
            const message_array = data.data.status[i].roomMessage.split('%!#@')
            console.log("message_array",message_array)
            temparr.push(message_array);
            
            // appendMessage_arr(message_array);
          }
          chatHook.setAppendMessages(temparr)
         // setPageNum(pageNum+1);
  
    });
  
  
  }, [chatHook.pageNum,chatHook.roomName])



  if (commonHook?.loading) {
  
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
  
  } else {

    return (
      <>
      <LayoutPanel {...props}>
      <ChatHistoryView  {...props}   {...chatHook} commonHook={commonHook}/>
      </LayoutPanel>
      </>
    )
  
  }
  
}

export default ChatHistory;
