import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import PersonalInformationView from "./view/PersonalInformationView";
import InfomationNavSide from "../../../components/infomationNavSide";
import usePersonalInformation from "./hooks/personalInformation.hook";
import useProfile from "../settings/profile/hooks/profile.hook";
import Loader from "../../../components/loader";

const PersonalInformation = props => {
    const {text} = props
    const personalInformationHook = usePersonalInformation()
    const profileHook = useProfile()
    const isLoaded = !profileHook.loading && !personalInformationHook?.loading

    useEffect(() => {
        personalInformationHook?.getDropDowns()
        profileHook?.getProfileDetails()
    }, [])
    if (!isLoaded) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
           
        <InfomationNavSide text={text?.INFORMATION_MENU}/>
           
                <PersonalInformationView
                    {...props}
                    {...personalInformationHook}
                    profileDetails={profileHook.profileDetails}
                />
            
        </>
    )
}

export default PersonalInformation;
