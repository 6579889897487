import React from "react";
import '../courseDetails/courseDetail.css';
import Button from '../button'
import { NavLink, useNavigate } from "react-router-dom";
import ViewContent from "../viewContent";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";

const ModuleCourseCard =(props)=>{
	const navigate = useNavigate();
	
  
    return(
        <>
        
      
	<div className="container mb-3">
				
	<div className="row bg-light custom-instructor-details">
		<div className="position-relative col-12 col-md-3 lazyloaded">
			<img src={process.env.REACT_APP_IMG_URL + props.imgsrc} className="img-fluid module_img mt-1 p-1" alt="instructor image" />
		</div>
		<div className="col-md-9 p-3">
			<div className="row p-0">
				<div className="col-md-11">
				<div className="d-flex flex-row bg-subcolor_2 position-relative top-6  align-items-center">
							<p className="mt-2 text-1 mr-2 font-weight-bold text-dark text-uppercase"> {props.course} </p>
							<h4 className="mt-0 text-2  text-color-secondary">: {props.coursetitle}</h4>
						</div>
				</div>
				<div className="col-md-1">
					<div className="mt2 d-flex flex-row text-center align-items-center">
					<h4 className="text-2 mb-3 text-center link_color"><NavLink to={props?.editlink} className="link_color"> {props.edit} </NavLink> </h4>	
				<h4 className="text-2 mb1 text-center link_color"><NavLink to={props?.link} className="link_color"> {props.delete} </NavLink> </h4>	
					</div>
				</div>
			</div>
		
			<div className="row mt-2">
				<div className="col-md-3">
					<div className="ps-md-0 text-center mb-md-0 pe-4 me-4 border-right align-items-center">
						<div className="p-0 mt-2"> 
							<p className="mb-2 text-1  font-weight-bold text-dark text-uppercase">{props.module}</p>
							<h4 className="mb-2 text-6 text-color-secondary">{props.value}<br/><ViewContent className="text-center"  title={props.viewcontent} link={props?.link}/></h4>
							
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="ps-md-0 text-center mb-md-0 pe-4 me-4 border-right align-items-center">
						<div className="p-0 mt-2"> 
							<p className="mb-2 text-1  font-weight-bold text-dark text-uppercase">{props.modulesavailable}</p>
							<h4 className="mb-4 text-6 text-color-secondary">{props.mdoulesvalue}<br/><ViewContent  title={props.modulesviewcontent} link={props?.link}/></h4>
							
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="ps-md-0 text-center mb-md-0 pe-4 me-4 border-right align-items-center">
						<div className="p-0 mt-2">
							<p className="mb-1 text-1 font-weight-bold text-dark text-uppercase">{props.availablevideos}</p>
							<h4 className="mb-0 text-6  text-color-secondary">{props.valuevideo}<br/><ViewContent  title={props.viewcontent1} link={props?.link}/></h4>
							
						</div>
					</div>
				</div>
				<div className="col-md-2">
					<div className="ps-md-0 text-center mb-md-0 pe-4 me-4 flex-grow-1 align-items-center">
						<div className="p-0 mt-2">
							<p className="mb-2 text-1  font-weight-bold text-dark text-uppercase">{props.status}</p>
							<h4 className="mb-0 text-6  text-color-secondary">{props.active}</h4>
							<div className="row mt-1">
								<div className="col-md-6">
									<div className="position-relative left-6">
								<ViewContent title={props.inactive} link=""/><br/>
								</div>
								</div>
								<div className="col-md-6">
								<ViewContent title={props.statusactive} link=""/>
								</div>
							</div>
							
							
						</div>
					</div>
				</div>
			</div>
			<div className="row">
			<div className="col-12"><p className="text-1 module_desc text-start">{props.des}</p></div>	
			</div>
		</div>
		
	</div>
	</div>

        
        </>
    )
}


export default ModuleCourseCard;

