
import React from 'react';
import { NavLink } from "react-router-dom";
import './navSide.scss'
import Text from '../text';

const NavSide = props => {
    return (
        <>
            <div className="nav-side-wrp">
                <Text type="H1" text={props.title} className="nav-title" />
                <ul className={`nav ${props.className ? props.className : ''}`}>
                    {props?.options?.map(function(d, idx){
                        return (
                            <>
                                <li
                                    className="nav-item"
                                >
                                    <NavLink key={idx} to={d.link} activeClassName="active" className="nav-link">
                                        <span>{d.text}</span>
                                    </NavLink>
                                </li>
                            </>
                        )
                    })}
                </ul>
            </div>
        </>
    );
};

export default NavSide;

NavSide.defaultProps = {
    title: 'Title',
    options: [
        {
            text:"Something 1",
            link: "/"
        },
        {
            text:"Something 2",
            link: "/"
        },
        {
            text:"Something 3",
            link: "/"
        },
    ]
};
