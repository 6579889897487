import React from 'react';
import Text from "../../../../components/text";
import {useNavigate} from "react-router-dom";
import Icon from '../../../../components/icon';
import RorateIcon from '../../../../components/svg/rorateIcon';
import {useMediaQuery} from 'react-responsive';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import WatchNextVideoCard from '../../../../components/watchNextCard';
import WatchNextPoster from '../../../../assets/images/watch-next-image.png';
import useHelper from '../../../../hooks/helpers.hook';

const RecommendedVideosOverlay = (props) => {

    const {isPremiumMember} = useHelper()
    const isPremium = isPremiumMember()
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const {nextvideos} = props?.videoDetails

    return (
        <>

            <div
                className={`vpd-watch-video-replay ${isPremium ? isMobile ? 'justify-content-center' : 'justify-content-end' : 'justify-content-center'}`}>
                <div className="vpd-watch-video-replay-icon">

                    <div
                        className="pointer"
                        onClick={() => {
                            props.setShowRecommendedVideos(false)
                            props.playerRef.current.play()
                        }}
                    >
                        <Icon
                            size32={isMobile}
                            icon={<RorateIcon/>}
                        />
                    </div>
                    <Text
                        type="PARAGRAPH"
                        className="font-weight-700 text-uppercase mt-48 mb-0"
                        text="Play Video again?"
                    />
                </div>
                {isPremium && !isMobile && nextvideos.length > 0 && (
                    <div className="vpd-watch-next">
                        <Text
                            type="PARAGRAPH"
                            className="text-uppercase font-weight-700"
                            text="WATCH NEXT.."
                        />
                        <div className="watch-next-slider">
                            <div className="slider-main">
                                <Swiper
                                    spaceBetween={12}
                                    slidesPerView={1}
                                    modules={[Navigation]}
                                    navigation
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                            pagination: true,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            pagination: true,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                            pagination: false,
                                        },
                                    }}
                                >
                                    {nextvideos?.map(function (d, idx) {
                                        const posterImage = d?.thumbnail_img ? process.env.REACT_APP_IMG_URL + d?.thumbnail_img : WatchNextPoster
                                        const videoLink = props?.isMyLearningPage ? '/trainee/my-learning/video-details/' + d.videoid : '/trainee/video-details/' + d.videoid
                                        return (
                                            <SwiperSlide>
                                                <WatchNextVideoCard
                                                    videoPoster={posterImage}
                                                    duration={d.videoduration}
                                                    views={d.ViewsCount}
                                                    imgWidth="320"
                                                    imgHeight="233"
                                                    title={d.title}
                                                    description={d.des}
                                                    onClick={() => {
                                                        navigate(videoLink)
                                                    }}
                                                />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RecommendedVideosOverlay;

