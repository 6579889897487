import React from 'react';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Avatar from '../../../../../components/avatar';
import DP from '../../../../../assets/images/dp-100.png';
import Icon from '../../../../../components/icon';
import TickIcon from '../../../../../components/svg/tickIcon';
import EditIcon from '../../../../../components/svg/editIcon';
import TextReadonly from '../../../../../components/textReadonly'
import NavLearnerMember from '../../../../../components/navLearnerMember';
import TopicInterestItem from '../../../../../components/topicInterestItem';

const ProfileView = (props) => {
    const { goToEditProfile, profileDetails } = props;
    const {text} =props
    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL+profileDetails?.pic : DP
    const UserStatusIcon = ()=>{
        if(profileDetails?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8" />} />

        } else if(profileDetails?.userstatus == 'Rejected'){
            return <Icon small danger icon={<TickIcon width="12" height="8" />} />
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8" />} />
        }

    }
    const fullAddress = ()=>{
        const address1 = profileDetails?.address1 ? profileDetails?.address1 : ''
        const address2 = profileDetails?.address2 ? profileDetails?.address2 : ''
        const city = profileDetails?.city ? profileDetails?.city : ''
        const statetitle = profileDetails?.statetitle ? profileDetails?.statetitle : ''
        const country = profileDetails?.country ? profileDetails?.country : ''
        const zipcode = profileDetails?.zipcode ? profileDetails?.zipcode : ''
        return address1 +' '+ address2 +' '+ ' '+ city  +' '+ statetitle +' '+ country +' '+zipcode

    }
    return (
        <>
           
        <div className='change-password mt-2 mb-4 px-5 py-4 rounded-0 shadow-sm'>

      
                <Text
                type="H1"
                text="My Profile"
            />
          
           
            <div className="profile-dp mb-20 mt-20">
                <Avatar
                    w100
                    src={profileImage}
                    alt={profileDetails?.fullname}
                />
                <div className="profile-dp-details">
                    <Text type="PARAGRAPH" text={profileDetails?.fullname} className="profile-dp-title" />
                    <Text type="PARAGRAPH" text={profileDetails?.email} className="profile-dp-email" />
                    <div className="account-status">
                        <UserStatusIcon/>
                        <Text type="H6" text="Active" />
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mb-10">
                <Text type="H4" text="General Information" className="mr-24 mb-0" />
                <Button
                    linkPrimary
                    text="Edit"
                    iconLeft={<Icon small square icon={<EditIcon />} />}
                    onClick={goToEditProfile}
                />
            </div>
            <div className="row row-cols-8">
            <div className="card border-radius-0 pad-20  ">
            <div className="row">
           
                <div className="col-md-4 ">
                    <TextReadonly
                        label="Instructor ID"
                        text={profileDetails?.userid}
                    />
                </div>
               
                <div className="col-md-4">
                    <TextReadonly
                        label="Full Name"
                        text={profileDetails?.fullname}
                    />
                </div>

                <div className="col-md-4">
                    <TextReadonly
                        label="Phone Number"
                        text={profileDetails?.mobile}
                    />
                </div>
                

                <div className="col-md-4">
                    <TextReadonly
                        label="Email Address"
                        text={profileDetails?.email}
                    />
                </div>

                <div className="col-md-4">
                    <TextReadonly
                        label="Preferred Name"
                        text={profileDetails?.preffered_name}
                    />
                </div>

                

{/*
                <div className="col-md-4">
                    <TextReadonly
                        label="Facebook Link"
                        text={profileDetails?.facebook}
                    />
                </div>
            
                <div className="col-md-4">
                    <TextReadonly
                        label="Twitter Link"
                        text={profileDetails?.twitter}
                    />
                </div>

                <div className="col-md-4">
                    <TextReadonly
                        label="Instagram Link"
                        text={profileDetails?.instagram}
                    />
                </div>

                <div className="col-md-4">
                    <TextReadonly
                        label="Linkedin Link"
                        text={profileDetails?.linkedin}
                    />
                </div>

    */}
                
                <div className="col-md-12">
                    <TextReadonly
                    type="PARAGRAPH"
                    className="mb-0"
                        label="About Yourself"
                        text={profileDetails?.about_yourself}
                    />
                </div>

            </div>
           
            </div>
              
                
              
            </div>
            



            <div className="d-flex align-items-center mb-10 mt-20">
                <Text type="H4" text="Work and Experience" className="mr-24 mb-0" />
                
            </div>

            <div className="row row-cols-8">
            <div className="card border-radius-0 pad-20  ">
            <div className="row">
           
                <div className="col-md-4 ">
                    <TextReadonly
                        label="Years of Experience"
                        text={profileDetails?.years_of_exp}
                    />
                </div>
               
                <div className="col-md-4">
                    <TextReadonly
                        label="Occupation"
                        text={profileDetails?.occupation}
                    />
                </div>

                <div className="col-md-4">
                 
                </div>
                

                
                <div className="col-md-4">
              
                <Text type="H4" text="Your Interest" />
                </div>              
                <div className="col-md-12">

                <div className="col-md-12">
                    <div className="topic-interest-group mb-2">
                            {profileDetails?.options.map((item,index)=>{
                                    const imgsrc = process.env.REACT_APP_IMG_URL+item?.catPic
                                    return (
                                        <TopicInterestItem
                                            text={item?.catName}
                                            catId={item?.catId}
                                            src={imgsrc}
                                            setSelectedOptions={props?.setSelectedOptions}
                                            isSelected = {true}
                                            dropdownsData = {profileDetails?.options}
                                        
                                        />
                                    )
                            })}
                    </div>  
                </div>


            </div>
            </div>
              </div>
                
              
            </div>
           


            <div className="d-flex align-items-center mb-10 mt-20">
                <Text type="H4" text="Bank Details" className="mr-24 mb-0" />
                
            </div>

            <div className="row row-cols-8">
            <div className="card border-radius-0 pad-20  ">
            <div className="formBox row">
           
                <div className="col-md-4 height-65" >
                    <TextReadonly
                        label="Bank Name"
                        text={profileDetails?.bankname}
                    />
                </div>
               
                <div className="col-md-4 height-65">
                    <TextReadonly
                        label="Account Holder Name"
                        text={profileDetails?.payeename}
                    />
                </div>

                <div className="col-md-4 height-65">
                <TextReadonly
                        label="Account Number"
                        text={profileDetails?.accountnumber}
                    />
                </div>
                

                
             

           
            </div>
              </div>
                
              
            </div>
                            
                </div>
        </>
    );
};

export default ProfileView;

