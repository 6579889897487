import React from "react";
import NavSide from "../navSide";
import './infomationNavSide.scss';

const InfomationNavSide = props => {
    const {text} = props
    return(
        <NavSide
            title="Complete Information"
            options={[
                {text: text?.PERSONAL_PARTICULARS, link: '/trainee/personal-information'},
                {text:text?.CORRESPONDENCE_ADDRESS, link: '/trainee/address-information'},
                {text:text?.BANK_DETAILS, link: '/trainee/bank-details'},
            ]}
        />
    )
}

export default InfomationNavSide;