import React from "react";
import './tooltip.scss';

const Tooltip = props => {
    return(
        <div
            className={`tooltip ${props.right ? 'tooltip-right' : props.top ? 'tooltip-top' : props.down ? 'tooltip-down' : 'tooltip-left'} ${props.className}`}
        >
            {props.text}
        </div>
    )
}

export default Tooltip;
