import {useState} from "react"

import {  useParams} from "react-router-dom"

import {DEFAULTFILTERS, DEFAULT_PAGE_COUNT, TEMP_DEFAULTFILTERS} from "../constants/app.constants"
import useHttpHandler from "./httphandler.hook"


const useNewCommonHook = () => {

 
    const {post} = useHttpHandler();
    const [loading, setLoading] = useState(false)
    const {userId} = useParams();

    const _promiseService = (postData) => {
       
        return new Promise((resolve, reject) => {
            setLoading(true)
            
             post(postData.uri, postData).then((res) => {
                
                const status = res.data.status
                const data = res.data.data
                setLoading(false)
                resolve(res);
             
            }).catch(() => {
                setLoading(false)
                reject();
            }
            )

        });
      };

      const _promiseServiceNonLoading = (postData) => {
       
        return new Promise((resolve, reject) => {
       
        
             post(postData.uri, postData).then((res) => {
                console.log(res);
                const status = res.data.status
                const data = res.data.data
              //  setLoading(false)
                resolve(res);
             
            }).catch(() => {
               // setLoading(false)
                reject();
            }
            )

        });
      };

    return {
        _promiseService,
        _promiseServiceNonLoading,

    }
}

export default useNewCommonHook;
