import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import EyeIcon from "../../../../components/svg/EyeIcon";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import FacebookIcon from "../../../../components/svg/facebookIcon";
import GoogleIcon from "../../../../components/svg/googleIcon";
import AppleIcon from "../../../../components/svg/appleIcon";
import {useFormik} from "formik";
import * as Yup from "yup";
import Checkbox from '../../../../components/checkbox';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {DEFAULT_COUNTRY_ID, FREEMIUM, GURU, SECRETKEY} from "../../../../constants/app.constants";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import useLogin from "../../../authentication/login/hooks/login.hook";
import PhoneInput from "react-phone-number-input";
import useGuruRegister from '../hooks/register.hook';
import useRegister from '../../../trainee/register/hooks/register.hook';
import CryptoJS from "crypto-js";


const SocialRegisterView = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('')
    const {socialUserInfo} = props
    const CryptoJS = require("crypto-js");
    const {
        redirectTo,
        setUserSessionInfo,
        phoneNumbersFormat
    } = useLogin()


    const {post} = useHttpHandler()


    console.log('registerWithGoogle socialUserInfo', socialUserInfo)

    const {text} = props
    const formik = useFormik({
        initialValues: {
            uname: socialUserInfo?.displayName ? socialUserInfo?.displayName : '',
            email: socialUserInfo?.email ? socialUserInfo?.email : '',
            mobile: socialUserInfo?.phoneNumber ? socialUserInfo?.phoneNumber : '',
        },
        validationSchema: Yup.object({
            uname: Yup.string().required('Please enter a valid username'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
            mobile: Yup.string()
                .required('Please enter a valid phone number')
                .min(12, 'Phone number should be minimum 9 digits')
                .max(13, 'Phone number should be less than 10 digits')
                .matches(phoneNumbersFormat, 'Please enter a valid phone number'),

        }),
        onSubmit: (values, actions) => {

            const isd = values.mobile.substring(0, 3)
            const newPhone = values.mobile.substr(3);

            const refCode = sessionStorage.getItem('referralCode');
            const password = JSON.stringify({uid: socialUserInfo?.uid});

            let data = {

                country_id: isd,
                usertype_id: GURU,
                mobile: newPhone,
                email: values.email,
                uname: values.uname,
                ref_code: refCode ? refCode : 1,
                id: CryptoJS.AES.encrypt(password, SECRETKEY).toString(),
                socialtype: searchParams.get('socialtype')
            }

            post('userRegistration', data).then((res) => {
                actions.setSubmitting(false);
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    setUserSessionInfo(data).then(() => {
                        redirectTo(data.redirectcode, data.usertypeid)
                    })
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {
                actions.setSubmitting(false);
            })
        },
    });
    useEffect(() => {
        localStorage.removeItem('token')
        sessionStorage.removeItem('isConvertUserFlow')
    }, [])

    useEffect(() => {
        formik.setFieldValue('mobile', phoneNumber)
    }, [phoneNumber])

    return (
        <>

            <Flex
                justifyContent="between"
                className="mb-40 flex-column flex-md-row"
            >
                <div>
                    <div>
                        <Text
                            type="SPAN"
                            text="Create a Instructor Account"
                            className="mb-0 mr-2 font-size-2rem font-weight-700"
                        />
                        <Text
                            type="SPAN"
                            text={text?.AUTOFILLED}
                            className="mb-0 text-gray8a8a8a"
                        />
                    </div>
                    <Text
                        type="PARAGRAPH"
                        text={text?.AUTOFILLED_DESC}
                        className="mb-0 text-gray8a8a8a"
                    />
                </div>

            </Flex>
            <form onSubmit={formik.handleSubmit}>
                <div className="formBox">
                    <Input
                        autoFocus={true}
                        label={text?.USERNAME}
                        type="text"
                        name="uname"
                        placeholder=""
                        value={formik.values.uname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.uname && formik.errors.uname ? (
                            formik.errors.uname
                        ) : null}
                    />
                </div>
                <div className="formBox">
                    <Input
                        label={text?.EMAIL_ADDRESS}
                        type="text"
                        name="email"
                        placeholder=""
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.email && formik.errors.email ? (
                            formik.errors.email
                        ) : null}
                    />
                </div>

                <div className="formBox">
                    <div className="form-field">
                        <label className="form-label">{text?.PHONE_NUMBER} <span
                            className="text-danger">*</span></label>
                        <PhoneInput
                            className={formik.touched.mobile && formik.errors.mobile ? 'error' : ''}
                            defaultCountry={'MY'}
                            countries={['MY']}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            countryCallingCodeEditable={false}
                            addInternationalOption={false}
                            limitMaxLength="10"
                            international
                        />
                    </div>

                    <div className="formBox hiddenInput">
                        <Input
                            type="hidden"
                            maxlength="10"
                            name="mobile"
                            placeholder=""
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={formik.touched.mobile && formik.errors.mobile ? (
                                formik.errors.mobile
                            ) : null}
                        />
                    </div>
                </div>


                <Flex justifyContent="center" className="mb-48">
                    <Button
                        type="submit"
                        text={text?.SIGN_UP}
                        large
                        disabled={formik.isSubmitting}
                        isSubmitting={formik.isSubmitting}
                    />
                </Flex>
            </form>

        </>
    );
};

export default SocialRegisterView;

