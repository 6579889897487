import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/button'
import CourseCard from '../../../../components/courseCard'
import InstructorImage from '../../../../assets/img/logo.svg';
import CourseImg from '../../../../assets/img/logo.svg';
import Table from '../../../../components/table';
import Pagination from '../../../../components/pagination';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import Image from '../../../../components/image';
import { useMediaQuery } from 'react-responsive';
import useHelper from "../../../../hooks/helpers.hook";
import Status from "../../../../components/status";
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import Select from '../../../../components/input/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FlowCard from '../../../../components/flowCard';
import CourseImage from '../../../../assets/img/logo.svg';
import {Modal, Popconfirm,Result,Tag } from 'antd';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import VideoCard from '../../../../components/videocard';
import ImageVideoPopup from '../../../../components/imageVideoPopup';
import CourseMainBar from '../../../../components/courseMainbar';
import VideoPlayer from '../../../../components/videoplayer';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';
import PlayVideoCompnent from '../../../../components/playVideoCompnent';
import useVideosCommonHooks from '../../createCourse/videosCommonHook';



const FreeCourseContentPageView = (props) => {
    const {formatDateDDMMYY, getShortDescription} = useHelper()
	const {text, goToLearnerSignup, goToGuruSignup} = props
	const {formatDateMMMDDYYYY} = useHelper()
	const [yearSelected, setYearSelected] = useState('Year 2022');
	const [monthSelected, setMonthSelected] = useState('Select Month');
	const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const navigate = useNavigate();
    const tableData = props?.tableData?.listData
    const customData = props?.tableData?.customdata;
    const {homeVideos} = props
    const toggleIsModal = (video) => {
        setVideoDetails(video)
        setIsModal(state => !state);
    }
    const [isModal, setIsModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);

    const [isVideoModal, setIsVideoModal] = useState(false);
    const [vidoePlayUrl, setVidoePlayUrl] = useState("");
    const commonHook = useVideosCommonHooks();
    
    const playVideo = (val) => {
        commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
            setVidoePlayUrl(data.data);
            setIsVideoModal(true);
          });
    };
    
    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        {
            text:"Free Courses",
            link:"/instructor/free-course",
        },
    ]

    const {
        dropdownsData,
        no_of_courses,
        no_of_videos,
        setCheckCategory,
        setCategoryId,
        getCategoryId,
        getSubCategoryId,
        setSubCategoryId,
        subCategories, 
        setSubCategories
        
    } = props

    const [selectedCourseId, setSelectedCourseId] = useState(0);
    const [isDelete, setIsDelete] = useState(false);
    const deleteCourse = (data) => {
        setSelectedCourseId(data.courseid);
        setIsDelete(true);
      }


    



  return (
    
    <>
    
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
    <div className='row'>
        <div className='col-md-9'>
      <Text
        type="H1"
        text="Free Courses"
        className="mt-0 main_heading_color ml-3 mb-0"
    />
    </div>

    <div className="col-md-3">
        <div className='btn_move'>
    <Button
        primary
        type="button"
        text="Create Free Course"
        onClick={()=>{navigate('/instructor/free-create-course')}}
    />
    </div>
</div>    
      
     
        <FlowCard
            course="Instructor"
            coursetitle="Antonio"
            module="No.of Courses"
            value={customData?.no_of_courses}
            availablevideos="Total Videos"
            valuevideo={customData?.no_of_videos}
            dropdownsData ={props?.dropdownsData}
            _props = {props}
        />

        </div>  
            


        <div className="row top-main-boxes  course-main-box mt_-10"  style={ {padding:'0px'}} >
                    
                    {tableData?.map(function (item, index) {
                        return (
                        <CourseMainBar index={index} playVideo={playVideo} data={item}  deleteCourse={deleteCourse} showButtons={1}/>     
                        )
                    })
                }
    
            </div>        

               {/* 
                <Table
                
                thead={
                    <tr>
                        <th className='w-5'>Srno</th>
                        <th className='w-15'>Category</th>
                        <th className='w-20'>Sub Category</th>
                        <th className='w-20'>Course</th>
                        <th className='w-10'>Added Modules</th>
                        <th className='w-10'>Added Videos</th>
                    </tr>
                }
                tbody={
                    <>
                       {tableData?.map(function (item, index) {
                           
                            return (
                                

                    <tr className=''>
                        <td className='bg-light'>{index + 1}</td>
                        <td className='bg-light'>
                            
                            {  <ImageVideoPopup
                                    poster={process.env.REACT_APP_IMG_URL +item.catPic}
                                    alt={item.catName}
                
                                />} 
                        
                        </td>
                        <td className='bg-light'>{item.subCatName}</td>
                        <td className='bg-light'>
                        {  <ImageVideoPopup
                                    poster={process.env.REACT_APP_IMG_URL +item.thumbnail}
                                    alt={item.title}
                
                                />} 
                            </td>
                        <td className='bg-light'>{item.modules } 
                        
                        <Button
                                    linkSecondary
                                    small
                                    text="Add Module"
                                    className="linkbtnsize"
                                    onClick={() => navigate('/instructor/create-module/'+item.courseid)}
                                />
                        
                        </td>
                        <td className='bg-light'>{item.avaliable_videos}</td>
                    </tr>

                                   
                                
                              
                            )
                        })}
                    </>
                }
              />
              
            
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
            */}
{isModal &&
            
            <Modal
               className="modal-light"
                onClose={() => {
                    setIsModal(false)
                    setShowVideoEnded(false)
                }}
                onOuterClose={() => {
                    setIsModal(false)
                    setShowVideoEnded(false)
                }}
                modalContent={
                    <>
                        <div className="homevideomodal-wrapper">
                            <div className="homevideomodal-main">
                                <StaticVideoPlayer videoDetails={videoDetails} showVideoEnded={showVideoEnded}
                                                   goToLearnerSignup={goToLearnerSignup}
                                                   videoEnded={setShowVideoEnded}/>


                            </div>
                            <div className="homevideomodal-content-bottom">
                                <Text
                                    type="H2"
                                    className=""
                                    text={videoDetails?.title}
                                />
                                {/*<Text
                                    type="PARAGRAPH"
                                    className="mt-0 mb-3 text-white-half"
                                    text={videoDetails?.title}
                                />*/}
                                <TextHtmlParser
                                    className="mb-0 text-white-half font-weight-400"
                                    text={videoDetails?.des}
                                />
                            </div>
                        </div>
                    </>
                }
            />
            
            }


{isVideoModal &&


<Modal
    title=""
    centered
    open={isVideoModal}
    onOk={() => setIsVideoModal(false)}
    onCancel={() => setIsVideoModal(false)}
    zIndex={10000}
    width={window.innerWidth - window.innerWidth/5}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
>

        <VideoPlayer
            videourl={vidoePlayUrl}
            width="100%"
            height="460"
        />

</Modal>

}           




        {isDelete &&
            
            
            <Modal
                title=""
                centered
                open={isDelete}
                onOk={() => setIsDelete(false)}
                onCancel={() => setIsDelete(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                zIndex={10000}
            >

                    <Result
                        status="error"
                        title="Are you sure want to delete this course"
                        subTitle=""
                        width={300}
                        extra={[
                        <Button type="primary" key="console" text="Yes"  onClick={()=>{
                            
                            props.guruDashboardHook.instrutorDeleteCourse({"uri":"instrutorDeleteCourse","courseId":selectedCourseId})
                            
                        }}/>,
                        <Button type="primary" key="console" text="No"  onClick={()=>{
                            setIsDelete(false);
                        
                        }}/>
                        ]}
                    />
            
            </Modal>
        }

    {props.guruDashboardHook.deletedCourse &&
        
        
        <Modal
            title=""
            centered
            open={props.guruDashboardHook.deletedCourse}
            onOk={() => props.guruDashboardHook.setDeletedCourse(false)}
            onCancel={() => props.guruDashboardHook.setDeletedCourse(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            zIndex={10000}
        >

                <Result
                    status="success"
                    title="Sucessfully deleted course"
                    subTitle=""
                    width={300}
                    extra={[
                    <Button type="primary" key="console" text="Done"  onClick={()=>{
                        DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
                        DEFAULT_FILTERS.userid = "g";
                        DEFAULT_FILTERS.coursetype = 0;
                        DEFAULT_FILTERS.category_id = props.getCategoryId;
                        DEFAULT_FILTERS.subcategory_id = props.getSubCategoryId;
                        props.getList(DEFAULT_FILTERS)
                        props.guruDashboardHook.setDeletedCourse(false);
                        setIsDelete(false);
                    }}/>,
                  
                    ]}
                />
        
        </Modal>
    }



    </>
  )
}

export default FreeCourseContentPageView
    
CourseCard.defaultProps = {
    videoListOption: []
}
