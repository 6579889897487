import React, { useContext } from "react";
import Button from "../button";
import Flex from "../flex";
import Image from "../image";
import Listen from "../listen";
import PlayIcon from "../svg/playIcon";
import Text from "../text";
import RecentImage from '../../assets/images/video-details-img.jpg'
import copyImg from '../../assets/img/copyIcon.png'
import shareImg from '../../assets/img/shareIcon.png'
import Icon from "../icon";
import { useNavigate } from "react-router-dom";
import useHelper from "../../hooks/helpers.hook";
import BinIcon from "../svg/binIcon";
import binImg from '../../assets/img/Bin.png'
import editImg from '../../assets/img/Edit.png'
import viewImg from '../../assets/img/Eye.png'
import LikesIcon from "../svg/likesIcon";
import lessonImg from '../../assets/img/AddLessons.png'
import QuizIcon from '../../assets/img/QuizIcon.png'
import EditQuizIcon from '../../assets/img/EditQuizIcon.png'


import { AccordionContext, useAccordionButton } from "react-bootstrap";
import useVideosCommonHooks from "../../pages/instructor/createCourse/videosCommonHook";


const SortBar = props => {
    const commonHook = useVideosCommonHooks();
    console.log("props",props)
    const index = props.index;
    const {getShortDescription_dot} = useHelper()
    const navigate = useNavigate();
    var divclass = "row width_100 ";
    const {formatDateDDMMMMYYYY, formatDateDDMMYY2, formatDateHms,formatDateDDMMYYHms} = useHelper();
    
    var descount = "";
    if(props.data?.des >= 200){
        descount = "..."
    }
    if(props.index % 2==0){
   
        //The number is even
        divclass = divclass + "accordion-even-color p-0";
     }
     else {
        divclass = divclass + "accordion-odd-color";
        //The number is odd
     }

    var moduleTitle = "";
    var deleteTitel = "";

    if(props.isLesson){
         moduleTitle = "Edit Lesson";
        deleteTitel ="Delete Lesson";

    }else{
        moduleTitle = "Edit Module";
        deleteTitel ="Delete Module";
    }



    return (
        <> 

         <div className={ divclass  } >

             <div className='col-md-4 col-xs-12'>

                  
                    <div className="video-card" >
                                        <div className="video-poster">
                                            <Image
                                                src={process.env.REACT_APP_IMG_URL + props.data?.thumbnail}
                                                alt={props.data?.title}
                                                width={""}
                                                height={""}
                                                className="video_card"
                                            />
                                            <div className="video-play">
                                                <Button
                                                toolTip="Play Video"
                                                    link
                                                    text={
                                                        <Icon icon={<PlayIcon />}/>
                                                    }
                                                    onClick={() => {
                                                  

                                                        props.playVideo(props.data?.videofilename)
                                                    }}
                                                    type="button"/>
                                            </div>

                                        </div>
                    </div>
                        
                    
            </div>    
        
                            

       

<div className='col-md-6 col-xs-12'>
                    <Text
                        type="PARAGRAPH"
                        text={props.data?.title}
                        className="fs-4"
                    />

                    <Text
                    
                        type="PARAGRAPH"
                        text={getShortDescription_dot(props.data?.des, 320) + descount }
                        className="fs-7"
                        
                    />

{/*
                    <Text
                        type="PARAGRAPH"
                        text={formatDateDDMMYYHms(props.data?.dot)}
                        className=""
                 />
 */}  
</div>



         
            
<div className='col-md-2'>
                                                                      
                    <div className="row pt-2">

                            <div className="col-md-12">
                               <Flex flexColumn="start" alignItemsStart="start">
                           

                            {!props.isLesson &&(
                                       <>

                                    <Button
                                      
                                       className="module-button-title-txt"
                                       link
                                       text={
                                           
                                           <>
                                         <img src={lessonImg}  alt="copy" className='icon-25' />
                                         <Text
                                                           type="SPAN"
                                                            className="module-button-title-txt"
                                                            text="Add Lesson"
                                                       />  
                                           </>
                                           
                                       }
                                       style={{margin:'0px',padding:'1px'}}
                                       onClick={() => {
                                            props.setIsPopType("addLesson");     
                                            props.setSelectedData(props.data)
                                            props.setisShowPopUP(true);
                                         }
                                       }
                                       type="button"
                                       />

{props.viewButton}


                                    
                                   
                                     
{!props.isLesson &&(
                                       <>


<Button
                                       className="module-button-title-txt"
                                       link
                                       text={
                                        <>
                                           <img src={QuizIcon}  alt="copy" className='icon-25' />

                                           <Text
                                                           type="SPAN"
                                                            className="module-button-title-txt"
                                                            text="Add Quiz"
                                                       />  
                                                       </>
                                       }
                                       style={{margin:'0px',padding:'1px'}}
                                       onClick={() => {
                                            props.setIsPopType("addQuiz");     
                                            props.setSelectedData(props.data)
                                            props.setisShowPopUP(true);
                                         }
                                       }
                                       type="button"
                                       />

                            
                                     


                                     

                                       </>
                                )
                            }

                          
                           

                                     

                                       </>
                                )
                            }

                           
                           

                        </Flex> 

                        <Flex flexColumn="start" alignItemsStart="start">
                               {!props.isLesson &&(
                                <>
                             
                             <Button
                                      
                                      className="module-button-title-txt"
                                      link
                                      text={
                                       <>
                                          <img src={EditQuizIcon}  alt="copy" className='icon-25' />
                                          <Text
                                          type="SPAN"
                                           className="module-button-title-txt"
                                           text="View Added Quiz"
                                      />
                                      </>
                                      }
                                      style={{margin:'0px',padding:'1px'}}
                                      onClick={() => {
                                           props.setIsPopType("viewQuiz");     
                                           props.setSelectedData(props.data)
                                           props.setisShowPopUP(true);
                                        }
                                      }
                                      type="button"
                                      />




                             <Button
                                className="module-button-title-txt"
                              
                                link
                                text={
                                    <>
                                    <img src={editImg}  alt="copy" className='icon-25' />
                                        <Text
                                        type="SPAN"
                                        className="module-button-title-txt"
                                        text={moduleTitle}
                                        />
                                    </>
                                }
                                style={{margin:'0px',padding:'1px'}}
                                onClick={() => {

                                    if(props.isLesson){
                                        props.setIsPopType("editLesson");
                                        props.setSelectedData(props.data)
                                        props.setisShowPopUP(true);
                                    }else{
                                        props.setIsPopType("editModule");  
                                        props.setSelectedData(props.data)   
                                        props.setisShowPopUP(true);
                                    }


                                    }
                                }
                                type="button"
                            />
                         

                            <Button
                            
                                link
                                className="module-button-title-txt"
                                text={
                                    <>
                                    <img src={binImg}  alt="copy" className='icon-25' />
                                    <Text
                                                    type="SPAN"
                                                     className="module-button-title-txt"
                                                     text={deleteTitel}
                                                />
                                    </>
                                    
                                }
                                style={{margin:'0px',padding:'1px'}}
                                onClick={() => {
                                    if(props.isLesson){
                                        props.setisDeletePopUP(true);
                                        props.setIsPopType("deleteLesson");
                                        props.setSelectedData(props.data)
                                        
                                    }else{
                                        props.setisDeletePopUP(true);
                                        props.setIsPopType("deleteModule");     
                                        props.setSelectedData(props.data)
                                        
                                    }
                                }
                                
                                }
                                type="button"
                            />
                            </>
)}

                          

                        </Flex> 
                        </div>          
                        <div className="col-md-6 d-none">
                              
                               <Flex flexColumn="start" alignItemsStart="start">
                               {!props.isLesson &&(
                                <>
                             
                             <Button
                                      
                                      className="module-button-title-txt"
                                      link
                                      text={
                                       <>
                                          <img src={EditQuizIcon}  alt="copy" className='icon-25' />
                                          <Text
                                          type="SPAN"
                                           className="module-button-title-txt"
                                           text="View Added Quiz"
                                      />
                                      </>
                                      }
                                      style={{margin:'0px',padding:'1px'}}
                                      onClick={() => {
                                           props.setIsPopType("viewQuiz");     
                                           props.setSelectedData(props.data)
                                           props.setisShowPopUP(true);
                                        }
                                      }
                                      type="button"
                                      />




                             <Button
                                className="module-button-title-txt"
                              
                                link
                                text={
                                    <>
                                    <img src={editImg}  alt="copy" className='icon-25' />
                                        <Text
                                        type="SPAN"
                                        className="module-button-title-txt"
                                        text={moduleTitle}
                                        />
                                    </>
                                }
                                style={{margin:'0px',padding:'1px'}}
                                onClick={() => {

                                    if(props.isLesson){
                                        props.setIsPopType("editLesson");
                                        props.setSelectedData(props.data)
                                        props.setisShowPopUP(true);
                                    }else{
                                        props.setIsPopType("editModule");  
                                        props.setSelectedData(props.data)   
                                        props.setisShowPopUP(true);
                                    }


                                    }
                                }
                                type="button"
                            />
                         

                            <Button
                            
                                link
                                className="module-button-title-txt"
                                text={
                                    <>
                                    <img src={binImg}  alt="copy" className='icon-25' />
                                    <Text
                                                    type="SPAN"
                                                     className="module-button-title-txt"
                                                     text={deleteTitel}
                                                />
                                    </>
                                    
                                }
                                style={{margin:'0px',padding:'1px'}}
                                onClick={() => {
                                    if(props.isLesson){
                                        props.setisDeletePopUP(true);
                                        props.setIsPopType("deleteLesson");
                                        props.setSelectedData(props.data)
                                        
                                    }else{
                                        props.setisDeletePopUP(true);
                                        props.setIsPopType("deleteModule");     
                                        props.setSelectedData(props.data)
                                        
                                    }
                                }
                                
                                }
                                type="button"
                            />
                            </>
)}

                          

                        </Flex> 
                                              
                        </div>
                    </div>                                                                      
                   

                        
                   
            </div>
        </div>
    


</>
    )
}

export default SortBar;